import { connect } from 'react-redux'
import * as penerbitDocumentSlice from '../../features/penerbit_document/penerbitDocumentSlilce'
import PenerbitDocument from './PenerbitDocument'
import React from 'react'

let PenerbitDocumentContainer = (props) => <PenerbitDocument {...props} />

const mapStateToProps = (state) => ({
    currentRegisterStep: state.authentication.currentRegisterStep,
    isLoading: state.penerbitDocument.isLoading,
    penerbitDocuments: state.penerbitDocument.penerbitDocuments,
    user: state.authentication.user,
})

const mapDispatchToProps = {
    clearPenerbitDocument: penerbitDocumentSlice.clearPenerbitDocument,
    getPenerbitDocuments: penerbitDocumentSlice.getPenerbitDocuments,
}

export default connect(mapStateToProps, mapDispatchToProps)(PenerbitDocumentContainer)
