import React from 'react'
import { connect } from 'react-redux'
import * as authenticationSlice from '../../features/authentication/authenticationSlice'
import PenerbitProfile from './PenerbitProfile'

let PenerbitProfileContainer = (props) => <PenerbitProfile {...props} />

const mapStateToProps = (state) => ({
    email: state.authentication.email,
    isActive: state.authentication.isActive,
    isBankinfoExist: state.authentication.isBankinfoExist,
    isEmailVerified: state.authentication.isEmailVerified,
    isTermsAndConditionsApproved: state.authentication.isTermsAndConditionsApproved,
    isRiskStatementApproved: state.authentication.isRiskStatementApproved,
    totalInboxes: state.inbox.totalInboxes,
})

const mapDispatchToProps = {
    refetchUser: authenticationSlice.refetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(PenerbitProfileContainer)
