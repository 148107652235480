import React from 'react'
import { connect } from 'react-redux'
import * as verificationSlice from '../../features/verification/verificationSlice'
import VerifyOtpForm from './VerifyOtpForm'

const VerifyOtpFormContainer = (props) => <VerifyOtpForm {...props} />

const mapStateToProps = (state) => ({
    email: state.authentication.email,
    temporaryEmail: state.authentication.temporaryEmail,
})

const mapDispatchToProps = {
    sendVerificationEmail: verificationSlice.sendVerificationEmail,
    sendVerificationMobileNumber: verificationSlice.sendVerificationMobileNumber,
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtpFormContainer)
