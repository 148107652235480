import { connect } from 'react-redux'
import * as penerbitDetailSlice from '../../features/penerbit_detail/penerbitDetailSlice'
import PenerbitDetail from './PenerbitDetail'
import React from 'react'

let PenerbitDetailContainer = (props) => <PenerbitDetail {...props} />

const mapStateToProps = (state) => ({
    currentRegisterStep: state.authentication.currentRegisterStep,
    isActionLoading: state.penerbitDetail.isActionLoading,
    isActionSuccess: state.penerbitDetail.isActionSuccess,
    isLoading: state.penerbitDetail.isLoading,
    penerbitDetails: state.penerbitDetail.penerbitDetails,
    totalPenerbitDetails: state.penerbitDetail.totalPenerbitDetails,
    user: state.authentication.user,
})

const mapDispatchToProps = {
    clearPenerbitDetail: penerbitDetailSlice.clearPenerbitDetail,
    getPenerbitDetails: penerbitDetailSlice.getPenerbitDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(PenerbitDetailContainer)
