import last from 'lodash/last'
import split from 'lodash/split'

export const toApi = (data) => ({
    file: data.file,
    identifier: data.identifier,
    type: data.type,
    url: data.url,
})

export const toState = (data) => {
    const identifier = data.identifier || ''
    const url = data.url || ''
    let name = ''
    if (url) {
        name = last(split(url, '/'))
    }

    return {
        fieldName: identifier,
        identifier,
        name,
        url,
    }
}
