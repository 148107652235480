import styled from 'styled-components'

const Counter = styled.span`
    &&& {
        @property --num {
            syntax: '<integer>';
            initial-value: 0;
            inherits: false;
        }

        & {
            animation: ${(props) => props.name} 2s;
            animation-fill-mode: forwards;
            transition: --num 5s;
            counter-set: num var(--num);
        }

        &::after {
            content: counter(num);
        }

        @-webkit-keyframes ${(props) => props.name} {
            from {
                --num: 0;
            }
            to {
                --num: ${(props) => props.value};
            }
        }

        @keyframes ${(props) => props.name} {
            from {
                --num: 0;
            }
            to {
                --num: ${(props) => props.value};
            }
        }
    }
`

export default Counter
