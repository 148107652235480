import { reducer as formReducer } from 'redux-form'

import { api } from './services/api'
import annualSalary from './features/annual_salary/annualSalarySlice'
import authentication from './features/authentication/authenticationSlice'
import bankList from './features/bank_list/bankListSlice'
import businessSector from './features/business_sector/businessSectorSlice'
import cart from './features/cart//cartSlice'
import city from './features/city/citySlice'
import contactUs from './features/contact_us/contactUsSlice'
import digisign from './features/digisign/digisignSlice'
import district from './features/district/districtSlice'
import entityType from './features/entity_type/entityTypeSlice'
import faq from './features/faq/faqSlice'
import favorite from './features/favorite/favoriteSlice'
import fundingAmount from './features/funding_amount/fundingAmountSlice'
import inbox from './features/inbox/inboxSlice'
import inboxDetail from './features/inbox_detail/inboxDetailSlice'
import incomeSource from './features/income_source/incomeSourceSlice'
import investmentGoal from './features/investment_goal/investmentGoalSlice'
import pemodal from './features/pemodal/pemodalSlice'
import pemodalBankinfo from './features/pemodal_bankinfo/pemodalBankinfoSlice'
import penerbit from './features/penerbit/penerbitSlice'
import penerbitBankinfo from './features/penerbit_bankinfo/penerbitBankinfoSlice'
import penerbitDetail from './features/penerbit_detail/penerbitDetailSlice'
import penerbitDocument from './features/penerbit_document/penerbitDocumentSlilce'
import product from './features/product/productSlice'
import productCategory from './features/product_category/productCategorySlice'
import productDocument from './features/product_document/productDocumentSlice'
import productRibbon from './features/product_ribbon/productRibbonSlice'
import productType from './features/product_type/productTypeSlice'
import purchaseOrder from './features/purchase_order/purchaseOrderSlice'
import socialMedia from './features/social_media/socialMediaSlice'
import verification from './features/verification/verificationSlice'

const reducers = {
    // redux form
    form: formReducer,

    // others
    annualSalary,
    authentication,
    bankList,
    businessSector,
    cart,
    city,
    contactUs,
    digisign,
    district,
    entityType,
    faq,
    favorite,
    fundingAmount,
    inbox,
    inboxDetail,
    incomeSource,
    investmentGoal,
    pemodal,
    pemodalBankinfo,
    penerbit,
    penerbitBankinfo,
    penerbitDetail,
    penerbitDocument,
    product,
    productCategory,
    productDocument,
    productRibbon,
    productType,
    purchaseOrder,
    socialMedia,
    verification,

    // Add the generated reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
}

export default reducers
