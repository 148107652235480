import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import moment from 'moment'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import colors from '../../basic/colors'
import StyledSpan from '../../components/styled_span/StyledSpan'
import StyledTableHeader from '../../components/styled_table_header/StyledTableHeader'
import { StyledCheckCircleOutlined, StyledMessage, StyledMessageBubble } from '../StyledComponents'
import { DATE_HOUR_SLASH_FORMAT, HOUR_MINUTE_FORMAT } from '../../constants/formats'

const MessageCard = (props) => {
    const { inboxDetail, inboxDates, user } = props
    const createdBy = get(inboxDetail, 'createdBy', '')
    const createdAt = get(inboxDetail, 'createdAt', '')
    const createdByFromTable = get(inboxDetail, 'createdByFromTable', '')

    const messageBubbleTime = moment(createdAt, DATE_HOUR_SLASH_FORMAT).format(HOUR_MINUTE_FORMAT)
    const isMessageFromOther = createdByFromTable !== user
    const deletedMessageText = 'Percakapan dihapus karena melanggar kebijakan Moneypool'

    return (
        <Fragment>
            <AntdRow justify='center'>{inboxDates}</AntdRow>
            <AntdRow justify={isMessageFromOther ? 'start' : 'end'}>
                <StyledMessage>
                    <AntdSpace size='middle'>
                        {!isMessageFromOther && inboxDetail.isRead && <StyledCheckCircleOutlined />}
                        <StyledMessageBubble>
                            <AntdRow justify='end'>
                                <StyledSpan color={colors.blueberry}>
                                    <AntdSpace align='center'>
                                        <Fragment>
                                            {isMessageFromOther && (
                                                <Fragment>
                                                    {(createdByFromTable === 'pemodal' ||
                                                        createdByFromTable === 'penerbit') &&
                                                        createdBy}
                                                    {createdByFromTable === 'admin_user' && 'admin moneypool'}
                                                </Fragment>
                                            )}
                                        </Fragment>
                                        <Fragment>{messageBubbleTime}</Fragment>
                                    </AntdSpace>
                                </StyledSpan>
                            </AntdRow>
                            <AntdRow justify={!isMessageFromOther ? 'end' : 'start'}>
                                <StyledTableHeader
                                    $textAlign={!isMessageFromOther ? 'end' : 'start'}
                                    color={inboxDetail.isDeleted ? colors.silver : colors.graniteGray}
                                >
                                    {inboxDetail.isDeleted ? deletedMessageText : inboxDetail.message}
                                </StyledTableHeader>
                            </AntdRow>
                        </StyledMessageBubble>
                    </AntdSpace>
                </StyledMessage>
            </AntdRow>
        </Fragment>
    )
}

MessageCard.propTypes = {
    inboxDates: PropTypes.array,
    inboxDetail: PropTypes.object,
    user: PropTypes.string,
}

export default MessageCard
