import { default as AntdCollapse } from 'antd/lib/collapse'
import { default as AntdRow } from 'antd/lib/row'
import styled from 'styled-components'
import colors from '../../basic/colors'

export const StyledAntdCollapse = styled(AntdCollapse)`
    &&& {
        background-color: white;
        border: none;
    }
`

const AntdCollapsePanel = AntdCollapse.Panel
export const StyledAntdCollapsePanel = styled(AntdCollapsePanel)`
    &&& {
        background-color: white;
        border-bottom: none;
        margin-top: 20px;

        div.ant-collapse-header {
            align-items: center;
            border: 2px solid silver;
            border-radius: 20px;
            display: flex;
            flex-direction: row-reverse;
            height: 100%;
            padding: 15px;
        }

        div.ant-collapse-content.ant-collapse-content-active {
            border: none;
        }

        div.ant-collapse-content-box {
            background-color: ${colors.silver}33;
            border-radius: 20px;
            margin-top: 10px;
            padding: 15px;
        }

        div.ant-collapse-content-box > span.ql-editor {
            line-height: 1, 5;
            padding: 0px;
        }
    }
`

export const StyledAntdRow = styled(AntdRow)`
    &&& {
        width: 100%;
        margin-right: 30px;

        span > * {
            line-height: 1, 5;
        }
    }
`
