import * as request from '../client/request'

export const create = (uri, data, deadline) => request.post(uri, data, deadline)

export const deleteById = (uri, id) => request.del(uri, { id })

export const deleteByIdAndName = (uri, { name, penerbitDetailId }) => request.del(uri, { name, penerbitDetailId })

export const deleteByIdentifer = (uri, identifier) => request.del(uri, { identifier })

export const deleteByProductId = (uri, product_id) => request.del(uri, { product_id })

export const fetch = (uri, { options } = {}) => request.get(uri, { options })

export const fetchAll = (uri, { limit, offset, options }) => request.get(uri, { limit, offset, options })

export const fetchByEmail = (uri, email) => request.get(uri, { email })

export const fetchById = (uri, id) => request.get(uri, { id })

export const fetchByIdCardName = (uri, id_card_name) => request.get(uri, { id_card_name })

export const fetchByMobileNumber = (uri, mobile_number) => request.get(uri, { mobile_number })

export const fetchBySlug = (uri, slug) => request.get(uri, { slug })

export const fetchByType = (uri, type) => request.get(uri, { type })

export const update = (uri, data) => request.put(uri, data)
