import { default as AntdLayout } from 'antd/lib/layout'
import styled from 'styled-components'
import colors from '../basic/colors'
import imagePath from '../basic/imagePath'

const AntdFooter = AntdLayout.Footer
export const StyledFooter = styled(AntdFooter)`
    &&& {
        background-color: ${colors.black}00;
        padding: 0px 100px;
        background-image: url(${imagePath.footerBackgroundDesktop});
        background-position: right bottom;
        background-repeat: no-repeat;

        @media screen and (max-width: 768px) {
            padding: 0px 35px;
            background-image: url(${imagePath.footerBackgroundMobile});
        }
    }
`

export const StyledFooterContainer = styled.div`
    margin-bottom: 0px;
    padding: 50px 0px;
    border-top: 1px solid ${(props) => (props.$dark ? 'white' : colors.graniteGray)};
    @media screen and (max-width: 768px) {
        margin-bottom: 50px;
        padding: 30px 0px;
    }
`

export const StyledFooterIcon = styled.img`
    width: 25px;
    height: 25px;
    ${(props) =>
        props.$dark &&
        `
        filter: brightness(0) invert(1);
    `}
`

export const StyledOrderedList = styled.ol`
    list-style-type: lower-alpha !important;
`

export const StyledLogo = styled.img`
    float: left;
    width: auto;
    height: 50px;
`

export const StyledTextRight = styled.div`
    line-height: 1.2;
    text-align: right;
`
