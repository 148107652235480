import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/pemodal'
import * as api from '../api'

export const createPemodal = createAsyncThunk('CREATE_PEMODAL', async (data, { rejectWithValue }) => {
    try {
        const res = await api.create('/pemodal/create', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getPemodalByEmail = createAsyncThunk('GET_PEMODAL_BY_EMAIL', async (email, { rejectWithValue }) => {
    try {
        const res = await api.fetchByEmail('/pemodal/check-duplicate', email)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getPemodalByMobileNumber = createAsyncThunk(
    'GET_PEMODAL_BY_MOBILE_NUMBER',
    async (mobile_number, { rejectWithValue }) => {
        try {
            const res = await api.fetchByMobileNumber('/pemodal/check-duplicate', mobile_number)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getPemodalProfile = createAsyncThunk('GET_PEMODAL_PROFILE', async (type, { rejectWithValue }) => {
    try {
        const res = await api.fetchByType('/pemodal/profile', type)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const updatePemodal = createAsyncThunk('UPDATE_PEMODAL', async (data, { rejectWithValue }) => {
    try {
        const res = await api.update('/pemodal/update', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})
