import React from 'react'
import { connect } from 'react-redux'
import Success from './Success'

const SuccessContainer = (props) => <Success {...props} />

const mapStateToProps = (state) => ({
    currentRegisterStep: state.authentication.currentRegisterStep,
    user: state.authentication.user,
    username: state.authentication.username,
})

export default connect(mapStateToProps)(SuccessContainer)
