import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import get from 'lodash/get'
import includes from 'lodash/includes'
import split from 'lodash/split'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import colors from '../basic/colors'
import imagePath from '../basic/imagePath'
import HelmetMeta from '../components/HelmetMeta'
import { StyledAntdSpace, StyledImage, StyledSection } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'
import { connect } from 'react-redux'
import { renderLinkButton } from '../utility/renderLinkComp'
import BankInfoModal from '../components/bank_info_modal/BankInfoModal'

const Result = (props) => {
    const { isBankinfoExist } = props
    const { dark, location, params } = useOutletContext()
    const pathname = get(location, 'pathname', '')
    const splitPathname = split(pathname, '/')
    const noDoc = get(params, 'noDoc', '')

    const isSuccess = includes(splitPathname, 'success')
    const imageSrc = isSuccess ? imagePath.purchaseOrderSuccess : imagePath.purchaseOrderFailed
    const image = <StyledImage src={imageSrc} />

    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        if (!isBankinfoExist && isSuccess) {
            setIsModalOpen(true)
        }
    }, [isBankinfoExist, isSuccess])

    const handleCancel = () => setIsModalOpen(false)

    const justify = isMobile() ? 'center' : 'left'

    const renderContent = () => {
        if (isSuccess) {
            return (
                <StyledAntdSpace direction='vertical' size='large'>
                    <StyledTitle color={colors.graniteGray} level={2}>
                        {`Order ${noDoc} Sukses`}
                    </StyledTitle>
                    <StyledTitle color={colors.graniteGray} level={5}>
                        Pelajari lebih lanjut status belimu pada menu Transaksi dan Portfolio.
                    </StyledTitle>
                    <AntdRow>
                        <AntdCol md={12} sm={24} xs={24}>
                            {renderLinkButton('/transaction-histories', {
                                dark,
                                name: 'history-btn',
                                value: 'Riwayat Transaksi',
                            })}
                        </AntdCol>
                    </AntdRow>
                </StyledAntdSpace>
            )
        }

        return (
            <StyledAntdSpace direction='vertical' size='large'>
                <StyledTitle $dark={dark} color={colors.graniteGray} level={2}>
                    Oops Order Tertunda !
                </StyledTitle>
                <StyledTitle color={colors.graniteGray} level={5}>
                    Cek kembali status saham bisnis yang kamu beli pada menu penawaran maupun status transaksimu pada
                    menu transaksi.
                </StyledTitle>
                <br />
                <AntdRow gutter={[20, 20]}>
                    <AntdCol md={12} sm={24} xs={24}>
                        <AntdRow justify={justify}>
                            {renderLinkButton('/transaction-histories', {
                                dark,
                                name: 'history-btn',
                                value: 'Riwayat Transaksi',
                            })}
                        </AntdRow>
                    </AntdCol>
                    <AntdCol md={12} sm={24} xs={24}>
                        <AntdRow justify={justify}>
                            {renderLinkButton('/products', { dark, name: 'see-offer-btn', value: 'Lihat Penawaran' })}
                        </AntdRow>
                    </AntdCol>
                    <AntdCol md={12} sm={24} xs={24}>
                        <AntdRow justify={justify}>
                            {renderLinkButton('/inbox', { dark, name: 'help-center-btn', value: 'Pusat Bantuan' })}
                        </AntdRow>
                    </AntdCol>
                </AntdRow>
            </StyledAntdSpace>
        )
    }

    const metaTitle = () => 'Order ' + (isSuccess ? 'Berhasil' : 'Gagal')

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle()} needIndex={false} />
            <AntdRow align='middle' gutter={[0, 20]}>
                <AntdCol md={12} sm={24} xs={24}>
                    <AntdRow justify='center'>{renderLazyLoadImage(image)}</AntdRow>
                </AntdCol>
                <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                    {renderContent()}
                </AntdCol>
            </AntdRow>
            <BankInfoModal isModalOpen={isModalOpen} onCancel={handleCancel} />
        </StyledSection>
    )
}

const mapStateToProps = (state) => ({
    isBankinfoExist: state.authentication.isBankinfoExist,
})

Result.propTypes = {
    isBankinfoExist: PropTypes.bool,
}

export default connect(mapStateToProps)(Result)
