import React from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import * as inboxSlice from '../features/inbox/inboxSlice'
import * as inboxDetailSlice from '../features/inbox_detail/inboxDetailSlice'
import * as penerbitSlice from '../features/penerbit/penerbitSlice'
import * as productSlice from '../features/product/productSlice'
import Inbox from './Inbox'

let InboxContainer = (props) => <Inbox {...props} />

const mapStateToProps = (state) => ({
    id: state.inbox.id,
    inbox: state.inbox.inbox,
    inboxes: state.inbox.inboxes,
    isActionLoadingInbox: state.inbox.isActionLoading,
    isActionLoadingInboxDetail: state.inboxDetail.isActionLoading,
    isActionSuccessInboxDetail: state.inboxDetail.isActionSuccess,
    penerbits: state.penerbit.penerbits,
    products: state.product.products,
    totalInboxes: state.inbox.totalInboxes,
    user: state.authentication.user,
    username: state.authentication.username,
})

const mapDispatchToProps = {
    clearInbox: inboxSlice.clearInbox,
    clearInboxDetail: inboxDetailSlice.clearInboxDetail,
    createInbox: inboxSlice.createInbox,
    createInboxDetail: inboxDetailSlice.createInboxDetail,
    getInbox: inboxSlice.getInbox,
    getInboxes: inboxSlice.getInboxes,
    getPenerbits: penerbitSlice.getPenerbits,
    getProducts: productSlice.getProducts,
    readInboxDetails: inboxDetailSlice.readInboxDetails,
    updateInbox: inboxSlice.updateInbox,
}

InboxContainer = reduxForm({
    enableReinitialize: true,
    form: 'inbox',
})(InboxContainer)

export default connect(mapStateToProps, mapDispatchToProps)(InboxContainer)
