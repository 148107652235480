import startCase from 'lodash/startCase'
import moment from 'moment'
import { DATETIME_SLASH_FULL_FORMAT } from '../constants/formats'

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid()
        ? moment(data.createdAt).format(DATETIME_SLASH_FULL_FORMAT)
        : null
    return {
        amount: data.amount || 0,
        createdAt,
        createdBy: data.createdBy || '',
        description: data.description ? startCase(data.description) : '-',
    }
}
