import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import { Button, Form, ReduxFormInput, ReduxFormSelect } from '../../antdcomponents'
import { typeOptions as userTypeOptions } from '../../common/options/user'
import { StyledAntdSpace, StyledCenterSection, StyledUnderlinedText } from '../../components/StyledComponents'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { required, validEmail } from '../../utility/formValidation'
import { renderLink } from '../../utility/renderLinkComp'

const ForgotPasswordForm = (props) => {
    const {
        clearCustomErrorMessage,
        customErrorMessage,
        forgotPassword,
        handleSubmit,
        isActionLoading,
        isActionSuccess,
        resetAction,
        setPageNumber,
    } = props
    const { dark, dispatch } = useOutletContext()

    useEffect(() => {
        const clearDatas = () => {
            dispatch(clearCustomErrorMessage())
        }
        window.addEventListener('beforeunload', clearDatas)
        return () => {
            clearDatas()
            window.removeEventListener('beforeunload', clearDatas)
        }
    }, [clearCustomErrorMessage, dispatch])

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            setPageNumber(1)
            dispatch(resetAction())
        }
    }, [dispatch, isActionLoading, isActionSuccess, resetAction, setPageNumber])

    const handleFinish = (values) => {
        forgotPassword(values)
    }

    return (
        <StyledCenterSection>
            <AntdSpace direction='vertical'>
                <StyledAntdSpace direction='vertical' size='large'>
                    <div>
                        <StyledTitle $dark={dark} level={2}>
                            Lupa Kata Sandi
                        </StyledTitle>
                        {isEmpty(customErrorMessage) && (
                            <StyledTitle $dark={dark} level={3}>
                                Kami akan mengirimkan info ke email terdaftarmu.
                            </StyledTitle>
                        )}
                    </div>
                    {!isEmpty(customErrorMessage) && (
                        <StyledTitle $dark={dark} level={5}>
                            {customErrorMessage}
                            {renderLink(
                                { to: '/contact-us' },
                                <StyledUnderlinedText>silahkan hubungi tim admin moneypool</StyledUnderlinedText>
                            )}
                        </StyledTitle>
                    )}
                    <Form onFinish={handleSubmit(handleFinish)}>
                        <Field
                            component={ReduxFormSelect}
                            componentProps={{
                                options: userTypeOptions,
                            }}
                            dark={dark}
                            label='Tipe Akun'
                            name='userType'
                            placeholder='Pilih tipe akun'
                            validate={[required]}
                        />
                        <Field
                            component={ReduxFormInput}
                            dark={dark}
                            label='Email'
                            name='email'
                            placeholder='Masukkan email anda'
                            validate={[required, validEmail]}
                        />
                        <br />
                        <AntdRow>
                            <AntdCol md={{ offset: 6, span: 12 }} sm={24} xs={24}>
                                <Button
                                    dark={dark}
                                    htmlType='submit'
                                    loading={isActionLoading}
                                    name='forgot-password-btn'
                                    value='Kirim'
                                />
                            </AntdCol>
                        </AntdRow>
                    </Form>
                    <center>
                        <StyledTitle $dark={dark} level={5}>
                            {renderLink({ to: '/login' }, `< Kembali`)}
                        </StyledTitle>
                    </center>
                </StyledAntdSpace>
            </AntdSpace>
        </StyledCenterSection>
    )
}

ForgotPasswordForm.propTypes = {
    clearCustomErrorMessage: PropTypes.func,
    customErrorMessage: PropTypes.string,
    forgotPassword: PropTypes.func,
    handleSubmit: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    resetAction: PropTypes.func,
    setPageNumber: PropTypes.func,
}

export default ForgotPasswordForm
