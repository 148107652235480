import PropTypes from 'prop-types'
import React from 'react'
import StyledTitle from '../../../components/styled_title/StyledTitle'
import { StyledFormItem, StyledInputOtp } from './StyledComponents'

const ReduxFormInputOtp = (props) => {
    const { dark, formItemProps, input, meta } = props
    const { onChange, value, name } = input
    const { touched, error } = meta
    const isError = touched && !!error

    const seperator = (
        <StyledTitle $dark={dark} level={5}>
            &nbsp;&nbsp;&nbsp;
        </StyledTitle>
    )

    return (
        <StyledFormItem
            {...formItemProps}
            hasFeedback={isError}
            help={isError && error}
            validateStatus={isError ? 'error' : undefined}
        >
            <StyledInputOtp name={name} numInputs={6} onChange={onChange} separator={seperator} value={value} />
        </StyledFormItem>
    )
}

ReduxFormInputOtp.propTypes = {
    dark: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelColor: PropTypes.string,
    meta: PropTypes.object.isRequired,
}

export default ReduxFormInputOtp
