import { DownOutlined } from '@ant-design/icons'
import { default as AntdDropdown } from 'antd/lib/dropdown'
import { default as AntdMenu } from 'antd/lib/menu'
import map from 'lodash/map'
import colors from '../../basic/colors'
import PropTypes from 'prop-types'
import React from 'react'
import StyledSpan from '../../components/styled_span/StyledSpan'

const AntdMenuItem = AntdMenu.Item

const Dropdown = (props) => {
    const { disabled, options, placement, title, trigger } = props

    const handleClick = (item) => {
        const handler = options[Number(item.key)].onClick
        return handler ? handler() : null
    }

    const mapOptions = () => {
        const items = map(options, (item, key) => (
            <AntdMenuItem key={key}>
                <StyledSpan color={colors.blueberry2}>{item.content}</StyledSpan>
            </AntdMenuItem>
        ))
        return <AntdMenu onClick={handleClick}>{items}</AntdMenu>
    }

    return (
        <AntdDropdown disabled={disabled} overlay={mapOptions()} placement={placement} trigger={trigger}>
            <a>
                <StyledSpan color={colors.blueberry2}>
                    {title} <DownOutlined />
                </StyledSpan>
            </a>
        </AntdDropdown>
    )
}

Dropdown.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            isDisabled: PropTypes.bool,
            onClick: PropTypes.func,
        })
    ).isRequired,
    placement: PropTypes.oneOf(['bottomCenter', 'bottomLeft', 'bottomRight', 'topCenter', 'topLeft', 'topRight']),
    title: PropTypes.string,
    trigger: PropTypes.array,
}
Dropdown.defaultProps = {
    disabled: false,
    options: [],
    placement: 'bottomLeft',
    title: '',
    trigger: ['hover'],
}
export default Dropdown
