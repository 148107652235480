import React from 'react'
import { reduxForm } from 'redux-form'
import EmailOtpVerification from './EmailOtpVerification'

let EmailOtpVerificationContainer = (props) => <EmailOtpVerification {...props} />

EmailOtpVerificationContainer = reduxForm({
    enableReinitialize: true,
    form: 'emailOtpVerification',
})(EmailOtpVerificationContainer)

export default EmailOtpVerificationContainer
