import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import lowerCase from 'lodash/lowerCase'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Collapse, Empty, InputSearch } from '../antdcomponents'
import HelmetMeta from '../components/HelmetMeta'
import Loader from '../components/loader/Loader'
import StyledTitle from '../components/styled_title/StyledTitle'
import { StyledAntdSpace, StyledSection } from '../components/StyledComponents'
import { filterFaq } from './helper'
import { StyledTabs } from './StyledComponents'

const Faq = (props) => {
    const { clearFaq, faqs, getFaqs, isError, isLoading } = props
    const { dark, dispatch, location, navigate, search } = useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const hash = get(location, 'hash', '')
    const keyword = get(query, 'keyword', '')
    const faqsRef = useRef()
    const [activeKey, setActiveKey] = useState('1')

    const panes = [
        {
            title: 'Umum',
            key: '1',
        },
        {
            title: 'Pemodal',
            key: '2',
        },
        {
            title: 'Penerbit',
            key: '3',
        },
    ]

    useEffect(() => {
        const faqs = faqsRef.current
        if (!isError && !isLoading && !isEmpty(hash) && faqs) {
            faqs.scrollIntoView({ behavior: 'smooth' })
        }
    }, [hash, isError, isLoading, faqsRef])

    useEffect(() => {
        const options = { keyword }
        const fetchFaqs = async () => await getFaqs({ options })
        fetchFaqs()
        return () => {
            dispatch(clearFaq())
        }
    }, [clearFaq, dispatch, getFaqs, keyword])

    const handleChangeTab = (activeKey) => {
        setActiveKey(activeKey)
        const route = `/faq#${lowerCase(panes[activeKey - 1].title)}`
        navigate(route)
    }

    const handleSearch = (value) => {
        if (isEmpty(value)) {
            navigate('/faq')
        } else {
            navigate(`/faq?keyword=${value}`)
        }
    }

    const renderFaq = (title, key) => {
        const lowerCaseTitle = lowerCase(title)
        const items = filterFaq(faqs, lowerCaseTitle)
        return (
            <div key={key} ref={`#${lowerCaseTitle}` === hash ? faqsRef : null}>
                <StyledTitle $dark={dark} level={3}>
                    {title}
                </StyledTitle>
                {isEmpty(items) && <Empty />}
                {!isEmpty(items) && <Collapse items={items} />}
            </div>
        )
    }

    const metaTitle = 'FAQ'

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} />
            <StyledAntdSpace direction='vertical' size='large'>
                <AntdRow gutter={[0, 15]}>
                    <AntdCol md={{ offset: 12, span: 4 }} sm={24} xs={24}>
                        <AntdRow justify={isMobile() ? 'center' : 'left'}>
                            <StyledTitle $dark={dark} level={2}>
                                {metaTitle}
                            </StyledTitle>
                        </AntdRow>
                    </AntdCol>
                    <AntdCol md={{ offset: 2, span: 6 }} sm={24} xs={24}>
                        <InputSearch
                            dark={dark}
                            defaultValue={keyword}
                            loading={isLoading}
                            name='keyword'
                            onSearch={handleSearch}
                            placeholder='Cari Pertanyaan'
                        />
                    </AntdCol>
                </AntdRow>

                <StyledTabs
                    activeKey={activeKey}
                    name='tabAction'
                    onChange={handleChangeTab}
                    panes={panes}
                    tabBarGutter={isMobile() ? 15 : 30}
                />

                {isLoading ? (
                    <Loader />
                ) : (
                    <StyledAntdSpace direction='vertical' size={50}>
                        {map(panes, (item, key) => renderFaq(item.title, key))}
                    </StyledAntdSpace>
                )}
            </StyledAntdSpace>
        </StyledSection>
    )
}

Faq.propTypes = {
    clearFaq: PropTypes.func,
    faqs: PropTypes.array,
    getFaqs: PropTypes.func,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
}

export default Faq
