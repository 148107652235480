import { default as AntdLayout } from 'antd/lib/layout'
import { default as AntdMenu } from 'antd/lib/menu'
import { default as AntdRow } from 'antd/lib/row'
import styled, { css } from 'styled-components'
import colors from '../basic/colors'
import imagePath from '../basic/imagePath'
import Counter from '../components/counter/Counter'
import { StyledSection } from '../components/StyledComponents'

const backgroundCss = css`
    background-repeat: no-repeat;
    background-size: 100% 100%;
`

const AntdHeader = AntdLayout.Header
export const StyledHeader = styled(AntdHeader)`
    &&& {
        background: ${colors.black}00;
        height: unset;
        line-height: unset;
        padding: 0px 100px;

        ${(props) =>
            props.$isHomepage &&
            `
                background-image: url(${imagePath.layoutBackground});
                ${backgroundCss};

                @media screen and (max-width: 768px) {
                    background-size: 100% 100%;
                }
            `}

        ${(props) =>
            (props.$isAboutPage || props.$isSecondaryMarketPage) &&
            `
                background-image: url(${props.$isAboutPage ? imagePath.about : imagePath.secondaryMarket});
                ${backgroundCss};
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                
                @media screen and (max-width: 768px) {
                    background-size: cover;
                    background-position-x: center;
                }
            `}

        @media screen and (max-width: 768px) {
            padding: 0px 35px;
        }
    }
`

export const StyledAntdMenu = styled(AntdMenu)`
    &&& {
        background: transparent;
        border-bottom: unset;
        display: flex;
        justify-content: end;
        text-align: right;

        li.ant-menu-item-only-child {
            margin: 0px;
            padding: 5px;
        }

        @media screen and (min-width: 768px) {
            & > li {
                border-bottom: none;
                margin-left: 3px;
                margin-right: 3px;
                padding: 0px 1px;
            }

            & > li:nth-child(1),
            & > li:nth-child(2) {
                margin: 0px 10px;
            }

            & > li::after {
                left: 0px;
                right: 0px;
            }
        }
    }
`

export const StyledAntdRow = styled(AntdRow)`
    padding: 0px 0px 100px 0px;
    @media screen and (min-width: 768px) {
        padding: 150px 0px;
    }
`

export const StyledHeaderContainer = styled.div`
    border-bottom: ${(props) => (props.$dark ? 'none' : `1px solid silver`)};
    padding: 50px 0px;
    @media screen and (max-width: 768px) {
        padding: 30px 0px;
    }
`

export const StyledInstitutionLogo = styled.img`
    width: 100%;
    height: auto;
    @media screen and (min-width: 768px) {
        height: 100%;
    }
`

export const StyledInvestmentData = styled.div`
    background-image: url(${(props) => props.rectangleDesktop});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px;

    @media screen and (max-width: 768px) {
        background-image: url(${(props) => props.rectangleMobile});
        padding: 0px 10px 10px 10px;
    }
`

export const StyledInvestmentDataContainer = styled.div`
    padding-bottom: 30px;
    @media screen and (min-width: 768px) {
        padding-bottom: 50px;
    }
`

export const StyledInvestmentDataSection = styled(StyledSection)`
    &&& {
        padding-left: 0px;
        padding-right: 0px;
    }
`

export const StyledInvestmentDataUnit = styled.small`
    font-size: 20px;
    font-weight: 300;
`

export const StyledLogo = styled.img`
    float: left;
    height: 30px;

    @media screen and (min-width: 768px) {
        height: 50px;
    }
`

export const StyledToogleDrawerIcon = styled.img`
    cursor: pointer;
`

export const StyledPemodalCounter = styled(Counter)``

export const StyledPenerbitCounter = styled(Counter)``

export const StyledInvestmentCounter = styled(Counter)``
