import styled from 'styled-components'
import { default as AntdMenu } from 'antd/lib/menu'
import { default as AntdSteps } from 'antd/lib/steps'
import colors from '../../basic/colors'
import { RightOutlined } from '@ant-design/icons'

export const StyledAntdMenu = styled(AntdMenu)`
    &&& {
        &,
        ul {
            background: transparent;
        }
        border: none;
        .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-arrow::after {
            background-color: white;
        }

        @media screen and (max-width: 767px) {
            li.ant-menu-item {
                padding-left: 12px !important;
            }
        }
    }
`

const AntdStep = AntdSteps.Step
export const StyledAntdStep = styled(AntdStep)`
    &&& {
        div.ant-steps-item-icon {
            ${(props) =>
                props.status === 'wait' &&
                `
                background: ${colors.persianIndigo}33;
                border: none;
            `}
        }

        @media screen and (max-width: 767px) {
            div.ant-steps-item-tail {
                margin-left: 55px;
                padding: 0px;
                padding-top: 5px;
                width: 35%;
            }
            div.ant-steps-item-icon {
                margin-left: 20px;
            }
            div.ant-steps-item-content {
                width: 60px;
            }
        }
    }
`

export const StyledRightOutlined = styled(RightOutlined)`
    color: white;
`

export const StyledUnorderedList = styled.ul`
    margin: 0px;
`
