import styled from 'styled-components'
import { Card } from '../../antdcomponents'
import colors from '../../basic/colors'

export const StyledNumberSection = styled.section`
    &&& {
        line-height: 1.2;
        > div:first-child {
            font-size: 10px;
        }
        > div:last-child {
            font-size: 18px;
            font-weight: 600;
            color: ${colors.blueberry2};
        }
    }
`

export const StyledCompanySection = styled.section`
    &&& {
        line-height: 1.5;
        > div:first-child {
            color: ${colors.blueberry2};
        }
        > div:last-child {
            font-weight: bold;
        }
    }
`

export const StyledImageSection = styled.section`
    &&& {
        display: inline-block;
        max-width: 100px;
        img {
            border-radius: 20px;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
`

export const StyledDetailSection = styled.section`
    &&& {
        display: flex;
        flex-direction: column;
        line-height: 1;
        gap: 16px;
    }
`

export const StyledAntdCard = styled(Card)`
    &&& {
        display: inline-block;
        min-width: 250px;
        .ant-card-body {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-end;
            justify-content: flex-end;
            gap: 10px;
        }
    }
`

export const StyledProductOrderCard = styled.div`
    &&& {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        cursor: pointer;
    }
`
