import styled from 'styled-components'
import { default as AntdResult } from 'antd/lib/result'

const StyledResult = styled(AntdResult)`
    &&& {
        padding: 0px;
        .ant-result-icon {
            display: ${(props) => (props.status ? 'block' : 'none')};
        }

        .ant-result-title,
        .ant-result-subtitle {
            text-align: left;
        }
    }
`

export default StyledResult
