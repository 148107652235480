import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdSteps } from 'antd/lib/steps'
import filter from 'lodash/filter'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Tooltip } from '../../antdcomponents'
import colors from '../../basic/colors'
import {
    StyledAntdAvatar,
    StyledAntdSpace,
    StyledCheckCircleFilled,
    StyledExclamationCircleFilled,
    StyledRightCircleOutlined,
    StyledUnderlinedText,
} from '../../components/StyledComponents'
import StyledSpan from '../../components/styled_span/StyledSpan'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { renderLink } from '../../utility/renderLinkComp'
import { StyledAntdMenu, StyledAntdStep } from './StyledComponents'

const PenerbitProfile = (props) => {
    const {
        email,
        isActive,
        isBankinfoExist,
        isEmailVerified,
        isTermsAndConditionsApproved,
        isRiskStatementApproved,
        refetchUser,
        totalInboxes,
    } = props
    const { dark, location } = useOutletContext()
    const [current, setCurrent] = useState(0)

    useEffect(() => {
        refetchUser({ email })
    }, [email, location, refetchUser])

    const handleChangeSteps = (value) => setCurrent(value)

    const renderMenuTitle = (title, completed, value) => {
        const tooltipNoData = (
            <Tooltip
                content={<StyledExclamationCircleFilled $color={colors.salmon} />}
                placement='right'
                title='Lengkapi Data Anda'
            />
        )

        return (
            <StyledTitle $dark={dark} level={5}>
                {title} {'\u00A0'}{' '}
                {!isUndefined(completed) && (
                    <Fragment>
                        {!completed && tooltipNoData}
                        {completed && <StyledCheckCircleFilled $color={colors.aquamarine} />}
                    </Fragment>
                )}
                {!isUndefined(value) && value > 0 && <StyledAntdAvatar size='small'>{value}</StyledAntdAvatar>}
            </StyledTitle>
        )
    }

    const renderMenuTitleIcon = (title, completed, value) => (
        <AntdRow align='middle'>
            <AntdCol span={22}>{renderMenuTitle(title, completed, value)}</AntdCol>
            <AntdCol span={2}>
                <AntdRow align='end'>
                    <StyledRightCircleOutlined />
                </AntdRow>
            </AntdCol>
        </AntdRow>
    )

    const renderSpaceItem = (first, second) => (
        <StyledAntdSpace direction='vertical' size={0}>
            <Fragment>{first}</Fragment>
            <Fragment>{second}</Fragment>
        </StyledAntdSpace>
    )

    const stepItems = [
        { title: renderSpaceItem('Registrasi', 'Penerbit') },
        { title: renderSpaceItem('Screening &', 'Scoring') },
        { title: renderSpaceItem('Legalitas', 'Perusahaan') },
        { title: renderSpaceItem('Listing', 'Pendanaan') },
    ]
    const menus = [
        {
            completed: isEmailVerified,
            key: 'profile1',
            step: 1,
            title: 'Profil Penerbit',
            to: '/profile/detail',
        },
        {
            completed: isTermsAndConditionsApproved,
            key: 'profile2',
            step: 1,
            title: 'Syarat & Ketentuan Penerbit',
            to: '/terms-and-conditions/penerbit',
        },
        {
            completed: isRiskStatementApproved,
            key: 'profile3',
            step: 1,
            title: 'Pernyataan Risiko',
            to: '/risk-statement',
        },
        {
            completed: isBankinfoExist,
            key: 'profile4',
            step: 1,
            title: 'Rekening Bank',
            to: '/profile/bank',
        },
        {
            key: 'profile5',
            step: 2,
            title: 'Screening Personel',
            to: '/profile/screening-detail',
        },
        {
            key: 'profile6',
            step: 3,
            title: 'Legalitas Perusahaan',
            to: '/profile/document',
        },
        {
            key: 'profile7',
            step: 4,
            title: 'Kotak Masuk',
            to: '/inbox',
            value: totalInboxes,
        },
        {
            key: 'profile8',
            step: 4,
            title: 'Listing Pendanaan',
            to: '/funds',
        },
    ]
    const filteredMenus = filter(menus, (item) => item.step <= current + 1)

    return (
        <StyledAntdSpace direction='vertical' size={36}>
            <AntdSpace direction='vertical' size={0}>
                <StyledTitle $dark={dark} $lineHeight={1} level={2}>
                    Status Registrasi Penerbit
                </StyledTitle>
                {!isActive && (
                    <StyledSpan $dark={dark}>
                        User telah diblokir. Silahkan hubungi tim Moneypool via &nbsp;
                        <a href='https://api.whatsapp.com/send/?phone='>
                            <StyledUnderlinedText>whatsapp</StyledUnderlinedText>
                        </a>
                        &nbsp;atau&nbsp;
                        {renderLink({ to: '/contact-us' }, <StyledUnderlinedText>email</StyledUnderlinedText>)}
                    </StyledSpan>
                )}
            </AntdSpace>

            <center>
                <AntdSteps
                    current={current}
                    labelPlacement='vertical'
                    onChange={handleChangeSteps}
                    responsive={false}
                    size='small'
                >
                    {map(stepItems, (item, key) => {
                        const title = (
                            <StyledSpan $dark={dark} $lineHeight={15}>
                                {item.title}
                            </StyledSpan>
                        )
                        return <StyledAntdStep key={key} status={key > current ? 'wait' : 'process'} title={title} />
                    })}
                </AntdSteps>
            </center>
            <StyledAntdMenu mode='inline'>
                {map(filteredMenus, (item) => (
                    <StyledAntdMenu.Item key={item.key}>
                        {renderLink({ to: item.to }, renderMenuTitleIcon(item.title, item.completed, item.value))}
                    </StyledAntdMenu.Item>
                ))}
            </StyledAntdMenu>
        </StyledAntdSpace>
    )
}

PenerbitProfile.propTypes = {
    email: PropTypes.string,
    isActive: PropTypes.bool,
    isBankinfoExist: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    isRiskStatementApproved: PropTypes.bool,
    isTermsAndConditionsApproved: PropTypes.bool,
    refetchUser: PropTypes.func,
    totalInboxes: PropTypes.number,
}

export default PenerbitProfile
