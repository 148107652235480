import React from 'react'
import PropTypes from 'prop-types'
import * as antdIcon from '@ant-design/icons'

const Icon = (props) => {
    const { className, onClick, spin, style, type } = props
    const AntdIcon = antdIcon[type]

    return <AntdIcon className={className} onClick={onClick} spin={spin} style={style} />
}

Icon.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    spin: PropTypes.bool,
    style: PropTypes.object,
    type: PropTypes.string,
}
Icon.defaultProps = {
    spin: false,
}
export default Icon
