import PropTypes from 'prop-types'
import React from 'react'
import { FormItem, InputPassword } from '../../../antdcomponents'
import { useOutletContext } from 'react-router-dom'

const ReduxFormInputPassword = (props) => {
    const { componentProps, dark, disabled, formItemProps, input, label, meta, placeholder } = props
    const { isConfirmPassword } = componentProps || {}
    const { isProfileDetail, passwordValidation } = useOutletContext()

    const { onChange, name, value } = input
    const { touched, error } = meta

    const isErrorStatus = touched && !!error
    const isErrorRequired = error === 'wajib diisi'
    const isErrorHelpPassword = !isProfileDetail && !passwordValidation && isErrorStatus
    const isErrorHelpConfirmPassword = isConfirmPassword && isErrorStatus && isErrorRequired

    return (
        <FormItem
            {...formItemProps}
            dark={dark}
            help={(isErrorHelpPassword || isErrorHelpConfirmPassword) && error}
            label={label}
            validateStatus={isErrorStatus ? 'error' : undefined}
        >
            <InputPassword
                {...componentProps}
                dark={dark}
                disabled={disabled}
                error={error}
                input={input}
                isTouched={touched}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
            />
        </FormItem>
    )
}

ReduxFormInputPassword.propTypes = {
    componentProps: PropTypes.object,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.node,
    meta: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
}

export default ReduxFormInputPassword
