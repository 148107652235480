import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import concat from 'lodash/concat'
import first from 'lodash/first'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { reset } from 'redux-form'
import { BackButton, Button, InputSearch, Table, TableRowActions, Tooltip } from '../antdcomponents'
import imagePath from '../basic/imagePath'
import { isAuthorizedUser } from '../common/helper/authorization'
import { getFilterFormData, getInitialFilter, getNewOptions } from '../common/helper/purchaseOrder'
import HelmetMeta from '../components/HelmetMeta'
import PurchaseOrderFilterContainer from '../components/purchase_order_filter/PurchaseOrderFilterContainer'
import PurchaseOrderFilterDrawer from '../components/purchase_order_filter_drawer/PurchaseOrderFilterDrawer'
import StyledTitle from '../components/styled_title/StyledTitle'
import { StyledAntdPagination, StyledAntdSpace, StyledSection } from '../components/StyledComponents'
import { getEncryptedId } from '../utility/getEncryptedDecryptedId'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'
import * as purchaseOrderConfig from './config/purchaseOrderConfig'
import * as purchaseOrderTransactionConfig from './config/purchaseOrderTransactionConfig'
import PurchaseOrder from './modal/PurchaseOrder'

const TransactionHistory = (props) => {
    const {
        cancelPurchaseOrder,
        clearInbox,
        clearPurchaseOrder,
        getPurchaseOrders,
        id,
        isActionLoading,
        isActionSuccess,
        isLoading,
        productTypes,
        purchaseOrders,
        totalPurchaseOrders,
    } = props
    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, location, navigate, pathname, search } =
        useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })

    const limit = 10
    const page = get(query, 'page', 1)
    const noDoc = get(query, 'noDoc', null)
    const { defaultAllFilter, initialOptions, initialValues, purchaseOrderSearch } = getInitialFilter(
        productTypes,
        query
    )

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isOpenFilter, setIsOpenFilter] = useState(false)
    const [modalType, setModalType] = useState('')
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState({})
    const [options, setOptions] = useState(initialOptions)
    const [urlParams, setUrlParams] = useState([])

    const filterDarkImage = <img src={imagePath.filterDark} />

    const tooltipActions = <Tooltip title='Aksi pembatalan hanya dapat dilakukan 2x24 jam dari pembelian diproses' />

    const tooltipInfo = <Tooltip title='Mencakup detail pembelian , pembagian dividen, dan pengembalian dana' />

    useEffect(() => {
        if (!isMobile()) {
            setIsOpenFilter(false)
        }
    }, [setIsOpenFilter])

    useEffect(() => {
        setOptions(initialOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const fetchPurchaseOrders = useCallback(async () => {
        const offset = (page - 1) * limit
        let newOptions = getNewOptions(options, defaultAllFilter)
        newOptions = Object.assign(newOptions, {
            noDoc,
            purchaseOrderSearch,
        })

        await getPurchaseOrders({ limit, offset, options: newOptions })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPurchaseOrders, limit, options, page, purchaseOrderSearch])

    //componentDidMount
    useEffect(() => {
        fetchPurchaseOrders()

        return () => {
            dispatch(clearPurchaseOrder())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearPurchaseOrder, dispatch, fetchPurchaseOrders])

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            setIsModalOpen(false)
            fetchPurchaseOrders()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchPurchaseOrders, isActionLoading, isActionSuccess])

    useEffect(() => {
        if (id) {
            const encryptedId = getEncryptedId(id)
            navigate(`/inbox/${encryptedId}`)
        }
        return () => {
            dispatch(clearInbox())
            dispatch(reset('inbox'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearInbox, dispatch, id])

    const handleOpenModal = (type, ord) => {
        let modalType = type
        const isCanceledBeforePaid = get(ord, 'isCanceledBeforePaid', false)
        if (isCanceledBeforePaid) {
            modalType = 'view'
        }
        setModalType(modalType)
        setSelectedPurchaseOrder(ord)
        setIsModalOpen(true)
    }

    const handleActions = (key, type) => {
        const ord = purchaseOrders[key]
        const inboxes = get(ord, 'inboxes', [])
        if (type === 'chat' && !isEmpty(inboxes)) {
            const inbox = first(inboxes)
            const id = get(inbox, 'id', null)
            const encryptedId = getEncryptedId(id)
            navigate(`/inbox/${encryptedId}`)
        }
        if (includes(['cancel', 'chat', 'view'], type)) {
            handleOpenModal(type, ord)
        }
        if (type === 'pay') {
            const invoiceUrl = get(ord, 'invoiceUrl', '')
            if (invoiceUrl) {
                window.location.href = invoiceUrl
            }
        }
        return null
    }

    const handleChange = (page) => {
        const newUrlParams = join(urlParams, '&')
        if (page === 1) {
            navigate(`/transaction-histories?${newUrlParams}`)
        } else {
            navigate(`/transaction-histories?${newUrlParams}${!isEmpty(newUrlParams) ? '&' : ''}page=${page}`)
        }
    }

    const handleCancel = () => setIsModalOpen(false)

    const handleFinish = (values) => {
        let { newOptions, urlParams } = getFilterFormData(values, defaultAllFilter)
        if (purchaseOrderSearch) {
            urlParams = concat(urlParams, `order=${purchaseOrderSearch}`)
        }
        setOptions((prevState) => Object.assign(prevState, newOptions))
        setUrlParams(urlParams)
        urlParams = join(urlParams, '&')
        navigate(`/transaction-histories?${urlParams}`)
    }

    const handleOk = () => {
        const { noDoc } = selectedPurchaseOrder
        cancelPurchaseOrder({ noDoc })
    }

    const handleOpenFilter = () => setIsOpenFilter((prevState) => !prevState)

    const handleReset = () => {
        setIsOpenFilter(false)
        navigate(`/transaction-histories`)
    }

    const handleSearch = (value) => {
        if (value !== '') {
            const urlParams = `order=${value}`
            setUrlParams([urlParams])
            navigate(`/transaction-histories?${urlParams}`)
        } else {
            navigate(`/transaction-histories`)
        }
    }

    const renderActions = (text, record, index) => (
        <TableRowActions options={purchaseOrderConfig.rowActions(index, handleActions, record)} />
    )

    const renderExpandedRow = (record) => {
        const { purchaseOrderTransactions } = record

        return (
            <AntdRow>
                <AntdCol offset={1} span={23}>
                    <Table
                        columns={purchaseOrderTransactionConfig.columns}
                        dataSource={purchaseOrderTransactionConfig.dataSource(purchaseOrderTransactions)}
                    />
                </AntdCol>
            </AntdRow>
        )
    }

    const renderFilterAndTable = (
        <AntdRow>
            <AntdCol md={5} sm={0} xs={0}>
                <PurchaseOrderFilterContainer
                    handleFinish={handleFinish}
                    handleReset={handleReset}
                    initialValues={initialValues}
                />
            </AntdCol>
            <AntdCol md={{ offset: 1, span: 18 }} sm={24}>
                <StyledAntdSpace direction='vertical' size='middle'>
                    <Table
                        columns={purchaseOrderConfig.columns(tooltipActions, renderActions)}
                        dataSource={purchaseOrderConfig.dataSource(purchaseOrders)}
                        expandedRowRender={renderExpandedRow}
                        loading={isLoading}
                    />
                    {!isEmpty(purchaseOrders) && (
                        <AntdRow justify='end'>
                            <StyledAntdPagination
                                $dark={dark}
                                current={Number(page)}
                                defaultPageSize={limit}
                                onChange={handleChange}
                                total={totalPurchaseOrders}
                            />
                        </AntdRow>
                    )}
                </StyledAntdSpace>
            </AntdCol>
        </AntdRow>
    )

    const renderModal = (
        <PurchaseOrder
            isActionLoading={isActionLoading}
            isModalOpen={isModalOpen}
            modalType={modalType}
            onCancel={handleCancel}
            onOk={handleOk}
            purchaseOrder={selectedPurchaseOrder}
        />
    )

    const metaTitle = 'Riwayat Transaksi'

    const renderTitleAndSearchBox = (
        <AntdRow align='middle' gutter={[0, 10]} justify={isMobile() ? 'center' : 'end'}>
            <AntdCol md={17} sm={24} xs={24}>
                <AntdRow align='middle' gutter={[15, 0]} justify={isMobile() ? 'center' : 'end'}>
                    <AntdCol>
                        <StyledTitle $dark={dark} level={2}>
                            {metaTitle}
                        </StyledTitle>
                    </AntdCol>
                    <AntdCol>{tooltipInfo}</AntdCol>
                </AntdRow>
            </AntdCol>
            <AntdCol md={0} sm={2} xs={2}>
                <center>
                    <Button
                        dark={dark}
                        name='open-filter-btn'
                        onClick={handleOpenFilter}
                        type='text'
                        value={renderLazyLoadImage(filterDarkImage)}
                    />
                </center>
            </AntdCol>
            <AntdCol md={{ offset: 1, span: 6 }} sm={{ offset: 1, span: 21 }} xs={{ offset: 1, span: 21 }}>
                <AntdRow justify='end'>
                    <InputSearch
                        dark={dark}
                        defaultValue={purchaseOrderSearch}
                        loading={isLoading}
                        name='order'
                        onSearch={handleSearch}
                        placeholder='Cari'
                    />
                </AntdRow>
            </AntdCol>
        </AntdRow>
    )

    if (!isLoggedIn) {
        navigate('/login')
        return null
    }

    if (!isAuthorizedUser(isPemodal, isPenerbit, pathname)) {
        navigate('/profile')
        return null
    }

    return (
        <Fragment>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <PurchaseOrderFilterDrawer
                handleFinish={handleFinish}
                handleReset={handleReset}
                initialValues={initialValues}
                isOpen={isOpenFilter}
                onClose={handleOpenFilter}
            />
            <StyledSection>
                <StyledAntdSpace direction='vertical' size='large'>
                    <BackButton lastLocation />
                    {renderTitleAndSearchBox}
                    {renderFilterAndTable}
                    {renderModal}
                </StyledAntdSpace>
            </StyledSection>
        </Fragment>
    )
}

TransactionHistory.propTypes = {
    cancelPurchaseOrder: PropTypes.func,
    clearInbox: PropTypes.func,
    clearPurchaseOrder: PropTypes.func,
    getPurchaseOrders: PropTypes.func,
    id: PropTypes.number,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    productTypes: PropTypes.array,
    purchaseOrders: PropTypes.array,
    totalPurchaseOrders: PropTypes.number,
}

export default TransactionHistory
