import { createSlice, current, isAnyOf } from '@reduxjs/toolkit'
import concat from 'lodash/concat'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/inbox'
import * as inboxAPI from './inboxAPI'

const initialState = {
    id: null,
    inbox: {},
    inboxes: [],
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    totalInboxes: 0,
}

export const { createInbox, getInbox, getInboxes, getInboxesUnread, updateInbox } = inboxAPI

const inboxSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(createInbox.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                id: get(data, 'inboxId', null),
                isActionLoading: false,
                isActionSuccess: true,
            })
        })
        builder.addCase(getInboxes.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(getInboxes.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            const oldInboxes = current(state).inboxes
            const newInboxes = map(data.inboxes, (item) => toState(item))
            return Object.assign(state, {
                inboxes: concat(oldInboxes, newInboxes),
                isLoading: false,
                totalInboxes: data.count,
            })
        })
        builder.addCase(getInbox.pending, (state) =>
            Object.assign(state, {
                inbox: {},
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(getInbox.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                inbox: toState(data.inbox),
                isLoading: false,
            })
        })
        builder.addCase(getInboxesUnread.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                totalInboxes: 0,
            })
        )
        builder.addCase(getInboxesUnread.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                totalInboxes: data.count,
            })
        })
        builder.addCase(updateInbox.fulfilled, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
        builder.addMatcher(isAnyOf(createInbox.pending, updateInbox.pending), (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addMatcher(isAnyOf(createInbox.rejected, updateInbox.rejected), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true,
            })
        )
        builder.addMatcher(isAnyOf(getInbox.rejected, getInboxes.rejected, getInboxesUnread.rejected), (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'INBOX',
    reducers: {
        clearInbox: () => initialState,
    },
})

export const { clearInbox } = inboxSlice.actions

const { reducer } = inboxSlice
export default reducer
