import CryptoJS from 'crypto-js'

export const getEncryptedId = (id) => {
    const encryptedId = CryptoJS.AES.encrypt(id.toString(), process.env.REACT_APP_SECRET_KEY).toString()
    const encodedId = CryptoJS.enc.Base64.parse(encryptedId).toString(CryptoJS.enc.Hex)
    return encodedId
}

export const getDecryptedId = (encodedId) => {
    const decodedId = CryptoJS.enc.Hex.parse(encodedId).toString(CryptoJS.enc.Base64)
    const decryptedId = CryptoJS.AES.decrypt(decodedId, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
    return Number(decryptedId)
}
