import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import PropTypes from 'prop-types'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { Modal } from '../../antdcomponents'
import colors from '../../basic/colors'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { verificationFailedTitle } from '../helper'
import { renderLinkButton } from '../../utility/renderLinkComp'

const VerificationFailed = (props) => {
    const { clearDigisign, isModalOpen, onCancel } = props
    const { dispatch } = useOutletContext()

    const handleCancel = () => {
        dispatch(clearDigisign())
        onCancel()
    }

    const footer = (
        <AntdRow justify='center'>
            <AntdCol md={12} sm={24} xs={24}>
                {renderLinkButton('/contact-us', { name: 'help-center-btn', value: 'Pusat Bantuan' })}
            </AntdCol>
        </AntdRow>
    )

    return (
        <Modal footer={footer} onCancel={handleCancel} visible={isModalOpen}>
            <AntdSpace direction='vertical'>
                <StyledTitle color={colors.graniteGray} level={2}>
                    {verificationFailedTitle}
                </StyledTitle>
                <StyledTitle color={colors.doveGray} level={3}>
                    Tim admin moneypool akan membantu pengecekan ulang dan menghubungi Anda dalam 3-5 hari kerja via
                    email terdaftar. Atau hubungi kami dengan klik tombol di bawah ini.
                </StyledTitle>
            </AntdSpace>
        </Modal>
    )
}

VerificationFailed.propTypes = {
    clearDigisign: PropTypes.func,
    isModalOpen: PropTypes.bool,
    onCancel: PropTypes.func,
}

export default VerificationFailed
