import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as cartSlice from '../features/cart/cartSlice'
import * as purchaseOrderSlice from '../features/purchase_order/purchaseOrderSlice'
import Confirmation from './Confirmation'

let ConfirmationContainer = (props) => <Confirmation {...props} />

const mapStateToProps = (state) => ({
    isActionLoading: state.purchaseOrder.isActionLoading,
    isDigisignActivated: state.authentication.isDigisignActivated,
})

const mapDispatchToProps = {
    clearCart: cartSlice.clearCart,
    clearPurchaseOrder: purchaseOrderSlice.clearPurchaseOrder,
    createPurchaseOrder: purchaseOrderSlice.createPurchaseOrder,
}

ConfirmationContainer = reduxForm({
    enableReinitialize: true,
    form: 'purchaseOrderConfirmation',
})(ConfirmationContainer)

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationContainer)
