import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { StyledNextArrowContainer, StyledPrevArrowContainer } from './StyledComponents'

export const PrevArrow = ({ onClick }) => (
    <StyledPrevArrowContainer onClick={onClick}>
        <LeftOutlined />
    </StyledPrevArrowContainer>
)

PrevArrow.propTypes = {
    onClick: PropTypes.func,
}

export const NextArrow = ({ onClick }) => (
    <StyledNextArrowContainer onClick={onClick}>
        <RightOutlined />
    </StyledNextArrowContainer>
)

NextArrow.propTypes = {
    onClick: PropTypes.func,
}
