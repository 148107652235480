import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isLocalStorageAvailable from './isLocalStorageAvailable'

const getPersistentData = (key, windowObj = {}) => {
    let localStorage = get(windowObj, 'localStorage', {})
    if (typeof window !== 'undefined') {
        localStorage = window.localStorage
    }
    const itemKey = !isEmpty(localStorage) ? localStorage.getItem(key) : ''
    if (isLocalStorageAvailable(windowObj) && itemKey) {
        return itemKey
    }

    const regex = new RegExp('(?:(?:^|.*;\\s*)' + key + '\\s*\\=\\s*([^;]*).*$)|^.*$')
    let cookie = get(windowObj, 'document.cookie', '')
    if (typeof window !== 'undefined') {
        cookie = window.document.cookie
    }
    const newCookie = cookie.replace(regex, '$1')
    if (newCookie) {
        return newCookie
    }
    return null
}

export default getPersistentData
