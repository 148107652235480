import omit from 'lodash/omit'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import includes from 'lodash/includes'
import reduce from 'lodash/reduce'
import toLower from 'lodash/toLower'
import find from 'lodash/find'

const getServerStoreState = (storeState) => {
    const { queries } = storeState.api
    const allDatas = mapValues(queries, (query) => query.data)
    const renamedData = map(allDatas, (data, key) => {
        if (includes(toLower(key), 'info')) {
            return { info: data }
        }
        if (includes(toLower(key), 'socialmedia')) {
            return { socialMedia: data }
        }
        if (includes(toLower(key), 'productcategories')) {
            return { productCategory: data }
        }

        return { [key]: data }
    })
    const combinedData = reduce(renamedData, (obj, data) => Object.assign(obj, data), {})
    const defaultReducers = omit(storeState, 'api')
    const mapData = reduce(
        combinedData,
        (obj, dat, key) => {
            const findCombine = find(defaultReducers, (dt, ky) => ky === key) || {}
            const objnew = { ...findCombine, ...dat }
            const combinedObj = { [key]: objnew }
            return Object.assign(obj, combinedObj)
        },
        {}
    )
    return Object.assign(defaultReducers, mapData)
}

export default getServerStoreState
