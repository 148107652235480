import OtpInput from 'react-otp-input'
import styled from 'styled-components'
import colors from '../../../basic/colors'
import modifyVars from '../../../basic/modifyVars'
import FormItem from '../../form_item/FormItem'

export const StyledFormItem = styled(FormItem)`
    &&& {
        .ant-form-item-control-input-content > div > div:nth-child(3)::after {
            color: ${colors.graniteGray};
            content: '-';
            font-size: 40px;
            padding-bottom: 15px;
            width: 13px;

            @media screen and (min-width: 768px) {
                font-size: 55px;
                width: 18px;
            }
        }

        .ant-form-item-control-input-content > div > div:nth-child(3) > h5,
        .ant-form-item-control-input > span.ant-form-item-children-icon {
            display: none;
        }

        .ant-form-item-control-input-content > div > div:nth-child(6) > input {
            width: 80% !important;
        }
    }
`

export const StyledInputOtp = styled(OtpInput)`
    &&& {
        input {
            aspect-ratio: 2 / 3;
            border: 2px solid ${colors.graniteGray};
            border-radius: 15px;
            min-width: 10px !important;
            width: 75% !important;
            font-family: ${modifyVars.hammersmithOneFont};
            font-size: 15px;
        }

        @media screen and (min-width: 768px) {
            input {
                font-size: 24px;
            }
        }
    }
`
