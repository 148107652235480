import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/pemodal'
import * as api from '../api'

const time = 95 * 1000 // allow 95 seconds for registration
export const register = createAsyncThunk('REGISTER', async (data, { rejectWithValue }) => {
    try {
        const res = await api.create('/digisign/register', toApi(data), time)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const generateLinkActivation = createAsyncThunk(
    'GENERATE_LINK_ACTIVATION',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.create('/digisign/generate-link-activation', {}, time)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const digisignCallback = createAsyncThunk('DIGISIGN_CALLBACK', async (data, { rejectWithValue }) => {
    try {
        const res = await api.fetch('/digisign/digisign-callback', data)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const signDocument = createAsyncThunk('SIGN_DOCUMENT', async (data, { rejectWithValue }) => {
    try {
        const res = await api.create('/digisign/sign-document', data, time)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})
