import imagePath from '../basic/imagePath'

const getMoneypoolLogo = (dark) => {
    const isSampleImage = process.env.REACT_APP_USE_SAMPLE_IMAGE === 'true'
    if (dark) {
        if (isSampleImage) {
            return imagePath.moneypoolDarkSample
        } else {
            return imagePath.moneypoolDark
        }
    } else {
        if (isSampleImage) {
            return imagePath.moneypoolLightSample
        } else {
            return imagePath.moneypoolLight
        }
    }
}

export default getMoneypoolLogo
