import { default as AntdBreadcrumb } from 'antd/lib/breadcrumb'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'
import { StyledBreadcrumbTitle } from './StyledComponents'
import { renderLink } from '../../utility/renderLinkComp'

const AntdBreadcrumbItem = AntdBreadcrumb.Item

const Breadcrumb = (props) => {
    const { dark, items } = props

    const renderText = (key, label, linkTo, color) => {
        const lastItem = key === items.length - 1
        const opacity = lastItem ? '25%' : '100%'
        return (
            <AntdBreadcrumbItem key={key}>
                <StyledBreadcrumbTitle $dark={dark} $opacity={opacity} color={color} level={5}>
                    {linkTo ? renderLink({ to: linkTo }, label) : label}
                </StyledBreadcrumbTitle>
            </AntdBreadcrumbItem>
        )
    }

    const renderItem = (item, key) => {
        const { color, label, linkTo } = item
        return renderText(key, label, linkTo, color)
    }

    return <AntdBreadcrumb separator='>'>{map(items, (item, key) => renderItem(item, key))}</AntdBreadcrumb>
}

Breadcrumb.propTypes = {
    dark: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            linkTo: PropTypes.string,
        })
    ).isRequired,
}

export default Breadcrumb
