import { default as AntdSpace } from 'antd/lib/space'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'
import startCase from 'lodash/startCase'
import toUpper from 'lodash/toUpper'
import PropTypes from 'prop-types'
import React, { Fragment, useRef, useState } from 'react'
import { Button, Modal, Upload } from '../../antdcomponents'
import { VALID_IMAGE_EXTENSIONS } from '../../constants/formats'
import useSelectorFormValues from '../../utility/useSelectorFormValues'
import Webcam from './Webcam'
import { StyledImage } from '../../components/StyledComponents'
import { StyledReactWebcam } from '../StyledComponents'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'

const ScreeningUpload = (props) => {
    const { change, fieldName, isDisabled } = props

    const [isSelfieDeleted, setIsSelfieDeleted] = useState(false)
    const [imgSrc, setImgSrc] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isTouched, setIsTouched] = useState(false)
    const isError = isTouched || isSelfieDeleted

    const webcamRef = useRef(null)
    const formValues = useSelectorFormValues('screening')
    const ktp = get(formValues, 'ktp', [])
    const selfie = get(formValues, 'selfie', [])
    const isFieldNameKtp = fieldName === 'ktp'
    const label = isFieldNameKtp ? toUpper(fieldName) : startCase(fieldName)
    const fileList = isFieldNameKtp ? ktp : selfie

    const handleRemove = (fieldName) => (options) => {
        const newFileList = get(options, 'fileList', [])
        change(fieldName, newFileList)
        if (fieldName === 'selfie' && !isEmpty(selfie)) {
            setIsSelfieDeleted(true)
        }
    }

    const handleUpload = (fieldName) => (options) => {
        const { fileList = [], onProgress = noop, onSuccess = noop } = options
        const newFileList = map(cloneDeep(fileList), (item) => {
            return Object.assign(item, {
                fieldName,
                identifier: get(item, 'identifier', fieldName),
                onProgress,
                onSuccess,
            })
        })

        change(fieldName, newFileList)
    }

    const handleCapture = () => {
        const imgSrc = webcamRef.current.getScreenshot()
        const url = imgSrc
        const base64Data = String(url)
        const base64Text = base64Data.substr(indexOf(base64Data, ',') + 1)
        const options = { fileList: [{ base64Text, name: 'selfie', type: 'image/jpeg', url }] }
        setImgSrc(imgSrc)
        handleUpload(fieldName)(options)
        setIsTouched(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        setIsTouched(true)
    }

    const handleWebcamRemove = () => {
        setImgSrc(null)
        setIsTouched(true)
    }

    const renderWebcamCapture = () => (
        <StyledReactWebcam
            audio={false}
            forceScreenshotSourceSize
            minScreenshotHeight={480}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            screenshotQuality={1}
            videoConstraints={{ facingMode: 'user' }}
        />
    )

    const image = <StyledImage src={imgSrc} />

    return (
        <Fragment>
            {fieldName === 'selfie' && isEmpty(selfie) && (
                <Webcam
                    disabled={isDisabled}
                    handleShowModal={() => setIsModalVisible(!isModalVisible)}
                    isError={isError}
                    label={label}
                />
            )}
            {(fieldName !== 'selfie' || !isEmpty(selfie)) && (
                <Upload
                    accept={VALID_IMAGE_EXTENSIONS}
                    disabled={isDisabled}
                    fileList={fileList}
                    formItemProps={{ required: true }}
                    label={label}
                    listType='picture-card'
                    name={fieldName}
                    onRemove={handleRemove(fieldName)}
                    onUpload={handleUpload(fieldName)}
                />
            )}
            {fieldName === 'selfie' && (
                <Modal onCancel={handleCancel} visible={isModalVisible}>
                    <center>
                        <AntdSpace direction='vertical'>
                            {!imgSrc && renderWebcamCapture()}
                            {imgSrc && renderLazyLoadImage(image)}
                            <Button
                                name='capture-btn'
                                onClick={imgSrc ? handleWebcamRemove : handleCapture}
                                value={imgSrc ? 'Ulangi' : 'Tangkap'}
                            />
                        </AntdSpace>
                    </center>
                </Modal>
            )}
        </Fragment>
    )
}

ScreeningUpload.propTypes = {
    change: PropTypes.func,
    fieldName: PropTypes.string,
    isDisabled: PropTypes.bool,
}

export default ScreeningUpload
