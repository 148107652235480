import { default as AntdSpace } from 'antd/lib/space'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import React, { Fragment } from 'react'
import { StyledAntdSpace } from '../StyledComponents'
import { StyledOrderedList, StyledUnorderedList } from './StyledComponents'

const pemodalTermsAndConditions = [
    {
        list: [
            {
                content:
                    'Layanan penawaran efek melalui Urun Dana adalah suatu program layanan penawaran efek melalui Urun Dana yang diselenggarakan oleh Penyelenggara dengan melakukan penawaran Saham milik Penerbit kepada Pemodal atau masyarakat umum melalui jaringan sistem elektronik layanan penawaran efek melalui Urun Dana melalui penawaran Saham berbasis teknologi informasi (securities crowdfunding) milik Penyelenggara yang bersifat terbuka sebagaimana diatur dalam POJK Penawaran Efek Melalui Layanan penawaran efek melalui Urun Dana Berbasis Teknologi Informasi.',
            },
            {
                content:
                    'Penyelenggara adalah PT Moneypool merupakan pihak yang menyediakan,mengelola, dan mengoperasikan Layanan penawaran efek melalui Urun Dana.',
            },
            {
                content: 'Pengguna adalah Penerbit dan Pemodal.',
            },
            {
                content:
                    'Penerbit adalah badan usaha berbentuk perseroan terbatas, merupakan pihak yang melaksanakan Layanan penawaran efek melalui Urun Dana menawarkan Saham berbasis teknologi melalui Penyelenggara.',
            },
            {
                content: 'Pemodal adalah pihak yang melakukan pembelian Saham Penerbit melalui Penyelenggara.',
            },
            {
                content:
                    'POJK Layanan penawaran efek melalui Urun Dana adalah Peraturan Otoritas Jasa Keuangan Nomor :57/POJK.04/2020 tanggal 10 Desember 2020, beserta perubahan-perubahannya.',
            },
            {
                content:
                    'Efek adalah surat berharga, yaitu surat pengakuan utang, surat berharga komersial, saham, obligasi, tanda bukti utang, unit penyertaan kontrak investasi kolektif, kontrak berjangka atas efek, dan setiap derivative dari efek.',
            },
            {
                content:
                    'EBUS Adalah Efek bersifat utang dan/atau Sukuk yang diterbitkan tanpa melalui mekanisme Penawaran Umum dan memenuhi kriteria yang telah ditetapkan sesuai dengan POJK Nomor 30/POJK.04/2019.',
            },
            {
                content:
                    'Buyback adalah proses dimana Penerbit melakukan pembelian kembali Sahamyang telah dijual oleh Penerbit kepada Pemodal.',
            },
            {
                content:
                    'Hari Kalender adalah hari Senin sampai dengan hari Minggu, termasuk hari libur Nasional yang ditetapkan Pemerintah Indonesia sebagaimana perhitungan tahun kalender Masehi.',
            },
            {
                content: 'Hari Kerja adalah hari kerja dimana Penyelenggara beroperasi.',
            },
        ],
        title: 'DEFINISI',
    },
    {
        description:
            'Pemodal dengan ini mengajukan pendaftaran kepada Penyelenggara dan Penyelenggara menerima pendaftaran dari Pemodal sebagai Anggota dalam rangka untuk melaksanakan pembelian Saham milik Penerbit melalui program Layanan penawaran efek melalui Urun Dana dengan maksud dan tujuan, lingkup Layanan penawaran efek melalui Urun Dana, syarat dan ketentuan, serta batas tanggung jawab sesuai dengan POJK Penawaran Efek Melalui Layanan penawaran efek melalui Urun Dana Berbasis Teknologi Informasi.',
        title: 'PELAKSANAAN LAYANAN PENAWARAN EFEK MELALUI URUN DANA',
    },
    {
        list: [
            {
                content:
                    'Dalam hal ini Pemodal melakukan pembelian Efek Penerbit selama masa penawaran Efek oleh Penerbit yang dilakukan paling lama 45 (empat puluh lima) Hari Kalender.',
            },
            {
                content:
                    'Pemodal mengerti dan memahami bahwa Penerbit dapat membatalkan penawaran Efek melalui Layanan penawaran efek melalui Urun Dana sebelum berakhirnya masa penawaran Efek dengan membayar sejumlah denda kepada Penyelenggara.',
            },
        ],
        title: 'MASA PENAWARAN EFEK',
    },
    {
        list: [
            {
                content:
                    'Pembelian efek oleh Pemodal dalam penawaran Saham melalui Layanan penawaran efek melalui Urun Dana dilakukan dengan menyetorkan sejumlah dana pada escrow account.',
            },
            {
                content:
                    'Batasan pembelian efek oleh Pemodal dalam Layanan penawaran efek melalui Urun Dana adalah sebagai berikut :',
                sublist: {
                    items: [
                        {
                            content:
                                'setiap Pemodal dengan penghasilan sampai dengan Rp500.000.000,00 (lima ratus juta rupiah) per tahun, dapat membeli efek melalui Layanan penawaran efek melalui Urun Dana paling banyak sebesar 5% (lima persen) dari penghasilan per tahun; dan',
                        },
                        {
                            content:
                                'setiap Pemodal dengan penghasilan lebih dari Rp500.000.000,00 (lima ratus juta rupiah) per tahun, dapat membeli efek melalui Layanan penawaran efek melalui Urun Dana paling banyak sebesar 10% (sepuluh persen) dari penghasilan per tahun.',
                        },
                    ],
                },
            },
            {
                content:
                    'Batasan pembelian efek oleh Pemodal tidak berlaku dalam hal Pemodal merupakan badan hukum; dan pihak yang mempunyai pengalaman berinvestasi di Pasar Modal yang dibuktikan dengan kepemilikan rekening efek paling sedikit 2 (dua) tahun sebelum penawaran Saham.',
            },
            {
                content:
                    'Batasan Dalam hal Efek yang diterbitkan melalui Layanan penawaran efek melalui Urun Dana merupakan Efek bersifat utang atau Sukuk dijamin atau ditanggung dengan nilai penjaminan atau nilai penanggungan paling sedikit 125% (seratus dua puluh lima persen) dari nilai penghimpunan dana, kriteria Pemodal dan batasan pembelian Efek sebagaimana dimaksud dalam BAB IV Pasal 2 tidak berlaku.',
            },
        ],
        title: 'PEMBELIAN EFEK',
    },
    {
        list: [
            {
                content:
                    'Pemodal mengerti dan memahami bahwa Penyelenggara wajib menyerahkan dana dari Pemodal kepada Penerbit melalui Penyelenggara, paling lambat 21 (dua puluh satu) Hari Kerja setelah berakhirnya masa penawaran Saham.',
            },
            {
                content: 'Manfaat bersih dari penempatan dana dikembalikan kepada Pemodal secara proporsional.',
            },
            {
                content: 'Berakhirnya masa penawaran adalah:',
                sublist: {
                    items: [
                        {
                            content: 'Tanggal tertentu yang telah ditetapkan dan disepakati oleh Para Pihak; atau',
                        },
                        {
                            content:
                                'tanggal tertentu berakhirnya masa penawaran Saham namun seluruh Saham yang ditawarkan melalui Layanan penawaran efek melalui Urun Dana telah dibeli oleh Pemodal.',
                        },
                    ],
                },
            },
            {
                content:
                    'Pemodal mengerti dan memahami bahwa Penerbit wajib menyerahkan Sahamkepada Penyelenggara untuk didistribusikan kepada Pemodal paling lambat 5 (lima) Hari Kerja setelah Penerbit menerima dana Pemodal dari Penyelenggara.',
            },
            {
                content:
                    'Pemodal mengerti dan memahami bahwa Penyelenggara wajib mendistribusikan Saham kepada Pemodal paling lambat 10 (sepuluh) Hari Kerja setelah menerima Saham dari Penerbit.',
            },
            {
                content:
                    'Pendistribusian Saham kepada Pemodal oleh Penyelenggara dapat dilakukan secara elektronik melalui penitipan kolektif pada kustodian atau pendistribusian secara fisik melalui pengiriman sertifikat Saham.',
            },
            {
                content:
                    'Pemodal mengerti dan memahami bahwa Penerbit diwajibkan menetapkan jumlah minimum dana yang harus diperoleh dalam penawaran Saham melalui Layanan penawaran efek melalui Urun Dana, dan apabila jumlah minimum dana yang telah ditentukan oleh Penerbit tersebut tidak terpenuhi, maka penawaran Saham melalui Layanan penawaran efek melalui Urun Dana tersebut dinyatakan batal demi hukum.',
            },
            {
                content:
                    'Pemodal mengerti dan memahami bahwa dalam hal penawaran Saham batal demi hukum, maka Penyelenggara wajib mengembalikan dana beserta seluruh manfaat yang timbul dari dana tersebut ke dalam saldo deposit Pemodal diplatform Penyelenggara secara proporsional kepada Pemodal paling lambat 2(dua) Hari Kerja setelah penawaran Saham dinyatakan batal demi hukum.',
            },
            {
                content:
                    'Bagi Pemodal yang transaksinya tidak valid atau valid sebagian, maka Moneypool akan menghubungi Pemodal untuk melakukan konfirmasi. Apabila Pemodal tidak melakukan konfirmasi balik selama 5 (lima) Hari Kerja kepada Penyelenggara, maka transaksi Pemodal tersebut dimasukkan ke dalam saldo deposit Pemodal di platform Penyelenggara yang sewaktu-waktu dapat ditarik oleh Pemodal.',
            },
            {
                content:
                    'Dalam hal transaksi pembelian Saham Pemodal dilakukan pada saat Saham telah dinyatakan habis/soldout, maka Pemodal berhak atas pengembalian pembelian Saham dengan melakukan konfirmasi kepada Penyelenggara melalui media komunikasi yang telah disediakan oleh Penyelenggara. Pengembalian pembayaran pembelian Saham tersebut akan masuk ke dalam saldo deposit Pemodal di platform Penyelenggara yang sewaktu-waktu dapat ditarik oleh Pemodal.',
            },
            {
                content:
                    'Pemodal dapat membatalkan rencana pembelian Saham melalui Layanan penawaran efek melalui Urun Dana paling lambat dalam waktu 48 (empat puluh delapan) jam setelah melakukan pembelian Saham. Dalam hal Pemodal membatalkan rencana pembelian Saham, Penyelenggara wajib mengembalikan dana kepada Pemodal selambatnya 2 (dua) Hari Kerja setelah pembatalan pemesanan Pemodal. Pengembalian tersebut akan masuk ke dalam menu deposit didalam website penyelenggara yang sewaktu-waktu dapat ditarik oleh Pemodal.',
            },
        ],
        title: 'PENYERAHAN DANA DAN EFEK',
    },
    {
        list: [
            {
                content:
                    'Pemodal mengerti dan memahami bahwa Penerbit wajib mencatatkan kepemilikan Saham Pemodal dalam daftar pemegang Saham.',
            },
            {
                content:
                    'Persetujuan Pemodal terhadap terms and conditions ini berarti Pemodal setujudan sepakat bahwa Pemodal memberikan kuasa kepada Penyelenggara untuk mewakili Pemodal sebagai pemegang Saham Penerbit termasuk dalam Rapat Umum Pemegang Saham (“RUPS”) Penerbit dan penandatanganan akta serta dokumen terkait lainnya',
            },
        ],
        title: 'DAFTAR PEMEGANG SAHAM',
    },
    {
        list: [
            {
                content:
                    'Pemodal mengerti dan memahami bahwa pembagian dividen kepada para pemegang Saham tidak bersifat lifetime karena Penerbit merupakan badanusaha berbadan hukum berhak melakukan Buyback sebagaimana diatur dalam akta anggaran dasar Penerbit dan peraturan perundang-undanganyang berlaku.',
            },
            {
                content:
                    'Pemodal mengerti dan memahami bahwa pembagian dividen Penerbit diinformasikan di dalam kebijakan dividen dan didasarkan pada laba bersihPenerbit setelah dikurangi dengan pencadangan. Mekanisme pembagiandividen lainnya (termasuk pembagian dividen interim) mengacu pada anggaran dasar Penerbit dan peraturan perundang-undangan yang berlaku.',
            },
            {
                content:
                    'Pemodal mengerti dan memahami bahwa pembagian dividen final Penerbit mengacu pada persetujuan Rapat Umum Pemegang Saham (“RUPS”) Penerbit.',
            },
            {
                content:
                    'Pemodal mengerti dan memahami bahwa apabila terdapat beban operasional usaha yang harus dikeluarkan setiap periode tertentu, Penerbit tidak memiliki hak untuk membebankannya kepada Pemodal, melainkan beban tersebut dimasukkan ke dalam penghitungan biaya operasional yang kemudian dilaporkan dalam laporan keuangan periode tersebut.',
            },
            {
                content:
                    'Pemodal mengerti dan memahami bahwa dapat dilunasi lebih awal sebelum jatuh tempo, sepanjang mendapat persetujuan dari mayoritas pemegang Efekbersifat utang atau Sukuk yang hadir dalam rapat umum pemegang Efek bersifat utang atau Sukuk',
            },
            {
                content:
                    'Pemodal mengerti dan memahami bahwa pembayaran pokok, bunga, besaran nisbah bagi hasil, margin, imbal jasa, atau imbal hasil dapat dilakukan secara berkala atau pada saat jatuh tempo.',
            },
        ],
        title: 'PEMBAGIAN DIVIDEN ATAU BUNGA EBUS',
    },
    {
        description:
            'Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam Perjanjian ini, maka kewajiban Pemodal adalah sebagai berikut:',
        list: [
            {
                content:
                    'Pemodal wajib menjaga nama baik dan reputasi Penyelenggara dengan tidak melakukan aktifitas yang mengandung unsur suku, agama dan ras, atau tidak melakukan penyebaran informasi yang tidak benar dengan mengatasnamakan Penyelenggara.',
            },
            {
                content:
                    'Pemodal wajib tunduk dan patuh pada ketentuan terms and conditions yang tercantum dalam website Penyelenggara serta tunduk dan patuh pada POJK Layanan Penawaran Efek melalui Urun Dana dan peraturan perundang-undangan yang berlaku di Negara Republik Indonesia.',
            },
            {
                content:
                    'Pemodal wajib setuju dan sepakat bersedia untuk memberikan kuasa kepada Penerbit untuk menjadi wali amanat yang mewakili Pemodal terkait EBUS',
            },
            {
                content:
                    'Pemodal wajib setuju dan sepakat bersedia untuk memberikan akses auditinternal maupun audit eksternal yang ditunjuk Penyelenggara serta auditOtoritas Jasa Keuangan (OJK) atau regulator berwenang lainnya setiap kali dibutuhkan terkait pelaksanaan Layanan Penawaran Efek berbasis Urun Dana ini.',
            },
        ],
        title: 'KEWAJIBAN PEMODAL',
    },
    {
        description:
            'Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam Perjanjian ini, maka hak Pemodal adalah sebagai berikut:',
        list: [
            {
                content:
                    'Pemodal berhak untuk melakukan pembelian Saham yang ditawarkan Penerbit melalui Layanan penawaran efek melalui Urun Dana yang diselenggarakan Penyelenggara.',
            },
            {
                content:
                    'Pemodal berhak mendapat manfaat atas pembagian dividen yang dilakukan oleh Penerbit melalui Penyelenggara.',
            },
        ],
        title: 'HAK PEMODAL',
    },
    {
        description:
            'Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam Perjanjian ini, maka kewajiban Penyelenggara adalah sebagai berikut:',
        list: [
            {
                content: 'Penyelenggara wajib memenuhi seluruh hak-hak Pemodal',
            },
            {
                content:
                    'Penyelenggara memonitor, menganalisa, dan memastikan bahwa Pengguna berada di jalur yang sesuai dengan visi misi Penyelenggara dan Layanan Penawaran Efek melalui Urun Dana.',
            },
            {
                content:
                    'Penyelenggara bertanggung jawab melakukan ganti rugi atas setiap kerugian Pemodal yang timbul disebabkan oleh kelalaian karyawan ataupun direksi Penyelenggara',
            },
        ],
        title: 'KEWAJIBAN PENYELENGGARA',
    },
    {
        description: (
            <Fragment>
                Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam Perjanjian ini, maka
                hak Penyelenggara adalah :
                <br />
                Penyelenggara berhak atas manfaat dari Pemodal atas Layanan Penawaran Efek melalui Urun Dana yang sedang
                berlangsung.
            </Fragment>
        ),
        title: 'HAK PENYELENGGARA',
    },
    {
        description:
            'Pembebanan pajak yang timbul dalam Layanan penawaran efek melalui Urun Dana ini menjadi beban masing-masing pihak sesuai dengan ketentuan hukum perpajakkan yang berlaku di wilayah Negara Republik Indonesia.',
        title: 'PERPAJAKAN',
    },
    {
        list: [
            {
                content:
                    'Hak atas kekayaan intelektual yang timbul atas pelaksanaan Layanan Penawaran Efek melalui Urun Dana dan izin Penyelenggara, beserta fasilitas-fasilitas lain yang dimiliki Penyelenggara dan digunakan dalam Layanan penawaran efek melalui Urun Dana ini adalah tetap dan seterusnya milik Penyelenggara dan tidak ada penyerahan hak dari Penyelenggara kepada Pemodal dalam Layanan penawaran efek melalui Urun Dana ini.',
            },
            {
                content:
                    'Pemodal tidak berhak untuk mengubah, mengembangkan, membagikan dan/atau menjual baik seluruh maupun sebagian hak atas kekayaan intelektual yang timbul atas pengembangan, inovasi, perubahan berupa fitur dan/atau fungsi terhadap sistem teknologi informasi.',
            },
            {
                content:
                    'Penyelenggara dengan ini menjamin bahwa hak atas kekayaan intelektual yang terkandung dalam pelaksanaan Layanan penawaran efek melalui Urun Dana ini tidak melanggar hak atas kekayaan intelektual milik pihak manapun, dan Penyelenggaran membebaskan Pemodal dari segala tuntutan, gugatan dari pihak manapun, sehubungan dengan pelanggaran terhadap hak atas kekayaan intelektual yang terkandung dalam Layanan Penawaran Efek melalui Urun Dana sesuai dengan terms and conditions ini.',
            },
        ],
        title: 'HAK ATAS KEKAYAAN INTELEKTUAL',
    },
    {
        list: [
            {
                content:
                    'Jangka waktu Layanan Penawaran Efek melalui Urun Dana antara Penyelenggara dan Pemodal ini berlaku selama Penerbit turut serta dalam Layanan penawaran efek melalui Urun Dana.',
            },
            {
                content: 'Layanan penawaran efek melalui Urun Dana ini berakhir dengan sendirinya, apabila :',
                sublist: {
                    items: [
                        {
                            content: 'Penerbit melakukan Buyback Saham',
                        },
                        {
                            content: 'Diakhiri oleh Penyelenggara.',
                        },
                    ],
                },
            },
            {
                content:
                    'Dalam hal Layanan penawaran efek melalui Urun Dana ini berakhir dan/atau dinyatakan berakhir, maka Para Pihak sepakat bahwa ketentuan Informasi Rahasia sebagaimana diatur dalam terms and conditions ini tetap berlaku dan mengikat Para Pihak hingga kapanpun meskipun Layanan Penawaran Efek melalui Urun Dana telah berakhir.',
            },
            {
                content:
                    'Pengakhiran/pembatalan Layanan penawaran efek melalui Urun Dana ini tidak menghapuskan kewajiban-kewajiban masing-masing Pihak yang telah atau akan timbul dan belum dilaksanakan pada saat berakhirnya Layanan penawaran efek melalui Urun Dana ini.',
            },
            {
                content:
                    'Dalam hal pengakhiran/pembatalan Layanan penawaran efek melalui Urun Dana ini, Para Pihak sepakat untuk mengesampingkan keberlakuan ketentuan Pasal 1266 Kitab Undang-Undang Hukum Perdata, sepanjang ketentuan tersebut mensyaratkan adanya suatu putusan atau penetapan pengadilan untuk menghentikan atau mengakhiri suatu perjanjian, sehingga pengakhiran/pembatalan Layanan Penawaran Efek melalui Urun Dana ini cukup dilakukan dengan pemberitahuan tertulis dari salah satu Pihak.',
            },
        ],
        title: 'JANGKA WAKTU DAN PENGAKHIRAN',
    },
    {
        list: [
            {
                content:
                    'Salah satu Pihak (selanjutnya disebut “ Pihak Pemberi ”) dapat memberikanInformasi Rahasia kepada Pihak lainnya (selanjutnya disebut “Pihak Penerima”) dalam melaksanakan Layanan penawaran efek melalui Urun Dana ini. Para Pihak sepakat bahwa pemberian, penerimaan dan penggunaan Informasi Rahasia tersebut dilakukan sesuai dengan terms and conditions ini.',
            },
            {
                content:
                    'Informasi Rahasia yang dimaksud dalam ketentuan ini berarti informasi yang bersifat non-publik, termasuk namun tidak terbatas pada skema atau gambar produk, penjelasan material, spesifikasi, laporan keuangan dan informasi mengenai klien, kebijaksanaan dan praktek bisnis Pihak Pemberi dan informasimana dapat dimuat dalam media cetak, tulis, disk / tape / compact disk komputer atau media lainnya yang sesuai.',
            },
            {
                content:
                    'Tidak termasuk sebagai Informasi Rahasia adalah materi atau informasi yang mana dapat dibuktikan oleh Pihak Penerima bahwa:',
                sublist: {
                    items: [
                        {
                            content:
                                'Pada saat penerimaannya sebagai milik publik ( public domain ) atau menjadi milik publik ( public domain ) atau menjadi milik publik ( public domain ) tanpa adanya pelanggaran oleh Pihak Penerima;',
                        },
                        {
                            content: 'Telah diketahui oleh Pihak Penerima pada saat diberikan oleh Pihak Pemberi;',
                        },
                        {
                            content:
                                'Telah didapatkan dari pihak ketiga tanpa adanya pelanggaran terhadap pengungkapan Informasi Rahasia;',
                        },
                        {
                            content: 'Dikembangkan sendiri oleh Pihak Penerima.',
                        },
                    ],
                },
            },
            {
                content:
                    'Pihak Penerima dengan ini menyatakan bahwa tidak akan mengungkapkan Informasi Rahasia apapun yang diberikan Pihak Pemberi ke pihak lainnya selain daripada yang diperlukan dalam melaksanakan tugas, peran dan kewajibannya dalam Layanan penawaran efek melalui Urun Dana ini, tanpa terlebih dahulu memperoleh persetujuan dari Pihak Pemberi dan Pihak Penerima akan melakukan semua tindakan-tindakan pencegahan yang wajar untuk mencegah terjadinya pelanggaran atau kelalaian dalam pengungkapan, penggunaan, pembuatan salinan ( copy ) atau pengalihan Informasi Rahasia tersebut.',
            },
            {
                content:
                    'Masing-masing Pihak berkewajiban untuk menyimpan segala rahasia data atau sistem yang diketahuinya baik secara langsung maupun tidak langsung sehubungan Layanan penawaran efek melalui Urun Dana yang dilaksanakan sesuai dengan terms and conditions ini dan bertanggung jawab atas segala kerugian yang diakibatkan karena pembocoran Informasi Rahasia tersebut, baik oleh masing-masing Pihak maupun karyawannya maupun perwakilannya.',
            },
        ],
        title: 'INFORMASI RAHASIA',
    },
    {
        list: [
            {
                content:
                    'Tanpa mengesampingkan pernyataan dan jaminan yang diberikan oleh salah satu Pihak ke Pihak lainnya dalam Layanan penawaran efek melalui Urun Dana ini, masing-masing Pihak dengan ini menyatakan dan menjamin Pihak lainnya dalam Layanan Penawaran Efek melalui Urun Dana hal-hal sebagai berikut:',
                sublist: {
                    items: [
                        {
                            content:
                                'Bahwa berdasarkan hukum negara Republik Indonesia, masing-masing Pihak cakap menurut hukum untuk memiliki harta kekayaan dan melakukan perbuatan hukum usahanya di wilayah Republik Indonesia serta memiliki segala perizinan yang diperlukan untuk menjalankan perbuatan hukum.;',
                        },
                        {
                            content:
                                'Bahwa masing-masing Pihak telah mengambil segala tindakan yang diperlukan untuk memastikan wakil dari masing-masing Pihak dalam Layanan penawaran efek melalui Urun Dana telah memiliki kuasa dan wewenang penuh untuk mengikatkan diri dalam persetujuan terms and conditions ini;',
                        },
                        {
                            content:
                                'Bahwa masing-masing Pihak telah memastikan bahwa Layanan penawaran efek melalui Urun Dana ini tidak melanggar ketentuan dari anggaran dasar masing-masing Pihak dalam Layanan penawaran efek melalui Urun Dana dan tidak bertentangan dengan perjanjian apapun yang dibuat oleh masing-masing Pihak dengan pihak ketiga;',
                        },
                        {
                            content:
                                'Pelaksanaan ketentuan-ketentuan dalam Layanan penawaran efek melalui Urun Dana ini dilaksanakan secara profesional dengan penuh tanggung jawab dan atas dasar hubungan yang saling menguntungkan.',
                        },
                        {
                            content:
                                'Persetujuan terms and conditions ini tidak bertentangan atau melanggaratau berbenturan dengan kaidah-kaidah hukum dan peraturan perundangundangan serta kebijakan-kebijakan pemerintah Republik Indonesia atau pihak yang berwenang lainnya;',
                        },
                        {
                            content:
                                'Bersedia untuk menerapkan, mendukung dan mematuhi ketentuan hukum dan peraturan perundang-undangan yang berlaku di Republik Indonesia termasuk namun tidak terbatas pada peraturan mengenai tindak pidana korupsi, anti pencucian uang dan anti penyuapan;',
                        },
                        {
                            content:
                                'Masing-masing Pihak akan melaksanakan Layanan penawaran efek melalui Urun Dana ini dengan itikad baik dan secara jujur. Tidak satupun ketentuan dalam Layanan Urun Dana ini akan digunakan oleh salah satu Pihak untuk mengambil keuntungan secara tidak wajar dan mengakibatkan kerugian bagi Pihak lainnya dan tidak satupun ketentuan dalam Layanan penawaran efek melalui Urun Dana ini dimaksudkan untuk memberikan keuntungan secara tidak wajar kepada Pihak lainnya.',
                        },
                    ],
                },
            },
            {
                content:
                    'Tanpa mengesampingkan pernyataan dan jaminan yang diberikan oleh Penerbit ke Penyelenggara, Pemodal dengan ini menyatakan dan menjamin kepada Penyelenggara hal-hal sebagai berikut:',
                sublist: {
                    items: [
                        {
                            content:
                                'Bahwa Pemodal akan membebaskan Penyelenggara dari klaim, tuntutan, gugatan dari pihak ketiga atas kelalaian Pemodal dan/atau karyawan Pemodal dalam melaksanakan Layanan penawaran efek melalui Urun Dana ini;',
                        },
                        {
                            content:
                                'Bahwa Pemodal menyatakan tidak berkeberatan dalam hal Otoritas Jasa Keuangan dan/atau pihak lain yang sesuai undang-undang berwenang untuk melakukan pemeriksaan, akan melakukan pemeriksaan terhadap pelaksanaan Layanan penawaran efek melalui Urun Dana ini.',
                        },
                        {
                            content:
                                'Bahwa Pemodal bersedia untuk kemungkinan pengubahan, pembuatan atau pengambil alih kegiatan yang dilaksanakan oleh Pemodal atau penghentian Layanan penawaran efek melalui Urun Dana, dalam hal atas permintaan Otoritas Jasa Keuangan apabila diperlukan.',
                        },
                    ],
                },
            },
        ],
        title: 'PERNYATAAN DAN JAMINAN',
    },
    {
        list: [
            {
                content:
                    'Keadaan Memaksa atau Force Majeure adalah kejadian-kejadian yang terjadi diluar kemampuan dan kekuasaan Para Pihak sehingga menghalangi Para Pihak untuk melaksanakan Layanan penawaran efek melalui Urun Dana ini, termasuk namun tidak terbatas pada adanya kebakaran, banjir, gempa bumi,likuifaksi, badai, huruhara, peperangan, epidemi, pertempuran, pemogokan, sabotase, embargo, peledakan yang mengakibatkan kerusakan sistem teknologi informasi yang menghambat pelaksanaan Layanan penawaran efek melalui Urun Dana ini, serta kebijaksanaan pemerintah Republik Indonesia yang secara langsung berpengaruh terhadap pelaksanaan Layanan penawaran efek melalui Urun Dana ini.',
            },
            {
                content:
                    'Masing-masing Pihak dibebaskan untuk membayar denda apabila terlambat dalam melaksanakan kewajibannya dalam Layanan penawaran efek melalui Urun Dana ini, karena adanya hal-hal Keadaan Memaksa.',
            },
            {
                content:
                    'Keadaan Memaksa sebagaimana dimaksud harus diberitahukan oleh Pihak yang mengalami Keadaan Memaksa kepada Pihak lainnya dalam Layanan Urun Dana ini paling lambat 7 (tujuh) Hari Kalender dengan melampirkan pernyataan atau keterangan tertulis dari pemerintah untuk dipertimbangkan oleh Pihak lainnya beserta rencana pemenuhan kewajiban yang tertunda akibat terjadinya Keadaan Memaksa.',
            },
            {
                content:
                    'Keadaan Memaksa yang menyebabkan keterlambatan pelaksanaan Layanan Urun Dana ini baik untuk seluruhnya maupun sebagian bukan merupakan alasan untuk pembatalan Layanan penawaran efek melalui Urun Dana ini sampai dengan diatasinya Keadaan Memaksa tersebut.',
            },
        ],
        title: 'KEADAAN MEMAKSA (FORCE MAJEURE)',
    },
    {
        list: [
            {
                content:
                    'Pemodal setuju dan sepakat untuk tidak mengalihkan sebagian atau keseluruhan hak dan kewajiban Penerbit dalam Layanan penawaran efek melalui Urun Dana ini kepada pihak lainnya atau pihak manapun.',
            },
            {
                content:
                    'Dalam hal adanya permintaan peralihan atas hak kepemilikan Saham dikarenakan Pemodal meninggal dunia, maka ahli waris mengajukan permohonan perubahan kepemilikan Saham kepada Penyelenggara dengan melengkapi dokumen sebagai sebagai berikut :',
                sublist: {
                    items: [
                        {
                            content:
                                'Surat permohonan peralihan kepemilikan Saham dikarenakan Pemodal meninggal dunia;',
                        },
                        {
                            content: 'Softcopy surat kematian dari instansi berwenang;',
                        },
                        {
                            content:
                                'Softcopy surat keterangan ahli waris dari instansi berwenang dan/atau surat penetapan pengadilan tentang ahli waris;',
                        },
                        {
                            content: 'Softcopy E-KTP Pemodal (almarhum/almarhumah) dan ahli waris',
                        },
                        {
                            content: 'Softcopy Kartu Keluarga (KK) Pemodal (almarhum/almarhumah)',
                        },
                        {
                            content:
                                'Surat Penunjukan dan/atau Surat Kuasa dari ahli waris (apabila ahli waris lebih dari satu) untuk menunjuk dan/atau menguasakan peralihan kepemilikan Saham kepada salah satu ahli waris.',
                        },
                    ],
                },
            },
        ],
        title: 'PENGALIHAN LAYANAN PENAWARAN EFEK MELALUI URUN DANA',
    },
    {
        list: [
            {
                content:
                    'Layanan penawaran efek melalui Urun Dana ini dibuat, ditafsirkan dan dilaksanakan berdasarkan hukum negara Republik Indonesia.',
            },
            {
                content:
                    'Setiap perselisihan yang timbul sehubungan dengan Layanan penawaran efek melalui Urun Dana ini, akan diupayakan untuk diselesaikan terlebih dahulu oleh Para Pihak dengan melaksanakan musyawarah untuk mufakat.',
            },
            {
                content:
                    'Apabila penyelesaian perselisihan secara musyawarah tidak berhasil mencapai mufakat sampai dengan 30 (tiga puluh) Hari Kalender sejak dimulainya musyawarah tersebut, maka Para Pihak sepakat untuk menyelesaikan perselisihan tersebut melalui proses pengadilan.',
            },
            {
                content:
                    'Para Pihak sepakat untuk menyelesaikan perselisihan di Pengadilan Negeri Jakarta Pusat di Daerah Khusus Ibukota Jakarta tanpa mengurangi hak dari salah satu untuk mengajukan gugatan pada domisili pengadilan lainnya ( nonexlusive jurisdiction ).',
            },
            {
                content:
                    'Tanpa mengesampingkan penyelesaian sengketa atau perselisihan melalui pengadilan negeri, Para Pihak setuju dan sepakat apabila penyelesaian sengketa atau perselisihan di badan arbitrase dan badan alternative penyelesaian sengketa yang ditunjuk oleh Otoritas Jasa Keuangan maupun regulator berwenang lainnya.',
            },
            {
                content:
                    'Hasil putusan pengadilan negeri maupun badan arbitrase dan badan alternative penyelesaian sengketa yang ditunjuk oleh Otoritas Jasa Keuangan maupun regulator berwenang lainnya bersifat final dan mempunyai kekuatan hukum tetap dan mengikat bagi Para Pihak.',
            },
        ],
        title: 'DOMISILI HUKUM DAN PENYELESAIAN SENGKETA',
    },
    {
        list: [
            {
                content:
                    'Dalam hal terjadi salah satu hal atau peristiwa yang ditetapkan di bawah ini, maka merupakan suatu kejadian kelalaian (wanprestasi) terhadap Layanan Urun Dana ini:',
                sublist: {
                    isUnordered: true,
                    items: [
                        {
                            content:
                                'Kelalaian dalam Layanan penawaran efek melalui Urun Dana Dalam hal salah satu Pihak terbukti sama sekali tidak melaksanakan kewajiban, atau melaksanakan kewajiban tetapi tidak sebagaimana disepakati, atau melaksanakan kewajiban tetapi tidak sesuai dengan waktu yang disepakati, atau melakukan sesuatu yang tidak diperbolehkan dalam terms and conditions.',
                        },
                        {
                            content:
                                'Pernyataan Tidak Benar Dalam hal ternyata bahwa sesuatu pernyataan atau jaminan yang diberikan oleh salah satu Pihak kepada Pihak lainnya dalam Layanan Urun Dana ini terbukti tidak benar atau tidak sesuai dengan kenyataannya dan menimbulkan kerugian langsung yang diderita salah satu Pihak.',
                        },
                        {
                            content:
                                'Kepailitan Dalam hal salah satu Pihak dalam Layanan penawaran efek melalui Urun Dana ini oleh instansi yang berwenang dinyatakan berada dalam keadaan pailit atau diberikan penundaan membayar hutang-hutang ( surseance van betaling ).',
                        },
                        {
                            content:
                                'Permohonan Kepailitan. Dalam hal salah satu Pihak dalam Layanan penawaran efek melalui Urun Dana ini mengajukan permohonan kepada instansi yang berwenang untuk dinyatakan pailit atau untuk diberikan penundaan membayar hutang-hutang ( surseance van betaling ) atau dalam hal pihak lain mengajukan permohonan kepada instansi yang berwenang agar salah satu Pihak dalam Layanan Urun Dana ini dinyatakan dalam keadaan pailit.',
                        },
                    ],
                },
            },
            {
                content:
                    'Dalam hal suatu kejadian kelalaian terjadi dan berlangsung, maka Pihak yang tidak lalai berhak menyampaikan peringatan sebanyak 3 (tiga) kali dengan tenggang waktu 7 (tujuh) Hari Kalender diantara masing-masing peringatan.',
            },
            {
                content:
                    'Setelah menyampaikan 3 (tiga) kali peringatan, Pihak yang tidak lalai berhak mengajukan tuntutan berupa meminta pemenuhan prestasi dilakukan atau meminta prestasi dilakukan disertai ganti kerugian atau meminta ganti kerugian saja atau menuntut pembatalan Layanan penawaran efek melalui Urun Dana disertai ganti kerugian.',
            },
        ],
        title: 'KELALAIAN / WANPRESTASI',
    },
    {
        description:
            'Apabila dalam Layanan penawaran efek melalui Urun Dana ini, Pemodal melanggar ketentuan dalam Layanan Urun Dana ini maka Penyelenggara berhak menon-aktifkan atau membekukan akun Pemodal, bahkan pengakhiran Layanan penawaran efek melalui Urun Dana Pemodal oleh Penyelenggara dalam Layanan penawaran efek melalui Urun Dana ini.',
        title: 'PENALTI',
    },
    {
        description:
            'Mekanisme penyelesaian Layanan penawaran efek melalui Urun Dana dalam hal Penyelenggara tidak dapat menjalankan operasional adalah sebagai berikut :',
        list: [
            {
                content:
                    'Penyelenggara melakukan pemberitahuan atau pengumuman secara tertulis di website Penyelenggara dan media sosial lainnya kepada seluruh Pengguna atau khalayak umum bahwa Penyelenggara tidak dapat memberitahukan operasionalnya dengan mencantumkan alasan jelas;',
            },
            {
                content:
                    'Bahwa pengaturan tata cara Buyback mengacu pada akta anggaran dasar Penerbit dan undang-undang dasar tentang perseroan terbatas yang berlaku di Negara Republik Indonesia;',
            },
            {
                content:
                    'Buyback seluruh Saham yang dimiliki Pemodal dengan harga pasar atau disepakati secara tertulis oleh Para Pihak di kemudian hari.',
            },
            {
                content:
                    'Menunjuk penyelenggara lain yang telah mendapat izin dari Otoritas Jasa Keuangan seperti Penyelenggara, dengan syarat dan ketentuan yang sudah disepakati bersama dengan Pemodal.',
            },
        ],
        title: 'MEKANISME DALAM HAL PENYELENGGARA TIDAK DAPAT MENJALANKAN OPERASIONALNYA',
    },
    {
        list: [
            {
                content:
                    'Penyelenggara bertindak sebagi penyelenggara Layanan penawaran efek melalui Urun Dana, bukan sebagai pihak yang menjalankan bisnis (Penerbit).',
            },
            {
                content:
                    'Semua data dan informasi yang tersaji di website, aplikasi dan prospectus diperoleh Penyelenggara dari Penerbit dan media online.',
            },
            {
                content:
                    'Keputusan pembelian Saham, sepenuhnya merupakan hak dan pilihan Pemodal, sehingga segala risiko dan konsekuensi atas pembelian Saham merupakan tanggung jawab dan risiko Pemodal sepenuhnya.',
            },
            {
                content:
                    'Dengan membeli Saham di Penyelenggara, berarti Pemodal dianggap telah membaca, memahami dan menyetujui seluruh syarat dan ketentuan serta memahami risiko investasi termasuk risiko kehilangan atau seluruh modal.',
            },
        ],
        title: 'DISCLAIMER',
    },
    {
        list: [
            {
                content:
                    'Para Pihak wajib tunduk dan patuh terhadap peraturan perundang-undangan yang berlaku di negara Republik Indonesia terkait pelaksanaan Layanan Penawaran Efek melalui Urun Dana ini.',
            },
            {
                content:
                    'Layanan penawaran efek melalui Urun Dana ini diinterpretasikan dan dilaksanakan	berdasarkan hukum yang berlaku di Negara Republik Indonesia.',
            },
            {
                content:
                    'Keabsahan, penafsiran dan pelaksanaan Layanan penawaran efek melalui Urun Dana ini, diatur serta tunduk pada hukum dan peraturan perundang-undangan yang berlaku di negara Republik Indonesia;',
            },
            {
                content:
                    'Dalam hal ada salah satu atau lebih ketentuan dalam terms and conditions ini dinyatakan tidak sah, tidak berlaku atau tidak dapat dilaksanakan berdasarkan hukum dan/atau peraturan perundang-undangan yang berlaku di Republik Indonesia, maka kedua belah pihak setuju bahwa keabsahan ketentuan lainnya dalam terms and conditions ini tetap berlaku dan dapat dilaksanakan serta tidak akan terpengaruh;',
            },
            {
                content:
                    'Penyelenggara berhak untuk mengubah terms and conditions ini sewaktu waktu dalam rangka penyempurnaan dan penyesuaian dengan ketentuan hukum yang berlaku di Negara Republik Indonesia',
            },
            {
                content:
                    'Dokumen-dokumen atau kesepakatan-kesepakatan terkait dengan Layanan Urun Dana ini yang telah dibuat oleh Para Pihak sebelum disetujuinya terms and conditions ini dinyatakan dicabut dan tidak berlaku terhitung sejak disetujuinya terms and conditions.',
            },
        ],
        title: 'LAIN-LAIN',
    },
]

const penerbitTermsAndConditions = [
    {
        list: [
            {
                content:
                    'Layanan Penawaran Efek Melalui Urun Dana adalah suatu program Layanan Penawaran Efek Melalui Urun Dana yang diselenggarakan oleh Penyelenggara dengan melakukan penawaran Saham milik Penerbit kepada Pemodal atau masyarakat umum melalui jaringan sistem elektronik Layanan Penawaran Efek Melalui Urun Dana melalui penawaran Saham berbasis teknologi informasi (equity crowdfunding) milik Penyelenggara yang bersifat terbuka sebagaimana diatur dalam POJK Penawaran Efek Melalui Layanan Penawaran Efek Melalui Urun Dana Berbasis Teknologi Informasi.',
            },
            {
                content:
                    'Penyelenggara adalah PT Moneypool, merupakan pihak yang menyediakan, mengelola, dan mengoperasikan Layanan Penawaran Efek Melalui Urun Dana.',
            },
            {
                content: 'Pengguna adalah Penerbit dan Pemodal.',
            },
            {
                content:
                    'Penerbit adalah badan usaha berbentuk perseroan terbatas, merupakan pihak yang melaksanakan Layanan Penawaran Efek Melalui Urun Dana menawarkan Saham berbasis teknologi melalui Penyelenggara.',
            },
            {
                content: 'Pemodal adalah pihak yang melakukan pembelian Saham Penerbit melalui Penyelenggara.',
            },
            {
                content:
                    'POJK Layanan Penawaran Efek melalui Urun Dana adalah Peraturan Otoritas Jasa Keuangan Nomor : 57/POJK.04/2020 tanggal 10 Desember 2020, beserta perubahan-perubahannya.',
            },
            {
                content:
                    'Efek adalah surat berharga, yaitu surat pengakuan utang, surat berharga komersial, saham, obligasi, tanda bukti utang, unit penyertaan kontrak investasi kolektif, kontrak berjangka atas efek, dan setiap derivative dari efek.',
            },
            {
                content:
                    'EBUS adalah Efek bersifat utang dan/atau Sukuk yang diterbitkan tanpa melalui mekanisme Penawaran Umum dan memenuhi kriteria yang telah ditetapkan sesuai dengan POJK Nomor 30/POJK.04/2019.',
            },
            {
                content:
                    'Buyback adalah proses dimana Penerbit melakukan pembelian kembali Saham yang telah dijual oleh Penerbit kepada Pemodal sesuai dengan akta anggaran dasar Penerbit dan undang-undang perseroan terbatas.',
            },
            {
                content:
                    'Hari Kalender adalah hari Senin sampai dengan hari Minggu, termasuk hari libur Nasional yang ditetapkan Pemerintah Indonesia sebagaimana perhitungan tahun kalender Masehi.',
            },
            {
                content: 'Hari Kerja adalah hari kerja dimana Penyelenggara beroperasi.',
            },
        ],
        title: 'DEFINISI',
    },
    {
        list: [
            {
                content:
                    'Penerbit dengan ini mengajukan pendaftaran kepada Penyelenggara dan Penyelenggara menerima pendaftaran dari Penerbit sebagai Anggota untuk melaksanakan Layanan Penawaran Efek Urun Dana dengan maksud dan tujuan, lingkup Layanan Penawaran Efek Melalui Urun Dana, syarat dan ketentuan, serta batas tanggung jawab sesuai dengan POJK Layanan Penawaran Efek Melalui Urun Dana.',
            },
            {
                content:
                    'Penerbit hanya dapat menawarkan Saham melalui 1 (satu) Penyelenggara dalam waktu yang bersamaan (exclusive).',
            },
            {
                content:
                    'Batas maksimum penghimpunan dana melalui Layanan Penawaran Efek Melalui Urun Dana oleh Penerbit dalam jangka waktu 12 (dua belas) bulan paling banyak sebesar Rp 10.000.000.000,00 (sepuluh miliar Rupiah) atau nilai lainnya yang ditetapkan Otoritas Jasa Keuangan melalui Surat Edaran Otoritas Jasa Keuangan.',
            },
            {
                content: 'Penawaran Saham oleh Penerbit dapat dilakukan dalam 1 (satu) kali penawaran atau lebih.',
            },
        ],
        title: 'PELAKSANAAN LAYANAN PENAWARAN EFEK MELALUI URUN DANA',
    },
    {
        list: [
            {
                content: 'Masa penawaran Efek oleh Penerbit dilakukan paling lama 45 (empat puluh lima) Hari Kalender.',
            },
            {
                content:
                    'Penerbit dapat membatalkan penawaran Efek melalui Layanan Penawaran Efek Melalui Urun Dana sebelum berakhirnya masa penawaran Saham dengan membayar sejumlah denda kepada Pemodal dan Penyelenggara.',
            },
        ],
        title: 'MASA PENAWARAN EFEK',
    },
    {
        description:
            'Penerbit mengetahui dan memahami bahwa pembelian Efek oleh Pemodal dalam penawaran Saham melalui Layanan Penawaran Efek Melalui Urun Dana dilakukan dengan menyetorkan sejumlah dana pada escrow account Penyelenggara atas nama PT Modal Anak Bangsa.',
        title: 'PEMBELIAN EFEK',
    },
    {
        list: [
            {
                content:
                    'Penyelenggara wajib menyerahkan dana dari Pemodal kepada Penerbit paling lambat 21 (dua puluh satu) Hari Kerja setelah berakhirnya masa penawaran Saham.',
            },
            {
                content: 'Manfaat bersih dari penempatan dana dikembalikan kepada Pemodal secara proporsional.',
            },
            {
                content:
                    'Dalam Layanan Penawaran Efek Melalui Urun Dana ini Penyelenggara berhak mendapat fee dari Penerbit dari dana terkumpul yang akan disepakati tertulis dalam Perjanjian Layanan Penawaran Efek Melalui Urun Dana.',
            },
            {
                content:
                    'Setelah Penerbit resmi terdaftar sebagai Anggota, maka Penerbit setuju untuk memberikan annual fee kepada Penyelenggara sebagai biaya pemasaran dan maintenance yang dibayar setiap pembagian dividen tahunan (final). Besar annual fee Penerbit yang diberikan kepada Penyelenggara tersebut akan disepakati secara tertulis dalam Perjanjian Layanan Penawaran Efek Melalui Urun Dana dan dilakukan review setiap tahunnya sesuai dengan kebijakan Penyelenggara.',
            },
            {
                content: 'Berakhirnya masa penawaran Efek adalah:',
                sublist: {
                    items: [
                        {
                            content: 'Tanggal tertentu yang telah ditetapkan dan disepakati oleh Para Pihak; atau',
                        },
                        {
                            content:
                                'tanggal tertentu berakhirnya masa penawaran Saham namun seluruh Saham yang ditawarkan melalui Layanan Penawaran Efek Melalui Urun Dana telah dibeli oleh Pemodal.',
                        },
                    ],
                },
            },
            {
                content:
                    'Penerbit wajib menyerahkan Efek kepada Penyelenggara untuk didistribusikan kepada Pemodal paling lambat 5 (lima) Hari Kerja setelah Penerbit menerima dana Pemodal dari Penyelenggara.',
            },
            {
                content:
                    'Penyelenggara wajib mendistribusikan Efek kepada Pemodal paling lambat 10 (sepuluh) Hari Kerja setelah menerima Saham dari Penerbit.',
            },
            {
                content:
                    'Penyelenggara dan Penerbit sepakat untuk pendistribusian Saham kepada Pemodal dilakukan secara elektronik melalui penitipan kolektif pada kustodian, dalam hal ini PT Kustodian Sentral Efek Indonesia (“KSEI”)',
            },
        ],
        title: 'PENYERAHAN DANA DAN EFEK',
    },
    {
        title: 'LAPORAN PENERBIT',
        list: [
            {
                content:
                    'Penerbit wajib menyampaikan laporan tahunan kepada Otoritas Jasa Keuangan dan mengumumkan kepada masyarakat melalui situs web Penyelenggara dan/atau situs website Penerbit paling lambat 6 (enam) bulan setelah tahun buku Penerbit berakhir.',
            },
            {
                content:
                    'Selain memuat informasi sebagaimana diatur dalam undang-undang mengenai perseroan terbatas, laporan tahunan Penerbit wajib memuat informasi tentang realisasi penggunaan dana hasil penawaran Saham melalui Layanan Penawaran Efek Melalui Urun Dana.',
            },
            {
                content:
                    'Informasi mengenai realisasi penggunaan dana wajib disampaikan dan diumumkan hingga dana hasil penawaran Saham melalui Layanan Penawaran Efek Melalui Urun Dana telah habis digunakan.',
            },
            {
                content:
                    'Penerbit dapat meminta kepada Otoritas Jasa Keuangan untuk dibebaskan dari kewajiban penyampaian dan pengumuman laporan tahunan, jika:',
                sublist: {
                    items: [
                        {
                            content:
                                'Penerbit telah mengumumkan paling sedikit 3 (tiga) laporan tahunan setelah penawaran Saham melalui Layanan Penawaran Efek Melalui Urun Dana dan jumlah pemegang Saham tidak lebih dari 300 (tiga ratus) pihak;',
                        },
                        {
                            content:
                                'Penerbit  telah  mengumumkan  paling  sedikit  3  (tiga)  laporan  tahunan setelah penawaran Saham melalui Layanan Penawaran Efek Melalui Urun Dana dan total aset tidak lebih dari Rp 30.000.000.000,00 (tiga puluh miliar Rupiah); atau',
                        },
                        {
                            content:
                                'Seluruh Saham yang dijual melalui Layanan Penawaran Efek Melalui Urun Dana dibeli kembali oleh Penerbit atau dibeli oleh pihak lain.',
                        },
                    ],
                },
            },
        ],
    },
    {
        description:
            'Saham dititipkan dalam penitipan kolektif di KSEI karenanya Penerbit wajib mencatatkan kepemilikan saham Pemodal dalam penitipan kolektif dan Penerbit memberikan kuasa kepada Penyelenggara untuk melakukan administrasi efek sesuai dengan ketentuan dan prosedur yang berlaku yang ditetapkan oleh KSEI dan ketentuan undang-undang tentang perseroan terbatas yang berlaku di Negara Republik Indonesia.',
        title: 'DAFTAR PEMEGANG EFEK',
    },
    {
        list: [
            {
                content:
                    'Penerbit wajib menetapkan jumlah minimum dana yang harus diperoleh dalam penawaran Saham melalui Layanan Penawaran Efek Melalui Urun Dana berdasarkan kesepakatan tertulis yang dituangkan dalam Perjanjian Layanan Penawaran Efek Melalui Urun Dana.',
            },
            {
                content: 'Dalam hal Penerbit menetapkan jumlah minimum dana, maka Penerbit wajib mengungkapkan:',
                sublist: {
                    items: [
                        {
                            content: 'rencana penggunaan dana sehubungan dengan perolehan dana minimum; atau',
                        },
                        {
                            content: 'sumber dana lain untuk melaksanakan rencana penggunaan dana.',
                        },
                    ],
                },
            },
            {
                content: 'Penerbit dilarang mengubah jumlah minimum dalam masa penawaran Saham.',
            },
            {
                content:
                    'Jika jumlah minimum dana tidak terpenuhi, maka penawaran Saham melalui Layanan Penawaran Efek Melalui Urun Dana tersebut dinyatakan batal demi hukum.',
            },
            {
                content:
                    'Dalam hal penawaran Saham batal demi hukum, maka Penyelenggara wajib mengembalikan dana beserta seluruh manfaat yang timbul dari dana tersebut selama dalam escrow account secara proporsional kepada Pemodal paling lambat 2 (dua) Hari Kerja setelah penawaran Saham dinyatakan batal demi hukum.',
            },
            {
                content:
                    'Ketentuan pembagian dividen Penerbit diinformasikan di dalam kebijakan dividen dan didasarkan pada laba bersih Penerbit setelah dikurangi dengan pencadangan. Mekanisme pembagian dividen lainnya (termasuk pembagian dividen interim) mengacu pada anggaran dasar Penerbit dan peraturan perundang-undangan yang berlaku.',
            },
            {
                content:
                    'Pembagian  dividen  final  Penerbit  mengacu  pada  persetujuan  Rapat  Umum Pemegang Saham (“RUPS”) Penerbit.',
            },
            {
                content:
                    'Pembagian dividen kepada para pemegang Saham tidak bersifat lifetime sehingga Penerbit berhak melakukan Buyback sebagaimana diatur dalam akta anggaran dasar Penerbit dan peraturan perundang-undangan yang berlaku',
            },
            {
                content:
                    'Apabila terdapat beban operasional usaha yang harus dikeluarkan setiap periode tertentu, Penerbit tidak memiliki hak untuk membebankannya kepada Pemodal, melainkan beban tersebut dimasukkan ke dalam penghitungan biaya operasional yang kemudian dilaporkan dalam laporan keuangan periode tersebut.',
            },
            {
                content:
                    'Apabila dikemudian hari Penerbit ingin mengakhiri kerjasama Layanan Penawaran Efek Melalui Urun Dana (opsi Buyback sesuai dengan ketentuan undang-undang perseroan terbatas), Penerbit berkewajiban mengajukan permohonan kepada Penyelenggara, selambat-lambatnya 60 (enam puluh) Hari Kalender.',
            },
            {
                content:
                    'Dalam hal Penerbit mengakhiri kerjasama sebagaimana dimaksud dalam ketentuan nomor 10 Romawi VIII ini, maka Penerbit wajib untuk melakukan hal-hal dibawah ini:',
                sublist: {
                    items: [
                        {
                            content: 'Mengajukan permohonan pembatalan pendaftaran efek di KSEI;',
                        },
                        {
                            content:
                                'Mengubah akta anggaran dasar Penerbit dengan menghapus ketentuan terkait penitipan kolektif di KSEI dan pemindahbukuan secara elektronik;',
                        },
                        {
                            content: 'Menerbitkan sertifikat Saham atas nama Pemodal',
                        },
                    ],
                },
            },
        ],
        title: 'PENGHIMPUNAN DANA DAN PENETAPAN DANA MINIMUM',
    },
    {
        description:
            'Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam Perjanjian ini, maka kewajiban Penerbit adalah sebagai berikut:',
        list: [
            {
                content:
                    'Penerbit wajib membuat materi video pada saat sebelum dan sesudah dilakukannya Layanan Penawaran Efek Melalui Urun Dana dengan konten (materi) yang akan ditentukan oleh Penyelenggara.',
            },
            {
                content: 'Penerbit wajib kooperatif dan berpartisipasi aktif membantu proses penjualan Saham.',
            },
            {
                content:
                    'Penerbit agar turut melakukan broadcast dan penyebaran baik melalui media-media sosial yang dimiliki ataupun jalur pribadi untuk men-support penjualan Saham.',
            },
            {
                content:
                    'Penerbit wajib memberikan laporan keuangan setiap bulan maksimal tanggal 10 bulan berikutnya, minimal mencakup laporan laba rugi bulanan.',
            },
            {
                content:
                    'Penerbit wajib memberikan laporan keuangan tiap per 6 (enam) bulan maksimal tanggal 15 bulan berikutnya, minimal mencakup laporan:',
                sublist: [
                    {
                        content: 'Laporan Rugi Laba',
                    },
                    {
                        content: 'Neraca / Balance Sheet',
                    },
                ],
                type: 'i',
            },
            {
                content:
                    'Pembagian dividen wajib dilakukan oleh Penerbit kepada Pemodal dengan jumlah bagian persentase dari laba bersih Penerbit untuk dividen sesuai porsi Pemodal.',
            },
            {
                content: 'Penerbit wajib membayar fee dan annual fee kepada Penyelenggara.',
            },
            {
                content: 'Penerbit wajib menentukan periode waktu untuk dividen atau bunga untuk',
            },
            {
                content:
                    'Pemodal sebagaimana diatur dalam akta anggaran dasar Penerbit. Pembagian dividen oleh Penerbit ditetapkan oleh RUPS Penerbit berdasarkan laporan keuangan Penerbit, dan pemegang Saham Penerbit berhak menolak dan menerima laporan keuangan Penerbit.',
            },
            {
                content:
                    'Penerbit wajib memberikan izin kepada Penyelenggara untuk melakukan audit ulang laporan keuangan Penerbit oleh tim independen, sebagai bagian dari due diligence.',
            },
            {
                content:
                    'Penerbit wajib menjaga nama baik dan reputasi Penyelenggara dengan tidak melakukan promosi-promosi yang mengandung unsur suku, agama dan ras, atau tidak melakukan penyebaran informasi yang tidak benar dengan mengatasnamakan Penyelenggara.',
            },
            {
                content:
                    'Penerbit wajib memberikan informasi terkait update perkembangan bisnis setiap 1 (satu) bulan sekali, kepada Penyelenggara, untuk disampaikan sebagai news update kepada Pemodal.',
            },
            {
                content:
                    'Penerbit wajib melaporkan penggunaan dana dari hasil Layanan Penawaran Efek Melalui Urun Dana.',
            },
            {
                content:
                    'Penerbit wajib tunduk dan patuh pada ketentuan terms and conditions yang tercantum dalam website Penyelenggara serta wajib tunduk dan patuh pada Perjanjian Layanan Penawaran Efek Melalui Urun Dana serta POJK Layanan Penawaran Efek Melalui Urun Dana',
            },
            {
                content:
                    'Penerbit wajib setuju dan sepakat bersedia untuk memberikan akses audit internal maupun audit eksternal yang ditunjuk Penyelenggara serta audit Otoritas Jasa Keuangan (OJK) atau regulator berwenang lainnya setiap kali dibutuhkan terkait pelaksanaan Layanan Penawaran Efek Melalui Urun Dana ini.',
            },
            {
                content:
                    'Penerbit wajib menyampaikan laporan tahunan kepada Otoritas Jasa Keuangan dan mengumumkan kepada masyarakat melalui situs web Penyelenggara dan/atau situs website Penerbit paling lambat 6 (enam) bulan setelah tahun buku Penerbit berakhir.',
            },
            {
                content:
                    'Penerbit wajib menyerahkan dokumen dan/atau informasi kepada Penyelenggara paling sedikit mengenai:',
                sublist: [
                    {
                        content:
                            'Akta pendirian badan hukum Penerbit, berikut perubahan anggaran dasar terakhir, jika terdapt perubahan anggaran dasar, yang telah disahkan/disetujui oleh Kementerian Hukum dan Hak Asasi Manusia dan/atau instansi yang berwenang atau diberitahukan kepada instansi yang berwenang;',
                    },
                    {
                        content:
                            'Jumlah dana yang akan dihimpun dalam penawaran Saham dan tujuan penggunaan dana hasil penawaran Saham melalui Layanan Penawaran Efek Melalui Urun Dana;',
                    },
                    {
                        content:
                            'Jumlah minimum dana yang harus diperoleh dalam penawaran Saham melalui Layanan Penawaran Efek Melalui Urun Dana, jika Penerbit menetapkan jumlah minimum dana yang harus diperoleh;',
                    },
                    {
                        content:
                            'Risiko utama yang dihadapi Penerbit dan risiko kemungkinan tidak likuidnya Saham yang ditawarkan, jika terdapat risiko kemungkinan tidak likuidnya Saham;',
                    },
                    {
                        content: 'Rencana bisnis Penerbit;',
                    },
                    {
                        content:
                            'Perizinan yang berkaitan dengan kegiatan usaha Penerbit dan/atau proyek yang akan didanai dengan dana hasil penawaran Saham melalui Layanan Penawaran Efek Melalui Urun Dana;',
                    },
                    {
                        content: 'Kebijakan dividen Penerbit sesuai akta anggaran dasar Penerbit;',
                    },
                    {
                        content:
                            'Laporan keuangan yang paling rendah disusun berdasarkan standar akuntansi tanpa akuntabilitas publik; dan',
                    },
                    {
                        content: 'Mekanisme penetapan harga Saham;',
                    },
                    {
                        content: 'Surat keterangan domisili Penerbit;',
                    },
                    {
                        content: 'Hasil RUPS Penerbit.',
                    },
                ],
            },
            {
                content:
                    'Dokumen asli yang diberikan Penerbit kepada Penyelenggara sebagaimana ketentuan nomor 17 huruf p Romawi IX di atas disertakan :',
                sublist: [
                    {
                        content: 'Kop surat asli Penerbit;',
                    },
                    {
                        content: 'Paraf basah direksi Penerbit di setiap halaman;',
                    },
                    {
                        content: 'Tanda tangan basah Direksi yang berwenang sesuai akta anggaran dasar;',
                    },
                    {
                        content: 'Cap atau stempel asli Penerbit di kolom tanda tangan Direksi.',
                    },
                ],
            },
            {
                content:
                    'Penerbit wajib memberikan sedikitnya 50 (lima puluh) referensi calon investor dari kalangan Pemodal, keluarga, dan teman dekat kepada Penyelenggara.',
            },
        ],
        title: 'KEWAJIBAN PENERBIT',
    },
    {
        description:
            'Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam terms and conditions ini, maka hak Penerbit adalah sebagai berikut:',
        list: [
            {
                content:
                    'Penerbit berhak mendapatkan support teknologi untuk proses pembukaan Layanan Penawaran Efek Melalui Urun Dana dan diperdagangkan.',
            },
            {
                content:
                    'Penerbit berhak atas teknologi untuk pembagian dividen yang peredarannya transparan dan termonitor (dengan memanfaatkan teknologi blockchain).',
            },
            {
                content:
                    'Penerbit berhak mendapatkan dukungan marketing, sesuai dengan data yang diberikan oleh Penerbit.',
            },
            {
                content:
                    'Penerbit berhak mendapatkan bantuan komunikasi penyebaran konten lewat channel resmi dan jaringan audience yang dimiliki Para Pihak.',
            },
            {
                content:
                    'Penerbit berhak mendapat bantuan dalam transaksi penjualan Saham, dan menyalurkan hasil penjualan kepada Penerbit.',
            },
        ],
        title: 'HAK PENERBIT',
    },
    {
        description:
            'Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam terms and conditions ini, maka kewajiban Penyelenggara adalah sebagai berikut:',
        list: [
            {
                content: 'Penyelenggara wajib memenuhi seluruh hak-hak Penerbit.',
            },
            {
                content:
                    'Penyelenggara wajib memonitor, menganalisa, dan memastikan bahwa Penerbit berada di jalur yang sesuai dengan visi misi Penyelenggara dan Layanan Penawaran Efek Melalui Urun Dana.',
            },
            {
                content:
                    'Penyelenggara wajib proaktif dalam berkomunikasi dengan Penerbit dan dalam mengimplementasikan program-program yang bisa membantu proses scale-up Penerbit.',
            },
        ],
        title: 'KEWAJIBAN PENYELENGGARA',
    },
    {
        description:
            'Tanpa mengesampingkan hak dan kewajiban lainnya sebagaimana telah tersebut dalam terms and conditions ini, maka kewajiban Penyelenggara adalah sebagai berikut:',
        list: [
            {
                content: 'Hak Penyelenggara adalah hal-hal yang menjadi kewajiban Penerbit.',
            },
            {
                content:
                    'Penyelenggara berhak mendapatkan akses audit ulang laporan keuangan yang diberikan oleh Penerbit, sebagai bagian dari perlindungan Pemodal maupun permintaan Otoritas Jasa Keuangan (OJK) atau regulator berwenang lainnya.',
            },
            {
                content:
                    'Penyelenggara berhak menyetujui atau menolak permintaan tertulis dari Penerbit dalam penggunaan dana, jika dirasa tidak sesuai dengan tujuan program pemberdayaan Usaha Mikro Kecil dan Menengah (UMKM) dari Penyelenggara.',
            },
            {
                content:
                    'Penyelenggara berhak mewakili Pemodal sebagai wali amanat dalam hal Penerbit menerbitkan EBUS.',
            },
            {
                content:
                    'Penyelenggara berhak meminta kehadiran perwakilan dari Penerbit untuk menemui perwakilan Penyelenggara secara langsung setiap kali dibutuhkan.',
            },
            {
                content:
                    'Penyelenggara berhak menghentikan seluruh partisipasi Penerbit dari Layanan Penawaran Efek Melalui Urun Dana jika Penerbit dinilai tidak kooperatif, mencemarkan nama baik Penyelenggara atau diindikasi melakukan aktifitas yang melanggar hukum.',
            },
        ],
        title: 'HAK PENYELENGGARA',
    },
    {
        description:
            'Penerbit wajib memberikan laporan-laporan sehubungan dengan pelaksanaan terms and conditions ini, dengan melaporkan dengan segera kepada Penyelenggara setiap kejadian kritis yang dapat mengakibatkan kerugian keuangan yang signifikan dan/atau mengganggu kelancaran operasional sistem Layanan Penawaran Efek Melalui Urun Dana.',
        title: 'LAPORAN KEJADIAN KRITIS',
    },
    {
        description:
            'Pembebanan pajak yang timbul dalam Layanan Penawaran Efek Melalui Urun Dana ini menjadi beban masing-masing pihak serta tunduk pada ketentuan hukum perpajakkan yang berlaku di wilayah Negara Republik Indonesia.',
        title: 'PERPAJAKAN',
    },
    {
        list: [
            {
                content:
                    'Hak atas kekayaan intelektual yang timbul atas pelaksanaan Layanan Penawaran Efek Melalui Urun Dana dan izin Penyelenggara, beserta fasilitas-fasilitas lain yang dimiliki Penyelenggara dan digunakan dalam Layanan Penawaran Efek Melalui Urun Dana ini adalah tetap dan seterusnya milik Penyelenggara dan tidak ada penyerahan hak dari Penyelenggara kepada Penerbit dalam Layanan Penawaran Efek Melalui Urun Dana ini.',
            },
            {
                content:
                    'Penerbit tidak berhak untuk mengubah, mengembangkan, membagikan dan/atau menjual baik seluruh maupun sebagian hak atas kekayaan intelektual yang timbul atas pengembangan, inovasi, perubahan berupa fitur dan/atau fungsi terhadap sistem teknologi informasi.',
            },
            {
                content:
                    'Penyelenggara dengan ini menjamin bahwa hak atas kekayaan intelektual yang terkandung dalam pelaksanaan Layanan Penawaran Efek Melalui Urun Dana ini tidak melanggar hak atas kekayaan intelektual milik pihak manapun, dan Penyelenggara membebaskan Penerbit dari segala tuntutan, gugatan dari pihak manapun, sehubungan dengan pelanggaran terhadap hak atas kekayaan intelektual yang terkandung dalam Layanan Penawaran Efek Melalui Urun Dana sesuai terms and conditions ini.',
            },
            {
                content:
                    'Dalam hal terdapat hak atas kekayaan intelektual milik pihak manapun (eksternal) maupun principal maka Penyelenggara menyatakan dan menjamin bahwa Penyelenggara tidak menggunakan hak atas kekayaan intelektual tersebut dan tetap menjadi milik pihak manapun (eksternal) maupun principal.',
            },
        ],
        title: 'HAK ATAS KEKAYAAN INTELEKTUAL',
    },
    {
        list: [
            {
                content:
                    'Penerbit setuju dan sepakat untuk menginformasikan kepada Penyelenggara melalui email Penyelenggara yaitu benih.bersama@mncgroup.com selambat-lambatnya 60 (enam puluh) Hari Kalender terhitung sebelum tanggal pengembalian Buyback atau tanggal pengakhiran Perjanjian Layanan Penawaran Efek Melalui Urun Dana;',
            },
            {
                content:
                    'Bahwa pengaturan tata cara Buyback mengacu pada akta anggaran dasar Penerbit dan undang-undang dasar perseroan terbatas.',
            },
            {
                content:
                    'Penerbit setuju dan sepakat untuk membeli Saham dengan harga pasar saat Buyback atau batas harga lainnya yang ditentukan oleh penyelenggara dan membayar Buyback fee senilai Rp 10.000.000,00 (sepuluh juta Rupiah) kepada Penyelenggara, dengan melalui transfer bank selambat-lambatnya 15 (lima belas) Hari Kalender terhitung sejak tanggal pengembalian Buyback dan tanggal pengakhiran Perjanjian Layanan Penawaran Efek Melalui Urun Dana;',
            },
            {
                content:
                    'Penerbit setuju dan sepakat untuk menyelesaikan kewajiban-kewajiban kepada Penyelenggara dan Pemodal yang belum terselesaikan;',
            },
            {
                content:
                    'Penerbit setuju dan sepakat untuk memberikan release di dunia maya berupa media sosial, website dan media sosial Penyelenggara.',
            },
        ],
        title: 'KETENTUAN BUYBACK',
    },
    {
        list: [
            {
                content:
                    'Jangka waktu Layanan Penawaran Efek Melalui Urun Dana ini mengacu pada kesepakatan tertulis yang dituangkan dalam bentuk Perjanjian Layanan Penawaran Efek Melalui Urun Dana antara Penyelenggara dan Penerbit, dan dapat diperpanjang berdasarkan kesepakatan tertulis dalam bentuk addendum yang ditandatangani oleh Para Pihak.',
            },
            {
                content: 'Layanan Penawaran Efek Melalui Urun Dana ini berakhir dengan sendirinya, dalam hal:',
                sublist: [
                    {
                        content:
                            'Jangka waktu berakhir sebagaimana diatur dalam Perjanjian Layanan Penawaran Efek Melalui Urun Dana;',
                    },
                    {
                        content: 'Penerbit mengajukan Buyback atas semua Saham yang dimiliki Pemodal;',
                    },
                    {
                        content: 'Salah satu Pihak dilikuidasi, kecuali untuk maksud pendirian kembali atau merger;',
                    },
                    {
                        content:
                            'Izin operasional salah satu Pihak dicabut oleh pemerintah Republik Indonesia dan/atau instansi yang berwenang atau telah berakhir;',
                    },
                    {
                        content: 'Salah satu Pihak dinyatakan pailit berdasarkan keputusan pengadilan yang berwenang;',
                    },
                    {
                        content:
                            'Terdapat ketentuan peraturan perundang-undangan dan/atau kebijakan pemerintah Republik Indonesia yang tidak memungkinkan berlangsungnya Layanan Penawaran Efek Melalui Urun Dana berdasarkan terms and conditions ini, termasuk namun tidak terbatas pada atas permintaan Otoritas Jasa Keuangan.',
                    },
                ],
            },
            {
                content:
                    'Tanpa mengesampingkan ketentuan sebagaimana diatur ketentuan nomor 1 dan 2. Romawi XVII ini, Penyelenggara berhak untuk mengubah, membuat perjanjian baru atau menghentikan Layanan Penawaran Efek Melalui Urun Dana ini sebelum berakhirnya Layanan Penawaran Efek Melalui Urun Dana atas dasar kondisi sebagai berikut, termasuk namun tidak terbatas pada:',
                sublist: [
                    {
                        content:
                            'Memburuknya kinerja Penerbit dalam pelaksanaan Layanan Penawaran Efek Melalui Urun Dana ini yang dapat berdampak signifikan pada kegiatan Layanan Penawaran Efek Melalui Urun Dana;',
                    },
                    {
                        content:
                            'Penerbit menjadi insolven, dalam proses menuju likuidasi, atau dipailitkan oleh pengadilan yang berwenang;',
                    },
                    {
                        content:
                            'Terdapat pelanggaran oleh Penerbit terhadap ketentuan Layanan Penawaran Efek Melalui Urun Dana ini; dan/atau',
                    },
                    {
                        content:
                            'Terdapat kondisi yang menyebabkan Penerbit tidak dapat menyediakan data yang diperlukan dalam rangka pengawasan oleh Otoritas Jasa Keuangan. Dalam hal Layanan Penawaran Efek Melalui Urun Dana ini berakhir dan/atau  dinyatakan berakhir, maka Para Pihak sepakat bahwa ketentuan Informasi Rahasia sebagaimana diatur dalam Layanan Penawaran Efek Melalui Urun Dana ini tetap berlaku dan mengikat Para Pihak hingga kapanpun meskipun Layanan Penawaran Efek Melalui Urun Dana telah berakhir.',
                    },
                ],
            },
            {
                content:
                    'Pengakhiran/pembatalan Layanan Penawaran Efek Melalui Urun Dana ini tidak menghapuskan kewajiban-kewajiban masing-masing Pihak yang telah atau akan timbul dan belum dilaksanakan pada saat berakhirnya Layanan Penawaran Efek Melalui Urun Dana ini.',
            },
            {
                content:
                    'Dalam hal pengakhiran/pembatalan Layanan Penawaran Efek Melalui Urun Dana ini, Para Pihak sepakat untuk mengesampingkan keberlakuan ketentuan Pasal 1266 Kitab Undang-Undang Hukum Perdata, sepanjang ketentuan tersebut mensyaratkan adanya suatu putusan atau penetapan pengadilan untuk menghentikan atau mengakhiri suatu perjanjian, sehingga pengakhiran/pembatalan Layanan Penawaran Efek Melalui Urun Dana ini cukup dilakukan dengan pemberitahuan tertulis dari salah satu Pihak.',
            },
        ],
        title: 'JANGKA WAKTU DAN PENGAKHIRAN',
    },
    {
        list: [
            {
                content:
                    'Salah satu Pihak (selanjutnya disebut “Pihak Pemberi”) dapat memberikan Informasi Rahasia kepada Pihak lainnya (selanjutnya disebut “Pihak Penerima”) dalam melaksanakan Layanan Penawaran Efek Melalui Urun Dana ini. Para Pihak sepakat bahwa pemberian, penerimaan dan penggunaan Informasi Rahasia tersebut dilakukan sesuai dengan terms and conditions ini.',
            },
            {
                content:
                    'Informasi Rahasia yang dimaksud dalam ketentuan ini berarti informasi yang bersifat non-publik, termasuk namun tidak terbatas pada skema atau gambar produk, penjelasan material, spesifikasi, penjualan dan informasi mengenai klien, kebijaksanaan dan praktek bisnis Pihak Pemberi dan informasi mana dapat dimuat dalam media cetak, tulis, disk / tape / compact disk komputer atau media lainnya yang sesuai.',
            },
            {
                content:
                    'Tidak termasuk sebagai Informasi Rahasia adalah materi atau informasi yang mana dapat dibuktikan oleh Pihak Penerima bahwa:',
                sublist: [
                    {
                        content:
                            'Pada saat penerimaannya sebagai milik publik (public domain) atau menjadi milik publik (public domain) atau menjadi milik publik (public domain) tanpa adanya pelanggaran oleh Pihak Penerima;',
                    },
                    {
                        content: 'Telah diketahui oleh Pihak Penerima pada saat diberikan oleh Pihak Pemberi;',
                    },
                    {
                        content:
                            'Telah didapatkan dari pihak ketiga tanpa adanya pelanggaran terhadap pengungkapan Informasi Rahasia;',
                    },
                    {
                        content: 'Dikembangkan sendiri oleh Pihak Penerima.',
                    },
                ],
            },
            {
                content:
                    'Pihak Penerima dengan ini menyatakan bahwa tidak akan mengungkapkan Informasi Rahasia apapun yang diberikan Pihak Pemberi ke pihak lainnya selain daripada yang diperlukan dalam melaksanakan tugas, peran dan kewajibannya dalam terms and conditions ini, tanpa terlebih dahulu memperoleh persetujuan dari Pihak Pemberi dan Pihak Penerima akan melakukan semua tindakan-tindakan pencegahan yang wajar untuk mencegah terjadinya pelanggaran atau kelalaian dalam pengungkapan, penggunaan, pembuatan salinan (copy) atau pengalihan Informasi Rahasia tersebut.',
            },
            {
                content:
                    'Masing-masing Pihak berkewajiban untuk menyimpan segala rahasia data atau sistem yang diketahuinya baik secara langsung maupun tidak langsung sehubungan Layanan Urun Dana dan bertanggung jawab atas segala kerugian yang diakibatkan karena pembocoran Informasi Rahasia tersebut, baik oleh masing-masing Pihak maupun karyawannya maupun perwakilannya.',
            },
        ],
        title: 'INFORMASI RAHASIA',
    },
    {
        list: [
            {
                content:
                    'Tanpa mengesampingkan pernyataan dan jaminan yang diberikan oleh salah satu Pihak ke Pihak lainnya dalam Layanan Penawaran Efek Melalui Urun Dana ini, masing-masing Pihak dengan ini menyatakan dan menjamin Pihak lainnya dalam Layanan Penawaran Efek Melalui Urun Dana hal-hal sebagai berikut:',
                sublist: [
                    {
                        content:
                            'Bahwa masing-masing Pihak adalah suatu perusahaan yang didirikan dan sah berdasarkan hukum negara Republik Indonesia dan cakap menurut hukum untuk memiliki harta kekayaan dan melakukan perbuatan hukum usahanya di wilayah Republik Indonesia serta memiliki segala perizinan yang diperlukan untuk kegiatan operasionalnya;',
                    },
                    {
                        content:
                            'Bahwa masing-masing Pihak telah mengambil segala tindakan yang diperlukan untuk memastikan wakil dari masing-masing Pihak dalam Layanan Penawaran Efek Melalui Urun Dana telah memiliki kuasa dan wewenang penuh untuk mengikatkan diri dan menyetujui terms and conditions ini;',
                    },
                    {
                        content:
                            'Bahwa masing-masing Pihak telah memastikan bahwa terms and conditions ini tidak melanggar ketentuan dari anggaran dasar masing-masing Pihak dalam terms and conditions dan tidak bertentangan dengan perjanjian apapun yang dibuat oleh masing- masing Pihak dengan pihak ketiga.',
                    },
                    {
                        content:
                            'Pelaksanaan ketentuan-ketentuan dalam Layanan Penawaran Efek Melalui Urun Dana ini dilaksanakan secara profesional dengan penuh tanggung jawab dan atas dasar hubungan yang saling menguntungkan.',
                    },
                    {
                        content:
                            'Persetujuan dan pelaksanaan terms and conditions ini tidak bertentangan atau melanggar atau berbenturan dengan kaidah-kaidah hukum dan peraturan perundang- undangan serta kebijakan-kebijakan pemerintah Republik Indonesia atau pihak yang berwenang lainnya;',
                    },
                    {
                        content:
                            'Bersedia untuk menerapkan, mendukung dan mematuhi ketentuan hukum dan peraturan perundang-undangan yang berlaku di Republik Indonesia termasuk namun tidak terbatas pada peraturan mengenai tindak pidana korupsi, anti pencucian uang dan anti penyuapan;',
                    },
                    {
                        content:
                            'Masing-masing Pihak akan melaksanakan Layanan Penawaran Efek Melalui Urun Dana ini dengan itikad baik dan secara jujur. Tidak satupun ketentuan dalam terms and conditions ini akan digunakan oleh salah satu Pihak untuk mengambil keuntungan secara tidak wajar dan mengakibatkan kerugian bagi Pihak lainnya dan tidak satupun ketentuan dalam terms and conditions ini dimaksudkan untuk memberikan keuntungan secara tidak wajar kepada Pihak lainnya.',
                    },
                ],
            },
            {
                content:
                    'Tanpa mengesampingkan pernyataan dan jaminan yang diberikan oleh Penerbit ke Penyelenggara, Penerbit dengan ini menyatakan dan menjamin kepada Penyelenggara hal-hal sebagai berikut:',
            },
            {
                content:
                    'Bahwa Penerbit akan membebaskan Penyelenggara dari klaim, tuntutan, gugatan dari pihak ketiga atas kelalaian Penerbit dan/atau karyawan Penerbit dalam melaksanakan Layanan Penawaran Efek Melalui Urun Dana ini;',
            },
            {
                content:
                    'Bahwa Penerbit menyatakan tidak berkeberatan dalam hal Otoritas Jasa Keuangan dan/atau pihak lain yang sesuai undang-undang berwenang untuk melakukan pemeriksaan, akan melakukan pemeriksaan terhadap pelaksanaan Layanan Penawaran Efek Melalui Urun Dana ini;',
            },
            {
                content:
                    'Bahwa Penerbit bersedia untuk kemungkinan pengubahan, pembuatan atau pengambil alih kegiatan yang dilaksanakan oleh Penerbit atau penghentian Layanan Penawaran Efek Melalui Urun Dana, dalam hal atas permintaan Otoritas Jasa keuangan apabila diperlukan.',
            },
        ],
        title: 'PERNYATAAN DAN JAMINAN',
    },
    {
        list: [
            {
                content:
                    'Keadaan Memaksa atau Force Majeure adalah kejadian-kejadian yang terjadi diluar kemampuan dan kekuasaan Para Pihak sehingga menghalangi Para Pihak untuk melaksanakan Layanan Urun Dan ini, termasuk namun tidak terbatas pada adanya kebakaran, banjir, gempa bumi, likuifaksi, badai, huru-hara, peperangan, epidemi, pertempuran, pemogokan, sabotase, embargo, peledakan yang mengakibatkan kerusakan sistem teknologi informasi yang menghambat pelaksanaan Layanan Penawaran Efek Melalui Urun Dana ini, serta kebijaksanaan pemerintah Republik Indonesia yang secara langsung berpengaruh terhadap pelaksanaan Layanan Penawaran Efek Melalui Urun Dana ini.',
            },
            {
                content:
                    'Masing-masing Pihak dibebaskan untuk membayar denda apabila terlambat dalam melaksanakan kewajibannya dalam terms and conditions ini, karena adanya hal-hal Keadaan Memaksa.',
            },
            {
                content:
                    'Keadaan Memaksa sebagaimana dimaksud harus diberitahukan oleh Pihak yang mengalami Keadaan Memaksa kepada Pihak lainnya dalam terms and conditions ini paling lambat 7 (tujuh) Hari Kalender dengan melampirkan pernyataan atau keterangan tertulis dari pemerintah untuk dipertimbangkan oleh Pihak lainnya beserta rencana pemenuhan kewajiban yang tertunda akibat terjadinya Keadaan Memaksa.',
            },
            {
                content:
                    'Keadaan Memaksa yang menyebabkan keterlambatan pelaksanaan Layanan Penawaran Efek Melalui Urun Dana ini baik untuk seluruhnya maupun sebagian bukan merupakan alasan untuk pembatalan Layanan Penawaran Efek Melalui Urun Dana ini sampai dengan diatasinya Keadaan Memaksa tersebut.',
            },
        ],
        title: 'KEADAAN MEMAKSA (FORCE MAJEURE)',
    },
    {
        description:
            'Penerbit setuju dan sepakat untuk tidak mengalihkan sebagian atau keseluruhan hak dan kewajiban Penerbit dalam Layanan Penawaran Efek Melalui Urun Dana ini kepada pihak lainnya.',
        title: 'PENGALIHAN PERJANJIAN',
    },
    {
        list: [
            {
                content:
                    'Layanan Penawaran Efek Melalui Urun Dana ini dibuat, ditafsirkan dan dilaksanakan berdasarkan hukum negara Republik Indonesia.',
            },
            {
                content:
                    'Setiap perselisihan yang timbul sehubungan dengan Layanan Penawaran Efek Melalui Urun Dana ini, akan diupayakan untuk diselesaikan terlebih dahulu oleh Para Pihak dengan melaksanakan musyawarah untuk mufakat.',
            },
            {
                content:
                    'Apabila penyelesaian perselisihan secara musyawarah tidak berhasil mencapai mufakat sampai dengan 30 (tiga puluh) Hari Kalender sejak dimulainya musyawarah tersebut, maka Para Pihak sepakat untuk menyelesaikan perselisihan tersebut melalui proses pengadilan.',
            },
            {
                content:
                    'Para Pihak sepakat untuk menyelesaikan perselisihan di Pengadilan Negeri Sleman di Provinsi Daerah Istimewa Yogyakarta tanpa mengurangi hak dari salah satu untuk mengajukan gugatan pada domisili pengadilan lainnya (non-exlusive jurisdiction).',
            },
            {
                content:
                    'Tanpa mengesampingkan penyelesaian sengketa atau perselisihan melalui pengadilan negeri, Para Pihak setuju dan sepakat apabila penyelesaian sengketa atau perselisihan di badan arbitrase dan badan alternatif penyelesaian sengketa yang ditunjuk oleh Otoritas Jasa Keuangan maupun regulator berwenang lainnya.',
            },
            {
                content:
                    'Hasil putusan pengadilan negeri maupun badan arbitrase dan badan alternatif penyelesaian sengketa yang ditunjuk oleh Otoritas Jasa Keuangan maupun regulator berwenang lainnya bersifat final dan mempunyai kekuatan hukum tetap dan mengikat bagi Para Pihak.',
            },
        ],
        title: 'DOMISILI HUKUM DAN PENYELESAIAN SENGKETA',
    },
    {
        list: [
            {
                content:
                    'Dalam hal terjadi salah satu hal atau peristiwa yang ditetapkan di bawah ini, maka merupakan suatu kejadian kelalaian (wanprestasi) terhadap Layanan Penawaran Efek Melalui Urun Dana ini:',
                sublist: [
                    {
                        content: (
                            <Fragment>
                                Kelalaian dalam Layanan Penawaran Efek Melalui Urun Dana.
                                <br />
                                Dalam hal salah satu Pihak terbukti sama sekali tidak melaksanakan kewajiban, atau
                                melaksanakan kewajiban tetapi tidak sebagaimana disepakati, atau melaksanakan kewajiban
                                tetapi tidak sesuai dengan waktu yang disepakati, atau melakukan sesuatu yang tidak
                                diperbolehkan dalam Layanan Penawaran Efek Melalui Urun Dana.
                            </Fragment>
                        ),
                    },
                    {
                        content: (
                            <Fragment>
                                Pernyataan Tidak Benar
                                <br />
                                Dalam hal ternyata bahwa sesuatu pernyataan atau jaminan yang diberikan oleh salah satu
                                Pihak kepada Pihak lainnya dalam Layanan Penawaran Efek Melalui Urun Dana ini terbukti
                                tidak benar atau tidak sesuai dengan kenyataannya dan menimbulkan kerugian langsung yang
                                diderita salah satu Pihak.
                            </Fragment>
                        ),
                    },
                    {
                        content: (
                            <Fragment>
                                Kepailitan
                                <br />
                                Dalam hal salah satu Pihak dalam Layanan Penawaran Efek Melalui Urun Dana ini oleh
                                instansi yang berwenang dinyatakan berada dalam keadaan pailit atau diberikan penundaan
                                membayar hutang- hutang (surseance van betaling).
                            </Fragment>
                        ),
                    },
                    {
                        content: (
                            <Fragment>
                                Permohonan Kepailitan.
                                <br />
                                Dalam hal salah satu Pihak dalam Layanan Penawaran Efek Melalui Urun Dana ini mengajukan
                                permohonan kepada instansi yang berwenang untuk dinyatakan pailit atau untuk diberikan
                                penundaan membayar hutang-hutang (surseance van betaling) atau dalam hal pihak lain
                                mengajukan permohonan kepada instansi yang berwenang agar salah satu Pihak dalam Layanan
                                Penawaran Efek Melalui Urun Dana ini dinyatakan dalam keadaan pailit.
                            </Fragment>
                        ),
                    },
                ],
            },
            {
                content:
                    'Dalam hal suatu kejadian kelalaian terjadi dan berlangsung, maka Pihak yang tidak lalai berhak menyampaikan peringatan sebanyak 3 (tiga) kali dengan tenggang waktu 7 (tujuh) Hari Kalender diantara masing-masing peringatan.',
            },
            {
                content:
                    'Setelah menyampaikan 3 (tiga) kali peringatan, Pihak yang tidak lalai berhak mengajukan tuntutan berupa meminta pemenuhan prestasi dilakukan atau meminta prestasi dilakukan disertai ganti kerugian atau meminta ganti kerugian saja atau menuntut pembatalan Layanan Penawaran Efek Melalui Urun Dana atau menuntut pembatalan Layanan Penawaran Efek Melalui Urun Dana disertai ganti kerugian.',
            },
        ],
        title: 'KELALAIAN / WANPESTASI',
    },
    {
        description:
            'Pengaturan mengenai pengenaan dan besarnya denda atau penalti diatur lebih lanjut dalam Perjanjian Layanan Penawaran Efek Melalui Urun Dana antara Penyelenggara dan Penerbit.',
        title: 'DENDA ATAU PENALTI',
    },
    {
        description:
            'Mekanisme penyelesaian Layanan Penawaran Efek Melalui Urun Dana dalam hal Penyelenggara tidak dapat menjalankan operasional adalah sebagai berikut :',
        list: [
            {
                content:
                    'Melakukan diskusi dengan para Pemodal untuk memilih satu diantara penyelenggara yang telah diberi izin oleh Otoritas Jasa Keuangan untuk dijadikan pengganti Penyelenggara, dengan syarat dan ketentuan yang sudah disepakati bersama dengan Pemodal.',
            },
            {
                content:
                    'Buyback seluruh Saham yang dimiliki Pemodal dengan harga pasar atau disepakati secara tertulis oleh Para Pihak di kemudian hari.',
            },
            {
                content:
                    'Menunjuk penyelenggara lain yang telah mendapat izin dari Otoritas Jasa Keuangan seperti Penyelenggara, dengan syarat dan ketentuan yang sudah disepakati bersama dengan Pemodal.',
            },
        ],
        title: 'MEKANISME DALAM HAL PENYELENGGARA TIDAK DAPAT MENJALANKAN OPERASIONALNYA',
    },
    {
        list: [
            {
                content:
                    'Para Pihak wajib tunduk dan patuh terhadap peraturan perundang-undangan yang berlaku di negara Republik Indonesia terkait pelaksanaan Layanan Penawaran Efek Melalui Urun Dana ini.',
            },
            {
                content:
                    'Layanan Penawaran Efek Melalui Urun Dana ini diinterpretasikan dan dilaksanakan berdasarkan hukum yang berlaku di Negara Republik Indonesia.',
            },
            {
                content:
                    'Segala sesuatu yang tidak atau belum cukup diatur dalam terms and conditions ini, akan diatur kemudian dalam bentuk Perjanjian Layanan Urun Dan yang dibuat secara tertulis dan ditandatangani oleh Penyelenggara dan Pemodal.',
            },
            {
                content:
                    'Keabsahan, penafsiran dan pelaksanaan Layanan Penawaran Efek Melalui Urun Dana ini, diatur serta tunduk pada hukum dan peraturan perundang-undangan yang berlaku di negara Republik Indonesia.',
            },
            {
                content:
                    'Dalam hal ada salah satu atau lebih ketentuan dalam terms and conditions ini dinyatakan tidak sah, tidak berlaku atau tidak dapat dilaksanakan berdasarkan hukum dan/atau peraturan perundang-undangan yang berlaku di Republik Indonesia, maka kedua belah pihak setuju bahwa keabsahan ketentuan lainnya dalam Perjanjian Layanan Penawaran Efek Melalui Urun Dana tetap berlaku dan dapat dilaksanakan serta tidak akan terpengaruh.',
            },
            {
                content:
                    'Dokumen-dokumen atau kesepakatan-kesepakatan terkait dengan Layanan Penawaran Efek Melalui Urun Dana ini yang telah dibuat oleh Para Pihak sebelum ditandatanganinya Perjanjian Layanan Penawaran Efek Melalui Urun Dana dinyatakan dicabut dan tidak berlaku sejak terhitung ditandatanganinya Perjanjian Layanan Penawaran Efek Melalui Urun Dana.',
            },
        ],
        title: 'LAIN-LAIN',
    },
]

const renderSublist = (sublist) => {
    const isUnordered = get(sublist, 'isUnordered', false)
    const items = get(sublist, 'items', [])
    if (isUnordered) {
        return (
            <StyledUnorderedList>
                {map(items, (item, key) => (
                    <li key={key}>{item.content}</li>
                ))}
            </StyledUnorderedList>
        )
    } else {
        const type = get(sublist, 'type', 'a')
        return (
            <StyledOrderedList type={type}>
                {map(items, (item, key) => (
                    <li key={key}>{item.content}</li>
                ))}
            </StyledOrderedList>
        )
    }
}

const renderOrderedList = (termsAndConditions) => (
    <StyledOrderedList $listStyle='upper-roman'>
        <StyledAntdSpace direction='vertical' size='middle'>
            {map(termsAndConditions, (item, key) => (
                <li key={key}>
                    {item.title}
                    {item.description && (
                        <Fragment>
                            <br />
                            {item.description}
                            <br />
                        </Fragment>
                    )}
                    <StyledOrderedList>
                        <AntdSpace direction='vertical'>
                            {map(item.list, (item, key) => (
                                <li key={key}>
                                    {item.content}
                                    {!isEmpty(item.sublist) && renderSublist(item.sublist)}
                                </li>
                            ))}
                        </AntdSpace>
                    </StyledOrderedList>
                </li>
            ))}
        </StyledAntdSpace>
    </StyledOrderedList>
)

export const getTermsAndConditions = (user) => {
    if (user === 'pemodal') {
        return (
            <Fragment>
                {renderOrderedList(pemodalTermsAndConditions)}
                <br />
                SYARAT DAN KETENTUAN INI DIBUAT DAN DIBERIKAN PERSETUJUAN SECARA ELEKTRONIK OLEH PEMODAL DALAM KEADAAN
                SEHAT DAN SADAR SERTA TANPA ADA PAKSAAN DARI PIHAK MANAPUN JUGA. SETELAH PEMODAL MEMBUBUHKAN TANDA
                CENTANG (√) PADA KOTAK PERSETUJUAN SECARA ELEKTRONIK ATAS SYARAT DAN KETENTUAN INI, MAKA PEMODAL DENGAN
                INI MENYATAKAN SETUJU TELAH MEMBACA, MENGERTI, MEMAHAMI SECARA SEKSAMA DAN TUNDUK PADA SETIAP DAN
                KESELURUHAN SYARAT DAN KETENTUAN, SERTA TUNDUK PADA PERATURAN OTORITAS JASA KEUANGAN NOMOR
                57/POJK.04/2020 BESERTA PERUBAHAN-PERUBAHANNYA. DAN OLEH KARENA ITU, DALAM PELAKSANAAN LAYANAN PENAWARAN
                EFEK MELALUI URUN DANA, PEMODAL MENYATAKAN DAN MENJAMIN BAHWA PEMODAL SELALU TETAP MEMATUHI DAN
                MELAKSANAKAN SETIAP KETENTUAN YANG ADA DALAM SYARAT DAN KETENTUAN INI DENGAN PENUH TANGGUNG JAWAB DAN
                PROFESIONAL.
            </Fragment>
        )
    }
    if (user === 'penerbit') {
        return (
            <Fragment>
                {renderOrderedList(penerbitTermsAndConditions)}
                <br />
                SYARAT DAN KETENTUAN INI DIBUAT DAN DIBERIKAN PERSETUJUAN SECARA ELEKTRONIK OLEH PENERBIT DALAM KEADAAN
                SEHAT DAN SADAR SERTA TANPA ADA PAKSAAN DARI PIHAK MANAPUN JUGA. SETELAH PENERBIT MEMBUBUHKAN TANDA
                CENTANG (√) PADA KOTAK PERSETUJUAN SECARA ELEKTRONIK ATAS SYARAT DAN KETENTUAN INI, MAKA PENERBIT DENGAN
                INI MENYATAKAN SETUJU TELAH MEMBACA, MENGERTI, MEMAHAMI SECARA SEKSAMA DAN TUNDUK PADA SETIAP DAN
                KESELURUHAN SYARAT DAN KETENTUAN, DAN PERJANJIAN LAYANAN PENAWARAN EFEK MELALUI URUN DANA YANG
                DISEPAKATI DI KEMUDIAN HARI, SERTA TUNDUK PADA PERATURAN OTORITAS JASA KEUANGAN NOMOR 57/POJK.04/2020
                BESERTA PERUBAHAN-PERUBAHANNYA. DAN OLEH KARENA ITU, DALAM PELAKSANAAN LAYANAN PENAWARAN EFEK MELALUI
                URUN DANA, PENERBIT MENYATAKAN DAN MENJAMIN BAHWA PENERBIT SELALU TETAP MEMATUHI DAN MELAKSANAKAN SETIAP
                KETENTUAN YANG ADA DALAM SYARAT DAN KETENTUAN INI DAN PERJANJIAN LAYANAN PENAWARAN EFEK MELALUI URUN
                DANA YANG DISEPAKATI DI KEMUDIAN HARI DENGAN PENUH TANGGUNG JAWAB DAN PROFESIONAL.
            </Fragment>
        )
    }
}
