import { CheckCircleOutlined, SendOutlined } from '@ant-design/icons'
import { default as AntdList } from 'antd/lib/list'
import { default as AntdRow } from 'antd/lib/row'
import styled, { css } from 'styled-components'
import { Select } from '../antdcomponents'
import colors from '../basic/colors'
import { StyledAntdSpace } from '../components/StyledComponents'
import StyledParagraph from '../components/styled_paragraph/StyledParagraph'

export const StyledAntdListItem = styled(AntdList.Item)`
    &&& {
        cursor: pointer;
        ${(props) => (props.$isActive ? `background-color: ${colors.brightGray};` : '')}
        &:hover {
            background-color: ${colors.brightGray};
        }
    }
`

export const StyledAntdList = styled(AntdList)`
    &&& {
        width: 100%;
    }
`

export const StyledAntdRow = styled(AntdRow)`
    &&& {
        height: 100%;
    }
`

export const StyledInputSearchAntdRow = styled(AntdRow)`
    &&& {
        padding: 16px 15px 0;
    }
`

export const StyledSelectTypeAntdRow = styled(AntdRow)`
    &&& {
        padding: 0 15px;
        @media screen and (max-width: 768px) {
            margin-top: 0;
        }
    }
`

export const StyledChat = styled.div`
    margin: 0 0 15px;
    height: 80vh;
    overflow-y: auto;
    @media screen and (min-width: 768px) {
        height: 75vh;
    }
`

export const StyledCheckCircleOutlined = styled(CheckCircleOutlined)`
    &&& {
        color: ${colors.blueberry};
    }
`

const ellipsisCss = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const StyledEllipsisParagraph = styled(StyledParagraph)`
    &&& {
        @media screen and (max-width: 425px) {
            ${ellipsisCss}
            width: ${(props) => {
                const unreadMessageIconWidth = props.$isShowUnreadMessage ? 3 : 0
                let additionalWidth = props.$width - 375
                additionalWidth = additionalWidth < 0 ? 0 : additionalWidth
                return additionalWidth / 6 + 17 - unreadMessageIconWidth
            }}ch;
        }
        @media screen and (min-width: 1024px) and (max-width: 1200px) {
            ${ellipsisCss}
            width: ${(props) => {
                let additionalWidth = props.$width - 1024
                additionalWidth = additionalWidth < 0 ? 0 : additionalWidth
                return additionalWidth / 10 + 10
            }}ch;
            ${ellipsisCss}
        }
    }
`

export const StyledInbox = styled.div`
    border: 1px solid ${colors.silver};
    border-radius: 20px;
`

export const StyledInboxContainer = styled.div`
    box-sizing: border-box;
    height: 100%;
    @media screen and (min-width: 1024px) {
        border-right: 1px solid ${colors.silver};
    }
`

export const StyledInboxesAntdRow = styled(AntdRow)`
    &&& {
        padding: 0 15px;
        width: 100%;
    }
`

export const StyledMessage = styled.div`
    max-width: 80%;
`

export const StyledMessages = styled.div`
    height: 80vh;
    overflow-y: auto;
    margin-bottom: 20px;
`

export const StyledMessageBubble = styled.div`
    background: ${colors.black}0a;
    border-radius: 10px;
    height: auto;
    padding: 10px 20px;
    min-height: 60px;
`

export const StyledMessageBubbleSpace = styled(StyledAntdSpace)`
    &&& {
        div.ant-space-item {
            width: 100%;
        }
    }
`

export const StyledMessageContainer = styled.div`
    padding: 42px 15px 0;
`

export const StyledMessageForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75vh;
    @media screen and (max-width: 1000px) {
        padding: 0 0 15px;
    }
`

export const StyledReceiverContainer = styled.div`
    border-bottom: 1px solid ${colors.silver};
    padding: 16px 0px 16px 15px;
`

export const StyledSelect = styled(Select)`
    &&& {
        width: 100%;

        .anticon.ant-select-suffix > svg {
            color: #c8bfff;
            font-size: 21.5px;
            margin-right: 5px;
        }
        span.ant-select-clear {
            margin-right: 47px;
        }
        @media screen and (max-width: 767px) {
            .anticon.ant-select-suffix > svg {
                font-size: 14px;
                margin-right: -5px;
            }
        }
    }
`

export const StyledSendOutlined = styled(SendOutlined)`
    &&& {
        color: ${colors.blueberry};
    }
`
