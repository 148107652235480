import React from 'react'
import PropTypes from 'prop-types'
import { StyledImage, StyledCard } from './StyledComponents'
import StyledTitle from '../styled_title/StyledTitle'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'

const FounderCard = (props) => {
    const { imgSrc, name, occupation } = props
    const image = <StyledImage src={imgSrc} />

    return (
        <StyledCard>
            {renderLazyLoadImage(image)}
            <center>
                <StyledTitle level={3}>{name}</StyledTitle>
                <StyledTitle level={2}>{occupation}</StyledTitle>
            </center>
        </StyledCard>
    )
}

FounderCard.propTypes = {
    imgSrc: PropTypes.string,
    name: PropTypes.string,
    occupation: PropTypes.string,
}

export default FounderCard
