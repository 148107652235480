import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/socialMedia'
import * as socialMediaAPI from './socialMediaAPI'

const initialState = {
    isError: false,
    isLoading: false,
    socialMedias: [],
    totalSocialMedias: 0,
    info: {},
}

export const { getInfo, getSocialMedias } = socialMediaAPI

const socialMedia = createSlice({
    name: 'SOCIAL_MEDIA',
    initialState,
    reducers: {
        clearSocialMedia: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getInfo.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                info: {},
            })
        )
        builder.addCase(getInfo.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                info: data,
            })
        })
        builder.addCase(getInfo.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
        builder.addCase(getSocialMedias.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                socialMedias: [],
            })
        )
        builder.addCase(getSocialMedias.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                socialMedias: map(data.socialMedias, (item) => toState(item)),
                totalSocialMedias: data.count,
            })
        })
        builder.addCase(getSocialMedias.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
})

export const { clearSocialMedia } = socialMedia.actions

const { reducer } = socialMedia
export default reducer
