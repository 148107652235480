import styled from 'styled-components'
import Button from '../button'

const StyledButton = styled(Button)`
    &&& {
        padding: 0px;
        svg {
            color: ${(props) => (props.$dark ? 'white' : 'black')};
        }
    }
`

export default StyledButton
