import { default as AntdBadge } from 'antd/lib/badge'
import PropTypes from 'prop-types'
import React from 'react'
import StyledParagraph from '../../components/styled_paragraph/StyledParagraph'

const AntdRibbon = AntdBadge.Ribbon
const Ribbon = (props) => {
    const { children, className, color, text } = props
    const ribbonText = <StyledParagraph $dark={true}>{text}</StyledParagraph>
    return (
        <AntdRibbon className={className} color={color} text={ribbonText}>
            {children}
        </AntdRibbon>
    )
}

Ribbon.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default Ribbon
