import React from 'react'
import PropTypes from 'prop-types'
import StyledAntdInputTextArea from './StyledAntdInputTextArea'

const TextArea = (props) => {
    const { dark, disabled, input, name, onChange, placeholder, rows, size, value } = props

    const handleChange = (e) => onChange(e.target.value, name, e)

    return (
        <StyledAntdInputTextArea
            {...input}
            $dark={dark}
            disabled={disabled}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            rows={rows}
            size={size}
            value={value}
        />
    )
}

TextArea.propTypes = {
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    size: PropTypes.string,
    value: PropTypes.string,
}

export default TextArea
