import PropTypes from 'prop-types'
import React from 'react'
import { FormItem, InputGroup } from '../../../antdcomponents'

const ReduxFormInputGroup = (props) => {
    const { disabled, dark, formItemProps, input, label, meta, placeholder, prefix } = props
    const { onChange, value, name } = input
    const { touched, error } = meta
    const isError = touched && !!error

    return (
        <FormItem
            {...formItemProps}
            dark={dark}
            help={isError && error}
            label={label}
            validateStatus={isError ? 'error' : undefined}
        >
            <InputGroup
                disabled={disabled}
                input={input}
                isError={isError}
                meta={meta}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                prefix={prefix}
                value={value}
            />
        </FormItem>
    )
}

ReduxFormInputGroup.propTypes = {
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
}

export default ReduxFormInputGroup
