export const toApi = (data) => ({
    account_number: data.accountNumber,
    bank_list_id: data.bankListId,
    created_by: data.createdBy,
    account_holder_name: data.accountHolderName,
})

export const toState = (data) => ({
    accountNumber: data.accountNumber || '',
    bankListId: data.bankListId || null,
    accountHolderName: data.accountHolderName || '',
})
