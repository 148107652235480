import { default as AntdTooltip } from 'antd/lib/tooltip'
import isNull from 'lodash/isNull'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '../../basic/colors'
import { StyledInfoCircleOutlined } from '../../components/StyledComponents'
import StyledSpan from '../../components/styled_span/StyledSpan'

const Tooltip = (props) => {
    const { placement, title } = props
    let content = props.content
    const styledTitle = <StyledSpan>{title}</StyledSpan>

    if (isNull(content)) {
        content = <StyledInfoCircleOutlined $color={colors.purpleHeart} />
    }

    return (
        <AntdTooltip placement={placement} title={styledTitle}>
            {content}
        </AntdTooltip>
    )
}

Tooltip.propTypes = {
    content: PropTypes.node,
    placement: PropTypes.string,
    title: PropTypes.string,
    trigger: PropTypes.string,
}

Tooltip.defaultProps = {
    content: null,
    placement: 'top',
    trigger: 'hover',
}

export default Tooltip
