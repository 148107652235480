import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useOutletContext } from 'react-router-dom'
import colors from '../basic/colors'
import HelmetMeta from '../components/HelmetMeta'
import { StyledSection } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import TermsAndConditionsText from '../components/terms_and_conditions_text/TermsAndConditionsText'
import { DATE_HOUR_SLASH_FORMAT_WITHOUTCOLON } from '../constants/formats'

const TermsAndConditions = (props) => {
    const { termsAndConditionsApprovalDate } = props
    const { dark, isPemodal, isPenerbit, params } = useOutletContext()
    const user = get(params, 'user', '')

    const metaTitle = `Syarat & Ketentuan ${capitalize(user)}`
    const ApprovalDate = moment(termsAndConditionsApprovalDate).format(DATE_HOUR_SLASH_FORMAT_WITHOUTCOLON)

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} />
            {!isEmpty(termsAndConditionsApprovalDate) &&
                ((isPemodal && user === 'pemodal') || (isPenerbit && user === 'penerbit')) && (
                    <Fragment>
                        <center>
                            <StyledTitle color={colors.salmon} level={5}>
                                {`telah dipahami dan disetujui pengguna pada ${ApprovalDate}`}
                            </StyledTitle>
                        </center>
                        <br />
                    </Fragment>
                )}
            <TermsAndConditionsText dark={dark} title={metaTitle} user={user} />
        </StyledSection>
    )
}

TermsAndConditions.propTypes = {
    termsAndConditionsApprovalDate: PropTypes.string,
}

export default TermsAndConditions
