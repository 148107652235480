import isMobile from 'is-mobile'
import divide from 'lodash/divide'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import size from 'lodash/size'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { useOutletContext } from 'react-router-dom'
import { Carousel } from '../../antdcomponents'
import Loader from '../../components/loader/Loader'
import ProductCardContainer from '../../components/product_card/ProductCardContainer'
import { StyledSection } from '../../components/StyledComponents'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { isProductionEnv } from '../../constants/constants'
import * as productCategorySlice from '../../features/product_category/productCategorySlice'
import { useGetProductCategoriesQuery } from '../../services/api'
import getServerStoreState from '../../utility/getServerStoreState'
import { renderLink } from '../../utility/renderLinkComp'
import {
    StyledProductCardContainer,
    StyledProductCategoryHeader,
    StyledProductCategoryTitle,
    StyledSeeAllProduct,
} from '../StyledComponents'

const ProductCategories = (props) => {
    const { getProductCategories, isProductCategoryLocalError, isProductCategoryLocalLoading, productCategories } =
        props

    const { productCategoriesRef, width } = useOutletContext()

    //for SSR only
    const { data, isError, isLoading, refetch } = useGetProductCategoriesQuery({
        options: { notExpired: 'true', withProducts: 'true' },
    })

    useEffect(() => {
        if (isProductionEnv) {
            //for SSR only
            refetch()
        } else {
            getProductCategories({
                options: { notExpired: 'true', withProducts: 'true' },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let currentProductCategories = productCategories
    let currentIsLoading = isProductCategoryLocalLoading
    let currentIsError = isProductCategoryLocalError
    //for SSR only
    if (isProductionEnv) {
        currentProductCategories = get(data, 'productCategories', [])
        currentIsLoading = isLoading
        currentIsError = isError
    }

    const getSlidesToShow = (productsSize) => {
        if (productsSize === 1) {
            return 1
        } else {
            let slidesToShow = divide(width - (isMobile() ? 70 : 200), 305) - 0.3
            slidesToShow = slidesToShow > productsSize ? productsSize : slidesToShow
            return slidesToShow
        }
    }

    const renderProductContent = (product, key, isLastProductRow) => (
        <StyledProductCardContainer $isLastProductRow={isLastProductRow} key={key}>
            <ProductCardContainer mini product={product} />
        </StyledProductCardContainer>
    )

    const renderProductCategory = (productCategory, productCategoryKey) => {
        const category = get(productCategory, 'title', '')
        const products = get(productCategory, 'products', [])
        const productsSize = size(products)
        const categoryRoute = kebabCase(category)

        const isLastProductRow = productCategoryKey === size(currentProductCategories) - 1
        let slidesToShow = getSlidesToShow(productsSize)

        const content = map(products, (product, productKey) =>
            renderProductContent(product, productKey, isLastProductRow)
        )

        return (
            <Fragment key={productCategoryKey}>
                <StyledProductCategoryHeader>
                    <StyledProductCategoryTitle>
                        <StyledTitle level={2}>{category}</StyledTitle>
                    </StyledProductCategoryTitle>
                    <StyledSeeAllProduct>
                        <StyledTitle level={5}>
                            {renderLink({ to: `/products?category=${categoryRoute}` }, `Lihat Semua >`)}
                        </StyledTitle>
                    </StyledSeeAllProduct>
                </StyledProductCategoryHeader>
                <Carousel
                    autoplay
                    autoplaySpeed={2000}
                    content={content}
                    dots={false}
                    slidesToShow={slidesToShow}
                    speed={1000}
                    variableWidth={true}
                />
            </Fragment>
        )
    }

    if (currentIsLoading) {
        return <Loader />
    }
    if (currentIsError) {
        return <div>Oh tidak, ada kesalahan</div>
    }

    return (
        <Fragment>
            <StyledSection ref={productCategoriesRef}>
                {map(currentProductCategories, (item, key) => renderProductCategory(item, key))}
            </StyledSection>
        </Fragment>
    )
}

ProductCategories.propTypes = {
    getProductCategories: PropTypes.func,
    isProductCategoryLocalError: PropTypes.bool,
    isProductCategoryLocalLoading: PropTypes.bool,
    productCategories: PropTypes.array,
}

const mapStateToProps = (state) => {
    const updatedState = getServerStoreState(state)

    return {
        isProductCategoryLocalError: updatedState.productCategory.isError,
        isProductCategoryLocalLoading: updatedState.productCategory.isLoading,
        productCategories: updatedState.productCategory.productCategories,
    }
}

const mapDispatchToProps = {
    getProductCategories: productCategorySlice.getProductCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategories)
