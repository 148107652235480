import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as authenticationSlice from '../../features/authentication/authenticationSlice'
import * as digisignSlice from '../../features/digisign/digisignSlice'
import scrollToViewFormError from '../../utility/scrollToViewFormError'
import Login from './Login'

let LoginContainer = (props) => <Login {...props} />

const mapStateToProps = (state) => ({
    authToken: state.authentication.authToken,
    currentRegisterStep: state.authentication.currentRegisterStep,
    customErrorMessage: state.authentication.customErrorMessage,
    failedLoginAttempts: state.authentication.failedLoginAttempts,
    initialValues: { user: 'pemodal' },
    isLoading: state.authentication.isLoadingAuthenticate,
    isValidUser: state.authentication.isValidUser,
    user: state.authentication.user,
})

const mapDispatchToProps = {
    authenticate: authenticationSlice.authenticate,
    clearCustomErrorMessage: authenticationSlice.clearCustomErrorMessage,
    digisignCallback: digisignSlice.digisignCallback,
}

LoginContainer = reduxForm({
    enableReinitialize: true,
    form: 'login',
    onSubmitFail: (errors) => scrollToViewFormError(errors),
})(LoginContainer)

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
