import isLocalStorageAvailable from './isLocalStorageAvailable'
import get from 'lodash/get'

const removePersistentData = (key, windowObj = {}) => {
    if (isLocalStorageAvailable(windowObj)) {
        let localStorage = get(windowObj, 'localStorage', {})
        if (typeof window !== 'undefined') {
            localStorage = window.localStorage
        }
        localStorage.removeItem(key)
        return
    }
    const expiry = '=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
    document.cookie = `${key}${expiry}`
    return
}

export default removePersistentData
