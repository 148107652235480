import React from 'react'
import { connect } from 'react-redux'
import * as cartSlice from '../features/cart/cartSlice'
import * as favoriteSlice from '../features/favorite/favoriteSlice'
import * as inboxSlice from '../features/inbox/inboxSlice'
import * as productSlice from '../features/product/productSlice'
import ProductDetail from './ProductDetail'

const ProductDetailContainer = (props) => <ProductDetail {...props} />

const mapStateToProps = (state) => ({
    id: state.authentication.id,
    isBankinfoExist: state.authentication.isBankinfoExist,
    favorites: state.favorite.favorites,
    inboxes: state.inbox.inboxes,
    isActionLoading: state.cart.isActionLoading,
    isActionSuccess: state.cart.isActionSuccess,
    isActive: state.authentication.isActive,
    isError: state.product.isError,
    isLoadingCart: state.cart.isLoading,
    isLoadingProduct: state.product.isLoading,
    product: state.product.product,
    totalPurchaseOrders: state.purchaseOrder.totalPurchaseOrders,
})

const mapDispatchToProps = {
    clearCart: cartSlice.clearCart,
    clearProduct: productSlice.clearProduct,
    createCart: cartSlice.createCart,
    createFavorite: favoriteSlice.createFavorite,
    deleteFavorite: favoriteSlice.deleteFavorite,
    getCart: cartSlice.getCart,
    getInboxes: inboxSlice.getInboxes,
    getProduct: productSlice.getProduct,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailContainer)
