import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import * as inboxDetailTransformer from './inboxDetail'
import * as pemodalTransformer from './pemodal'
import * as penerbitTransformer from './penerbit'
import * as productTransformer from './product'
import * as purchaseOrderTransformer from './purchaseOrder'

export const toApi = (data) => {
    const inbox_detail = !isEmpty(data.inboxDetail) ? inboxDetailTransformer.toApi(data.inboxDetail) : undefined
    return {
        created_by: data.createdBy,
        entity_code: data.entityCode,
        id: data.id,
        inbox_detail,
        product_id: data.productId,
        purchase_order_id: data.purchaseOrderId,
        from_table: data.fromTable,
        status: data.status,
        to_table: data.toTable,
    }
}

export const toState = (data) => {
    const inboxDetails = !isEmpty(data.inboxDetails)
        ? map(data.inboxDetails, (item) => inboxDetailTransformer.toState(item))
        : []
    const pemodal = !isEmpty(data.pemodal) ? pemodalTransformer.toState(data.pemodal) : undefined
    const penerbit = !isEmpty(data.penerbit) ? penerbitTransformer.toState(data.penerbit) : undefined

    return {
        fromTable: data.fromTable || '',
        id: data.id || null,
        inboxDetails,
        pemodal,
        pemodalId: data.pemodalId || null,
        penerbit,
        penerbitId: data.penerbitId || null,
        productId: data.productId || null,
        product: data.product ? productTransformer.toState(data.product) : {},
        purchaseOrderId: data.purchaseOrderId || null,
        purchaseOrder: data.purchaseOrder ? purchaseOrderTransformer.toState(data.purchaseOrder) : {},
        status: data.status || '',
        toTable: data.toTable || '',
    }
}
