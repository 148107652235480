// replace default theme in here
// see https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

const colors = require('./colors')

const fontStyle = 'normal !important'
const fontWeight = 'normal !important'
const arTechniFont = 'AR Techni'
const hammersmithOneFont = 'Hammersmith One'

const headingStyle = {
    fontStyle,
    fontWeight,
    fontSizeDesktop: ['48px', '36px', '24px', '20px', '20px'],
    fontSizeMobile: ['36px', '20px', '16px', '16px', '14px'],
    fontFamily: [hammersmithOneFont, hammersmithOneFont, arTechniFont, hammersmithOneFont, arTechniFont],
}

const priceTitleStyle = {
    fontStyle,
    fontWeight,
    fontSizeDesktop: '28px',
    fontSizeMobile: '24px',
    fontFamily: hammersmithOneFont,
}

const paragraphStyle = {
    fontStyle,
    fontWeight,
    fontSizeDesktop: '18px',
    fontSizeMobile: '12px',
    fontFamily: arTechniFont,
}

const spanStyle = {
    fontStyle,
    fontWeight,
    fontSizeDesktop: '12px',
    fontSizeMobile: '9px',
    fontFamily: arTechniFont,
}

const tableHeaderStyle = {
    fontStyle,
    fontWeight,
    fontSizeDesktop: '16px',
    fontSizeMobile: '12px',
    fontFamily: arTechniFont,
}

// Base Scaffolding Variables
// ---
const base = {
    '@border-radius-base': '20px',
    '@btn-font-size-sm': '16px',
    '@font-family': arTechniFont,
    '@font-size-base': '14px',
    '@font-size-lg': '20px',
    '@heading-color': colors.persianIndigo,
    '@text-color': colors.persianIndigo,
}

// Buttons
const buttons = {
    '@btn-height-lg': '55px',
}

// -------- Colors -----------
const antdColors = {
    // >>> Primary
    '@primary-color': colors.persianIndigo,
}

// Disabled states
const disabledStates = {
    '@disabled-color': 'silver',
}

// Tooltip
// ---
const tooltip = {
    '@tooltip-bg': 'white',
    '@tooltip-border-radius': '50px',
    '@tooltip-color': colors.graniteGray,
}

const defaultModifyVars = {
    // Fonts
    arTechniFont,
    fontStyle,
    fontWeight,
    hammersmithOneFont,
    headingStyle,
    paragraphStyle,
    priceTitleStyle,
    spanStyle,
    tableHeaderStyle,
}

const modifyVars = Object.assign(antdColors, base, buttons, defaultModifyVars, disabledStates, tooltip)

module.exports = modifyVars
