export const recieverTypeOptions = [
    {
        label: 'Semua',
        value: 'all',
    },
    {
        label: 'Belum Dibaca',
        value: 'unread',
    },
    {
        label: 'Diproses',
        value: 'in progress',
    },
    {
        label: 'Selesai',
        value: 'expired',
    },
]

export const sendToOptions = (penerbitName) => [
    {
        label: penerbitName,
        value: 'penerbit',
    },
    {
        label: 'Admin Moneypool',
        value: 'adminUser',
    },
]
