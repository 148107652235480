import concat from 'lodash/concat'
import get from 'lodash/get'
import includes from 'lodash/includes'
import startCase from 'lodash/startCase'
import * as productOptions from '../common/options/product'
import * as purchaseOrderOptions from '../common/options/purchaseOrder'
import getLabelOption from './getLabelOption'

const getRenderStatusPurchaseOrder = (purchaseOrder) => {
    const purchaseOrderStatus = get(purchaseOrder, 'status', '')
    const product = get(purchaseOrder, 'product', {})

    const productStatus = get(product, 'status', '')

    const labelPurchaseOrderStatus = getLabelOption(purchaseOrderStatus, purchaseOrderOptions.statusOptions)
    const isShowProductStatus = includes(
        concat(productOptions.canceledStatus, productOptions.finishedStatus),
        productStatus
    )
    const labelProductStatus = isShowProductStatus ? `(${startCase(productStatus)} Offer)` : ''

    return `${labelPurchaseOrderStatus}\n${labelProductStatus}`
}

export default getRenderStatusPurchaseOrder
