import { default as AntdCol } from 'antd/lib/col'
import { default as AntdProgress } from 'antd/lib/progress'
import { default as AntdRow } from 'antd/lib/row'
import find from 'lodash/find'
import first from 'lodash/first'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Button } from '../../antdcomponents'
import colors from '../../basic/colors'
import gradientColors from '../../basic/gradientColors'
import imagePath from '../../basic/imagePath'
import formatPrice from '../../utility/formatPrice'
import * as notification from '../../utility/notification'
import { renderLink } from '../../utility/renderLinkComp'
import FavoriteModal from '../favorite_modal/FavoriteModal'
import {
    StyledAntdCol,
    StyledBottomText,
    StyledCard,
    StyledEmpty,
    StyledFavoriteImage,
    StyledImage,
    StyledProductDetail,
    StyledProductSummary,
    StyledRibbon,
    StyledTitleText,
    StyledValueText,
} from './StyledComponents'

const ProductCard = (props) => {
    const { authToken, createFavorite, deleteFavorite, favorites, isError, isLoading, product, mini } = props
    const {
        category,
        collectedFundPercentage,
        dividendPeriod,
        id,
        interestRate,
        isObligation,
        isProductStarted,
        name,
        numberShare,
        pricePerShare,
        productImages,
        productRibbon,
        remainingDate,
        slug,
        target,
        turnoverLastYear,
        type,
    } = product
    const { isPenerbit, navigate } = useOutletContext()

    const productId = get(product, 'id', null)
    const productRibbonTitle = get(productRibbon, 'title', '')

    const [isFavorited, setIsFavorited] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const handleCancel = () => setIsModalOpen(false)

    useEffect(() => {
        if (!isError && !isLoading) {
            const newIsFavorited = !isEmpty(find(favorites, (item) => item.productId === id))
            setIsFavorited(newIsFavorited)
        }
    }, [favorites, id, isError, isLoading])

    const toogleFavorite = (e) => {
        e.preventDefault()
        if (authToken) {
            if (isFavorited) {
                deleteFavorite(productId)
                notification.success('Penawaran telah dihapus dari favorit')
            } else {
                createFavorite({ productId })
                notification.success('Penawaran telah ditambah ke favorit')
            }
        } else {
            setIsModalOpen(true)
        }
    }

    const productNameSection = () => {
        const heartImage = <StyledFavoriteImage src={isFavorited ? imagePath.heartFill : imagePath.heartEmpty} />
        return (
            <Fragment>
                <AntdCol span={24}>
                    <StyledTitleText color={colors.blueberry2}>{`${category} | ${type}`}</StyledTitleText>
                </AntdCol>
                <StyledAntdCol span={20}>
                    <StyledValueText color='black' size='md'>
                        {name}
                    </StyledValueText>
                </StyledAntdCol>
                <AntdCol span={4}>
                    <AntdRow>
                        {!isPenerbit && (
                            <Button name='favorite-btn' onClick={toogleFavorite} type='text' value={heartImage} />
                        )}
                    </AntdRow>
                </AntdCol>
            </Fragment>
        )
    }

    const crowdFundSection = () => (
        <Fragment>
            <AntdCol span={16}>
                <StyledValueText color={colors.blueberry2} size={target / 100000000000 < 1 ? 'md' : 'sm'}>
                    {`Rp ${formatPrice(target)}`}
                </StyledValueText>
            </AntdCol>
            <AntdCol span={8}>
                <AntdRow justify='end'>
                    <StyledBottomText>
                        <StyledTitleText color={colors.graniteGray}>Pendanaan</StyledTitleText>
                    </StyledBottomText>
                </AntdRow>
            </AntdCol>
        </Fragment>
    )

    const endDateSection = () => (
        <Fragment>
            <AntdCol span={16}>
                <StyledValueText color={colors.graniteGray} size='md'>
                    {remainingDate}
                </StyledValueText>
            </AntdCol>
            <AntdCol span={8}>
                <AntdRow justify='end'>
                    <StyledBottomText>
                        <StyledTitleText color={colors.graniteGray}>
                            {isProductStarted ? 'Sisa Hari' : 'Hari Lagi'}
                        </StyledTitleText>
                    </StyledBottomText>
                </AntdRow>
            </AntdCol>
        </Fragment>
    )

    const renderProductCardSummary = () => (
        <StyledProductSummary mini={mini}>
            <AntdRow align='middle'>
                {productNameSection()}
                {crowdFundSection()}
                {endDateSection()}
            </AntdRow>
        </StyledProductSummary>
    )

    const CardDetailSection = (title, value, colored) => (
        <Fragment>
            <StyledTitleText color={colors.graniteGray}>{title}</StyledTitleText>
            <StyledValueText color={colored ? colors.blueberry2 : null}>{value}</StyledValueText>
        </Fragment>
    )

    const renderProductCardDetail = (mini) => (
        <StyledProductDetail>
            <AntdRow>
                <AntdCol span={24}>
                    <AntdProgress
                        percent={collectedFundPercentage}
                        showInfo={false}
                        strokeColor={gradientColors.progress}
                    />
                </AntdCol>
                {!mini && (
                    <Fragment>
                        <AntdCol span={12}>
                            {CardDetailSection('Harga per Lembar', `Rp ${formatPrice(pricePerShare)}`, true)}
                        </AntdCol>
                        <AntdCol span={12}>{CardDetailSection('Jumlah Lembar', numberShare, true)}</AntdCol>
                    </Fragment>
                )}
                <AntdCol span={12}>{CardDetailSection('Periode Dividen', dividendPeriod, true)}</AntdCol>
                {!mini && (
                    <Fragment>
                        <AntdCol span={12}>
                            {isObligation && CardDetailSection('Suku Bunga', `${interestRate}%`, true)}
                        </AntdCol>
                        <AntdCol span={12}>
                            {CardDetailSection(`Omzet tahun lalu`, `Rp ${formatPrice(turnoverLastYear)}`, false)}
                        </AntdCol>
                    </Fragment>
                )}
            </AntdRow>
            {!isEmpty(productRibbon) && (
                <StyledRibbon
                    $mini={mini}
                    color={`#${get(productRibbon, 'colorCode', '')}`}
                    text={productRibbonTitle}
                />
            )}
        </StyledProductDetail>
    )

    const renderProductImage = () => {
        const productImageUrl = get(first(productImages), 'url', '')
        const productImage = <StyledImage src={productImageUrl} />
        return productImageUrl !== '' ? productImage : <StyledEmpty image={imagePath.noDataGray} />
    }

    return (
        <Fragment>
            {renderLink(
                { to: `/product/${slug}` },
                <StyledCard mini={mini}>
                    {renderProductImage()}
                    {renderProductCardSummary()}
                    {renderProductCardDetail(mini)}
                </StyledCard>
            )}
            <FavoriteModal isModalOpen={isModalOpen} navigate={navigate} onCancel={handleCancel} />
        </Fragment>
    )
}

ProductCard.propTypes = {
    authToken: PropTypes.string,
    createFavorite: PropTypes.func,
    deleteFavorite: PropTypes.func,
    favorites: PropTypes.array,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    mini: PropTypes.bool,
    product: PropTypes.object.isRequired,
}

ProductCard.defaultProps = {
    mini: false,
}

export default ProductCard
