import { default as AntdTabs } from 'antd/lib/tabs'
import map from 'lodash/map'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import { default as Icon } from '../icon'

const TabPane = AntdTabs.TabPane

const Tabs = (props) => {
    const handleChange = (activeKey) => {
        const { onChange } = props
        onChange(activeKey)
    }

    const mapContent = () => {
        const { panes } = props

        return map(panes, (pane, key) => {
            const title = pane.icon ? (
                <span>
                    <Icon type={pane.icon} />
                    {pane.title}
                </span>
            ) : (
                pane.title
            )

            return (
                <TabPane
                    closable={pane.closable}
                    disabled={pane.disabled}
                    key={(Number(key) + 1).toString()}
                    tab={title}
                >
                    {pane.content}
                </TabPane>
            )
        })
    }

    const {
        activeKey,
        animated,
        centered,
        className,
        hideAdd,
        name,
        size,
        tabBarExtraContent,
        tabBarGutter,
        tabBarStyle,
        tabPosition,
        type,
    } = props

    return (
        <AntdTabs
            activeKey={activeKey}
            animated={animated}
            centered={centered}
            className={className}
            destroyInactiveTabPane
            hideAdd={hideAdd}
            name={name}
            onChange={handleChange}
            size={size}
            tabBarExtraContent={tabBarExtraContent}
            tabBarGutter={tabBarGutter}
            tabBarStyle={tabBarStyle}
            tabPosition={tabPosition}
            type={type}
        >
            {mapContent()}
        </AntdTabs>
    )
}

Tabs.propTypes = {
    activeKey: PropTypes.string,
    animated: PropTypes.bool,
    centered: PropTypes.bool,
    className: PropTypes.string,
    defaultActiveKey: PropTypes.string,
    hideAdd: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    panes: PropTypes.arrayOf(
        PropTypes.shape({
            closable: PropTypes.bool,
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            disabled: PropTypes.bool,
            forceRender: PropTypes.bool,
            icon: PropTypes.string,
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        })
    ),
    size: PropTypes.oneOf(['default', 'small', ' large']),
    tabBarExtraContent: PropTypes.node,
    tabBarGutter: PropTypes.number,
    tabBarStyle: PropTypes.object,
    tabPosition: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
    type: PropTypes.oneOf(['card', 'line', 'editable-card']),
}

Tabs.defaultProps = {
    centered: false,
    hideAdd: false,
    onChange: noop,
    panes: [],
    size: 'default',
    tabBarExtraContent: null,
    tabPosition: 'top',
    type: 'line',
}

export default Tabs
