import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import * as productAPI from './productAPI'
import { toState } from '../../transformers/product'

const initialState = {
    isError: false,
    isLoading: false,
    product: {},
    products: [],
    totalProducts: 0,
}

export const { getFunds, getPortfolio, getProduct, getProducts } = productAPI

const productSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getFunds.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                products: map(data.products, (item) => toState(item)),
            })
        })
        builder.addCase(getProduct.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                product: {},
            })
        )
        builder.addCase(getProduct.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                product: toState(data.product),
            })
        })
        builder.addMatcher(isAnyOf(getFunds.pending, getPortfolio.pending, getProducts.pending), (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                products: [],
            })
        )
        builder.addMatcher(isAnyOf(getPortfolio.fulfilled, getProducts.fulfilled), (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                products: map(data.products, (item) => toState(item)),
                totalProducts: data.count,
            })
        })
        builder.addMatcher(
            isAnyOf(getFunds.rejected, getPortfolio.rejected, getProduct.rejected, getProducts.rejected),
            (state) =>
                Object.assign(state, {
                    isError: true,
                    isLoading: false,
                })
        )
    },
    initialState,
    name: 'PRODUCT',
    reducers: {
        clearProduct: () => initialState,
    },
})

export const { clearProduct } = productSlice.actions

const { reducer } = productSlice
export default reducer
