import styled from 'styled-components'
import FormItem from '../../form_item/FormItem'

export const StyledFormItem = styled(FormItem)`
    &&& {
        .ant-form-item-control-input > span.ant-form-item-children-icon {
            display: none;
        }
    }
`
