import { default as AntdSlider } from 'antd/lib/slider'
import styled from 'styled-components'
import colors from '../../../basic/colors'

export const StyledAntdSlider = styled(AntdSlider)`
    &&& {
        .ant-slider-handle {
            background-color: ${(props) => (props.$dark ? 'white' : colors.persianIndigo)};
        }
        .ant-slider-mark-text {
            width: 100%;
        }
    }
`
