import React from 'react'
import ReactDOM from 'react-dom'

// import reportWebVitals from './reportWebVitals'
import App from './App'

const rootElement = document.getElementById('root')
let renderMethod = ReactDOM.hydrate
if (module.hot) {
    renderMethod = ReactDOM.render
}

renderMethod(<App />, rootElement)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
