import { default as AntdModal } from 'antd/lib/modal'
// import get from 'lodash/get'
// import React from 'react'
// import colors from '../basic/colors'
// import StyledTitle from '../components/styled_title/StyledTitle'

// TODO: clarify
// const styledText = (options) => {
//     const content = (
//         <StyledTitle color={colors.doveGray} level={3}>
//             {get(options, 'content', '')}
//         </StyledTitle>
//     )
//     const title = (
//         <StyledTitle color={colors.graniteGray} level={2}>
//             {get(options, 'title', '')}
//         </StyledTitle>
//     )
//     return Object.assign(options, {
//         content,
//         title,
//     })
// }

const triggerModal = (type, options) =>
    AntdModal[type](
        Object.assign(
            {
                cancelText: 'Batal',
                centered: true,
                destroyOnClose: true,
                maskClosable: false,
                okText: 'Oke',
            },
            options
        )
    )

const confirm = (options) => triggerModal('confirm', options)
const error = (options) => triggerModal('error', options)
const info = (options) => triggerModal('info', options)
const success = (options) => triggerModal('success', options)
const warning = (options) => triggerModal('warning', options)

const { destroyAll } = AntdModal

export { confirm, destroyAll, error, info, success, warning }
