import { default as AntdDivider } from 'antd/lib/divider'
import styled from 'styled-components'
import { Select } from '../antdcomponents'

export const StyledAntdDivider = styled(AntdDivider)`
    &&& {
        background: white;
    }
`

export const StyledSelect = styled(Select)`
    &&& {
        background-color: unset;
        width: 100%;
        div.ant-select-selector {
            background-color: white;
        }
    }
`
