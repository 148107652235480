import React from 'react'
import { connect } from 'react-redux'
import * as faqSlice from '../features/faq/faqSlice'
import Faq from './Faq'

const FaqContainer = (props) => <Faq {...props} />

const mapStateToProps = (state) => ({
    faqs: state.faq.faqs,
    isError: state.faq.isError,
    isLoading: state.faq.isLoading,
})

const mapDispatchToProps = {
    clearFaq: faqSlice.clearFaq,
    getFaqs: faqSlice.getFaqs,
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqContainer)
