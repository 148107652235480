import { default as AntdCard } from 'antd/lib/card'
import { default as AntdDivider } from 'antd/lib/divider'
import styled, { css } from 'styled-components'
import colors from '../../basic/colors'
import gradientColors from '../../basic/gradientColors'
import StyledTitle from '../../components/styled_title/StyledTitle'

const Container = css`
    padding-left: 20px;
    width: 100%;
`

export const StyledAntdCard = styled(AntdCard)`
    &&& {
        background: ${gradientColors.profileCard};
        border: 2px solid ${colors.aquamarine};
        border-radius: 20px;
        .ant-card-body {
            padding: 20px;
        }

        @media screen and (min-width: 768px) {
            border-radius: 40px;
        }
    }
`

export const StyledAntdDivider = styled(AntdDivider)`
    &&& {
        border: 1px solid ${colors.aquamarine};
    }
`

export const StyledMenuContainer = styled.div`
    padding-left: 0px;
    width: 100%;

    @media screen and (min-width: 768px) {
        ${Container}
    }
`

export const StyledMenuTitle = styled(StyledTitle)`
    text-align: left;
`

export const StyledUserBlockedText = styled.div`
    line-height: 1;
`

export const StyledWelcomeContainer = styled.div`
    padding-left: 0px;
    width: 100%;
    @media screen and (min-width: 768px) {
        ${Container}
    }
`
