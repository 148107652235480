import get from 'lodash/get'
import toLower from 'lodash/toLower'
import omit from 'lodash/omit'
import * as request from '../client/request'

const superagentBaseQuery =
    () =>
    async ({ url, method, data }) => {
        try {
            const result = await request[toLower(method)](url, data).then((response) => response.body)
            return omit(result, ['status'])
        } catch (superagentError) {
            let err = superagentError
            // eslint-disable-next-line
            console.log(err)
            return {
                error: {
                    status: get(err, 'response.status'),
                    data: get(err, 'response.data'),
                },
            }
        }
    }

export default superagentBaseQuery
