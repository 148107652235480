import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/penerbit'
import * as penerbitAPI from './penerbitAPI'

const initialState = {
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    penerbit: {},
    penerbits: [],
}

export const { createPenerbit, getPenerbit, getPenerbits, updatePenerbit } = penerbitAPI

const penerbitSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getPenerbit.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                penerbit: {},
            })
        )
        builder.addCase(getPenerbit.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                penerbit: toState(data.penerbit),
            })
        })
        builder.addCase(getPenerbit.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
        builder.addCase(getPenerbits.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                penerbits: [],
            })
        )
        builder.addCase(getPenerbits.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                penerbits: map(data.penerbits, (item) => toState(item)),
            })
        })
        builder.addMatcher(isAnyOf(createPenerbit.pending, updatePenerbit.pending), (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addMatcher(isAnyOf(createPenerbit.fulfilled, updatePenerbit.fulfilled), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
        builder.addMatcher(isAnyOf(createPenerbit.rejected, updatePenerbit.rejected), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true,
            })
        )
    },
    initialState,
    name: 'PENERBIT',
    reducers: {
        clearPenerbit: () => initialState,
    },
})

export const { clearPenerbit } = penerbitSlice.actions

const { reducer } = penerbitSlice
export default reducer
