import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/favorite'
import * as api from '../api'

export const createFavorite = createAsyncThunk('CREATE_FAVORITE', async (data, { rejectWithValue }) => {
    try {
        const res = await api.create('/favorite/create', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const deleteFavorite = createAsyncThunk('DELETE_FAVORITE', async (product_id, { rejectWithValue }) => {
    try {
        const res = await api.deleteByProductId('/favorite/delete', product_id)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getFavorites = createAsyncThunk(
    'GET_FAVORITES',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/favorites', { limit, offset, options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
