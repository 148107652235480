import PropTypes from 'prop-types'
import React from 'react'
import { DatePicker, FormItem } from '../../../antdcomponents'

const ReduxFormDatePicker = (props) => {
    const { dark, disabled, formItemProps, input, label, meta, placeholder, size } = props

    const { onChange, value, name } = input
    const { touched, error } = meta
    const isError = touched && !!error

    return (
        <FormItem
            {...formItemProps}
            dark={dark}
            help={isError && error}
            label={label}
            validateStatus={isError ? 'error' : undefined}
        >
            <DatePicker
                dark={dark}
                disabled={disabled}
                input={input}
                isError={isError}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                size={size}
                value={value}
            />
        </FormItem>
    )
}

ReduxFormDatePicker.propTypes = {
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    formItemProps: PropTypes.object,
    format: PropTypes.string,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.string,
}

export default ReduxFormDatePicker
