import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { toState } from '../../transformers/pemodal'
import * as pemodalAPI from './pemodalAPI'

const initialState = {
    isActionLoading: false,
    isActionSuccess: false,
    isDuplicate: false,
    isError: false,
    isLoading: false,
    pemodal: {},
}

export const { createPemodal, getPemodalByEmail, getPemodalByMobileNumber, getPemodalProfile, updatePemodal } =
    pemodalAPI

const pemodalSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getPemodalProfile.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                pemodal: {},
            })
        )
        builder.addCase(getPemodalProfile.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                pemodal: toState(data.pemodal),
            })
        })
        builder.addMatcher(isAnyOf(getPemodalByEmail.pending, getPemodalByMobileNumber.pending), (state) =>
            Object.assign(state, {
                isDuplicate: false,
                isError: false,
                isLoading: true,
            })
        )
        builder.addMatcher(
            isAnyOf(getPemodalByEmail.fulfilled, getPemodalByMobileNumber.fulfilled),
            (state, action) => {
                const data = get(action, 'data', {})
                return Object.assign(state, {
                    isDuplicate: !isEmpty(data.pemodal),
                    isLoading: false,
                })
            }
        )
        builder.addMatcher(isAnyOf(createPemodal.pending, updatePemodal.pending), (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addMatcher(isAnyOf(createPemodal.fulfilled, updatePemodal.fulfilled), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
        builder.addMatcher(isAnyOf(createPemodal.rejected, updatePemodal.rejected), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true,
            })
        )

        builder.addMatcher(isAnyOf(getPemodalByEmail.rejected, getPemodalProfile.rejected), (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'PEMODAL',
    reducers: {
        clearPemodal: () => initialState,
    },
})

export const { clearPemodal } = pemodalSlice.actions

const { reducer } = pemodalSlice
export default reducer
