import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import React, { useState } from 'react'
import HelmetMeta from '../../components/HelmetMeta'
import { StyledSection } from '../../components/StyledComponents'
import ResetPasswordFormContainer from './ResetPasswordFormContainer'
import Success from './Success'

const ResetPassword = () => {
    const [pageNumber, setPageNumber] = useState(0)
    const metaTitle = 'Set Ulang Kata Sandi'

    const resetPasswordFormContainer = <ResetPasswordFormContainer setPageNumber={setPageNumber} title={metaTitle} />
    const success = <Success />
    const resetPasswordStep = [resetPasswordFormContainer, success]

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <AntdRow justify='center'>
                <AntdCol>{resetPasswordStep[pageNumber]}</AntdCol>
            </AntdRow>
        </StyledSection>
    )
}

export default ResetPassword
