import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import colors from '../../basic/colors'
import StyledParagraph from '../styled_paragraph/StyledParagraph'
import StyledTitle from '../styled_title/StyledTitle'
import { getTermsAndConditions } from './helpers'

const TermsAndConditionsText = (props) => {
    const { dark, title, user } = props

    return (
        <Fragment>
            <AntdRow justify={isMobile() ? 'left' : 'center'}>
                <StyledTitle $dark={dark} level={2}>
                    {title}
                </StyledTitle>
            </AntdRow>
            <br />
            <br />
            <StyledParagraph $textAlign='justify' color={colors.graniteGray}>
                {getTermsAndConditions(user)}
            </StyledParagraph>
        </Fragment>
    )
}

TermsAndConditionsText.propTypes = {
    dark: PropTypes.bool,
    title: PropTypes.string,
    user: PropTypes.string,
}

export default TermsAndConditionsText
