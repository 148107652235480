import first from 'lodash/first'
import isObject from 'lodash/isObject'

const scrollToViewFormError = (errors) => {
    let firstFieldError = first(Object.keys(errors))
    const firstField = errors[firstFieldError]
    if (isObject(firstField)) {
        firstFieldError = `${firstFieldError}.${first(Object.keys(firstField))}`
    }
    const field = document.querySelector(`[name="${firstFieldError}"]`)
    if (field) {
        field.scrollIntoView({ behavior: 'smooth' })
    }
}

export default scrollToViewFormError
