import { default as AntdAvatar } from 'antd/lib/avatar'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import round from 'lodash/round'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Tooltip } from '../../antdcomponents'
import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import AvatarPicker from '../../components/avatar_picker/AvatarPicker'
import {
    StyledAntdAvatar,
    StyledAntdSpace,
    StyledCheckCircleFilled,
    StyledExclamationCircleFilled,
    StyledInfoCircleOutlined,
    StyledRightCircleOutlined,
    StyledUnderlinedText,
} from '../../components/StyledComponents'
import StyledSpan from '../../components/styled_span/StyledSpan'
import StyledTitle from '../../components/styled_title/StyledTitle'
import formatPrice from '../../utility/formatPrice'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { renderLink } from '../../utility/renderLinkComp'
import {
    StyledAntdCard,
    StyledAntdDivider,
    StyledMenuContainer,
    StyledMenuTitle,
    StyledUserBlockedText,
    StyledWelcomeContainer,
} from './StyledComponents'

const PemodalProfile = (props) => {
    const {
        colorSelector,
        clearDigisign,
        digisignActivationUrl,
        email,
        generateLinkActivation,
        getPemodalTransactionSummary,
        initialValueTotal,
        isActionLoadingDigisign,
        isActive,
        isBankinfoExist,
        isDetailExist,
        isDigisignActivated,
        isKycStatusSuccess,
        isKycStatusVerified,
        isTermsAndConditionsApproved,
        isRiskStatementApproved,
        isValidUser,
        pemodalStatement,
        profilePictureUrl,
        refetchUser,
        totalInboxes,
        username,
        ytdValueTotal,
    } = props
    const { dark } = useOutletContext()

    useEffect(() => {
        const fetchPemodalTransactionSummary = async () => await getPemodalTransactionSummary()
        fetchPemodalTransactionSummary()
    }, [getPemodalTransactionSummary])

    useEffect(() => {
        if (!isActionLoadingDigisign) {
            refetchUser({ email }).then(() => {
                if (isEmpty(digisignActivationUrl)) {
                    return null
                }

                Promise.resolve(window.open(digisignActivationUrl, '_blank')).then(() => clearDigisign())
            })
        }
    }, [clearDigisign, digisignActivationUrl, email, isActionLoadingDigisign, refetchUser])

    const getMenuIcon = (menu) => {
        const content = <StyledExclamationCircleFilled $color={colors.salmon} />
        const title = menu.preliminary ? 'Segera kirim data anda' : 'Lengkapi data anda'
        const tooltipNoData = <Tooltip content={content} placement='right' title={title} />

        const completedIcon = <StyledCheckCircleFilled $color={colors.aquamarine} />

        if (!isUndefined(menu.value)) {
            if (menu.value > 0) {
                return <StyledAntdAvatar size='small'>{menu.value}</StyledAntdAvatar>
            }
        } else if (!menu.completed) {
            return tooltipNoData
        } else if (menu.completed) {
            return completedIcon
        }
    }

    const renderUserWelcome = () => (
        <StyledWelcomeContainer>
            <AntdSpace direction='vertical'>
                <AntdSpace align='center' size='large'>
                    {profilePictureUrl ? (
                        <AntdAvatar size={64} src={profilePictureUrl} />
                    ) : (
                        <AvatarPicker $size={64} colorSelector={colorSelector} profileInitial={username} />
                    )}
                    <AntdSpace direction='vertical'>
                        <StyledTitle $dark={dark} $lineHeight={1} level={2}>
                            {`Hi, ${username}!`}
                        </StyledTitle>
                        {!isActive && (
                            <StyledUserBlockedText>
                                <StyledSpan $dark={dark}>
                                    User telah diblokir. Silahkan hubungi tim Moneypool via&nbsp;
                                    <a href='https://api.whatsapp.com/send/?phone='>
                                        <StyledUnderlinedText>whatsapp</StyledUnderlinedText>
                                    </a>
                                    &nbsp;atau&nbsp;
                                    {renderLink(
                                        { to: '/contact-us' },
                                        <StyledUnderlinedText>email</StyledUnderlinedText>
                                    )}
                                </StyledSpan>
                            </StyledUserBlockedText>
                        )}
                    </AntdSpace>
                </AntdSpace>
            </AntdSpace>
        </StyledWelcomeContainer>
    )

    const renderUserInfo = () => {
        const dividendPercentage = round((ytdValueTotal - initialValueTotal) / initialValueTotal, 2)

        const historyImage = <img src={imagePath.history} />
        const portfolioImage = <img src={imagePath.portfolio} />

        const content = <StyledInfoCircleOutlined $color='white' />
        const tooltipStatementPemodal = (
            <Tooltip content={content} title='Total order baru kurang dari kurun waktu 2x24 jam' />
        )

        const tooltipTotalValue = <Tooltip content={content} title='Total value awal + keuntungan' />

        return (
            <StyledAntdCard>
                <AntdRow align='middle' justify='space-between'>
                    <AntdCol span={16}>
                        <AntdSpace>
                            <StyledTitle $dark={dark} level={5}>
                                Statement Pemodal &nbsp;
                            </StyledTitle>
                            {tooltipStatementPemodal}
                        </AntdSpace>
                        <StyledTitle $dark={dark} level={4}>
                            {`Rp ${formatPrice(pemodalStatement)}`}
                        </StyledTitle>
                    </AntdCol>
                    <AntdCol span={8}>
                        <AntdRow justify='end'>
                            <AntdSpace align='end'>
                                {renderLink(
                                    { to: '/portfolio' },
                                    <center>
                                        {renderLazyLoadImage(portfolioImage)}
                                        <StyledSpan $dark={dark}>Portfolio</StyledSpan>
                                    </center>
                                )}
                                {renderLink(
                                    { to: '/transaction-histories' },
                                    <center>
                                        {renderLazyLoadImage(historyImage)}
                                        <StyledSpan $dark={dark}>Riwayat</StyledSpan>
                                    </center>
                                )}
                            </AntdSpace>
                        </AntdRow>
                    </AntdCol>
                </AntdRow>
                <StyledAntdDivider />
                <AntdRow>
                    <AntdCol span={12}>
                        <StyledTitle $dark={dark} level={5}>
                            Total Value Awal
                        </StyledTitle>
                    </AntdCol>
                    <AntdCol offset={1} span={11}>
                        <StyledTitle $dark={dark} level={5}>
                            {`Rp ${formatPrice(initialValueTotal)}`}
                        </StyledTitle>
                    </AntdCol>
                    <AntdCol span={12}>
                        <AntdSpace>
                            <StyledTitle $dark={dark} level={5}>
                                Total Value &nbsp;
                            </StyledTitle>
                            {tooltipTotalValue}
                        </AntdSpace>
                    </AntdCol>
                    <AntdCol offset={1} span={11}>
                        <StyledTitle $dark={dark} level={5}>
                            {`Rp ${formatPrice(ytdValueTotal)} (+${
                                !isNaN(dividendPercentage) ? dividendPercentage : 0
                            }%)`}
                        </StyledTitle>
                    </AntdCol>
                </AntdRow>
            </StyledAntdCard>
        )
    }

    const redirectDigisingUrl = (e) => {
        e.preventDefault()
        generateLinkActivation()
    }

    const renderMenus = () => {
        const menus = [
            {
                completed: isValidUser,
                title: 'Akun Profil',
                to: '/profile/detail',
            },
            {
                completed: (isDigisignActivated && isKycStatusSuccess) || isKycStatusVerified,
                preliminary: isDetailExist,
                title: 'Screening',
                to: '/profile/screening',
            },
            {
                completed: isBankinfoExist,
                title: 'Rekening Bank',
                to: '/profile/bank',
            },
            {
                completed: isTermsAndConditionsApproved,
                title: 'Syarat & Ketentuan Pemodal',
                to: '/terms-and-conditions/pemodal',
            },
            {
                completed: isRiskStatementApproved,
                title: 'Pernyataan Risiko',
                to: '/risk-statement',
            },
            {
                title: 'Kotak Masuk',
                to: '/inbox',
                value: totalInboxes,
            },
        ]

        return (
            <StyledMenuContainer>
                {map(menus, (item, key) => {
                    let specialNode = ''

                    if (item.title === 'Screening' && isKycStatusSuccess && !isDigisignActivated) {
                        specialNode = (
                            <StyledSpan background onClick={redirectDigisingUrl}>
                                Aktifasi Akun Digisign
                            </StyledSpan>
                        )
                    }

                    return (
                        <Fragment key={key}>
                            {renderLink(
                                { to: item.to },
                                <AntdRow align='middle'>
                                    <AntdCol span={22}>
                                        <StyledAntdSpace>
                                            <StyledMenuTitle $dark={dark} level={5}>
                                                {item.title} {'\u00A0'} {getMenuIcon(item)}
                                            </StyledMenuTitle>
                                            {specialNode}
                                        </StyledAntdSpace>
                                    </AntdCol>
                                    <AntdCol span={2}>
                                        <StyledRightCircleOutlined />
                                    </AntdCol>
                                </AntdRow>
                            )}
                            <br />
                        </Fragment>
                    )
                })}
            </StyledMenuContainer>
        )
    }

    return (
        <StyledAntdSpace direction='vertical' size='large'>
            <AntdRow gutter={[0, 20]}>
                <AntdCol span={24}>{renderUserWelcome()}</AntdCol>
                <AntdCol span={24}>
                    <StyledAntdSpace direction='vertical' size='large'>
                        {renderUserInfo()}
                        {renderMenus()}
                    </StyledAntdSpace>
                </AntdCol>
            </AntdRow>
        </StyledAntdSpace>
    )
}

PemodalProfile.propTypes = {
    clearDigisign: PropTypes.func,
    colorSelector: PropTypes.string,
    digisignActivationUrl: PropTypes.string,
    email: PropTypes.string,
    generateLinkActivation: PropTypes.func,
    getPemodalTransactionSummary: PropTypes.func,
    initialValueTotal: PropTypes.number,
    isActionLoadingDigisign: PropTypes.bool,
    isActive: PropTypes.bool,
    isBankinfoExist: PropTypes.bool,
    isDetailExist: PropTypes.bool,
    isDigisignActivated: PropTypes.bool,
    isKycStatusSuccess: PropTypes.bool,
    isKycStatusVerified: PropTypes.bool,
    isRiskStatementApproved: PropTypes.bool,
    isTermsAndConditionsApproved: PropTypes.bool,
    isValidUser: PropTypes.bool,
    pemodalStatement: PropTypes.number,
    profilePictureUrl: PropTypes.string,
    refetchUser: PropTypes.func,
    totalInboxes: PropTypes.number,
    username: PropTypes.string,
    ytdValueTotal: PropTypes.number,
}

export default PemodalProfile
