import { default as AntdCarousel } from 'antd/lib/carousel'
import styled, { css } from 'styled-components'

const ArrowContainer = css`
    color: white;
    font-size: 36px;
    position: absolute;
    top: 45%;
    width: 36px;
    z-index: 100;
`

export const StyledPrevArrowContainer = styled.div`
    ${ArrowContainer}
`

export const StyledNextArrowContainer = styled.div`
    ${ArrowContainer}
    right: 5px;
`

export const StyledCarousel = styled(AntdCarousel)`
    &&& {
        > .slick-dots {
            padding-left: 10px;
            margin-bottom: ${(props) => props.$dotsMarginBottom}px;
        }

        > .slick-dots li button {
            width: 11px;
            height: 11px;
            border-radius: 100%;
            border: 2px solid ${(props) => props.$dotsColor};
        }

        > .slick-dots li.slick-active button {
            width: 11px;
            height: 11px;
            border-radius: 100%;
            background: ${(props) => props.$dotsColor};
        }

        & img {
            display: inline;
        }

        @media screen and (min-width: 768px) {
            .slick-active {
                .ant-card {
                    margin: auto;
                }
            }

            .slick-active.slick-current {
                .ant-card {
                    margin: 0px;
                }
            }
        }
    }
`
