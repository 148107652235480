import PropTypes from 'prop-types'
import React, { Fragment, useState, useEffect } from 'react'
import { default as AntdRow } from 'antd/lib/row'
import { StyledAntdInputPassword, StyledUnorderedList } from './StyledAntdInputPassword'
import StyledSpan from '../../components/styled_span/StyledSpan'
import colors from '../../basic/colors'
import { useOutletContext } from 'react-router-dom'
import { default as AntdTypography } from 'antd/lib/typography'
import { renderLink } from '../../utility/renderLinkComp'
import isMobile from 'is-mobile'

const AntdTypographyText = AntdTypography.Text

const InputPassword = (props) => {
    const {
        dark,
        disabled,
        error,
        input,
        isConfirmPassword,
        isResetPassword,
        isTouched,
        isValidUser,
        maxLength,
        name,
        onChange,
        placeholder,
        prefix,
        size,
        type,
        value,
        visibilityToggle,
    } = props

    const { isProfileDetail, passwordValidation } = useOutletContext()
    const [isErrorMinimal, setIsErrorMinimal] = useState(true)
    const [isErrorRequiredCharacter, setIsErrorRequiredCharacter] = useState(true)
    const [isErrorSimilarPassword, setIsErrorSimilarPassword] = useState(false)
    const [errorSize, setErrorSize] = useState(0)
    const isError = isTouched && !!error

    const handleChange = (e) => {
        onChange(e.target.value, name, e)
        setErrorSize(e.target.value.length)
    }

    const handleColor = () => {
        if (isError) {
            return colors.rose
        } else {
            if (dark) {
                return 'white'
            }
            return colors.graniteGray
        }
    }

    const handleError = (error) => {
        if (error === 'kata sandi harus berisi huruf kapital, huruf kecil, dan angka saja') {
            setIsErrorRequiredCharacter(true)
        } else {
            setIsErrorRequiredCharacter(false)
        }

        if (error === 'kata sandi minimal 8 karakter' || errorSize < 8) {
            setIsErrorMinimal(true)
        } else {
            setIsErrorMinimal(false)
        }

        if (error === 'kata sandi dan konfirmasi kata sandi tidak cocok') {
            setIsErrorSimilarPassword(true)
        } else {
            setIsErrorSimilarPassword(false)
        }

        if (error === 'wajib diisi' || errorSize <= 0) {
            setIsErrorMinimal(true)
            setIsErrorRequiredCharacter(true)
            setIsErrorSimilarPassword(false)
        }
    }

    useEffect(() => {
        if (error) {
            handleError(error)
        } else {
            setIsErrorMinimal(false)
            setIsErrorRequiredCharacter(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, errorSize, setIsErrorMinimal, setIsErrorRequiredCharacter])

    const renderError = () => isErrorSimilarPassword && <AntdTypographyText type='danger'>{error}</AntdTypographyText>

    const renderResetPassword = () =>
        isValidUser && (
            <AntdRow justify='end'>
                {renderLink(
                    { to: '/forgot-password' },
                    <StyledSpan color={colors.salmon}>{`Klik untuk mengubah password >`}</StyledSpan>
                )}
            </AntdRow>
        )

    const renderErrorSpace = () =>
        !isProfileDetail && (
            <Fragment>
                <br />
                <br />
            </Fragment>
        )

    const renderPasswordValidation = () =>
        (isProfileDetail || passwordValidation) &&
        !isConfirmPassword && (
            <StyledSpan color={handleColor()}>
                {renderErrorSpace()}
                <StyledUnorderedList $isMobile={isMobile()} $isResetPassword={isResetPassword}>
                    {isErrorMinimal && <li>Kata sandi minimal 8 karakter</li>}
                    {isErrorRequiredCharacter && (
                        <Fragment>
                            <li>Kata sandi harus berisi huruf kapital, huruf kecil, dan angka saja</li>
                            <li>Kata sandi tidak boleh menggunakan spesial karakter (!, ”, #, $, %, &, ’, dll)</li>
                        </Fragment>
                    )}
                </StyledUnorderedList>
            </StyledSpan>
        )

    return (
        <Fragment>
            <StyledAntdInputPassword
                {...input}
                $dark={dark}
                disabled={disabled}
                maxLength={maxLength}
                name={name}
                onChange={handleChange}
                placeholder={placeholder}
                prefix={prefix}
                size={size}
                type={type}
                value={value}
                visibilityToggle={visibilityToggle}
            />
            {renderResetPassword()}
            {renderError()}
            {renderPasswordValidation()}
        </Fragment>
    )
}

InputPassword.propTypes = {
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    input: PropTypes.object.isRequired,
    isConfirmPassword: PropTypes.bool,
    isResetPassword: PropTypes.bool,
    isTouched: PropTypes.bool,
    isValidUser: PropTypes.bool,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    visibilityToggle: PropTypes.bool,
}

export default InputPassword
