import React from 'react'
import { connect } from 'react-redux'
import * as cartSlice from '../features/cart/cartSlice'
import * as productSlice from '../features/product/productSlice'
import * as productDocumentSlice from '../features/product_document/productDocumentSlice'
import FundReport from './FundReport'

const FundReportContainer = (props) => <FundReport {...props} />

const mapStateToProps = (state) => ({
    dividendReports: state.productDocument.dividendReports,
    financialReports: state.productDocument.financialReports,
    isLoadingDividendReports: state.productDocument.isLoadingDividendReports,
    isLoadingFinancialReports: state.productDocument.isLoadingFinancialReports,
    product: state.product.product,
    totalDividendReports: state.productDocument.totalDividendReports,
    totalFinancialReports: state.productDocument.totalFinancialReports,
})

const mapDispatchToProps = {
    clearProduct: productSlice.clearProduct,
    clearProductDocument: productDocumentSlice.clearProductDocument,
    createCart: cartSlice.createCart,
    getCart: cartSlice.getCart,
    getDividendReports: productDocumentSlice.getDividendReports,
    getFinancialReports: productDocumentSlice.getFinancialReports,
    getProduct: productSlice.getProduct,
}

export default connect(mapStateToProps, mapDispatchToProps)(FundReportContainer)
