import clone from 'lodash/clone'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import formatPrice from '../../utility/formatPrice'
import getRenderStatusPurchaseOrder from '../../utility/getRenderStatusPurchaseOrder'

const columns = [
    {
        dataIndex: 'createdAt',
        title: 'Dibuat Pada',
        width: 150,
    },
    {
        dataIndex: 'noDoc',
        render: (text) => startCase(text),
        title: 'Nomor Order',
        width: 150,
    },
    {
        dataIndex: 'pemodal',
        render: (object) => {
            let text = ''
            if (!isEmpty(object)) {
                text = get(object, 'username', '')
            }

            return text
        },
        title: 'Username',
        width: 150,
    },
    {
        dataIndex: 'quantity',
        render: (text) => `${text} lembar`,
        title: 'Jumlah Lembar',
        width: 150,
    },
    {
        dataIndex: 'subtotal',
        render: (text) => `Rp ${formatPrice(text)}`,
        title: 'Total',
        width: 150,
    },
    {
        render: (object) => getRenderStatusPurchaseOrder(object),
        title: 'Status',
        width: 150,
    },
]

const dataSource = (data) =>
    map(data, (item, key) =>
        Object.assign(clone(item), {
            key: String(key),
        })
    )

export { columns, dataSource }
