export const DATE_FULL_FORMAT_WITHSPACE = 'DD MMMM YYYY'
export const DATE_HOUR_SLASH_FORMAT = 'DD/MM/YYYY : HH.mm'
export const DATE_HOUR_SLASH_FORMAT_WITHOUTCOLON = 'DD/MM/YYYY HH.mm'
export const DATE_SLASH_FORMAT = 'DD/MM/YYYY'
export const DATETIME_SLASH_FULL_FORMAT = 'DD/MM/YYYY HH:mm:ss'
export const HOUR_MINUTE_FORMAT = 'HH.mm'
export const MINUTE_SECOND_FORMAT = 'mm:ss'
export const VALID_IMAGE_EXTENSIONS = 'image/jpeg,image/png'
export const VALID_PDF_EXTENSION = 'application/pdf'
export const YEAR = 'YYYY'
export const UPLOAD_FORMAT_SIZE = 'format jpg, png ( < 4Mb )'
