import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/cart'
import * as api from '../api'

export const createCart = createAsyncThunk('CREATE_CART', async (data, { rejectWithValue }) => {
    try {
        const res = await api.create('/cart/create', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getCart = createAsyncThunk('GET_CART', async ({ options } = {}, { rejectWithValue }) => {
    try {
        const res = await api.fetch('/cart', { options })
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const updateCart = createAsyncThunk('UPDATE_CART', async (data) => await api.update('/cart/update', toApi(data)))
