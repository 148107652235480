import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import imagePath from '../basic/imagePath'
import HelmetMeta from '../components/HelmetMeta'
import { StyledImage, StyledSection } from '../components/StyledComponents'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'
import PemodalProfileContainer from './pemodal/PemodalProfileContainer'
import PenerbitProfileContainer from './penerbit/PenerbitProfileContainer'

const Profile = (props) => {
    const { clearInbox, currentRegisterStep, getInboxesUnread, isValidUser, user } = props
    const { dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, search } = useOutletContext()
    const metaTitle = 'Profil'
    const pumpImage = <StyledImage src={imagePath.pump} />

    useEffect(() => {
        const fetchInboxesUnread = async () => await getInboxesUnread({ options: { user } })
        fetchInboxesUnread()
        return () => {
            dispatch(clearInbox())
        }
    }, [clearInbox, dispatch, getInboxesUnread, user])

    if (!isLoggedIn) {
        navigate('/login' + search)
        return null
    }

    if (!isValidUser) {
        navigate(`/register/${user}/step${currentRegisterStep}`)
        return null
    }

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <AntdRow gutter={[0, 20]}>
                <AntdCol md={12} order={isMobile() ? 2 : 1} sm={24} xs={24}>
                    <center>{renderLazyLoadImage(pumpImage)}</center>
                </AntdCol>
                <AntdCol md={{ offset: 1, span: 11 }} order={isMobile() ? 1 : 2} sm={24} xs={24}>
                    {isPemodal && <PemodalProfileContainer />}
                    {isPenerbit && <PenerbitProfileContainer />}
                </AntdCol>
            </AntdRow>
        </StyledSection>
    )
}

Profile.propTypes = {
    clearInbox: PropTypes.func,
    currentRegisterStep: PropTypes.number,
    digisignCallback: PropTypes.func,
    getInboxesUnread: PropTypes.func,
    isValidUser: PropTypes.bool,
    user: PropTypes.string,
}

export default Profile
