import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

const getPenerbitName = (penerbit) => {
    if (isEmpty(penerbit)) {
        return ''
    }

    const entityType = get(penerbit, 'entityType.value', '')
    const entityName = get(penerbit, 'entityName', '')

    return entityType ? `${entityType} ${entityName}` : entityName
}

export default getPenerbitName
