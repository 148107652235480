import concat from 'lodash/concat'
import map from 'lodash/map'
import toLower from 'lodash/toLower'

export const paymentMethods = [
    'Akun Virtual (Bank Transfer)',
    'Dana/LinkAja',
    'OVO',
    'ShopeePay',
    'QR Code',
    'Direct Debit',
]
export const paymentMethodOptions = map(paymentMethods, (item) => ({ label: item, value: toLower(item) }))

export const statusOptions = [
    {
        label: 'Menunggu',
        value: 'pending',
    },
    {
        label: 'Kedaluwarsa',
        value: 'expired',
    },
    {
        label: 'Berhasil',
        value: 'paid',
    },
    {
        label: 'Dibatalkan',
        value: 'canceled',
    },
]

export const filterStatusOptions = (isFundPage) => {
    let options = isFundPage
        ? [
              {
                  label: 'Semua',
                  value: 'all',
              },
          ]
        : []
    if (isFundPage) {
        options = concat(options, [
            {
                label: 'Aktif',
                value: 'active',
            },
            {
                label: 'Tidak Aktif',
                value: 'inactive',
            },
        ])
    } else {
        options = concat(options, statusOptions)
    }

    options = concat(options, [
        {
            label: 'Full Offer',
            value: 'full',
        },
        {
            label: 'Done Offer',
            value: 'done',
        },
        {
            label: 'Failed Offer',
            value: 'failed',
        },
    ])
    return options
}
