import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import StyledResult from '../../components/styled_result/StyledResult'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { renderLinkButton } from '../../utility/renderLinkComp'

const Success = () => {
    const { dark, dispatch, handleLogout } = useOutletContext()

    useEffect(() => {
        return () => {
            handleLogout
        }
    }, [dispatch, handleLogout])

    const title = (
        <StyledTitle $dark={dark} level={2}>
            Password berhasil diganti
        </StyledTitle>
    )
    const subTitle = (
        <StyledTitle $dark={dark} level={3}>
            Jaga kerahasiaan passwordmu.
            <br />
            Penggantian password maksimal 1 kali dalam 14 hari
        </StyledTitle>
    )
    const extra = (
        <AntdRow gutter={[0, 15]}>
            <AntdCol md={11} sm={24} xs={24}>
                {renderLinkButton('/profile', { dark, name: 'to-account-btn', value: 'Akun moneypool-ku' })}
            </AntdCol>
            <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                {renderLinkButton('/products', { dark, name: 'see-offer-btn', value: 'Lihat Penawaran' })}
            </AntdCol>
        </AntdRow>
    )

    return (
        <AntdRow justify='center'>
            <AntdCol>
                <StyledResult extra={extra} subTitle={subTitle} title={title} />
            </AntdCol>
        </AntdRow>
    )
}

export default Success
