import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import isMobile from 'is-mobile'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Fragment, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { reset } from 'redux-form'
import { Button } from '../antdcomponents'
import imagePath from '../basic/imagePath'
import { isAuthorizedUser } from '../common/helper/authorization'
import { getInitialFilter, getNewOptions, getProductFilterFormData } from '../common/helper/product'
import HelmetMeta from '../components/HelmetMeta'
import Loader from '../components/loader/Loader'
import ProductFilterContainer from '../components/product_filter/ProductFilterContainer'
import ProductFilterDrawer from '../components/product_filter_drawer/ProductFilterDrawer'
import ProductList from '../components/product_list/ProductList'
import Result from '../components/result/Result'
import { StyledAntdPagination, StyledAntdSpace, StyledSection } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import { DEFAULT_PAGINATION_CONFIG } from '../constants/pagination'
import * as notification from '../utility/notification'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'
import { renderLinkButton } from '../utility/renderLinkComp'

const Favorite = (props) => {
    const {
        clearFavorite,
        clearProduct,
        favorites,
        getProducts,
        isActionLoading,
        isActionSuccess,
        isLoading,
        isLoadingFavorite,
        productCategories,
        productRibbons,
        productTypes,
        products,
        totalProducts,
    } = props
    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, location, navigate, pathname, search } =
        useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })

    const limit = DEFAULT_PAGINATION_CONFIG.defaultPageSize
    const page = get(query, 'page', 1)

    const { defaultAllFilter, defaultFilterValue, initialOptions, initialValues } = getInitialFilter(
        productCategories,
        productRibbons,
        productTypes,
        query
    )
    const [options, setOptions] = useState(initialOptions)
    const [urlParams, setUrlParams] = useState([])

    const isFavoriteEmpty = isEmpty(favorites)
    const isFilterEmpty = isEmpty(productCategories) || isEmpty(productRibbons) || isEmpty(productTypes)
    const [toogleFetch, setToogleFetch] = useState(true)

    const [isOpenFilter, setIsOpenFilter] = useState(false)
    const [isShowMessage, setIsShowMessage] = useState(false)
    const [isUnmounting, setIsUnmounting] = useState(false)
    const filterLightImage = <img src={imagePath.filterLight} />
    const handleOpenFilter = () => setIsOpenFilter((prevState) => !prevState)

    useEffect(() => {
        if (!isMobile()) {
            setIsOpenFilter(false)
        }
    }, [setIsOpenFilter])

    useEffect(() => {
        setOptions(initialOptions)
        setToogleFetch(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        if (!isFavoriteEmpty && !isFilterEmpty && toogleFetch) {
            const offset = (page - 1) * limit
            let newOptions = getNewOptions(options, defaultAllFilter)
            newOptions = Object.assign(newOptions, {
                isFavorite: true,
            })
            getProducts({ limit, offset, options: newOptions })
            setToogleFetch(false)
        }
    }, [defaultAllFilter, getProducts, isFavoriteEmpty, isFilterEmpty, limit, options, page, toogleFetch])

    useEffect(() => {
        const clearDatas = () => {
            setIsUnmounting(true)
            dispatch(clearProduct())
            dispatch(clearFavorite())
        }
        window.addEventListener('beforeunload', clearDatas)
        return () => {
            clearDatas()
            window.removeEventListener('beforeunload', clearDatas)
        }
    }, [clearFavorite, clearProduct, dispatch])

    useEffect(() => {
        if (isActionLoading) {
            setIsShowMessage(true)
        }
        if (!isActionLoading && isActionSuccess && isShowMessage) {
            notification.success('Penawaran telah dihapus dari favorit')
            setIsShowMessage(false)
            setToogleFetch(true)
        }
    }, [isActionLoading, isActionSuccess, isShowMessage])

    const renderEmptyResult = () => {
        const imageSrc = imagePath.purchaseOrderFailed
        const title = 'Oopss !'
        if (isLoadingFavorite || isUnmounting) {
            return <Loader />
        } else {
            const content = (
                <AntdRow>
                    <AntdCol md={12} sm={24} xs={24}>
                        {renderLinkButton('/products', {
                            dark: !dark,
                            name: 'see-products-btn',
                            value: 'Lihat Penawaran',
                        })}
                    </AntdCol>
                </AntdRow>
            )
            const description = (
                <AntdSpace direction='vertical'>
                    <>Kamu belum menambahkan favoritmu.</>
                    <>Klik tombol hati untuk penawaran favorit kamu.</>
                </AntdSpace>
            )
            return <Result content={content} description={description} imageSrc={imageSrc} title={title} />
        }
    }

    const resetFilter = () => {
        setIsOpenFilter(false)
        setToogleFetch(true)
    }

    const handleChange = (page) => {
        setToogleFetch(true)
        const newUrlParams = join(urlParams, '&')
        if (page === 1) {
            navigate(`/favorite?${newUrlParams}`)
        } else {
            navigate(`/favorite?${newUrlParams}${!isEmpty(newUrlParams) ? '&' : ''}page=${page}`)
        }
    }

    const handleFinish = (values) => {
        let { newOptions, urlParams } = getProductFilterFormData(values, defaultAllFilter)
        setOptions((prevState) => Object.assign(prevState, newOptions))
        setUrlParams(urlParams)
        resetFilter()
        urlParams = join(urlParams, '&')
        navigate(`/favorite?${urlParams}`)
    }

    const handleReset = () => {
        dispatch(reset('product'))
        setOptions(defaultFilterValue)
        resetFilter()
        navigate('/favorite')
    }

    if (!isLoggedIn) {
        navigate('/login')
        return null
    }

    if (!isAuthorizedUser(isPemodal, isPenerbit, pathname)) {
        navigate('/profile')
        return null
    }

    const metaTitle = 'Favoritku'

    return (
        <Fragment>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <ProductFilterDrawer
                initialValues={initialValues}
                isOpen={isOpenFilter}
                onClose={handleOpenFilter}
                onFinish={handleFinish}
                onReset={handleReset}
            />
            <StyledSection>
                {isFavoriteEmpty && renderEmptyResult()}
                {!isFavoriteEmpty && (
                    <StyledAntdSpace direction='vertical' size='large'>
                        <center>
                            <AntdSpace>
                                {isMobile() && (
                                    <Button
                                        dark={dark}
                                        name='open-filter-btn'
                                        onClick={handleOpenFilter}
                                        type='text'
                                        value={renderLazyLoadImage(filterLightImage)}
                                    />
                                )}
                                <StyledTitle $dark={dark} level={2}>
                                    {metaTitle}
                                </StyledTitle>
                            </AntdSpace>
                        </center>
                        {isLoading && <Loader />}
                        <AntdRow justify='center'>
                            <AntdCol md={5} sm={0} xs={0}>
                                <ProductFilterContainer
                                    dark={dark}
                                    initialValues={initialValues}
                                    onFinish={handleFinish}
                                    onReset={handleReset}
                                />
                            </AntdCol>
                            <AntdCol md={{ offset: 1, span: 18 }} sm={24}>
                                <StyledAntdSpace direction='vertical'>
                                    <ProductList isLoading={isLoading} products={products} />
                                    <AntdRow justify='end'>
                                        <StyledAntdPagination
                                            $dark={dark}
                                            current={Number(page)}
                                            defaultPageSize={limit}
                                            onChange={handleChange}
                                            total={totalProducts}
                                        />
                                    </AntdRow>
                                </StyledAntdSpace>
                            </AntdCol>
                        </AntdRow>
                    </StyledAntdSpace>
                )}
            </StyledSection>
        </Fragment>
    )
}

Favorite.propTypes = {
    clearFavorite: PropTypes.func,
    clearProduct: PropTypes.func,
    favorites: PropTypes.array,
    getProducts: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingFavorite: PropTypes.bool,
    productCategories: PropTypes.array,
    productRibbons: PropTypes.array,
    productTypes: PropTypes.array,
    products: PropTypes.array,
    totalProducts: PropTypes.number,
}

export default Favorite
