import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import first from 'lodash/first'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import size from 'lodash/size'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import {
    Button,
    Form,
    ReduxFormCheckboxGroup,
    ReduxFormDatePicker,
    ReduxFormInput,
    ReduxFormInputGroup,
    ReduxFormSelect,
    ReduxFormTextArea,
    Tooltip,
} from '../antdcomponents'
import colors from '../basic/colors'
import { genderOptions as userGenderOptions } from '../common/options/user'
import Loader from '../components/loader/Loader'
import { StyledAntdSpace, StyledVerification } from '../components/StyledComponents'
import StyledSpan from '../components/styled_span/StyledSpan'
import StyledTitle from '../components/styled_title/StyledTitle'
import { lengthIdCardNumber, numberRequired, olderThan, required, validEmail } from '../utility/formValidation'
import getBase64 from '../utility/getBase64'
import mapSelectOptions from '../utility/mapSelectOptions'
import * as notification from '../utility/notification'
import { renderLink } from '../utility/renderLinkComp'
import useSelectorFormValues from '../utility/useSelectorFormValues'
import { verificationFailedTitle } from './helper'
import DataVerificationContainer from './modal/DataVerificationContainer'
import EmailOtpVerificationContainer from './modal/EmailOtpVerificationContainer'
import VerificationFailedContainer from './modal/VerificationFailedContainer'
import ScreeningUpload from './comps/ScreeningUpload'

const ScreeningForm = (props) => {
    const {
        generateLinkActivation,
        annualSalaries,
        cities,
        change,
        clearAnnualSalary,
        clearCity,
        clearDistrict,
        clearIncomeSource,
        clearInvestmentGoal,
        clearPenerbitDetail,
        createPenerbitDetail,
        digisignActivationUrl,
        districts,
        email,
        error,
        getAnnualSalaries,
        getCities,
        getDistricts,
        getIncomeSources,
        getInvestmentGoals,
        getPenerbitDetails,
        handleSubmit,
        id,
        incomeSources,
        initialValues,
        investmentGoals,
        isActionLoadingDigisign,
        isActionLoadingPemodal,
        isActionLoadingPenerbitDetail,
        isActionSuccessDigisign,
        isActionSuccessPemodal,
        isActionSuccessPenerbitDetail,
        isDigisignActivated,
        isError,
        isKycStatusFailed,
        isKycStatusVerified,
        isKycStatusSuccessVerified,
        isKycVerified,
        isLoadingPemodal,
        isLoadingPenerbitDetail,
        isMobileNumberVerified,
        isNeedActivation,
        isSavingScreeningData,
        penerbitDetail,
        penerbitDetails,
        pristine,
        refetchUser,
        reset,
        result,
        setIsSavingScreeningData,
        setPageNumber,
        updatePemodal,
        updatePenerbitDetail,
        username,
    } = props

    const { dark, dispatch, isPemodal, isPenerbit, navigate, params, search } = useOutletContext()

    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const mode = get(params, 'mode', '')

    const isLoading = isLoadingPemodal || isLoadingPenerbitDetail
    const isActionPemodal = !isActionLoadingPemodal && isActionSuccessPemodal
    const isActionPenerbitDetail = !isActionLoadingPenerbitDetail && isActionSuccessPenerbitDetail

    const isEmptyQuery = isEmpty(query)
    const isWindowTypeEdit = mode === 'edit'
    const isWindowTypeView = mode === 'view'

    const isDisabled = isWindowTypeView || isKycStatusFailed || isKycStatusSuccessVerified

    const isEmailVerified = get(penerbitDetail, 'isEmailVerified', false)
    const penerbitDetailId = get(penerbitDetail, 'id', null)

    const mainPersonel = find(penerbitDetails, { isSameAsPenerbitEmail: true })
    const mainPersonelId = get(mainPersonel, 'id', null)
    const allowSameEmailAsPenerbit = isEmpty(mainPersonel) || mainPersonelId === penerbitDetailId

    const formValues = useSelectorFormValues('screening')
    const cityId = get(formValues, 'cityId', null)
    const districtName = get(formValues, 'districtName', '')
    const isSameAsPenerbitEmail = get(formValues, 'isSameAsPenerbitEmail', [])
    const ktp = get(formValues, 'ktp', [])
    const provinceName = get(formValues, 'provinceName', '')
    const screeningEmail = get(formValues, 'email', '')
    const selfie = get(formValues, 'selfie', [])
    const subdistrictName = get(formValues, 'subdistrictName', '')
    const isEmptyProv = !provinceName
    const isEmptyProvCity = isEmptyProv || !cityId
    const isEmptyProvCityDist = isEmptyProvCity || !districtName

    const [backToProfile, setBackToProfile] = useState(true)
    const [isActionLoading, setIsActionLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isRegister, setIsRegister] = useState(false)
    const [modalType, setModalType] = useState('')
    const [screeningData, setScreeningData] = useState({})
    const [isCheckKycResult, setIsCheckKycResult] = useState(true)
    const provinces = uniq(map(cities, 'province'))
    const provinceOptions = orderBy(
        map(provinces, (item) => ({
            label: item,
            value: item,
        })),
        'label'
    )
    const cityOptions = filter(cities, (item) => item.province === provinceName)
    const districtOptions = uniqBy(
        map(districts, (item) => ({
            label: item.name,
            value: item.name,
        })),
        'label'
    )
    const subdistrictOptions = map(
        filter(districts, (item) => item.name === districtName),
        (item) => ({
            label: item.subdistrict,
            value: item.subdistrict,
        })
    )

    const olderThan18 = olderThan(18)
    const cityLabelKey = ['type', 'name']
    const isSameAsPenerbitEmailOptions = [
        {
            label: 'Gunakan email perusahaan yang teregistrasi',
            required: true,
            value: 'true',
        },
    ]

    const documents = concat(ktp, selfie)

    useEffect(() => {
        const fetchAnnualSalaries = async () => await getAnnualSalaries()
        const fetchCities = async () => await getCities()
        const fetchIncomeSources = async () => await getIncomeSources()
        const fetchInvestmentGoals = async () => await getInvestmentGoals()
        const fetchPenerbitDetails = async () => await getPenerbitDetails()

        fetchAnnualSalaries()
        fetchCities()
        fetchIncomeSources()
        fetchInvestmentGoals()
        if (isPenerbit) {
            fetchPenerbitDetails()
        }

        return () => {
            dispatch(clearAnnualSalary())
            dispatch(clearCity())
            dispatch(clearDistrict())
            dispatch(clearIncomeSource())
            dispatch(clearInvestmentGoal())
            dispatch(clearPenerbitDetail())
        }
    }, [
        clearAnnualSalary,
        clearCity,
        clearDistrict,
        clearIncomeSource,
        clearInvestmentGoal,
        clearPenerbitDetail,
        dispatch,
        getAnnualSalaries,
        getCities,
        getIncomeSources,
        getInvestmentGoals,
        getPenerbitDetails,
        isPenerbit,
    ])

    useEffect(() => {
        if (!isCheckKycResult) {
            refetchUser({ email }).then(() => setIsCheckKycResult((prevState) => !prevState))
        }
        if (!isActionLoadingDigisign) {
            refetchUser({ email }).then(() => {
                if (isEmpty(digisignActivationUrl)) {
                    return null
                }
                window.open(digisignActivationUrl, '_blank')
            })
        }
    }, [email, isCheckKycResult, isActionLoadingDigisign, digisignActivationUrl, refetchUser])

    useEffect(() => {
        if (isPemodal) {
            if (!isActionLoadingDigisign && !isActionLoading && isCheckKycResult) {
                if (isActionSuccessDigisign) {
                    setPageNumber(1)
                }
                if (isError && isKycStatusFailed) {
                    setIsModalOpen(true)
                    setModalType('failed')
                }
            }
        }
        if (((isActionPemodal && !isRegister) || (isActionPenerbitDetail && backToProfile)) && !isActionLoading) {
            if (isSavingScreeningData) {
                notification.success('Data screening berhasil diperbaharui')
                setIsSavingScreeningData(false)
            }
            if (isPenerbit) {
                navigate('/profile/screening-detail')
            }
        }
        if (!backToProfile && !isActionLoading && isActionPenerbitDetail && !isModalOpen && modalType !== 'email') {
            dispatch(clearPenerbitDetail())
            navigate('/profile/screening/add')
        }
    }, [
        backToProfile,
        clearPenerbitDetail,
        dispatch,
        documents,
        isActionLoading,
        isActionLoadingDigisign,
        isActionPemodal,
        isActionPenerbitDetail,
        isActionSuccessDigisign,
        isCheckKycResult,
        isEmptyQuery,
        isEmailVerified,
        isError,
        isKycStatusFailed,
        isModalOpen,
        isPemodal,
        isPenerbit,
        isRegister,
        isSavingScreeningData,
        modalType,
        navigate,
        setIsSavingScreeningData,
        setPageNumber,
    ])

    const resetSubdistPostal = useCallback(() => {
        change('subdistrictName', '')
        change('postalCode', '')
    }, [change])

    useEffect(() => {
        if (isEmptyProvCityDist) {
            resetSubdistPostal()
        }
        if (isEmptyProvCity) {
            change('districtName', '')
            resetSubdistPostal()
        }
        if (isEmptyProv) {
            change('cityId', '')
            change('districtName', '')
            resetSubdistPostal()
        }
    }, [change, isEmptyProv, isEmptyProvCity, isEmptyProvCityDist, resetSubdistPostal])

    useEffect(() => {
        if (isActionPenerbitDetail && isModalOpen) {
            setModalType('email')
        }
    }, [isActionPenerbitDetail, isModalOpen])

    useEffect(() => {
        const fetchDistricts = async () => await getDistricts({ options: { cityId } })
        if (cityId) {
            fetchDistricts()
        }
    }, [cityId, getDistricts])

    useEffect(() => {
        const postalCode = get(find(districts, { subdistrict: subdistrictName }), 'postalCode', '')
        if (!isEmpty(postalCode)) {
            change('postalCode', postalCode)
        }
    }, [change, districts, subdistrictName])

    useEffect(() => {
        if (!isEmpty(isSameAsPenerbitEmail)) {
            change('email', email)
        }
    }, [change, email, isSameAsPenerbitEmail])

    useEffect(() => {
        if (!isActionLoadingDigisign && isActionSuccessDigisign && !isNeedActivation && result === '00') {
            navigate('/digisign-activation-status')
        }
    }, [isActionLoadingDigisign, isActionSuccessDigisign, isNeedActivation, navigate, result])

    const handleAddPersonel = () => setBackToProfile(false)
    const handleBackToProfile = () => setBackToProfile(true)

    const handleCancel = () => setIsModalOpen(false)

    const handleClick = () => {
        const { email } = formValues
        const data = {
            email,
            id: penerbitDetailId,
        }
        updatePenerbitDetail(data)
        setIsModalOpen(true)
        setBackToProfile(false)
    }

    const handleFinish = async (values) => {
        setIsActionLoading(true)
        setIsSavingScreeningData(true)
        const newDocument = find(documents, (item) => item.originFileObj && !item.url)
        if (!isEmpty(newDocument)) {
            notification.info('Unggah dokumen...')
        }

        return Promise.all(Array.from(documents).map(getBase64))
            .then((newFileList) => {
                const { address, birthDate, cityId, gender, hometownId, postalCode, subdistrictName } = values
                const district = find(districts, { subdistrict: subdistrictName })
                const baseData = {
                    address,
                    birthDate,
                    cityId,
                    createdBy: username,
                    districtId: get(district, 'id', null),
                    gender,
                    hometownId,
                    isUploading: true,
                    postalCode,
                    updatedBy: username,
                }

                let promise = null
                if (isPemodal) {
                    const { pemodalDetail } = values
                    const { annualSalaryId, idCardName, idCardNumber, incomeSourceId, investmentGoalId, motherName } =
                        pemodalDetail
                    const newPemodalDetail = {
                        annualSalaryId,
                        idCardName: idCardName,
                        idCardNumber,
                        incomeSourceId,
                        investmentGoalId,
                        motherName,
                    }

                    const data = Object.assign(baseData, {
                        id,
                        pemodalDetail: newPemodalDetail,
                        pemodalDocuments: newFileList,
                    })

                    if (isRegister) {
                        setScreeningData((prevState) => Object.assign(prevState, { data }))
                    } else {
                        promise = updatePemodal(data)
                    }
                }
                if (isPenerbit) {
                    const {
                        annualSalaryId,
                        email,
                        idCardName,
                        idCardNumber,
                        incomeSourceId,
                        isSameAsPenerbitEmail,
                        mobileNumber,
                        motherName,
                        position,
                    } = values

                    let data = Object.assign(baseData, {
                        annualSalaryId,
                        createdBy: username,
                        email,
                        idCardName,
                        idCardNumber,
                        incomeSourceId,
                        isSameAsPenerbitEmail: first(isSameAsPenerbitEmail) === 'true',
                        mobileNumber,
                        motherName,
                        penerbitDetailDocuments: newFileList,
                        penerbitId: id,
                        position,
                    })

                    if (isEmptyQuery) {
                        promise = createPenerbitDetail(data)
                        setIsModalOpen(true)
                    } else {
                        data = Object.assign(data, { id: penerbitDetailId })
                        promise = updatePenerbitDetail(data)
                    }

                    if (!backToProfile) {
                        reset()
                    }
                }
                if (isRegister) {
                    return
                }
                return promise
            })
            .then(() => setIsActionLoading(false))
    }

    const handleSave = () => setIsRegister(false)

    const handleVerify = () => {
        setIsRegister(true)
        setIsModalOpen(true)
        setModalType('screening')
    }

    const renderAdditionalData = () => (
        <StyledAntdSpace direction='vertical'>
            <Field
                component={ReduxFormInput}
                disabled={isDisabled}
                label='Nama Ibu Kandung'
                name={isPemodal ? 'pemodalDetail.motherName' : 'motherName'}
                placeholder='Masukkan Nama Ibu Kandung'
            />
            <Field
                component={ReduxFormSelect}
                componentProps={{
                    optionFilterProp: 'label',
                    options: mapSelectOptions(annualSalaries, 'value', false),
                }}
                disabled={isDisabled}
                formItemProps={isPemodal ? { required: true } : {}}
                label='Penghasilan Tahunan'
                name={isPemodal ? 'pemodalDetail.annualSalaryId' : 'annualSalaryId'}
                placeholder='Pilih Penghasilan Tahunan'
                validate={isPemodal ? [required] : []}
            />
            <Field
                component={ReduxFormSelect}
                componentProps={{
                    optionFilterProp: 'label',
                    options: mapSelectOptions(incomeSources, 'value'),
                }}
                disabled={isDisabled}
                formItemProps={isPemodal ? { required: true } : {}}
                label='Sumber Penghasilan'
                name={isPemodal ? 'pemodalDetail.incomeSourceId' : 'incomeSourceId'}
                placeholder='Pilih Sumber Penghasilan'
                validate={isPemodal ? [required] : []}
            />
        </StyledAntdSpace>
    )

    const renderButton = () => {
        const validDocuments = filter(documents, (item) => !isEmpty(item.file) || item.url)

        const isDisabledButton = (error && size(validDocuments) !== 2) || isDisabled
        const isDisabledVerifyButton = isDisabledButton || !pristine || (isPemodal && !isMobileNumberVerified)
        const isVerifiedPemodal = isPemodal && isKycStatusSuccessVerified

        return (
            !isWindowTypeView && (
                <AntdRow align='middle' gutter={[0, 30]} justify='center'>
                    <AntdCol md={11} sm={24} xs={24}>
                        <Button
                            dark={dark}
                            disabled={pristine}
                            htmlType='submit'
                            loading={isActionLoading}
                            name='save-btn'
                            onClick={isPemodal ? handleSave : handleBackToProfile}
                            value='Simpan'
                        />
                    </AntdCol>
                    {!isVerifiedPemodal && (
                        <AntdCol md={{ offset: 2, span: 11 }} sm={24} xs={24}>
                            <Button
                                dark={dark}
                                disabled={isDisabledVerifyButton}
                                htmlType='submit'
                                loading={isActionLoading}
                                name='register-btn'
                                onClick={isPemodal ? handleVerify : handleAddPersonel}
                                value={isPemodal ? 'Verifikasi' : 'Tambah Personel'}
                            />
                        </AntdCol>
                    )}
                    {isVerifiedPemodal && (
                        <AntdCol md={{ offset: 2, span: 11 }} sm={24} xs={24}>
                            <Button
                                dark={dark}
                                disabled={isDigisignActivated || isKycStatusVerified}
                                loading={isActionLoadingDigisign}
                                name='generate-link-activation'
                                onClick={() => generateLinkActivation()}
                                value='Aktivasi'
                            />
                        </AntdCol>
                    )}
                </AntdRow>
            )
        )
    }

    const renderIdCard = () => (
        <StyledAntdSpace direction='vertical'>
            <Field
                component={ReduxFormInput}
                disabled={isDisabled}
                formItemProps={{
                    required: true,
                }}
                label='Nomor KTP'
                name={isPemodal ? 'pemodalDetail.idCardNumber' : 'idCardNumber'}
                placeholder='Masukkan Nomor KTP'
                validate={[required, numberRequired, lengthIdCardNumber]}
            />
            <Field
                component={ReduxFormInput}
                disabled={isDisabled}
                formItemProps={{
                    required: true,
                }}
                label='Nama Lengkap Sesuai KTP'
                name={isPemodal ? 'pemodalDetail.idCardName' : 'idCardName'}
                placeholder='Masukkan Nama Lengkap Sesuai KTP'
                validate={[required]}
            />
            <Field
                component={ReduxFormSelect}
                componentProps={{
                    options: userGenderOptions,
                }}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Jenis Kelamin'
                name='gender'
                placeholder='Pilih Jenis Kelamin'
                validate={[required]}
            />
            <Field
                component={ReduxFormSelect}
                componentProps={{
                    optionFilterProp: 'label',
                    options: mapSelectOptions(cities, cityLabelKey),
                }}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Tempat Lahir'
                name='hometownId'
                placeholder='Pilih Tempat Lahir'
                validate={[required]}
            />
            <Field
                component={ReduxFormDatePicker}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Tanggal Lahir'
                name='birthDate'
                placeholder='Pilih Tanggal Lahir'
                validate={[required, olderThan18]}
            />
            <AntdRow gutter={15}>
                <AntdCol md={12} sm={24} xs={24}>
                    <Field
                        component={ReduxFormSelect}
                        componentProps={{
                            options: provinceOptions,
                        }}
                        disabled={isDisabled}
                        formItemProps={{ required: true }}
                        label='Provinsi'
                        name='provinceName'
                        placeholder='Pilih Provinsi Sesuai KTP'
                        validate={[required]}
                    />
                </AntdCol>
                <AntdCol md={12} sm={24} xs={24}>
                    <Field
                        component={ReduxFormSelect}
                        componentProps={{
                            optionFilterProp: 'label',
                            options: mapSelectOptions(cityOptions, cityLabelKey),
                        }}
                        disabled={isDisabled || !provinceName}
                        formItemProps={{ required: true }}
                        label='Kota'
                        name='cityId'
                        placeholder='Pilih Kota Sesuai KTP'
                        validate={[required]}
                    />
                </AntdCol>
                <AntdCol md={12} sm={24} xs={24}>
                    <Field
                        component={ReduxFormSelect}
                        componentProps={{
                            options: districtOptions,
                        }}
                        disabled={isDisabled || !provinceName || !cityId}
                        formItemProps={{ required: true }}
                        label='Kecamatan'
                        name='districtName'
                        placeholder='Pilih Kecamatan Sesuai KTP'
                        validate={[required]}
                    />
                </AntdCol>
                <AntdCol md={12} sm={24} xs={24}>
                    <Field
                        component={ReduxFormSelect}
                        componentProps={{
                            options: subdistrictOptions,
                        }}
                        disabled={isDisabled || !provinceName || !cityId || !districtName}
                        formItemProps={{ required: true }}
                        label='Kelurahan'
                        name='subdistrictName'
                        placeholder='Pilih Kelurahan Sesuai KTP'
                        validate={[required]}
                    />
                </AntdCol>
            </AntdRow>
            <Field
                component={ReduxFormInput}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Kode Pos'
                name='postalCode'
                placeholder='Masukkan Kode Pos Sesuai KTP'
                validate={[required, numberRequired]}
            />
            <Field
                component={ReduxFormTextArea}
                componentProps={{ rows: 3 }}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Alamat Lengkap Sesuai KTP'
                name='address'
                placeholder='Contoh : Jalan Ahmad Yani Gang 5 Nomor 80'
                validate={[required]}
            />
        </StyledAntdSpace>
    )

    const renderInvestmentGoal = () => (
        <Field
            component={ReduxFormSelect}
            componentProps={{
                optionFilterProp: 'label',
                options: mapSelectOptions(investmentGoals, 'value'),
            }}
            disabled={isDisabled}
            formItemProps={{ required: true }}
            label='Tujuan Pendanaan'
            name='pemodalDetail.investmentGoalId'
            placeholder='Pilih Tujuan Pendanaan'
            validate={[required]}
        />
    )

    const renderKtpAndSelfie = () => (
        <StyledAntdSpace direction='vertical'>
            <StyledTitle $lineHeight={1} color={colors.graniteGray} level={2}>
                Berkas Identitas
            </StyledTitle>
            {!((isPemodal && isKycStatusSuccessVerified) || (isPenerbit && isKycVerified)) && (
                <StyledSpan color={colors.blueberry}>
                    <a href={process.env.REACT_APP_SELFIE_GUIDE_URL} rel='noopener noreferrer' target='_blank'>
                        Klik untuk lihat panduan
                    </a>
                </StyledSpan>
            )}
            <AntdRow align='middle' justify='space-between' type='flex'>
                <AntdCol span={11}>
                    <ScreeningUpload change={change} fieldName='ktp' isDisabled={isDisabled} />
                </AntdCol>
                <AntdCol offset={2} span={11}>
                    <ScreeningUpload change={change} fieldName='selfie' isDisabled={isDisabled} />
                </AntdCol>
            </AntdRow>
        </StyledAntdSpace>
    )

    const renderModal = () => {
        if (modalType === 'email') {
            return (
                <EmailOtpVerificationContainer
                    email={screeningEmail}
                    initialValues={initialValues}
                    isModalOpen={isModalOpen}
                    onCancel={handleCancel}
                />
            )
        }
        if (modalType === 'failed') {
            return <VerificationFailedContainer isModalOpen={isModalOpen} onCancel={handleCancel} />
        }
        if (modalType === 'screening') {
            return (
                <DataVerificationContainer
                    isModalOpen={isModalOpen}
                    onCancel={handleCancel}
                    screeningData={screeningData}
                    setIsCheckKycResult={setIsCheckKycResult}
                />
            )
        }

        return null
    }

    const renderPenerbitDetail = () => (
        <StyledAntdSpace direction='vertical'>
            <Field
                component={ReduxFormCheckboxGroup}
                disabled={!allowSameEmailAsPenerbit || isDisabled}
                formItemProps={{
                    noMargin: true,
                    required: true,
                }}
                label='Email'
                name='isSameAsPenerbitEmail'
                options={isSameAsPenerbitEmailOptions}
            />
            <StyledAntdSpace direction='vertical' size={0}>
                <Field
                    component={ReduxFormInput}
                    disabled={!isEmpty(isSameAsPenerbitEmail) || isDisabled}
                    formItemProps={{
                        isHideLabel: true,
                        noMargin: isPenerbit && isWindowTypeEdit,
                    }}
                    name='email'
                    placeholder='Masukkan Email Aktif Personel'
                    validate={[required, validEmail]}
                />
                {isWindowTypeEdit && (
                    <AntdRow justify='end'>
                        {isEmailVerified && <StyledSpan color={colors.blueberry}>*Terverifikasi</StyledSpan>}
                        {!isEmailVerified && (
                            <StyledVerification color={colors.salmon} onClick={handleClick}>
                                {'Klik untuk verifikasi >'}
                            </StyledVerification>
                        )}
                    </AntdRow>
                )}
            </StyledAntdSpace>
            <Field
                component={ReduxFormInputGroup}
                dark={dark}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Nomor Telepon Seluler'
                name='mobileNumber'
                placeholder='Masukkan Nomor Telepon Seluler'
                prefix='+62'
                validate={[required, numberRequired]}
            />
            <Field
                component={ReduxFormInput}
                disabled={isDisabled}
                formItemProps={{ required: true }}
                label='Jabatan'
                name='position'
                placeholder='Masukkan Jabatan'
                validate={[required]}
            />
        </StyledAntdSpace>
    )

    const renderTitle = () => {
        const tooltipScreening = (
            <Tooltip
                title={
                    <ul>
                        <li>Satu akun penerbit dapat dikelola oleh lebih dari satu personel.</li>
                        <li>
                            Personel terdaftar akan melakukan KYC sesuai dalam Ketentuan Otoritas Jasa Keuangan (OJK).
                        </li>
                    </ul>
                }
            />
        )

        return (
            <AntdSpace>
                <StyledTitle color={colors.graniteGray} level={2}>
                    Screening
                </StyledTitle>
                {tooltipScreening}
            </AntdSpace>
        )
    }

    const renderVerificationFailedInfo = () =>
        isKycStatusFailed && (
            <StyledTitle color={colors.salmon} level={5}>
                {verificationFailedTitle}.&nbsp;
                {renderLink({ to: '/contact-us' }, <u>Silahkan hubungi tim admin moneypool</u>)}
            </StyledTitle>
        )

    return isLoading || isActionLoading ? (
        <Loader />
    ) : (
        <StyledAntdSpace direction='vertical'>
            {isPenerbit && renderTitle()}
            <Form onFinish={handleSubmit(handleFinish)}>
                <StyledAntdSpace direction='vertical'>
                    {isPenerbit && renderPenerbitDetail()}
                    {renderIdCard()}
                    {renderAdditionalData()}
                    {isPemodal && renderInvestmentGoal()}
                    {renderKtpAndSelfie()}
                    {renderVerificationFailedInfo()}
                    {renderButton()}
                </StyledAntdSpace>
            </Form>
            {renderModal()}
        </StyledAntdSpace>
    )
}

ScreeningForm.propTypes = {
    annualSalaries: PropTypes.array,
    change: PropTypes.func,
    cities: PropTypes.array,
    clearAnnualSalary: PropTypes.func,
    clearCity: PropTypes.func,
    clearDistrict: PropTypes.func,
    clearIncomeSource: PropTypes.func,
    clearInvestmentGoal: PropTypes.func,
    clearPenerbitDetail: PropTypes.func,
    createPenerbitDetail: PropTypes.func,
    digisignActivationUrl: PropTypes.string,
    districts: PropTypes.array,
    email: PropTypes.string,
    error: PropTypes.any,
    generateLinkActivation: PropTypes.func,
    getAnnualSalaries: PropTypes.func,
    getCities: PropTypes.func,
    getDistricts: PropTypes.func,
    getIncomeSources: PropTypes.func,
    getInvestmentGoals: PropTypes.func,
    getPenerbitDetails: PropTypes.func,
    handleSubmit: PropTypes.func,
    id: PropTypes.number,
    incomeSources: PropTypes.array,
    initialValues: PropTypes.object,
    investmentGoals: PropTypes.array,
    isActionLoadingDigisign: PropTypes.bool,
    isActionLoadingPemodal: PropTypes.bool,
    isActionLoadingPenerbitDetail: PropTypes.bool,
    isActionSuccessDigisign: PropTypes.bool,
    isActionSuccessPemodal: PropTypes.bool,
    isActionSuccessPenerbitDetail: PropTypes.bool,
    isDigisignActivated: PropTypes.bool,
    isError: PropTypes.bool,
    isKycStatusFailed: PropTypes.bool,
    isKycStatusSuccessVerified: PropTypes.bool,
    isKycStatusVerified: PropTypes.bool,
    isKycVerified: PropTypes.bool,
    isLoadingPemodal: PropTypes.bool,
    isLoadingPenerbitDetail: PropTypes.bool,
    isMobileNumberVerified: PropTypes.bool,
    isNeedActivation: PropTypes.bool,
    isSavingScreeningData: PropTypes.bool,
    penerbitDetail: PropTypes.object,
    penerbitDetails: PropTypes.array,
    pristine: PropTypes.bool,
    refetchUser: PropTypes.func,
    reset: PropTypes.func,
    result: PropTypes.string,
    setIsSavingScreeningData: PropTypes.func,
    setPageNumber: PropTypes.func,
    updatePemodal: PropTypes.func,
    updatePenerbitDetail: PropTypes.func,
    username: PropTypes.string,
}
export default ScreeningForm
