import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../antdcomponents'

export const renderLink = (props, child) => <Link {...props}>{child}</Link>

export const renderLinkButton = (target, props) => (
    <Link to={target}>
        <Button {...props} />
    </Link>
)
