import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

export const handleWebsiteUrl = (value) => {
    const isWebsiteUrlNeedHttp = includes(value, 'http')
    const isWebsiteUrlEmpty = isEmpty(value) || value === '' || value === null || value === '-'
    if (isWebsiteUrlEmpty) {
        return null
    } else if (!isWebsiteUrlNeedHttp) {
        return `http://${value}`
    }
    return value
}

export const handleNameWebsiteUrl = (value) => {
    const isWebsiteUrlHttps = includes(value, 'https')
    const isWebsiteUrlHttp = includes(value, 'http')
    const isWebsiteUrlNeedWww = !includes(value, 'www')
    const isWebsiteUrlEmpty = isEmpty(value) || value === '' || value === null || value === '-'

    const removedHttps = value.replace('https://', '').replace(/\//g, '')
    const removedHttp = value.replace('http://', '').replace(/\//g, '')

    if (isWebsiteUrlEmpty) {
        return '-'
    } else if (isWebsiteUrlHttps) {
        return removedHttps
    } else if (isWebsiteUrlHttp) {
        return removedHttp
    } else if (isWebsiteUrlNeedWww) {
        return `www.${value}`
    }
    return value
}
