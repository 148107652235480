import PropTypes from 'prop-types'
import React from 'react'
import imagePath from '../../basic/imagePath'
import StyledAntdEmpty from './StyledAntdEmpty'

const Empty = (props) => {
    const { children, className, description, image } = props

    return (
        <StyledAntdEmpty className={className} description={description} image={image}>
            {children}
        </StyledAntdEmpty>
    )
}

Empty.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    description: PropTypes.node,
    image: PropTypes.node,
}

// TODO: change using https://ant.design/components/empty/#components-empty-demo-config-provider
Empty.defaultProps = {
    description: '',
    image: imagePath.noDataGray,
}

export default Empty
