const drawerList = (props) => {
    const { isLoggedIn, isPemodal, isPenerbit, onLogout, username } = props
    return [
        {
            isShown: true,
            isToogleDrawer: true,
        },
        {
            value: 'Hi, ' + username + ' !',
            isShown: isLoggedIn,
            isProfile: true,
        },
        {
            value: '',
            isShown: !isLoggedIn,
            isProfile: true,
        },
        {
            value: 'Profil',
            isShown: isLoggedIn,
            link: '/profile/detail',
        },
        {
            value: 'Screening',
            isShown: isLoggedIn,
            link: isPemodal ? '/profile/screening' : '/profile/screening-detail',
        },
        {
            value: 'Rekening Bank',
            isShown: isLoggedIn,
            link: '/profile/bank',
        },
        {
            value: 'Transaksi',
            isShown: isLoggedIn && isPemodal,
            link: '/transaction-histories',
        },
        {
            value: 'Portfolio',
            isShown: isLoggedIn && isPemodal,
            link: '/portfolio',
        },
        {
            value: 'Favorit',
            isShown: isLoggedIn && isPemodal,
            link: '/favorite',
        },
        {
            value: 'Keranjang',
            isShown: isLoggedIn && isPemodal,
            link: '/order',
        },
        {
            value: 'Legalitas Perusahaan',
            isShown: isLoggedIn && isPenerbit,
            link: '/profile/document',
        },
        {
            value: 'Kotak Masuk',
            isShown: isLoggedIn,
            link: '/inbox',
        },
        {
            value: 'FAQ',
            isShown: true,
            link: '/faq',
        },
        {
            value: 'Tentang',
            isShown: true,
            link: '/about',
        },

        {
            value: 'Pasar Sekunder',
            isShown: true,
            link: '/secondary-market',
        },
        {
            value: 'Listing Pendanaan',
            isShown: isLoggedIn && isPenerbit,
            link: '/funds',
        },
        {
            value: 'Syarat & Ketentuan Pemodal',
            isShown: !isLoggedIn || isPemodal,
            link: '/terms-and-conditions/pemodal',
        },
        {
            value: 'Syarat & Ketentuan Penerbit',
            isShown: !isLoggedIn || isPenerbit,
            link: '/terms-and-conditions/penerbit',
        },
        {
            value: 'Pernyataan Risiko',
            isShown: true,
            link: '/risk-statement',
        },
        {
            value: 'Kebijakan Privasi',
            isShown: true,
            link: '/privacy-policy',
        },
        {
            value: 'Masuk/Daftar',
            isShown: !isLoggedIn,
            link: '/login',
        },
        {
            value: 'Keluar',
            isShown: isLoggedIn,
            link: '',
            onClick: onLogout,
        },
    ]
}

export default drawerList
