import styled from 'styled-components'
import colors from '../../basic/colors'

export const StyledAnchor = styled.a`
    &&& {
        color: ${colors.persianIndigo};

        cursor: pointer;
        font-size: 14px;
        margin: 5px 0px;

        &:hover {
            opacity: 0.6;
        }
        .fas {
            padding-right: 8px;
        }
    }
`

export const StyledToogleDrawerIcon = styled.img`
    cursor: pointer;
`
