import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import snakeCase from 'lodash/snakeCase'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import { Button, Form, ReduxFormRadioGroup, ReduxFormTextArea } from '../../antdcomponents'
import colors from '../../basic/colors'
import { sendToOptions } from '../../common/options/inbox'
import { required } from '../../utility/formValidation'
import { getEncryptedId } from '../../utility/getEncryptedDecryptedId'

const ChatModalContent = (props) => {
    const {
        clearInbox,
        createInbox,
        entityCode,
        handleSubmit,
        id,
        isActionLoading,
        onCancel,
        penerbitName,
        productId,
        purchaseOrderId,
        user,
        username,
    } = props
    const { dark, dispatch, navigate } = useOutletContext()

    useEffect(() => {
        if (id) {
            const encryptedId = getEncryptedId(id)
            navigate(`/inbox/${encryptedId}`)
        }
        return () => {
            dispatch(clearInbox())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearInbox, dispatch, id])

    const handleFinish = (values) => {
        const { message, toTable } = values

        let data = {
            createdBy: username,
            entityCode,
            fromTable: user,
            inboxDetail: {
                message,
            },
            productId,
            purchaseOrderId,
            toTable: snakeCase(toTable),
        }

        createInbox(data)
        onCancel()
    }

    return (
        <Form initialValues={{ productTypeTitle: 'penerbit' }} onFinish={handleSubmit(handleFinish)}>
            {purchaseOrderId && (
                <Field
                    component={ReduxFormRadioGroup}
                    componentProps={{
                        direction: 'horizontal',
                        radioPosition: 'left',
                    }}
                    formItemProps={{
                        labelColor: colors.graniteGray,
                        labelLevel: 4,
                    }}
                    label='Kirim Ke'
                    name='toTable'
                    options={sendToOptions(penerbitName)}
                    validate={[required]}
                />
            )}
            <Field
                component={ReduxFormTextArea}
                componentProps={{ rows: 5 }}
                formItemProps={{ isHideLabel: true }}
                name='message'
                placeholder='Ketik Pesan'
                validate={[required]}
            />
            <AntdRow justify='end'>
                <AntdCol md={12} sm={24} xs={24}>
                    <Button
                        dark={dark}
                        htmlType='submit'
                        loading={isActionLoading}
                        name='send-message-btn'
                        value='Kirim'
                    />
                </AntdCol>
            </AntdRow>
        </Form>
    )
}

ChatModalContent.propTypes = {
    clearInbox: PropTypes.func,
    createInbox: PropTypes.func,
    entityCode: PropTypes.string,
    handleSubmit: PropTypes.func,
    id: PropTypes.number,
    isActionLoading: PropTypes.bool,
    onCancel: PropTypes.func,
    penerbitName: PropTypes.string,
    productId: PropTypes.number,
    purchaseOrderId: PropTypes.number,
    user: PropTypes.string,
    username: PropTypes.string,
}

export default ChatModalContent
