import get from 'lodash/get'

const isLocalStorageAvailable = (windowObj = {}) => {
    let localStorage = get(windowObj, 'localStorage', {})
    if (typeof window !== 'undefined') {
        localStorage = window.localStorage
    }
    try {
        localStorage.setItem('test', 'test')
        localStorage.removeItem('test')
        return true
    } catch (e) {
        return false
    }
}

export default isLocalStorageAvailable
