import React from 'react'
import { connect } from 'react-redux'
import * as favoriteSlice from '../features/favorite/favoriteSlice'
import * as productSlice from '../features/product/productSlice'
import Favorite from './Favorite'

const FavoriteContainer = (props) => <Favorite {...props} />

const mapStateToProps = (state) => ({
    favorites: state.favorite.favorites,
    isActionLoading: state.favorite.isActionLoading,
    isActionSuccess: state.favorite.isActionSuccess,
    isLoading:
        state.product.isLoading ||
        state.productCategory.isLoading ||
        state.productRibbon.isLoading ||
        state.productType.isLoading,
    isLoadingFavorite: state.favorite.isLoading,
    productCategories: state.productCategory.productCategories,
    productRibbons: state.productRibbon.productRibbons,
    productTypes: state.productType.productTypes,
    products: state.product.products,
    totalProducts: state.product.totalProducts,
})

const mapDispatchToProps = {
    clearProduct: productSlice.clearProduct,
    clearFavorite: favoriteSlice.clearFavorite,
    getProducts: productSlice.getProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteContainer)
