import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/inbox'
import * as api from '../api'

export const createInbox = createAsyncThunk(
    'CREATE_INBOX',
    async (data) => await api.create('/inbox/create', toApi(data))
)

export const getInbox = createAsyncThunk('GET_INBOX', async (id, { rejectWithValue }) => {
    try {
        const res = await api.fetchById('/inbox', id)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getInboxes = createAsyncThunk(
    'GET_INBOXES',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/inboxes', { limit, offset, options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getInboxesUnread = createAsyncThunk(
    'GET_INBOXES_UNREAD',
    async ({ options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/inboxes/unread', { options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updateInbox = createAsyncThunk('UPDATE_INBOX', async (data, { rejectWithValue }) => {
    try {
        const res = await api.update('/inbox/update', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})
