import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import replace from 'lodash/replace'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field, reset } from 'redux-form'
import { BackButton, Button, Form, ReduxFormInput, ReduxFormSelect, ReduxFormTextArea } from '../antdcomponents'
import { subjectsOptions } from '../common/options/contactUs'
import HelmetMeta from '../components/HelmetMeta'
import SearchBar from '../components/search_bar/SearchBar'
import { StyledAntdSpace, StyledCenterSection, StyledSection } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import { required, validEmail } from '../utility/formValidation'
import * as notification from '../utility/notification'
import { StyledAntdDivider } from './StyledComponents'

const ContactUs = (props) => {
    const {
        change,
        clearContactUs,
        clearFaq,
        createContactUs,
        faqs,
        getFaqs,
        handleSubmit,
        isActionLoading,
        isActionSuccess,
    } = props
    const { dark, dispatch, navigate, search } = useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const subject = get(query, 'subject', '')

    const metaTitle = 'Tanya Moneypool'
    const faqOptions = map(faqs, (item) => {
        const question = replace(item.question, /<[^>]*>/g, '')
        return {
            label: question,
            value: question,
        }
    })

    useEffect(() => {
        if (!isEmpty(subject)) {
            change('subject', subject)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            notification.success('Email telah terkirim')
            dispatch(reset('contactUs'))
        }
        const fetchFaqs = async () =>
            await getFaqs({
                options: {
                    orderBy: {
                        order: 'asc',
                        by: 'question',
                    },
                },
            })
        fetchFaqs()
        return () => {
            dispatch(clearContactUs())
            dispatch(clearFaq())
        }
    }, [clearContactUs, clearFaq, dispatch, getFaqs, isActionLoading, isActionSuccess])

    const handleFinish = (values) => {
        const { email, message, subject } = values
        const data = {
            email,
            message,
            status: 'new',
            subject,
        }
        createContactUs(data)
    }

    const findFaqs = (value) => navigate(`/faq?keyword=${value}`)

    const handleSelect = (value) => findFaqs(value)
    const handleSearch = (value) => findFaqs(value)

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} />
            <AntdRow justify='center'>
                <AntdCol>
                    <StyledCenterSection>
                        <AntdSpace direction='vertical' size='large'>
                            <div>
                                <StyledAntdSpace direction='vertical' size='middle'>
                                    <StyledTitle $dark={dark} level={2}>
                                        {metaTitle}
                                    </StyledTitle>
                                    <SearchBar
                                        onSearch={handleSearch}
                                        onSelect={handleSelect}
                                        options={faqOptions}
                                        placeholder='Ketik Pertanyaan'
                                    />
                                </StyledAntdSpace>
                                <StyledAntdDivider />
                                <StyledTitle $dark={dark} level={3}>
                                    Belum menemukan jawaban dari pertanyaanmu? Kirim pertanyaanmu sekarang
                                </StyledTitle>
                            </div>
                            <Form
                                initialValues={{ subject: 'akun moneypool.co.id' }}
                                onFinish={handleSubmit(handleFinish)}
                            >
                                <Field
                                    component={ReduxFormInput}
                                    dark={dark}
                                    formItemProps={{ required: true }}
                                    label='Email'
                                    name='email'
                                    placeholder='Masukkan Email'
                                    validate={[required, validEmail]}
                                />
                                <Field
                                    component={ReduxFormSelect}
                                    componentProps={{
                                        options: subjectsOptions,
                                    }}
                                    dark={dark}
                                    formItemProps={{ required: true }}
                                    label='Subyek Pertanyaan'
                                    name='subject'
                                    placeholder='Pilih Subyek Pertanyaan'
                                    validate={[required]}
                                />
                                <Field
                                    component={ReduxFormTextArea}
                                    componentProps={{ rows: 5 }}
                                    dark={dark}
                                    formItemProps={{ required: true }}
                                    label='Pesan'
                                    name='message'
                                    validate={[required]}
                                />
                                <AntdRow>
                                    <AntdCol md={{ offset: 6, span: 12 }} sm={24} xs={24}>
                                        <Button
                                            dark={dark}
                                            htmlType='submit'
                                            loading={isActionLoading}
                                            name='contact-us-btn'
                                            value='Kirim'
                                        />
                                    </AntdCol>
                                </AntdRow>
                            </Form>
                            <center>
                                <BackButton dark={dark} lastLocation />
                            </center>
                        </AntdSpace>
                    </StyledCenterSection>
                </AntdCol>
            </AntdRow>
        </StyledSection>
    )
}

ContactUs.propTypes = {
    change: PropTypes.func,
    clearContactUs: PropTypes.func,
    clearFaq: PropTypes.func,
    createContactUs: PropTypes.func,
    faqs: PropTypes.array,
    getFaqs: PropTypes.func,
    handleSubmit: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
}

export default ContactUs
