import { default as AntdSpace } from 'antd/lib/space'
import clone from 'lodash/clone'
import get from 'lodash/get'
import map from 'lodash/map'
import React from 'react'
import EyeIcon from '../components/eye_icon/EyeIcon'
import formatPrice from '../utility/formatPrice'

const columns = (tooltipSettlementDate) => [
    {
        dataIndex: 'type',
        title: 'Tipe',
        width: 50,
    },
    {
        render: (object) => {
            const name = get(object, 'name', '')
            const slug = get(object, 'slug', '')
            return (
                <>
                    <EyeIcon route={`/product/${slug}`} /> {name}
                </>
            )
        },
        title: 'Produk',
        width: 250,
    },
    {
        dataIndex: 'purchaseOrderSubtotal',
        render: (number) => (number ? `Rp ${formatPrice(number)}` : '-'),
        title: 'Total Beli',
        width: 100,
    },
    {
        dataIndex: 'purchaseOrderDividendTotal',
        render: (number) => (number ? `Rp ${formatPrice(number)}` : '-'),
        title: 'Keuntungan',
        width: 100,
    },
    {
        dataIndex: 'settlementDate',
        title: (
            <AntdSpace>
                <>Tanggal Settlement</>
                <>{tooltipSettlementDate}</>
            </AntdSpace>
        ),
        width: 150,
    },
]

const dataSource = (data) =>
    map(data, (item, key) =>
        Object.assign(clone(item), {
            key: String(key),
        })
    )

export { columns, dataSource }
