import { Input as AntdInput } from 'antd'
import styled from 'styled-components'
import colors from '../../basic/colors'

const AntdInputTextArea = AntdInput.TextArea
const StyledAntdInputTextArea = styled(AntdInputTextArea)`
    &&& {
        border-color: ${(props) => (props.$dark ? colors.robbinEggBlue : 'silver')};
        border-radius: 20px;
        border-style: solid;
        border-width: 2px;
        box-shadow: none;
        outline: none;
        resize: none;
        &:focus {
            box-shadow: none !important;
            border-right-width: 2px !important;
        }
        ${(props) =>
            !props.disabled &&
            `
            background-color: rgba(0, 0, 0, 0) !important;
            color: ${props.$dark ? 'white' : 'black'};
        `}
    }
`
export default StyledAntdInputTextArea
