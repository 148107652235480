import PropTypes from 'prop-types'
import React from 'react'
import { StyledAntdSelect } from './StyledComponents'

const Select = (props) => {
    const {
        allowClear,
        bordered,
        className,
        dark,
        defaultValue,
        disabled,
        name,
        notFoundContent,
        onChange,
        onBlur,
        onFocus,
        onSearch,
        optionFilterProp,
        optionLabelProp,
        options,
        placeholder,
        size,
        value,
    } = props

    const handleChange = (value) => {
        const newValue = value === undefined ? '' : value
        onChange(newValue, name)
    }

    const handleSelect = (value) => onChange(value, name)

    return (
        <StyledAntdSelect
            $dark={dark}
            allowClear={allowClear}
            bordered={bordered}
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            dropdownMatchSelectWidth
            maxTagCount={30}
            maxTagPlaceholder='lebih banyak...'
            name={name}
            notFoundContent={notFoundContent}
            onBlur={onBlur}
            onChange={handleChange}
            onFocus={onFocus}
            onSearch={onSearch}
            onSelect={handleSelect}
            optionFilterProp={optionFilterProp}
            optionLabelProp={optionLabelProp}
            options={options}
            placeholder={placeholder}
            showSearch
            size={size}
            value={value}
        />
    )
}

Select.propTypes = {
    allowClear: PropTypes.bool,
    bordered: PropTypes.bool,
    className: PropTypes.string,
    dark: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    notFoundContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onSearch: PropTypes.func,
    onSelect: PropTypes.func,
    optionFilterProp: PropTypes.string,
    optionLabelProp: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ).isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number),
    ]),
}

Select.defaultProps = {
    allowClear: true,
    notFoundContent: 'Tidak Ditemukan',
    options: [],
    placeholder: 'Pilih disini',
}

export default Select
