import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../api'

export const getCities = createAsyncThunk(
    'GET_CITIES',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/cities', { limit, offset, options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
