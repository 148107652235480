import { default as AntdCol } from 'antd/lib/col'
import { default as AntdDivider } from 'antd/lib/divider'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { Field } from 'redux-form'
import { useOutletContext } from 'react-router'
import { BackButton, Button, Form, ReduxFormCheckboxGroup } from '../antdcomponents'
import colors from '../basic/colors'
import { paymentMethodOptions as purchaseOrderPaymentMethodOptions } from '../common/options/purchaseOrder'
import StyledTitle from '../components/styled_title/StyledTitle'
import { DATE_SLASH_FORMAT } from '../constants/formats'
import { required } from '../utility/formValidation'
import formatPrice from '../utility/formatPrice'
import useSelectorFormValues from '../utility/useSelectorFormValues'
import { StyledFormContainer } from './StyledComponents'

const Confirmation = (props) => {
    const {
        change,
        clearCart,
        clearPurchaseOrder,
        createPurchaseOrder,
        handleSubmit,
        isActionLoading,
        isDigisignActivated,
        isSignSuccess,
        newPurchaseOrder,
        setPageNumber,
        tooltipAdminFee,
        tooltipTransactionFee,
    } = props
    const { dark, dispatch } = useOutletContext()
    const [invoiceUrl, setInvoiceUrl] = useState('')

    useEffect(() => {
        if (invoiceUrl) {
            window.location.href = invoiceUrl
        }
        if (isDigisignActivated && isSignSuccess) {
            change('isConfirm', ['1'])
        }
        return () => {
            dispatch(clearPurchaseOrder())
        }
    }, [change, clearPurchaseOrder, dispatch, invoiceUrl, isSignSuccess, isDigisignActivated])

    const { adminFee, paymentMethod, product, quantity, subtotal, totalPayment, transactionFee, unitPrice } =
        newPurchaseOrder
    const totalFees = adminFee + transactionFee

    const penerbitName = get(product, 'penerbitName', '')
    const category = get(product, 'category', '')
    const code = get(product, 'code', '')
    const type = get(product, 'type', '')

    const today = moment().format(DATE_SLASH_FORMAT)

    const confirmationLabel =
        'Dengan ini saya menyetujui pembelian saham bisnis termasuk prospektus penerbit bersangkutan di dalamnya dan memahami segala risiko atas keputusan beli yang saya buat.'

    const formValues = useSelectorFormValues('purchaseOrderConfirmation')
    const isConfirm = get(formValues, 'isConfirm', [])
    const disabled = isEmpty(isConfirm)

    const purchaseOrderPaymentMethodOption = find(
        purchaseOrderPaymentMethodOptions,
        (item) => item.value === paymentMethod
    )
    const purchaseOrderPaymentMethodOptionLabel = get(purchaseOrderPaymentMethodOption, 'label', '')

    useEffect(() => {
        if (invoiceUrl) {
            dispatch(clearCart())
            window.location.href = invoiceUrl
        }
        return () => {
            dispatch(clearPurchaseOrder())
        }
    }, [clearCart, clearPurchaseOrder, dispatch, invoiceUrl])

    const handleBack = () => setPageNumber(0)

    const handleFinish = (values) =>
        createPurchaseOrder(Object.assign(newPurchaseOrder, values)).then((response) => {
            const data = get(response, 'payload.body.data', {})
            const newInvoiceUrl = get(data, 'invoiceUrl', '')
            setInvoiceUrl(newInvoiceUrl)
            return null
        })

    const renderFormRowItem = (title, value, valueColor) => (
        <Fragment>
            <AntdCol span={12}>
                <AntdRow justify='left'>
                    <StyledTitle color={colors.graniteGray} level={3}>
                        {title}
                    </StyledTitle>
                </AntdRow>
            </AntdCol>
            <AntdCol offset={1} span={11}>
                <AntdRow justify='left'>
                    <StyledTitle $dark={dark} color={valueColor} level={3}>
                        {value}
                    </StyledTitle>
                </AntdRow>
            </AntdCol>
        </Fragment>
    )

    return (
        <Form onFinish={handleSubmit(handleFinish)}>
            <AntdSpace direction='vertical'>
                <BackButton onClick={handleBack} />
                <center>
                    <StyledTitle $dark={dark} level={2}>
                        Konfirmasi Order
                    </StyledTitle>
                </center>
                <AntdRow justify='center'>
                    <StyledFormContainer>
                        <AntdRow gutter={[0, 20]}>
                            {renderFormRowItem('Tanggal', today)}
                            {renderFormRowItem('Kode', code)}
                            {renderFormRowItem('Penerbit', penerbitName)}
                            {renderFormRowItem('Kategori', category)}
                            {renderFormRowItem('Tipe', type)}
                            {renderFormRowItem('Harga Per Satuan', `Rp ${formatPrice(unitPrice)}`)}
                            {renderFormRowItem('Jumlah Lembar', quantity)}
                            {renderFormRowItem('Metode Pembayaran', purchaseOrderPaymentMethodOptionLabel)}
                            {renderFormRowItem('Subtotal', `Rp ${formatPrice(subtotal)}`)}
                            <AntdDivider />
                            {renderFormRowItem(
                                <Fragment>
                                    Biaya Admin &nbsp;
                                    {tooltipAdminFee}
                                </Fragment>,
                                `Rp ${formatPrice(adminFee)}`
                            )}
                            {transactionFee > 0 &&
                                renderFormRowItem(
                                    <Fragment>
                                        Biaya Transaksi &nbsp;
                                        {tooltipTransactionFee}
                                    </Fragment>,
                                    `Rp ${formatPrice(transactionFee)}`
                                )}
                            {renderFormRowItem('Total Biaya', `Rp ${formatPrice(totalFees)}`)}
                            <AntdDivider />
                            {renderFormRowItem('Total Pembayaran', `Rp ${formatPrice(totalPayment)}`)}
                        </AntdRow>
                        <br />
                        <br />
                        <Field
                            component={ReduxFormCheckboxGroup}
                            formItemProps={{
                                isHideLabel: true,
                                required: true,
                            }}
                            name='isConfirm'
                            options={[
                                {
                                    label: confirmationLabel,
                                    value: '1',
                                },
                            ]}
                            validate={[required]}
                        />
                        <AntdRow>
                            <AntdCol offset={6} span={12}>
                                <Button
                                    dark={dark}
                                    disabled={disabled}
                                    htmlType='submit'
                                    loading={isActionLoading}
                                    name='confirm-order-btn'
                                    value='Beli'
                                />
                            </AntdCol>
                        </AntdRow>
                    </StyledFormContainer>
                </AntdRow>
            </AntdSpace>
        </Form>
    )
}

Confirmation.propTypes = {
    change: PropTypes.func,
    clearCart: PropTypes.func,
    clearPurchaseOrder: PropTypes.func,
    createPurchaseOrder: PropTypes.func,
    handleSubmit: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isDigisignActivated: PropTypes.bool,
    isSignSuccess: PropTypes.bool,
    newPurchaseOrder: PropTypes.object,
    setPageNumber: PropTypes.func,
    tooltipAdminFee: PropTypes.node,
    tooltipTransactionFee: PropTypes.node,
}

export default Confirmation
