import React from 'react'
import { connect } from 'react-redux'
import * as digisignSlice from '../../features/digisign/digisignSlice'
import VerificationFailed from './VerificationFailed'

let VerificationFailedContainer = (props) => <VerificationFailed {...props} />

const mapStateToProps = () => ({})

const mapDispatchToProps = {
    clearDigisign: digisignSlice.clearDigisign,
}

export default connect(mapStateToProps, mapDispatchToProps)(VerificationFailedContainer)
