import styled from 'styled-components'
import { Card } from '../../antdcomponents'
import colors from '../../basic/colors'

export const StyledCard = styled(Card)`
    &&& {
        border-radius: 15px;
        box-shadow: 0px 4px 10px ${colors.black}40;
        margin: 5px;
        padding: 25px 30px;
        width: 280px;
        z-index: 10;

        div.ant-card-body {
            padding: 0px;
        }

        @media screen and (min-width: 768px) {
            padding: 25px 65px;
            width: 480px;
        }
    }
`

export const StyledNameContainer = styled.div`
    display: flex;
`
