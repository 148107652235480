import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import * as inboxDetailAPI from './inboxDetailAPI'

const initialState = {
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
}

export const { createInboxDetail, readInboxDetails } = inboxDetailAPI

const inboxDetailSlice = createSlice({
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(createInboxDetail.pending, readInboxDetails.pending), (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addMatcher(isAnyOf(createInboxDetail.fulfilled, readInboxDetails.fulfilled), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
        builder.addMatcher(isAnyOf(createInboxDetail.rejected, readInboxDetails.rejected), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true,
            })
        )
    },
    initialState,
    name: 'INBOX_DETAIL',
    reducers: {
        clearInboxDetail: () => initialState,
    },
})

export const { clearInboxDetail } = inboxDetailSlice.actions

const { reducer } = inboxDetailSlice
export default reducer
