import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import size from 'lodash/size'
import moment from 'moment'
import { DATE_SLASH_FORMAT } from '../constants/formats'

export const required = (value) => {
    if (value === undefined || value === null || value === '') {
        return 'wajib diisi'
    }
    if (isArray(value) || isObject(value)) return isEmpty(value) ? 'wajib' : undefined
    return undefined
}

export const alphabetRequired = (value) => {
    const regex = /^[a-zA-Z]+$/g
    if (regex.test(value)) {
        return 'hanya boleh memasukkan huruf'
    }
    return undefined
}

export const numberRequired = (value) => {
    const regex = /\D/g
    if (isEmpty(value) || regex.test(value)) {
        return 'hanya boleh memasukkan angka'
    }
    return undefined
}

export const greaterThanZero = (value) => (!isNaN(value) && Number(value) > 0 ? undefined : 'harus lebih besar dari 0')

export const lessThanOneHundred = (value) =>
    !isNaN(value) && Number(value) > 0 ? undefined : 'harus lebih besar kecil dari 100'

export const maxLength = (max) => (value) =>
    value && value.length > max ? `harus terdiri dari ${max} karakter atau kurang` : undefined

export const minLength = (min) => (value) =>
    value && value.length < min ? `harus terdiri dari ${min} karakter atau lebih` : undefined

export const rangeRequired = (value) => {
    if (!value) return undefined
    if (!isArray(value) || value.length < 2) return 'harus dalam bentuk periode tanggal'
    if (!value[0] && !value[1]) return undefined
    if (!value[0] || !value[1]) return 'harus dalam bentuk periode tanggal'
}

export const inputRangeRequired = (value) => {
    if (!value) return undefined
    if (!isArray(value) || value[0] > value[1]) return 'kolom kedua harus lebih besar dari kolom pertama'
    if (!value[0] && !value[1]) return undefined
}

export const todayOnwards = (value) =>
    value && moment(value).startOf('day').isBefore(moment().startOf('day'))
        ? 'tidak boleh memilih tanggal yang sudah lewat'
        : undefined

export const username = (value) =>
    value && !/^[a-zA-Z0-9._-]+([-_.]?[a-zA-Z0-9._-])*$/g.test(value)
        ? 'username hanya boleh menggunakan angka dan huruf'
        : undefined

export const passwordMinimalCharacter = (value) => {
    const regexMinimal = /.{8,}/g
    if (!regexMinimal.test(value)) {
        return 'kata sandi minimal 8 karakter'
    }

    return undefined
}

export const passwordRequiredCharacter = (value) => {
    const regexMustCharacter = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.*\W)/g

    if (!regexMustCharacter.test(value)) {
        return 'kata sandi harus berisi huruf kapital, huruf kecil, dan angka saja'
    }

    return undefined
}

export const confirmPassword = (values) => {
    const errors = {}
    const passwordErrorMessage = 'kata sandi dan konfirmasi kata sandi tidak cocok'
    if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
        errors.password = passwordErrorMessage
        errors.confirmPassword = passwordErrorMessage
    }
    return errors
}

export const otpCheck = (values) => (values !== '000000' ? 'Kode OTP tidak sesuai' : undefined)

export const otpCode = (value) => {
    if (size(value) !== 6) {
        return 'kode otp harus terdiri dari 6 digit'
    }

    const regex = /\D/g
    if (regex.test(value)) {
        return 'kode otp hanya terdiri dari angka'
    }

    return undefined
}

export const lengthIdCardNumber = (value) => {
    const length = 16
    if (value && String(value).length !== length) {
        return `masukan harus berjumlah ${length} karakter`
    }
    return undefined
}

export const olderThan = (age) => (value) => {
    return value && moment().diff(moment(value, DATE_SLASH_FORMAT), 'years') < age
        ? `pengguna harus berusia lebih dari ${age} tahun`
        : undefined
}

export const validEmail = (value) => {
    const regex = /^\s*[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}\s*$/
    if (!regex.test(value)) {
        return 'email harus valid'
    }
    return undefined
}
