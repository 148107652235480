import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import includes from 'lodash/includes'
import toLower from 'lodash/toLower'
import * as digisignAPI from './digisignAPI'

const initialState = {
    digisignActivationUrl: '',
    digisignDocumentUrl: '',
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    message: '',
    result: '',
}

export const { generateLinkActivation, digisignCallback, register, signDocument } = digisignAPI

const digisign = createSlice({
    extraReducers: (builder) => {
        builder.addCase(register.pending, (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
                message: '',
            })
        )
        builder.addCase(register.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            const message = get(data, 'message', '')
            const isNeedActivation = includes(toLower(message), 'aktivasi')
            return Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
                message,
                isNeedActivation,
                result: get(data, 'result', ''),
            })
        })
        builder.addCase(register.rejected, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: false,
                isError: true,
            })
        )
        builder.addCase(generateLinkActivation.pending, (state) =>
            Object.assign(state, {
                digisignActivationUrl: '',
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addCase(generateLinkActivation.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                digisignActivationUrl: get(data, 'digisignActivationUrl', ''),
                isActionLoading: false,
            })
        })
        builder.addCase(generateLinkActivation.rejected, (state) =>
            Object.assign(state, {
                digisignActivationUrl: '',
                isActionLoading: false,
            })
        )
        builder.addCase(signDocument.pending, (state) =>
            Object.assign(state, {
                digisignDocumentUrl: '',
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addCase(signDocument.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                digisignDocumentUrl: data.digisignDocumentUrl,
            })
        })
        builder.addCase(signDocument.rejected, (state) =>
            Object.assign(state, {
                isActionLoading: false,
            })
        )
        builder.addCase(digisignCallback.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(digisignCallback.fulfilled, (state) => {
            return Object.assign(state, {
                isLoading: false,
            })
        })
        builder.addCase(digisignCallback.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'digisign',
    reducers: {
        clearDigisign: () => initialState,
    },
})

export const { clearDigisign } = digisign.actions

const { reducer } = digisign
export default reducer
