export const toApi = (data) => ({
    file: data.file,
    identifier: data.identifier,
    type: data.type,
    url: data.url,
})

export const toState = (data) => ({
    identifier: data.identifier || '',
    url: data.url || '',
})
