import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as productTypeSlice from '../../features/product_type/productTypeSlice'
import PurchaseOrderFilter from './PurchaseOrderFilter'

let PurchaseOrderFilterContainer = (props) => <PurchaseOrderFilter {...props} />

const mapStateToProps = (state) => ({
    isLoadingProductType: state.productType.isLoading,
    isLoadingPurchaseOrder: state.purchaseOrder.isLoading,
    productTypes: state.productType.productTypes,
})

const mapDispatchToProps = {
    clearProductType: productTypeSlice.clearProductType,
    getProductTypes: productTypeSlice.getProductTypes,
}

PurchaseOrderFilterContainer = reduxForm({
    enableReinitialize: true,
    form: 'purchaseOrderFilter',
})(PurchaseOrderFilterContainer)

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderFilterContainer)
