import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../../basic/colors'
import modifyVars from '../../basic/modifyVars'

const getColor = (color, dark) => {
    if (dark) {
        return 'white'
    } else {
        if (color) {
            return color
        } else {
            return colors.persianIndigo
        }
    }
}

const StyledSpan = styled.span`
    font-style: ${modifyVars.spanStyle.fontStyle};
    font-weight: ${modifyVars.spanStyle.fontWeight};
    font-size: ${modifyVars.spanStyle.fontSizeMobile} !important;
    font-family: ${modifyVars.spanStyle.fontFamily};
    letter-spacing: ${modifyVars.spanStyle.letterSpacing};
    line-height: ${(props) => props.$lineHeight}px;

    &,
    & > * {
        color: ${(props) => getColor(props.color, props.$dark)} !important;
    }

    ${(props) =>
        props.$textAlign &&
        `
        display: block;
        text-align: ${props.$textAlign};
    `}

    ${(props) => {
        if (!props.background) {
            return ''
        }
        const color = getColor(props.background, !props.$dark)
        return `
            background-color: ${color};
            padding: 5px 10px;
            border-radius: 20px;
        `
    }}

    @media screen and (min-width: 768px) {
        font-size: ${modifyVars.spanStyle.fontSizeDesktop} !important;
    }
`

StyledSpan.propTypes = {
    $dark: PropTypes.bool,
    background: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    color: PropTypes.string,
}

StyledSpan.defaultProps = {
    $dark: false,
}

export default StyledSpan
