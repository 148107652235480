import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/contactUs'
import * as api from '../api'

export const createContactUs = createAsyncThunk('CREATE_CONTACT_US', async (data, { rejectWithValue }) => {
    try {
        const res = await api.create('/contact-us/create', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})
