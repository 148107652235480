import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import React, { Fragment, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { StyledSection } from '../components/StyledComponents'
import StyledParagraph from '../components/styled_paragraph/StyledParagraph'
import StyledTitle from '../components/styled_title/StyledTitle'
import { StyledAskQuestionContainer, StyledIframe } from './StyledComponents'

import HelmetMeta from '../components/HelmetMeta'
import ProductCategories from './comps/ProductCategories'
import { getInfo, getSocialMedias, getProductCategories } from '../services/api'
import { connect } from 'react-redux'
import { isProductionEnv } from '../constants/constants'
import { renderLinkButton } from '../utility/renderLinkComp'

const Home = () => {
    const { dark, dispatch } = useOutletContext()

    useEffect(() => {
        //for SSR only
        if (isProductionEnv) {
            const dispatchInfo = dispatch(getInfo.initiate())
            const dispatchSocial = dispatch(getSocialMedias.initiate())
            const dispatchCategory = dispatch(
                getProductCategories.initiate({ options: { notExpired: 'true', withProducts: 'true' } })
            )

            return () => {
                dispatchInfo.unsubscribe
                dispatchSocial.unsubscribe
                dispatchCategory.unsubscribe
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderEasyWayToInvest = () => (
        <StyledSection>
            <center>
                <StyledTitle level={2}>Cara Mudah Investasi Moneypool</StyledTitle>
            </center>
            <br />
            <AntdRow align='middle' justify={isMobile() ? 'center' : 'space-between'}>
                <AntdCol md={{ span: 16 }} sm={24}>
                    <StyledIframe
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        src='https://www.youtube.com/embed/Su34c5Z8DW4'
                        title='How To Invest moneypool'
                    />
                </AntdCol>
                <AntdCol md={{ offset: 1, span: 7 }} sm={24}>
                    <StyledAskQuestionContainer>
                        <StyledTitle level={3}>Ada pertanyaan lebih lanjut?</StyledTitle>
                        <StyledParagraph>
                            Silahkan hubungi pusat bantuan untuk memberikan solusi untukmu
                        </StyledParagraph>
                        <br />
                        <AntdRow>
                            <AntdCol
                                md={{ offset: 0, span: 24 }}
                                sm={{ offset: 5, span: 14 }}
                                xs={{ offset: 5, span: 14 }}
                            >
                                {renderLinkButton('/contact-us', { dark, name: 'ask-btn', value: 'Tanya Moneypool' })}
                            </AntdCol>
                        </AntdRow>
                    </StyledAskQuestionContainer>
                </AntdCol>
            </AntdRow>
        </StyledSection>
    )

    return (
        <Fragment>
            <HelmetMeta />
            <ProductCategories />
            {renderEasyWayToInvest()}
        </Fragment>
    )
}

Home.propTypes = {}

const mapStateToProps = (state) => ({
    id: state.authentication.id,
    isBankinfoExist: state.authentication.isBankinfoExist,
    favorites: state.favorite.favorites,
    inboxes: state.inbox.inboxes,
    isActionLoading: state.cart.isActionLoading,
    isActionSuccess: state.cart.isActionSuccess,
    isActive: state.authentication.isActive,
    isError: state.product.isError,
    isLoading: state.product.isLoading,
    product: state.product.product,
})

export default connect(mapStateToProps)(Home)
