import { connect } from 'react-redux'
import * as penerbitDetailSlice from '../../../features/penerbit_detail/penerbitDetailSlice'
import DeletePenerbitDetail from './DeletePenerbitDetail'
import React from 'react'

let DeletePenerbitDetailContainer = (props) => <DeletePenerbitDetail {...props} />

const mapStateToProps = (state) => ({
    isActionLoading: state.penerbitDetail.isActionLoading,
    isActionSuccess: state.penerbitDetail.isActionSuccess,
})

const mapDispatchToProps = {
    deletePenerbitDetail: penerbitDetailSlice.deletePenerbitDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletePenerbitDetailContainer)
