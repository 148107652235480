import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import map from 'lodash/map'
import isFunction from 'lodash/isFunction'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '../../basic/colors'
import StyledParagraph from '../../components/styled_paragraph/StyledParagraph'
import { StyledAntdRadio, StyledAntdRadioGroup, StyledAntdSpace } from './StyledComponents'

const RadioGroup = (props) => {
    const {
        className,
        containerClassName,
        dark,
        direction,
        disabled,
        name,
        onChange,
        options,
        optionsClassName,
        radioPosition,
        size,
        type,
        value,
    } = props

    const renderOptionLabel = (option) => (isFunction(option.renderLabel) ? option.renderLabel() : option.label)

    const renderOptions = (options, type, optionsClassName) => {
        let RadioComponent = StyledAntdRadio
        if (type === 'button') {
            RadioComponent = StyledAntdRadio.Button
        }

        return map(options, (o, key) => {
            if (radioPosition === 'left') {
                return (
                    <RadioComponent className={optionsClassName} disabled={o.disabled} key={key} value={o.value}>
                        <StyledParagraph $dark={dark} color={colors.graniteGray}>
                            {renderOptionLabel(o)}
                        </StyledParagraph>
                    </RadioComponent>
                )
            } else {
                return (
                    <AntdRow justify='space-between' key={key}>
                        <AntdCol span={22}>
                            <StyledParagraph $dark={dark} color={colors.graniteGray}>
                                {renderOptionLabel(o)}
                            </StyledParagraph>
                        </AntdCol>
                        <AntdCol span={2}>
                            <AntdRow>
                                <RadioComponent
                                    className={optionsClassName}
                                    disabled={o.disabled}
                                    key={o.value}
                                    value={o.value}
                                />
                            </AntdRow>
                        </AntdCol>
                    </AntdRow>
                )
            }
        })
    }

    const handleChange = (e) => {
        onChange(e.target.value, name, e)
    }

    return (
        <div className={`radio-group ${className}`}>
            <StyledAntdRadioGroup
                className={`radio-group__container ${containerClassName}`}
                disabled={disabled}
                name={name}
                onChange={handleChange}
                size={size}
                value={value}
            >
                <StyledAntdSpace direction={direction}>
                    {renderOptions(options, type, `radio-group__option ${optionsClassName}`)}
                </StyledAntdSpace>
            </StyledAntdRadioGroup>
        </div>
    )
}

RadioGroup.propTypes = {
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    dark: PropTypes.bool,
    direction: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.bool,
            label: PropTypes.string,
            renderLabel: PropTypes.func,
            value: PropTypes.any.isRequired,
        })
    ),
    optionsClassName: PropTypes.string,
    radioPosition: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    type: PropTypes.oneOf(['button', 'radio']),
    value: PropTypes.any,
}

RadioGroup.defaultProps = {
    className: '',
    containerClassName: '',
    direction: 'vertical',
    disabled: false,
    onChange: noop,
    options: [],
    optionsClassName: '',
    size: 'large',
    type: 'radio',
}

export default RadioGroup
