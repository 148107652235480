import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/investmentGoal'
import * as investmentGoalAPI from './investmentGoalAPI'

const initialState = {
    investmentGoals: [],
    isError: false,
    isLoading: false,
    totalInvestmentGoals: 0,
}

export const { getInvestmentGoals } = investmentGoalAPI

const investmentGoalSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getInvestmentGoals.pending, (state) =>
            Object.assign(state, {
                investmentGoals: [],
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(getInvestmentGoals.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                investmentGoals: map(data.investmentGoals, (item) => toState(item)),
                isLoading: false,
                totalInvestmentGoals: data.count,
            })
        })
        builder.addCase(getInvestmentGoals.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'INCOME_SOURCE',
    reducers: {
        clearInvestmentGoal: () => initialState,
    },
})

export const { clearInvestmentGoal } = investmentGoalSlice.actions

const { reducer } = investmentGoalSlice
export default reducer
