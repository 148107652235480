import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import * as productTransformer from './product'

export const toState = (data) => {
    const products = !isEmpty(data.products) ? map(data.products, (product) => productTransformer.toState(product)) : []

    return {
        products,
        title: data.title || '',
    }
}
