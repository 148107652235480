import PropTypes from 'prop-types'
import React from 'react'
import { FormItem } from '../../antdcomponents'
import colors from '../../basic/colors'
import { StyledPlusOutlined, StyledUploadButtonSpace } from '../../components/StyledComponents'
import StyledSpan from '../../components/styled_span/StyledSpan'
import { UPLOAD_FORMAT_SIZE } from '../../constants/formats'
import { StyledButton } from '../StyledComponents'

const Webcam = (props) => {
    const { disabled, handleShowModal, isError, label } = props

    const cameraButtonValue = (
        <StyledUploadButtonSpace direction='vertical'>
            <StyledPlusOutlined />
            <StyledUploadButtonSpace direction='vertical' size={0}>
                <StyledSpan $lineHeight={1}>Unggah</StyledSpan>
                <StyledSpan $lineHeight={1} color={colors.blueberry}>
                    {UPLOAD_FORMAT_SIZE}
                </StyledSpan>
            </StyledUploadButtonSpace>
        </StyledUploadButtonSpace>
    )

    return (
        <FormItem help={isError && 'wajib diisi'} label={label} validateStatus={isError ? 'error' : undefined}>
            <StyledButton
                $isError={isError}
                disabled={disabled}
                name='camera-btn'
                onClick={handleShowModal}
                type='default'
                value={cameraButtonValue}
            />
        </FormItem>
    )
}

Webcam.propTypes = {
    disabled: PropTypes.bool,
    handleShowModal: PropTypes.func,
    isError: PropTypes.bool,
    label: PropTypes.string,
}

export default Webcam
