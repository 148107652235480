import { default as AntdAvatar } from 'antd/lib/avatar'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import isMobile from 'is-mobile'
import divide from 'lodash/divide'
import floor from 'lodash/floor'
import map from 'lodash/map'
import size from 'lodash/size'
import React, { Fragment } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Carousel } from '../antdcomponents'
import colors from '../basic/colors'
import imagePath from '../basic/imagePath'
import FounderCard from '../components/founder_card/FounderCard'
import HelmetMeta from '../components/HelmetMeta'
import { StyledAntdSpace, StyledSection } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import TestimonialCard from '../components/testimonial_card/TestimonialCard'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'
import { founders, testimonial, whyInvestReasons } from './helper'
import {
    StyledAntdList,
    StyledBuildingImage,
    StyledCarousel,
    StyledImage,
    StyledLocationInfoContainer,
    StyledLocationInfoDetail,
    StyledWhyInvestContainer,
    StyledWhyInvestSection,
} from './StyledComponents'

const About = () => {
    const { componentSize, dark, width } = useOutletContext()

    const renderWhyInvest = () => {
        const column = isMobile() ? 1 : 2

        const handleRenderItem = (item, key) => (
            <StyledAntdList.Item key={key}>
                <StyledWhyInvestContainer>
                    <AntdSpace size='large'>
                        <AntdAvatar size={66} src={item.imgSrc} />
                        <StyledTitle $dark={!dark} level={3}>
                            {item.text}
                        </StyledTitle>
                    </AntdSpace>
                </StyledWhyInvestContainer>
            </StyledAntdList.Item>
        )
        return (
            <StyledWhyInvestSection $size={componentSize}>
                <AntdSpace direction='vertical' size='large'>
                    <AntdRow justify={isMobile() ? 'left' : 'center'}>
                        <StyledTitle $dark={!dark} level={2}>
                            Mengapa Berinvestasi di Moneypool ?
                        </StyledTitle>
                    </AntdRow>
                    <StyledAntdList
                        dataSource={whyInvestReasons}
                        grid={{ gutter: 10, column }}
                        renderItem={handleRenderItem}
                    />
                </AntdSpace>
            </StyledWhyInvestSection>
        )
    }

    const renderOurStory = () => {
        const slidesToScroll = 1
        const foundersSize = size(founders)
        let slidesToShow = floor(divide(width - 200, 300) - 0.5)
        slidesToShow = slidesToShow > foundersSize ? foundersSize : slidesToShow
        slidesToShow = isMobile() ? 1 : slidesToShow
        const content = map(founders, (item, key) => (
            <center key={key}>
                <FounderCard {...item} />
            </center>
        ))
        const visionAndMision = [
            {
                title: 'Visi',
                content:
                    'Menjadi pembina dan pendorong kemajuan usaha kecil dan menengah untuk mendapatkan modal melalui pendanaan dari public (urun dana), termasuk pembinaan kualits management, pembinaan kuliatas SDM, dan bantuan teknis khusus dibidang teknologi informasi, dengan tujuan utama untuk menciptakan kesuksesan & kelimpahan bagi perusahaan dan Pemodal Indonesia',
            },
            {
                title: 'Misi',
                content: (
                    <AntdSpace direction='vertical' size='large'>
                        <Fragment>
                            Mengembangkan sarana teknologi digital modern yang lebih efisien dan mudah untuk
                            mempertemukan Pemodal dengan pemilik perusahaan.
                        </Fragment>
                        <Fragment>
                            Penyedia dan peningkatan permodalan, membantu dan membimbing perusahaan berkembang lebih
                            cepat dari target sebelumnya.
                        </Fragment>
                        <Fragment>
                            Mengevaluasi dan menvaluasi secara jujur dan transparan performa perusahaan yang sehat dan
                            bertanggung jawab untuk ditawarkan kepada Pemodal.
                        </Fragment>
                        <Fragment>
                            Melakukan penawaran saham dan promosi atas jumlah dan harga saham yang ingin ditawarkan
                            dengan menarik.
                        </Fragment>
                        <Fragment>
                            Memastikan sistem dan keamanan yang selalu diperbaharui agar setiap perjanjian yang ada
                            dapat terealisasi dengan baik.
                        </Fragment>

                        <Fragment>
                            Membantu dan menyakinkan pihak Penerbit, Pemodal, semua rekan bisnis dan seluruh staf
                            penyelenggara secara bersama-sama untuk bisa menikmati kesuksesan dan kemakmuran
                        </Fragment>
                    </AntdSpace>
                ),
            },
        ]
        return (
            <StyledSection $size={componentSize}>
                <StyledCarousel
                    arrows
                    autoplaySpeed={4000}
                    content={content}
                    dots={false}
                    slidesToScroll={slidesToScroll}
                    slidesToShow={slidesToShow}
                    speed={1000}
                />
                <AntdRow gutter={[0, 35]}>
                    {map(visionAndMision, (item, key) => (
                        <Fragment key={key}>
                            <AntdCol md={4} sm={24} xs={24}>
                                <StyledTitle level={1}>{item.title}</StyledTitle>
                            </AntdCol>
                            <AntdCol md={{ offset: 1, span: 19 }} sm={24} xs={24}>
                                <StyledTitle color={colors.graniteGray} level={3}>
                                    {item.content}
                                </StyledTitle>
                            </AntdCol>
                        </Fragment>
                    ))}
                </AntdRow>
            </StyledSection>
        )
    }

    const renderLocationInfo = () => {
        const address = 'Jl. Prof. Dr. Satrio Nomor 164, Jakarta'
        return (
            <StyledLocationInfoContainer>
                <AntdRow align='middle'>
                    <AntdCol md={12} sm={24} xs={24}>
                        <AntdRow justify='end'>
                            <StyledLocationInfoDetail>
                                <StyledTitle $textAlign='end' color={colors.graniteGray} level={3}>
                                    <AntdSpace direction='vertical' size='large'>
                                        <AntdSpace direction='vertical'>
                                            <Fragment>Menara Standard Chartered Lt. 35</Fragment>
                                            <a
                                                href={`http://maps.google.com/?q=${address}`}
                                                rel='noreferrer'
                                                target='_blank'
                                            >
                                                {address}
                                            </a>
                                            <Fragment>12930</Fragment>
                                        </AntdSpace>
                                        <a
                                            href={`https://api.whatsapp.com/send/?phone=62123456789`}
                                            rel='noreferrer'
                                            target='_blank'
                                        >
                                            +62 123456789
                                        </a>
                                        <Fragment>support@moneypool.co.id</Fragment>
                                    </AntdSpace>
                                </StyledTitle>
                            </StyledLocationInfoDetail>
                        </AntdRow>
                    </AntdCol>
                    <AntdCol md={12} sm={0} xs={0}>
                        <StyledBuildingImage />
                    </AntdCol>
                </AntdRow>
            </StyledLocationInfoContainer>
        )
    }

    const renderTestimonialsCarousel = () => {
        const testimonials = Array(5).fill(testimonial)
        const content = map(testimonials, (item, key) => <TestimonialCard {...item} key={key} />)
        const slidesToScroll = 1
        const slidesToShow = isMobile() ? 1 : floor(divide(width - 200, 480))

        return (
            <Carousel
                autoplay
                autoplaySpeed={3000}
                content={content}
                dotsColor={colors.persianIndigo}
                dotsMarginBottom={-35}
                slidesToScroll={slidesToScroll}
                slidesToShow={slidesToShow}
                speed={1000}
            />
        )
    }

    const renderTestimonialAndPartner = () => {
        const partners = Array(isMobile() ? 2 : 3).fill(imagePath.xendit)
        return (
            <StyledSection>
                <center>
                    <StyledAntdSpace direction='vertical' size={60}>
                        {renderTestimonialsCarousel()}
                        <StyledAntdSpace direction='vertical' size='large'>
                            <StyledTitle $dark={dark} level={2}>
                                Partner Moneypool
                            </StyledTitle>
                            <AntdRow gutter={[0, 10]} justify='space-between'>
                                {map(partners, (item, key) => {
                                    const image = <StyledImage src={item} />
                                    return <AntdCol key={key}>{renderLazyLoadImage(image)}</AntdCol>
                                })}
                            </AntdRow>
                        </StyledAntdSpace>
                    </StyledAntdSpace>
                </center>
            </StyledSection>
        )
    }

    return (
        <Fragment>
            <HelmetMeta mainTitle='About' />
            {renderWhyInvest()}
            {renderOurStory()}
            {renderLocationInfo()}
            {renderTestimonialAndPartner()}
        </Fragment>
    )
}

export default About
