import PropTypes from 'prop-types'
import React from 'react'
import StyledAntdInputSearch from './StyledAntdInputSearch'

const InputSearch = (props) => {
    const { allowClear, dark, defaultValue, loading, name, onChange, onSearch, placeholder } = props

    return (
        <StyledAntdInputSearch
            $dark={dark}
            allowClear={allowClear}
            defaultValue={defaultValue}
            loading={loading}
            name={name}
            onChange={onChange}
            onSearch={onSearch}
            placeholder={placeholder}
        />
    )
}

InputSearch.propTypes = {
    allowClear: PropTypes.bool,
    dark: PropTypes.bool,
    defaultValue: PropTypes.string,
    loading: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    placeholder: PropTypes.string,
}

InputSearch.defaultProps = {
    allowClear: true,
}

export default InputSearch
