import React from 'react'
import { connect } from 'react-redux'
import * as productCategorySlice from '../../features/product_category/productCategorySlice'
import * as productRibbonSlice from '../../features/product_ribbon/productRibbonSlice'
import * as productTypeSlice from '../../features/product_type/productTypeSlice'
import ProductFilter from './ProductFilter'
import { reduxForm } from 'redux-form'

let ProductFilterContainer = (props) => <ProductFilter {...props} />

const mapStateToProps = (state, props) => ({
    initialValues: props.initialValues,
    isLoading:
        state.product.isLoading ||
        state.productCategory.isLoading ||
        state.productRibbon.isLoading ||
        state.productType.isLoading,
    productCategories: state.productCategory.productCategories,
    productRibbons: state.productRibbon.productRibbons,
    productTypes: state.productType.productTypes,
})

const mapDispatchToProps = {
    clearProductCategory: productCategorySlice.clearProductCategory,
    clearProductRibbon: productRibbonSlice.clearProductRibbon,
    clearProductType: productTypeSlice.clearProductType,
    getProductCategories: productCategorySlice.getProductCategories,
    getProductRibbons: productRibbonSlice.getProductRibbons,
    getProductTypes: productTypeSlice.getProductTypes,
}

ProductFilterContainer = reduxForm({
    enableReinitialize: true,
    form: 'productFilter',
})(ProductFilter)

export default connect(mapStateToProps, mapDispatchToProps)(ProductFilterContainer)
