import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/productDocument'
import * as productDocumentAPI from './productDocumentAPI'

const initialState = {
    dividendReports: [],
    financialReports: [],
    isError: false,
    isLoadingDividendReports: false,
    isLoadingFinancialReports: false,
    totalDividendReports: 0,
    totalFinancialReports: 0,
}

export const { getDividendReports, getFinancialReports } = productDocumentAPI

const productDocument = createSlice({
    name: 'PRODUCT_DOCUMENT',
    initialState,
    reducers: {
        clearProductDocument: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getDividendReports.pending, (state) =>
            Object.assign(state, {
                dividendReports: [],
                isError: false,
                isLoadingDividendReports: true,
            })
        )
        builder.addCase(getDividendReports.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                dividendReports: map(data.productDocuments, (item) => toState(item)),
                isLoadingDividendReports: false,
                totalDividendReports: data.count,
            })
        })
        builder.addCase(getDividendReports.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoadingDividendReports: false,
            })
        )
        builder.addCase(getFinancialReports.pending, (state) =>
            Object.assign(state, {
                financialReports: [],
                isError: false,
                isLoadingFinancialReports: true,
            })
        )
        builder.addCase(getFinancialReports.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                financialReports: map(data.productDocuments, (item) => toState(item)),
                isLoadingFinancialReports: false,
                totalFinancialReports: data.count,
            })
        })
        builder.addCase(getFinancialReports.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoadingFinancialReports: false,
            })
        )
    },
})

export const { clearProductDocument } = productDocument.actions

const { reducer } = productDocument
export default reducer
