import styled from 'styled-components'
import { Card } from '../../antdcomponents'

export const StyledCard = styled(Card)`
    &&& {
        background-color: transparent;
        border: none;
        height: 410px;

        div.ant-card-body {
            padding: 0px;
            width: 300px;
        }
    }
`

export const StyledImage = styled.img`
    height: 255px;
    width: 230px;

    @media screen and (min-width: 768px) {
        height: 330px;
        width: 300px;
    }
`
