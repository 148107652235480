import { default as AntdCol } from 'antd/lib/col'
import get from 'lodash/get'
import map from 'lodash/map'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import imagePath from '../../basic/imagePath'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { renderLink } from '../../utility/renderLinkComp'
import FavoriteModal from '../favorite_modal/FavoriteModal'
import { StyledNav, StyledRow } from './StyledComponents'

const Navbar = (props) => {
    const { isLoggedIn, isPenerbit, navigate } = props
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleCancel = () => setIsModalOpen(false)
    const handleClick = (e) => {
        e.preventDefault()
        setIsModalOpen(true)
    }

    const menus = [
        {
            src: imagePath.glowingHome,
            to: '/',
        },
        {
            src: imagePath.glowingAdd,
            to: '/products?order=terbaru',
        },
        {
            onClick: isLoggedIn ? noop : handleClick,
            src: isPenerbit ? imagePath.glowingFund : imagePath.glowingHeart,
            to: isPenerbit ? '/funds' : '/favorite',
        },
        {
            src: imagePath.glowingProfile,
            to: '/profile',
        },
    ]

    return (
        <StyledNav>
            <StyledRow align='bottom' justify='space-around' type='flex'>
                {map(menus, (item, key) => {
                    const onClick = get(item, 'onClick', noop)
                    const image = <img src={item.src} />
                    return (
                        <AntdCol key={key} span={2}>
                            {renderLink({ onClick: onClick, to: item.to }, renderLazyLoadImage(image))}
                        </AntdCol>
                    )
                })}
            </StyledRow>
            <FavoriteModal isModalOpen={isModalOpen} navigate={navigate} onCancel={handleCancel} />
        </StyledNav>
    )
}

Navbar.propTypes = {
    isLoggedIn: PropTypes.bool,
    isPenerbit: PropTypes.bool,
    navigate: PropTypes.func,
}

export default Navbar
