import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Button, Modal } from '../../antdcomponents'
import colors from '../../basic/colors'
import StyledTitle from '../../components/styled_title/StyledTitle'

const ChangeOrder = (props) => {
    const { cart, id, isModalOpen, onCancel, onOrder } = props
    const { navigate } = useOutletContext()
    const productId = get(cart, 'product.id', null)

    const footer = (
        <AntdRow>
            <AntdCol md={14} sm={24} xs={24}>
                <Button name='change-order-btn' onClick={onOrder} value='Ya, Lanjutkan' />
            </AntdCol>
        </AntdRow>
    )

    useEffect(() => {
        if (isModalOpen && (id === productId || !productId)) {
            if (!productId) {
                onOrder()
            } else {
                navigate('/order')
            }
            onCancel()
        }
    }, [id, isModalOpen, navigate, onCancel, onOrder, productId])

    return (
        <Modal footer={footer} onCancel={onCancel} visible={isModalOpen}>
            <AntdSpace direction='vertical'>
                <StyledTitle color={colors.graniteGray} level={2}>
                    Beralih Penawaran
                </StyledTitle>
                <StyledTitle color={colors.graniteGray} level={3}>
                    Tentu bisa, namun order penawaran sebelumnya pada keranjangmu akan terhapus
                </StyledTitle>
            </AntdSpace>
        </Modal>
    )
}

ChangeOrder.propTypes = {
    cart: PropTypes.object,
    id: PropTypes.number,
    isModalOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onOrder: PropTypes.func,
}

export default ChangeOrder
