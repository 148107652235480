import replace from 'lodash/replace'
import React from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import * as authenticationSlice from '../../features/authentication/authenticationSlice'
import * as pemodalSlice from '../../features/pemodal/pemodalSlice'
import * as penerbitSlice from '../../features/penerbit/penerbitSlice'
import * as verificationSlice from '../../features/verification/verificationSlice'
import { confirmPassword } from '../../utility/formValidation'
import ProfileForm from './ProfileForm'
import scrollToViewFormError from '../../utility/scrollToViewFormError'

let ProfileFormContainer = (props) => <ProfileForm {...props} />

const validate = (values) => {
    let errors = {}
    errors = Object.assign(errors, confirmPassword(values))
    return errors
}

const mapStateToProps = (state) => {
    const authToken = state.authentication.authToken
    let email
    let initialValues = { entityTypeId: 1 }
    let mobileNumber
    if (authToken) {
        const user = state.authentication.user
        email = state.authentication.email
        mobileNumber = state.authentication.mobileNumber
        const username = state.authentication.username
        initialValues = {
            email,
            mobileNumber,
            username,
        }
        if (user === 'penerbit') {
            const address = state.authentication.address
            const businessSectorId = state.authentication.businessSectorId
            const cityId = state.authentication.cityId
            const description = state.authentication.description
            const districtName = state.authentication.districtName
            const entityCode = state.authentication.entityCode
            const entityIdentificationNo = state.authentication.entityIdentificationNo
            const entityName = state.authentication.entityName
            const entityTypeId = state.authentication.entityTypeId
            const fundingAmountId = state.authentication.fundingAmountId
            const npwp = state.authentication.npwp
            const postalCode = state.authentication.postalCode
            const productTypeId = state.authentication.productTypeId
            const provinceName = state.authentication.provinceName
            const subdistrictName = state.authentication.subdistrictName
            let websiteUrl = state.authentication.websiteUrl
            websiteUrl = websiteUrl ? replace(websiteUrl, 'https://', '') : ''
            initialValues = Object.assign(initialValues, {
                address,
                businessSectorId,
                cityId,
                description,
                districtName,
                entityCode,
                entityIdentificationNo,
                entityTypeId,
                entityName,
                fundingAmountId,
                npwp,
                postalCode,
                productTypeId,
                provinceName,
                subdistrictName,
                websiteUrl,
            })
        }
    }
    return {
        authToken,
        currentRegisterStep: state.authentication.currentRegisterStep,
        districts: state.district.districts,
        email,
        id: state.authentication.id,
        initialValues,
        isActionLoadingSendVerification: state.verification.isActionLoadingSendVerification,
        isActionLoadingUser: state.pemodal.isActionLoading || state.penerbit.isActionLoading,
        isActionSuccessSendVerification: state.verification.isActionSuccessSendVerification,
        isActionSuccessUser: state.pemodal.isActionSuccess || state.penerbit.isActionSuccess,
        isEmailVerified: state.authentication.isEmailVerified,
        isLoading: state.authentication.isLoadingAuthenticate,
        isValidUser: state.authentication.isValidUser,
        mobileNumber,
    }
}

const mapDispatchToProps = {
    authenticate: authenticationSlice.authenticate,
    clearPemodal: pemodalSlice.clearPemodal,
    clearPenerbit: penerbitSlice.clearPenerbit,
    clearVerification: verificationSlice.clearVerification,
    createPemodal: pemodalSlice.createPemodal,
    createPenerbit: penerbitSlice.createPenerbit,
    refetchUser: authenticationSlice.refetchUser,
    sendVerificationEmail: verificationSlice.sendVerificationEmail,
    sendVerificationMobileNumber: verificationSlice.sendVerificationMobileNumber,
    updatePemodal: pemodalSlice.updatePemodal,
    updatePenerbit: penerbitSlice.updatePenerbit,
}

ProfileFormContainer = reduxForm({
    enableReinitialize: true,
    form: 'detail',
    onSubmitFail: (errors) => scrollToViewFormError(errors),
    validate,
})(ProfileFormContainer)

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFormContainer)
