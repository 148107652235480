import PropTypes from 'prop-types'
import React from 'react'
import colors from '../../basic/colors'
import StyledTitle from '../../components/styled_title/StyledTitle'
import {
    StyledButtonCol,
    StyledDecrementButtonContainer,
    StyledIncrementButtonContainer,
    StyledInputCol,
    StyledInputNumber,
    StyledInputSpinnerButon,
    StyledRow,
} from './StyledComponents'

const InputSpinner = (props) => {
    const { max, min, multiplier, name, onChange, step, value } = props

    const valueDecrementButton = (
        <StyledTitle color={colors.blueberry2} level={3}>
            -
        </StyledTitle>
    )

    const valueIncrementButton = (
        <StyledTitle color={colors.blueberry2} level={3}>
            +
        </StyledTitle>
    )

    const handleDecrement = () => {
        if (value <= min) {
            return
        }

        const constant = 1 * multiplier
        const newValue = value - constant
        if (newValue < min) {
            return
        }
        onChange(newValue, name)
    }

    const handleIncrement = () => {
        if (value >= max) {
            return
        }

        const constant = 1 * multiplier
        const newValue = value + constant
        if (newValue > max) {
            return
        }
        onChange(newValue, name)
    }

    return (
        <StyledRow>
            <StyledButtonCol>
                <StyledDecrementButtonContainer>
                    <StyledInputSpinnerButon
                        disabled={value <= multiplier}
                        name='decrement-btn'
                        onClick={handleDecrement}
                        type='text'
                        value={valueDecrementButton}
                    />
                </StyledDecrementButtonContainer>
            </StyledButtonCol>
            <StyledInputCol>
                <StyledInputNumber disabled max={max} min={min} name={name} step={step} type='number' value={value} />
            </StyledInputCol>
            <StyledButtonCol>
                <StyledIncrementButtonContainer>
                    <StyledInputSpinnerButon
                        disabled={value + multiplier > max}
                        name='increment-btn'
                        onClick={handleIncrement}
                        type='text'
                        value={valueIncrementButton}
                    />
                </StyledIncrementButtonContainer>
            </StyledButtonCol>
        </StyledRow>
    )
}

InputSpinner.propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    multiplier: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
    step: PropTypes.number,
    value: PropTypes.number,
}

InputSpinner.defaultProps = {
    min: 1,
    multiplier: 1,
}

export default InputSpinner
