import { default as AntdTypography } from 'antd/lib/typography'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../../basic/colors'
import modifyVars from '../../basic/modifyVars'

const getColor = (color, dark) => {
    if (dark) {
        return 'white'
    } else {
        if (color) {
            return color
        } else {
            return colors.persianIndigo
        }
    }
}

const AntdParagraph = AntdTypography.Paragraph
const StyledParagraph = styled(AntdParagraph)`
    &&& {
        font-style: ${modifyVars.paragraphStyle.fontStyle};
        font-weight: ${modifyVars.paragraphStyle.fontWeight};
        font-size: ${modifyVars.paragraphStyle.fontSizeMobile};
        font-family: ${modifyVars.paragraphStyle.fontFamily};
        letter-spacing: ${modifyVars.paragraphStyle.letterSpacing};
        color: ${(props) => getColor(props.color, props.$dark)};
        margin: 0px;
        text-align: ${(props) => props.$textAlign};

        @media screen and (min-width: 768px) {
            font-size: ${modifyVars.paragraphStyle.fontSizeDesktop};
        }
    }
`

StyledParagraph.propTypes = {
    $dark: PropTypes.bool,
    color: PropTypes.string,
}

StyledParagraph.defaultProps = {
    $dark: false,
}

export default StyledParagraph
