import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import styled, { css } from 'styled-components'
import { Button } from '../../antdcomponents'

const ButtonContainer = css`
    border-top: 2px solid silver;
    border-bottom: 2px solid silver;
    height: 30px;
    text-align: center;

    @media screen and (min-width: 768px) {
        height: 45px;
        padding-top: 3px;
    }
`

export const StyledButtonCol = styled(AntdCol)`
    &&& {
        width: 15%;
    }
`

export const StyledInputCol = styled(AntdCol)`
    &&& {
        width: 70%;
    }
`

export const StyledDecrementButtonContainer = styled.div`
    ${ButtonContainer}
    border-left: 2px solid silver;
    border-radius: 20px 0px 0px 20px;
`

export const StyledIncrementButtonContainer = styled.div`
    ${ButtonContainer}
    border-right: 2px solid silver;
    border-radius: 0px 20px 20px 0px;
`

export const StyledInputSpinnerButon = styled(Button)`
    &&& {
        height: 20px;
        margin: 0px;
    }
`

export const StyledInputNumber = styled.input`
    &&& {
        height: 30px;
        outline: none;
        border-top: 2px solid silver;
        border-bottom: 2px solid silver;
        border-left: none;
        border-right: none;
        text-align: center;
        font-size: 14px;
        max-width: 335px;
        width: 100%;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type='number'] {
            -moz-appearance: textfield;
        }

        @media screen and (min-width: 768px) {
            font-size: 20px;
            height: 45px;
            max-width: 630px;
        }
    }
`

export const StyledRow = styled(AntdRow)`
    &&& {
        width: 100%;
    }
`
