const listRisk = [
    {
        content:
            'Dalam melakukan aktifitas usaha/bisnis maka selalu ada risiko yang dihadapi, baik berupa kondisi yang terkait sisi permintaan (dari sisi pelanggan) maupun dari sisi Penerbit dalam melaksanakan usaha yang telah dituangkan dalam proposal bisnis.',
        title: 'Risiko Usaha',
    },
    {
        content:
            'Setiap jenis investasi memiliki tingkat risiko yang berbeda. Investasi dalam Securities Crowdfunding dapat memiliki risiko antara lain: target dana tidak terkumpul dari Pemodal sesuai dengan yang diharapkan pada masa pengumpulan dana. Bisa juga risiko berupa tingkat keberhasilan usaha yang menghasilkan keuntungan di bawah target yang diharapkan sehingga Penerbit tidak dapat membagikan dividen.',
        title: 'Risiko Investasi',
    },
    {
        content:
            'Investasi saham dalam lingkup Securities Crowdfunding tidak selikuid saham emiten yang ditawarkan di Bursa Efek Indonesia, karena saat ini kondisi pasar sekunder dalam lingkup Securities Crowdfunding belum seaktif perdagangan saham pada pasar sekunder dalam layanan Bursa Efek Indonesia',
        title: 'Risiko Likuiditas',
    },
    {
        content:
            'Risiko ini dihadapi Pemodal yaitu jika Penerbit tidak dapat membagikan dividen karena usaha yang dijalankan oleh Penerbit berjalan tidak sesuai yang diharapkan.',
        title: 'Risiko Kelangkaan Pembagian Dividen',
    },
    {
        content:
            'Pengertian dilusi kepemilikan saham yaitu penurunan persentase kepemilikan saham oleh Pemodal karena Penerbit menambah total jumlah saham yang beredar, dimana Pemodal yang bersangkutan tidak ikut menambah porsi kepemilikannya dengan membeli saham yang baru diterbitkan oleh Penerbit. Penerbit dapat menerbitkan saham baru sampai dengan batas maksimum sesuai ketentuan POJK yang mengatur Securities Crowdfunding.',
        title: 'Risiko Dilusi Kepemilikan Saham',
    },
    {
        content:
            'Sistem elektronik telah dirancang dan dilakukan uji coba oleh Penyelenggara sebelum digunakan untuk melayani Penerbit maupun Pemodal, namun sistem eletronik dapat juga mengalami kegagalan yang dapat berpengaruh pada kecepatan dan ketepatan layanan',
        noBreak: true,
        title: 'Risiko Kegagalan Sistem Elektronik',
    },
]

export default listRisk
