import React from 'react'
import { connect } from 'react-redux'
import * as cartSlice from '../features/cart/cartSlice'
import * as digisignSlice from '../features/digisign/digisignSlice'
import PurchaseOrder from './PurchaseOrder'

let PurchaseOrderContainer = (props) => <PurchaseOrder {...props} />

const mapStateToProps = (state) => ({
    cart: state.cart.cart,
    isActive: state.authentication.isActive,
    isDigisignActivated: state.authentication.isDigisignActivated,
    isErrorCart: state.cart.isError,
    isErrorDigisign: state.digisign.isError,
    isLoadingCart: state.cart.isLoading,
    isLoadingDigisign: state.digisign.isLoading,
})

const mapDispatchToProps = {
    clearCart: cartSlice.clearCart,
    clearDigisign: digisignSlice.clearDigisign,
    digisignCallback: digisignSlice.digisignCallback,
    getCart: cartSlice.getCart,
    updateCart: cartSlice.updateCart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderContainer)
