export const toApi = (data) => ({
    annual_salary_id: data.annualSalaryId,
    created_by: data.createdBy,
    id_card_name: data.idCardName,
    id_card_number: data.idCardNumber,
    income_source_id: data.incomeSourceId,
    investment_goal_id: data.investmentGoalId,
    mother_name: data.motherName,
    pemodal_id: data.pemodalId,
})

export const toState = (data) => ({
    annualSalaryId: data.annualSalaryId || null,
    idCardName: data.idCardName || '',
    idCardNumber: data.idCardNumber || '',
    incomeSourceId: data.incomeSourceId || null,
    investmentGoalId: data.investmentGoalId || null,
    motherName: data.motherName || '',
})
