import isEmpty from 'lodash/isEmpty'
import * as productTransformer from './product'
import moment from 'moment'
import { DATE_HOUR_SLASH_FORMAT } from '../constants/formats'

export const toApi = (data) => ({
    product_id: data.productId,
    quantity: data.quantity,
})

export const toState = (data) => {
    const approvedAt = moment(data.approvedAt).isValid() ? moment(data.approvedAt).format(DATE_HOUR_SLASH_FORMAT) : null

    return {
        approvedAt,
        id: data.id || null,
        pemodalId: data.pemodalId || null,
        product: !isEmpty(data.product) ? productTransformer.toState(data.product) : {},
        productId: data.productId || null,
        quantity: data.quantity || 0,
    }
}
