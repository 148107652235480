import { DatePicker as AntdDatePicker } from 'antd'
import styled from 'styled-components'
import colors from '../../basic/colors'

const StyledAntdDatePicker = styled(AntdDatePicker)`
    &&& {
        border-color: ${(props) => (props.$isError ? colors.rose : 'silver')};
        border-width: 2px;
        border-radius: 20px;
        height: 30px;
        width: 100%;
        padding: 0px 11px;

        &,
        &:hover {
            box-shadow: none;
            outline: none;
        }

        input {
            ${(props) =>
                !props.disabled &&
                `
                color: ${props.$dark ? 'white' : 'black'};
            `}
            border-radius: unset;
            box-shadow: none;
            font-size: 14px;
            padding: 4px 0px;
            outline: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        @media screen and (min-width: 768px) {
            height: 45px;
            input {
                font-size: 20px;
            }
        }
    }
`

export default StyledAntdDatePicker
