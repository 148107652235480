import React from 'react'
import PropTypes from 'prop-types'
import ProductCardContainer from '../product_card/ProductCardContainer'
import { StyledAntdList } from './StyledComponents'

const ProductList = (props) => {
    const { isLoading, products } = props

    const handleRenderItem = (item, key) => (
        <StyledAntdList.Item key={key}>
            <ProductCardContainer product={item} />
        </StyledAntdList.Item>
    )

    if (isLoading) {
        return null
    }

    return <StyledAntdList dataSource={products} grid={{ gutter: 10 }} renderItem={handleRenderItem} />
}

ProductList.propTypes = {
    isLoading: PropTypes.bool,
    products: PropTypes.array,
}

export default ProductList
