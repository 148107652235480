import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/faq'
import * as faqAPI from './faqAPI'

const initialState = {
    faqs: [],
    isError: false,
    isLoading: false,
    totalFaqs: 0,
}

export const { getFaqs } = faqAPI

const faqSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getFaqs.pending, (state) =>
            Object.assign(state, {
                faqs: [],
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(getFaqs.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                faqs: map(data.faqs, (item) => toState(item)),
                isLoading: false,
                totalFaqs: data.count,
            })
        })
        builder.addCase(getFaqs.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'FAQ',
    reducers: {
        clearFaq: () => initialState,
    },
})

export const { clearFaq } = faqSlice.actions

const { reducer } = faqSlice
export default reducer
