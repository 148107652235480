import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import { Button, Form, ReduxFormInput, ReduxFormSelect } from '../antdcomponents'
import colors from '../basic/colors'
import Loader from '../components/loader/Loader'
import { StyledAntdSpace } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import { numberRequired, required } from '../utility/formValidation'
import mapSelectOptions from '../utility/mapSelectOptions'
import * as notification from '../utility/notification'

const BankForm = (props) => {
    const {
        bankLists,
        clearBankList,
        createPemodalBankinfo,
        createPenerbitBankinfo,
        getBankLists,
        handleSubmit,
        isActionLoadingPemodalBankinfo,
        isActionLoadingPenerbitBankinfo,
        isActionSuccessPemodalBankinfo,
        isActionSuccessPenerbitBankinfo,
        isBankinfoExist,
        isKycStatusSuccessVerified,
        isLoadingPemodalBankinfo,
        isLoadingPenerbitBankinfo,
        pristine,
        setPageNumber,
        username,
    } = props
    const { dark, dispatch, isPemodal, isPenerbit, navigate } = useOutletContext()

    useEffect(() => {
        const fetchBankLists = async () => await getBankLists()
        fetchBankLists()
        return () => {
            dispatch(clearBankList())
        }
    }, [clearBankList, dispatch, getBankLists])

    useEffect(() => {
        if (
            (!isActionLoadingPemodalBankinfo && isActionSuccessPemodalBankinfo) ||
            (!isActionLoadingPenerbitBankinfo && isActionSuccessPenerbitBankinfo)
        ) {
            if (isBankinfoExist || !isKycStatusSuccessVerified) {
                notification.success('Data rekening bank berhasil diperbaharui')
                navigate('/profile')
            } else if (isKycStatusSuccessVerified) {
                setPageNumber(1)
            }
        }
    }, [
        dispatch,
        isActionLoadingPemodalBankinfo,
        isActionLoadingPenerbitBankinfo,
        isActionSuccessPemodalBankinfo,
        isActionSuccessPenerbitBankinfo,
        isBankinfoExist,
        isKycStatusSuccessVerified,
        navigate,
        setPageNumber,
    ])

    const handleSave = (values) => {
        const { bankListId, accountHolderName, accountNumber } = values
        const data = {
            accountNumber,
            bankListId,
            createdBy: username,
            accountHolderName,
        }
        if (isPemodal) {
            createPemodalBankinfo(data)
        }
        if (isPenerbit) {
            createPenerbitBankinfo(data)
        }
    }

    return isLoadingPemodalBankinfo || isLoadingPenerbitBankinfo ? (
        <Loader />
    ) : (
        <StyledAntdSpace direction='vertical'>
            {isPenerbit && (
                <StyledTitle color={colors.graniteGray} level={2}>
                    Rekening Bank
                </StyledTitle>
            )}
            <StyledTitle color={colors.graniteGray} level={5}>
                {!isBankinfoExist && (
                    <Fragment>
                        {isPemodal && 'Daftarkan rekening Anda yang akan menerima uang dividen'}
                        {isPenerbit && 'Daftarkan rekening Anda yang akan menerima transfer urun dana'}
                    </Fragment>
                )}
            </StyledTitle>
            <Form onFinish={handleSubmit(handleSave)}>
                <Field
                    component={ReduxFormInput}
                    dark={dark}
                    formItemProps={{ required: true }}
                    label='Nama Pemilik Rekening'
                    name='accountHolderName'
                    placeholder='Nama (harus sama dengan rekening bank)'
                    validate={[required]}
                />
                <Field
                    component={ReduxFormSelect}
                    componentProps={{
                        optionFilterProp: 'label',
                        options: mapSelectOptions(bankLists, 'name'),
                    }}
                    dark={dark}
                    formItemProps={{ required: true }}
                    label='Nama Bank'
                    name='bankListId'
                    placeholder='Pilih nama bank'
                    validate={[required]}
                />
                <Field
                    component={ReduxFormInput}
                    dark={dark}
                    formItemProps={{ required: true }}
                    label='Nomer Rekening'
                    name='accountNumber'
                    placeholder='Masukkan nomor rekening'
                    validate={[required, numberRequired]}
                />
                <br />
                <AntdRow>
                    <AntdCol offset={6} span={12}>
                        <Button
                            dark={dark}
                            disabled={pristine}
                            htmlType='submit'
                            loading={isActionLoadingPemodalBankinfo || isActionLoadingPenerbitBankinfo}
                            name='bank-info-btn'
                            value={isBankinfoExist ? 'Simpan' : 'Kirim'}
                        />
                    </AntdCol>
                </AntdRow>
            </Form>
        </StyledAntdSpace>
    )
}

BankForm.propTypes = {
    bankLists: PropTypes.array,
    clearBankList: PropTypes.func,
    createPemodalBankinfo: PropTypes.func,
    createPenerbitBankinfo: PropTypes.func,
    getBankLists: PropTypes.func,
    handleSubmit: PropTypes.func,
    isActionLoadingPemodalBankinfo: PropTypes.bool,
    isActionLoadingPenerbitBankinfo: PropTypes.bool,
    isActionSuccessPemodalBankinfo: PropTypes.bool,
    isActionSuccessPenerbitBankinfo: PropTypes.bool,
    isBankinfoExist: PropTypes.bool,
    isKycStatusSuccessVerified: PropTypes.bool,
    isLoadingPemodalBankinfo: PropTypes.bool,
    isLoadingPenerbitBankinfo: PropTypes.bool,
    pristine: PropTypes.bool,
    setPageNumber: PropTypes.func,
    username: PropTypes.string,
}

export default BankForm
