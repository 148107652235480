import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import cloneDeep from 'lodash/cloneDeep'
import map from 'lodash/map'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import { Button, Form, ReduxFormInput, ReduxFormInputGroup, ReduxFormInputPassword, Upload } from '../../antdcomponents'
import colors from '../../basic/colors'
import { VALID_IMAGE_EXTENSIONS } from '../../constants/formats'
import {
    maxLength,
    numberRequired,
    passwordMinimalCharacter,
    passwordRequiredCharacter,
    required,
    username,
    validEmail,
} from '../../utility/formValidation'
import { StyledVerification } from '../StyledComponents'
import { connect } from 'react-redux'
import includes from 'lodash/includes'

const maxLength15 = maxLength(15)

const PemodalForm = (props) => {
    const {
        change,
        handleSubmit,
        isActionLoadingUser,
        isEmailVerified,
        isKycStatusSuccessVerified,
        isLoading,
        isLoggedIn,
        isMobileNumberVerified,
        isUpdateAllowed,
        isValidUser,
        onFinish,
        onReverify = noop,
        pristine,
        profilePicture,
    } = props

    const { dark, isProfileDetail, passwordValidation, location } = useOutletContext()

    const isRegisterPage = includes(location.pathname, 'register')
    const isChangeEmailMobileAllowed = isValidUser && !isKycStatusSuccessVerified && isUpdateAllowed
    const isNeedReverifyEmail = !isRegisterPage && isChangeEmailMobileAllowed && !isEmailVerified
    const isNeedReverifyMobileNumber = !isRegisterPage && isChangeEmailMobileAllowed && !isMobileNumberVerified

    const handleRemove = () => {
        change('profilePicture', [])
    }

    const handleUpload = (options) => {
        const { fileList = [], onProgress = noop, onSuccess = noop } = options
        const newFileList = map(cloneDeep(fileList), (item) => {
            return Object.assign(item, {
                fieldName: 'profilePicture',
                onProgress,
                onSuccess,
            })
        })
        change('profilePicture', newFileList)
    }

    return (
        <Form onFinish={handleSubmit(onFinish)}>
            <br />
            {isLoggedIn && (
                <Upload
                    accept={VALID_IMAGE_EXTENSIONS}
                    fileList={profilePicture}
                    listType='picture-card'
                    name='profilePicture'
                    onRemove={handleRemove}
                    onUpload={handleUpload}
                    shape='round'
                />
            )}
            <Field
                component={ReduxFormInput}
                dark={dark}
                disabled={!isRegisterPage && !isNeedReverifyEmail}
                formItemProps={{
                    noMargin: isNeedReverifyEmail,
                    required: true,
                }}
                label='Email'
                name='email'
                placeholder='Masukkan Email'
                validate={[required, validEmail]}
            />
            {isNeedReverifyEmail && (
                <AntdRow justify='end'>
                    <StyledVerification color={colors.salmon} onClick={onReverify('email')}>
                        Klik untuk verifikasi
                    </StyledVerification>
                </AntdRow>
            )}
            <Field
                component={ReduxFormInput}
                componentProps={{ maxLength: 15 }}
                dark={dark}
                disabled={!isUpdateAllowed}
                formItemProps={{ required: true }}
                label='Username'
                name='username'
                placeholder='Masukkan Username'
                validate={[maxLength15, required, username]}
            />
            <Field
                component={ReduxFormInputGroup}
                dark={dark}
                disabled={!isRegisterPage && !isNeedReverifyMobileNumber}
                formItemProps={{
                    noMargin: isNeedReverifyMobileNumber,
                    required: true,
                }}
                label='Nomor Telepon Seluler'
                name='mobileNumber'
                placeholder='Masukkan Nomor Telepon Seluler'
                prefix='+62'
                validate={[required, numberRequired]}
            />
            {isNeedReverifyMobileNumber && (
                <AntdRow justify='end'>
                    <StyledVerification color={colors.salmon} onClick={onReverify('phone')}>
                        Klik untuk verifikasi
                    </StyledVerification>
                </AntdRow>
            )}
            <Field
                component={ReduxFormInputPassword}
                componentProps={{
                    isValidUser: isValidUser,
                }}
                dark={dark}
                disabled={!isUpdateAllowed}
                formItemProps={{
                    noMargin: true,
                    required: true,
                }}
                label='Kata Sandi'
                name='password'
                placeholder='Masukkan Kata Sandi'
                validate={[required, passwordRequiredCharacter, passwordMinimalCharacter]}
            />

            {!isProfileDetail && (
                <Field
                    component={ReduxFormInputPassword}
                    componentProps={{
                        isConfirmPassword: true,
                    }}
                    dark={dark}
                    formItemProps={{ required: true }}
                    label='Konfirmasi Kata Sandi'
                    name='confirmPassword'
                    placeholder='Masukkan Ulang Kata Sandi'
                    validate={[required]}
                />
            )}
            <br />
            <AntdRow>
                <AntdCol offset={6} span={12}>
                    <Button
                        dark={dark}
                        disabled={pristine}
                        htmlType='submit'
                        loading={isActionLoadingUser || isLoading}
                        name='register-btn'
                        value={passwordValidation ? 'Daftar' : 'Simpan'}
                    />
                </AntdCol>
            </AntdRow>
        </Form>
    )
}

const mapStateToProps = (state) => ({
    isDigisignActivated: state.authentication.isDigisignActivated,
    isKycStatusSuccessVerified: state.authentication.isKycStatusSuccessVerified,
    isKycStatusFailed: state.authentication.isKycStatusFailed,
})

PemodalForm.propTypes = {
    change: PropTypes.func,
    handleSubmit: PropTypes.func,
    isActionLoadingUser: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    isKycStatusSuccessVerified: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isMobileNumberVerified: PropTypes.bool,
    isUpdateAllowed: PropTypes.bool,
    isValidUser: PropTypes.bool,
    onFinish: PropTypes.func,
    onReverify: PropTypes.func,
    pristine: PropTypes.bool,
    profilePicture: PropTypes.array,
}

export default connect(mapStateToProps)(PemodalForm)
