import imagePath from '../basic/imagePath'

export const founders = [
    {
        imgSrc: imagePath.founder,
        name: 'Herlina',
        occupation: 'Founder',
    },
    {
        imgSrc: imagePath.founder,
        name: 'Herlina',
        occupation: 'Co-Founder',
    },
    {
        imgSrc: imagePath.founder,
        name: 'Herlina',
        occupation: 'CEO',
    },
]

export const testimonial = {
    imgSrc: imagePath.founder,
    name: 'Doremi',
    occupation: 'Public Figure',
    text: 'Memudahkan banget nih. Kalo g punya  latar belakang bisnis tapi pingin punya bisnis. moneypool ini deh solusi terbaiknya. Makasi Moneypool',
}

export const whyInvestReasons = [
    {
        imgSrc: imagePath.verified,
        text: 'Aman dan Terpercaya (terdaftar dan diawasi oleh OJK)',
    },
    {
        imgSrc: imagePath.shop,
        text: 'Pengembangan Usaha UMKM (Usaha Mikro Kecil dan Menengah)',
    },
    {
        imgSrc: imagePath.money,
        text: 'Raih Deviden dengan suku bunga sekitar 20% per tahun',
    },
]
