import { default as AntdCheckbox } from 'antd/lib/checkbox'
import styled from 'styled-components'
import colors from '../../basic/colors'

const AntdCheckboxGroup = AntdCheckbox.Group

export const StyledAntdCheckbox = styled(AntdCheckbox)`
    &&& {
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: white;
            border-color: ${colors.graniteGray};
        }
        .ant-checkbox-checked .ant-checkbox-inner::after {
            border: 3px solid ${colors.persianIndigo};
            border-top: 0px;
            border-left: 0px;
        }

        .ant-checkbox-checked::after {
            border: none;
        }

        input.ant-checkbox-input:hover {
            outline: none;
        }

        span.ant-checkbox-inner {
            border: 2px solid ${colors.persianIndigo};
            border-radius: 50%;
        }
    }
`

export const StyledAntdCheckboxGroup = styled(AntdCheckboxGroup)`
    width: 100%;
`
