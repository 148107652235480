export const menus = [
    {
        title: 'Pemodal',
        content: [
            {
                title: 'Semua Penawaran',
                to: '/products?order=terbaru',
            },
            {
                title: 'Penawaran Terlaris',
                to: '/products?status=terpopuler',
            },
            {
                title: 'Syarat & Ketentuan',
                to: '/terms-and-conditions/pemodal',
            },
        ],
    },
    {
        title: 'Penerbit',
        content: [
            {
                title: 'Cara Daftar',
                to: '/faq#penerbit',
            },
            {
                isCheckUserType: true,
                isPenerbitFeature: true,
                title: 'Registrasi Perusahaan',
                to: '/register/penerbit/step1',
            },
            {
                title: 'Syarat & Ketentuan',
                to: '/terms-and-conditions/penerbit',
            },
        ],
    },
    {
        title: 'Lain-Lain',
        content: [
            {
                title: 'Pernyataan Risiko',
                to: '/risk-statement',
            },
            {
                title: 'Kebijakan Privasi',
                to: '/privacy-policy',
            },
            {
                title: 'FAQ',
                to: '/faq',
            },
            {
                title: 'Tanya Moneypool',
                to: '/contact-us',
            },
        ],
    },
]

export const statements = [
    'OTORITAS JASA KEUANGAN TIDAK MEMBERIKAN PERNYATAAN MENYETUJUI ATAU TIDAK MENYETUJUI EFEK INI, TIDAK JUGA MENYATAKAN KEBENARAN ATAU KECUKUPAN INFORMASI DALAM LAYANAN URUN DANA INI. SETIAP PERNYATAAN YANG BERTENTANGAN DENGAN HAL TERSEBUT ADALAH PERBUATAN MELANGGAR HUKUM.',
    'INFORMASI DALAM LAYANAN URUN DANA INI PENTING DAN PERLU MENDAPAT PERHATIAN SEGERA. APABILA TERDAPAT KERAGUAN PADA TINDAKAN YANG AKAN DIAMBIL, SEBAIKNYA BERKONSULTASI DENGAN PENYELENGGARA.',
    'PENERBIT DAN PENYELENGGARA, BAIK SENDIRI-SENDIRI MAUPUN BERSAMA-SAMA, BERTANGGUNG JAWAB SEPENUHNYA ATAS KEBENARAN SEMUA INFORMASI YANG TERCANTUM DALAM LAYANAN URUN DANA INI',
]
