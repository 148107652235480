import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import replace from 'lodash/replace'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import trimStart from 'lodash/trimStart'
import moment from 'moment'
import { DATE_HOUR_SLASH_FORMAT } from '../constants/formats'
import * as cityTransformer from './city'
import * as districtTransformer from './district'
import * as entityTypeTransformer from './entityType'
import * as penerbitDetailTransformer from './penerbitDetail'
import * as penerbitDocumentTransformer from './penerbitDocument'

export const toApi = (data) => {
    const mobile_number = data.mobileNumber ? '+62' + trimStart(data.mobileNumber, '0') : undefined
    const website_url = data.websiteUrl ? 'https://' + data.websiteUrl : undefined

    const detail_updated_at = moment(data.detailUpdatedAt, DATE_HOUR_SLASH_FORMAT).isValid()
        ? moment.utc(data.detailUpdatedAt, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined
    const penerbit_documents = !isEmpty(data.penerbitDocuments)
        ? map(data.penerbitDocuments, (item) =>
              penerbitDocumentTransformer.toApi(
                  Object.assign(item, {
                      penerbitId: data.id || item.penerbitId,
                  })
              )
          )
        : []
    const terms_and_conditions_approval_date = moment(
        data.termsAndConditionsApprovalDate,
        DATE_HOUR_SLASH_FORMAT
    ).isValid()
        ? moment.utc(data.termsAndConditionsApprovalDate, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined
    const risk_statement_approval_date = moment(data.riskStatementApprovalDate, DATE_HOUR_SLASH_FORMAT).isValid()
        ? moment.utc(data.riskStatementApprovalDate, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined

    return {
        address: data.address,
        business_sector_id: data.businessSectorId,
        city_id: data.cityId,
        created_by: data.createdBy,
        description: data.description,
        detail_updated_at,
        district_id: data.districtId,
        email: data.email,
        entity_code: data.entityCode ? toLower(data.entityCode) : undefined,
        entity_identification_no: data.entityIdentificationNo,
        entity_name: data.entityName,
        entity_type_id: data.entityTypeId,
        funding_amount_id: data.fundingAmountId,
        id: data.id, // TODO: need to be removed and change to jwt
        is_active: data.isActive,
        is_risk_statement_approved: data.isRiskStatementApproved,
        is_terms_and_conditions_approved: data.isTermsAndConditionsApproved,
        is_uploading: data.isUploading,
        mobile_number,
        password: data.password,
        penerbit_documents,
        postal_code: data.postalCode,
        product_type_id: data.productTypeId,
        risk_statement_approval_date,
        terms_and_conditions_approval_date,
        updated_by: data.updatedBy,
        username: data.username,
        website_url,
    }
}

export const toState = (data) => {
    const detailUpdatedAt = moment(data.detailUpdatedAt).isValid()
        ? moment(data.detailUpdatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const isUpdateAllowed = moment(detailUpdatedAt, DATE_HOUR_SLASH_FORMAT).isValid()
        ? moment().diff(moment(detailUpdatedAt, DATE_HOUR_SLASH_FORMAT), 'days') >= 14
        : true
    const mobileNumber = data.mobileNumber ? replace(data.mobileNumber, '+62', '') : ''

    const penerbitDocuments = !isEmpty(data.penerbitDocuments)
        ? map(data.penerbitDocuments, (item) => penerbitDocumentTransformer.toState(item))
        : []
    const npwp = filter(penerbitDocuments, (item) => item.fieldName === 'npwp')

    const riskStatementApprovalDate = moment(data.riskStatementApprovalDate).isValid()
        ? moment(data.riskStatementApprovalDate).format(DATE_HOUR_SLASH_FORMAT)
        : null

    return {
        address: data.address || '',
        businessSectorId: data.businessSectorId || null,
        city: !isEmpty(data.city) ? cityTransformer.toState(data.city) : {},
        cityId: data.cityId || null,
        description: data.description || '',
        detailUpdatedAt,
        district: !isEmpty(data.district) ? districtTransformer.toState(data.district) : {},
        districtId: data.districtId || null,
        email: data.email || '',
        entityCode: data.entityCode ? toUpper(data.entityCode) : '',
        entityIdentificationNo: data.entityIdentificationNo || '',
        entityName: data.entityName || '',
        entityType: !isEmpty(data.entityType) ? entityTypeTransformer.toState(data.entityType) : {},
        entityTypeId: data.entityTypeId || null,
        fundingAmountId: data.fundingAmountId || null,
        id: data.id || null,
        isTermsAndConditionsApproved: data.isTermsAndConditionsApproved || false,
        isRiskStatementApproved: data.isRiskStatementApproved || false,
        isUpdateAllowed,
        kycUserCount: data.kycUserCount || 0,
        mobileNumber,
        npwp,
        penerbitDetail: !isEmpty(data.penerbitDetail) ? penerbitDetailTransformer.toState(data.penerbitDetail) : {},
        penerbitDocuments,
        postalCode: data.postalCode || '',
        productTypeId: data.productTypeId || null,
        profilePictureUrl: data.profilePictureUrl || '',
        riskStatementApprovalDate,
        username: data.username || '',
        websiteUrl: data.websiteUrl || '',
    }
}
