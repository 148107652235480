import get from 'lodash/get'
import includes from 'lodash/includes'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import Loader from '../components/loader/Loader'

const VerifyEmail = (props) => {
    const { clearVerification, isActionLoadingVerify, isActionSuccessVerify, isError, isValidUser, verifyEmail } = props
    const { dispatch, navigate, search } = useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const secret = get(query, 'secret', '')
    const value = get(query, 'value', '')
    const user = get(query, 'user', '')
    const isUserValid = includes(['pemodal', 'penerbit', 'penerbit-detail'], user)

    if ((!secret || !value) && !isUserValid) {
        navigate('/', { replace: true })
    }

    useEffect(() => {
        const verifyEmailMutation = async () => {
            let data = { user }
            if (secret) {
                data = Object.assign(data, { secret })
            }
            if (value) {
                data = Object.assign(data, { value })
            }

            return await verifyEmail(data)
        }
        verifyEmailMutation()

        // componentWillUnmount
        return () => {
            dispatch(clearVerification())
        }
    }, [clearVerification, dispatch, secret, user, value, verifyEmail])

    if (isActionSuccessVerify || isError) {
        if (user === 'penerbit-detail') {
            navigate('/profile/screening-detail')
        } else {
            if (isValidUser) {
                navigate('/profile/email')
            } else {
                navigate(`/register/${user}/step2`)
            }
        }
    }

    if (isActionLoadingVerify) {
        return <Loader />
    }

    return null
}

VerifyEmail.propTypes = {
    clearVerification: PropTypes.func,
    isActionLoadingVerify: PropTypes.bool,
    isActionSuccessVerify: PropTypes.bool,
    isError: PropTypes.bool,
    isValidUser: PropTypes.bool,
    verifyEmail: PropTypes.func,
}

export default VerifyEmail
