import { default as AntdLayout } from 'antd/lib/layout'
import styled, { createGlobalStyle } from 'styled-components'
import imagePath from './basic/imagePath'

export const GlobalStyle = createGlobalStyle`
    div.ant-tooltip-arrow {
        display: none;
    }
    div.ant-tooltip-inner{
        border-radius: 15px;
        padding: 10px;
        min-height: 20px;
        min-width: 100px;
    }
    div.ant-select-dropdown {
        border-radius: 0px 0px 20px 20px;
    }
    div.ant-select-item-option-content {
        height: auto;
        white-space: normal;
    }
    p {
        margin-bottom: 0px;
    }

    @font-face {
        font-family: "AR Techni";
        src: url("/fonts/AR Techni.ttf")
    }
`

export const StyledAntdLayout = styled(AntdLayout)`
    &&& {
        ${(props) =>
            props.$dark
                ? `
                    background-image: url(${imagePath.layoutBackground});
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                `
                : `background-color: white;`}
    }
`

export const StyledImage = styled.img`
    height: 50%;
    width: 50%;
`

export const StyledPortfolioEmptyContainer = styled.div`
    padding-top: 35px;
`
