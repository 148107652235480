import { default as AntdSpace } from 'antd/lib/space'
import { isNull, isUndefined } from 'lodash'
import first from 'lodash/first'
import get from 'lodash/get'
import toLower from 'lodash/toLower'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Fragment, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Empty, Tooltip } from '../antdcomponents'
import { isAuthorizedUser } from '../common/helper/authorization'
import { paymentMethods } from '../common/options/purchaseOrder'
import HelmetMeta from '../components/HelmetMeta'
import Loader from '../components/loader/Loader'
import { StyledSection } from '../components/StyledComponents'
import { transactionFees } from '../constants/xendit'
import { renderLinkButton } from '../utility/renderLinkComp'
import { getDetailPurchaseOrder } from './helper'
import ConfirmationContainer from './ConfirmationContainer'
import PurchaseOrderFormContainer from './PurchaseOrderFormContainer'

const PurchaseOrder = (props) => {
    const {
        cart,
        clearCart,
        clearDigisign,
        digisignCallback,
        getCart,
        isActive,
        isDigisignActivated,
        isErrorCart,
        isErrorDigisign,
        isLoadingCart,
        isLoadingDigisign,
    } = props
    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, pathname, search } = useOutletContext()

    const paymentMethod = toLower(first(paymentMethods))
    const [newPurchaseOrder, setNewPurchaseOrder] = useState({})
    const [pageNumber, setPageNumber] = useState(0)

    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const isSignSuccess = !isNull(cart.approvedAt) && !isUndefined(cart.approvedAt)

    useEffect(() => {
        const getDigisignResponse = async () => {
            await digisignCallback({ options: query })
            await getCart()
        }

        if (query.msg && isDigisignActivated) {
            if (isSignSuccess) {
                setPageNumber(1)
            } else {
                setPageNumber(0)
                if (!isLoadingDigisign && !isErrorDigisign) {
                    getDigisignResponse()
                }
            }
        }

        return () => {
            dispatch(clearDigisign())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.toString(), isSignSuccess])

    useEffect(() => {
        const product = get(cart, 'product', {})
        const quantity = get(cart, 'quantity', 1)
        const approvedAt = get(cart, 'approvedAt', null)

        const initialPurchaseOrder = getDetailPurchaseOrder({
            paymentMethod,
            product,
            quantity,
            approvedAt,
            transactionFee: transactionFees[paymentMethod].value,
        })
        setNewPurchaseOrder(initialPurchaseOrder)
    }, [cart, paymentMethod])

    useEffect(() => {
        const fetchCart = async () => await getCart()
        fetchCart()

        return () => {
            dispatch(clearCart())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearCart, dispatch, getCart])

    const tooltipAdminFee = <Tooltip title='Biaya dukungan manajemen dan perkembangan platform' />

    const tooltipTransactionFee = (
        <Tooltip title='Pelajari lebih lanjut tentang biaya transasi pada laman https://www.xendit.co/id/biaya/' />
    )

    const purchaseOrderFormContainer = (
        <PurchaseOrderFormContainer
            newPurchaseOrder={newPurchaseOrder}
            setNewPurchaseOrder={setNewPurchaseOrder}
            setPageNumber={setPageNumber}
            tooltipAdminFee={tooltipAdminFee}
            tooltipTransactionFee={tooltipTransactionFee}
        />
    )
    const confirmationContainer = (
        <ConfirmationContainer
            isSignSuccess={isSignSuccess}
            newPurchaseOrder={newPurchaseOrder}
            setPageNumber={setPageNumber}
            tooltipAdminFee={tooltipAdminFee}
            tooltipTransactionFee={tooltipTransactionFee}
        />
    )
    const pages = [purchaseOrderFormContainer, confirmationContainer]

    useEffect(() => {
        if (pageNumber) {
            window.scrollTo(0, 0)
        }
    }, [pageNumber])

    if (!isLoggedIn) {
        navigate('/login')
        return null
    }

    if (!isAuthorizedUser(isPemodal, isPenerbit, pathname) || (!isActive && isPemodal)) {
        navigate('/profile')
        return null
    }

    const metaTitle = 'Order'

    return (
        <Fragment>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            {isLoadingCart && <Loader />}
            {!isLoadingCart && (
                <StyledSection>
                    {isErrorCart && (
                        <center>
                            <AntdSpace direction='vertical' size='large'>
                                <Empty />
                                {renderLinkButton('/products', {
                                    dark,
                                    name: 'products-btn',
                                    value: 'Lihat Penawaran',
                                })}
                            </AntdSpace>
                        </center>
                    )}
                    {!isErrorCart && pages[pageNumber]}
                </StyledSection>
            )}
        </Fragment>
    )
}

PurchaseOrder.propTypes = {
    cart: PropTypes.object,
    clearCart: PropTypes.func,
    clearDigisign: PropTypes.func,
    digisignCallback: PropTypes.func,
    getCart: PropTypes.func,
    isActive: PropTypes.bool,
    isDigisignActivated: PropTypes.bool,
    isErrorCart: PropTypes.bool,
    isErrorDigisign: PropTypes.bool,
    isLoadingCart: PropTypes.bool,
    isLoadingDigisign: PropTypes.bool,
}

export default PurchaseOrder
