import React from 'react'
import PropTypes from 'prop-types'
import { Input as AntdInput } from 'antd'
import { StyledLeftAntdInput, StyledRightAntdInput } from './StyledComponents'

const AntdInputGroup = AntdInput.Group

const InputGroup = (props) => {
    const { disabled, input, name, onChange, placeholder, prefix, value } = props

    return (
        <AntdInputGroup compact>
            <StyledLeftAntdInput disabled name='prefix' value={prefix} />
            <StyledRightAntdInput
                {...input}
                disabled={disabled}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
            />
        </AntdInputGroup>
    )
}

InputGroup.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default InputGroup
