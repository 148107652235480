import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as inboxSlice from '../../features/inbox/inboxSlice'
import ChatModalContent from './ChatModalContent'

let ChatModalContentContainer = (props) => <ChatModalContent {...props} />

const mapStateToProps = (state, props) => ({
    id: state.inbox.id,
    initialValues: props.initialValues || {},
    isActionLoading: state.inbox.isActionLoading,
    user: state.authentication.user,
    username: state.authentication.username,
})

const mapDispatchToProps = {
    clearInbox: inboxSlice.clearInbox,
    createInbox: inboxSlice.createInbox,
}

ChatModalContentContainer = reduxForm({
    enableReinitialize: true,
    form: 'chatModalContent',
})(ChatModalContentContainer)

export default connect(mapStateToProps, mapDispatchToProps)(ChatModalContentContainer)
