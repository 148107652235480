import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/penerbitDetail'
import * as api from '../api'

export const createPenerbitDetail = createAsyncThunk('CREATE_PENERBIT_DETAIL', async (data, { rejectWithValue }) => {
    try {
        const res = await api.create('/penerbit-detail/create', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const deletePenerbitDetail = createAsyncThunk('DELETE_PENERBIT_DETAIL', async (id, { rejectWithValue }) => {
    try {
        const res = await api.deleteById('/penerbit-detail/delete', id)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getPenerbitDetail = createAsyncThunk('GET_PENERBIT_DETAIL', async (name, { rejectWithValue }) => {
    try {
        const res = await api.fetchByIdCardName('/penerbit-detail', name)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getPenerbitDetails = createAsyncThunk(
    'GET_PENERBIT_DETAILS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/penerbit-details', { limit, offset, options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const updatePenerbitDetail = createAsyncThunk('UPDATE_PENERBIT_DETAIL', async (data, { rejectWithValue }) => {
    try {
        const res = await api.update('/penerbit-detail/update', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})
