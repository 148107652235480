import React from 'react'
import { connect } from 'react-redux'
import Register from './Register'

let RegisterContainer = (props) => <Register {...props} />

const mapStateToProps = (state) => ({
    currentRegisterStep: state.authentication.currentRegisterStep,
    isValidUser: state.authentication.isValidUser,
    user: state.authentication.user,
})

export default connect(mapStateToProps)(RegisterContainer)
