import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/inboxDetail'
import * as api from '../api'

export const createInboxDetail = createAsyncThunk('CREATE_INBOX_DETAIL', async (data, { rejectWithValue }) => {
    try {
        const res = await api.create('/inbox-detail/create', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const readInboxDetails = createAsyncThunk('READ_INBOX_DETAILS', async (data, { rejectWithValue }) => {
    try {
        const res = await api.update('/inbox-details/read', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})
