import React from 'react'
import { connect } from 'react-redux'
import * as authenticationSlice from '../features/authentication/authenticationSlice'
import * as digisignSlice from '../features/digisign/digisignSlice'
import * as pemodalBankinfoSlice from '../features/pemodal_bankinfo/pemodalBankinfoSlice'
import * as pemodalSlice from '../features/pemodal/pemodalSlice'
import * as penerbitSlice from '../features/penerbit/penerbitSlice'
import * as penerbitBankinfoSlice from '../features/penerbit_bankinfo/penerbitBankinfoSlice'
import * as penerbitDetailSlice from '../features/penerbit_detail/penerbitDetailSlice'
import * as verificationSlice from '../features/verification/verificationSlice'
import EditProfile from './EditProfile'

const EditProfileContainer = (props) => <EditProfile {...props} />

const mapStateToProps = (state) => ({
    currentRegisterStep: state.authentication.currentRegisterStep,
    digisignActivationUrl: state.digisign.digisignActivationUrl,
    email: state.authentication.email,
    isActionLoadingDigisign: state.digisign.isActionLoading,
    isActionLoadingPemodal: state.pemodal.isActionLoading,
    isActionLoadingPemodalBankinfo: state.pemodalBankinfo.isActionLoading,
    isActionLoadingPenerbitBankinfo: state.penerbitBankinfo.isActionLoading,
    isActionSuccessDigisign: state.digisign.isActionSuccess,
    isActionSuccessPemodal: state.pemodal.isActionSuccess,
    isActionSuccessPemodalBankinfo: state.pemodalBankinfo.isActionSuccess,
    isActionSuccessPenerbitBankinfo: state.penerbitBankinfo.isActionSuccess,
    isBankinfoExist: state.authentication.isBankinfoExist,
    isDetailExist: state.authentication.isDetailExist,
    isDigisignActivated: state.authentication.isDigisignActivated,
    isEmailVerified: state.authentication.isEmailVerified,
    isNeedActivation: state.digisign.isNeedActivation,
    isKycStatusSuccessVerified: state.authentication.isKycStatusSuccessVerified,
    message: state.digisign.message,
    user: state.authentication.user,
})

const mapDispatchToProps = {
    clearDigisign: digisignSlice.clearDigisign,
    clearPemodal: pemodalSlice.clearPemodal,
    clearPemodalBankinfo: pemodalBankinfoSlice.clearPemodalBankinfo,
    clearPenerbit: penerbitSlice.clearPenerbit,
    clearPenerbitBankinfo: penerbitBankinfoSlice.clearPenerbitBankinfo,
    clearPenerbitDetail: penerbitDetailSlice.clearPenerbitDetail,
    clearVerification: verificationSlice.clearVerification,
    generateLinkActivation: digisignSlice.generateLinkActivation,
    getPemodalBankinfo: pemodalBankinfoSlice.getPemodalBankinfo,
    getPemodalProfile: pemodalSlice.getPemodalProfile,
    getPenerbit: penerbitSlice.getPenerbit,
    getPenerbitBankinfo: penerbitBankinfoSlice.getPenerbitBankinfo,
    getPenerbitDetail: penerbitDetailSlice.getPenerbitDetail,
    refetchUser: authenticationSlice.refetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileContainer)
