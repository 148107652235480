import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/pemodalBankinfo'
import * as api from '../api'

export const createPemodalBankinfo = createAsyncThunk(
    'CREATE_PEMODAL_BANKINFO',
    async (data) => await api.create('/pemodal-bankinfo/create', toApi(data))
)

export const getPemodalBankinfo = createAsyncThunk(
    'GET_PEMODAL_BANKINFO',
    async () => await api.fetch('/pemodal-bankinfo')
)
