import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/fundingAmount'
import * as fundingAmountAPI from './fundingAmountAPI'

const initialState = {
    fundingAmounts: [],
    isError: false,
    isLoading: false,
    totalFundingAmounts: 0,
}

export const { getFundingAmounts } = fundingAmountAPI

const fundingAmountSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getFundingAmounts.pending, (state) =>
            Object.assign(state, {
                fundingAmounts: [],
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(getFundingAmounts.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                fundingAmounts: map(data.fundingAmounts, (item) => toState(item)),
                isLoading: false,
                totalFundingAmounts: data.count,
            })
        })
        builder.addCase(getFundingAmounts.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'FUNDING_AMOUNT',
    reducers: {
        clearFundingAmount: () => initialState,
    },
})

export const { clearFundingAmount } = fundingAmountSlice.actions

const { reducer } = fundingAmountSlice
export default reducer
