import styled from 'styled-components'

export const StyledListContainer = styled.div`
    padding-left: 5px;
`

export const StyledScopeContainer = styled.div`
    padding-left: 25px;
`

export const StyledScopeTitle = styled.div`
    padding-left: 10px;
`
