import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'
import toLower from 'lodash/toLower'
import moment from 'moment'
import { DATETIME_SLASH_FULL_FORMAT, DATE_HOUR_SLASH_FORMAT } from '../constants/formats'
import { currentPlatformFee } from '../constants/purchaseOrder'
import formatPrice from '../utility/formatPrice'
import * as inboxTransformer from './inbox'
import * as pemodalTransformer from './pemodal'
import * as productTransformer from './product'
import * as purchaseOrderTransactionTransformer from './purchaseOrderTransaction'

export const toApi = (data) => {
    const approved_at = moment(data.approvedAt, DATE_HOUR_SLASH_FORMAT).isValid()
        ? moment.utc(data.approvedAt, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined

    return {
        admin_fee: data.adminFee,
        approved_at,
        id: data.id, // TODO:  need to be removed using only no_doc
        no_doc: data.noDoc,
        payment_method: data.paymentMethod,
        pemodal_id: data.pemodalId,
        product_id: data.productId,
        quantity: data.quantity,
        subtotal: data.subtotal,
        total_payment: data.totalPayment,
        transaction_fee: data.transactionFee,
        unit_price: data.unitPrice,
    }
}

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid() ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT) : null

    const inboxes = !isEmpty(data.inboxes) ? map(data.inboxes, (item) => inboxTransformer.toState(item)) : []
    const pemodal = !isEmpty(data.pemodal) ? pemodalTransformer.toState(data.pemodal) : {}
    const product = !isEmpty(data.product) ? productTransformer.toState(data.product) : {}
    const purchaseOrderTransactions = !isEmpty(data.purchaseOrderTransactions)
        ? map(data.purchaseOrderTransactions, (item) => purchaseOrderTransactionTransformer.toState(item))
        : []

    const lastDescription = get(last(purchaseOrderTransactions), 'description', '')

    const invoiceUrl = get(data, 'invoiceUrl', '')
    const status = get(data, 'status', '')

    const isCanceled = status === 'canceled'
    const isPaid = toLower(status) === 'paid'
    const isPending = toLower(status) === 'pending'

    const purchaseOrderTransactionPaid = find(
        purchaseOrderTransactions,
        (item) => toLower(item.description) === 'pembelian berhasil'
    )
    const purchaseOrderTransactionPaidDate = get(purchaseOrderTransactionPaid, 'createdAt', null)
    const difference = Math.abs(
        moment(purchaseOrderTransactionPaidDate, DATETIME_SLASH_FULL_FORMAT).diff(moment(), 'days')
    )

    const isAllowedToCancel = (isPaid && difference < 2) || isPending
    const isAllowedToPay = isPending && invoiceUrl
    const isOrderPaid = !isEmpty(purchaseOrderTransactionPaid)
    const isCanceledAfterPaid = isCanceled && isOrderPaid
    const isCanceledBeforePaid = isCanceled && !isOrderPaid

    const canceledPurchaseOrderTransaction = find(purchaseOrderTransactions, (item) => {
        const description = toLower(item.description)
        return description === 'pembelian dibatalkan' || description === 'pendanaan failed offer'
    })
    const canceledDate = get(canceledPurchaseOrderTransaction, 'createdAt', null)
    const approvedAt = moment(data.approvedAt).isValid() ? moment(data.approvedAt).format(DATE_HOUR_SLASH_FORMAT) : null

    const dataAdminFee = data.adminFee || 0
    let adminFee = !isCanceledBeforePaid ? dataAdminFee : 0
    let platformFee = isCanceled ? data.platformFee : currentPlatformFee
    const subtotal = data.subtotal || 0
    let totalPayment = data.totalPayment || 0
    let transactionFee = data.transactionFee || 0
    let unitPrice = data.unitPrice || 0
    let totalRefund = subtotal - platformFee

    adminFee = `Rp ${formatPrice(adminFee)}`
    platformFee = `Rp ${formatPrice(platformFee)}`
    totalPayment = `Rp ${formatPrice(totalPayment)}`
    totalRefund = `Rp ${formatPrice(totalRefund)}`
    transactionFee = `Rp ${formatPrice(transactionFee)}`
    unitPrice = `Rp ${formatPrice(unitPrice)}`

    return {
        adminFee,
        approvedAt,
        canceledDate,
        createdAt,
        id: data.id || null, // TODO:  need to be removed using only no_doc,
        inboxes,
        invoiceUrl,
        isAllowedToCancel,
        isAllowedToPay,
        isCanceled,
        isCanceledAfterPaid,
        isCanceledBeforePaid,
        isOrderPaid,
        lastDescription,
        noDoc: data.noDoc || '',
        paymentMethod: data.paymentMethod || '',
        pemodal,
        pemodalId: data.pemodalId || null,
        platformFee,
        product,
        productId: data.productId || null,
        purchaseOrderTransactions,
        quantity: data.quantity || 0,
        remarks: data.remarks || '',
        status,
        subtotal,
        totalPayment,
        totalRefund,
        transactionFee,
        unitPrice,
    }
}
