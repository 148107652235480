import styled from 'styled-components'

export const StyledAskQuestionContainer = styled.div`
    padding-top: 10px;
    text-align: center;
    @media screen and (min-width: 768px) {
        padding-top: 0px;
        text-align: left;
    }
`

export const StyledIframe = styled.iframe`
    aspect-ratio: 16 / 9;
    border-radius: 20px;
    height: auto;
    width: 100%;

    @media screen and (min-width: 768px) {
        border-radius: 50px;
    }
`

export const StyledProductCardContainer = styled.div`
    padding: 15px 2px ${(props) => (props.$isLastProductRow ? '2px' : '50px')} 2px;

    @media screen and (min-width: 768px) {
        padding-right: 10px;
    }
`

export const StyledProductCategoryHeader = styled.div`
    display: table;
    width: 100%;
`

export const StyledProductCategoryTitle = styled.div`
    display: table-cell;
    width: 70%;
`

export const StyledSeeAllProduct = styled.div`
    display: table-cell;
    text-align: end;
    width: 30%;
`
