import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import isMobile from 'is-mobile'
import concat from 'lodash/concat'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import join from 'lodash/join'
import kebabCase from 'lodash/kebabCase'
import lowerCase from 'lodash/lowerCase'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useOutletContext } from 'react-router'
import { BackButton, Breadcrumb, Button, Carousel, Empty } from '../antdcomponents'
import colors from '../basic/colors'
import gradientColors from '../basic/gradientColors'
import imagePath from '../basic/imagePath'
import BankInfoModal from '../components/bank_info_modal/BankInfoModal'
import FavoriteModal from '../components/favorite_modal/FavoriteModal'
import HelmetMeta from '../components/HelmetMeta'
import Loader from '../components/loader/Loader'
import { StyledAntdSpace, StyledCarouselImage, StyledChatImage, StyledSection } from '../components/StyledComponents'
import StyledParagraph from '../components/styled_paragraph/StyledParagraph'
import StyledPriceTitle from '../components/styled_price_title/StyledPriceTitle'
import StyledTitle from '../components/styled_title/StyledTitle'
import formatPrice from '../utility/formatPrice'
import * as notification from '../utility/notification'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'
import { renderLink, renderLinkButton } from '../utility/renderLinkComp'
import usePrevious from '../utility/usePrevious'
import { handleNameWebsiteUrl, handleWebsiteUrl } from './helper'
import ChangeOrderContainer from './modal/ChangeOrderContainer'
import {
    StyledAntdCol,
    StyledAntdProgress,
    StyledButton,
    StyledCarouselImageContainer,
    StyledCol,
    StyledImage,
    StyledRibbon,
    StyledRibbonContainer,
} from './StyledComponents'
import { getEncryptedId } from '../utility/getEncryptedDecryptedId'

const ProductDetail = (props) => {
    const {
        clearCart,
        clearProduct,
        createCart,
        createFavorite,
        deleteFavorite,
        favorites,
        getCart,
        getInboxes,
        getProduct,
        id,
        inboxes,
        isActionLoading,
        isActionSuccess,
        isActive,
        isBankinfoExist,
        isError,
        isLoadingCart,
        isLoadingProduct,
        product = {},
        totalPurchaseOrders,
    } = props
    const { dark, dispatch, handleNavigate, isLoggedIn, isPemodal, isPenerbit, navigate, params } = useOutletContext()
    const slug = get(params, 'slug', '')

    const {
        category,
        code,
        collected,
        collectedFundPercentage,
        couponRate,
        description,
        dividendPeriod,
        interestRate,
        isObligation,
        isProductStarted,
        isShown,
        name,
        numberShare,
        pricePerShare,
        productDocuments,
        productRibbon,
        prospectusUrl,
        remainingDate,
        settlementDate,
        shareSize,
        target,
        turnoverLastYear,
        type,
    } = product

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalType, setModalType] = useState('')
    const [isOrderingProduct, setIsOrderingProduct] = useState(false)
    const prevIsLoadingCart = usePrevious(isLoadingCart)
    const handleCancel = () => {
        setModalType('')
        setIsModalOpen(false)
    }

    const fetchProduct = () => getProduct(slug)
    //componentDidMount
    useEffect(() => {
        fetchProduct()

        return () => {
            dispatch(clearCart())
            dispatch(clearProduct())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isPemodal && !isEmpty(product)) {
            getInboxes({ limit: 1, options: { pemodalId: id, productId: product.id } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(product)])

    useEffect(() => {
        if (isError) {
            navigate('/products')
        }
    }, [isError, navigate])

    const initialBreadcrumbItem = useMemo(() => [{ label: 'Semua Penawaran', linkTo: '/products' }], [])
    const [breadcrumbItems, setBreadcrumbItems] = useState(initialBreadcrumbItem)
    useEffect(() => {
        if (!isEmpty(product)) {
            const categoryRoute = kebabCase(category)
            const additionalBreadcrumbItems = [
                {
                    label: category,
                    linkTo: `/products?category=${categoryRoute}`,
                },
                { label: code },
            ]
            setBreadcrumbItems(concat(initialBreadcrumbItem, additionalBreadcrumbItems))
        }
    }, [category, code, initialBreadcrumbItem, product])

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            navigate('/order')
        }
    }, [isActionLoading, isActionSuccess, navigate])

    useEffect(() => {
        if (!isLoadingCart && prevIsLoadingCart !== isLoadingCart && isOrderingProduct) {
            setIsModalOpen(true)
            setModalType('order')
        }
    }, [isLoadingCart, isOrderingProduct, prevIsLoadingCart])

    const penerbit = get(product, 'penerbit', {})
    const isFavorited = !isEmpty(find(favorites, (item) => item.productId === product.id))
    const chatImage = <StyledChatImage src={imagePath.chat} />
    const heartImage = <StyledImage src={isFavorited ? imagePath.heartFill : imagePath.heartEmpty} />
    const penerbitDescription = get(penerbit, 'description', '')
    const productRibbonTitle = get(productRibbon, 'title', '')
    const productRibbonColorCode = get(productRibbon, 'colorCode', '')
    const isPurchase = totalPurchaseOrders > 0
    const isNeedBankInfo = !isBankinfoExist && isPurchase

    const renderProductRibbon = (color, text, space) => (
        <StyledRibbonContainer>
            <StyledRibbon $space={space} color={color} text={text} />
        </StyledRibbonContainer>
    )
    const renderProductImageCarousel = () => {
        const productImages = get(product, 'productImages', [])
        const content = map(productImages, (item, key) => <StyledCarouselImage key={key} src={item.url} />)
        const slidesToScroll = 1
        const slidesToShow = 1

        return isEmpty(productImages) ? (
            <StyledAntdCol md={10} sm={24}>
                <Empty />
            </StyledAntdCol>
        ) : (
            <AntdCol md={10} sm={24}>
                <StyledCarouselImageContainer>
                    <Carousel
                        arrows
                        autoplaySpeed={4000}
                        content={content}
                        dotsColor={colors.persianIndigo}
                        dotsMarginBottom={-35}
                        slidesToScroll={slidesToScroll}
                        slidesToShow={slidesToShow}
                        speed={1000}
                    />
                    {!isEmpty(productRibbon) && renderProductRibbon(`#${productRibbonColorCode}`, productRibbonTitle)}
                    <br />
                    <br />
                </StyledCarouselImageContainer>
            </AntdCol>
        )
    }

    const handleClick = () => {
        if (isNeedBankInfo) {
            setIsModalOpen(true)
            setModalType('bank')
        } else {
            setIsOrderingProduct(true)
            getCart()
        }
    }

    const handleOrder = () => {
        const data = {
            productId: product.id,
            quantity: shareSize,
        }
        createCart(data)
    }

    const handleViewProspectus = () => window.open(prospectusUrl, '_blank')

    const renderButton = (
        <AntdRow gutter={[0, 20]}>
            {prospectusUrl && (
                <AntdCol span={11}>
                    <StyledButton dark={dark} name='prospectus-btn' onClick={handleViewProspectus} value='Prospektus' />
                </AntdCol>
            )}
            {!isEmpty(productDocuments) && (
                <AntdCol offset={prospectusUrl ? 2 : 0} span={11}>
                    <StyledButton
                        dark={dark}
                        name='fund-report-btn'
                        onClick={handleNavigate(`fund-report/${slug}`)}
                        value='Laporan Dana'
                    />
                </AntdCol>
            )}
            <AntdCol span={24}>
                {isLoggedIn
                    ? isNull(settlementDate) &&
                      isActive &&
                      isPemodal &&
                      isShown && (
                          <AntdCol span={24}>
                              <Button
                                  dark={dark}
                                  loading={isActionLoading}
                                  name='buy-btn'
                                  onClick={handleClick}
                                  value='Beli'
                              />
                          </AntdCol>
                      )
                    : renderLinkButton('/login', { dark, name: 'login-btn', value: 'Masuk/Daftar untuk Beli' })}
            </AntdCol>
        </AntdRow>
    )

    const ProductDetailSection = (title, value) => (
        <Fragment>
            <StyledTitle $dark={dark} color={colors.graniteGray} level={3}>
                {title}
            </StyledTitle>
            <StyledTitle $dark={dark} color={colors.blueberry2} level={4}>
                {value}
            </StyledTitle>
        </Fragment>
    )

    const toogleFavorite = () => {
        if (isLoggedIn) {
            if (isFavorited) {
                deleteFavorite(product.id)
                notification.success('Penawaran telah dihapus dari favorit')
            } else {
                createFavorite({ productId: product.id })
                notification.success('Penawaran telah ditambah ke favorit')
            }
        } else {
            setIsModalOpen(true)
            setModalType('favorite')
        }
    }

    const renderProductDetail = () => (
        <AntdSpace direction='vertical' size={isMobile() ? 'small' : 'large'}>
            <StyledAntdSpace direction='vertical'>
                <StyledTitle $dark={dark} color={colors.blueberry2} level={3}>
                    {renderLink({ to: `/products/?category=${lowerCase(category)}` }, category)}
                    &nbsp;|&nbsp;
                    {renderLink({ to: `/products/?type=${lowerCase(type)}` }, type)}
                </StyledTitle>
                <AntdRow align='middle' justify='space-between'>
                    <AntdCol span={20}>
                        <StyledTitle $dark={dark} level={2}>
                            {name}
                        </StyledTitle>
                    </AntdCol>
                    {!isPenerbit && (
                        <AntdCol span={4}>
                            <Button
                                dark={dark}
                                name='favorite-btn'
                                onClick={toogleFavorite}
                                type='text'
                                value={heartImage}
                            />
                        </AntdCol>
                    )}
                </AntdRow>
                <StyledTitle color={colors.graniteGray} level={4}>
                    {description}
                </StyledTitle>
            </StyledAntdSpace>
            <AntdRow align='bottom'>
                <AntdCol md={18} order={isMobile() ? 2 : 1} sm={24} xs={24}>
                    <StyledPriceTitle color={colors.blueberry2}>Rp {formatPrice(target)}</StyledPriceTitle>
                </AntdCol>
                <AntdCol md={6} order={isMobile() ? 1 : 2} sm={24} xs={24}>
                    <AntdRow justify={isMobile() ? 'left' : 'end'}>
                        <StyledParagraph $textAlign='end' color={colors.graniteGray}>
                            Total Pendanaan
                        </StyledParagraph>
                    </AntdRow>
                </AntdCol>
            </AntdRow>
            <AntdRow align='bottom' gutter={[0, 5]}>
                <AntdCol md={{ span: 14 }} order={isMobile() ? 2 : 1} sm={24} xs={24}>
                    <StyledPriceTitle $dark={dark}>
                        {`${collectedFundPercentage}% Rp ${formatPrice(collected)}`}
                    </StyledPriceTitle>
                    <StyledAntdProgress
                        percent={collectedFundPercentage}
                        showInfo={false}
                        strokeColor={gradientColors.progress}
                    />
                </AntdCol>
                <AntdCol md={{ offset: 1, span: 9 }} order={isMobile() ? 1 : 2} sm={24} xs={24}>
                    <AntdRow justify={isMobile() ? 'left' : 'end'}>
                        <StyledParagraph color={colors.graniteGray}>Dana Terkumpul</StyledParagraph>
                    </AntdRow>
                </AntdCol>
            </AntdRow>
            <AntdRow align='bottom'>
                <AntdCol md={12} order={isMobile() ? 2 : 1} sm={24} xs={24}>
                    <StyledPriceTitle color={colors.graniteGray}>{remainingDate}</StyledPriceTitle>
                </AntdCol>
                <AntdCol md={12} order={isMobile() ? 1 : 2} sm={24} xs={24}>
                    <AntdRow justify={isMobile() ? 'left' : 'end'}>
                        <StyledParagraph color={colors.graniteGray}>
                            {isProductStarted ? 'Sisa Hari' : 'Hari Lagi'}
                        </StyledParagraph>
                    </AntdRow>
                </AntdCol>
            </AntdRow>
            <AntdRow gutter={[0, 10]}>
                <AntdCol span={11}>
                    {ProductDetailSection('Harga per Lembar', `Rp. ${formatPrice(pricePerShare)}`)}
                </AntdCol>
                <AntdCol offset={2} span={11}>
                    {ProductDetailSection('Jumlah Lembar', numberShare)}
                </AntdCol>
                <AntdCol span={11}>{ProductDetailSection('Periode Dividen', dividendPeriod)}</AntdCol>
                <AntdCol offset={2} span={11}>
                    {ProductDetailSection(`Omzet tahun lalu`, `Rp. ${formatPrice(turnoverLastYear)}`)}
                </AntdCol>
                {isObligation && (
                    <Fragment>
                        <AntdCol span={11}>{ProductDetailSection(`Suku Bunga`, `${interestRate}%`)}</AntdCol>
                        <AntdCol offset={2} span={11}>
                            {ProductDetailSection(`Kupon Bunga`, `${couponRate}%`)}
                        </AntdCol>
                    </Fragment>
                )}
            </AntdRow>
            {collectedFundPercentage < 100 && renderButton}
        </AntdSpace>
    )

    const chatPenerbitButtonValue = (
        <AntdSpace>
            {renderLazyLoadImage(chatImage)}
            <StyledTitle $dark={!dark} level={4}>
                Chat Penerbit
            </StyledTitle>
        </AntdSpace>
    )

    const renderRowItem = (title, value) => (
        <Fragment>
            <AntdCol span={10}>
                <StyledParagraph color={colors.graniteGray}>{title}</StyledParagraph>
            </AntdCol>
            <AntdCol span={14}>
                <AntdSpace align='start'>
                    <Fragment>:</Fragment>
                    {value}
                </AntdSpace>
            </AntdCol>
        </Fragment>
    )

    const handleRedirectChat = () => {
        if (isEmpty(inboxes)) {
            let urlParams = []
            const penerbitId = get(product, 'penerbitId', 0)
            const encryptedPenerbitId = getEncryptedId(penerbitId)
            const productId = get(product, 'id', 0)
            const encryptedProductId = getEncryptedId(productId)
            urlParams = concat(urlParams, `penerbit=${encryptedPenerbitId}`)
            urlParams = concat(urlParams, `product=${encryptedProductId}`)
            urlParams = join(urlParams, '&')
            return `/inbox/${urlParams}`
        } else {
            const inboxId = get(inboxes, '0.id', 0)
            const encryptedInboxId = getEncryptedId(inboxId)
            return '/inbox/' + encryptedInboxId
        }
    }

    const renderPenerbitData = () => {
        const penerbit = get(product, 'penerbit', {})

        const address = get(penerbit, 'address', '')
        const email = get(penerbit, 'email', '')
        const mobileNumber = get(penerbit, 'mobileNumber', '')
        const websiteUrl = get(penerbit, 'websiteUrl', '')

        return (
            <AntdSpace direction='vertical' size={isMobile() ? 'small' : 36}>
                <StyledTitle $dark={dark} level={2}>
                    Data Penerbit
                </StyledTitle>
                <AntdRow>
                    {renderRowItem(
                        'Situs Resmi',
                        <a href={handleWebsiteUrl(websiteUrl)} rel='noreferrer' target='_blank'>
                            <StyledParagraph color={colors.graniteGray}>
                                {handleNameWebsiteUrl(websiteUrl)}
                            </StyledParagraph>
                        </a>
                    )}
                    {renderRowItem(
                        'Email Bisnis',
                        <a href={`mailto:${email}`}>
                            <StyledParagraph color={colors.graniteGray}>{email}</StyledParagraph>
                        </a>
                    )}
                    {renderRowItem(
                        'Nomor Telepon Seluler',
                        <a href={`tel:${mobileNumber}`}>
                            <StyledParagraph color={colors.graniteGray}>{mobileNumber}</StyledParagraph>
                        </a>
                    )}
                    {renderRowItem('Lokasi', <StyledParagraph color={colors.graniteGray}>{address}</StyledParagraph>)}
                </AntdRow>
                {!isMobile() && isPemodal && (
                    <AntdRow>
                        <AntdCol span={18}>
                            {renderLinkButton(handleRedirectChat(), {
                                dark,
                                name: 'chat-btn',
                                value: chatPenerbitButtonValue,
                            })}
                        </AntdCol>
                    </AntdRow>
                )}
            </AntdSpace>
        )
    }

    const renderDescription = () => (
        <StyledAntdSpace direction='vertical' size='large'>
            <StyledTitle $dark={dark} level={2}>
                Deskripsi
            </StyledTitle>
            <AntdRow>
                <StyledParagraph $textAlign='justify' color={colors.graniteGray}>
                    {penerbitDescription}
                </StyledParagraph>
            </AntdRow>
            {isMobile() && isPemodal && (
                <AntdRow>
                    <AntdCol span={24}>
                        {renderLinkButton(handleRedirectChat(), {
                            dark,
                            name: 'chat-btn',
                            type: 'primary',
                            value: chatPenerbitButtonValue,
                        })}
                    </AntdCol>
                </AntdRow>
            )}
        </StyledAntdSpace>
    )

    const renderProduct = () => (
        <AntdRow gutter={[20, 20]}>
            {renderProductImageCarousel()}
            <AntdCol md={{ offset: 1, span: 13 }} sm={24}>
                {renderProductDetail()}
            </AntdCol>
        </AntdRow>
    )

    const renderPenerbit = () => (
        <AntdRow gutter={[20, 20]}>
            <AntdCol lg={24} xl={10}>
                {renderPenerbitData()}
            </AntdCol>
            <StyledCol lg={24} xl={{ offset: 1, span: 13 }}>
                {renderDescription()}
            </StyledCol>
        </AntdRow>
    )

    if (isLoadingProduct) {
        return <Loader />
    }

    return (
        <Fragment>
            <HelmetMeta
                description={product.description || ''}
                mainTitle='Detail Produk'
                productTitle={product.title || ''}
            />
            <StyledSection>
                {!isError && (
                    <Fragment>
                        <StyledAntdSpace direction='vertical' size='large'>
                            {isMobile() ? (
                                <BackButton route='/products' value='Lihat Semua Penawaran' />
                            ) : (
                                <Breadcrumb items={breadcrumbItems} />
                            )}
                            {renderProduct()}
                            {renderPenerbit()}
                        </StyledAntdSpace>
                        <ChangeOrderContainer
                            id={product.id}
                            isModalOpen={isModalOpen && modalType === 'order'}
                            onCancel={handleCancel}
                            onOrder={handleOrder}
                        />
                        <FavoriteModal
                            isModalOpen={isModalOpen && modalType === 'favorite'}
                            navigate={navigate}
                            onCancel={handleCancel}
                        />
                        <BankInfoModal isModalOpen={isModalOpen && modalType === 'bank'} onCancel={handleCancel} />
                    </Fragment>
                )}
            </StyledSection>
        </Fragment>
    )
}

ProductDetail.propTypes = {
    clearCart: PropTypes.func,
    clearProduct: PropTypes.func,
    createCart: PropTypes.func,
    createFavorite: PropTypes.func,
    deleteFavorite: PropTypes.func,
    favorites: PropTypes.array,
    getCart: PropTypes.func,
    getInboxes: PropTypes.func,
    getProduct: PropTypes.func,
    id: PropTypes.number,
    inboxes: PropTypes.array,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isActive: PropTypes.bool,
    isBankinfoExist: PropTypes.bool,
    isError: PropTypes.bool,
    isLoadingCart: PropTypes.bool,
    isLoadingProduct: PropTypes.bool,
    product: PropTypes.object,
    totalPurchaseOrders: PropTypes.number,
}

export default ProductDetail
