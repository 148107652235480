import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import capitalize from 'lodash/capitalize'
import PropTypes from 'prop-types'
import React from 'react'
import { Button, Modal } from '../../antdcomponents'
import colors from '../../basic/colors'
import StyledTitle from '../../components/styled_title/StyledTitle'

const SwitchAccount = (props) => {
    const { isModalOpen, isPemodal, isPenerbit, navigate, onCancel, onLogout, to, user } = props

    const handleClick = () => {
        onLogout()
        onCancel()
        navigate(to)
    }

    const footer = (
        <AntdRow>
            <AntdCol md={14} sm={24} xs={24}>
                <Button
                    name='change-account-btn'
                    onClick={handleClick}
                    value={`Login Akun ${isPemodal ? 'Penerbit' : 'Pemodal'}`}
                />
            </AntdCol>
        </AntdRow>
    )

    return (
        <Modal footer={footer} onCancel={onCancel} visible={isModalOpen}>
            <AntdSpace direction='vertical'>
                <StyledTitle color={colors.graniteGray} level={2}>
                    Keluar Akun {capitalize(user)}
                </StyledTitle>
                <StyledTitle color={colors.graniteGray} level={3}>
                    Masuk sebagai&nbsp;
                    {isPenerbit && 'pemodal untuk beli saham/ obligasi perusahaan yang kamu minati.'}
                    {isPemodal && 'penerbit untuk membuka pendanaan usahamu.'}
                </StyledTitle>
            </AntdSpace>
        </Modal>
    )
}

SwitchAccount.propTypes = {
    isModalOpen: PropTypes.bool,
    isPemodal: PropTypes.bool,
    isPenerbit: PropTypes.bool,
    navigate: PropTypes.func,
    onCancel: PropTypes.func,
    onLogout: PropTypes.func,
    to: PropTypes.string,
    user: PropTypes.string,
}

export default SwitchAccount
