import { default as AntdCol } from 'antd/lib/col'
import { default as AntdDivider } from 'antd/lib/divider'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import isMobile from 'is-mobile'
import clone from 'lodash/clone'
import concat from 'lodash/concat'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import {
    BackButton,
    Breadcrumb,
    Button,
    Carousel,
    Empty,
    Form,
    ReduxFormInputSpinner,
    ReduxFormSelect,
} from '../antdcomponents'
import colors from '../basic/colors'
import imagePath from '../basic/imagePath'
import { paymentMethodOptions as purchaseOrderPaymentMethodOptions } from '../common/options/purchaseOrder'
import { StyledAntdSpace, StyledCarouselImage, StyledChatImage } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import formatPrice from '../utility/formatPrice'
import { greaterThanZero, required } from '../utility/formValidation'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'
import useSelectorFormValues from '../utility/useSelectorFormValues'
import { getDetailPurchaseOrder } from './helper'
import DataVerification from './modal/DataVerification'
import { StyledAntdCol } from './StyledComponents'
import { renderLink } from '../utility/renderLinkComp'
import { getEncryptedId } from '../utility/getEncryptedDecryptedId'

const PurchaseOrderForm = (props) => {
    const {
        cart,
        clearDigisign,
        digisignDocumentUrl,
        getInboxes,
        handleSubmit,
        id,
        inboxes,
        isActionLoading,
        isDigisignActivated,
        isKycStatusVerified,
        isKycStatusSuccess,
        setNewPurchaseOrder,
        setPageNumber,
        signDocument,
        tooltipAdminFee,
        tooltipTransactionFee,
        updateCart,
    } = props
    const { dark, dispatch, isPemodal } = useOutletContext()

    const initialBreadcrumbItem = useMemo(() => [{ label: 'Semua Penawaran', linkTo: '/products' }], [])
    const [breadcrumbItems, setBreadcrumbItems] = useState(initialBreadcrumbItem)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const formValues = useSelectorFormValues('purchaseOrder')
    const values = clone(formValues)
    const { adminFee, product, totalPayment, transactionFee, unitPrice } = getDetailPurchaseOrder(values)
    const totalFees = adminFee + transactionFee

    const category = get(product, 'category', '')
    const code = get(product, 'code', '')
    const outstandingNumberShare = get(product, 'outstandingNumberShare', 0)
    const name = get(product, 'name', '')
    const shareSize = get(product, 'shareSize', 1)
    const slug = get(product, 'slug', '')
    const type = get(product, 'type', '')

    const chatImage = <StyledChatImage src={imagePath.chat} />

    const valueChatPenerbitButton = (
        <AntdSpace align='center'>
            {renderLazyLoadImage(chatImage)}
            <StyledTitle $dark={!dark} level={4}>
                Chat Penerbit
            </StyledTitle>
        </AntdSpace>
    )

    const justify = isMobile() ? 'center' : 'left'

    useEffect(() => {
        if (isPemodal && !isEmpty(product)) {
            getInboxes({ limit: 1, options: { pemodalId: id, productId: product.id } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(product)])

    useEffect(() => {
        if (!isEmpty(product)) {
            const categoryRoute = kebabCase(category)
            const additionalBreadcrumbItems = [
                { label: category, linkTo: `/products?category=${categoryRoute}` },
                { label: code, linkTo: `/product/${slug}` },
                { label: 'Order' },
            ]
            setBreadcrumbItems(concat(initialBreadcrumbItem, additionalBreadcrumbItems))
        }
        if (!isEmpty(digisignDocumentUrl)) {
            Promise.resolve(window.open(digisignDocumentUrl, '_blank')).then(() => clearDigisign())
        }

        return () => {
            dispatch(clearDigisign())
        }
    }, [category, clearDigisign, code, digisignDocumentUrl, dispatch, initialBreadcrumbItem, product, slug])

    const handleCancel = () => setIsModalOpen(false)

    const handleChange = (e, value, previousValue, name) => {
        const newValues = Object.assign(values, { [`${name}`]: value })
        const newPurchaseOrder = getDetailPurchaseOrder(newValues)
        setNewPurchaseOrder(newPurchaseOrder)

        if (name === 'quantity') {
            const quantity = get(newPurchaseOrder, 'quantity', 1)
            updateCart({ quantity })
        }
    }

    const handleFinish = (values) => {
        if (isKycStatusVerified || isDigisignActivated) {
            const newPurchaseOrder = getDetailPurchaseOrder(clone(values))
            setNewPurchaseOrder(newPurchaseOrder)

            if (isKycStatusVerified || cart.approvedAt) {
                setPageNumber(1)
            } else {
                signDocument()
            }
        } else {
            setIsModalOpen(true)
        }
    }

    const handleRedirectChat = () => {
        if (isEmpty(inboxes)) {
            const productId = get(product, 'id', 0)
            const encryptedProductId = getEncryptedId(productId)
            const productPenerbitId = get(product, 'penerbitId', 0)
            const encryptedProductPenerbitId = getEncryptedId(productPenerbitId)
            let urlParams = []
            urlParams = concat(urlParams, `penerbit=${encryptedProductPenerbitId}`)
            urlParams = concat(urlParams, `product=${encryptedProductId}`)
            urlParams = join(urlParams, '&')
            return `/inbox/${urlParams}`
        } else {
            const inboxId = get(inboxes, '0.id', 0)
            const encryptedInboxId = getEncryptedId(inboxId)
            return '/inbox/' + encryptedInboxId
        }
    }

    const renderFormRowItem = (title, value, isFormField) => (
        <Fragment>
            <AntdCol span={12}>
                <StyledTitle color={colors.graniteGray} level={3}>
                    {title}
                </StyledTitle>
            </AntdCol>
            <AntdCol span={12}>
                {!isFormField ? (
                    <StyledTitle color='black' level={3}>
                        {value}
                    </StyledTitle>
                ) : (
                    value
                )}
            </AntdCol>
        </Fragment>
    )

    const renderProduct = () => (
        <Fragment>
            <StyledTitle $dark={dark} level={2}>
                {name}
            </StyledTitle>
            <br />
            <AntdRow align='middle' gutter={[0, 20]}>
                {renderFormRowItem('Lembar Tersedia', outstandingNumberShare)}
                {renderFormRowItem('Kategori', category)}
                {renderFormRowItem('Tipe', type)}
                {renderFormRowItem('Harga Per Satuan', `Rp ${formatPrice(unitPrice)}`)}
                {renderFormRowItem(
                    'Jumlah Lembar',
                    <Field
                        component={ReduxFormInputSpinner}
                        componentProps={{
                            max: outstandingNumberShare,
                            multiplier: shareSize,
                        }}
                        dark={dark}
                        formItemProps={{ isHideLabel: true, noMargin: true }}
                        name='quantity'
                        onChange={handleChange}
                        validate={[required, greaterThanZero]}
                    />,
                    true
                )}
                {renderFormRowItem(
                    'Metode Pembayaran',
                    <Field
                        component={ReduxFormSelect}
                        componentProps={{
                            options: purchaseOrderPaymentMethodOptions,
                        }}
                        dark={dark}
                        formItemProps={{ isHideLabel: true, noMargin: true }}
                        name='paymentMethod'
                        onChange={handleChange}
                        validate={[required]}
                    />,
                    true
                )}
                <AntdDivider />
                {renderFormRowItem(
                    <Fragment>
                        Biaya Admin &nbsp;
                        {tooltipAdminFee}
                    </Fragment>,
                    `Rp ${formatPrice(adminFee)}`
                )}
                {renderFormRowItem(
                    <Fragment>
                        Biaya Transaksi &nbsp;
                        {tooltipTransactionFee}
                    </Fragment>,
                    `Rp ${formatPrice(transactionFee)}`
                )}
                {renderFormRowItem('Total Biaya', `Rp ${formatPrice(totalFees)}`)}
                <AntdDivider />
                {renderFormRowItem('Total Pembayaran', `Rp ${formatPrice(totalPayment)}`)}
            </AntdRow>
            <br />
            <br />
            <AntdRow gutter={[20, 20]}>
                <AntdCol md={12} sm={24} xs={24}>
                    {renderLink(
                        { to: handleRedirectChat() },
                        <AntdRow justify={justify}>
                            <Button dark={dark} name='chat-btn' type='primary' value={valueChatPenerbitButton} />
                        </AntdRow>
                    )}
                </AntdCol>
                <AntdCol md={12} sm={24} xs={24}>
                    <AntdRow justify={justify}>
                        <Button htmlType='submit' loading={isActionLoading} name='buy-btn' value='Beli' />
                    </AntdRow>
                </AntdCol>
            </AntdRow>
        </Fragment>
    )

    const renderProductImages = () => {
        const productImages = get(product, 'productImages', [])
        const content = map(productImages, (item, key) => <StyledCarouselImage key={key} src={item.url} />)

        return productImages.length === 0 ? (
            <StyledAntdCol md={10} sm={24}>
                <Empty />
            </StyledAntdCol>
        ) : (
            <AntdCol md={10} sm={24}>
                <Carousel
                    arrows
                    autoplaySpeed={4000}
                    content={content}
                    dotsColor={colors.persianIndigo}
                    dotsMarginBottom={-35}
                    slidesToScroll={1}
                    slidesToShow={1}
                    speed={1000}
                />
            </AntdCol>
        )
    }

    return (
        <StyledAntdSpace direction='vertical' size='large'>
            {isMobile() ? (
                <BackButton route='/products' value='Lihat Semua Penawaran' />
            ) : (
                <Breadcrumb items={breadcrumbItems} />
            )}
            <Form onFinish={handleSubmit(handleFinish)}>
                <AntdRow gutter={[10, 20]}>
                    {renderProductImages()}
                    <AntdCol md={{ offset: 1, span: 13 }} sm={24}>
                        {renderProduct()}
                    </AntdCol>
                </AntdRow>
            </Form>
            <DataVerification isKycSuccess={isKycStatusSuccess} isModalOpen={isModalOpen} onCancel={handleCancel} />
        </StyledAntdSpace>
    )
}

PurchaseOrderForm.propTypes = {
    cart: PropTypes.object,
    clearDigisign: PropTypes.func,
    digisignDocumentUrl: PropTypes.string,
    getInboxes: PropTypes.func,
    handleSubmit: PropTypes.func,
    id: PropTypes.number,
    inboxes: PropTypes.array,
    isActionLoading: PropTypes.bool,
    isDigisignActivated: PropTypes.bool,
    isKycStatusSuccess: PropTypes.bool,
    isKycStatusVerified: PropTypes.bool,
    setNewPurchaseOrder: PropTypes.func,
    setPageNumber: PropTypes.func,
    signDocument: PropTypes.func,
    tooltipAdminFee: PropTypes.node,
    tooltipTransactionFee: PropTypes.node,
    updateCart: PropTypes.func,
}

export default PurchaseOrderForm
