import React from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import * as authenticationSlice from '../../features/authentication/authenticationSlice'
import * as pemodalSlice from '../../features/pemodal/pemodalSlice'
import * as penerbitSlice from '../../features/penerbit/penerbitSlice'
import TermsAndConditionsApproval from './TermsAndConditionsApproval'

let TermsAndConditionsApprovalContainer = (props) => <TermsAndConditionsApproval {...props} />

const mapStateToProps = (state) => ({
    currentRegisterStep: state.authentication.currentRegisterStep,
    email: state.authentication.email,
    id: state.authentication.id,
    isActionLoading: state.pemodal.isActionLoading || state.penerbit.isActionLoading,
    isActionSuccess: state.pemodal.isActionSuccess || state.penerbit.isActionSuccess,
    isLoading: state.authentication.isLoading,
    isValidUser: state.authentication.isValidUser,
    user: state.authentication.user,
})

const mapDispatchToProps = {
    clearPemodal: pemodalSlice.clearPemodal,
    clearPenerbit: penerbitSlice.clearPenerbit,
    refetchUser: authenticationSlice.refetchUser,
    updatePemodal: pemodalSlice.updatePemodal,
    updatePenerbit: penerbitSlice.updatePenerbit,
}

TermsAndConditionsApprovalContainer = reduxForm({
    enableReinitialize: true,
    form: 'registerTermsAndConditionsApproval',
})(TermsAndConditionsApprovalContainer)

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsApprovalContainer)
