import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import concat from 'lodash/concat'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Fragment, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { reset } from 'redux-form'
import { Button, InputSearch } from '../antdcomponents'
import imagePath from '../basic/imagePath'
import { getInitialFilter, getNewOptions, getProductFilterFormData } from '../common/helper/product'
import HelmetMeta from '../components/HelmetMeta'
import Loader from '../components/loader/Loader'
import ProductFilterContainer from '../components/product_filter/ProductFilterContainer'
import ProductFilterDrawer from '../components/product_filter_drawer/ProductFilterDrawer'
import ProductList from '../components/product_list/ProductList'
import { StyledAntdPagination, StyledAntdSpace, StyledSection } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import { DEFAULT_PAGINATION_CONFIG } from '../constants/pagination'
import { StyledCol } from './StyledComponents'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'

const Product = (props) => {
    const {
        clearProduct,
        getProducts,
        isLoading,
        productCategories,
        productRibbons,
        productTypes,
        products,
        totalProducts,
    } = props
    const { dark, dispatch, location, navigate, search } = useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })

    const limit = DEFAULT_PAGINATION_CONFIG.defaultPageSize
    const page = get(query, 'page', 1)

    const { defaultAllFilter, defaultFilterValue, initialOptions, initialValues, title } = getInitialFilter(
        productCategories,
        productRibbons,
        productTypes,
        query
    )
    const [options, setOptions] = useState(initialOptions)
    const [urlParams, setUrlParams] = useState([])

    const [toogleFetch, setToogleFetch] = useState(true)
    const filterImageValue = <img src={imagePath.filterDark} />
    const [isOpenFilter, setIsOpenFilter] = useState(false)
    const handleOpenFilter = () => setIsOpenFilter((prevState) => !prevState)

    useEffect(() => {
        if (!isMobile()) {
            setIsOpenFilter(false)
        }
    }, [setIsOpenFilter])

    useEffect(() => {
        setOptions(initialOptions)
        setToogleFetch(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        if (toogleFetch) {
            const offset = (page - 1) * limit
            let newOptions = getNewOptions(options, defaultAllFilter)
            newOptions = Object.assign(newOptions, {
                title,
            })
            getProducts({ limit, offset, options: newOptions })
            setToogleFetch(false)
        }
    }, [defaultAllFilter, getProducts, limit, options, page, title, toogleFetch])

    useEffect(() => {
        const clearDatas = () => {
            dispatch(clearProduct())
        }
        window.addEventListener('beforeunload', clearDatas)
        return () => {
            window.removeEventListener('beforeunload', clearDatas)
            clearDatas()
        }
    }, [clearProduct, dispatch])

    const handleChange = (page) => {
        setToogleFetch(true)
        const newUrlParams = join(urlParams, '&')
        if (page === 1) {
            navigate(`/products?${newUrlParams}`)
        } else {
            navigate(`/products?${newUrlParams}${!isEmpty(newUrlParams) ? '&' : ''}page=${page}`)
        }
    }

    const handleFinish = (values) => {
        let { newOptions, urlParams } = getProductFilterFormData(values, defaultAllFilter)
        if (title !== '') {
            urlParams = concat(urlParams, `title=${title}`)
        }
        setOptions((prevState) => Object.assign(prevState, newOptions))
        setUrlParams(urlParams)
        setIsOpenFilter(false)
        urlParams = join(urlParams, '&')
        navigate(`/products?${urlParams}`)
    }

    const handleReset = () => {
        dispatch(reset('product'))
        let urlParams = `/products`
        if (title !== '') {
            urlParams += `?title=${title}`
        }
        setOptions(defaultFilterValue)
        setIsOpenFilter(false)
        setToogleFetch(true)
        navigate(urlParams)
    }

    const handleSearch = (value) => {
        dispatch(reset('product'))
        if (value !== '') {
            let urlParams = `title=${value}`
            setUrlParams([urlParams])
            navigate(`/products?${urlParams}`)
        } else {
            navigate(`/products`)
        }
    }

    return (
        <Fragment>
            <HelmetMeta
                description={
                    'Pendanaan pada platform moneypool terbuka untuk berbagai ' +
                    'kategori usaha dalam bentuk saham maupun surat hutang/ obligasi. ' +
                    'Bergabung dengan moneypool sekarang untuk melakuakan pendanaan ' +
                    'maupun permodalan dengan dividen menjanjikan tiap periodenya.'
                }
                mainTitle='Semua Penawaran'
            />
            <ProductFilterDrawer
                initialValues={initialValues}
                isOpen={isOpenFilter}
                onClose={handleOpenFilter}
                onFinish={handleFinish}
                onReset={handleReset}
            />
            <StyledSection>
                <StyledAntdSpace direction='vertical' size='large'>
                    <AntdRow align='middle' gutter={[0, 10]} justify={isMobile() ? 'center' : 'end'}>
                        <AntdCol md={16} sm={24} xs={24}>
                            <AntdRow justify={isMobile() ? 'center' : 'end'}>
                                <StyledTitle $dark={dark} level={2}>
                                    Semua Penawaran
                                </StyledTitle>
                            </AntdRow>
                        </AntdCol>
                        <AntdCol md={0} sm={2} xs={2}>
                            <center>
                                <Button
                                    dark={dark}
                                    name='open-filter-btn'
                                    onClick={handleOpenFilter}
                                    type='text'
                                    value={renderLazyLoadImage(filterImageValue)}
                                />
                            </center>
                        </AntdCol>
                        <AntdCol md={{ offset: 2, span: 6 }} sm={{ offset: 1, span: 21 }} xs={{ offset: 1, span: 21 }}>
                            <AntdRow justify='end'>
                                <InputSearch
                                    dark={dark}
                                    defaultValue={title}
                                    loading={isLoading}
                                    name='product-name'
                                    onSearch={handleSearch}
                                    placeholder='Cari'
                                />
                            </AntdRow>
                        </AntdCol>
                    </AntdRow>
                    <AntdRow>
                        <AntdCol md={5} sm={0} xs={0}>
                            <ProductFilterContainer
                                dark={dark}
                                initialValues={initialValues}
                                onFinish={handleFinish}
                                onReset={handleReset}
                            />
                        </AntdCol>
                        <StyledCol md={{ offset: 1, span: 18 }} sm={24}>
                            <StyledAntdSpace direction='vertical'>
                                {isLoading ? <Loader /> : <ProductList isLoading={isLoading} products={products} />}
                                {!isEmpty(products) && (
                                    <AntdRow justify='end'>
                                        <StyledAntdPagination
                                            $dark={dark}
                                            current={Number(page)}
                                            defaultPageSize={limit}
                                            onChange={handleChange}
                                            total={totalProducts}
                                        />
                                    </AntdRow>
                                )}
                            </StyledAntdSpace>
                        </StyledCol>
                    </AntdRow>
                </StyledAntdSpace>
            </StyledSection>
        </Fragment>
    )
}

Product.propTypes = {
    clearProduct: PropTypes.func,
    getProducts: PropTypes.func,
    isLoading: PropTypes.bool,
    productCategories: PropTypes.array,
    productRibbons: PropTypes.array,
    productTypes: PropTypes.array,
    products: PropTypes.array,
    totalProducts: PropTypes.number,
}

export default Product
