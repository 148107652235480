import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../api'

export const getProductCategories = createAsyncThunk(
    'GET_PRODUCT_CATEGORIES',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/product-categories', { limit, offset, options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
