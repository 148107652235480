import capitalize from 'lodash/capitalize'
import map from 'lodash/map'

const subjects = [
    'akun bank',
    'akun moneypool.co.id',
    'dividen',
    'pemodal',
    'penerbit',
    'penyelenggara',
    'portfolio',
    'refund',
    'registrasi pemodal',
    'registrasi perusahaan',
    'screening',
    'secondary market',
    'transaksi',
    'lainnya',
]

export const subjectsOptions = map(subjects, (item) => ({
    label: capitalize(item),
    value: item,
}))
