import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { StaticRouter } from 'react-router-dom/server'

import configureStore from './configureStore'
import RouteHandler from './RouteHandler'

import './App.less'

const App = (props) => {
    const { persistor, store } = configureStore()
    return (
        <React.StrictMode>
            <Provider store={props.store ? props.store : store}>
                {props.location ? (
                    <StaticRouter location={props.location}>
                        <RouteHandler />
                    </StaticRouter>
                ) : (
                    <PersistGate loading={null} persistor={persistor}>
                        <BrowserRouter>
                            <RouteHandler />
                        </BrowserRouter>
                    </PersistGate>
                )}
            </Provider>
        </React.StrictMode>
    )
}

App.propTypes = {
    location: PropTypes.string,
    store: PropTypes.object,
}

export default App
