import React from 'react'
import PropTypes from 'prop-types'
import StyledAntdInput from './StyledComponents'
import replace from 'lodash/replace'

const Input = (props) => {
    const {
        dark,
        disabled,
        numberOnly,
        input,
        maxLength,
        name,
        onChange,
        placeholder,
        prefix,
        size,
        suffix,
        type,
        value,
    } = props

    const handleChange = (e) => onChange(e.target.value, name, e)
    const formattedValue = () => {
        if (!numberOnly) {
            return value
        }

        const numberOnlyRegex = /^\d+$/
        if (numberOnlyRegex.test(value)) {
            return value
        }
        const otherthanDigitRegex = /[a-zA-Z_.-]+/
        const newValue = replace(value, otherthanDigitRegex, '')
        return newValue
    }

    return (
        <StyledAntdInput
            {...input}
            $dark={dark}
            disabled={disabled}
            maxLength={maxLength}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            prefix={prefix}
            size={size}
            suffix={suffix}
            type={type}
            value={formattedValue()}
        />
    )
}

Input.propTypes = {
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    input: PropTypes.object.isRequired,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    numberOnly: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.string,
    suffix: PropTypes.node,
    type: PropTypes.string,
    value: PropTypes.string,
}

export default Input
