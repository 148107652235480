import filter from 'lodash/filter'
import includes from 'lodash/includes'
import upperCase from 'lodash/upperCase'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { InputSearch } from '../../antdcomponents'
import { StyledAntdAutoComplete } from './StyledComponents'

const SearchBar = (props) => {
    const { onSearch, onSelect, options = [], placeholder } = props
    const [searchValue, setSearchValue] = useState('')
    const onChange = (e) => setSearchValue(e.target.value)
    const onPressEnter = (e) => onSearch(e.target.value)

    const newOptions = filter(options, (item) => includes(upperCase(item.label), upperCase(searchValue)))

    return (
        <StyledAntdAutoComplete onSelect={onSelect} options={newOptions}>
            <InputSearch
                onChange={onChange}
                onPressEnter={onPressEnter}
                onSearch={onSearch}
                placeholder={placeholder}
            />
        </StyledAntdAutoComplete>
    )
}

SearchBar.propTypes = {
    onSearch: PropTypes.func,
    onSelect: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ).isRequired,
    placeholder: PropTypes.string,
}

export default SearchBar
