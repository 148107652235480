import { default as AntdCard } from 'antd/lib/card'
import PropTypes from 'prop-types'
import React from 'react'

const Card = (props) => {
    const { children, className, title, extra } = props

    return (
        <AntdCard className={className} extra={extra} title={title}>
            {children}
        </AntdCard>
    )
}

Card.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    extra: PropTypes.any,
    title: PropTypes.string,
}

export default Card
