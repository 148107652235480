import find from 'lodash/find'
import get from 'lodash/get'

const getLabelOption = (value, options) => {
    const option = find(options, (item) => item.value === value)
    const label = get(option, 'label', '')
    return label
}

export default getLabelOption
