const imagePath = {
    about: '/images/about.png',
    building: '/images/building.png',
    cartDark: '/images/cart_dark.png',
    cartLight: '/images/cart_light.png',
    chat: '/images/chat.png',
    customUnderline: '/images/custom_underline.png',
    drawerBurgerMenu: '/images/drawer_burger_menu.png',
    drawerClose: '/images/drawer_close.png',
    filterDark: '/images/filter_dark.png',
    filterLight: '/images/filter_light.png',
    footerBackgroundDesktop: '/images/footer_background_desktop.png',
    footerBackgroundMobile: '/images/footer_background_mobile.png',
    founder: '/images/founder.png',
    glowingAdd: '/images/glowing_add.png',
    glowingFund: '/images/glowing_fund.png',
    glowingHeart: '/images/glowing_heart.png',
    glowingHome: '/images/glowing_home.png',
    glowingProfile: '/images/glowing_profile.png',
    headerBurgerMenuDark: '/images/header_burger_menu_dark.png',
    headerBurgerMenuLight: '/images/header_burger_menu_light.png',
    heartEmpty: '/images/heart_empty.png',
    heartFill: '/images/heart_fill.png',
    history: '/images/history.png',
    iso: '/images/iso.png',
    isoSample: '/images/iso_sample.png',
    kominfo: '/images/kominfo.png',
    kominfoSample: '/images/kominfo_sample.png',
    layoutBackground: '/images/layout_background.png',
    loader: '/images/loader.gif',
    money: '/images/money.png',
    moneypoolDark: '/images/moneypool_dark.png',
    moneypoolDarkSample: '/images/moneypool_dark_sample.png',
    moneypoolLight: '/images/moneypool_light.png',
    moneypoolLightSample: '/images/moneypool_light_sample.png',
    moneypoolLogoDark: '/images/moneypool_logo_dark.png',
    noDataGray: '/images/no_data_gray.png',
    noMatchBackground: '/images/no_match_background.png',
    ojk: '/images/ojk.png',
    ojkSample: '/images/ojk_sample.png',
    portfolio: '/images/portfolio.png',
    profileDark: '/images/profile_dark.png',
    profileLight: '/images/profile_light.png',
    profit: '/images/profit.png',
    pump: '/images/pump.png',
    purchaseOrderFailed: '/images/purchase_order_failed.png',
    purchaseOrderSuccess: '/images/purchase_order_success.png',
    quote: '/images/quote.png',
    rectangleDesktop: '/images/rectangle_desktop.png',
    rectangleMobile: '/images/rectangle_mobile.png',
    sampleOnly: '/images/sample_only.png',
    secondaryMarket: '/images/secondary_market.png',
    sectionBackground: '/images/section_background.png',
    seeMore: '/images/see_more.png',
    shop: '/images/shop.png',
    verified: '/images/verified.png',
    viewMore: '/images/view_more.png',
    xendit: '/images/xendit.png',
}

export default imagePath
