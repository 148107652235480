import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Button, Modal } from '../../../antdcomponents'
import colors from '../../../basic/colors'
import StyledTitle from '../../../components/styled_title/StyledTitle'

const DeletePenerbitDetail = (props) => {
    const { deletePenerbitDetail, id, isActionLoading, isActionSuccess, isModalOpen, onCancel } = props

    const handleClick = () => deletePenerbitDetail(id)

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            onCancel()
        }
    }, [isActionLoading, isActionSuccess, onCancel])

    const footer = (
        <AntdRow>
            <AntdCol span={11}>
                <Button loading={isActionLoading} name='delete-btn' onClick={handleClick} value='Hapus' />
            </AntdCol>
            <AntdCol offset={2} span={11}>
                <Button loading={isActionLoading} name='cancel-btn' onClick={onCancel} value='Batal' />
            </AntdCol>
        </AntdRow>
    )

    return (
        <Modal footer={footer} onCancel={onCancel} visible={isModalOpen}>
            <AntdSpace direction='vertical' size='large'>
                <center>
                    <StyledTitle color={colors.graniteGray} level={2}>
                        Hapus Personel
                    </StyledTitle>
                </center>
                <StyledTitle color={colors.doveGray} level={3}>
                    Apakah anda yakin ingin menghapus personel ini? Seluruh data personel terkait akan terhapus secara
                    permanen.
                </StyledTitle>
            </AntdSpace>
        </Modal>
    )
}

DeletePenerbitDetail.propTypes = {
    deletePenerbitDetail: PropTypes.func,
    id: PropTypes.number,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    onCancel: PropTypes.func,
}

export default DeletePenerbitDetail
