import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import colors from '../../basic/colors'
import Statement from '../statement/Statement'
import StyledParagraph from '../styled_paragraph/StyledParagraph'
import StyledTitle from '../styled_title/StyledTitle'
import map from 'lodash/map'
import listRisk from './listRisk'

const RiskStatementText = (props) => {
    const { dark, title } = props

    return (
        <Fragment>
            <AntdRow justify={isMobile() ? 'left' : 'center'}>
                <StyledTitle $dark={dark} level={2}>
                    {title}
                </StyledTitle>
            </AntdRow>
            <br />
            <br />
            <StyledParagraph $dark={dark} $textAlign='justify' color={colors.graniteGray}>
                Jenis-jenis risiko yang perlu diketahui Penerbit dan Pemodal
            </StyledParagraph>
            <br />
            {map(listRisk, (item, key) => (
                <Statement content={item.content} dark={dark} key={key} noBreak={item.noBreak} title={item.title} />
            ))}
        </Fragment>
    )
}

RiskStatementText.propTypes = {
    dark: PropTypes.bool,
    title: PropTypes.string,
}

export default RiskStatementText
