import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import StyledTitle from '../../components/styled_title/StyledTitle'
import StyledResult from '../../components/styled_result/StyledResult'

const Success = () => {
    const { dark, dispatch, handleLogout } = useOutletContext()

    useEffect(() => {
        return () => {
            handleLogout()
        }
    }, [dispatch, handleLogout])

    const title = (
        <StyledTitle $dark={dark} level={2}>
            Email set ulang kata sandi berhasil dikirimkan.
        </StyledTitle>
    )
    const subTitle = (
        <StyledTitle $dark={dark} level={3}>
            Silahkan cek emailmu untuk melakukan set ulang kata sandi
            <br />
            Email set ulang kata sandi hanya berlaku selama 24 jam saja.
        </StyledTitle>
    )

    return <StyledResult subTitle={subTitle} title={title} />
}

export default Success
