import { default as AntdSpace } from 'antd/lib/space'
import React, { Fragment } from 'react'
import StyledParagraph from '../components/styled_paragraph/StyledParagraph'
import { UPLOAD_FORMAT_SIZE } from '../constants/formats'
import { StyledOrderedList } from './StyledComponents'

export const verificationFailedTitle = 'Verifikasi Data Gagal'

export const formInfo = (type) => {
    return (
        <StyledParagraph>
            {type === 'detail' && (
                <ul>
                    <li>{`Foto profil yang diupload menggunakan ${UPLOAD_FORMAT_SIZE}`}</li>
                    <li>Maksimal karakter untuk username adalah 15 karakter.</li>
                    <li>Penggantian data maksimal 1 kali dalam jangka waktu 14 hari.</li>
                </ul>
            )}
            {type === 'screening' && (
                <AntdSpace direction='vertical' size='middle'>
                    <AntdSpace direction='vertical' size={0}>
                        <Fragment>
                            Silahkan lengkapi data e-KYC terlebih dahulu untuk bisa lanjut ke tahap transaksi.
                        </Fragment>
                        <Fragment>Klik tombol verifikasi untuk memverifikasi data e-KYC kamu</Fragment>
                    </AntdSpace>
                    <Fragment>{`Foto KTP yang diupload menggunakan ${UPLOAD_FORMAT_SIZE}`}</Fragment>
                    <Fragment>
                        Data profil dan screening pengguna akan masuk dan diperiksa oleh Digisign yang berperan sebagai
                        perantara penyelenggara KYC online antara Moneypool dan KSEI. KYC memerlukan email, no HP, NIK
                        unik dan aktivasi akun Digisign dari tiap pengguna untuk validasi data.
                    </Fragment>
                    <Fragment>
                        NOMOR 57 /POJK.04/2020 TENTANG PENAWARAN EFEK MELALUI LAYANAN URUN DANA BERBASIS TEKNOLOGI
                        INFORMASI Pasal 37 (3) Kriteria Pemodal dan batasan pembelian Efek sebagaimana dimaksud pada
                        ayat (1) huruf c meliputi:
                    </Fragment>
                    <StyledOrderedList>
                        <li>
                            Setiap Pemodal dengan penghasilan sampai dengan Rp500.000.000,00 (lima ratus juta rupiah)
                            per tahun, dapat membeli Efek melalui Layanan Urun Dana paling banyak sebesar 5% (lima
                            persen) dari penghasilan per tahun.
                        </li>
                        <li>
                            Setiap Pemodal dengan penghasilan lebih dari Rp500.000.000,00 (lima ratus juta rupiah) per
                            tahun, dapat membeli Efek melalui Layanan Urun Dana paling banyak sebesar 10% (sepuluh
                            persen) dari penghasilan per tahun.
                        </li>
                    </StyledOrderedList>
                </AntdSpace>
            )}
            {type === 'bank' && <Fragment>Rekening terdaftar akan menerima pencairan dana ketika tarik dana.</Fragment>}
        </StyledParagraph>
    )
}
