import { default as AntdDivider } from 'antd/lib/divider'
import { default as AntdPopconfirm } from 'antd/lib/popconfirm'
import { default as AntdSpace } from 'antd/lib/space'
import isMobile from 'is-mobile'
import isFunction from 'lodash/isFunction'
import map from 'lodash/map'
import slice from 'lodash/slice'
import PropTypes from 'prop-types'
import React from 'react'
import colors from '../../basic/colors'
import StyledSpan from '../../components/styled_span/StyledSpan'
import { Dropdown } from '../index'
import { StyledAntdButton } from './StyledComponents'

const typeToLabel = {
    activate: 'Aktifkan',
    delete: 'Hapus',
    details: 'Detail',
    export: 'Export',
    reverse: 'Reverse',
}

const TableRowActions = (props) => {
    const getOption = (option) => {
        if (option.type === 'custom') {
            return isFunction(option.render) ? (
                option.render()
            ) : (
                <StyledAntdButton disabled={option.disabled} onClick={option.onClick} type='link'>
                    <StyledSpan color={colors.blueberry2}>{option.label}</StyledSpan>
                </StyledAntdButton>
            )
        }

        if (!option.needConfirm) {
            return (
                <StyledAntdButton onClick={option.onClick} type='link'>
                    {option.label}
                </StyledAntdButton>
            )
        }
        return (
            <AntdPopconfirm onConfirm={option.onClick} title={option.confirmTitle}>
                <StyledAntdButton type='link'>{option.label}</StyledAntdButton>
            </AntdPopconfirm>
        )
    }

    const getDropdownContent = (option) => {
        if (!option.needConfirm) {
            return option.label
        }
        return (
            <AntdPopconfirm onConfirm={option.onClick} title={option.confirmTitle}>
                {option.label}
            </AntdPopconfirm>
        )
    }

    let { options } = props
    options = map(options, (option) => {
        if (option.type) option.label = typeToLabel[option.type] || option.label
        if (option.type === 'delete' && option.needConfirm !== false) {
            option.needConfirm = true
            option.confirmTitle = 'Hapus data ini?'
        }
        if (option.type === 'activate' && option.needConfirm !== false) {
            option.needConfirm = true
            option.confirmTitle = 'Aktifkan karyawan ini kembali?'
        }
        if (option.type === 'reverse' && option.needConfirm !== false) {
            option.needConfirm = true
            option.confirmTitle = 'Reverse cuti yang sudah terbuat?'
        }
        return option
    })
    if (options.length === 0) {
        return <span />
    }
    if (options.length === 1) {
        return getOption(options[0])
    }
    if (options.length === 2) {
        return (
            <AntdSpace size='middle'>
                {getOption(options[0])}
                {getOption(options[1])}
            </AntdSpace>
        )
    }
    if (options.length === 3 && !isMobile()) {
        return (
            <AntdSpace size='middle'>
                {getOption(options[0])}
                {getOption(options[1])}
                {getOption(options[2])}
            </AntdSpace>
        )
    }

    const restOptions = slice(options, 1, options.length)
    const dropdownOptions = map(restOptions, (option) => ({
        content: getDropdownContent(option),
        onClick: option.needConfirm ? null : option.onClick,
    }))

    return (
        <span>
            {getOption(options[0])}
            <AntdDivider type='vertical' />
            <Dropdown name='more' options={dropdownOptions} title='Lainnya' />
        </span>
    )
}

TableRowActions.propTypes = {
    isWriteAllowed: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            confirmTitle: PropTypes.string,
            label: PropTypes.string,
            needConfirm: PropTypes.bool,
            onClick: PropTypes.func.isRequired,
            render: PropTypes.func,
            type: PropTypes.oneOf(['activate', 'details', 'delete', 'custom', 'export', 'reverse']),
        })
    ).isRequired,
}

export default TableRowActions
