import noop from 'lodash/noop'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { DATE_SLASH_FORMAT } from '../../constants/formats'
import StyledAntdDatePicker from './StyledAntdDatePicker'

const DatePicker = (props) => {
    const {
        dark,
        defaultValue,
        disabled,
        disabledOptions,
        format,
        input,
        isError,
        name,
        onChange,
        placeholder,
        popupClassName,
        size,
        value,
    } = props

    const getDisabledDate = (value) => disabledOptions(moment(value).toISOString())

    const handleChange = (name, format) => (value) => {
        const dateObj = value ? moment(value).format(format) : ''
        onChange(dateObj, name)
    }

    const newValue = value ? moment(value, format) : null

    return (
        <StyledAntdDatePicker
            {...input}
            $dark={dark}
            $isError={isError}
            defaultValue={defaultValue ? moment(defaultValue, format) : null}
            disabled={disabled}
            disabledDate={getDisabledDate}
            format={format}
            onChange={handleChange(name, format)}
            placeholder={placeholder}
            popupClassName={popupClassName}
            size={size}
            value={newValue}
        />
    )
}

DatePicker.propTypes = {
    dark: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    disabledOptions: PropTypes.func,
    format: PropTypes.string,
    input: PropTypes.object.isRequired,
    isError: PropTypes.bool,
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    popupClassName: PropTypes.string,
    size: PropTypes.string,
    value: PropTypes.string,
}

DatePicker.defaultProps = {
    disabledOptions: noop,
    format: DATE_SLASH_FORMAT,
    onChange: noop,
    popupClassName: '',
}

export default DatePicker
