import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import concat from 'lodash/concat'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Field } from 'redux-form'
import { useOutletContext } from 'react-router-dom'
import {
    Button,
    Form,
    FormItem,
    ReduxFormCheckboxGroup,
    ReduxFormInput,
    ReduxFormRadioGroup,
} from '../../antdcomponents'
import colors from '../../basic/colors'
import {
    filterDividendPeriodOptions,
    filterOrderOptions,
    finishedStatus,
    finishedStatusLabel,
} from '../../common/options/product'
import { StyledAntdSpace } from '../../components/StyledComponents'
import { StyledLine } from './StyledComponents'

const ProductFilter = (props) => {
    const {
        clearProductCategory,
        clearProductRibbon,
        clearProductType,
        dark,
        getProductCategories,
        getProductRibbons,
        getProductTypes,
        handleSubmit,
        isLoading,
        onFinish,
        onReset,
        productCategories,
        productRibbons,
        productTypes,
    } = props
    const { dispatch } = useOutletContext()

    useEffect(() => {
        const fetchProductCategories = async () => await getProductCategories()
        const fetchProductRibbons = async () => await getProductRibbons()
        const fetchProductTypes = async () => await getProductTypes()
        fetchProductCategories()
        fetchProductRibbons()
        fetchProductTypes()
        return () => {
            dispatch(clearProductCategory())
            dispatch(clearProductRibbon())
            dispatch(clearProductType())
        }
    }, [
        clearProductCategory,
        clearProductRibbon,
        clearProductType,
        dispatch,
        getProductCategories,
        getProductRibbons,
        getProductTypes,
    ])

    const categoryOptions = map(productCategories, (item) => ({
        label: item.title,
        value: kebabCase(item.title),
    }))
    const typeOptions = map(productTypes, (item) => ({
        label: item.title,
        value: kebabCase(item.title),
    }))
    const ribbonOptions = map(productRibbons, (item) => ({
        label: startCase(item.title),
        value: kebabCase(item.title),
    }))
    let statusOptions = [{ label: 'Semua', value: 'all' }]
    const additionalStatusOptions = map(finishedStatus, (item, key) => ({
        label: finishedStatusLabel[key],
        value: item,
    }))
    statusOptions = concat(statusOptions, additionalStatusOptions)

    const componentProps = {
        canCheckAll: true,
        checkboxPosition: 'right',
    }
    const formItemProps = {
        labelColor: colors.graniteGray,
        labelLevel: 4,
    }

    return (
        <Form onFinish={handleSubmit(onFinish)}>
            <StyledAntdSpace direction='vertical' size='middle'>
                <Field
                    component={ReduxFormCheckboxGroup}
                    componentProps={componentProps}
                    dark={dark}
                    formItemProps={formItemProps}
                    label='Kategori'
                    name='productCategoryTitle'
                    options={categoryOptions}
                />
                <Field
                    component={ReduxFormCheckboxGroup}
                    componentProps={componentProps}
                    dark={dark}
                    formItemProps={formItemProps}
                    label='Tipe'
                    name='productTypeTitle'
                    options={typeOptions}
                />
                <Field
                    component={ReduxFormRadioGroup}
                    dark={dark}
                    formItemProps={formItemProps}
                    label='Urutkan'
                    name='orderBy'
                    options={filterOrderOptions}
                />
                <Field
                    component={ReduxFormCheckboxGroup}
                    componentProps={componentProps}
                    dark={dark}
                    formItemProps={formItemProps}
                    label='Periode Dividen'
                    name='dividendPeriod'
                    options={filterDividendPeriodOptions}
                />
                <Field
                    component={ReduxFormCheckboxGroup}
                    componentProps={componentProps}
                    dark={dark}
                    formItemProps={formItemProps}
                    label='Status Pendanaan'
                    name='productRibbonTitle'
                    options={ribbonOptions}
                />
                <Field
                    component={ReduxFormRadioGroup}
                    dark={dark}
                    formItemProps={formItemProps}
                    label='Status Product'
                    name='status'
                    options={statusOptions}
                />
                <FormItem {...formItemProps} dark={dark} label='Harga per Lembar'>
                    <AntdRow align='middle'>
                        <AntdCol span={10}>
                            <Field
                                component={ReduxFormInput}
                                componentProps={{
                                    numberOnly: true,
                                }}
                                dark={dark}
                                formItemProps={{
                                    isHideLabel: true,
                                    noMargin: true,
                                }}
                                name='minPricePerShare'
                                placeholder='Rp Min'
                            />
                        </AntdCol>
                        <AntdCol offset={1} span={2}>
                            <StyledLine />
                        </AntdCol>
                        <AntdCol offset={1} span={10}>
                            <Field
                                component={ReduxFormInput}
                                componentProps={{
                                    numberOnly: true,
                                }}
                                dark={dark}
                                formItemProps={{
                                    isHideLabel: true,
                                    noMargin: true,
                                }}
                                name='maxPricePerShare'
                                placeholder='Rp Max'
                            />
                        </AntdCol>
                    </AntdRow>
                </FormItem>
                <Button dark={dark} htmlType='submit' loading={isLoading} name='filter-btn' value='Filter' />
                <Button dark={dark} loading={isLoading} name='reset-btn' onClick={onReset} value='Reset' />
            </StyledAntdSpace>
        </Form>
    )
}

ProductFilter.propTypes = {
    clearProductCategory: PropTypes.func,
    clearProductRibbon: PropTypes.func,
    clearProductType: PropTypes.func,
    dark: PropTypes.bool,
    getProductCategories: PropTypes.func,
    getProductRibbons: PropTypes.func,
    getProductTypes: PropTypes.func,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    onFinish: PropTypes.func,
    onReset: PropTypes.func,
    productCategories: PropTypes.array,
    productRibbons: PropTypes.array,
    productTypes: PropTypes.array,
}

export default ProductFilter
