import React from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import * as authenticationSlice from '../../features/authentication/authenticationSlice'
import * as pemodalSlice from '../../features/pemodal/pemodalSlice'
import * as verificationSlice from '../../features/verification/verificationSlice'
import VerifyMobileNumberOtp from './VerifyMobileNumberOtp'
import getTemporaryData from '../../utility/getTemporaryData'

let VerifyMobileNumberOtpContainer = (props) => <VerifyMobileNumberOtp {...props} />

const mapStateToProps = (state) => {
    const { mobileNumber } = getTemporaryData(state.authentication)
    return {
        currentRegisterStep: state.authentication.currentRegisterStep,
        email: state.authentication.email,
        id: state.authentication.id,
        isActionLoadingPemodal: state.pemodal.isActionLoading,
        isActionLoadingSendVerification: state.verification.isActionLoadingSendVerification,
        isActionLoadingVerify: state.verification.isActionLoadingVerify,
        isActionSuccessPemodal: state.pemodal.isActionSuccess,
        isActionSuccessSendVerification: state.verification.isActionSuccessSendVerification,
        isActionSuccessVerify: state.verification.isActionSuccessVerify,
        isLoading: state.authentication.isLoading,
        isValidUser: state.authentication.isValidUser,
        mobileNumber,
        tokenExpiryDate: state.authentication.tokenExpiryDate,
        user: state.authentication.user,
    }
}

const mapDispatchToProps = {
    clearPemodal: pemodalSlice.clearPemodal,
    clearTemporaryData: authenticationSlice.clearTemporaryData,
    clearVerification: verificationSlice.clearVerification,
    refetchUser: authenticationSlice.refetchUser,
    updatePemodal: pemodalSlice.updatePemodal,
    verifyMobileNumber: verificationSlice.verifyMobileNumber,
}

VerifyMobileNumberOtpContainer = reduxForm({
    enableReinitialize: true,
    form: 'registerPemodalVerifyMobileNumber',
})(VerifyMobileNumberOtpContainer)

export default connect(mapStateToProps, mapDispatchToProps)(VerifyMobileNumberOtpContainer)
