import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { toState } from '../../transformers/cart'
import * as cartAPI from './cartAPI'

const initialState = {
    cart: {},
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
}

export const { createCart, getCart, updateCart } = cartAPI

const cart = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getCart.pending, (state) =>
            Object.assign(state, {
                cart: {},
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(getCart.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            const cart = get(data, 'cart', {})
            return Object.assign(state, {
                cart: !isEmpty(cart) ? toState(cart) : {},
                isLoading: false,
            })
        })
        builder.addCase(getCart.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
        builder.addMatcher(isAnyOf(createCart.pending, updateCart.pending), (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addMatcher(isAnyOf(createCart.fulfilled, updateCart.fulfilled), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
        builder.addMatcher(isAnyOf(createCart.rejected, updateCart.rejected), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true,
            })
        )
    },
    initialState,
    name: 'CART',
    reducers: {
        clearCart: () => initialState,
    },
})

export const { clearCart } = cart.actions

const { reducer } = cart
export default reducer
