import styled from 'styled-components'
import { default as AntdRow } from 'antd/lib/row'
import colors from '../../basic/colors'

export const StyledNav = styled.nav`
    background: ${colors.persianIndigo}cc;
    border-radius: 30px;
    backdrop-filter: blur(30px);
    box-shadow: 0px 4px 4px ${colors.black}40;
    mix-blend-mode: normal;
    bottom: -1px;
    position: fixed !important;
    margin-bottom: 15px;

    text-align: center;
    width: 85%;
    z-index: 1000;
    display: block;
    left: 50%;
    transform: translate(-50%);

    @media screen and (min-width: 768px) {
        margin-bottom: 0px;
        display: none;
    }
`

export const StyledRow = styled(AntdRow)`
    border-radius: 50px;
    font-size: 12px;
    height: 50px;
    margin: auto;
    margin-bottom: 10px;
    width: 95%;
`
