import { default as AntdCol } from 'antd/lib/col'
import { default as AntdList } from 'antd/lib/list'
import { default as AntdProgress } from 'antd/lib/progress'
import styled from 'styled-components'
import { Button, Ribbon } from '../antdcomponents'
import colors from '../basic/colors'

export const StyledAntdCol = styled(AntdCol)`
    &&& {
        margin: auto;
    }
`

export const StyledAntdList = styled(AntdList)`
    &&& {
        li.ant-list-item {
            padding: 1px 0px;
        }
        li.ant-list-item > * {
            width: 100%;
        }
    }
`

export const StyledAntdProgress = styled(AntdProgress)`
    &&& {
        .ant-progress-inner {
            padding: 2px;
            height: 30px;
        }

        .ant-progress-bg {
            height: 25px !important;
        }
    }
`

export const StyledButton = styled(Button)`
    &&& {
        &,
        &:hover,
        &:active:focus,
        &:focus {
            background-image: unset;
        }
        background-color: white;
        border: 2px solid ${colors.blueberry2};

        span {
            color: ${colors.blueberry2};
        }
    }
`

export const StyledCarouselImageContainer = styled.div`
    position: relative;
    z-index: 10;
`

export const StyledCol = styled(AntdCol)`
    width: 100%;
`

export const StyledImage = styled.img`
    margin-top: 5px;
`

export const StyledRibbon = styled(Ribbon)`
    &&& {
        & {
            bottom: -185px;
            right: -75px;
            width: 230px;
            height: 45px;
            top: auto;
            left: auto;
            transform: rotate(-40deg);
            overflow: hidden;
            position: absolute;
            text-align: center;

            @media screen and (min-width: 768px) {
                right: -55px;
                height: 70px;
                padding-top: ${(props) => (props.$space ? '10px' : '20px')};
            }
        }

        span {
            height: 250px;
            width: 50px;
        }
    }
`

export const StyledRibbonContainer = styled.div`
    bottom: 43px;
    height: 200px;
    overflow: hidden;
    position: absolute;
    right: 0px;
    width: 200px;
`
