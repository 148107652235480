import { default as AntdRadio } from 'antd/lib/radio'
import { default as AntdSpace } from 'antd/lib/space'
import styled from 'styled-components'
import colors from '../../basic/colors'

export const StyledAntdRadio = styled(AntdRadio)`
    &&& {
        span.ant-radio-inner {
            border: 2px solid ${colors.persianIndigo};
        }
    }
`

const AntdRadioGroup = AntdRadio.Group
export const StyledAntdRadioGroup = styled(AntdRadioGroup)`
    &&& {
        width: 100%;
    }
`

export const StyledAntdSpace = styled(AntdSpace)`
    &&& {
        width: 100%;
        .ant-space-item {
            margin-bottom: 0px !important;
        }
    }
`
