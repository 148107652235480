import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import size from 'lodash/size'
import split from 'lodash/split'

const deleteAllCookies = (windowObj = {}) => {
    let cookie = get(windowObj, 'document.cookie', '')
    if (typeof window !== 'undefined') {
        cookie = window.document.cookie
    }
    const cookies = split(cookie, ';')

    for (let i = 0; i < size(cookies); i++) {
        const cookie = cookies[i]
        const eqPos = indexOf(cookie, '=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        const expiry = '=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
        window.document.cookie = `${name}${expiry}`
    }
    return
}

export default deleteAllCookies
