import { createSlice } from '@reduxjs/toolkit'
import * as contactUsAPI from './contactUsAPI'

const initialState = {
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
}

export const { createContactUs } = contactUsAPI

const contactUsSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(createContactUs.pending, (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addCase(createContactUs.fulfilled, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
        builder.addCase(createContactUs.rejected, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true,
            })
        )
    },
    initialState,
    name: 'CONTACT_US',
    reducers: {
        clearContactUs: () => initialState,
    },
})

export const { clearContactUs } = contactUsSlice.actions

const { reducer } = contactUsSlice
export default reducer
