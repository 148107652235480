import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import ContactUs from './ContactUs'
import * as contactUsSlice from '../features/contact_us/contactUsSlice'
import * as faqSlice from '../features/faq/faqSlice'
import scrollToViewFormError from '../utility/scrollToViewFormError'

let ContactUsContainer = (props) => <ContactUs {...props} />

const mapStateToProps = (state) => ({
    faqs: state.faq.faqs,
    isActionLoading: state.contactUs.isActionLoading,
    isActionSuccess: state.contactUs.isActionSuccess,
    isLoading: state.faq.isLoading,
})

const mapDispatchToProps = {
    clearContactUs: contactUsSlice.clearContactUs,
    clearFaq: faqSlice.clearFaq,
    createContactUs: contactUsSlice.createContactUs,
    getFaqs: faqSlice.getFaqs,
}

ContactUsContainer = reduxForm({
    enableReinitialize: true,
    form: 'contactUs',
    onSubmitFail: (errors) => scrollToViewFormError(errors),
})(ContactUsContainer)

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsContainer)
