import { default as AntdAvatar } from 'antd/lib/avatar'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import PropTypes from 'prop-types'
import React from 'react'
import { useOutletContext } from 'react-router-dom'

import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import StyledParagraph from '../styled_paragraph/StyledParagraph'
import StyledTitle from '../styled_title/StyledTitle'
import { StyledCard, StyledNameContainer } from './StyledComponents'

const TestimonialCard = (props) => {
    const { imgSrc, name, occupation, text } = props
    const { dark } = useOutletContext()

    const quoteImage = <img src={imagePath.quote} />

    return (
        <StyledCard>
            <AntdSpace direction='vertical' size='large'>
                <AntdSpace size='large'>
                    <AntdAvatar size={100} src={imgSrc} />
                    <AntdSpace direction='vertical'>
                        <StyledNameContainer>
                            <StyledTitle $dark={dark} level={4}>
                                {name}
                            </StyledTitle>
                        </StyledNameContainer>
                        <StyledTitle color={colors.graniteGray} level={5}>
                            {occupation}
                        </StyledTitle>
                    </AntdSpace>
                </AntdSpace>
                <AntdRow justify='left'>{renderLazyLoadImage(quoteImage)}</AntdRow>
                <StyledParagraph $textAlign='justify' color={colors.graniteGray}>
                    {text}
                </StyledParagraph>
            </AntdSpace>
        </StyledCard>
    )
}

TestimonialCard.propTypes = {
    imgSrc: PropTypes.string,
    name: PropTypes.string,
    occupation: PropTypes.string,
    text: PropTypes.node,
}

export default TestimonialCard
