import filter from 'lodash/filter'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import replace from 'lodash/replace'
import moment from 'moment'
import { getCurrentRegisterStep } from '../common/helper/authentication'
import { DATE_HOUR_SLASH_FORMAT } from '../constants/formats'
import * as penerbitDocumentTransformer from '../transformers/penerbitDocument'
import { avatarSelectorProfile } from './avatarSelector'

const getUserData = (state, data) => {
    const userDetail = get(data, 'userDetail', {})
    const isTermsAndConditionsApproved = get(userDetail, 'isTermsAndConditionsApproved', false)
    const isRiskStatementApproved = get(userDetail, 'isRiskStatementApproved', false)
    const isValidUser = isTermsAndConditionsApproved && isRiskStatementApproved

    const mobileNumber = userDetail.mobileNumber ? replace(userDetail.mobileNumber, '+62', '') : state.mobileNumber
    let newState = {
        colorSelector: avatarSelectorProfile(),
        currentRegisterStep: getCurrentRegisterStep(userDetail),
        email: userDetail.email || state.email,
        id: userDetail.id || state.id,
        isActive: userDetail.isActive,
        isBankinfoExist: userDetail.isBankinfoExist,
        isDetailExist: userDetail.isDetailExist,
        isEmailVerified: userDetail.isEmailVerified,
        isLoading: false,
        isTermsAndConditionsApproved,
        isRiskStatementApproved,
        isTokenExpired: false,
        isValidUser,
        mobileNumber,
        termsAndConditionsApprovalDate: userDetail.termsAndConditionsApprovalDate,
        profilePictureUrl: userDetail.profilePictureUrl || state.profilePictureUrl,
        riskStatementApprovalDate: userDetail.riskStatementApprovalDate,
        user: userDetail.user || state.user,
        username: userDetail.username || state.username,
    }
    if (userDetail.user === 'pemodal') {
        newState = Object.assign(newState, {
            isDigisignActivated: userDetail.isDigisignActivated,
            isKycStatusFailed: userDetail.kycStatus === 'failed',
            isKycStatusSuccessVerified: includes(['success', 'verified'], userDetail.kycStatus),
            isKycStatusSuccess: userDetail.kycStatus === 'success',
            isKycStatusVerified: userDetail.kycStatus === 'verified',
            isMobileNumberVerified: userDetail.isMobileNumberVerified,
        })
    }
    if (userDetail.user === 'penerbit') {
        const penerbitDocuments = !isEmpty(userDetail.penerbitDocuments)
            ? map(userDetail.penerbitDocuments, (item) => penerbitDocumentTransformer.toState(item))
            : []
        const npwp = filter(penerbitDocuments, (item) => item.fieldName === 'npwp')
        const websiteUrl = userDetail.websiteUrl ? replace(userDetail.websiteUrl, 'https://', '') : state.websiteUrl
        newState = Object.assign(newState, {
            address: userDetail.address || state.address,
            businessSectorId: userDetail.businessSectorId || state.businessSectorId,
            cityId: userDetail.cityId || state.cityId,
            description: userDetail.description || state.description,
            districtName: userDetail.district.name || state.district.name,
            entityCode: userDetail.entityCode || state.entityCode,
            entityIdentificationNo: userDetail.entityIdentificationNo || state.entityIdentificationNo,
            entityName: userDetail.entityName || state.entityName,
            entityTypeId: userDetail.entityTypeId || state.entityTypeId,
            fundingAmountId: userDetail.fundingAmountId || state.fundingAmountId,
            npwp,
            postalCode: userDetail.postalCode || state.postalCode,
            productTypeId: userDetail.productTypeId || state.productTypeId,
            provinceName: userDetail.city.province || state.city.province,
            subdistrictName: userDetail.district.subdistrict || state.district.subdistrict,
            websiteUrl,
        })
    }

    if (userDetail.tokenExpiryDate !== null) {
        newState = Object.assign(newState, {
            tokenExpiryDate: moment(userDetail.tokenExpiryDate).isValid()
                ? moment(userDetail.tokenExpiryDate).format(DATE_HOUR_SLASH_FORMAT)
                : null,
        })
    }
    return newState
}

export default getUserData
