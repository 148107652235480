import { default as AntdEmpty } from 'antd/lib/empty'
import styled from 'styled-components'

export const StyledAntdEmpty = styled(AntdEmpty)`
    &&& {
        height: 100%;

        .ant-empty-image,
        .ant-empty-description {
            margin: auto;
            width: 100%;
        }

        .ant-empty-image {
            height: 100%;
        }

        .ant-empty-description {
            height: 0%;
        }
        img {
            display: inline-block;
            height: auto;
        }
    }
`

export default StyledAntdEmpty
