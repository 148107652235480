import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { Button } from '../../antdcomponents'
import colors from '../../basic/colors'
import { isAuthorizedUser } from '../../common/helper/authorization'
import imagePath from '../../basic/imagePath'
import Loader from '../../components/loader/Loader'
import map from 'lodash/map'
import { StyledAntdSpace, StyledImage, StyledSection, StyledUnorderedList } from '../../components/StyledComponents'
import StyledParagraph from '../../components/styled_paragraph/StyledParagraph'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { useOutletContext } from 'react-router-dom'
import HelmetMeta from '../../components/HelmetMeta'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { renderLinkButton } from '../../utility/renderLinkComp'

const PenerbitDocument = (props) => {
    const { clearPenerbitDocument, currentRegisterStep, getPenerbitDocuments, isLoading, penerbitDocuments, user } =
        props
    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, pathname } = useOutletContext()

    const metaTitle = 'Legalitas Perusahaan'
    const profitImage = <StyledImage src={imagePath.profit} />

    useEffect(() => {
        const fetchPenerbitDocuments = async () => await getPenerbitDocuments()
        fetchPenerbitDocuments()
        return () => {
            dispatch(clearPenerbitDocument())
        }
    }, [clearPenerbitDocument, dispatch, getPenerbitDocuments])

    if (!isLoggedIn) {
        navigate('/login')
        return null
    }

    if (currentRegisterStep) {
        navigate(`/register/${user}/step${currentRegisterStep}`)
        return null
    }

    if (!isAuthorizedUser(isPemodal, isPenerbit, pathname)) {
        navigate('/profile')
        return null
    }

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <AntdRow>
                <AntdCol md={12} sm={0} xs={0}>
                    <AntdRow justify='center'>{renderLazyLoadImage(profitImage)}</AntdRow>
                </AntdCol>
                <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                    <StyledAntdSpace direction='vertical' size='large'>
                        <StyledTitle color={colors.graniteGray} level={2}>
                            {metaTitle}
                        </StyledTitle>
                        <div>
                            <StyledTitle color={colors.black} level={4}>
                                Dokumen Perusahaan
                            </StyledTitle>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <StyledUnorderedList $type='no-style'>
                                    {map(penerbitDocuments, (item, key) => (
                                        <AntdRow align='middle' justify='space-between' key={key}>
                                            <AntdCol>
                                                <StyledParagraph $dark={dark}>{item.identifier}</StyledParagraph>
                                            </AntdCol>
                                            <AntdCol>
                                                <a href={`${item.url}`} rel='noreferrer' target='_blank'>
                                                    <Button
                                                        color={colors.blueberry}
                                                        dark={dark}
                                                        name='download-btn'
                                                        noStyle
                                                        type='link'
                                                        value='Download'
                                                    />
                                                </a>
                                            </AntdCol>
                                        </AntdRow>
                                    ))}
                                </StyledUnorderedList>
                            )}
                        </div>
                        <AntdRow>
                            <AntdCol md={12} sm={24} xs={24}>
                                {renderLinkButton('/profile', {
                                    dark,
                                    name: 'back-to-dashboard-btn',
                                    value: 'Kembali Ke Dashboard',
                                })}
                            </AntdCol>
                        </AntdRow>
                    </StyledAntdSpace>
                </AntdCol>
            </AntdRow>
        </StyledSection>
    )
}

PenerbitDocument.propTypes = {
    clearPenerbitDocument: PropTypes.func,
    currentRegisterStep: PropTypes.number,
    getPenerbitDocuments: PropTypes.func,
    isEmailVerified: PropTypes.bool,
    isLoading: PropTypes.bool,
    isMobileNumberVerified: PropTypes.bool,
    isRiskStatementApproved: PropTypes.bool,
    isTermsAndConditionsApproved: PropTypes.bool,
    penerbitDocuments: PropTypes.array,
    user: PropTypes.string,
}

export default PenerbitDocument
