import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import * as productRibbonAPI from './productRibbonAPI'
import { toState } from '../../transformers/productRibbon'

const initialState = {
    isError: false,
    isLoading: false,
    productRibbons: [],
    totalProductRibbons: 0,
}

export const { getProductRibbons } = productRibbonAPI

const productRibbon = createSlice({
    name: 'PRODUCT_RIBBON',
    initialState,
    reducers: {
        clearProductRibbon: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getProductRibbons.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                productRibbons: [],
            })
        )
        builder.addCase(getProductRibbons.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                productRibbons: map(data.productRibbons, (item) => toState(item)),
                totalProductRibbons: data.count,
            })
        })
        builder.addCase(getProductRibbons.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
})

export const { clearProductRibbon } = productRibbon.actions

const { reducer } = productRibbon
export default reducer
