import { ConfigProvider } from 'antd'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdLayout } from 'antd/lib/layout'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import { default as AntdSpin } from 'antd/lib/spin'
import isMobile from 'is-mobile'
import get from 'lodash/get'
import includes from 'lodash/includes'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Empty } from './antdcomponents'
import colors from './basic/colors'
import imagePath from './basic/imagePath'
import Drawer from './components/drawer/Drawer'
import Navbar from './components/navbar/Navbar'
import { StyledExclamationCircleFilled } from './components/StyledComponents'
import StyledTableHeader from './components/styled_table_header/StyledTableHeader'
import StyledTitle from './components/styled_title/StyledTitle'
import * as authenticationSlice from './features/authentication/authenticationSlice'
import * as favoriteSlice from './features/favorite/favoriteSlice'
import * as penerbitDetailSlice from './features/penerbit_detail/penerbitDetailSlice'
import * as cartSlice from './features/cart/cartSlice'
import Footer from './footer/Footer'
import HeaderContainer from './header/HeaderContainer'
import ScrollToTop from './ScrollToTop'
import { GlobalStyle, StyledAntdLayout, StyledImage, StyledPortfolioEmptyContainer } from './StyledComponents'
import deleteAllCookies from './utility/deleteAllCookies'
import isLoggedIn from './utility/isLoggedIn'
import removePersistentData from './utility/removePersistentData'
import setPersistentData from './utility/setPersistentData'
import usePrevious from './utility/usePrevious'
import * as purchaseOrderSlice from '../src/features/purchase_order/purchaseOrderSlice'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { isEmpty } from 'lodash'
import { renderLinkButton } from './utility/renderLinkComp'

const AntdContent = AntdLayout.Content

const Layout = (props) => {
    const {
        authToken,
        cart,
        clearFavorite,
        clearPenerbitDetail,
        getCart,
        getFavorites,
        getPurchaseOrders,
        isActionLoading,
        isActionSuccess,
        isActive,
        isTokenExpired,
        isValidUser,
        logout,
        profilePictureUrl,
        reauthenticate,
        user,
        username,
    } = props

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const productCategoriesRef = useRef()

    const prevIsTokenExpired = usePrevious(isTokenExpired)

    // https://stephencook.dev/blog/using-window-in-react-ssr/
    const useIsSsr = () => {
        // we always start off in "SSR mode", to ensure our initial browser render
        // matches the SSR render
        const [isSsr, setIsSsr] = useState(true)

        useEffect(() => {
            // `useEffect` never runs on the server, so we must be on the client if
            // we hit this block
            setIsSsr(false)
        }, [])

        return isSsr
    }

    const isSsr = useIsSsr()
    const windowObj = useMemo(() => (isSsr ? {} : window), [isSsr])

    const isPemodal = user === 'pemodal'
    const isPenerbit = user === 'penerbit'
    const isLoggedInWithWindowObj = isLoggedIn(windowObj)

    const fetchFavorites = useCallback(async () => {
        if (isLoggedInWithWindowObj && isPemodal && isValidUser) {
            await getFavorites()
        }
    }, [getFavorites, isLoggedInWithWindowObj, isPemodal, isValidUser])

    const fetchCart = useCallback(async () => {
        if (isLoggedInWithWindowObj && isPemodal && isValidUser) {
            await getCart()
        }
    }, [getCart, isLoggedInWithWindowObj, isPemodal, isValidUser])

    useEffect(() => {
        fetchFavorites()
        fetchCart()
    }, [fetchFavorites, fetchCart, location])

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            fetchFavorites()
        }
    }, [fetchFavorites, isActionLoading, isActionSuccess])

    useEffect(() => {
        if (
            (!isLoggedInWithWindowObj && authToken) ||
            (prevIsTokenExpired !== isTokenExpired && prevIsTokenExpired && !isTokenExpired)
        ) {
            setPersistentData(windowObj, 'authToken', authToken)
        }
    }, [authToken, isLoggedInWithWindowObj, isTokenExpired, prevIsTokenExpired, windowObj])

    // update screen width
    const initialWidth = get(windowObj, 'innerWidth', 800)
    const [width, setWidth] = useState(initialWidth)
    useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth
            setWidth(newWidth)
        }
        updateWindowDimensions()
        window.addEventListener('resize', updateWindowDimensions)
        return () => window.removeEventListener('resize', updateWindowDimensions)
    }, [])

    useEffect(() => {
        if (isTokenExpired) {
            reauthenticate()
        }
    }, [isTokenExpired, reauthenticate])

    const [isOpenDrawer, setIsOpenDrawer] = useState(false)
    const toogleOpenDrawer = useCallback(() => setIsOpenDrawer((prevState) => !prevState), [])

    const handleLogout = () => {
        removePersistentData('authToken', windowObj)
        deleteAllCookies(windowObj)
        dispatch(logout())
        dispatch(clearFavorite())
        dispatch(clearPenerbitDetail())
        navigate('/')
    }

    const handleNavigate = (route) => () => navigate(route)

    const { pathname, search } = location
    const darkRoute = [
        '/404',
        '/contact-us',
        '/favorite',
        '/forgot-password',
        '/login',
        '/profile',
        '/reset-password',
        '/secondary-market',
    ]
    const passwordValidationRoute = ['/register/pemodal/step1', '/register/penerbit/step1', '/reset-password']

    const dark = includes(darkRoute, pathname)
    const passwordValidation = includes(passwordValidationRoute, pathname)
    const isAboutPage = pathname === '/about'
    const isHomepage = pathname === '/'
    const isSecondaryMarketPage = pathname === '/secondary-market'
    const isTransactionHistory = pathname === '/transaction-histories'
    const isProfileDetail = pathname === '/profile/detail'

    const defaultIndicator = <StyledImage src={imagePath.loader} />
    AntdSpin.setDefaultIndicator(defaultIndicator)
    const componentSize = isMobile() ? 'small' : 'large'

    const fetchPurchaseOrders = useCallback(async () => {
        const newOptions = {
            status: 'paid',
        }
        await getPurchaseOrders({ options: newOptions })
    }, [getPurchaseOrders])

    useEffect(() => {
        if (!isTransactionHistory && isLoggedInWithWindowObj && isPemodal && isValidUser) {
            fetchPurchaseOrders()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchPurchaseOrders, isTransactionHistory, isLoggedInWithWindowObj, isPemodal, isValidUser])

    const renderEmpty = () => {
        const isFundReportPage = includes(pathname, 'fund-report')
        const isInboxPage = includes(pathname, 'inbox')
        const isPortfolioPage = includes(pathname, 'portfolio')
        const isRenderDefaultEmpty = isFundReportPage || isInboxPage
        if (!isRenderDefaultEmpty && isPortfolioPage) {
            return (
                <StyledPortfolioEmptyContainer>
                    <AntdRow justify='center'>
                        <AntdSpace direction='vertical' size='large'>
                            <AntdSpace>
                                <StyledExclamationCircleFilled $color={colors.salmon} $size={64} />
                                <AntdSpace direction='vertical' size={0}>
                                    <StyledTitle color={colors.persianIndigo} level={4}>
                                        Oops.. data portfolio belum tersedia
                                    </StyledTitle>
                                    <StyledTableHeader color={colors.persianIndigo}>
                                        Yuk buat kolam uangmu sekarang
                                    </StyledTableHeader>
                                </AntdSpace>
                            </AntdSpace>
                            <AntdRow justify='center'>
                                <AntdCol span={18}>
                                    {renderLinkButton('/product', {
                                        dark,
                                        name: 'see-offer-btn',
                                        value: 'Cek Penawaran',
                                    })}
                                </AntdCol>
                            </AntdRow>
                        </AntdSpace>
                    </AntdRow>
                </StyledPortfolioEmptyContainer>
            )
        }
        if (!isRenderDefaultEmpty && !isPortfolioPage) {
            return <Empty />
        }
    }

    const context = {
        componentSize,
        dark,
        dispatch,
        handleLogout,
        handleNavigate,
        isLoggedIn: isLoggedInWithWindowObj,
        isPemodal,
        isPenerbit,
        isProfileDetail,
        location,
        navigate,
        params,
        passwordValidation,
        pathname,
        productCategoriesRef,
        search,
        width,
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}>
            <ConfigProvider componentSize={componentSize} renderEmpty={renderEmpty}>
                <StyledAntdLayout $dark={dark}>
                    <GlobalStyle />
                    <ScrollToTop location={location} windowObj={windowObj} />
                    <Drawer
                        isLoggedIn={isLoggedInWithWindowObj}
                        isOpen={isOpenDrawer}
                        isPemodal={isPemodal}
                        isPenerbit={isPenerbit}
                        onLogout={handleLogout}
                        onNavigate={handleNavigate}
                        profilePictureUrl={profilePictureUrl}
                        right
                        toogleOpenDrawer={toogleOpenDrawer}
                        username={username}
                    />
                    <HeaderContainer
                        dark={dark || isAboutPage || isHomepage}
                        isAboutPage={isAboutPage}
                        isActive={isActive}
                        isCartEmpty={isEmpty(cart)}
                        isHomepage={isHomepage}
                        isLoggedIn={isLoggedInWithWindowObj}
                        isPemodal={isPemodal}
                        isSecondaryMarketPage={isSecondaryMarketPage}
                        isValidUser={isValidUser}
                        productCategoriesRef={productCategoriesRef}
                        toogleOpenDrawer={toogleOpenDrawer}
                        username={username}
                        width={width}
                    />
                    <Navbar isLoggedIn={isLoggedInWithWindowObj} isPenerbit={isPenerbit} navigate={navigate} />
                    <AntdContent>
                        <Outlet context={context} />
                    </AntdContent>
                    <Footer
                        dark={dark}
                        isPemodal={isPemodal}
                        isPenerbit={isPenerbit}
                        navigate={navigate}
                        onLogout={handleLogout}
                    />
                </StyledAntdLayout>
            </ConfigProvider>
        </GoogleReCaptchaProvider>
    )
}

Layout.propTypes = {
    authToken: PropTypes.string,
    cart: PropTypes.object,
    clearFavorite: PropTypes.func,
    clearPenerbitDetail: PropTypes.func,
    getCart: PropTypes.func,
    getFavorites: PropTypes.func,
    getPurchaseOrders: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isActive: PropTypes.bool,
    isTokenExpired: PropTypes.bool,
    isValidUser: PropTypes.bool,
    logout: PropTypes.func,
    profilePictureUrl: PropTypes.string,
    reauthenticate: PropTypes.func,
    user: PropTypes.string,
    username: PropTypes.string,
}

const mapStateToProps = (state) => ({
    authToken: state.authentication.authToken,
    cart: state.cart.cart,
    isActionLoading: state.favorite.isActionLoading,
    isActionSuccess: state.favorite.isActionSuccess,
    isActive: state.authentication.isActive,
    isEmailVerified: state.authentication.isEmailVerified,
    isTokenExpired: state.authentication.isTokenExpired,
    isValidUser: state.authentication.isValidUser,
    profilePictureUrl: state.authentication.profilePictureUrl,
    user: state.authentication.user,
    username: state.authentication.username,
})

const mapDispatchToProps = {
    clearFavorite: favoriteSlice.clearFavorite,
    clearPenerbitDetail: penerbitDetailSlice.clearPenerbitDetail,
    getCart: cartSlice.getCart,
    getFavorites: favoriteSlice.getFavorites,
    getPurchaseOrders: purchaseOrderSlice.getPurchaseOrders,
    logout: authenticationSlice.logout,
    reauthenticate: authenticationSlice.reauthenticate,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
