import React from 'react'
import { connect } from 'react-redux'
import TermsAndConditions from './TermsAndConditions'

const TermsAndConditionsContainer = (props) => <TermsAndConditions {...props} />

const mapStateToProps = (state) => ({
    termsAndConditionsApprovalDate: state.authentication.termsAndConditionsApprovalDate,
})

export default connect(mapStateToProps)(TermsAndConditionsContainer)
