import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/entityType'
import * as entityTypeAPI from './entityTypeAPI'

const initialState = {
    entityTypes: [],
    isError: false,
    isLoading: false,
    totalEntityTypes: 0,
}

export const { getEntityTypes } = entityTypeAPI

const entityTypeSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getEntityTypes.pending, (state) =>
            Object.assign(state, {
                entityTypes: [],
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(getEntityTypes.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                entityTypes: map(data.entityTypes, (item) => toState(item)),
                isLoading: false,
                totalEntityTypes: data.count,
            })
        })
        builder.addCase(getEntityTypes.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'ENTITY_TYPE',
    reducers: {
        clearEntityType: () => initialState,
    },
})

export const { clearEntityType } = entityTypeSlice.actions

const { reducer } = entityTypeSlice
export default reducer
