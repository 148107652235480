import React from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import * as digisignSlice from '../../features/digisign/digisignSlice'
import DataVerification from './DataVerification'

let DataVerificationContainer = (props) => <DataVerification {...props} />

const mapStateToProps = (state) => ({
    isActionLoading: state.digisign.isActionLoading,
})

const mapDispatchToProps = {
    register: digisignSlice.register,
}

DataVerificationContainer = reduxForm({
    enableReinitialize: true,
    form: 'dataVerification',
})(DataVerificationContainer)

export default connect(mapStateToProps, mapDispatchToProps)(DataVerificationContainer)
