import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'
import replace from 'lodash/replace'
import split from 'lodash/split'
import trimStart from 'lodash/trimStart'
import moment from 'moment'
import { DATE_SLASH_FORMAT, DATE_HOUR_SLASH_FORMAT } from '../constants/formats'
import * as cityTransformer from './city'
import * as districtTransformer from './district'
import * as pemodalDetailTransformer from './pemodalDetail'
import * as pemodalDocumentTransformer from './pemodalDocument'

export const toApi = (data) => {
    const mobile_number = data.mobileNumber ? '+62' + trimStart(data.mobileNumber, '0') : undefined

    const birth_date = moment(data.birthDate, DATE_SLASH_FORMAT).isValid()
        ? moment.utc(data.birthDate, DATE_SLASH_FORMAT).toISOString()
        : undefined
    const detail_updated_at = moment(data.detailUpdatedAt, DATE_HOUR_SLASH_FORMAT).isValid()
        ? moment.utc(data.detailUpdatedAt, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined
    const terms_and_conditions_approval_date = moment(
        data.termsAndConditionsApprovalDate,
        DATE_HOUR_SLASH_FORMAT
    ).isValid()
        ? moment.utc(data.termsAndConditionsApprovalDate, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined
    const risk_statement_approval_date = moment(data.riskStatementApprovalDate, DATE_HOUR_SLASH_FORMAT).isValid()
        ? moment.utc(data.riskStatementApprovalDate, DATE_HOUR_SLASH_FORMAT).toISOString()
        : undefined

    const pemodal_detail = !isEmpty(data.pemodalDetail) ? pemodalDetailTransformer.toApi(data.pemodalDetail) : undefined
    const pemodal_documents = !isEmpty(data.pemodalDocuments)
        ? map(data.pemodalDocuments, (item) =>
              pemodalDocumentTransformer.toApi(
                  Object.assign(item, {
                      pemodalId: data.id || item.pemodalId,
                  })
              )
          )
        : undefined
    const profile_picture = !isEmpty(data.profilePicture)
        ? {
              file: get(data.profilePicture, 'file', ''),
              type: get(data.profilePicture, 'type', ''),
              url: get(data.profilePicture, 'url', ''),
          }
        : undefined

    return {
        address: data.address,
        birth_date,
        city_id: data.cityId,
        created_by: data.createdBy,
        detail_updated_at,
        district_id: data.districtId,
        email: data.email,
        gender: data.gender,
        hometown_id: data.hometownId,
        id: data.id,
        is_active: data.isActive,
        is_digisign_activated: data.isDigisignActivated,
        is_email_verified: data.isEmailVerified,
        is_mobile_number_verified: data.isMobileNumberVerified,
        is_terms_and_conditions_approved: data.isTermsAndConditionsApproved,
        is_risk_statement_approved: data.isRiskStatementApproved,
        is_uploading: data.isUploading,
        mobile_number,
        password: data.password,
        pemodal_detail,
        pemodal_documents,
        postal_code: data.postalCode,
        terms_and_conditions_approval_date,
        profile_picture,
        risk_statement_approval_date,
        updated_by: data.updatedBy,
        username: data.username,
    }
}

export const toState = (data) => {
    const birthDate = moment(data.birthDate).isValid() ? moment(data.birthDate).format(DATE_SLASH_FORMAT) : null
    const detailUpdatedAt = moment(data.detailUpdatedAt).isValid()
        ? moment(data.detailUpdatedAt).format(DATE_HOUR_SLASH_FORMAT)
        : null
    const isUpdateAllowed = moment(detailUpdatedAt, DATE_HOUR_SLASH_FORMAT).isValid()
        ? moment().diff(moment(detailUpdatedAt, DATE_HOUR_SLASH_FORMAT), 'days') >= 14
        : true
    const mobileNumber = data.mobileNumber ? replace(data.mobileNumber, '+62', '') : ''

    const pemodalDetail = !isEmpty(data.pemodalDetail)
        ? pemodalDetailTransformer.toState(data.pemodalDetail)
        : undefined

    const pemodalDocuments = !isEmpty(data.pemodalDocuments)
        ? map(data.pemodalDocuments, (item) => pemodalDocumentTransformer.toState(item))
        : undefined
    const ktp = filter(pemodalDocuments, (item) => item.identifier === 'ktp')
    const selfie = filter(pemodalDocuments, (item) => item.identifier === 'selfie')

    const profilePictureUrl = get(data, 'profilePictureUrl', '')
    const profilePicture = profilePictureUrl
        ? [
              {
                  fieldName: 'profilePicture',
                  name: last(split(profilePictureUrl, '/')),
                  url: profilePictureUrl,
              },
          ]
        : []

    return {
        address: data.address || '',
        birthDate,
        city: !isEmpty(data.city) ? cityTransformer.toState(data.city) : {},
        cityId: data.cityId || null,
        detailUpdatedAt,
        district: !isEmpty(data.district) ? districtTransformer.toState(data.district) : {},
        districtId: data.districtId || null,
        email: data.email || '',
        gender: data.gender || '',
        hometownId: data.hometownId || null,
        isUpdateAllowed,
        ktp,
        mobileNumber,
        pemodalDetail,
        postalCode: data.postalCode || '',
        profilePicture,
        profilePictureUrl,
        selfie,
        username: data.username || '',
    }
}
