import { UploadOutlined } from '@ant-design/icons'
import { default as AntdRow } from 'antd/lib/row'
import filter from 'lodash/filter'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'
import noop from 'lodash/noop'
import size from 'lodash/size'
import split from 'lodash/split'
import trim from 'lodash/trim'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, FormItem, Modal } from '../../antdcomponents'
import colors from '../../basic/colors'
import { StyledImage, StyledPlusOutlined, StyledUploadButtonSpace } from '../../components/StyledComponents'
import StyledSpan from '../../components/styled_span/StyledSpan'
import { UPLOAD_FORMAT_SIZE, VALID_IMAGE_EXTENSIONS } from '../../constants/formats'
import * as modal from '../../utility/modal'
import * as notification from '../../utility/notification'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { StyledAntdUpload } from './StyledComponents'

const Upload = (props) => {
    const {
        accept,
        csvHeaders,
        customValidators,
        disabled,
        formItemProps,
        imageConstraints,
        label,
        limit,
        listType,
        maxSizeMb,
        onRemove,
        onUpload,
        shape,
    } = props

    const initialFileList = get(props, 'fileList', [])
    const [fileList, setFileList] = useState([])
    const [isPreview, setIsPreview] = useState(false)
    const [previewUrl, setPreviewUrl] = useState('')
    const [isTouched, setIsTouched] = useState(false)
    const [isUploaded, setIsUploaded] = useState(true)
    const [isRequired, setIsRequired] = useState(false)
    const isUploadRequired = isTouched && isUploaded && isRequired

    const isRenderButton = !(size(fileList) >= limit)
    const newAccept = isArray(accept) ? toString(accept) : accept

    useEffect(() => {
        setFileList(initialFileList)
        if (!isEmpty(initialFileList)) {
            setIsUploaded(false)
        }
        if (formItemProps) {
            setIsRequired(formItemProps.required)
        }
    }, [initialFileList, formItemProps, setIsUploaded, setFileList, setIsRequired])

    const showRemoveIcon = disabled !== true

    const isValidFileType = (file) => {
        const { type } = file
        const acceptList = split(accept, ',')
        return indexOf(acceptList, type) > -1
    }

    const isValidFileSize = (file) => {
        const { size } = file
        const fileSizeMb = size / 1024 / 1024
        return maxSizeMb ? fileSizeMb < maxSizeMb : true
    }

    const handleUpload = ({ file, onProgress, onSuccess }) => onUpload({ file, fileList, onProgress, onSuccess })

    const handleValidationError = (onError, errorMessage) => {
        if (errorMessage) {
            notification.error(errorMessage)
        }
        onError()
        setFileList(fileList)
    }

    const validateCsv = (file, onProgress, onSuccess, onError) => {
        const csvValidator = customValidators.csv || {}
        const { validate } = csvValidator

        let reader = new FileReader()
        reader.onload = (event) => {
            let fileContents = event.target.result

            if (!fileContents) {
                return handleValidationError(onError, 'empty file')
            }

            if (!isEmpty(csvHeaders) && trim(split(fileContents, '\n')[0]) !== join(csvHeaders, ',')) {
                return handleValidationError(onError, 'invalid csv content')
            }

            if (validate && !validate(fileContents)) {
                return handleValidationError(onError, csvValidator.errorMessage)
            }

            handleUpload({ file, onError, onProgress, onSuccess })
        }
        reader.readAsText(file)
    }

    const validateImage = (file, onProgress, onSuccess, onError) => {
        const { height, width } = imageConstraints
        const imageValidator = get(customValidators, 'image', {})
        const { validate } = imageValidator

        let reader = new FileReader()
        reader.onload = (event) => {
            let image = new Image()
            image.src = event.target.result
            image.onload = () => {
                if (!isEmpty(imageConstraints) && image.height !== height && image.width !== width) {
                    const errorMessage = `dimensi gambar tidak valid dari ${width} x ${height} piksel`
                    return handleValidationError(onError, errorMessage)
                }

                if (validate && !validate(image)) {
                    return handleValidationError(onError, imageValidator.errorMessage)
                }

                handleUpload({ file, onError, onProgress, onSuccess })
            }
        }
        reader.readAsDataURL(file)
    }

    const handleCustomRequest = (info) => {
        const { file, onError, onProgress, onSuccess } = info

        if (!isValidFileType(file)) {
            notification.error('jenis file tidak valid')
            return onError()
        }
        if (!isValidFileSize(file)) {
            notification.error(`melebihi file size limit ${maxSizeMb} MB`)
            return onError()
        }
        if (indexOf(split(VALID_IMAGE_EXTENSIONS, ','), file.type) > -1) {
            return validateImage(file, onProgress, onSuccess, onError)
        }
        if (indexOf(['text/csv', 'application/vnd.ms-excel'], file.type) > -1) {
            return validateCsv(file, onProgress, onSuccess, onError)
        }

        handleUpload({ file, onError, onProgress, onSuccess })
    }

    const handleChange = (info) => {
        const { file, fileList } = info
        const name = get(file, 'name', '')
        const status = get(file, 'status', '')

        if (!isValidFileType(file) || !isValidFileSize(file)) {
            notification.error(`${name} unggahan file tidak valid.`)
            return
        }

        const newFileList = map(fileList, (item) => {
            const isNewFile = item.name === name
            if (isNewFile && item.percent === 0) {
                notification.info(`${name} berkas berhasil terpilih`)
            }
            if (isNewFile && status !== 'done') {
                return Object.assign(item, { status: 'done', url: '' })
            }

            return item
        })

        setFileList(newFileList)
    }

    const handleCancel = () => {
        setIsPreview(!isPreview)
        setPreviewUrl('')
    }

    const handlePreview = (file) => {
        if (indexOf(['picture', 'picture-card'], listType) < 0) {
            return window.open(file.url, '_blank')
        }

        let url = get(file, 'url', '')
        setIsPreview(!isPreview)
        if (url === '') {
            const reader = new FileReader()
            let base64Data = null
            reader.readAsDataURL(file.originFileObj)
            reader.onloadend = () => {
                base64Data = reader.result
                setPreviewUrl(base64Data)
            }
        } else setPreviewUrl(url)
    }

    const handleRemove = (file) => {
        if (disabled === false) {
            const name = get(file, 'name', '')
            modal.confirm({
                onOk: () => {
                    const newFileList = filter(fileList, (item) => item.uid !== file.uid)
                    const name = get(file, 'name', '')
                    notification.warning(`${name} berhasil dihapus.`)
                    setIsUploaded(true)
                    setIsTouched(true)
                    setFileList(newFileList)
                    onRemove(file, newFileList)
                },
                title: `Hapus ${name}?`,
            })
        }

        return false
    }

    const handleBlur = () => setIsTouched(true)

    const previewImage = <StyledImage src={previewUrl} />

    const renderButton = () => {
        if (listType === 'picture-card') {
            return (
                <StyledUploadButtonSpace direction='vertical'>
                    <StyledPlusOutlined />
                    <StyledUploadButtonSpace direction='vertical' size={0}>
                        <StyledSpan $lineHeight={1}>Unggah</StyledSpan>
                        {shape !== 'round' && (
                            <StyledSpan $lineHeight={1} color={colors.blueberry}>
                                {UPLOAD_FORMAT_SIZE}
                            </StyledSpan>
                        )}
                    </StyledUploadButtonSpace>
                </StyledUploadButtonSpace>
            )
        }

        const btnContent = (
            <span>
                <UploadOutlined /> Pilih File
            </span>
        )
        return <Button name='upload-btn' type='default' value={btnContent} />
    }

    const uploadComp = (
        <StyledAntdUpload
            $isUploadRequired={isUploadRequired}
            $shape={shape}
            accept={newAccept}
            customRequest={handleCustomRequest}
            disabled={disabled}
            fileList={fileList}
            listType={listType}
            onChange={handleChange}
            onPreview={handlePreview}
            onRemove={handleRemove}
            showUploadList={{ showRemoveIcon }}
        >
            {isRenderButton && renderButton()}
        </StyledAntdUpload>
    )

    return (
        <FormItem
            {...formItemProps}
            help={isUploadRequired && 'wajib diisi'}
            label={label}
            onBlur={handleBlur}
            validateStatus={isUploadRequired ? 'error' : undefined}
        >
            {renderLazyLoadImage(uploadComp)}
            <Modal onCancel={handleCancel} visible={isPreview}>
                <AntdRow justify='center' type='flex'>
                    {renderLazyLoadImage(previewImage)}
                </AntdRow>
            </Modal>
        </FormItem>
    )
}

Upload.propTypes = {
    accept: PropTypes.string,
    csvHeaders: PropTypes.array,
    customValidators: PropTypes.shape({
        csv: PropTypes.shape({
            errorMessage: PropTypes.string,
            validate: PropTypes.func.isRequired,
        }),
        image: PropTypes.shape({
            errorMessage: PropTypes.string,
            validate: PropTypes.func.isRequired,
        }),
    }),
    disabled: PropTypes.bool,
    enableCropper: PropTypes.bool,
    fileList: PropTypes.array,
    formItemProps: PropTypes.object,
    imageConstraints: PropTypes.object,
    label: PropTypes.string,
    limit: PropTypes.number,
    listType: PropTypes.oneOf(['picture', 'picture-card', 'text']),
    maxSizeMb: PropTypes.number,
    name: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
    onUpload: PropTypes.func.isRequired,
    shape: PropTypes.string,
}

Upload.defaultProps = {
    csvHeaders: [],
    customValidators: {},
    disabled: false,
    enableCropper: false,
    imageConstraints: {},
    limit: 1,
    maxSizeMb: 4,
    onRemove: noop,
}

export default Upload
