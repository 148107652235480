import clone from 'lodash/clone'
import concat from 'lodash/concat'
import difference from 'lodash/difference'
import flattenDeep from 'lodash/flattenDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import size from 'lodash/size'
import startCase from 'lodash/startCase'
import { filterDividendPeriodOptions, filterOrderOptions } from '../options/product'

const defaultOrderBy = filterOrderOptions[0].value
const productOrderMap = {
    [kebabCase('Nama Bisnis, A-Z')]: {
        by: 'code',
        order: 'ASC',
    },
    [kebabCase('Nama Bisnis, Z-A')]: {
        by: 'code',
        order: 'DESC',
    },
    [kebabCase('Sisa Hari, A-Z')]: {
        by: 'endDate',
        order: 'ASC',
    },
    [kebabCase('Sisa Hari, Z-A')]: {
        by: 'endDate',
        order: 'DESC',
    },
    [kebabCase('Terbaru')]: {
        by: 'createdAt',
        order: 'DESC',
    },
}

const getKebabCaseTitle = (list) => map(list, (item) => kebabCase(item.title))
const getStartCaseTitle = (list) => map(list, (item) => startCase(item))
const getFlattenDeepFilter = (query, attribute, defaultValue) => flattenDeep([get(query, attribute, defaultValue)])
const isEqualWithDefaultFilter = (filter, defaultFilter) =>
    isEmpty(difference(filter, defaultFilter)) && size(filter) === size(defaultFilter)

export const getInitialFilter = (productCategories, productRibbons, productTypes, query) => {
    const defaultAllDividendPeriod = map(filterDividendPeriodOptions, 'value')
    const defaultAllProductCategories = getKebabCaseTitle(productCategories)
    const defaultAllProductRibbons = getKebabCaseTitle(productRibbons)
    const defaultAllProductTypes = getKebabCaseTitle(productTypes)

    const dividendPeriod = getFlattenDeepFilter(query, 'period', defaultAllDividendPeriod)
    const maxPricePerShare = get(query, 'max', null)
    const minPricePerShare = get(query, 'min', null)
    const orderBy = get(query, 'order', defaultOrderBy)
    const productCategoryTitle = getFlattenDeepFilter(query, 'category', defaultAllProductCategories)
    const productRibbonTitle = getFlattenDeepFilter(query, 'tag', defaultAllProductRibbons)
    const productTypeTitle = getFlattenDeepFilter(query, 'type', defaultAllProductTypes)
    const status = get(query, 'status', 'all')
    const title = get(query, 'title', '')

    const defaultAllFilter = {
        defaultAllDividendPeriod,
        defaultAllProductCategories,
        defaultAllProductRibbons,
        defaultAllProductTypes,
    }

    const defaultFilterValue = {
        dividendPeriod: defaultAllDividendPeriod,
        notExpired: true,
        orderBy: defaultOrderBy,
        productCategoryTitle: defaultAllProductCategories,
        productRibbonTitle: defaultAllProductRibbons,
        productTypeTitle: defaultAllProductTypes,
        status: 'all',
    }

    const initialValues = {
        dividendPeriod,
        notExpired: true,
        maxPricePerShare,
        minPricePerShare,
        orderBy,
        productCategoryTitle,
        productRibbonTitle,
        productTypeTitle,
        status,
    }

    const initialOptions = clone(initialValues)

    return {
        defaultAllFilter,
        defaultFilterValue,
        initialOptions,
        initialValues,
        title,
    }
}

export const getNewOptions = (options, defaultAllFilter) => {
    const { dividendPeriod, orderBy, productCategoryTitle, productRibbonTitle, productTypeTitle } = options
    const { defaultAllDividendPeriod, defaultAllProductCategories, defaultAllProductRibbons, defaultAllProductTypes } =
        defaultAllFilter

    const newDividendPeriod =
        !isEqual(dividendPeriod, defaultAllDividendPeriod) && !isEmpty(dividendPeriod)
            ? getStartCaseTitle(dividendPeriod)
            : undefined
    const newProductCategory =
        !isEqual(productCategoryTitle, defaultAllProductCategories) && !isEmpty(productCategoryTitle)
            ? getStartCaseTitle(productCategoryTitle)
            : undefined
    const newProductRibbons =
        !isEqual(productRibbonTitle, defaultAllProductRibbons) && !isEmpty(productRibbonTitle)
            ? getStartCaseTitle(productRibbonTitle)
            : undefined
    const newProductTypes =
        !isEqual(productTypeTitle, defaultAllProductTypes) && !isEmpty(productTypeTitle)
            ? getStartCaseTitle(productTypeTitle)
            : undefined

    const newOptions = Object.assign(options, {
        dividendPeriod: newDividendPeriod,
        orderBy: get(productOrderMap, orderBy, {}),
        productCategoryTitle: newProductCategory,
        productRibbonTitle: newProductRibbons,
        productTypeTitle: newProductTypes,
    })

    return newOptions
}

export const getProductFilterFormData = (values, defaultAllFilter) => {
    const {
        dividendPeriod,
        maxPricePerShare,
        minPricePerShare,
        orderBy,
        productCategoryTitle,
        productRibbonTitle,
        productTypeTitle,
        status,
    } = values
    const { defaultAllProductCategories, defaultAllProductRibbons, defaultAllProductTypes, defaultAllDividendPeriod } =
        defaultAllFilter
    let urlParams = []
    if (dividendPeriod && !isEqualWithDefaultFilter(dividendPeriod, defaultAllDividendPeriod)) {
        map(dividendPeriod, (item) => {
            urlParams = concat(urlParams, `period=${item}`)
        })
    }
    if (minPricePerShare) {
        urlParams = concat(urlParams, `min=${minPricePerShare}`)
    }
    if (maxPricePerShare) {
        urlParams = concat(urlParams, `max=${maxPricePerShare}`)
    }
    if (orderBy && orderBy !== defaultOrderBy) {
        urlParams = concat(urlParams, `order=${orderBy}`)
    }
    if (productCategoryTitle && !isEqualWithDefaultFilter(productCategoryTitle, defaultAllProductCategories)) {
        map(productCategoryTitle, (item) => {
            urlParams = concat(urlParams, `category=${item}`)
        })
    }
    if (productRibbonTitle && !isEqualWithDefaultFilter(productRibbonTitle, defaultAllProductRibbons)) {
        map(productRibbonTitle, (item) => {
            urlParams = concat(urlParams, `tag=${item}`)
        })
    }
    if (productTypeTitle && !isEqualWithDefaultFilter(productTypeTitle, defaultAllProductTypes)) {
        map(productTypeTitle, (item) => {
            urlParams = concat(urlParams, `type=${item}`)
        })
    }
    if (status && status !== 'all') {
        urlParams = concat(urlParams, `status=${status}`)
    }
    const newOptions = {
        dividendPeriod,
        maxPricePerShare,
        minPricePerShare,
        orderBy,
        productCategoryTitle,
        productRibbonTitle,
        productTypeTitle,
    }
    return {
        newOptions,
        urlParams,
    }
}
