import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import imagePath from '../../basic/imagePath'
import { getRegisterData } from '../../common/helper/authentication'
import { successOptions } from '../../common/helper/profile'
import HelmetMeta from '../../components/HelmetMeta'
import { StyledAntdSpace, StyledImage, StyledSection } from '../../components/StyledComponents'
import VerifyOtpFormContainer from '../../components/verify_otp_form/VerifyOtpFormContainer'
import * as modal from '../../utility/modal'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'

const VerifyEmailOtp = (props) => {
    const {
        clearPemodal,
        clearTemporaryData,
        clearVerification,
        currentRegisterStep,
        email,
        id,
        isActionLoadingPemodal,
        isActionLoadingSendVerification,
        isActionLoadingVerify,
        isActionSuccessPemodal,
        isActionSuccessSendVerification,
        isActionSuccessVerify,
        isLoading,
        isValidUser,
        mobileNumber,
        refetchUser,
        sendVerificationMobileNumber,
        temporaryEmail,
        updatePemodal,
        user,
    } = props
    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, pathname } = useOutletContext()
    const isReverificationPage = pathname === '/profile/email'
    const { currentPage, userType } = getRegisterData(dark, pathname, isPemodal)
    const [isAllowedRedirection, setIsAllowedRedirection] = useState(false)
    const [isSendingVerification, setIsSendingVerification] = useState(false)

    const profitImage = <StyledImage src={imagePath.profit} />

    useEffect(() => {
        if (!isActionLoadingSendVerification && isActionSuccessSendVerification) {
            refetchUser({ email })
            setIsSendingVerification(false)
        }
        if (!isActionLoadingPemodal && isActionSuccessPemodal) {
            setIsAllowedRedirection(true)
            refetchUser({ additionalAttr: ['email'], email: temporaryEmail })
        }
    }, [
        email,
        isActionLoadingPemodal,
        isActionLoadingSendVerification,
        isActionLoadingVerify,
        isActionSuccessPemodal,
        isActionSuccessSendVerification,
        isActionSuccessVerify,
        isValidUser,
        refetchUser,
        temporaryEmail,
    ])

    useEffect(() => {
        if (!isActionLoadingVerify && isActionSuccessVerify) {
            if (isPemodal) {
                if (isValidUser) {
                    const data = {
                        email: temporaryEmail,
                        id,
                    }
                    updatePemodal(data)
                } else {
                    setIsSendingVerification(true)
                    sendVerificationMobileNumber({ new_mobile_number: '+62' + mobileNumber })
                }
            }
            if (isPenerbit) {
                refetchUser({ email })
            }
        }
        return () => {
            dispatch(clearPemodal())
            dispatch(clearVerification())
        }
    }, [
        clearPemodal,
        clearVerification,
        dispatch,
        email,
        id,
        isActionLoadingVerify,
        isActionSuccessVerify,
        isLoading,
        isPemodal,
        isPenerbit,
        isValidUser,
        mobileNumber,
        refetchUser,
        sendVerificationMobileNumber,
        temporaryEmail,
        updatePemodal,
    ])

    useEffect(() => {
        if (isAllowedRedirection && !isLoading) {
            dispatch(clearTemporaryData())
            modal.success(successOptions)
            navigate('/profile')
        }
    }, [clearTemporaryData, dispatch, isAllowedRedirection, isLoading, navigate])

    const handleClick = () => navigate(`/register/${userType}/step1`)

    const handleFinish = (values) => {
        const { otp } = values
        navigate(`/verify_email?user=${userType}&value=${otp}`)
    }

    if (isLoggedIn) {
        if (!isReverificationPage) {
            if (isValidUser) {
                navigate('/profile')
                return null
            }
            if ((user !== userType || currentRegisterStep !== 2) && !isLoading && !isSendingVerification) {
                navigate(`/register/${user}/step${currentRegisterStep}`)
                return null
            }
        }
    } else {
        navigate('/login')
        return null
    }

    const metaTitle = 'Verifikasi Email'

    return (
        <StyledSection dark={dark}>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <StyledAntdSpace direction='vertical' size={20}>
                {!isReverificationPage && currentPage}
                <AntdRow align='middle'>
                    <AntdCol md={12} sm={0} xs={0}>
                        <AntdRow justify='center'>{renderLazyLoadImage(profitImage)}</AntdRow>
                    </AntdCol>
                    <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                        <VerifyOtpFormContainer
                            {...props}
                            onClick={handleClick}
                            onFinish={handleFinish}
                            title={metaTitle}
                            user={userType}
                        />
                    </AntdCol>
                </AntdRow>
            </StyledAntdSpace>
        </StyledSection>
    )
}

VerifyEmailOtp.propTypes = {
    clearPemodal: PropTypes.func,
    clearTemporaryData: PropTypes.func,
    clearVerification: PropTypes.func,
    currentRegisterStep: PropTypes.number,
    email: PropTypes.string,
    id: PropTypes.number,
    isActionLoadingPemodal: PropTypes.bool,
    isActionLoadingSendVerification: PropTypes.bool,
    isActionLoadingVerify: PropTypes.bool,
    isActionSuccessPemodal: PropTypes.bool,
    isActionSuccessSendVerification: PropTypes.bool,
    isActionSuccessVerify: PropTypes.bool,
    isLoading: PropTypes.bool,
    isValidUser: PropTypes.bool,
    mobileNumber: PropTypes.string,
    refetchUser: PropTypes.func,
    sendVerificationMobileNumber: PropTypes.func,
    temporaryEmail: PropTypes.string,
    updatePemodal: PropTypes.func,
    user: PropTypes.string,
}

export default VerifyEmailOtp
