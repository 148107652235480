import clone from 'lodash/clone'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import * as annualSalarySlice from '../features/annual_salary/annualSalarySlice'
import * as authenticationSlice from '../features/authentication/authenticationSlice'
import * as citySlice from '../features/city/citySlice'
import * as districtSlice from '../features/district/districtSlice'
import * as incomeSourceSlice from '../features/income_source/incomeSourceSlice'
import * as investmentGoalSlice from '../features/investment_goal/investmentGoalSlice'
import * as pemodalSlice from '../features/pemodal/pemodalSlice'
import * as penerbitDetailSlice from '../features/penerbit_detail/penerbitDetailSlice'
import ScreeningForm from './ScreeningForm'
import * as digisignSlice from '../features/digisign/digisignSlice'
import { lengthIdCardNumber, numberRequired, olderThan } from '../utility/formValidation'
import scrollToViewFormError from '../utility/scrollToViewFormError'

let ScreeningFormContainer = (props) => <ScreeningForm {...props} />

const validate = (values, props) => {
    const { isPemodal, isPenerbit } = props

    const address = get(values, 'address', '')
    const birthDate = get(values, 'birthDate', '')
    const cityId = get(values, 'cityId', null)
    const districtName = get(values, 'districtName', '')
    const gender = get(values, 'gender', '')
    const hometownId = get(values, 'hometownId', null)
    const idCardName = get(values, isPemodal ? 'pemodalDetail.idCardName' : 'idCardName', '')
    const idCardNumber = get(values, isPemodal ? 'pemodalDetail.idCardNumber' : 'idCardNumber', '')
    const ktp = get(values, 'ktp', [])
    const postalCode = get(values, 'postalCode', '')
    const provinceName = get(values, 'provinceName', '')
    const selfie = get(values, 'selfie', [])
    const subdistrictName = get(values, 'subdistrictName', '')

    const errors = {}

    const idCardNumberNumberRequired = numberRequired(idCardNumber)
    if (idCardNumberNumberRequired) {
        return {
            _error: '*nomor ktp hanya boleh berisikan angka',
        }
    }
    const idCardNumberLength = lengthIdCardNumber(idCardNumber)
    if (idCardNumberLength) {
        return {
            _error: '*nomor ktp harus berjumlah 16 karakter',
        }
    }
    if (!idCardName) {
        return {
            _error: '*nama lengkap belum diisi',
        }
    }
    if (!gender) {
        return {
            _error: '*jenis kelamin belum dipilih',
        }
    }
    if (!hometownId) {
        return {
            _error: '*tempat lahir belum dipilih',
        }
    }
    if (!birthDate) {
        return {
            _error: '*tanggal lahir belum dipilih',
        }
    }
    const age = 18
    const birthDateOlderThan18 = olderThan(age)(birthDate)
    if (birthDateOlderThan18) {
        return {
            _error: `*pengguna harus berusia lebih dari ${age} tahun`,
        }
    }
    if (!provinceName) {
        return {
            _error: '*provinsi belum dipilih',
        }
    }
    if (!cityId) {
        return {
            _error: '*kota belum dipilih',
        }
    }
    if (!districtName) {
        return {
            _error: '*kecamatan belum dipilih',
        }
    }
    if (!subdistrictName) {
        return {
            _error: '*kelurahan belum dipilih',
        }
    }
    const postalCodeNumberRequired = numberRequired(postalCode)
    if (postalCodeNumberRequired) {
        return {
            _error: '*kode pos hanya boleh berisikan angka',
        }
    }
    if (!address) {
        return {
            _error: '*alamat belum diisi',
        }
    }

    if (isPemodal) {
        const annualSalaryId = get(values, isPemodal ? 'pemodalDetail.annualSalaryId' : 'annualSalaryId', null)
        const incomeSourceId = get(values, isPemodal ? 'pemodalDetail.incomeSourceId' : 'incomeSourceId', null)
        const investmentGoalId = get(values, isPemodal ? 'pemodalDetail.investmentGoalId' : 'investmentGoalId', null)
        if (!annualSalaryId) {
            return {
                _error: '*penghasilan tahunan belum dipilih',
            }
        }
        if (!incomeSourceId) {
            return {
                _error: '*sumber penghasilan belum dipilih',
            }
        }
        if (!investmentGoalId) {
            return {
                _error: '*tujuan pendanaan belum dipilih',
            }
        }
    }
    if (isPenerbit) {
        const position = get(values, 'position', '')
        if (!position) {
            return {
                _error: '*jabatan personel belum diisi',
            }
        }
    }

    if (isEmpty(ktp)) {
        return {
            _error: '*ktp belum diunggah',
        }
    }

    if (isEmpty(selfie)) {
        return {
            _error: '*selfie belum diunggah',
        }
    }

    return errors
}

const mapStateToProps = (state) => {
    const user = state.authentication.user
    const isLoadingPemodal = clone(state.pemodal.isLoading)
    const isLoadingPenerbitDetail = clone(state.penerbitDetail.isLoading)
    const isLoading = isLoadingPemodal || isLoadingPenerbitDetail
    const pemodal = clone(state.pemodal.pemodal)
    const penerbitDetail = clone(state.penerbitDetail.penerbitDetail)

    const isPemodal = user === 'pemodal'
    const isPenerbit = user === 'penerbit'
    const userData = isPemodal ? pemodal : penerbitDetail

    const districtName = get(userData, 'district.name', '')
    const provinceName = get(userData, 'city.province', '')
    const subdistrictName = get(userData, 'district.subdistrict', '')

    let isSameAsPenerbitEmail = get(userData, 'isSameAsPenerbitEmail', false)
    isSameAsPenerbitEmail = isSameAsPenerbitEmail ? ['true'] : []

    const isKycVerified = isPenerbit ? get(userData, 'isKycVerified', false) : false

    let initialValues = {}
    if (!isLoading) {
        initialValues = Object.assign(userData, {
            districtName,
            isSameAsPenerbitEmail,
            provinceName,
            subdistrictName,
        })
    }

    return {
        annualSalaries: state.annualSalary.annualSalaries,
        cities: state.city.cities,
        digisignActivationUrl: state.digisign.digisignActivationUrl,
        districts: state.district.districts,
        email: state.authentication.email,
        id: state.authentication.id,
        incomeSources: state.incomeSource.incomeSources,
        initialValues,
        investmentGoals: state.investmentGoal.investmentGoals,
        isActionLoadingDigisign: state.digisign.isActionLoading,
        isActionLoadingPemodal: state.pemodal.isActionLoading,
        isActionLoadingPenerbitDetail: state.penerbitDetail.isActionLoading,
        isActionSuccessDigisign: state.digisign.isActionSuccess,
        isActionSuccessPemodal: state.pemodal.isActionSuccess,
        isActionSuccessPenerbitDetail: state.penerbitDetail.isActionSuccess,
        isDigisignActivated: state.authentication.isDigisignActivated,
        isError: state.digisign.isError,
        isKycStatusFailed: state.authentication.isKycStatusFailed,
        isKycStatusSuccessVerified: state.authentication.isKycStatusSuccessVerified,
        isKycStatusVerified: state.authentication.isKycStatusVerified,
        isKycVerified,
        isLoadingPemodal,
        isLoadingPenerbitDetail,
        isMobileNumberVerified: state.authentication.isMobileNumberVerified,
        isNeedActivation: state.digisign.isNeedActivation,
        isPemodal,
        penerbitDetail,
        penerbitDetails: state.penerbitDetail.penerbitDetails,
        result: state.digisign.result,
        username: state.authentication.username,
    }
}

const mapDispatchToProps = {
    generateLinkActivation: digisignSlice.generateLinkActivation,
    clearAnnualSalary: annualSalarySlice.clearAnnualSalary,
    clearCity: citySlice.clearCity,
    clearDistrict: districtSlice.clearDistrict,
    clearIncomeSource: incomeSourceSlice.clearIncomeSource,
    clearInvestmentGoal: investmentGoalSlice.clearInvestmentGoal,
    clearPenerbitDetail: penerbitDetailSlice.clearPenerbitDetail,
    createPenerbitDetail: penerbitDetailSlice.createPenerbitDetail,
    getAnnualSalaries: annualSalarySlice.getAnnualSalaries,
    getCities: citySlice.getCities,
    getDistricts: districtSlice.getDistricts,
    getIncomeSources: incomeSourceSlice.getIncomeSources,
    getInvestmentGoals: investmentGoalSlice.getInvestmentGoals,
    getPenerbitDetails: penerbitDetailSlice.getPenerbitDetails,
    refetchUser: authenticationSlice.refetchUser,
    updatePemodal: pemodalSlice.updatePemodal,
    updatePenerbitDetail: penerbitDetailSlice.updatePenerbitDetail,
}

ScreeningFormContainer = reduxForm({
    enableReinitialize: true,
    form: 'screening',
    onSubmitFail: (errors) => scrollToViewFormError(errors),
    validate,
})(ScreeningFormContainer)

export default connect(mapStateToProps, mapDispatchToProps)(ScreeningFormContainer)
