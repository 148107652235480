import AsyncStorage from '@react-native-async-storage/async-storage'

// https://github.com/rt2zz/redux-persist/issues/1208
// https://github.com/vercel/next.js/discussions/15687
// https://github.com/vercel/next.js/tree/canary/examples/with-redux-persist
const createNoopStorage = () => {
    return {
        getItem(_key) {
            // eslint-disable-next-line
            console.log('getItem _key', _key)
            return Promise.resolve(null)
        },
        setItem(_key, value) {
            // eslint-disable-next-line
            console.log('setItem _key', _key)
            return Promise.resolve(value)
        },
        removeItem(_key) {
            // eslint-disable-next-line
            console.log('removeItem _key', _key)
            return Promise.resolve()
        },
    }
}

const storage = typeof window !== 'undefined' ? AsyncStorage : createNoopStorage()

export default storage
