// https://abouts.co/ - color name
module.exports = {
    antiFlashWhite: '#F0F0F0',
    aquamarine: '#5AFEEB',
    black: '#000000',
    blueberry: '#726EFF',
    blueberry2: '#4492FB',
    brightGray: '#ECECEC',
    caribbeanGreen: '#08C6AC',
    carnationPink: '#FF99BD',
    doveGray: '#6E6E6E',
    graniteGray: '#656565',
    mercury: '#E5E5E5',
    persianIndigo: '#2A1467',
    persiaRose: '#F734AB',
    purpleHeart: '#4C19D9',
    robbinEggBlue: '#29E8E5',
    rose: '#FF4D4F',
    salmon: '#FF7E64',
    silver: '#C4C4C4',
    springGreen: '#09E67E',
    tuna: '#373A47',
}
