import { Input as AntdInput } from 'antd'
import styled from 'styled-components'
import colors from '../../basic/colors'

export const StyledUnorderedList = styled.ul`
    padding-inline-start: 15px;
    width: ${(props) => (props.$isResetPassword && !props.$isMobile ? '510px' : '')};
    @media screen and (min-width: 768px) {
        padding-inline-start: 20px;
        width: ${(props) => (props.$isResetPassword && props.$isMobile ? '510px' : '')};
    }
`

const AntdInputPassword = AntdInput.Password
export const StyledAntdInputPassword = styled(AntdInputPassword)`
    &&& {
        border: 2px solid ${(props) => (props.$dark ? colors.robbinEggBlue : 'silver')};
        border-radius: 20px;
        height: 30px;
        padding: 0px 11px;

        &,
        input {
            box-shadow: none;
            outline: none;

            ${(props) =>
                !props.disabled &&
                `
                background-color: transparent !important;
                color: ${props.$dark ? 'white' : 'black'};
            `}
        }

        &:focus {
            box-shadow: none !important;
            border-right-width: 2px !important;
        }

        input {
            border-radius: unset;
            padding: 4px 0px;
        }

        .ant-input-password-icon {
            color: ${(props) => (props.$dark ? colors.robbinEggBlue : colors.blueberry)};
        }
        @media screen and (min-width: 768px) {
            height: 45px;
        }
    }
`
