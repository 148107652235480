import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import isMobile from 'is-mobile'
import camelCase from 'lodash/camelCase'
import concat from 'lodash/concat'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import noop from 'lodash/noop'
import size from 'lodash/size'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Carousel } from '../antdcomponents'
import imagePath from '../basic/imagePath'
import BankInfoModal from '../components/bank_info_modal/BankInfoModal'
import { StyledImage } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import { isProductionEnv } from '../constants/constants'
import { useGetInfoQuery } from '../services/api'
import getMoneypoolLogo from '../utility/getMoneypoolLogo'
import * as notification from '../utility/notification'
import { renderLink, renderLinkButton } from '../utility/renderLinkComp'
import usePrevious from '../utility/usePrevious'
import CartModal from './modal/CartModal'
import {
    StyledAntdMenu,
    StyledAntdRow,
    StyledHeader,
    StyledHeaderContainer,
    StyledInstitutionLogo,
    StyledInvestmentCounter,
    StyledInvestmentData,
    StyledInvestmentDataContainer,
    StyledInvestmentDataSection,
    StyledInvestmentDataUnit,
    StyledLogo,
    StyledPemodalCounter,
    StyledPenerbitCounter,
    StyledToogleDrawerIcon,
} from './StyledComponents'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'

const Header = (props) => {
    const {
        dark,
        getInfo,
        info,
        isAboutPage,
        isActive,
        isBankinfoExist,
        isCartEmpty,
        isHomepage,
        isInfoLocalError,
        isInfoLocalLoading,
        isLoadingAuthenticate,
        isLoggedIn,
        isPemodal,
        isSecondaryMarketPage,
        isValidUser,
        productCategoriesRef,
        toogleOpenDrawer,
        username,
        width,
    } = props

    //for SSR only
    const { data, isError, isLoading, refetch } = useGetInfoQuery()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalType, setModalType] = useState('')
    const prevIsLoadingAuthenticate = usePrevious(isLoadingAuthenticate)

    const profileLogo = dark ? imagePath.profileLight : imagePath.profileDark

    let currentInfo = info
    let currentIsError = isInfoLocalError
    let currentIsLoading = isInfoLocalLoading
    //for SSR only
    if (isProductionEnv) {
        currentInfo = data
        currentIsError = isError
        currentIsLoading = isLoading
    }

    useEffect(() => {
        if (isProductionEnv) {
            //for SSR only
            refetch()
        } else {
            getInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            !isBankinfoExist &&
            !isLoadingAuthenticate &&
            isLoadingAuthenticate !== prevIsLoadingAuthenticate &&
            !isUndefined(prevIsLoadingAuthenticate) &&
            isValidUser
        ) {
            setIsModalOpen(true)
            setModalType('bank')
        }
    }, [isBankinfoExist, isLoadingAuthenticate, prevIsLoadingAuthenticate, isValidUser])

    const handleCancel = () => {
        setIsModalOpen(false)
        setModalType('')
    }

    const profileLogoImage = <img src={profileLogo} />
    const profileMenu = renderLink(
        { to: isValidUser ? '/profile' : '/register' },
        <AntdRow align='middle'>
            <AntdCol>
                <StyledTitle $dark={dark} level={3}>
                    {username}
                </StyledTitle>
            </AntdCol>
            <AntdCol>{renderLazyLoadImage(profileLogoImage)}</AntdCol>
        </AntdRow>
    )

    const loginMenu = renderLink(
        { to: '/login' },
        <StyledTitle $dark={dark} level={3}>
            Masuk/Daftar
        </StyledTitle>
    )

    const renderTitleLogin = isLoggedIn ? profileMenu : loginMenu
    const srcLogo = getMoneypoolLogo(dark)
    const headerBurgerMenu = dark ? imagePath.headerBurgerMenuLight : imagePath.headerBurgerMenuDark
    const cartLogo = dark ? imagePath.cartLight : imagePath.cartDark
    const cartImage = <img src={cartLogo} />

    const handleOpenCart = () => {
        if (isActive) {
            setIsModalOpen(true)
            setModalType('cart')
        } else {
            notification.error('Akun anda telah diblokir')
        }
    }

    const cartMenu = (
        <Button
            name='cart-btn'
            noStyle
            onClick={isCartEmpty ? handleOpenCart : noop}
            type='text'
            value={renderLazyLoadImage(cartImage)}
        />
    )

    const fullCartMenu = renderLink({ to: '/order' }, cartMenu)

    const renderCart = !isCartEmpty ? fullCartMenu : cartMenu

    const renderMenus = () => {
        let items = []
        if (width >= 1024) {
            items = [
                {
                    key: 'about',
                    isShown: !isMobile(),
                    label: (
                        <StyledTitle $dark={dark} level={3}>
                            {renderLink({ to: '/about' }, 'Tentang')}
                        </StyledTitle>
                    ),
                },
                {
                    key: 'faq',
                    isShown: !isMobile(),
                    label: (
                        <StyledTitle $dark={dark} level={3}>
                            {renderLink({ to: '/faq' }, 'FAQ')}
                        </StyledTitle>
                    ),
                },
                {
                    key: 'login',
                    isShown: !isMobile(),
                    label: renderTitleLogin,
                },
                {
                    key: 'cart',
                    isShown: isLoggedIn && isPemodal && !isMobile(),
                    label: renderCart,
                },
            ]
        }
        items = concat(items, {
            key: 'drawer',
            isShown: true,
            label: <StyledToogleDrawerIcon onClick={toogleOpenDrawer} src={headerBurgerMenu} />,
        })

        return (
            <StyledAntdMenu $dark={dark} mode='horizontal'>
                {map(
                    items,
                    (item) => item.isShown && <StyledAntdMenu.Item key={item.key}>{item.label}</StyledAntdMenu.Item>
                )}
            </StyledAntdMenu>
        )
    }

    const renderHeader = (
        <AntdRow align='middle' justify='space-between'>
            <AntdCol md={8} sm={22}>
                {renderLink({ to: '/' }, <StyledLogo src={srcLogo} />)}
            </AntdCol>
            <AntdCol md={16} sm={2}>
                <AntdRow justify='end'>
                    <AntdCol span={24}>{renderMenus()}</AntdCol>
                </AntdRow>
            </AntdCol>
        </AntdRow>
    )

    const scrollToProductCategories = () => {
        const productCategories = productCategoriesRef.current
        if (productCategories) {
            productCategories.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const renderInvestData = () => {
        if (currentIsError) {
            return <Fragment>Oh tidak, ada kesalahan</Fragment>
        }
        if (currentIsLoading) {
            return <Fragment>Loading...</Fragment>
        }
        if (isEmpty(currentInfo)) {
            return null
        }

        const pemodalCount = get(currentInfo, 'pemodalCount', 0)
        const penerbitCount = get(currentInfo, 'penerbitCount', 0)
        const totalInvesment = get(currentInfo, 'totalInvesment', 0)
        const investmentDatas = [
            { CounterComponent: StyledPemodalCounter, title: 'Pemodal Terdaftar', value: pemodalCount },
            { CounterComponent: StyledPenerbitCounter, title: 'Penerbit Terdaftar', value: penerbitCount },
            {
                CounterComponent: StyledInvestmentCounter,
                title: 'Total Investasi',
                value: totalInvesment / 1000000,
                unit: 'mil',
            },
        ]

        const slidesToScroll = isMobile() ? 1 : 3
        const slidesToShow = isMobile() ? 1 : 3
        const totalSlidesToShow = 3

        // invest data
        const contentInvestmentDatas = map(investmentDatas, (item) => {
            const { CounterComponent, title, unit, value } = item
            const name = camelCase(title)
            return (
                <center key={item}>
                    <StyledTitle $dark={dark} level={1}>
                        <CounterComponent name={name} value={value} />
                        <StyledInvestmentDataUnit>{unit}</StyledInvestmentDataUnit>
                    </StyledTitle>
                    <StyledTitle $dark={dark} level={3}>
                        {title}
                    </StyledTitle>
                </center>
            )
        })

        const sizeContent = size(contentInvestmentDatas)
        let contentEmptyInvestmentDatas = []
        const remainingInvestmentData = sizeContent % totalSlidesToShow
        if (remainingInvestmentData !== 0) {
            const sizeEmptyContent = totalSlidesToShow - remainingInvestmentData
            contentEmptyInvestmentDatas = flatten(Array(sizeEmptyContent).fill(<div key='empty' />))
        }

        const content = [...contentInvestmentDatas, ...contentEmptyInvestmentDatas]

        const viewMoreIcon = <img src={imagePath.viewMore} />

        return (
            <AntdRow>
                <AntdCol span={24}>
                    <center>
                        <Button
                            dark={dark}
                            name='view-more-btn'
                            onClick={scrollToProductCategories}
                            type='text'
                            value={renderLazyLoadImage(viewMoreIcon)}
                        />
                    </center>
                </AntdCol>
                <AntdCol span={24}>
                    <StyledInvestmentData
                        rectangleDesktop={imagePath.rectangleDesktop}
                        rectangleMobile={imagePath.rectangleMobile}
                    >
                        <Carousel
                            autoplay
                            autoplaySpeed={4000}
                            content={content}
                            dotsColor='white'
                            dotsMarginBottom={-35}
                            slidesToScroll={slidesToScroll}
                            slidesToShow={slidesToShow}
                            speed={1000}
                        />
                    </StyledInvestmentData>
                </AntdCol>
            </AntdRow>
        )
    }

    const isSampleImage = process.env.REACT_APP_USE_SAMPLE_IMAGE === 'true'

    const renderStartInvesting = () => {
        const institutionLogo = (imageUrl) => <StyledInstitutionLogo src={imageUrl} />

        const institutionLink = (institution) => (
            <Button name='institution-button' type='text' value={institutionLogo(institution.imageUrl)} />
        )

        const institutionList = [
            {
                imageUrl: isSampleImage ? imagePath.kominfoSample : imagePath.kominfo,
                url: 'https://www.kominfo.go.id',
            },
            { imageUrl: isSampleImage ? imagePath.ojkSample : imagePath.ojk, url: 'https://www.ojk.go.id' },
            { imageUrl: isSampleImage ? imagePath.isoSample : imagePath.iso, url: 'https://www.iso.org' },
        ]

        return (
            <StyledInvestmentDataSection>
                <AntdRow align='middle'>
                    <AntdCol md={12} sm={24}>
                        <AntdRow justify='center'>
                            <StyledImage src={imagePath.pump} />
                        </AntdRow>
                    </AntdCol>
                    <AntdCol md={{ offset: 1, span: 11 }} sm={24}>
                        <StyledTitle $dark={dark} level={1}>
                            Miliki Saham Bisnis UMKM
                        </StyledTitle>
                        <StyledTitle $dark={dark} level={3}>
                            Alokasikan dana pada bisnis pilihan Anda, Tuai keuntungan jangka panjang.
                        </StyledTitle>
                        <br />
                        <AntdRow>
                            <AntdCol
                                md={{ offset: 0, span: 14 }}
                                sm={{ offset: 5, span: 14 }}
                                xs={{ offset: 5, span: 14 }}
                            >
                                {renderLinkButton('/products', { dark, name: 'invest-btn', value: 'Beli Sekarang' })}
                            </AntdCol>
                        </AntdRow>
                        <br />
                        <AntdSpace direction='horizontal'>
                            {map(institutionList, (item, key) => (
                                <a href={`${item.url}`} key={key} rel='noreferrer' target='_blank'>
                                    <Fragment>{institutionLink(item)}</Fragment>
                                </a>
                            ))}
                        </AntdSpace>
                    </AntdCol>
                </AntdRow>
                <br />
                {renderInvestData()}
            </StyledInvestmentDataSection>
        )
    }

    const renderAboutSecondaryMarketPageHeader = (
        <StyledAntdRow align='middle'>
            <AntdCol span={24}>
                <AntdSpace direction='vertical' size='large'>
                    <StyledTitle $dark={dark} level={3}>
                        <AntdSpace direction='vertical' size='large'>
                            <StyledTitle $dark={dark} level={2}>
                                {isAboutPage && 'Tentang Kami'}
                                {isSecondaryMarketPage && 'Pasar Sekunder'}
                            </StyledTitle>
                            {isAboutPage && (
                                <Fragment>
                                    <Fragment>
                                        PT Moneypool adalah perusahaan yang bergerak dibidang Layanan Penawaran Efek
                                        melalui Urun Dana berbasis Teknologi Informasi dengan brand Moneypool. Moneypool
                                        hadir untuk mendukung perkembangan usaha rintisan dan Usaha Mikro Kecil Menegah
                                        (UMKM) dengan memberikan akses permodalan yang lebih fleksibel.
                                    </Fragment>
                                    <Fragment>
                                        Moneypool berkomitmen untuk menjadi penjembatan antara Pemodal dan Penerbit
                                        secara digital.
                                    </Fragment>
                                </Fragment>
                            )}
                            {isSecondaryMarketPage && (
                                <Fragment>
                                    Tak lama lagi, fitur jual beli pada pasar sekunder akan segera dibuka. Yuk cek
                                    kembali portfoliomu maupun produk yang ingin kamu beli tapi telah habis masa
                                    penawarannya.
                                </Fragment>
                            )}
                        </AntdSpace>
                    </StyledTitle>
                    <AntdRow gutter={[0, 20]}>
                        <AntdCol lg={5} md={8} sm={18} xs={18}>
                            {renderLinkButton('/portfolio', {
                                dark,
                                name: 'see-portfolio-btn',
                                value: 'Lihat Portfolio',
                            })}
                        </AntdCol>
                        <AntdCol lg={5} md={{ offset: 1, span: 8 }} sm={18} xs={18}>
                            {renderLinkButton('/products', { dark, name: 'see-offer-btn', value: 'Cek Penawaran' })}
                        </AntdCol>
                    </AntdRow>
                </AntdSpace>
            </AntdCol>
        </StyledAntdRow>
    )

    return (
        <StyledHeader
            $isAboutPage={isAboutPage}
            $isHomepage={isHomepage}
            $isSecondaryMarketPage={isSecondaryMarketPage}
        >
            <StyledHeaderContainer $dark={dark}>{renderHeader}</StyledHeaderContainer>
            {isHomepage && <StyledInvestmentDataContainer>{renderStartInvesting()}</StyledInvestmentDataContainer>}
            {(isAboutPage || isSecondaryMarketPage) && renderAboutSecondaryMarketPageHeader}
            <CartModal isModalOpen={isModalOpen && modalType === 'cart'} onCancel={handleCancel} />
            <BankInfoModal isModalOpen={isModalOpen && modalType === 'bank'} onCancel={handleCancel} />
        </StyledHeader>
    )
}

Header.propTypes = {
    dark: PropTypes.bool,
    getInfo: PropTypes.func,
    info: PropTypes.object,
    isAboutPage: PropTypes.bool,
    isActive: PropTypes.bool,
    isBankinfoExist: PropTypes.bool,
    isCartEmpty: PropTypes.bool,
    isHomepage: PropTypes.bool,
    isInfoLocalError: PropTypes.bool,
    isInfoLocalLoading: PropTypes.bool,
    isLoadingAuthenticate: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isPemodal: PropTypes.bool,
    isSecondaryMarketPage: PropTypes.bool,
    isValidUser: PropTypes.bool,
    productCategoriesRef: PropTypes.object,
    toogleOpenDrawer: PropTypes.func,
    username: PropTypes.string,
    width: PropTypes.number,
    windowObj: PropTypes.object,
}

export default Header
