import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/purchaseOrder'
import * as api from '../api'

export const cancelPurchaseOrder = createAsyncThunk('CANCEL_PURCHASE_ORDER', async (data, { rejectWithValue }) => {
    try {
        const res = await api.update('/purchase-order/cancel', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const createPurchaseOrder = createAsyncThunk('CREATE_PURCHASE_ORDER', async (data, { rejectWithValue }) => {
    try {
        const res = await api.create('/purchase-order/create', toApi(data))
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getPurchaseOrders = createAsyncThunk(
    'GET_PURCHASE_ORDERS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/purchase-orders', { limit, offset, options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getPemodalTransactionSummary = createAsyncThunk(
    'GET_PEMODAL_TRANSACTION_SUMMARY',
    async (data, { rejectWithValue }) => {
        try {
            const res = await api.fetch('/purchase-order/summary')
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
