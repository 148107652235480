import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import get from 'lodash/get'
import includes from 'lodash/includes'
import last from 'lodash/last'
import split from 'lodash/split'
import React from 'react'
import StyledTitle from '../../components/styled_title/StyledTitle'

export const getCurrentRegisterStep = (userDetail) => {
    const user = get(userDetail, 'user', '')
    const isPemodal = user === 'pemodal'
    const isPenerbit = user === 'penerbit'
    const isEmailVerified = get(userDetail, 'isEmailVerified', false)
    const isMobileNumberVerified = get(userDetail, 'isMobileNumberVerified', false)
    const isTermsAndConditionsApproved = get(userDetail, 'isTermsAndConditionsApproved', false)
    const isRiskStatementApproved = get(userDetail, 'isRiskStatementApproved', false)

    let currentStep
    if (!isEmailVerified && !isTermsAndConditionsApproved && !isRiskStatementApproved) {
        currentStep = 2
    } else if (
        (isPemodal && !isMobileNumberVerified && !isTermsAndConditionsApproved && !isRiskStatementApproved) ||
        (isPenerbit && !isTermsAndConditionsApproved)
    ) {
        currentStep = 3
    } else if ((isPemodal && !isTermsAndConditionsApproved) || (isPenerbit && !isRiskStatementApproved)) {
        currentStep = 4
    } else if (isPemodal && !isRiskStatementApproved) {
        currentStep = 5
    }
    return currentStep
}

export const getRegisterData = (dark, pathname, isPemodal) => {
    const splitPathname = split(pathname, '/')
    let userType = isPemodal ? 'pemodal' : 'penerbit'
    const page = Number(last(splitPathname[3]))
    userType = Number(page) === 1 ? splitPathname[2] : userType
    const currentPage = (
        <AntdRow justify={isMobile() ? 'start' : 'end'}>
            <StyledTitle $dark={dark} level={4}>
                {page}/{isPemodal || userType === 'pemodal' ? 5 : 4}
            </StyledTitle>
        </AntdRow>
    )
    return {
        currentPage,
        page,
        userType,
    }
}

export const isValidUserType = (user) => includes(['pemodal', 'penerbit'], user)
