import { createAsyncThunk } from '@reduxjs/toolkit'
import * as request from '../../client/request'

export const sendVerificationEmail = createAsyncThunk('SEND_VERIFICATION_EMAIL', async (data, { rejectWithValue }) => {
    try {
        const res = await request.post('/verification/send-verification-email', data)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const sendVerificationMobileNumber = createAsyncThunk(
    'SEND_VERIFICATION_MOBILE_NUMBER',
    async (data, { rejectWithValue }) => {
        try {
            const res = await request.post('/verification/send-verification-mobile-number', data)
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const verifyEmail = createAsyncThunk('VERIFY_EMAIL', async (data, { rejectWithValue }) => {
    try {
        const res = await request.post('/verification/verify-email', data)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const verifyMobileNumber = createAsyncThunk('VERIFY_MOBILE_NUMBER', async (data, { rejectWithValue }) => {
    try {
        const res = await request.post('/verification/verify-mobile-number', data)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})
