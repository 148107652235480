import PropTypes from 'prop-types'
import React from 'react'
import StyledAntdModal from './StyledAntdModal'

const Modal = (props) => {
    const { cancelText, children, closable, footer, okText, onCancel, onOk, title, visible } = props
    return (
        <StyledAntdModal
            cancelText={cancelText}
            centered
            closable={closable}
            destroyOnClose
            footer={footer}
            maskClosable={false}
            okText={okText}
            onCancel={onCancel}
            onOk={onOk}
            title={title}
            visible={visible}
        >
            {children}
        </StyledAntdModal>
    )
}

Modal.propTypes = {
    cancelText: PropTypes.string,
    children: PropTypes.node,
    closable: PropTypes.bool,
    footer: PropTypes.node,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    title: PropTypes.node,
    visible: PropTypes.bool,
}

Modal.defaultProps = {
    cancelText: 'Batal',
    footer: null,
    okText: 'Oke',
}

export default Modal
