import filter from 'lodash/filter'
import map from 'lodash/map'
import React from 'react'

const htmlToString = (text) => (
    <span
        dangerouslySetInnerHTML={{
            __html: text,
        }}
    />
)

export const filterFaq = (faqs, category) =>
    map(filter(faqs, ['category', category]), (item) => ({
        title: htmlToString(item.question),
        content: htmlToString(item.answer),
    }))
