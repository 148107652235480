import { Table as AntdTable } from 'antd'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import React from 'react'
import { getTableCellTitle, getTableHeaderTitle } from '../../common/helper/table'

const keyMap = {
    ascend: 'asc',
    descend: 'desc',
}

const Table = (props) => {
    const {
        bordered,
        className,
        columns,
        components,
        dataSource,
        expandedRowKeys,
        expandedRowRender,
        loading,
        onChange,
        onExpand,
        onRow,
        rowExpandable,
        rowKey,
        rowSelection,
        scroll,
        showHeader,
        size,
        title,
    } = props

    const handleChange = (pagination, filters, sorter) => {
        let s = null
        if (!isUndefined(get(sorter, 'order'))) {
            s = sorter
            s.order = keyMap[s.order]
        }
        onChange(pagination, filters, s)
    }

    const newColumns = map(columns, (column) => {
        let newColumn = column
        const render = get(newColumn, 'render', noop)
        const title = getTableHeaderTitle(get(newColumn, 'title', ''))

        newColumn = Object.assign(newColumn, {
            title,
        })

        if (newColumn.dataIndex === 'actions') {
            newColumn = Object.assign(newColumn, { fixed: 'right' })
        } else {
            if (render === noop) {
                newColumn = Object.assign(newColumn, {
                    render: (text) => getTableCellTitle(text),
                })
            } else {
                newColumn = Object.assign(newColumn, {
                    render: (text) => getTableCellTitle(render(text)),
                })
            }
        }
        return newColumn
    })

    return (
        <AntdTable
            bordered={bordered}
            className={className}
            columns={newColumns}
            components={components}
            dataSource={dataSource}
            expandedRowKeys={expandedRowKeys}
            expandedRowRender={expandedRowRender}
            loading={loading}
            onChange={handleChange}
            onExpand={onExpand}
            onRow={onRow}
            pagination={false}
            rowExpandable={rowExpandable}
            rowKey={rowKey}
            rowSelection={rowSelection}
            scroll={scroll}
            showHeader={showHeader}
            size={size}
            title={title}
        />
    )
}

Table.propTypes = {
    bordered: PropTypes.bool,
    className: PropTypes.string,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            colSpan: PropTypes.number,
            dataIndex: PropTypes.string,
            defaultSortOrder: PropTypes.oneOf(['ascend', 'descend']),
            key: PropTypes.string,
            onCell: PropTypes.func,
            onHeaderCell: PropTypes.func,
            sorter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ).isRequired,
    components: PropTypes.object,
    dataSource: PropTypes.array,
    expandedRowKeys: PropTypes.arrayOf(PropTypes.string),
    expandedRowRender: PropTypes.func,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onExpand: PropTypes.func,
    onRow: PropTypes.func,
    rowExpandable: PropTypes.func,
    rowKey: PropTypes.string,
    rowSelection: PropTypes.any,
    scroll: PropTypes.object,
    showHeader: PropTypes.bool,
    size: PropTypes.oneOf(['default', 'middle', 'small']),
    title: PropTypes.func,
}

Table.defaultProps = {
    bordered: false,
    columns: [],
    dataSource: [],
    loading: false,
    onChange: noop,
    onExpand: noop,
    onRow: noop,
    rowKey: 'key',
    scroll: { x: 'max-content' },
    showHeader: true,
    size: 'middle',
}

export default Table
