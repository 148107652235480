import { Input as AntdInput } from 'antd'
import styled from 'styled-components'
import colors from '../../basic/colors'

const AntdInputSearch = AntdInput.Search
const StyledAntdInputSearch = styled(AntdInputSearch)`
    &&& {
        background-color: ${colors.black}00;
        border: none;
        color: ${(props) => (props.$dark ? 'white' : 'black')};

        ${(props) => (props.allowClear ? 'span.ant-input-affix-wrapper' : 'input.ant-input')} {
            border: 2px solid silver;
            border-right: none;
            box-shadow: none;
        }

        input.ant-input {
            border-radius: ${(props) => (props.allowClear ? '0px' : '20px 0px 0px 20px')};
            outline: none;
        }

        span.ant-input-wrapper.ant-input-group,
        span.ant-input-affix-wrapper.ant-input-affix-wrapper-sm {
            height: 30px;
        }

        span.ant-input-group-addon {
            background-color: white;
            border: 2px solid silver;
            border-left: none;
            border-radius: 0px 20px 20px 0px;
        }

        button.ant-btn.ant-btn-icon-only.ant-input-search-button {
            background-color: transparent;
            border: none;
            border-radius: 0px 20px 20px 0px;
            box-shadow: none;
            height: 20px;
            outline: none;
        }

        .anticon.anticon-search > svg {
            filter: invert(40%) sepia(70%) saturate(2302%) hue-rotate(223deg) brightness(101%) contrast(102%);
        }

        @media screen and (min-width: 768px) {
            span.ant-input-clear-icon {
                margin-right: -10px;
            }
            span.ant-input-wrapper.ant-input-group,
            span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
                height: 45px;
            }
        }
    }
`

export default StyledAntdInputSearch
