import React from 'react'
import { connect } from 'react-redux'
import Result from './Result'

const ResultContainer = (props) => <Result {...props} />

const mapStateToProps = (state) => ({
    isBankinfoExist: state.authentication.isBankinfoExist,
})

export default connect(mapStateToProps)(ResultContainer)
