import { default as AntdForm } from 'antd/lib/form'
import PropTypes from 'prop-types'
import React from 'react'

const Form = (props) => {
    const { children, initialValues, layout, onFinish, refInit } = props

    return (
        <AntdForm initialValues={initialValues} layout={layout} onFinish={onFinish} ref={refInit}>
            {children}
        </AntdForm>
    )
}

Form.propTypes = {
    children: PropTypes.node,
    initialValues: PropTypes.object,
    layout: PropTypes.string,
    onFinish: PropTypes.func,
    refInit: PropTypes.object,
}

Form.defaultProps = {
    layout: 'vertical',
}

export default Form
