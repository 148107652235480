import { default as AntdModal } from 'antd/lib/modal'
import styled from 'styled-components'

const StyledAntdModal = styled(AntdModal)`
    &&& {
        margin: 10px auto;
        width: 80% !important;

        button.ant-modal-close {
            margin-right: 10px !important;
            margin-top: 10px !important;
        }

        div.ant-modal-content {
            padding: 20px;
        }

        @media screen and (min-width: 768px) {
            width: 70% !important;
            div.ant-modal-content {
                padding: 50px;
            }
        }
        @media screen and (min-width: 1200px) {
            width: 60% !important;
            div.ant-modal-content {
                padding: 50px 100px;
            }
        }
    }
`

export default StyledAntdModal
