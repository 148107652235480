import PropTypes from 'prop-types'
import React from 'react'
import { NextArrow, PrevArrow } from './decotrators'
import { StyledCarousel } from './StyledComponents'

const Carousel = (props) => {
    const {
        adaptiveHeight,
        afterChange,
        arrows,
        autoplay,
        autoplaySpeed,
        beforeChange,
        className,
        content,
        dotPosition,
        dots,
        dotsColor,
        dotsMarginBottom,
        draggable,
        easing,
        effect,
        lazyLoad,
        refInit,
        rows,
        slidesToScroll,
        slidesToShow,
        speed,
        variableWidth,
    } = props

    return (
        <StyledCarousel
            $dotsColor={dotsColor}
            $dotsMarginBottom={dotsMarginBottom}
            adaptiveHeight={adaptiveHeight}
            afterChange={afterChange}
            arrows={arrows}
            autoplay={autoplay}
            autoplaySpeed={autoplaySpeed}
            beforeChange={beforeChange}
            className={className}
            dotPosition={dotPosition}
            dots={dots}
            draggable={draggable}
            easing={easing}
            effect={effect}
            lazyLoad={lazyLoad}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
            ref={refInit}
            rows={rows}
            slidesToScroll={slidesToScroll}
            slidesToShow={slidesToShow}
            speed={speed}
            variableWidth={variableWidth}
        >
            {content}
        </StyledCarousel>
    )
}

Carousel.propTypes = {
    adaptiveHeight: PropTypes.bool,
    afterChange: PropTypes.func,
    arrows: PropTypes.bool,
    autoplay: PropTypes.bool,
    autoplaySpeed: PropTypes.number,
    beforeChange: PropTypes.func,
    className: PropTypes.string,
    content: PropTypes.node.isRequired,
    dotPosition: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    dots: PropTypes.bool,
    dotsColor: PropTypes.string,
    dotsMarginBottom: PropTypes.number,
    draggable: PropTypes.bool,
    easing: PropTypes.string,
    effect: PropTypes.oneOf(['scrollx', 'fade']),
    lazyLoad: PropTypes.bool,
    nextArrow: PropTypes.node,
    prevArrow: PropTypes.node,
    refInit: PropTypes.func,
    rows: PropTypes.number,
    slidesToScroll: PropTypes.number,
    slidesToShow: PropTypes.number,
    speed: PropTypes.number,
    variableWidth: PropTypes.bool,
}

Carousel.defaultProps = {
    adaptiveHeight: false,
    draggable: true,
    rows: 1,
    slidesToScroll: 1,
    slidesToShow: 1,
}
export default Carousel
