import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import getUserData from '../../utility/getUserData'
import * as authenticationAPI from './authenticationAPI'

const initialState = {
    authToken: '',
    colorSelector: '',
    customErrorMessage: '',
    email: '',
    failedLoginAttempts: 0,
    id: null,
    isActionLoading: false,
    isActionSuccess: false,
    isActive: false,
    isBankinfoExist: false,
    isDetailExist: false,
    isDigisignActivated: false,
    isEmailVerified: false,
    isError: false,
    isLoading: false,
    isLoadingAuthenticate: false,
    isMobileNumberVerified: false,
    isRiskStatementApproved: false,
    isTermsAndConditionsApproved: false,
    isTokenExpired: false,
    isValidUser: false,
    kycStatus: '',
    mobileNumber: '',
    termsAndConditionsApprovalDate: null,
    profilePictureUrl: '',
    riskStatementApprovalDate: null,
    temporaryEmail: '',
    temporaryMobileNumber: '',
    user: '',
    username: '',
}

export const { authenticate, forgotPassword, reauthenticate, refetchUser, resetPassword } = authenticationAPI

const authentication = createSlice({
    name: 'AUTHENTICATION',
    initialState,
    reducers: {
        clearCustomErrorMessage: (state) => Object.assign(state, { customErrorMessage: '', failedLoginAttempts: 0 }),
        clearTemporaryData: (state) => Object.assign(state, { temporaryEmail: '', temporaryMobileNumber: '' }),
        logout: () => initialState,
        resetAction: (state) => Object.assign(state, { isActionLoading: false, isActionSuccess: false }),
        setTemporaryEmail: (state, action) => Object.assign(state, { temporaryEmail: action.payload }),
        setTemporaryMobileNumber: (state, action) => Object.assign(state, { temporaryMobileNumber: action.payload }),
        tokenExpired: (state) => Object.assign(state, { isTokenExpired: true }),
    },
    extraReducers: (builder) => {
        builder.addCase(authenticate.pending, (state) =>
            Object.assign(state, {
                customErrorMessage: '',
                isLoadingAuthenticate: true,
                isTokenExpired: false,
            })
        )
        builder.addCase(authenticate.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            const newState = getUserData(state, data)
            return Object.assign(state, newState, {
                authToken: data.token,
                customErrorMessage: '',
                isLoadingAuthenticate: false,
            })
        })
        builder.addCase(authenticate.rejected, (state, action) => {
            const customErrorMessage = get(action, 'customErrorMessage', '')
            const failedLoginAttempts = get(action, 'failedLoginAttempts', 0)
            return Object.assign(state, {
                customErrorMessage,
                failedLoginAttempts,
                isLoadingAuthenticate: false,
            })
        })
        builder.addCase(forgotPassword.rejected, (state, action) => {
            const customErrorMessage = get(action, 'customErrorMessage', '')
            return Object.assign(state, {
                customErrorMessage,
                isActionLoading: false,
                isError: true,
            })
        })
        builder.addCase(reauthenticate.pending, (state) =>
            Object.assign(state, {
                isLoading: true,
            })
        )
        builder.addCase(reauthenticate.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                authToken: data.token,
                isLoading: false,
                isTokenExpired: false,
            })
        })
        builder.addCase(reauthenticate.rejected, (state) =>
            Object.assign(state, {
                isLoading: false,
            })
        )
        builder.addCase(refetchUser.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(refetchUser.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            const newState = getUserData(state, data)
            return Object.assign(state, newState)
        })
        builder.addCase(refetchUser.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
        builder.addCase(resetPassword.rejected, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true,
            })
        )
        builder.addMatcher(isAnyOf(forgotPassword.pending, resetPassword.pending), (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
            })
        )
        builder.addMatcher(isAnyOf(forgotPassword.fulfilled, resetPassword.fulfilled), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
    },
})

export const {
    clearCustomErrorMessage,
    clearTemporaryData,
    logout,
    resetAction,
    setTemporaryEmail,
    setTemporaryMobileNumber,
    tokenExpired,
} = authentication.actions

const { reducer } = authentication
export default reducer
