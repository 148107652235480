import clone from 'lodash/clone'
import get from 'lodash/get'
import map from 'lodash/map'
import React from 'react'
import EyeIcon from '../../components/eye_icon/EyeIcon'
import getRenderStatusPurchaseOrder from '../../utility/getRenderStatusPurchaseOrder'

const rowActions = (index, handleActions, record) => {
    let rowActions = [
        {
            label: 'Lihat',
            onClick: () => handleActions(index, 'view'),
            type: 'custom',
        },
        {
            label: 'Chat',
            onClick: () => handleActions(index, 'chat'),
            type: 'custom',
        },
    ]

    const isAllowedToCancel = get(record, 'isAllowedToCancel', '')
    if (isAllowedToCancel) {
        rowActions.push({
            label: 'Batalkan',
            onClick: () => handleActions(index, 'cancel'),
            type: 'custom',
        })
    }

    const isAllowedToPay = get(record, 'isAllowedToPay', '')
    if (isAllowedToPay) {
        rowActions.push({
            label: 'Bayar',
            onClick: () => handleActions(index, 'pay'),
            type: 'custom',
        })
    }

    return rowActions
}

const columns = (tooltipActions, renderActions) => [
    {
        dataIndex: 'noDoc',
        title: 'Nomor',
        width: 100,
    },
    {
        dataIndex: 'product',
        render: (object) => get(object, 'type', ''),
        title: 'Tipe',
        width: 70,
    },
    {
        dataIndex: 'product',
        render: (object) => {
            const name = get(object, 'name', '')
            const slug = get(object, 'slug', '')
            return (
                <>
                    <EyeIcon route={`/product/${slug}`} /> {name}
                </>
            )
        },
        title: 'Produk',
        width: 250,
    },
    {
        dataIndex: 'totalPayment',
        title: 'Total Pembayaran',
        width: 200,
    },
    {
        dataIndex: 'createdAt',
        title: 'Tanggal',
        width: 120,
    },
    {
        render: (object) => getRenderStatusPurchaseOrder(object),
        title: 'Status',
        width: 150,
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        title: tooltipActions,
        width: 120,
    },
]

const dataSource = (data) =>
    map(data, (item, key) =>
        Object.assign(clone(item), {
            key: String(key),
        })
    )

export { columns, dataSource, rowActions }
