import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as cartSlice from '../features/cart/cartSlice'
import * as digisignSlice from '../features/digisign/digisignSlice'
import * as inboxSlice from '../features/inbox/inboxSlice'
import PurchaseOrderForm from './PurchaseOrderForm'

let PurchaseOrderFormContainer = (props) => <PurchaseOrderForm {...props} />

const mapStateToProps = (state, props) => ({
    id: state.authentication.id,
    cart: state.cart.cart,
    digisignDocumentUrl: state.digisign.digisignDocumentUrl,
    inboxes: state.inbox.inboxes,
    initialValues: props.newPurchaseOrder,
    isActionLoading: state.digisign.isActionLoading,
    isDigisignActivated: state.authentication.isDigisignActivated,
    isKycStatusSuccess: state.authentication.isKycStatusSuccess,
    isKycStatusVerified: state.authentication.isKycStatusVerified,
})

const mapDispatchToProps = {
    clearDigisign: digisignSlice.clearDigisign,
    getInboxes: inboxSlice.getInboxes,
    signDocument: digisignSlice.signDocument,
    updateCart: cartSlice.updateCart,
}

PurchaseOrderFormContainer = reduxForm({
    enableReinitialize: true,
    form: 'purchaseOrder',
})(PurchaseOrderFormContainer)

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderFormContainer)
