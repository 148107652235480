import isFunction from 'lodash/isFunction'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledInlineTitle } from '../../components/StyledComponents'
import { parseQueryString } from '../../utility/url'
import StyledButton from './StyledButton'

const BackButton = (props) => {
    const { dark, icon, lastLocation, location, onClick, route, value } = props
    const navigate = useNavigate()
    const queries = parseQueryString(location)

    const handleClick = () => {
        //run any function that pass from client
        if (isFunction(onClick)) {
            onClick()
        }

        // first: check if there is params in url
        if (queries.backLink) {
            navigate(queries.backLink)
            return
        }

        // second: check if we can go back
        if (lastLocation) {
            navigate(-1)
        } else {
            navigate(route)
        }
    }

    const valueTitle = (
        <StyledInlineTitle $dark={dark} level={5}>
            {value || queries.backText || ''}
        </StyledInlineTitle>
    )

    return (
        <StyledButton
            $dark={dark}
            icon={icon}
            name='back-button'
            noStyle
            onClick={handleClick}
            type='link'
            value={valueTitle}
        />
    )
}

BackButton.propTypes = {
    dark: PropTypes.bool,
    icon: PropTypes.string,
    lastLocation: PropTypes.bool,
    location: PropTypes.object,
    onClick: PropTypes.func,
    route: PropTypes.string,
    value: PropTypes.string,
}

BackButton.defaultProps = {
    icon: 'LeftOutlined',
    value: 'Kembali',
}

export default BackButton
