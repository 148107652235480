import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import colors from '../basic/colors'
import { StyledAntdSpace } from '../components/StyledComponents'
import StyledParagraph from '../components/styled_paragraph/StyledParagraph'
import StyledSpan from '../components/styled_span/StyledSpan'
import StyledTitle from '../components/styled_title/StyledTitle'
import { isProductionEnv } from '../constants/constants'
import { YEAR } from '../constants/formats'
import * as socialMediaSlice from '../features/social_media/socialMediaSlice'
import { useGetSocialMediasQuery } from '../services/api'
import getMoneypoolLogo from '../utility/getMoneypoolLogo'
import getServerStoreState from '../utility/getServerStoreState'
import { renderLink } from '../utility/renderLinkComp'
import { menus, statements } from './helper'
import SwitchAccountContainer from './modal/SwitchAccountContainer'
import {
    StyledFooter,
    StyledFooterContainer,
    StyledFooterIcon,
    StyledLogo,
    StyledOrderedList,
    StyledTextRight,
} from './StyledComponents'

const Footer = (props) => {
    const {
        dark,
        getSocialMedias,
        isPemodal,
        isPenerbit,
        isSocialMediaLocalError,
        isSocialMediaLocalLoading,
        navigate,
        onLogout,
        socialMedias,
    } = props

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [to, setTo] = useState('')

    //for SSR only
    const { data, isError, isLoading, refetch } = useGetSocialMediasQuery()

    useEffect(() => {
        if (isProductionEnv) {
            //for SSR only
            refetch()
        } else {
            getSocialMedias()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let currentSocialMedias = socialMedias
    let currentError = isSocialMediaLocalError
    let currentLoading = isSocialMediaLocalLoading
    //for SSR only
    if (isProductionEnv) {
        currentSocialMedias = get(data, 'socialMedias', [])
        currentError = isError
        currentLoading = isLoading
    }

    const moneypoolLogoImage = <StyledLogo src={getMoneypoolLogo(dark)} />
    const thisYear = moment().format(YEAR)

    const handleCancel = () => setIsModalOpen(false)

    const handleClick = (item) => (e) => {
        const { isCheckUserType, isPenerbitFeature, to } = item
        if (isCheckUserType && isPemodal && isPenerbitFeature) {
            e.preventDefault()
            setIsModalOpen(true)
            setTo(to)
        }
    }

    const renderCopyright = () => (
        <AntdRow>
            <AntdCol span={24}>
                <StyledTextRight>
                    <AntdSpace direction='vertical' size={0}>
                        <div>{renderSocialMedia()}</div>
                        <StyledParagraph $dark={dark} color={colors.graniteGray}>
                            Copyright © {thisYear}. All rights reserved
                        </StyledParagraph>
                        <StyledSpan $dark={dark} color={colors.graniteGray}>
                            <a href='https://movingbytesdigital.com' rel='noreferrer' target='_blank'>
                                by movingbytes
                            </a>
                        </StyledSpan>
                    </AntdSpace>
                </StyledTextRight>
            </AntdCol>
        </AntdRow>
    )

    const renderDisclaimer = () => (
        <AntdRow>
            <AntdCol span={24}>
                <StyledParagraph $dark={dark} $textAlign='justify' color={colors.graniteGray}>
                    Moneypool.id sebagai Penyelenggara dengan ini menyatakan bahwa :
                    <StyledOrderedList>
                        {map(statements, (item, key) => (
                            <li key={key}>{item}</li>
                        ))}
                    </StyledOrderedList>
                </StyledParagraph>
            </AntdCol>
        </AntdRow>
    )

    const renderMenu = () => (
        <AntdRow gutter={[20, 20]}>
            <AntdCol span={24}>{renderLink({ to: '/' }, moneypoolLogoImage)}</AntdCol>
            {map(menus, (item, key) => (
                <AntdCol key={key} md={8} sm={key === size(menus) - 1 ? 24 : 12} xs={key === size(menus) - 1 ? 24 : 12}>
                    <StyledTitle $dark={dark} level={4}>
                        {item.title}
                    </StyledTitle>
                    {map(item.content, (item, key) => (
                        <StyledTitle $dark={dark} key={key} level={5}>
                            {renderLink({ onClick: handleClick(item), to: item.to }, item.title)}
                        </StyledTitle>
                    ))}
                </AntdCol>
            ))}
        </AntdRow>
    )

    const renderSocialMedia = () => {
        if (currentError) {
            return <Fragment>Oh tidak, ada kesalahan</Fragment>
        }
        if (currentLoading) {
            return <Fragment>Loading...</Fragment>
        }
        if (isEmpty(currentSocialMedias)) {
            return null
        }

        return map(currentSocialMedias, (item, key) => (
            <a href={item.url} key={key}>
                <StyledFooterIcon $dark={dark} src={item.imageUrl} />
                {key < currentSocialMedias.length - 1 && (
                    <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>
                )}
            </a>
        ))
    }

    return (
        <StyledFooter $dark={dark}>
            <StyledFooterContainer $dark={dark}>
                <StyledAntdSpace direction='vertical' size='large'>
                    {renderMenu()}
                    <AntdSpace direction='vertical'>
                        {renderDisclaimer()}
                        {renderCopyright()}
                    </AntdSpace>
                </StyledAntdSpace>
            </StyledFooterContainer>
            <SwitchAccountContainer
                isModalOpen={isModalOpen}
                isPemodal={isPemodal}
                isPenerbit={isPenerbit}
                navigate={navigate}
                onCancel={handleCancel}
                onLogout={onLogout}
                to={to}
            />
        </StyledFooter>
    )
}

Footer.propTypes = {
    dark: PropTypes.bool,
    getSocialMedias: PropTypes.func,
    isPemodal: PropTypes.bool,
    isPenerbit: PropTypes.bool,
    isSocialMediaLocalError: PropTypes.bool,
    isSocialMediaLocalLoading: PropTypes.bool,
    navigate: PropTypes.func,
    onLogout: PropTypes.func,
    socialMedias: PropTypes.array,
}

const mapStateToProps = (state) => {
    const updatedState = getServerStoreState(state)

    return {
        socialMedias: updatedState.socialMedia.socialMedias,
        isSocialMediaLocalError: updatedState.socialMedia.isError,
        isSocialMediaLocalLoading: updatedState.socialMedia.isLoading,
    }
}

const mapDispatchToProps = {
    getSocialMedias: socialMediaSlice.getSocialMedias,
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
