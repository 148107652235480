import PropTypes from 'prop-types'
import React from 'react'
import { FormItem, InputSpinner } from '../../../antdcomponents'

const ReduxFormInputSpinner = (props) => {
    const { componentProps, dark, formItemProps, input, label, meta } = props
    const { onChange, value, name } = input
    const { touched, error } = meta
    const isError = touched && !!error

    const newValue = value || 0

    return (
        <FormItem
            {...formItemProps}
            dark={dark}
            hasFeedback={isError}
            help={isError && error}
            label={label}
            validateStatus={isError ? 'error' : undefined}
        >
            <InputSpinner {...componentProps} dark={dark} name={name} onChange={onChange} value={newValue} />
        </FormItem>
    )
}

ReduxFormInputSpinner.propTypes = {
    componentProps: PropTypes.object,
    dark: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    max: PropTypes.number,
    meta: PropTypes.object.isRequired,
}

export default ReduxFormInputSpinner
