import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import round from 'lodash/round'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Fragment, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { BackButton, Tooltip } from '../antdcomponents'
import { isAuthorizedUser } from '../common/helper/authorization'
import HelmetMeta from '../components/HelmetMeta'
import { StyledAntdPagination, StyledAntdSpace, StyledSection } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import formatPrice from '../utility/formatPrice'
import * as config from './config'
import { StyledTable } from './StyledComponents'

const Portfolio = (props) => {
    const { clearProduct, getPortfolio, isLoading, products, totalProducts } = props

    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, pathname, search } = useOutletContext()

    const query = qs.parse(search, { ignoreQueryPrefix: true })

    const tooltipMoneypoolku = <Tooltip title='Portofolio total kepemilikan dana dan dividen diterima' />

    const tooltipSettlementDate = <Tooltip title='Tanggal kepemilikan sebenarnya dari pendanaan sukses oleh pemodal' />

    const tooltipOwnershipTotal = (
        <Tooltip title='Value Kepemilikan adalah jumlah seluruh saham dan obligasi yang dimiliki setelah perdagangan efek selesai dan berhasil' />
    )

    const tooltipInterestTotal = (
        <Tooltip title='Value Keuntungan adalah jumlah seluruh dividen dan kupon yang didapat' />
    )

    const totalOwnership = reduce(products, (sum, item) => sum + get(item, 'purchaseOrderSubtotal', 0), 0)
    const totalProfit =
        totalOwnership + reduce(products, (sum, item) => sum + get(item, 'purchaseOrderDividendTotal', 0), 0)

    const dividendPercentage = round((totalProfit - totalOwnership) / totalOwnership, 2)
    const pemodalSummary = [
        {
            title: (
                <AntdSpace>
                    <Fragment>Total Kepemilikan</Fragment>
                    {tooltipOwnershipTotal}
                </AntdSpace>
            ),
            value: `Rp ${formatPrice(totalOwnership)}`,
        },
        {
            title: (
                <AntdSpace>
                    <Fragment>Total Keuntungan</Fragment>
                    {tooltipInterestTotal}
                </AntdSpace>
            ),
            value: `Rp ${formatPrice(totalProfit)} (+${!isNaN(dividendPercentage) ? dividendPercentage : 0}%)`,
        },
    ]

    const limit = 5
    const page = get(query, 'page', 1)

    useEffect(() => {
        const fetchPortfolio = () => {
            const offset = (page - 1) * limit
            getPortfolio({ limit, offset, options: {} })
        }
        fetchPortfolio()
        return () => {
            dispatch(clearProduct())
        }
    }, [clearProduct, dispatch, getPortfolio, limit, page])

    const handleChange = (page) => {
        if (page === 1) {
            navigate(`/portfolio`)
        } else {
            navigate(`/portfolio?page=${page}`)
        }
    }

    const metaTitle = 'Moneypoolku'

    const renderTitle = () => (
        <center>
            <AntdSpace>
                <StyledTitle $dark={dark} level={2}>
                    {metaTitle}
                </StyledTitle>
                {tooltipMoneypoolku}
            </AntdSpace>
        </center>
    )

    const renderPemodalSummary = () => (
        <StyledAntdSpace direction='vertical'>
            {map(pemodalSummary, (item, key) => (
                <AntdRow align='bottom' key={key}>
                    <AntdCol md={4} sm={24} xs={24}>
                        <StyledTitle $dark={dark} level={5}>
                            {item.title}
                        </StyledTitle>
                    </AntdCol>
                    <AntdCol md={{ offset: 2, span: 18 }} sm={{ offset: 0, span: 24 }} xs={{ offset: 0, span: 24 }}>
                        <StyledTitle $dark={dark} level={4}>
                            {item.value}
                        </StyledTitle>
                    </AntdCol>
                </AntdRow>
            ))}
        </StyledAntdSpace>
    )

    const renderPortfolio = () => (
        <Fragment>
            <StyledTable
                columns={config.columns(tooltipSettlementDate)}
                dataSource={config.dataSource(products)}
                loading={isLoading}
            />
            {!isEmpty(products) && (
                <AntdRow justify='end'>
                    <StyledAntdPagination
                        $dark={dark}
                        current={Number(page)}
                        defaultPageSize={limit}
                        onChange={handleChange}
                        total={totalProducts}
                    />
                </AntdRow>
            )}
        </Fragment>
    )

    if (!isLoggedIn) {
        navigate('/login')
        return null
    }

    if (!isAuthorizedUser(isPemodal, isPenerbit, pathname)) {
        navigate('/profile')
        return null
    }

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <StyledAntdSpace direction='vertical' size='large'>
                <BackButton route='/profile' />
                {renderTitle()}
                {renderPemodalSummary()}
                {renderPortfolio()}
            </StyledAntdSpace>
        </StyledSection>
    )
}

Portfolio.propTypes = {
    clearProduct: PropTypes.func,
    getPortfolio: PropTypes.func,
    isLoading: PropTypes.bool,
    products: PropTypes.array,
    totalProducts: PropTypes.number,
}

export default Portfolio
