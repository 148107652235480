import { Table } from '../antdcomponents'
import styled from 'styled-components'

export const StyledTable = styled(Table)`
    &&& {
        tr.ant-table-placeholder {
            text-align: left !important;
        }
    }
`
