import React, { Fragment } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import Layout from './Layout'

// main components
import About from './about/About'
import ContactUsContainer from './contact_us/ContactUsContainer'
import DigisignActivationStatusContainer from './profile/pemodal/DigisignActivationStatusContainer'
import EditProfileContainer from './profile/EditProfileContainer'
import FaqContainer from './faq/FaqContainer'
import FavoriteContainer from './favorite/FavoriteContainer'
import ForgotPassword from './authentication/forgot_password/ForgotPassword'
import FundContainer from './fund/FundContainer'
import FundReportContainer from './product/FundReportContainer'
import Home from './home/Home'
import InboxContainer from './inbox/InboxContainer'
import LoginContainer from './authentication/login/LoginContainer'
import NoMatch from './no_match/NoMatch'
import PenerbitDetailContainer from './profile/penerbit/PenerbitDetailContainer'
import PenerbitDocumentContainer from './profile/penerbit/PenerbitDocumentContainer'
import PortfolioContainer from './portfolio/PortfolioContainer'
import PrivacyPolicy from './privacy_policy/PrivacyPolicy'
import TermsAndConditionsApprovalContainer from './authentication/register/TermsAndConditionsApprovalContainer'
import ProductContainer from './product/ProductContainer'
import ProductDetailContainer from './product/ProductDetailContainer'
import ProfileContainer from './profile/ProfileContainer'
import ProfileFormContainer from './authentication/register/ProfileFormContainer'
import PurchaseOrderContainer from './purchase_order/PurchaseOrderContainer'
import PurchaseOrderResultContainer from './purchase_order/ResultContainer'
import RegisterContainer from './authentication/register/RegisterContainer'
import ResetPassword from './authentication/reset_password/ResetPassword'
import RiskStatementContainer from './risk_statement/RiskStatementContainer'
import RiskStatementApprovalContainer from './authentication/register/RiskStatementApprovalContainer'
import SuccessContainer from './authentication/register/SuccessContainer'
import TermsAndConditionsContainer from './terms_and_conditions/TermsAndConditionsContainer'
import TransactionHistoryContainer from './transaction_history/TransactionHistoryContainer'
import VerifyEmailContainer from './verification/VerifyEmailContainer'
import VerifyEmailOtpContainer from './authentication/register/VerifyEmailOtpContainer'
import VerifyMobileNumberOtpContainer from './authentication/register/VerifyMobileNumberOtpContainer'

const RouteHandler = () => {
    return (
        <Routes>
            {/* Routes nest inside one another. Nested route paths build upon
                parent route paths, and nested route elements render inside
                parent route elements. See the note about <Outlet> below. */}
            <Route element={<Layout />} path='/'>
                {/* public */}
                <Route element={<About />} path='about' />
                <Route element={<ContactUsContainer />} path='contact-us' />
                <Route element={<FaqContainer />} path='faq' />
                <Route element={<ForgotPassword />} path='forgot-password' />
                <Route element={<FundReportContainer />} path='fund-report/:slug' />
                <Route element={<Home />} index />
                <Route element={<LoginContainer />} path='login' />
                <Route element={<Navigate replace to='pemodal' />} path='terms-and-conditions' />
                <Route element={<PrivacyPolicy />} path='privacy-policy' />
                <Route element={<ProductContainer />} path='products' />
                <Route element={<ProductDetailContainer />} path='product/:slug' />
                <Route element={<ProfileFormContainer />} path='register/:user/step1' />
                <Route element={<RegisterContainer />} path='register' />
                <Route element={<RiskStatementContainer />} path='risk-statement' />
                <Route element={<TermsAndConditionsContainer />} path='terms-and-conditions/:user' />

                {/* need authenticate */}
                <Route element={<DigisignActivationStatusContainer />} path='digisign-activation-status' />
                <Route element={<EditProfileContainer />} path='profile/:type' />
                <Route element={<EditProfileContainer />} path='profile/screening/:mode' />
                <Route element={<FavoriteContainer />} path='favorite' />
                <Route element={<Fragment></Fragment>} path='secondary-market' />
                <Route element={<FundContainer />} path='funds' />
                <Route element={<InboxContainer />} path='inbox' />
                <Route element={<InboxContainer />} path='inbox/:id' />
                <Route element={<PenerbitDetailContainer />} path='profile/screening-detail' />
                <Route element={<PenerbitDocumentContainer />} path='profile/document' />
                <Route element={<PortfolioContainer />} path='portfolio' />
                <Route element={<ProfileContainer />} path='profile' />
                <Route element={<PurchaseOrderContainer />} path='/order' />
                <Route element={<PurchaseOrderResultContainer />} path='order/failed' />
                <Route element={<PurchaseOrderResultContainer />} path='order/success/:noDoc' />
                <Route element={<ResetPassword />} path='reset-password' />
                <Route element={<RiskStatementApprovalContainer />} path='register/pemodal/step5' />
                <Route element={<RiskStatementApprovalContainer />} path='register/penerbit/step4' />
                <Route element={<SuccessContainer />} path='register/success' />
                <Route element={<TermsAndConditionsApprovalContainer />} path='register/pemodal/step4' />
                <Route element={<TermsAndConditionsApprovalContainer />} path='register/penerbit/step3' />
                <Route element={<TransactionHistoryContainer />} path='transaction-histories' />
                <Route element={<VerifyEmailOtpContainer />} path='profile/email' />
                <Route element={<VerifyEmailOtpContainer />} path='register/:user/step2' />
                <Route element={<VerifyMobileNumberOtpContainer />} path='profile/phone' />
                <Route element={<VerifyMobileNumberOtpContainer />} path='register/pemodal/step3' />

                {/* email related */}
                <Route element={<VerifyEmailContainer />} path='verify_email' />

                {/* Using path="*"" means "match anything", so this route
                    acts like a catch-all for URLs that we don't have explicit
                    routes for. */}
                <Route element={<NoMatch />} path='404' />
                <Route element={<Navigate replace to='404' />} path='*' />
            </Route>
        </Routes>
    )
}

export default RouteHandler
