import { default as AntdSpin } from 'antd/lib/spin'
import PropTypes from 'prop-types'
import React from 'react'
import StyledLoaderContainer from './StyledLoaderContainer'

const Loader = (props) => {
    const { noPadding } = props

    return (
        <StyledLoaderContainer $noPadding={noPadding}>
            <center>
                <AntdSpin />
            </center>
        </StyledLoaderContainer>
    )
}

Loader.propTypes = {
    noPadding: PropTypes.bool,
}

export default Loader
