import PropTypes from 'prop-types'
import React from 'react'
import { FormItem, TextArea } from '../../../antdcomponents'

const ReduxFormTextArea = (props) => {
    const { componentProps, dark, disabled, formItemProps, input, label, meta, placeholder, size } = props
    const { onChange, value, name } = input
    const { touched, error } = meta
    const isError = touched && !!error

    return (
        <FormItem
            {...formItemProps}
            dark={dark}
            help={isError && error}
            label={label}
            name={name}
            validateStatus={isError ? 'error' : undefined}
        >
            <TextArea
                {...componentProps}
                dark={dark}
                disabled={disabled}
                input={input}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                size={size}
                value={value}
            />
        </FormItem>
    )
}

ReduxFormTextArea.propTypes = {
    componentProps: PropTypes.object,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.string,
}

export default ReduxFormTextArea
