import React from 'react'
import { connect } from 'react-redux'
import * as authenticationSlice from '../../features/authentication/authenticationSlice'
import * as digisignSlice from '../../features/digisign/digisignSlice'
import DigisignActivationStatus from './DigisignActivationStatus'

const DigisignActivationStatusContainer = (props) => <DigisignActivationStatus {...props} />

const mapStateToProps = (state) => ({
    email: state.authentication.email,
    isDigisignActivated: state.authentication.isDigisignActivated,
    isLoading: state.authentication.isLoading,
})

const mapDispatchToProps = {
    clearDigisign: digisignSlice.clearDigisign,
    digisignCallback: digisignSlice.digisignCallback,
    refetchUser: authenticationSlice.refetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(DigisignActivationStatusContainer)
