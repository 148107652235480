import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as authenticationSlice from '../../features/authentication/authenticationSlice'
import { confirmPassword } from '../../utility/formValidation'
import scrollToViewFormError from '../../utility/scrollToViewFormError'
import ResetPasswordForm from './ResetPasswordForm'

let ResetPasswordFormContainer = (props) => <ResetPasswordForm {...props} />

const validate = (values) => {
    let errors = {}
    errors = Object.assign(errors, confirmPassword(values))
    return errors
}

const mapStateToProps = (state) => ({
    isActionLoading: state.authentication.isActionLoading,
    isActionSuccess: state.authentication.isActionSuccess,
})

const mapDispatchToProps = {
    resetPassword: authenticationSlice.resetPassword,
}

ResetPasswordFormContainer = reduxForm({
    form: 'resetPassword',
    onSubmitFail: (errors) => scrollToViewFormError(errors),
    validate,
})(ResetPasswordFormContainer)

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordFormContainer)
