import { default as AntdTypography } from 'antd/lib/typography'
import styled from 'styled-components'
import colors from '../../basic/colors'
import modifyVars from '../../basic/modifyVars'

const getColor = (color, dark) => {
    if (dark) {
        return 'white'
    } else {
        if (color) {
            return color
        } else {
            return colors.persianIndigo
        }
    }
}

const AntdTitle = AntdTypography.Title
const StyledTitle = styled(AntdTitle)`
    &&& {
        &,
        a {
            font-style: ${modifyVars.headingStyle.fontStyle};
            font-weight: ${modifyVars.headingStyle.fontWeight};
            font-size: ${(props) => modifyVars.headingStyle.fontSizeMobile[props.level - 1]};
            font-family: ${(props) => modifyVars.headingStyle.fontFamily[props.level - 1]};
            letter-spacing: ${modifyVars.headingStyle.letterSpacing};
            line-height: ${(props) => props.$lineHeight};
            margin: 0px;
            color: ${(props) => getColor(props.color, props.$dark)};

            ${(props) =>
                props.$textAlign &&
                `
                text-align: ${props.$textAlign};
            `}

            @media screen and (min-width: 768px) {
                font-size: ${(props) => modifyVars.headingStyle.fontSizeDesktop[props.level - 1]};
            }
        }
    }
`

export default StyledTitle
