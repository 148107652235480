import PropTypes from 'prop-types'
import React, { useState } from 'react'
import CheckboxGroup from '../../checkbox_group/CheckboxGroup'
import { StyledFormItem } from './StyledComponents'

const ReduxFormCheckboxGroup = (props) => {
    const { componentProps, dark, disabled, formItemProps, input, label, meta, options } = props
    const { extra } = formItemProps || {}
    const { onChange, value, name } = input
    const { touched, error } = meta
    const [isTouched, setIsTouched] = useState(false)
    const isError = (touched || isTouched) && !!error

    const handleBlur = () => setIsTouched(true)

    return (
        <StyledFormItem
            {...formItemProps}
            dark={dark}
            extra={extra}
            help={isError && error}
            label={label}
            validateStatus={isError ? 'error' : undefined}
        >
            <CheckboxGroup
                {...componentProps}
                dark={dark}
                disabled={disabled}
                name={name}
                onBlur={handleBlur}
                onChange={onChange}
                options={options}
                value={value || []}
            />
        </StyledFormItem>
    )
}

ReduxFormCheckboxGroup.propTypes = {
    componentProps: PropTypes.object,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelColor: PropTypes.string,
    meta: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            required: PropTypes.bool,
            value: PropTypes.string.isRequired,
        })
    ),
}

export default ReduxFormCheckboxGroup
