const colors = require('../basic/colors')
const avatarColors = [
    colors.caribbeanGreen,
    colors.blueberry,
    colors.purpleHeart,
    colors.persianIndigo,
    colors.persiaRose,
    colors.salmon,
]
let randomIndexProfile = Math.floor(Math.random() * avatarColors.length)

export const avatarSelectorProfile = () => {
    return avatarColors[randomIndexProfile]
}

export const avatarSelectorInbox = (value) => {
    if (value) {
        let randomIndexInbox = Math.floor(Math.random() * avatarColors.length)
        return avatarColors[randomIndexInbox]
    }
}
