import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import React, { useState } from 'react'
import HelmetMeta from '../../components/HelmetMeta'
import { StyledSection } from '../../components/StyledComponents'
import ForgotPasswordFormContainer from './ForgotPasswordFormContainer'
import Success from './Success'

const ForgotPassword = () => {
    const [pageNumber, setPageNumber] = useState(0)
    const metaTitle = 'Forgot Password'

    const forgotPasswordFormContainer = <ForgotPasswordFormContainer setPageNumber={setPageNumber} title={metaTitle} />
    const success = <Success />
    const pages = [forgotPasswordFormContainer, success]

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <AntdRow justify='center'>
                <AntdCol>{pages[pageNumber]}</AntdCol>
            </AntdRow>
        </StyledSection>
    )
}

export default ForgotPassword
