import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import first from 'lodash/first'
import { getRegisterData } from '../../common/helper/authentication'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field } from 'redux-form'
import { Button, Form, ReduxFormCheckboxGroup } from '../../antdcomponents'
import { StyledAntdSpace, StyledSection } from '../../components/StyledComponents'
import { required } from '../../utility/formValidation'
import RiskStatementText from '../../components/risk_statement_text/RiskStatementText'
import { useOutletContext } from 'react-router-dom'
import moment from 'moment'
import { DATE_HOUR_SLASH_FORMAT } from '../../constants/formats'
import HelmetMeta from '../../components/HelmetMeta'

const RiskStatementApproval = (props) => {
    const {
        clearPemodal,
        clearPenerbit,
        currentRegisterStep,
        email,
        handleSubmit,
        id,
        isActionLoading,
        isActionSuccess,
        isLoading,
        isValidUser,
        refetchUser,
        updatePemodal,
        updatePenerbit,
        user,
    } = props
    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, pathname } = useOutletContext()
    const { currentPage, userType } = getRegisterData(dark, pathname, isPemodal)
    const [toProfile, setToProfile] = useState(true)

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            refetchUser({ email })
        }
        return () => {
            dispatch(clearPemodal())
            dispatch(clearPenerbit())
        }
    }, [clearPemodal, clearPenerbit, dispatch, email, isActionLoading, isActionSuccess, refetchUser])

    const handleFinish = (values) => {
        const { isRiskStatementApproved } = values
        const data = {
            id,
            isRiskStatementApproved: first(isRiskStatementApproved) === 'true',
            riskStatementApprovalDate: moment.utc().format(DATE_HOUR_SLASH_FORMAT),
        }
        if (user === 'pemodal') {
            updatePemodal(data)
        }
        if (user === 'penerbit') {
            updatePenerbit(data)
        }
        setToProfile(false)
    }

    const riskStatementOptions = [
        {
            label: 'Dengan ini saya memahami dan menyetujui Pernyataan Risiko dan juga menyetujui data saya didaftarkan ke Digisign',
            required: true,
            value: 'true',
        },
    ]

    if (!isLoggedIn) {
        navigate('/login')
        return null
    }

    if (isValidUser) {
        if (toProfile) {
            navigate('/profile')
        } else {
            navigate('/register/success')
        }
        return null
    }

    if (user !== userType || (isPemodal && currentRegisterStep !== 5) || (isPenerbit && currentRegisterStep !== 4)) {
        navigate(`/register/${user}/step${currentRegisterStep}`)
        return null
    }

    const metaTitle = 'Pernyataan Risiko'

    return (
        <StyledSection dark={dark}>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <StyledAntdSpace direction='vertical' size={20}>
                {currentPage}
                <AntdRow justify='center'>
                    <Form onFinish={handleSubmit(handleFinish)}>
                        <AntdCol>
                            <RiskStatementText dark={dark} title={metaTitle} />
                            <br />
                            <AntdRow justify='center'>
                                <Field
                                    component={ReduxFormCheckboxGroup}
                                    formItemProps={{
                                        isHideLabel: true,
                                        required: true,
                                    }}
                                    name='isRiskStatementApproved'
                                    options={riskStatementOptions}
                                    validate={[required]}
                                />
                            </AntdRow>
                            <AntdRow>
                                <AntdCol
                                    md={{ offset: 9, span: 6 }}
                                    sm={{ offset: 6, span: 12 }}
                                    xs={{ offset: 6, span: 12 }}
                                >
                                    <Button
                                        dark={dark}
                                        htmlType='submit'
                                        loading={isActionLoading || isLoading}
                                        name='risk-statement-btn'
                                        value='Selanjutnya'
                                    />
                                </AntdCol>
                            </AntdRow>
                        </AntdCol>
                    </Form>
                </AntdRow>
            </StyledAntdSpace>
        </StyledSection>
    )
}

RiskStatementApproval.propTypes = {
    clearPemodal: PropTypes.func,
    clearPenerbit: PropTypes.func,
    currentRegisterStep: PropTypes.number,
    email: PropTypes.string,
    handleSubmit: PropTypes.func,
    id: PropTypes.number,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    isValidUser: PropTypes.bool,
    refetchUser: PropTypes.func,
    setPageNumber: PropTypes.func,
    updatePemodal: PropTypes.func,
    updatePenerbit: PropTypes.func,
    user: PropTypes.string,
}

export default RiskStatementApproval
