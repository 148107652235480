import React from 'react'
import { connect } from 'react-redux'
import * as productSlice from '../features/product/productSlice'
import Fund from './Fund'

const FundContainer = (props) => <Fund {...props} />

const mapStateToProps = (state) => ({
    isLoading: state.product.isLoading,
    products: state.product.products,
})

const mapDispatchToProps = {
    clearProduct: productSlice.clearProduct,
    getFunds: productSlice.getFunds,
}

export default connect(mapStateToProps, mapDispatchToProps)(FundContainer)
