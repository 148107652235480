import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import isMobile from 'is-mobile'
import PropTypes from 'prop-types'
import React from 'react'
import AllMenu from 'react-burger-menu'
import { Button } from '../../antdcomponents'
import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import PurchaseOrderFilterContainer from '../purchase_order_filter/PurchaseOrderFilterContainer'

const BurgerMenu = AllMenu.scaleRotate

const PurchaseOrderFilterDrawer = (props) => {
    const { handleFinish, handleReset, initialValues, isFundPage, isOpen, onClose } = props

    const styles = {
        bmBurgerBars: {
            background: 'white',
            borderRadius: '5px',
        },
        bmBurgerButton: {
            position: 'fixed',
            width: '22px',
            height: '22px',
            right: '21px',
            top: '21px',
            zIndex: '-1',
        },
        bmCrossButton: {
            display: 'none',
        },
        bmItemList: {
            color: 'white',
            height: '90%',
        },
        bmMenu: {
            background: 'white',
            left: '0px',
            overflowY: 'scroll',
            padding: isMobile() ? '50px 30px 0px' : '50px 50px 0px',
            position: 'fixed',
            top: '0px',
            width: '340px',
        },
        bmMenuWrap: {
            width: '350px',
            top: '0px',
        },
        bmMorphShape: {
            fill: colors.tuna,
        },
        bmOverlay: {
            background: 'black',
            opacity: '0.3',
            top: '0px',
        },
    }

    const drawerCloseImage = <img src={imagePath.drawerClose} />

    const renderMenus = (
        <AntdSpace direction='vertical'>
            <AntdRow align='end'>
                <Button
                    name='close-drawer-btn'
                    onClick={onClose}
                    type='text'
                    value={renderLazyLoadImage(drawerCloseImage)}
                />
            </AntdRow>
            <PurchaseOrderFilterContainer
                handleFinish={handleFinish}
                handleReset={handleReset}
                initialValues={initialValues}
                isFundPage={isFundPage}
            />
        </AntdSpace>
    )

    return (
        <BurgerMenu isOpen={isOpen} styles={styles}>
            {renderMenus}
        </BurgerMenu>
    )
}

PurchaseOrderFilterDrawer.propTypes = {
    handleFinish: PropTypes.func,
    handleReset: PropTypes.func,
    initialValues: PropTypes.object,
    isFundPage: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
}

export default PurchaseOrderFilterDrawer
