import isEmpty from 'lodash/isEmpty'
import * as cityTransformer from './city'

export const toState = (data) => ({
    city: !isEmpty(data.city) ? cityTransformer.toState(data.city) : {},
    cityId: data.cityId || null,
    id: data.id || null,
    name: data.name || '',
    postalCode: data.postalCode || '',
    subdistrict: data.subdistrict || '',
})
