import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../api'

export const getFaqs = createAsyncThunk('GET_FAQS', async ({ limit, offset, options } = {}, { rejectWithValue }) => {
    try {
        const res = await api.fetchAll('/faqs', { limit, offset, options })
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})
