import React from 'react'
import { connect } from 'react-redux'
import * as verificationSlice from '../features/verification/verificationSlice'
import VerifyEmail from './VerifyEmail'

const VerifyEmailContainer = (props) => <VerifyEmail {...props} />

const mapStateToProps = (state) => ({
    isActionLoadingVerify: state.verification.isActionLoadingVerify,
    isActionSuccessVerify: state.verification.isActionSuccessVerify,
    isError: state.verification.isError,
    isValidUser: state.authentication.isValidUser,
})

const mapDispatchToProps = {
    clearVerification: verificationSlice.clearVerification,
    verifyEmail: verificationSlice.verifyEmail,
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailContainer)
