import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popconfirm as AntdPopconfirm } from 'antd'
import noop from 'lodash/noop'

class Popconfirm extends Component {
    render() {
        const { className, children, cancelText, okText, okType, title, onCancel, onConfirm } = this.props

        return (
            <AntdPopconfirm
                cancelText={cancelText}
                className={className}
                okText={okText}
                okType={okType}
                onCancel={onCancel}
                onConfirm={onConfirm}
                title={title}
            >
                {children}
            </AntdPopconfirm>
        )
    }
}

Popconfirm.propTypes = {
    cancelText: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    okText: PropTypes.string,
    okType: PropTypes.string,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string.isRequired,
}

Popconfirm.defaultProps = {
    cancelText: 'Cancel',
    okText: 'OK',
    onCancel: noop,
    onConfirm: noop,
}

export default Popconfirm
