import last from 'lodash/last'
import split from 'lodash/split'

export const toState = (data) => {
    const identifier = data.identifier || ''
    const url = data.url || ''
    let name = ''
    if (url) {
        name = last(split(url, '/'))
    }

    return {
        identifier,
        name,
        url,
    }
}
