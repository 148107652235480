import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/annualSalary'
import * as annualSalaryAPI from './annualSalaryAPI'

const initialState = {
    annualSalaries: [],
    isError: false,
    isLoading: false,
    totalAnnualSalaries: 0,
}

export const { getAnnualSalaries } = annualSalaryAPI

const annualSalarySlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getAnnualSalaries.pending, (state) =>
            Object.assign(state, {
                annualSalaries: [],
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(getAnnualSalaries.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                annualSalaries: map(data.annualSalaries, (item) => toState(item)),
                isLoading: false,
                totalAnnualSalaries: data.count,
            })
        })
        builder.addCase(getAnnualSalaries.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'ANNUAL_SALARY',
    reducers: {
        clearAnnualSalary: () => initialState,
    },
})

export const { clearAnnualSalary } = annualSalarySlice.actions

const { reducer } = annualSalarySlice
export default reducer
