import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'redux-form'
import { useOutletContext } from 'react-router-dom'
import { Button, Form, Modal, ReduxFormCheckboxGroup } from '../../antdcomponents'
import colors from '../../basic/colors'
import { StyledAntdSpace } from '../../components/StyledComponents'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { required } from '../../utility/formValidation'
import useSelectorFormValues from '../../utility/useSelectorFormValues'

const DataVerification = (props) => {
    const { handleSubmit, isActionLoading, isModalOpen, onCancel, register, screeningData, setIsCheckKycResult } = props
    const { dark, isPemodal } = useOutletContext()

    const formValues = useSelectorFormValues('dataVerification')
    const isConfirm = get(formValues, 'isConfirm', [])

    const confirmationLabel =
        'Dengan ini Pengguna memahami dan menyetujui pendaftaran data pada platform Moneypool dan Digisign'

    const handleFinish = () => {
        const { data } = screeningData
        if (isPemodal) {
            return register(data).then(() => {
                setIsCheckKycResult((prevState) => !prevState)
                onCancel()
            })
        }
    }

    return (
        <Modal onCancel={onCancel} visible={isModalOpen}>
            <Form onFinish={handleSubmit(handleFinish)}>
                <StyledAntdSpace direction='vertical'>
                    <StyledTitle color={colors.graniteGray} level={2}>
                        Verifikasi Data Pemodal
                    </StyledTitle>
                    <Field
                        component={ReduxFormCheckboxGroup}
                        name='isConfirm'
                        options={[
                            {
                                label: confirmationLabel,
                                value: '1',
                            },
                        ]}
                        validate={[required]}
                    />
                    <AntdRow>
                        <AntdCol md={14} sm={24} xs={24}>
                            <Button
                                dark={dark}
                                disabled={isEmpty(isConfirm)}
                                htmlType='submit'
                                loading={isActionLoading}
                                name='verify-btn'
                                value='Kirim'
                            />
                        </AntdCol>
                    </AntdRow>
                </StyledAntdSpace>
            </Form>
        </Modal>
    )
}

DataVerification.propTypes = {
    handleSubmit: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    register: PropTypes.func,
    screeningData: PropTypes.object,
    setIsCheckKycResult: PropTypes.func,
}

export default DataVerification
