import * as businessSectorSlice from '../../features/business_sector/businessSectorSlice'
import * as citySlice from '../../features/city/citySlice'
import { connect } from 'react-redux'
import * as districtSlice from '../../features/district/districtSlice'
import * as entityTypeSlice from '../../features/entity_type/entityTypeSlice'
import * as fundingAmountSlice from '../../features/funding_amount/fundingAmountSlice'
import * as productTypeSlice from '../../features/product_type/productTypeSlice'
import React from 'react'
import PenerbitForm from './PenerbitForm'

const PenerbitFormContainer = (props) => <PenerbitForm {...props} />

const mapStateToProps = (state) => ({
    businessSectors: state.businessSector.businessSectors,
    cities: state.city.cities,
    districts: state.district.districts,
    entityTypes: state.entityType.entityTypes,
    fundingAmounts: state.fundingAmount.fundingAmounts,
    productTypes: state.productType.productTypes,
})

const mapDispatchToProps = {
    clearBusinessSector: businessSectorSlice.clearBusinessSector,
    clearCity: citySlice.clearCity,
    clearDistrict: districtSlice.clearDistrict,
    clearEntityType: entityTypeSlice.clearEntityType,
    clearFundingAmount: fundingAmountSlice.clearFundingAmount,
    clearProductType: productTypeSlice.clearProductType,
    getBusinessSectors: businessSectorSlice.getBusinessSectors,
    getCities: citySlice.getCities,
    getDistricts: districtSlice.getDistricts,
    getEntityTypes: entityTypeSlice.getEntityTypes,
    getFundingAmounts: fundingAmountSlice.getFundingAmounts,
    getProductTypes: productTypeSlice.getProductTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(PenerbitFormContainer)
