import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/penerbitDocument'
import * as penerbitDocumentAPI from './penerbitDocumentAPI'

const initialState = {
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    penerbitDocument: {},
    penerbitDocuments: [],
    totalPenerbitDocuments: 0,
}

export const { getPenerbitDocuments } = penerbitDocumentAPI

const penerbitDocumentSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getPenerbitDocuments.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                penerbitDocuments: [],
            })
        )
        builder.addCase(getPenerbitDocuments.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                penerbitDocuments: map(data.penerbitDocuments, (item) => toState(item)),
                totalPenerbitDocuments: data.count,
            })
        })
        builder.addCase(getPenerbitDocuments.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'PENERBIT_DOCUMENT',
    reducers: {
        clearPenerbitDocument: () => initialState,
    },
})

export const { clearPenerbitDocument } = penerbitDocumentSlice.actions

const { reducer } = penerbitDocumentSlice
export default reducer
