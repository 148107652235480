import React from 'react'
import { connect } from 'react-redux'
import * as socialMediaSlice from '../features/social_media/socialMediaSlice'
import getServerStoreState from '../utility/getServerStoreState'
import Header from './Header'

const HeaderContainer = (props) => <Header {...props} />

const mapStateToProps = (state) => {
    const updatedState = getServerStoreState(state)

    return {
        info: updatedState.socialMedia.info,
        isBankinfoExist: state.authentication.isBankinfoExist,
        isCartError: state.cart.isError,
        isInfoLocalError: updatedState.socialMedia.isError,
        isInfoLocalLoading: updatedState.socialMedia.isLoading,
        isLoadingAuthenticate: state.authentication.isLoadingAuthenticate,
    }
}

const mapDispatchToProps = {
    getInfo: socialMediaSlice.getInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
