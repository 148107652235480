import styled from 'styled-components'
import { Tabs } from '../antdcomponents'
import colors from '../basic/colors'
import imagePath from '../basic/imagePath'

export const StyledTabs = styled(Tabs)`
    &&& {
        & .ant-tabs-ink-bar {
            background: url(${imagePath.customUnderline}) no-repeat !important;
            background-position-x: center !important;
            background-size: 100% !important;
            height: 10px !important;
        }

        div.ant-tabs-nav-list {
            width: 100%;
        }

        div.ant-tabs-nav::before {
            border-bottom: none;
        }

        div.ant-tabs-nav {
            border-bottom: none;
        }

        div.ant-tabs-tab {
            display: flex;
            justify-content: center;
            width: 33%;
            margin: 0px !important;
        }

        div.ant-tabs-tab-btn {
            font-size: 12px;
        }

        div[aria-selected='true'] {
            color: ${colors.graniteGray};
        }

        div[aria-selected='false'] {
            color: silver;
        }

        @media screen and (min-width: 768px) {
            & .ant-tabs-ink-bar {
                background-size: 60% !important;
            }
            div.ant-tabs-tab-btn {
                font-size: 20px;
            }
        }
    }
`
