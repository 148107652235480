import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import first from 'lodash/first'
import { getRegisterData } from '../../common/helper/authentication'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import { Button, Form, ReduxFormCheckboxGroup } from '../../antdcomponents'
import { StyledAntdSpace, StyledSection } from '../../components/StyledComponents'
import { required } from '../../utility/formValidation'
import moment from 'moment'
import { DATE_HOUR_SLASH_FORMAT } from '../../constants/formats'
import TermsAndConditionsText from '../../components/terms_and_conditions_text/TermsAndConditionsText'
import capitalize from 'lodash/capitalize'
import HelmetMeta from '../../components/HelmetMeta'

const TermsAndConditionsApproval = (props) => {
    const {
        clearPemodal,
        clearPenerbit,
        currentRegisterStep,
        email,
        handleSubmit,
        id,
        isActionLoading,
        isActionSuccess,
        isLoading,
        isValidUser,
        refetchUser,
        updatePemodal,
        updatePenerbit,
        user,
    } = props
    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, pathname } = useOutletContext()
    const { currentPage, userType } = getRegisterData(dark, pathname, isPemodal)

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            refetchUser({ email })
        }
        return () => {
            dispatch(clearPemodal())
            dispatch(clearPenerbit())
        }
    }, [clearPemodal, clearPenerbit, dispatch, email, isActionLoading, isActionSuccess, refetchUser])

    const handleFinish = (values) => {
        const { isTermsAndConditionsApproved } = values
        const data = {
            id,
            isTermsAndConditionsApproved: first(isTermsAndConditionsApproved) === 'true',
            termsAndConditionsApprovalDate: moment.utc().format(DATE_HOUR_SLASH_FORMAT),
        }

        if (user === 'pemodal') {
            updatePemodal(data)
        }
        if (user === 'penerbit') {
            updatePenerbit(data)
        }
    }

    const termsAndConditionsOptions = [
        {
            label: 'Dengan ini saya memahami dan menyetujui Syarat & Ketentuan dan juga menyetujui data saya didaftarkan ke Digisign',
            required: true,
            value: 'true',
        },
    ]

    if (isLoggedIn) {
        if (isValidUser) {
            navigate('/profile')
            return null
        }
        if (
            user !== userType ||
            (isPemodal && currentRegisterStep !== 4) ||
            (isPenerbit && currentRegisterStep !== 3)
        ) {
            navigate(`/register/${user}/step${currentRegisterStep}`)
            return null
        }
    } else {
        navigate('/login')
        return null
    }

    const metaTitle = `Syarat & Ketentuan ${capitalize(user)}`

    return (
        <StyledSection dark={dark}>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <StyledAntdSpace direction='vertical' size={20}>
                {currentPage}
                <AntdRow justify='center'>
                    <Form onFinish={handleSubmit(handleFinish)}>
                        <AntdCol>
                            <TermsAndConditionsText dark={dark} title={metaTitle} user={user} />
                            <br />
                            <br />
                            <AntdRow justify='center'>
                                <Field
                                    component={ReduxFormCheckboxGroup}
                                    formItemProps={{
                                        isHideLabel: true,
                                        required: true,
                                    }}
                                    name='isTermsAndConditionsApproved'
                                    options={termsAndConditionsOptions}
                                    validate={[required]}
                                />
                            </AntdRow>
                            <AntdRow>
                                <AntdCol
                                    md={{ offset: 9, span: 6 }}
                                    sm={{ offset: 6, span: 12 }}
                                    xs={{ offset: 6, span: 12 }}
                                >
                                    <Button
                                        dark={dark}
                                        htmlType='submit'
                                        loading={isActionLoading || isLoading}
                                        name='next-btn'
                                        value='Selanjutnya'
                                    />
                                </AntdCol>
                            </AntdRow>
                        </AntdCol>
                    </Form>
                </AntdRow>
            </StyledAntdSpace>
        </StyledSection>
    )
}

TermsAndConditionsApproval.propTypes = {
    clearPemodal: PropTypes.func,
    clearPenerbit: PropTypes.func,
    currentRegisterStep: PropTypes.number,
    email: PropTypes.string,
    handleSubmit: PropTypes.func,
    id: PropTypes.number,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    isValidUser: PropTypes.bool,
    refetchUser: PropTypes.func,
    updatePemodal: PropTypes.func,
    updatePenerbit: PropTypes.func,
    user: PropTypes.string,
}

export default TermsAndConditionsApproval
