import React from 'react'
import { connect } from 'react-redux'
import ChangeOrder from './ChangeOrder'

const ChangeOrderContainer = (props) => <ChangeOrder {...props} />

const mapStateToProps = (state) => ({
    cart: state.cart.cart,
})

export default connect(mapStateToProps)(ChangeOrderContainer)
