import PropTypes from 'prop-types'
import React from 'react'
import { FormItem, RadioGroup } from '../../../antdcomponents'

const ReduxFormRadioGroup = (props) => {
    const { componentProps, dark, disabled, formItemProps, input, label, meta, options } = props
    const { onChange, value, name } = input
    const { touched, error } = meta
    const isError = touched && !!error

    return (
        <FormItem
            {...formItemProps}
            dark={dark}
            hasFeedback={isError}
            help={isError && error}
            label={label}
            validateStatus={isError ? 'error' : undefined}
        >
            <RadioGroup
                {...componentProps}
                dark={dark}
                disabled={disabled}
                name={name}
                onChange={onChange}
                options={options}
                value={value}
            />
        </FormItem>
    )
}

ReduxFormRadioGroup.propTypes = {
    componentProps: PropTypes.object,
    dark: PropTypes.bool,
    direction: PropTypes.string,
    disabled: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired,
        })
    ),
}

export default ReduxFormRadioGroup
