import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import size from 'lodash/size'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { BackButton, Button, Tooltip } from '../../antdcomponents'
import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import { isAuthorizedUser } from '../../common/helper/authorization'
import HelmetMeta from '../../components/HelmetMeta'
import Loader from '../../components/loader/Loader'
import StyledParagraph from '../../components/styled_paragraph/StyledParagraph'
import StyledSpan from '../../components/styled_span/StyledSpan'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { StyledAntdPagination, StyledAntdSpace, StyledImage, StyledSection } from '../../components/StyledComponents'
import { DEFAULT_PAGINATION_CONFIG } from '../../constants/pagination'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { renderLinkButton } from '../../utility/renderLinkComp'
import DeletePenerbitDetailContainer from './modal/DeletePenerbitDetailContainer'
import { StyledUnorderedList } from './StyledComponents'

const PenerbitDetail = (props) => {
    const {
        clearPenerbitDetail,
        currentRegisterStep,
        getPenerbitDetails,
        isActionLoading,
        isActionSuccess,
        isLoading,
        penerbitDetails,
        totalPenerbitDetails,
        user,
    } = props
    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, pathname, search } = useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })

    const metaTitle = 'Screening'
    const limit = DEFAULT_PAGINATION_CONFIG.defaultPageSize
    const page = Number(get(query, 'page', DEFAULT_PAGINATION_CONFIG.defaultCurrent))
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [id, setId] = useState()

    const fetchPenerbitDetails = useCallback(() => {
        const offset = (page - 1) * limit
        getPenerbitDetails({ limit, offset })
    }, [getPenerbitDetails, limit, page])

    const handleCancel = () => setIsModalOpen(false)
    const handleOpenModal = (id) => () => {
        setId(id)
        setIsModalOpen(true)
    }

    useEffect(() => {
        fetchPenerbitDetails()
        return () => {
            dispatch(clearPenerbitDetail())
        }
    }, [clearPenerbitDetail, dispatch, fetchPenerbitDetails])

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            fetchPenerbitDetails()
        }
    }, [fetchPenerbitDetails, isActionLoading, isActionSuccess])

    const profitImage = <StyledImage src={imagePath.profit} />

    const handleChange = (page) => {
        navigate(`/profile/screening-detail?page=${page}`)
    }

    const tooltipScreening = (
        <Tooltip
            title={
                <StyledUnorderedList>
                    <li>Satu akun penerbit dapat dikelola oleh lebih dari satu personel.</li>
                    <li>Personel terdaftar akan melakukan KYC sesuai dalam Ketentuan Otoritas Jasa Keuangan (OJK).</li>
                </StyledUnorderedList>
            }
        />
    )

    const renderPenerbitDetails = (
        <StyledAntdSpace direction='vertical' size='large'>
            {map(penerbitDetails, (item, key) => {
                const isKycVerified = get(item, 'isKycVerified', false)
                return (
                    <AntdRow align='top' justify='space-between' key={key}>
                        <AntdCol>
                            <StyledParagraph $dark={dark}>
                                <AntdSpace>
                                    <Fragment>Personel</Fragment>
                                    <Fragment>{key + 1}</Fragment>
                                    <Fragment>{key === 0 && 'Utama '}</Fragment>
                                    <Fragment> - {item.idCardName}</Fragment>
                                </AntdSpace>
                            </StyledParagraph>
                            <StyledSpan color={colors.purpleHeart}>
                                *{!isKycVerified && 'Belum '}Terverifikasi
                            </StyledSpan>
                        </AntdCol>
                        <AntdCol>
                            <AntdSpace size='large'>
                                {renderLinkButton(`/profile/screening/edit?name=${kebabCase(item.idCardName)}`, {
                                    color: colors.blueberry,
                                    disabled: isKycVerified,
                                    name: 'edit-btn',
                                    noStyle: true,
                                    type: 'link',
                                    value: 'Ubah',
                                })}
                                {renderLinkButton(`/profile/screening/view?name=${kebabCase(item.idCardName)}`, {
                                    color: colors.blueberry,
                                    disabled: isKycVerified,
                                    name: 'view-btn',
                                    noStyle: true,
                                    type: 'link',
                                    value: 'Lihat',
                                })}
                                <Button
                                    color={colors.blueberry}
                                    disabled={size(penerbitDetails) === 1 || isKycVerified}
                                    name='delete-btn'
                                    noStyle
                                    onClick={handleOpenModal(item.id)}
                                    type='link'
                                    value='Hapus'
                                />
                            </AntdSpace>
                        </AntdCol>
                    </AntdRow>
                )
            })}
        </StyledAntdSpace>
    )

    if (!isLoggedIn) {
        navigate('/login')
        return null
    }

    if (currentRegisterStep) {
        navigate(`/register/${user}/step${currentRegisterStep}`)
        return null
    }

    if (!isAuthorizedUser(isPemodal, isPenerbit, pathname)) {
        navigate('/profile')
        return null
    }

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <AntdRow>
                <AntdCol md={12} sm={0} xs={0}>
                    <AntdRow justify='center'>{renderLazyLoadImage(profitImage)}</AntdRow>
                </AntdCol>
                <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                    <StyledAntdSpace direction='vertical' size='large'>
                        <AntdSpace>
                            <StyledTitle color={colors.graniteGray} level={2}>
                                {metaTitle}
                            </StyledTitle>
                            {tooltipScreening}
                        </AntdSpace>
                        {isLoading && <Loader />}
                        {!isLoading && renderPenerbitDetails}
                        {!isEmpty(penerbitDetails) && (
                            <AntdRow justify='end'>
                                <StyledAntdPagination
                                    $dark={dark}
                                    current={page}
                                    defaultPageSize={limit}
                                    onChange={handleChange}
                                    total={totalPenerbitDetails}
                                />
                            </AntdRow>
                        )}
                        <AntdRow>
                            <AntdCol md={12} sm={24} xs={24}>
                                <StyledAntdSpace direction='vertical'>
                                    {renderLinkButton('/profile/screening/add', {
                                        dark,
                                        icon: 'PlusOutlined',
                                        name: 'add-penerbit-detail-btn',
                                        value: 'Tambah Personel',
                                    })}
                                    <BackButton route='/profile' />
                                </StyledAntdSpace>
                            </AntdCol>
                        </AntdRow>
                    </StyledAntdSpace>
                </AntdCol>
            </AntdRow>
            <DeletePenerbitDetailContainer id={id} isModalOpen={isModalOpen} onCancel={handleCancel} />
        </StyledSection>
    )
}

PenerbitDetail.propTypes = {
    clearPenerbitDetail: PropTypes.func,
    currentRegisterStep: PropTypes.number,
    getPenerbitDetails: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    isLoading: PropTypes.bool,
    penerbitDetails: PropTypes.array,
    totalPenerbitDetails: PropTypes.number,
    user: PropTypes.string,
}

export default PenerbitDetail
