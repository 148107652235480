import concat from 'lodash/concat'
import clone from 'lodash/clone'
import difference from 'lodash/difference'
import flattenDeep from 'lodash/flattenDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import size from 'lodash/size'
import toLower from 'lodash/toLower'
import { filterStatusOptions } from '../options/purchaseOrder'

const getFlattenDeepFilter = (query, attribute, defaultValue) => flattenDeep([get(query, attribute, defaultValue)])
const isEqualWithDefaultFilter = (filter, defaultFilter) =>
    isEmpty(difference(filter, defaultFilter)) && size(filter) === size(defaultFilter)

export const getInitialFilter = (productTypes, query, isFundPage) => {
    const defaultAllProductTypes = map(productTypes, (item) => toLower(item.title))
    const defaultAllStatus = map(filterStatusOptions(isFundPage), 'value')

    const productTypeTitle = getFlattenDeepFilter(query, 'type', defaultAllProductTypes)
    const status = getFlattenDeepFilter(query, 'status', defaultAllStatus)
    const purchaseOrderSearch = get(query, 'order', '')

    const defaultAllFilter = {
        defaultAllProductTypes,
        defaultAllStatus,
    }

    const initialValues = {
        productTypeTitle,
        status,
    }

    const initialOptions = clone(initialValues)

    return {
        defaultAllFilter,
        initialOptions,
        initialValues,
        purchaseOrderSearch,
    }
}

export const getNewOptions = (options, defaultAllFilter) => {
    const { productTypeTitle, status } = options
    const { defaultAllProductTypes, defaultAllStatus } = defaultAllFilter

    const newProductTypes =
        !isEqual(productTypeTitle, defaultAllProductTypes) && !isEmpty(productTypeTitle) ? productTypeTitle : undefined
    const newStatus = !isEqual(status, defaultAllStatus) && !isEmpty(status) ? status : undefined

    const newOptions = {
        productTypeTitle: newProductTypes,
        status: newStatus,
    }

    return newOptions
}

export const getFilterFormData = (values, defaultAllFilter) => {
    const { productTypeTitle, status } = values
    const { defaultAllProductTypes, defaultAllStatus } = defaultAllFilter
    let urlParams = []
    if (productTypeTitle && !isEqualWithDefaultFilter(productTypeTitle, defaultAllProductTypes)) {
        map(productTypeTitle, (item) => {
            urlParams = concat(urlParams, `type=${item}`)
        })
    }
    if (status && !isEqualWithDefaultFilter(status, defaultAllStatus)) {
        map(status, (item) => {
            urlParams = concat(urlParams, `status=${item}`)
        })
    }
    const newOptions = clone(values)
    return {
        newOptions,
        urlParams,
    }
}
