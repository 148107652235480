import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import isMobile from 'is-mobile'
import PropTypes from 'prop-types'
import React from 'react'
import AllMenu from 'react-burger-menu'

import { Button } from '../../antdcomponents'
import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import ProductFilterContainer from '../product_filter/ProductFilterContainer'

const BurgerMenu = AllMenu.scaleRotate

const ProductFilterDrawer = (props) => {
    const { initialValues, isOpen, onClose, onFinish, onReset } = props

    const styles = {
        bmBurgerBars: {
            background: 'white',
            borderRadius: '5px',
        },
        bmBurgerButton: {
            position: 'fixed',
            width: '22px',
            height: '22px',
            right: '21px',
            top: '21px',
            zIndex: '-1',
        },
        bmCrossButton: {
            display: 'none',
        },
        bmItem: {
            paddingBottom: isMobile() ? '30px ' : '50px',
        },
        bmItemList: {
            color: 'white',
            height: '90%',
        },
        bmMenu: {
            background: 'white',
            left: '0px',
            overflowY: 'scroll',
            padding: isMobile() ? '50px 30px 0px' : '50px 50px 0px',
            position: 'fixed',
            top: '0px',
            width: '310px',
        },
        bmMenuWrap: {
            width: '350px',
            top: '0px',
        },
        bmMorphShape: {
            fill: colors.tuna,
        },
        bmOverlay: {
            background: 'black',
            opacity: '0.3',
            top: '0px',
        },
    }

    const drawerCloseImage = <img src={imagePath.drawerClose} />

    const renderMenus = (
        <AntdSpace direction='vertical'>
            <AntdRow align='end'>
                <Button
                    name='close-drawer-btn'
                    onClick={onClose}
                    type='text'
                    value={renderLazyLoadImage(drawerCloseImage)}
                />
            </AntdRow>
            <ProductFilterContainer initialValues={initialValues} onFinish={onFinish} onReset={onReset} />
        </AntdSpace>
    )

    return (
        <BurgerMenu isOpen={isOpen} styles={styles}>
            {renderMenus}
        </BurgerMenu>
    )
}

ProductFilterDrawer.propTypes = {
    initialValues: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onReset: PropTypes.func,
}

export default ProductFilterDrawer
