import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import kebabCase from 'lodash/kebabCase'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Fragment, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { BackButton, Button, Breadcrumb } from '../antdcomponents'
import colors from '../basic/colors'
import Loader from '../components/loader/Loader'
import StyledParagraph from '../components/styled_paragraph/StyledParagraph'
import StyledTitle from '../components/styled_title/StyledTitle'
import { StyledAntdPagination, StyledAntdSpace, StyledSection } from '../components/StyledComponents'
import { DEFAULT_PAGINATION_CONFIG } from '../constants/pagination'
import { renderLinkButton } from '../utility/renderLinkComp'
import ChangeOrderContainer from './modal/ChangeOrderContainer'
import { StyledAntdList } from './StyledComponents'

const FundReport = (props) => {
    const {
        clearProduct,
        clearProductDocument,
        createCart,
        dividendReports,
        financialReports,
        getCart,
        getDividendReports,
        getFinancialReports,
        getProduct,
        isLoadingDividendReports,
        isLoadingFinancialReports,
        product,
        totalDividendReports,
        totalFinancialReports,
    } = props
    const { dark, dispatch, isLoggedIn, navigate, params, search } = useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })

    const slug = get(params, 'slug', '')
    const code = get(product, 'code', '')
    const id = get(product, 'id', null)
    const shareSize = get(product, 'shareSize', 0)

    const limit = DEFAULT_PAGINATION_CONFIG.defaultPageSize
    const dividendPage = Number(get(query, 'dividendPage', DEFAULT_PAGINATION_CONFIG.defaultCurrent))
    const financialPage = Number(get(query, 'financialPage', DEFAULT_PAGINATION_CONFIG.defaultCurrent))

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalType, setModalType] = useState('')

    const category = get(product, 'category', '')
    const breadcrumbItems = [
        { label: 'Semua Penawaran', linkTo: '/products' },
        { label: category, linkTo: `/products?category=${kebabCase(category)}` },
        { label: code, linkTo: `/product/${slug}` },
        { label: 'Laporan Dana' },
    ]

    useEffect(() => {
        const fetchProduct = async () => await getProduct(slug)
        fetchProduct()
        return () => {
            dispatch(clearProduct())
            dispatch(clearProductDocument())
        }
    }, [clearProduct, clearProductDocument, dispatch, getProduct, slug])

    useEffect(() => {
        const offset = (dividendPage - 1) * limit
        getDividendReports({ limit, offset, options: { productSlug: slug } })
    }, [dividendPage, getDividendReports, limit, slug])

    useEffect(() => {
        const offset = (financialPage - 1) * limit
        getFinancialReports({ limit, offset, options: { productSlug: slug } })
    }, [financialPage, getFinancialReports, limit, slug])

    const handleCancel = () => setIsModalOpen(false)

    const handleChange = (type) => (page) => {
        if (type === 'dividend') {
            navigate(`/fund-report/${slug}?dividendPage=${page}&financialPage=${financialPage}`)
        }
        if (type === 'financial') {
            navigate(`/fund-report/${slug}?dividendPage=${dividendPage}&financialPage=${page}`)
        }
    }

    const handleClick = async () => {
        await getCart()
        setIsModalOpen(true)
        setModalType('order')
    }

    const handleOrder = () => {
        const data = {
            productId: id,
            quantity: shareSize,
        }
        createCart(data)
    }

    const handleRenderItem = (item, key) => (
        <StyledAntdList.Item key={key}>
            <AntdRow justify='space-between'>
                <StyledParagraph $color={colors.graniteGray}>{item.name}</StyledParagraph>
                <a href={`${item.url}`} rel='noreferrer' target='_blank'>
                    <Button
                        color={colors.blueberry}
                        dark={dark}
                        name='download-btn'
                        noStyle
                        type='link'
                        value='Unduh'
                    />
                </a>
            </AntdRow>
        </StyledAntdList.Item>
    )

    const renderFundReport = () => (
        <AntdRow gutter={[0, 20]}>
            <AntdCol md={11} sm={24} xs={24}>
                <StyledAntdSpace direction='vertical' size='large'>
                    <StyledTitle color={colors.graniteGray} level={2}>
                        Laporan Keuangan
                    </StyledTitle>
                    {isLoadingFinancialReports && <Loader />}
                    {!isLoadingFinancialReports && (
                        <Fragment>
                            <StyledAntdList dataSource={financialReports} renderItem={handleRenderItem} split={false} />
                            {!isEmpty(financialReports) && (
                                <AntdRow justify='end'>
                                    <StyledAntdPagination
                                        $dark={dark}
                                        current={financialPage}
                                        defaultPageSize={limit}
                                        onChange={handleChange('financial')}
                                        total={totalFinancialReports}
                                    />
                                </AntdRow>
                            )}
                        </Fragment>
                    )}
                </StyledAntdSpace>
            </AntdCol>
            <AntdCol md={{ offset: 2, span: 11 }} sm={24} xs={24}>
                <StyledAntdSpace direction='vertical' size='large'>
                    <StyledTitle color={colors.graniteGray} level={2}>
                        Laporan Dividen
                    </StyledTitle>
                    {isLoadingDividendReports && <Loader />}
                    {!isLoadingDividendReports && (
                        <Fragment>
                            <StyledAntdList dataSource={dividendReports} renderItem={handleRenderItem} split={false} />
                            {!isEmpty(dividendReports) && (
                                <AntdRow justify='end'>
                                    <StyledAntdPagination
                                        $dark={dark}
                                        current={dividendPage}
                                        defaultPageSize={limit}
                                        onChange={handleChange('dividend')}
                                        total={totalDividendReports}
                                    />
                                </AntdRow>
                            )}
                        </Fragment>
                    )}
                </StyledAntdSpace>
            </AntdCol>
        </AntdRow>
    )

    return (
        <StyledSection>
            <StyledAntdSpace direction='vertical' size='large'>
                <Breadcrumb items={breadcrumbItems} />
                {renderFundReport()}
                <center>
                    <AntdRow>
                        <AntdCol md={{ offset: 6, span: 12 }} sm={24} xs={24}>
                            {isLoggedIn ? (
                                <Button dark={dark} name='buy-btn' onClick={handleClick} value='Beli' />
                            ) : (
                                renderLinkButton('/login', {
                                    dark,
                                    name: 'login-btn',
                                    value: 'Masuk/Daftar untuk Beli',
                                })
                            )}
                        </AntdCol>
                    </AntdRow>
                    <BackButton route={`/product/${slug}`} />
                </center>
            </StyledAntdSpace>
            <ChangeOrderContainer
                id={id}
                isModalOpen={isModalOpen && modalType === 'order'}
                onCancel={handleCancel}
                onOrder={handleOrder}
            />
        </StyledSection>
    )
}

FundReport.propTypes = {
    clearProduct: PropTypes.func,
    clearProductDocument: PropTypes.func,
    createCart: PropTypes.func,
    dividendReports: PropTypes.array,
    financialReports: PropTypes.array,
    getCart: PropTypes.func,
    getDividendReports: PropTypes.func,
    getFinancialReports: PropTypes.func,
    getProduct: PropTypes.func,
    isLoadingDividendReports: PropTypes.bool,
    isLoadingFinancialReports: PropTypes.bool,
    product: PropTypes.object,
    totalDividendReports: PropTypes.number,
    totalFinancialReports: PropTypes.number,
}

export default FundReport
