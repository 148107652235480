import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import HelmetMeta from '../components/HelmetMeta'
import { StyledSection } from '../components/StyledComponents'
import { StyledAntdRow, StyledNoMatchSection, StyledSpan } from './StyledComponents'
import { renderLinkButton } from '../utility/renderLinkComp'

const NoMatch = () => {
    const { dark } = useOutletContext()
    const metaTitle = '404'

    return (
        <StyledNoMatchSection>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <StyledSection>
                <StyledAntdRow justify='center'>
                    <AntdCol md={12} sm={0}></AntdCol>
                    <AntdCol md={12} sm={24}>
                        <AntdRow justify='end'>
                            <StyledSpan>{metaTitle}</StyledSpan>
                        </AntdRow>
                        <AntdRow justify='end'>
                            <AntdCol md={12} sm={24} xs={24}>
                                {renderLinkButton('/', { dark, name: 'homepage-btn', value: 'Homepage' })}
                            </AntdCol>
                        </AntdRow>
                    </AntdCol>
                </StyledAntdRow>
            </StyledSection>
        </StyledNoMatchSection>
    )
}

export default NoMatch
