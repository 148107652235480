import { default as AntdCol } from 'antd/lib/col'
import styled from 'styled-components'

export const StyledAntdCol = styled(AntdCol)`
    margin: auto;
`

export const StyledFormContainer = styled.div`
    width: 100%;

    @media screen and (min-width: 768px) {
        width: 50%;
    }
`
