import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import get from 'lodash/get'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useOutletContext } from 'react-router-dom'
import { BackButton, Button, Modal } from '../../antdcomponents'
import colors from '../../basic/colors'
import ChatModalContentContainer from '../../components/chat_modal_content/ChatModalContentContainer'
import { StyledAntdSpace } from '../../components/StyledComponents'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { DATE_FULL_FORMAT_WITHSPACE, DATE_HOUR_SLASH_FORMAT, DATE_SLASH_FORMAT } from '../../constants/formats'
import formatPrice from '../../utility/formatPrice'

const PurchaseOrder = (props) => {
    const { isActionLoading, isModalOpen, modalType, onCancel, onOk, purchaseOrder } = props
    const { dark } = useOutletContext()

    const isModalTypeCancel = modalType === 'cancel'
    const isModalTypeChat = modalType === 'chat'
    const isModalTypeView = modalType === 'view'

    const adminFee = get(purchaseOrder, 'adminFee', 0)
    const category = get(purchaseOrder, 'product.category', '')
    const code = get(purchaseOrder, 'product.code', '')
    const createdAt = get(purchaseOrder, 'createdAt', null)
    const entityCode = get(purchaseOrder, 'product.penerbit.entityCode', '')
    const isCanceled = get(purchaseOrder, 'isCanceled', false)
    const isCanceledAfterPaid = get(purchaseOrder, 'isCanceledAfterPaid', false)
    const isOrderPaid = get(purchaseOrder, 'isOrderPaid', false)
    const noDoc = get(purchaseOrder, 'noDoc', '')
    const penerbitName = get(purchaseOrder, 'product.penerbitName', '')
    const platformFee = get(purchaseOrder, 'platformFee', 0)
    const purchaseOrderId = get(purchaseOrder, 'id', null)
    const canceledDate = get(purchaseOrder, 'canceledDate', null)
    const quantity = get(purchaseOrder, 'quantity', 0)
    const subtotal = get(purchaseOrder, 'subtotal', 0)
    const totalPayment = get(purchaseOrder, 'totalPayment', 0)
    const totalRefund = get(purchaseOrder, 'totalRefund', 0)
    const transactionFee = get(purchaseOrder, 'transactionFee', 0)
    const type = get(purchaseOrder, 'product.type', '')
    const unitPrice = get(purchaseOrder, 'unitPrice', 0)

    const today = moment().format(DATE_SLASH_FORMAT)

    const title = !isModalTypeChat ? (
        <center>
            <StyledTitle $dark={dark} level={2}>
                {isModalTypeView && (isCanceledAfterPaid ? 'Total Pengembalian Dana' : 'Detail Order')}
                {isModalTypeCancel && 'Pembatalan'}
            </StyledTitle>
        </center>
    ) : null

    let footer = null
    if (isModalTypeCancel) {
        footer = (
            <center>
                <StyledAntdSpace direction='vertical'>
                    <AntdRow justify='center'>
                        <AntdCol md={12} sm={24} xs={24}>
                            <Button
                                dark={dark}
                                loading={isActionLoading}
                                name='cancel-btn'
                                onClick={onOk}
                                value='Batalkan'
                            />
                        </AntdCol>
                    </AntdRow>
                    <BackButton onClick={onCancel} />
                </StyledAntdSpace>
            </center>
        )
    }

    const renderFormRowItem = (title, value, valueColor) => (
        <Fragment>
            <AntdCol span={12}>
                <AntdRow justify='left'>
                    <StyledTitle color={colors.graniteGray} level={3}>
                        {title}
                    </StyledTitle>
                </AntdRow>
            </AntdCol>
            <AntdCol span={12}>
                <AntdRow justify='left'>
                    <StyledTitle $dark={dark} color={valueColor} level={3}>
                        {value}
                    </StyledTitle>
                </AntdRow>
            </AntdCol>
        </Fragment>
    )

    const cancelContent = isOrderPaid ? (
        <AntdRow align='middle' gutter={[0, 10]} justify='center'>
            {renderFormRowItem(
                'Tanggal Pembatalan',
                moment(isCanceled ? canceledDate : today, DATE_SLASH_FORMAT).format(DATE_FULL_FORMAT_WITHSPACE)
            )}
            {renderFormRowItem('Nomor', noDoc)}
            {renderFormRowItem('Kode', code)}
            {renderFormRowItem('Penerbit', penerbitName)}
            {renderFormRowItem('Total Pembayaran', totalPayment, colors.blueberry2)}
            {renderFormRowItem('Biaya Admin', adminFee)}
            {renderFormRowItem('Biaya Transaksi', transactionFee)}
            {renderFormRowItem('Biaya Platform', platformFee)}
            {renderFormRowItem('Total Pengembalian Dana', totalRefund, colors.blueberry2)}
        </AntdRow>
    ) : (
        <StyledTitle color={colors.graniteGray} level={3}>
            Apakah anda yakin untuk melakukan pembatalan transaksi?
        </StyledTitle>
    )

    const detailContent = (
        <AntdRow gutter={[0, 10]} justify='center'>
            {renderFormRowItem('Tanggal', moment(createdAt, DATE_HOUR_SLASH_FORMAT).format(DATE_FULL_FORMAT_WITHSPACE))}
            {renderFormRowItem('Nomor', noDoc)}
            {renderFormRowItem('Kode', code)}
            {renderFormRowItem('Penerbit', penerbitName)}
            {renderFormRowItem('Kategori', category)}
            {renderFormRowItem('Tipe', type)}
            {renderFormRowItem('Harga Per Satuan', unitPrice)}
            {renderFormRowItem('Jumlah Lembar', quantity)}
            {renderFormRowItem('Biaya Beli', `Rp ${formatPrice(subtotal)}`)}
            {renderFormRowItem('Biaya Admin', adminFee)}
            {renderFormRowItem('Biaya Transaksi', transactionFee)}
            {renderFormRowItem('Total Pembayaran', totalPayment, colors.blueberry2)}
        </AntdRow>
    )

    const renderCancelContent = (
        <StyledAntdSpace direction='vertical'>
            {cancelContent}
            {isOrderPaid && (
                <StyledTitle color={colors.doveGray} level={3}>
                    Pengembalian dana akan diproses paling lama 2x24 jam kerja ke rekening pemodal yang telah terdaftar
                    pada sistem moneypool.
                </StyledTitle>
            )}
        </StyledAntdSpace>
    )

    const renderChatContent = (
        <ChatModalContentContainer
            entityCode={entityCode}
            onCancel={onCancel}
            penerbitName={penerbitName}
            purchaseOrderId={purchaseOrderId}
        />
    )

    const renderViewContent = isCanceledAfterPaid ? cancelContent : detailContent

    return (
        <Modal footer={footer} onCancel={onCancel} title={title} visible={isModalOpen}>
            {isModalTypeCancel && renderCancelContent}
            {isModalTypeChat && renderChatContent}
            {isModalTypeView && renderViewContent}
        </Modal>
    )
}

PurchaseOrder.propTypes = {
    isActionLoading: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    modalType: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    purchaseOrder: PropTypes.object,
}

export default PurchaseOrder
