import { Input as AntdInput } from 'antd'
import styled from 'styled-components'
import colors from '../../basic/colors'

const StyledAntdInput = styled(AntdInput)`
    &&& {
        input {
            border-radius: 0px;
        }
        border-color: ${(props) => (props.$dark ? colors.robbinEggBlue : 'silver')};
        border-style: solid;
        border-width: 2px;
        box-shadow: none;
        height: 30px;
        outline: none;
        &:focus {
            box-shadow: none !important;
            border-right-width: 2px !important;
        }
        ${(props) =>
            !props.disabled &&
            `
            color: ${props.$dark ? 'white' : 'black'};

            &,
            input {
                background: transparent !important;
            }
        `}
        @media screen and (min-width: 768px) {
            height: 45px;
        }
    }
`

export default StyledAntdInput
