import React from 'react'
import { connect } from 'react-redux'
import * as favoriteSlice from '../../features/favorite/favoriteSlice'
import ProductCard from './ProductCard'

const ProductCardContainer = (props) => <ProductCard {...props} />

const mapStateToProps = (state) => ({
    authToken: state.authentication.authToken,
    favorites: state.favorite.favorites,
    isError: state.favorite.isError,
    isLoading: state.favorite.isLoading,
})

const mapDispatchToProps = {
    createFavorite: favoriteSlice.createFavorite,
    deleteFavorite: favoriteSlice.deleteFavorite,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer)
