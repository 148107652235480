import React from 'react'
import { connect } from 'react-redux'
import RiskStatement from './RiskStatement'

const RiskStatementContainer = (props) => <RiskStatement {...props} />

const mapStateToProps = (state) => ({
    riskStatementApprovalDate: state.authentication.riskStatementApprovalDate,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RiskStatementContainer)
