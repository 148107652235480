import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../api'

export const getFunds = createAsyncThunk('GET_FUNDS', async ({ limit, offset, options } = {}, { rejectWithValue }) => {
    try {
        const res = await api.fetchAll('/products/funds', { limit, offset, options })
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getPortfolio = createAsyncThunk(
    'GET_PORTFOLIO',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/products/portfolio', { limit, offset, options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getProduct = createAsyncThunk('GET_PRODUCT', async (slug, { rejectWithValue }) => {
    try {
        const res = await api.fetchBySlug('/product', slug)
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getProducts = createAsyncThunk(
    'GET_PRODUCTS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/products', { limit, offset, options })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
