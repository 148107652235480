import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import colors from '../../basic/colors'
import StyledParagraph from '../styled_paragraph/StyledParagraph'
import StyledTitle from '../styled_title/StyledTitle'

const Statement = (props) => {
    const { content, dark, noBreak, title } = props
    return (
        <Fragment>
            <StyledTitle $dark={dark} color={colors.graniteGray} level={4}>
                {title}
            </StyledTitle>
            <StyledParagraph $dark={dark} $textAlign='justify' color={colors.graniteGray}>
                {content}
            </StyledParagraph>
            {!noBreak && <br />}
        </Fragment>
    )
}

Statement.propTypes = {
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    dark: PropTypes.bool,
    noBreak: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export default Statement
