import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../../basic/colors'
import modifyVars from '../../basic/modifyVars'

const getColor = (color, dark) => {
    if (dark) {
        return 'white'
    } else {
        if (color) {
            return color
        } else {
            return colors.persianIndigo
        }
    }
}

const StyledTableHeader = styled.span`
    font-style: ${modifyVars.tableHeaderStyle.fontStyle};
    font-weight: ${modifyVars.tableHeaderStyle.fontWeight};
    font-size: ${modifyVars.tableHeaderStyle.fontSizeMobile} !important;
    font-family: ${modifyVars.tableHeaderStyle.fontFamily};
    letter-spacing: ${modifyVars.tableHeaderStyle.letterSpacing};

    &,
    & > * {
        color: ${(props) => getColor(props.color, props.$dark)} !important;
    }

    ${(props) =>
        props.$textAlign &&
        `
        display: block;
        text-align: ${props.$textAlign};
    `}

    @media screen and (min-width: 768px) {
        font-size: ${modifyVars.tableHeaderStyle.fontSizeDesktop} !important;
    }
`

StyledTableHeader.propTypes = {
    $dark: PropTypes.bool,
    background: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    color: PropTypes.string,
}

StyledTableHeader.defaultProps = {
    $dark: false,
}

export default StyledTableHeader
