import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import { Button, Form, ReduxFormInputPassword } from '../../antdcomponents'
import { isValidUserType } from '../../common/helper/authentication'
import { StyledAntdSpace, StyledCenterSection } from '../../components/StyledComponents'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { passwordMinimalCharacter, passwordRequiredCharacter, required } from '../../utility/formValidation'

const ResetPasswordForm = (props) => {
    const { handleSubmit, isActionLoading, isActionSuccess, resetPassword, setPageNumber, title } = props
    const { dark, navigate, search } = useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const secret = get(query, 'secret', '')
    const user = get(query, 'user', '')

    if (!secret && !isValidUserType(user)) {
        navigate('/forgot-password', { replace: true })
    }

    useEffect(() => {
        if (!isActionLoading && isActionSuccess) {
            setPageNumber(1)
        }
    }, [isActionLoading, isActionSuccess, setPageNumber])

    const handleFinish = (values) => resetPassword({ password: values.password, secret, user })

    return (
        <StyledCenterSection>
            <StyledAntdSpace direction='vertical'>
                <StyledAntdSpace direction='vertical' size='large'>
                    <StyledTitle $dark={dark} level={2}>
                        Kata Sandi Baru
                    </StyledTitle>
                    <Form onFinish={handleSubmit(handleFinish)}>
                        <Field
                            component={ReduxFormInputPassword}
                            componentProps={{
                                isResetPassword: true,
                            }}
                            dark={dark}
                            formItemProps={{ noMargin: true, required: true }}
                            label='Kata Sandi Baru'
                            name='password'
                            placeholder='Kata Sandi Baru'
                            validate={[required, passwordRequiredCharacter, passwordMinimalCharacter]}
                        />
                        <Field
                            component={ReduxFormInputPassword}
                            componentProps={{
                                isConfirmPassword: true,
                            }}
                            dark={dark}
                            formItemProps={{ required: true }}
                            label='Konfirmasi Kata Sandi Baru'
                            name='confirmPassword'
                            placeholder='Ketik Ulang Kata Sandi Baru'
                            validate={[required, passwordRequiredCharacter, passwordMinimalCharacter]}
                        />
                        <br />
                        <AntdRow>
                            <AntdCol md={{ offset: 4, span: 16 }} sm={24} xs={24}>
                                <Button
                                    dark={dark}
                                    htmlType='submit'
                                    loading={isActionLoading}
                                    name='reset-password-btn'
                                    value={title}
                                />
                            </AntdCol>
                        </AntdRow>
                    </Form>
                </StyledAntdSpace>
            </StyledAntdSpace>
        </StyledCenterSection>
    )
}

ResetPasswordForm.propTypes = {
    handleSubmit: PropTypes.func,
    isActionLoading: PropTypes.bool,
    isActionSuccess: PropTypes.bool,
    pristine: PropTypes.bool,
    resetPassword: PropTypes.func,
    setPageNumber: PropTypes.func,
    title: PropTypes.string,
}

export default ResetPasswordForm
