import concat from 'lodash/concat'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import { Button, Form, ReduxFormCheckboxGroup, ReduxFormRadioGroup } from '../../antdcomponents'
import colors from '../../basic/colors'
import { filterStatusOptions } from '../../common/options/purchaseOrder'
import { StyledAntdSpace } from '../StyledComponents'

const PurchaseOrderFilter = (props) => {
    const {
        clearProductType,
        getProductTypes,
        handleFinish,
        handleReset,
        handleSubmit,
        isFundPage,
        isLoadingProductType,
        isLoadingPurchaseOrder,
        productTypes,
    } = props
    const { dark, dispatch } = useOutletContext()

    useEffect(() => {
        const fetchProductTypes = async () => await getProductTypes()
        fetchProductTypes()
        return () => {
            dispatch(clearProductType())
        }
    }, [clearProductType, dispatch, getProductTypes])

    const defaultOptions = [{ label: 'Semua', value: 'all' }]
    const additionalProductTypeOptions = map(productTypes, (item) => ({
        label: item.title,
        value: kebabCase(item.title),
    }))
    const productTypeOptions = isFundPage
        ? concat(defaultOptions, additionalProductTypeOptions)
        : additionalProductTypeOptions

    const isLoading = isLoadingProductType || isLoadingPurchaseOrder

    const component = isFundPage ? ReduxFormRadioGroup : ReduxFormCheckboxGroup

    const componentProps = {
        canCheckAll: true,
        checkboxPosition: 'right',
    }

    const formItemProps = {
        labelColor: colors.graniteGray,
        labelLevel: 4,
    }

    if (isLoadingProductType || isLoadingPurchaseOrder) {
        return null
    }

    return (
        <Form onFinish={handleSubmit(handleFinish)}>
            <StyledAntdSpace direction='vertical' size='middle'>
                <Field
                    component={component}
                    componentProps={componentProps}
                    formItemProps={formItemProps}
                    label='Tipe'
                    name='productTypeTitle'
                    options={productTypeOptions}
                />
                <Field
                    component={component}
                    componentProps={componentProps}
                    formItemProps={formItemProps}
                    label='Status'
                    name='status'
                    options={filterStatusOptions(isFundPage)}
                />
                <Button dark={dark} htmlType='submit' loading={isLoading} name='filter-btn' value='Filter' />
                <Button dark={dark} loading={isLoading} name='reset-btn' onClick={handleReset} value='Reset' />
            </StyledAntdSpace>
        </Form>
    )
}

PurchaseOrderFilter.propTypes = {
    clearProductType: PropTypes.func,
    getProductTypes: PropTypes.func,
    handleFinish: PropTypes.func,
    handleReset: PropTypes.func,
    handleSubmit: PropTypes.func,
    isFundPage: PropTypes.bool,
    isLoadingProductType: PropTypes.bool,
    isLoadingPurchaseOrder: PropTypes.bool,
    productTypes: PropTypes.array,
}

export default PurchaseOrderFilter
