import { List as AntdList } from 'antd'
import styled from 'styled-components'

export const StyledAntdList = styled(AntdList)`
    &&& {
        div.ant-spin-container > div.ant-row {
            justify-content: center;
        }

        @media screen and (min-width: 768px) {
            div.ant-spin-container > div.ant-row {
                justify-content: start;
            }
        }
    }
`
