import { default as AntdSpace } from 'antd/lib/space'
import capitalize from 'lodash/capitalize'
import find from 'lodash/find'
import get from 'lodash/get'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import colors from '../basic/colors'
import { successOptions } from '../common/helper/profile'
import Loader from '../components/loader/Loader'
import PemodalForm from '../components/pemodal_form/PemodalForm'
import PenerbitFormContainer from '../components/penerbit_form/PenerbitFormContainer'
import StyledTitle from '../components/styled_title/StyledTitle'
import { DATE_HOUR_SLASH_FORMAT } from '../constants/formats'
import getBase64 from '../utility/getBase64'
import * as notification from '../utility/notification'
import usePrevious from '../utility/usePrevious'
import useSelectorFormValues from '../utility/useSelectorFormValues'

const DetailForm = (props) => {
    const {
        clearVerification,
        districts,
        email,
        getPemodalByEmail,
        getPemodalByMobileNumber,
        id,
        isActionLoadingSendVerification,
        isActionLoadingUser,
        isActionSuccessSendVerification,
        isActionSuccessUser,
        isDuplicate,
        isLoadingPemodal,
        isLoadingPenerbit,
        isUpdateAllowed,
        mobileNumber,
        refetchUser,
        sendVerificationEmail,
        sendVerificationMobileNumber,
        setTemporaryEmail,
        setTemporaryMobileNumber,
        updatePemodal,
        updatePenerbit,
        user,
        username,
    } = props
    const { dispatch, isLoggedIn, isPemodal, isPenerbit, navigate } = useOutletContext()
    const formValues = useSelectorFormValues('detail')
    const newEmail = get(formValues, 'email', '')
    const newMobileNumber = get(formValues, 'mobileNumber', '')
    const npwp = get(formValues, 'npwp', [])
    const profilePicture = get(formValues, 'profilePicture', [])

    const oldUsername = username
    const oldEmail = email
    const oldMobileNumber = mobileNumber

    const [changingField, setChangingField] = useState('')
    const prevIsLoadingPemodal = usePrevious(isLoadingPemodal)

    const dataUpdated = (values) => {
        const { username, email, mobileNumber } = values
        if (oldUsername !== username || oldEmail !== email || oldMobileNumber !== mobileNumber) {
            return moment.utc().format(DATE_HOUR_SLASH_FORMAT)
        }
        return null
    }

    useEffect(() => {
        if (!isActionLoadingUser && isActionSuccessUser) {
            const { content, title } = successOptions
            notification.success(content, title)
            refetchUser({ additionalAttr: ['profilePictureUrl'], email })
            navigate('/profile')
        }
    }, [email, isActionLoadingUser, isActionSuccessUser, navigate, refetchUser])

    useEffect(() => {
        if (!isLoadingPemodal && prevIsLoadingPemodal !== isLoadingPemodal) {
            if (isDuplicate) {
                let field
                if (changingField === 'email') {
                    field = 'email'
                }
                if (changingField === 'phone') {
                    field = 'nomor telepon seluler'
                }
                notification.error(`${capitalize(field)} sudah terdaftar, gunakan ${field} lainnya`)
            } else {
                if (changingField === 'email') {
                    setTemporaryEmail(newEmail)
                    sendVerificationEmail({ email, id, new_email: newEmail, user })
                }
                if (changingField === 'phone') {
                    setTemporaryMobileNumber(newMobileNumber)
                    sendVerificationMobileNumber({ new_mobile_number: '+62' + newMobileNumber })
                }
            }
        }
    }, [
        changingField,
        email,
        id,
        isDuplicate,
        isLoadingPemodal,
        newEmail,
        newMobileNumber,
        prevIsLoadingPemodal,
        sendVerificationEmail,
        sendVerificationMobileNumber,
        setTemporaryEmail,
        setTemporaryMobileNumber,
        user,
    ])

    useEffect(() => {
        if (!isActionLoadingSendVerification && isActionSuccessSendVerification) {
            refetchUser({ email })
            if (changingField === 'email') {
                navigate('/profile/email')
            }
            if (changingField === 'phone') {
                navigate('/profile/phone')
            }
        }
        return () => {
            dispatch(clearVerification())
        }
    }, [
        clearVerification,
        changingField,
        dispatch,
        email,
        isActionLoadingSendVerification,
        isActionSuccessSendVerification,
        navigate,
        refetchUser,
    ])

    const handleFinish = async (values) => {
        return Promise.all(Array.from(isPemodal ? profilePicture : npwp).map(getBase64)).then((newFileList) => {
            const { mobileNumber, username } = values
            const baseData = {
                id,
                isUploading: true,
                mobileNumber,
                username,
            }
            if (isPemodal) {
                const { email } = values
                const data = Object.assign(baseData, {
                    detailUpdatedAt: dataUpdated(values),
                    email,
                    profilePicture: find(newFileList, (item) => item.fieldName === 'profilePicture'),
                })
                updatePemodal(data)
            }
            if (isPenerbit) {
                const {
                    address,
                    businessSectorId,
                    cityId,
                    description,
                    entityCode,
                    entityIdentificationNo,
                    entityName,
                    entityTypeId,
                    fundingAmountId,
                    postalCode,
                    productTypeId,
                    subdistrictName,
                    websiteUrl,
                } = values

                const districtId = get(find(districts, { subdistrict: subdistrictName }), 'id', null)

                const data = Object.assign(baseData, {
                    address,
                    businessSectorId,
                    cityId,
                    detailUpdatedAt: moment.utc().format(DATE_HOUR_SLASH_FORMAT),
                    description,
                    districtId,
                    entityCode,
                    entityIdentificationNo,
                    entityName,
                    entityTypeId,
                    fundingAmountId,
                    id,
                    penerbitDocuments: newFileList,
                    postalCode,
                    productTypeId,
                    websiteUrl,
                })

                updatePenerbit(data)
            }
        })
    }

    const handleReverify = (field) => () => {
        setChangingField(field)
        if (field === 'email') {
            getPemodalByEmail(newEmail)
        }
        if (field === 'phone') {
            getPemodalByMobileNumber('+62' + newMobileNumber)
        }
    }

    if (isLoadingPemodal || isLoadingPenerbit) {
        return <Loader />
    }

    if (isPemodal) {
        return (
            <PemodalForm
                {...props}
                isActionLoading={isActionLoadingUser}
                isLoggedIn={isLoggedIn}
                isUpdateAllowed={isUpdateAllowed}
                onFinish={handleFinish}
                onReverify={handleReverify}
                profilePicture={profilePicture}
            />
        )
    }
    if (isPenerbit) {
        return (
            <AntdSpace direction='vertical'>
                <StyledTitle color={colors.graniteGray} level={2}>
                    Profil Penerbit
                </StyledTitle>
                <PenerbitFormContainer
                    {...props}
                    isActionLoading={isActionLoadingUser}
                    isUpdateAllowed={isUpdateAllowed}
                    npwp={npwp}
                    onFinish={handleFinish}
                />
            </AntdSpace>
        )
    }
}

DetailForm.propTypes = {
    clearVerification: PropTypes.func,
    districts: PropTypes.array,
    email: PropTypes.string,
    getPemodalByEmail: PropTypes.func,
    getPemodalByMobileNumber: PropTypes.func,
    id: PropTypes.number,
    isActionLoadingSendVerification: PropTypes.bool,
    isActionLoadingUser: PropTypes.bool,
    isActionSuccessSendVerification: PropTypes.bool,
    isActionSuccessUser: PropTypes.bool,
    isDuplicate: PropTypes.bool,
    isLoadingPemodal: PropTypes.bool,
    isLoadingPenerbit: PropTypes.bool,
    isUpdateAllowed: PropTypes.bool,
    mobileNumber: PropTypes.string,
    refetchUser: PropTypes.func,
    sendVerificationEmail: PropTypes.func,
    sendVerificationMobileNumber: PropTypes.func,
    setTemporaryEmail: PropTypes.func,
    setTemporaryMobileNumber: PropTypes.func,
    updatePemodal: PropTypes.func,
    updatePenerbit: PropTypes.func,
    user: PropTypes.string,
    username: PropTypes.string,
}

export default DetailForm
