import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/purchaseOrder'
import * as purchaseOrderAPI from './purchaseOrderAPI'

const initialState = {
    initialValueTotal: 0,
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    pemodalStatement: 0,
    purchaseOrder: {},
    purchaseOrders: [],
    totalPurchaseOrders: 0,
    ytdValueTotal: 0,
}

export const { cancelPurchaseOrder, createPurchaseOrder, getPurchaseOrders, getPemodalTransactionSummary } =
    purchaseOrderAPI

const purchaseOrderSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(cancelPurchaseOrder.fulfilled, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
        builder.addCase(createPurchaseOrder.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
                purchaseOrder: data,
            })
        })
        builder.addCase(getPemodalTransactionSummary.pending, (state) =>
            Object.assign(state, {
                initialValueTotal: 0,
                isError: false,
                isLoading: true,
                pemodalStatement: 0,
                ytdValueTotal: 0,
            })
        )
        builder.addCase(getPemodalTransactionSummary.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                initialValueTotal: data.initialValueTotal,
                isLoading: false,
                pemodalStatement: data.pemodalStatement,
                ytdValueTotal: data.ytdValueTotal,
            })
        })
        builder.addCase(getPurchaseOrders.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                purchaseOrders: [],
            })
        )
        builder.addCase(getPurchaseOrders.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                purchaseOrders: map(data.purchaseOrders, (item) => toState(item)),
                totalPurchaseOrders: data.count,
            })
        })
        builder.addMatcher(isAnyOf(getPemodalTransactionSummary.rejected, getPurchaseOrders.rejected), (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
        builder.addMatcher(isAnyOf(cancelPurchaseOrder.pending, createPurchaseOrder.pending), (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addMatcher(isAnyOf(cancelPurchaseOrder.rejected, createPurchaseOrder.rejected), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true,
            })
        )
    },
    initialState,
    name: 'PURCHASE_ORDER',
    reducers: {
        clearPurchaseOrder: () => initialState,
    },
})

export const { clearPurchaseOrder } = purchaseOrderSlice.actions

const { reducer } = purchaseOrderSlice
export default reducer
