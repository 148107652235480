import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import PropTypes from 'prop-types'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import colors from '../../basic/colors'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { StyledAntdSpace, StyledImage } from '../StyledComponents'
import StyledTitle from '../styled_title/StyledTitle'

const Result = (props) => {
    const { content, description, imageSrc, title } = props
    const { dark } = useOutletContext()

    const image = <StyledImage src={imageSrc} />

    return (
        <AntdRow align='middle' gutter={[0, 30]}>
            <AntdCol md={12} sm={24} xs={24}>
                <AntdRow justify='center'>{renderLazyLoadImage(image)}</AntdRow>
            </AntdCol>
            <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                <StyledAntdSpace direction='vertical' size='large'>
                    <StyledTitle color={dark ? 'white' : colors.graniteGray} level={2}>
                        {title}
                    </StyledTitle>
                    <StyledTitle color={dark ? 'white' : colors.graniteGray} level={5}>
                        {description}
                    </StyledTitle>
                    {content}
                </StyledAntdSpace>
            </AntdCol>
        </AntdRow>
    )
}

Result.propTypes = {
    content: PropTypes.node,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    imageSrc: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default Result
