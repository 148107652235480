import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import colors from '../../basic/colors'
import StyledParagraph from '../styled_paragraph/StyledParagraph'
import StyledTitle from '../styled_title/StyledTitle'
import { StyledListContainer, StyledScopeContainer, StyledScopeTitle } from './StyledComponents'
import { StyledUnorderedList } from '../StyledComponents'
import map from 'lodash/map'

const PrivacyPolicyText = (props) => {
    const { dark, title } = props

    const listAccess = [
        'CAMERA : Otorisasi kami untuk mendapatkan akses ke kamera Anda atau mengambil gambar/video dari perangkat tersebut;',
        'NETWORK : Otorisasi kami untuk mendapatkan akses ke status jaringan internet Anda;',
        'LOCATION : Otorisasi kami untuk mendapatkan akses ke informasi lokasi Anda;',
        'MICROPHONE : Otorisasi kami untuk mendapatkan akses ke microphone Anda dan menggunakan microphone di perangkat tersebut;',
    ]

    const listIdentity = [
        'Nama Lengkap;',
        'Tanggal Lahir;',
        'Identitas (KTP) atau Paspor atau Nomor atau Keterangan Identifikasi lain;',
        'Alamat Sesuai KTP;',
        'Nomor Ponsel;',
        'Deskripsi dan Foto Pribadi;',
        'Informasi Perusahaan dan',
        'Informasi kontak darurat dan informasi lain yang terkait dengan informasi tambahan (seperti provinsi dan kota domisili), alamat e-mail, akun media sosial.',
    ]

    const listCondition = [
        'Memperoleh persetujuan dari Anda, untuk melindungi Layanan Urun Dana dan Perusahaan afiliasi Layanan Urun Dana, Perusahaan Cabang, dan pihak lain yang legal secara hukum. Melalui transaksi keuangan Anda dengan pihak lain, Penerbit Anda dapat mengetahui sebagian informasi akun Layanan Urun Dana Anda (termasuk nama terdaftar di akun, atau nama panggilan).',
        'Berdasarkan hukum yang berlaku atau permintaan lembaga berwenang tertentu.',
    ]

    return (
        <Fragment>
            <center>
                <StyledTitle $dark={dark} level={2}>
                    {title}
                </StyledTitle>
            </center>
            <br />
            <br />
            <StyledParagraph $textAlign='justify' color={colors.graniteGray}>
                Layanan Urun Dana Penawaran Saham Berbasis Teknologi Informasi merupakan kesepakatan perdata antara
                Pemodal dengan Penerbit, sehingga segala risiko yang timbul dari kesepakatan tersebut ditanggung
                sepenuhnya oleh masing-masing pihak. Tidak ada lembaga atau Otoritas Negara yang bertanggung jawab atas
                risiko gagal investasi ini. Pemodal yang belum memiliki pengetahuan dan pengalaman dalam hal pembelian
                saham, disarankan untuk tidak menggunakan layanan ini. Penerbit harus mempertimbangkan biaya sesuai
                dengan kemampuan dalam hal pengembalian saham dan dividen.
                <br />
                <br />
                Penyelenggara dengan persetujuan dari masing-masing Pengguna (Pemodal dan/atau Penerbit) berhak
                mengakses, memperoleh, menyimpan, mengelola dan/atau menggunakan data pribadi Pengguna (“Pemanfaatan
                Data”) pada atau di dalam benda, perangkat elektronik (termasuk smartphone atau telepon seluler),
                perangkat keras (hardware) maupun perangkat lunak (software), dokumen elektronik, aplikasi atau sistem
                elektronik milik Pengguna atau yang dikuasai Pengguna, dengan memberitahukan tujuan, batasan dan
                mekanisme Pemanfaatan Data tersebut kepada Pengguna yang bersangkutan sebelum memperoleh persetujuan
                yang dimaksud.
                <br />
                <br />
                Setiap kecurangan tercatat secara digital di dunia maya dan dapat diketahui masyarakat luas di media
                sosial. Pengguna harus membaca dan memahami informasi ini sebelum membuat keputusan menjadi Pemodal atau
                Penerbit. Pemerintah yaitu dalam hal ini Otoritas Jasa Keuangan, tidak bertanggung jawab atas setiap
                pelanggaran atau ketidakpatuhan oleh Pengguna, baik Pemodal maupun Penerbit (baik karena kesengajaan
                atau kelalaian Pengguna) terhadap ketentuan peraturan perundang-undangan maupun kesepakatan atau
                perikatan antara Penyelenggara dengan Pemodal dan/atau Penerbit. Setiap transaksi dan kegiatan penawaran
                saham atau pelaksanaan kesepakatan mengenai penawaran saham antara atau yang melibatkan Penyelenggara,
                Pemodal dan/atau Penerbit wajib dilakukan melalui escrow account dan rekening virtual account
                sebagaimana yang diwajibkan berdasarkan Peraturan Otoritas Jasa Keuangan Nomor 37/POJK.04/2018 yang
                disesuaikan dengan POJK terbaru saat ini, yaitu POJK NOMOR 57/POJK.04/2020 tentang Layanan Urun Dana
                melalui Penawaran Saham Berbasis Teknologi Informasi (Securities Crowdfunding) dan pelanggaran atau
                ketidakpatuhan terhadap ketentuan tersebut merupakan bukti telah terjadinya pelanggaran hukum oleh
                Penyelenggara sehingga Penyelenggara wajib menanggung ganti rugi yang diderita oleh masing-masing
                Pengguna sebagai akibat langsung dari pelanggaran hukum tersebut di atas tanpa mengurangi hak Pengguna
                yang menderita kerugian menurut Kitab Undang-Undang Hukum Perdata.
                <br />
                <br />
                Penyelenggara dengan persetujuan dari masing-masing Pengguna (Pemodal dan/atau Penerbit) berhak
                mengakses, memperoleh, menyimpan, mengelola dan/atau menggunakan data pribadi Pengguna (“Pemanfaatan
                Data”) pada atau di dalam benda, perangkat elektronik (termasuk smartphone atau telepon seluler),
                perangkat keras (hardware) maupun perangkat lunak (software), dokumen elektronik, aplikasi atau sistem
                elektronik milik Pengguna atau yang dikuasai Pengguna, dengan memberitahukan tujuan, batasan dan
                mekanisme Pemanfaatan Data tersebut kepada Pengguna yang bersangkutan sebelum memperoleh persetujuan
                yang dimaksud.
                <br />
                <br />
                Layanan Urun Dana (selanjutnya disebut PT Dana Saham Bersama) berkomitmen untuk menghormati serta
                melindungi privasi pengguna. PT Dana Saham Bersama berdasarkan dengan Perjanjian Privasi ini
                (selanjutnya disebut “Perjanjian”) mengumpulkan, menggunakan dan berbagi informasi pribadi Anda.
                Perjanjian ini mencakup persyaratan untuk pengumpulan, penyimpanan, perlindungan, penggunaan serta
                berbagi informasi pribadi Anda, kami sarankan Anda membaca terlebih dahulu seluruh isi Perjanjian ini,
                guna membantu Anda untuk mempelajari cara-cara melindungi hak privasi masing-masing.
                <br />
                <br />
                Agar Anda dapat sepenuhnya memahami Perjanjian ini, kami akan menggunakan pengetikan dengan pengaturan
                “cetak tebal” untuk menandakan persyaratan dalam Perjanjian ini yang ada atau mungkin memiliki hubungan
                material/penting dengan kepentingan Anda. Pada saat Anda menyetujui “Perjanjian Privasi dan Otorisasi”
                ini dalam Layanan Urun Dana, maka Anda dianggap telah menyetujui penggunaan yang sah serta perlindungan
                privasi Anda sesuai dengan Perjanjian ini.
                <br />
                <br />
                Dengan mengakses dan/atau menggunakan layanan-layanan yang tersedia melalui aplikasi ini, Anda dengan
                ini mengakui bahwa Anda telah membaca dan setuju untuk memberikan kami otorisasi untuk mengakses
                informasi dari perangkat Anda:
                <StyledListContainer>
                    <StyledUnorderedList $type='disc'>
                        {map(listAccess, (item, key) => (
                            <li key={key}>{item}</li>
                        ))}
                    </StyledUnorderedList>
                </StyledListContainer>
                Data yang diperoleh dari izin diatas akan kami kelola sesuai dengan standar ISO 27001.
                <br />
                <br />
                Kami mengumpulkan dan menggunakan informasi Anda dalam rangka mematuhi ketentuan undang-undang dan
                peraturan negara serta penyediaan layanan kepada Anda dan meningkatkan kualitas layanan (termasuk
                dukungan untuk pengembangan produk baru atau peningkatan fungsi produk yang ada bagi Anda dan pengguna
                lainnya, untuk memberikan kualitas pelayanan yang lebih baik). Jika Anda memberikan informasi
                sebagaimana diatur dalam perjanjian ini kepada kami, maka Anda dapat menggunakan fasilitas Layanan Urun
                Dana dan/atau menikmati layanan pelanggan yang lebih nyaman dan praktis, dan/atau memungkinkan kami
                untuk melindungi akun Anda dan keamanan keuangan, dan/atau mencegah dampak negatif terhadap Anda yang
                diakibatkan apabila kami tidak dapat menghubungi Anda secara tepat waktu.
                <br />
                <br />
                Bacalah hal-hal berikut ini dengan teliti untuk memahami pandangan dan kebiasaan kami sehubungan dengan
                data pribadi Anda dan bagaimana kami akan memperlakukannya.
                <br />
                <StyledScopeTitle>1. Ruang lingkup pengumpulan informasi pribadi</StyledScopeTitle>
                <StyledScopeContainer>
                    Ketika Anda mengunjungi website kami atau fasilitas layanan pengguna mobilitas kami, atau jika Anda
                    menggunakan Layanan Urun Dana lainnya, dari waktu ke waktu, kami dapat mengumpulkan informasi dan
                    data tentang Anda, termasuk informasi dan data dengan ketentuan:
                    <br />
                    a. Bahwa data tersebut diberikan oleh Anda atau diberikan oleh siapapun yang dizinkan oleh Anda;
                    <br />
                    b. Dalam kaitannya dengan setiap kunjungan Anda ke aplikasi kami dan
                    <br />
                    c. Dari para pihak ketiga lainnya yang diizinkan oleh Anda (seperti afiliasi dan rekan bisnis).
                </StyledScopeContainer>
                Dalam mengumpulkan informasi yang kami maksud diatas, kami akan menggunakan sistem keamanan yang
                terenkripsi untuk menyimpan dan mengumpulkan informasi pribadi Anda yang bersifat rahasia.
                <br />
                <br />
                Untuk memenuhi ketentuan perundang-undangan, serta memberikan Anda layanan yang lebih personal dan
                mudah/praktis, kami perlu mengidentifikasi identitas Anda. Ketika Anda mendaftar akun pada Layanan Urun
                Dana atau menggunakan jasa Layanan Urun Dana yang tersedia, Anda perlu memberikan beberapa informasi
                pribadi kepada kami, termasuk tetapi tidak terbatas, seperti:
                <br />
                <StyledUnorderedList $type='lower-alpha'>
                    {map(listIdentity, (item, key) => (
                        <li key={key}>{item}</li>
                    ))}
                </StyledUnorderedList>
                Apabila Layanan Urun Dana yang Anda gunakan perlu dihubungkan dengan rekening Bank atau rekening lain
                yang terkait agar proses penawaran saham dapat tercapai, maka Anda perlu memberikan informasi mengenai
                nomor Rekening Bank Anda, atau media pembayaran lain yang terkait kepada kami.
                <br />
                <br />
                Untuk mendaftarkan akun Layanan Urun Dana, Anda perlu menyediakan informasi berikut ini:
                <br />
                a. Lokasi permohonan pendaftaran;
                <br />
                b. Informasi identitas;
                <br />
                c. Informasi pengenalan wajah orang (syarat ini hanya berlaku untuk aplikasi yang membutuhkan);
                <br />
                d. Informasi kontak darurat dan
                <br />
                e. Informasi keuangan
                <br />
                Untuk mempermudah Anda login dengan cepat ke akun Layanan Urun Dana, selain dengan menggunakan kode
                verifikasi yang akan kami kirimkan ke nomor telepon seluler Anda, Anda juga dapat memilih untuk login
                menggunakan kata sandi yang Anda atur saat menggunakan Layanan Urun Dana. Sistem akan mencatat status
                login Anda sehingga Anda dapat lebih cepat menggunakannya. Untuk keamanan dan penyimpanan data, Layanan
                Urun Dana dapat menyimpan informasi akun dan kata sandi Anda dan data yang terkait dengan Anda pada
                server Layanan Urun Dana. Untuk mengetahui status fasilitas Anda, kami akan memberikan konfirmasi untuk
                verifikasi resmi melalui pihak ketiga. Untuk mempermudah Anda memeriksa status transaksi Anda atau
                riwayat transaksi Anda, dan juga untuk mematuhi hukum dan peraturan yang berlaku, kami akan menyimpan
                informasi transaksi Anda yang dihasilkan dari transaksi melalui Layanan Urun Dana sesuai hukum dan
                peraturan yang berlaku.
                <br />
                <br />
                Untuk sepenuhnya melindungi keamanan akun Anda, ketika Anda mengunjungi website Layanan Urun Dana dan
                situs terkait, atau Layanan Urun Dana melalui perangkat mobile client kami dapat mencatat/merekam
                informasi login operasi Anda, termasuk namun tidak terbatas, alamat IP, perangkat pengenal permanen
                (seperti: SSAID, alamat MAC, IMEI dan lain-lain), model perangkat keras, versi sistem operasi komputer
                Anda, lokasi Anda dan log informasi yang berkaitan dengan jasa Layanan Urun Dana, informasi ini dapat
                membantu kami untuk lebih memahami Anda dan melindungi keamanan akun Anda, seperti akun login Anda dari
                aktivitas yang mencurigakan. Sistem Layanan Urun Dana dapat memantau risiko yang akan terjadi pada akun
                Anda, dan mengambil langkah-langkah pencegahan untuk menghindari kerugian materil yang mungkin akan
                terjadi pada Anda.
                <br />
                <br />
                Selain informasi di atas, kami juga dapat mengumpulkan informasi tambahan tentang kebutuhan data dan
                meningkatkan kualitas layanan untuk Anda, termasuk informasi tentang Layanan Urun Dana, informasi yang
                Anda berikan saat menghubungi tim layanan pelanggan kami, termasuk kuisioner tanya jawab yang akan Anda
                isi pada saat survei Layanan Urun Dana, serta informasi tentang Anda yang kami dapatkan dari perusahaan
                afiliasi Layanan Urun Dana dan kerjasama interaktif perusahaan komersial yang kami kumpulkan. Pada saat
                yang sama, dalam rangka meningkatkan keamanan penggunaan, Layanan Urun Dana memberikan pencegahan
                penipuan dan ancaman virus yang lebih tepat dan ampuh, kami mungkin memahami beberapa kebiasaan Anda
                dalam menggunakan jaringan, informasi software yang sering Anda gunakan dan cara lain untuk menentukan
                risiko akun, serta mungkin mencatat beberapa URL yang menurut kami berisiko.
                <br />
                <br />
                &nbsp;&nbsp;2. Penggunaan Cookie
                <br />
                Agar Anda memiliki pengalaman mengakses situs dengan lebih rileks dan mudah, saat Anda mengunjungi situs
                Layanan Urun Dana atau saat menggunakan layanan yang disediakan oleh Layanan Urun Dana, kami mungkin
                akan mengenali identitas Anda melalui data kecil, hal ini dilakukan untuk membantu Anda menghemat waktu
                untuk login serta membantu memastikan bahwa akun Anda aman. Data kecil yang dimaksud ialah berupa
                cookie, flash cookie, atau mesin penelusuran atau perangkat lainnya yang berkaitan dengan penyimpanan
                lainnya (umumnya disebut “cookie”). Perlu Anda pahami bahwa beberapa layanan kami hanya bisa melalui
                penggunaan “cookie”. Jika search engine/mesin penelusuran atau perangkat pendukung search engine/mesin
                penelusuran Anda memungkinkan, Anda bisa mengubah tingkat penerimaan cookie atau menolak cookie dari
                Layanan Urun Dana, akan tetapi tindakan ini di beberapa kondisi akan mempengaruhi keamanan saat
                mengunjungi website atau menggunakan Layanan Urun
                <br />
                <br />
                &nbsp;&nbsp;3. Dana.Perlindungan dan Penyimpanan Informasi Pribadi
                <br />
                Untuk menjamin keamanan data Anda, kami akan melakukan upaya yang wajar untuk memastikan tindakan
                pengamanan dengan berbagai macam bentuk fisik, teknologi dan kontrol yang sesuai, sehingga data Anda
                tidak akan disebarluaskan, rusak maupun hilang, termasuk tidak terbatas pada SSL, penyimpanan dengan
                pengamanan ekstra dan kontrol pada pusat data. Kami juga menerapkan pengendalian yang ketat terhadap
                karyawan yang mungkin berhubungan dengan data Anda, termasuk dengan menerapkan kontrol dan akses pada
                tingkatan dan jabatan yang berbeda, menandatangani kontrak kerahasiaan dengan para karyawan tersebut,
                serta memantau situasi kerja mereka dan juga tindakan lainnya.Layanan Urun Dana akan menyediakan
                fasilitas pengamanan untuk melindungi data Anda berdasarkan sistem yang ada sekarang dan yang sesuai,
                menyediakan jaminan keamanan yang logis dan sesuai, Layanan Urun Dana akan selalu berusaha setiap saat
                menjaga agar informasi Anda tidak tersebar, tidak rusak dan tidak hilang. Data Anda tersimpan pada
                server Layanan Urun Dana, untuk kebutuhan keamanan dan cadangan data, Layanan Urun Dana mungkin
                menyimpan informasi dan data Anda pada server perusahaan afiliasi yang berkaitan. Kami akan memastikan
                data pribadi Anda yang ada di bawah penguasaan atau berada di bawah kendali kami akan dimusnahkan
                dan/atau dijadikan bersifat anonim sesegera mungkin saat dianggap wajar untuk mengasumsikan bahwa
                penyimpanan tidak lagi perlu dilakukan untuk tujuan hukum atau bisnis lain apapun.
                <br />
                <br />
                &nbsp;&nbsp;4. Penggunaan dan Pengungkapan Informasi dan Data Pribadi
                <br />
                Karena penyimpanan informasi Anda diatur dalam Peraturan Perundang-Undangan yang berlaku, dan juga
                dengan tujuan menyediakan dan meningkatkan kualitas pelayanan, maka untuk merealisasikan hal tersebut,
                kami akan menggunakan informasi Anda untuk kebutuhan berikut ini:
                <br />
                a. Menyediakan berbagai layanan keuangan dan layanan pengguna dari Layanan Urun Dana, serta melindungi
                dan meningkatkan layanan-layanan tersebut;
                <br />
                b. Membandingkan keakuratan informasi, serta melakukan proses verifikasi dengan pihak ketiga. Seperti,
                verifikasi data pribadi dan kartu identitas yang Anda berikan kepada organisasi/lembaga yang melakukan
                verifikasi. Agar Anda mengetahui kondisi penggunaan Layanan Urun Dana atau memahami layanan Layanan Urun
                Dana, kami akan mengirimkan pemberitahuan status layanan, program pemasaran serta informasi teknologi
                lainnya. Hal tersebut akan digunakan untuk mengetahui jumlah pengguna Layanan Urun Dana, mengakumulasi
                informasi transaksi, menganalisa atau menambah, untuk penjualan, penghargaan, atau supaya Anda memiliki
                lingkaran sosial yang lebih luas;
                <br />
                c. Informasi pengenalan wajah orang (syarat ini hanya berlaku untuk aplikasi yang membutuhkan);
                <br />
                d. Meningkatkan konten aplikasi kami untuk memastikan bahwa konten situs, produk, layanan, layanan
                interaktif, konten pihak ketiga dan situs pihak ketiga disampaikan dengan cara yang paling efektif untuk
                Anda melalui aplikasi kami;
                <br />
                e. Memenuhi persyaratan-persyaratan hukum, regulasi dan kepatuhan di dalam dan luar Negeri berdasarkan
                peraturan hukum yang berlaku (termasuk anti pencucian uang dan pencegahan pendanaan terorisme serta
                kewajiban pajak yang berlaku pada kami, dan pengungkapan pada setiap bursa efek, pengadilan, otoritas
                pemerintah, otoritas pengawas atau regulator lain apapun sesuai dengan pedoman, peraturan, perintah,
                petunjuk atau permintaan yang relevan dari organisasi-organisasi tersebut) dan mematuhi setiap
                perjanjian internasional atau perjanjian yang berlaku dengan atau antara pemerintah asing dan dalam
                Negeri yang berlaku;
                <br />
                f. Memantau dan mencatat panggilan dan komunikasi elektronik dengan Anda yang bertujuan untuk
                pencatatan, kualitas, pelatihan, investigasi, dan pencegahan penipuan;
                <br />
                g. Mendeteksi, mencegah, melakukan penyidikan dan mendakwa kejahatan dan penipuan termasuk membantu
                dalam setiap penyidikan kejahatan oleh pihak berwenang yang relevan atau pihak ketiga yang relevan
                terhadap Anda dan; h. Kebutuhan-kebutuhan lainnya yang belum diatur dalam Perjanjian ini, namun akan
                dibutuhkan di masa depan berdasarkan persetujuan Anda.
                <br />
                <br />
                &nbsp;&nbsp;5. Syarat dan Ketentuan Penerimaan Kuasa
                <br />
                Terhadap informasi yang Anda berikan, sebagaimana disebutkan pada point nomor 1, maka Anda akan menerima
                kuasa/hak yang diberikan atau bisa diterima dari PT Dana Saham Bersama atau segala perusahan afiliasi
                yang khusus, berlaku seluruh dunia, seumur hidup, gratis (serta berhak menerima kuasa dalam berbagai
                aspek lainnya). Selain itu, PT Dana Saham Bersama dan afiliasinya berhak (seluruh atau sebagian)
                menggunakan, menyalin, mengubah atau menyesuaikan, mengedit/mengubah, meluncurkan, menerjemahkan,
                mendistribusikan, melaksanakan serta menampilkan seluruh angka data Anda. PT Dana Saham Bersama dan
                afiliasinya akan secara bertahap memperbolehkan pengguna Layanan Urun Dana mendaftar pada platform serta
                layanan lainnya. Pengguna Layanan Urun Dana pada platform segala kegiatan diminta untuk menaati
                peraturan sesuai kesepakatan, peraturan yang diumumkan platform serta mengenai penjelasan dan tata acara
                penggunaan yang benar dan tepat. Untuk merealisasikan fungsi yang disebut di atas, Anda menyetujui bahwa
                informasi, pembayaran atas transaksi, angka lainnya yang terdaftar pada PT Dana Saham Bersama dan juga
                afiliasinya serta setuju untuk digunakan. Anda memahami dan menyetujui, saat Anda mendaftar untuk
                menggunakan aplikasi Layanan Urun Dana, serta memberikan kuasa atau hak kepada PT Dana Saham Bersama
                untuk menggunakan segala informasi dan data keuangan yang Anda berikan untuk menentukan jumlah fasilitas
                serta menentukan apakah pendaftaran Anda berhasil. Anda memahami dan menyetujui, memberikan hak kepada
                PT Dana Saham Bersama untuk menggunakan informasi yang Anda daftarkan pada saat pendaftaran dan
                penggunaan layanan keuangan kami seperti nama lengkap, kartu identitas, alamat, nomor telepon, informasi
                Perusahaan, karakteristik biologis serta informasi lainnya yang berkaitan (seperti provinsi atau kota
                domisili Anda). Anda memahami serta menyetujui, jika Anda memberikan informasi dan memberikan hak atas
                informasi kartu/rekening Bank, maka hal tersebut memungkinkan PT Dana Saham Bersama untuk:
                <br />
                a. Memeriksa informasi rekening Anda;
                <br />
                b. Memeriksa dan membaca info transaksi rekening Bank.
                <br />
                Berdasarkan (Perjanjian) melalui pemberian kuasa atau pengikatan rekening Bank melakukan layanan
                penerimaan atau layanan pembayaran. Anda memahami dan menyetujui, saat Anda mendaftar dan menggunakan
                aplikasi Layanan Urun Dana, hal ini memungkinkan PT Dana Saham Bersama berdasarkan peraturan hukum yang
                ada untuk membayarkan kepada pihak ketiga dan membuktikan informasi Anda. Semua data pribadi yang
                diperoleh hanya akan digunakan di layanan Layanan Urun Dana, serta tidak akan disebarkan ke
                organisasi/lembaga lainnya. Anda memahami dan menyetujui, jika Anda menggunakan layanan pembayaran, maka
                Anda harus melakukan pembayaran berdasarkan peraturan (Syarat dan Ketentuan Layanan Urun Dana), kemudian
                PT Dana Saham Bersama akan mengirimkan email yang berisi nominal biaya yang harus dibayarkan dan Nomor
                Rekening Virtual Account yang digunakan sebagai rekening tujuan pembayaran. Anda memahami dan menyetujui
                dalam proses pendaftaran, Bank yang ditentukan atau lembaga pihak ketiga lainnya yang dalam rangka
                memberikan layanan yang lebih baik berhak menerima informasi Anda. Anda memahami dan menyetujui bahwa
                kami akan menyimpan data asli Anda dan kami dapat meminta ulang serta memperbarui setiap saat. Terhadap
                data yang telah melalui penambahan, data tersebut akan kami simpan pada server Layanan Urun Dana. Anda
                memahami dan menyetujui bahwa Anda tidak bisa membatalkan pemberian kuasa kepada PT Dana Saham Bersama,
                afiliasinya/pihak ketiga yang sah untuk melakukan verifikasi atas data yang Anda berikan. Anda
                menyetujui bahwa adanya pengumpulan informasi serta menyetujui untuk memberikan informasi tersebut
                kepada afiliasi dan juga pihak ketiga yang berkaitan sah serta berkaitan dengan kegiatan operasional dan
                pada saat yang sama menyetujui PT Dana Saham Bersama, afiliasinya/pihak ketiga yang secara sah untuk
                memeriksa dan mengumpulkan data, menyimpan, mengadministrasikan dokumen, mengedit/mengubah (tetapi di
                luar informasi yang dilarang oleh hukum, peraturan dan kebijakan aparat hukum).
                <br />
                <br />
                &nbsp;&nbsp;6. Pembagian Informasi Pribadi
                <br />
                Kami memiliki kewajiban dan jaminan atas data Anda, akan tetapi kami akan mengelola dengan pihak ketiga
                yang tunjuk sesuai dengan ISO 27001, dalam kondisi di bawah ini:
                <StyledListContainer>
                    <StyledUnorderedList $type='disc'>
                        {map(listCondition, (item, key) => (
                            <li key={key}>{item}</li>
                        ))}
                    </StyledUnorderedList>
                </StyledListContainer>
                &nbsp;&nbsp;7. Pemeriksaan dan Pengelolaan Informasi Pribadi
                <br />
                Anda dapat memeriksa atau mengelola informasi akun Anda pada saat setiap login ke akun Layanan Urun Dana
                Anda, jika Anda menemukan kendala, dapat menghubungi customer service kami pada Layanan Urun Dana.
                <br />
                <br />
                &nbsp;&nbsp;8. Penjelasan/ Klarifikasi tanggung jawab terhadap Pihak Ketiga
                <br />
                Mohon Anda perhatikan, pihak Penerbit, pengelola situs pihak ketiga, pihak ketiga penyedia layanan Anda
                dan pihak ketiga yang menerima informasi Anda dari Layanan Urun Dana kemungkinan akan mempunyai
                peraturan pengamanan privasi tertentu, saat Anda memeriksa situs yang dikembangkan oleh pihak ketiga
                atau menggunakan perangkat yang dikembangkan oleh pihak ketiga, kemungkinan akan menemukan situs atau
                program bersangkutan yang memiliki tanda cookie. Cookie atau tanda tersebut tidak dalam pengawasan dan
                tanggung jawab kami, selain itu penggunaan cookie tidak termasuk dalam ikatan perjanjian ini.
                <br />
                <br />
                &nbsp;&nbsp;9. Ruang lingkup pengumpulan informasi pribadi
                <br />
                Kami lebih mengutamakan memberikan layanan kepada penduduk yang berusia 21 tahun ke atas. Jika Anda
                belum berusia 21 tahun, mohon agar Anda tidak memberikan informasi dan data apapun kepada kami, dan kami
                juga tidak menyarankan Anda untuk menggunakan Layanan Urun Dana.Pada dasarnya, kami tidak dapat
                membedakan usia orang yang mengakses dan menggunakan aplikasi ini. Jika seorang anak di bawah umur
                (menurut hukum yang berlaku) telah memberikan data pribadinya kepada kami tanpa persetujuan orang tua
                atau walinya, orang tua atau walinya itu harus menghubungi kami untuk menghapus data pribadi yang
                terkait dan menghapus nama anak di bawah umur tersebut dari daftar pelanggannya.
                <br />
                <br />
                &nbsp;&nbsp;10. Lingkup Penggunaan
                <br />
                Perjanjian ini merupakan bagian yang tidak tepisahkan dari (Syarat dan Ketentuan Layanan Urun Dana),
                jika terkait Layanan Urun Dana lainnya yang memerlukan peraturan khusus, perjanjian ini akan digunakan
                saat Anda mengunjungi situs Layanan Urun Dana serta situs berkaitan lainnya.
                <br />
                <br />
                &nbsp;&nbsp;11. Perubahan Perjanjian
                <br />
                Kami sewaktu-waktu akan melakukan perubahan pada perjanjian, dikarenakan pangsa pasar pengguna Layanan
                Urun Dana sangat luas dan beragam, jika terjadi suatu perubahan, kami akan memilih untuk mengumumkannnya
                melalui situs bukan menghubungi Anda secara personal, perubahan yang dimaksud akan berlaku saat
                diumumkan serta mendapatkan persetujuan dari Anda. Kami akan menganggap Anda telah memahami serta
                menerima perikatan setelah perubahan perjanjian. Harap secara berkala memeriksa kembali Layanan Urun
                Dana untuk melihat pengkinian (update) atau perubahan terhadap kebijakan privasi kami.
                <br />
                <br />
                &nbsp;&nbsp;12. Pengakuan
                <br />
                Anda mengakui bahwa apabila Anda tidak memberikan informasi atau data yang relevan atau mendapat
                persetujuan sehubungan dengan pengumpulan, penggunaan dan/atau pengungkapan informasi atau data yang
                relevan sebagaimana dijelaskan dalam Perjanjian ini, kami memiliki hak untuk tidak dapat membuka atau
                melanjutkan akun Anda atau mengakses aplikasi kami.
            </StyledParagraph>
        </Fragment>
    )
}

PrivacyPolicyText.propTypes = {
    dark: PropTypes.bool,
    title: PropTypes.string,
}

export default PrivacyPolicyText
