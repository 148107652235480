import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/favorite'
import * as favoriteAPI from './favoriteAPI'

const initialState = {
    favorites: [],
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    totalFavorites: 0,
}

export const { createFavorite, deleteFavorite, getFavorites } = favoriteAPI

const favoriteSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getFavorites.pending, (state) =>
            Object.assign(state, {
                favorites: [],
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(getFavorites.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                favorites: map(data.favorites, (item) => toState(item)),
                isLoading: false,
                totalFavorites: data.count,
            })
        })
        builder.addCase(getFavorites.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
        builder.addMatcher(isAnyOf(createFavorite.pending, deleteFavorite.pending), (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addMatcher(isAnyOf(createFavorite.fulfilled, deleteFavorite.fulfilled), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
        builder.addMatcher(isAnyOf(createFavorite.rejected, deleteFavorite.rejected), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true,
            })
        )
    },
    initialState,
    name: 'FAVORITE',
    reducers: {
        clearFavorite: () => initialState,
    },
})

export const { clearFavorite } = favoriteSlice.actions

const { reducer } = favoriteSlice
export default reducer
