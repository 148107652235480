import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../api'

export const getDividendReports = createAsyncThunk(
    'GET_DIVIDEND_REPORTS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/product-documents', {
                limit,
                offset,
                options: Object.assign(options, {
                    identifier: 'dividendReports',
                }),
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const getFinancialReports = createAsyncThunk(
    'GET_FINANCIAL_REPORTS',
    async ({ limit, offset, options } = {}, { rejectWithValue }) => {
        try {
            const res = await api.fetchAll('/product-documents', {
                limit,
                offset,
                options: Object.assign(options, {
                    identifier: 'financialReports',
                }),
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
