import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider as AntdDivider } from 'antd'

class Divider extends Component {
    render() {
        const { className, dashed, style, type } = this.props

        return (
            <AntdDivider className={className} dashed={dashed} style={style} type={type}>
                {this.props.children}
            </AntdDivider>
        )
    }
}

Divider.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dashed: PropTypes.bool,
    style: PropTypes.object,
    type: PropTypes.string,
}
Divider.defaultProps = {
    dashed: false,
    type: 'horizontal',
}
export default Divider
