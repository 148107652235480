import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import concat from 'lodash/concat'
import get from 'lodash/get'
import join from 'lodash/join'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { BackButton, Button, InputSearch, Table, TableRowActions, Tooltip } from '../antdcomponents'
import imagePath from '../basic/imagePath'
import { isAuthorizedUser } from '../common/helper/authorization'
import HelmetMeta from '../components/HelmetMeta'
import PurchaseOrderFilterContainer from '../components/purchase_order_filter/PurchaseOrderFilterContainer'
import PurchaseOrderFilterDrawer from '../components/purchase_order_filter_drawer/PurchaseOrderFilterDrawer'
import { StyledAntdSpace, StyledSection } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'
import * as productConfig from './config/productConfig'
import * as purchaseOrderConfig from './config/purchaseOrderConfig'

const Fund = (props) => {
    const { clearProduct, getFunds, isLoading, products } = props
    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, pathname, search } = useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })

    const productTypeTitle = get(query, 'type', 'all')
    const purchaseOrderSearch = get(query, 'order', '')
    const status = get(query, 'status', 'all')

    const initialValues = { productTypeTitle, status }
    const [isOpen, setIsOpen] = useState(false)
    const filterDarkImage = <img src={imagePath.filterDark} />

    const tooltipInfo = <Tooltip title='Mencakup detail pembelian , pembagian dividen, dan pengembalian dana' />

    const tooltipSettlementDate = <Tooltip title='Tanggal kepemilikan sebenarnya dari pendanaan sukses oleh pemodal' />

    useEffect(() => {
        if (!isMobile()) {
            setIsOpen(false)
        }
    }, [setIsOpen])

    useEffect(() => {
        const fetchFunds = async () => {
            const options = {
                productTypeTitle,
                purchaseOrderSearch,
                orderBy: {
                    by: 'createdAt',
                    order: 'DESC',
                },
                status,
            }
            await getFunds({ options })
        }
        fetchFunds()
        return () => {
            dispatch(clearProduct())
        }
    }, [clearProduct, dispatch, getFunds, productTypeTitle, purchaseOrderSearch, status])

    const handleActions = (key, type) => {
        const product = products[key]
        if (type === 'view') {
            const slug = get(product, 'slug', '')
            navigate(`/product/${slug}`)
        }
        return null
    }

    const handleFinish = (values) => {
        const { productTypeTitle, status } = values
        let urlParams = []
        if (purchaseOrderSearch) {
            urlParams = concat(urlParams, `order=${purchaseOrderSearch}`)
        }
        if (status && status !== 'all') {
            urlParams = concat(urlParams, `status=${status}`)
        }
        if (productTypeTitle && productTypeTitle !== 'all') {
            urlParams = concat(urlParams, `type=${productTypeTitle}`)
        }
        urlParams = join(urlParams, '&')
        setIsOpen(false)
        navigate(`funds?${urlParams}`)
    }

    const handleOpenFilter = () => setIsOpen((prevState) => !prevState)

    const handleReset = () => {
        setIsOpen(false)
        navigate(`/funds`)
    }

    const handleSearch = (value) => {
        if (value !== '') {
            navigate(`/funds?order=${value}`)
        } else {
            navigate(`/funds`)
        }
    }

    const renderActions = (text, record, index) => (
        <TableRowActions options={productConfig.rowActions(index, handleActions, record)} />
    )

    const renderExpandedRow = (record) => {
        const { purchaseOrders } = record

        return (
            <AntdRow>
                <AntdCol offset={1}>
                    <Table
                        columns={purchaseOrderConfig.columns}
                        dataSource={purchaseOrderConfig.dataSource(purchaseOrders)}
                    />
                </AntdCol>
            </AntdRow>
        )
    }

    const metaTitle = 'Listing Pendanaan'

    const renderTitleAndSearchBox = () => (
        <AntdRow align='middle' gutter={[0, 10]} justify={isMobile() ? 'center' : 'end'}>
            <AntdCol md={17} sm={24} xs={24}>
                <AntdRow align='middle' gutter={[15, 0]} justify={isMobile() ? 'center' : 'end'}>
                    <AntdCol>
                        <StyledTitle $dark={dark} level={2}>
                            {metaTitle}
                        </StyledTitle>
                    </AntdCol>
                    <AntdCol>{tooltipInfo}</AntdCol>
                </AntdRow>
            </AntdCol>
            <AntdCol md={0} sm={2} xs={2}>
                <center>
                    <Button
                        dark={dark}
                        name='open-filter-btn'
                        onClick={handleOpenFilter}
                        type='text'
                        value={renderLazyLoadImage(filterDarkImage)}
                    />
                </center>
            </AntdCol>
            <AntdCol md={{ offset: 1, span: 6 }} sm={{ offset: 1, span: 21 }} xs={{ offset: 1, span: 21 }}>
                <AntdRow justify='end'>
                    <InputSearch
                        dark={dark}
                        defaultValue={purchaseOrderSearch}
                        loading={isLoading}
                        name='order'
                        onSearch={handleSearch}
                        placeholder='Cari Transaksi'
                    />
                </AntdRow>
            </AntdCol>
        </AntdRow>
    )

    const renderFilterAndTable = () => (
        <AntdRow>
            <AntdCol md={5} sm={0} xs={0}>
                <PurchaseOrderFilterContainer
                    handleFinish={handleFinish}
                    handleReset={handleReset}
                    initialValues={initialValues}
                    isFundPage={true}
                />
            </AntdCol>
            <AntdCol md={{ offset: 1, span: 18 }} sm={24}>
                <Table
                    columns={productConfig.columns(tooltipSettlementDate, renderActions)}
                    dataSource={productConfig.dataSource(products)}
                    expandedRowRender={renderExpandedRow}
                    loading={isLoading}
                />
            </AntdCol>
        </AntdRow>
    )

    if (!isLoggedIn) {
        navigate('/login')
        return null
    }

    if (!isAuthorizedUser(isPemodal, isPenerbit, pathname)) {
        navigate('/profile')
        return null
    }

    return (
        <>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <PurchaseOrderFilterDrawer
                handleFinish={handleFinish}
                handleReset={handleReset}
                initialValues={initialValues}
                isFundPage={true}
                isOpen={isOpen}
                onClose={handleOpenFilter}
            />
            <StyledSection>
                <StyledAntdSpace direction='vertical' size='large'>
                    <BackButton route='/profile' />
                    {renderTitleAndSearchBox()}
                    {renderFilterAndTable()}
                </StyledAntdSpace>
            </StyledSection>
        </>
    )
}

Fund.propTypes = {
    clearProduct: PropTypes.func,
    getFunds: PropTypes.func,
    isLoading: PropTypes.bool,
    products: PropTypes.array,
}

export default Fund
