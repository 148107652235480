import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import concat from 'lodash/concat'
import isUndefined from 'lodash/isUndefined'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import { BackButton, Button, Form, ReduxFormInputOtp } from '../../antdcomponents'
import colors from '../../basic/colors'
import { DATE_HOUR_SLASH_FORMAT, MINUTE_SECOND_FORMAT } from '../../constants/formats'
import { otpCheck, otpCode } from '../../utility/formValidation'
import { renderLink } from '../../utility/renderLinkComp'
import { StyledAntdSpace } from '../StyledComponents'
import StyledTitle from '../styled_title/StyledTitle'

const VerifyOtpForm = (props) => {
    const {
        email,
        handleSubmit,
        id,
        isActionLoadingPemodal,
        isActionLoadingSendVerification,
        isActionLoadingVerify,
        isLoading,
        isValidUser,
        mobileNumber,
        onClick,
        onFinish,
        page,
        pathname,
        sendVerificationEmail,
        sendVerificationMobileNumber,
        temporaryEmail,
        title,
        tokenExpiryDate,
        user,
    } = props
    const { dark, isPemodal } = useOutletContext()

    const isVerifyingMobileNumber = isPemodal && (page === 3 || pathname === '/profile/phone')
    const isActionLoading =
        isActionLoadingPemodal || isActionLoadingSendVerification || isActionLoadingVerify || isLoading
    const emailBeingVerified = isValidUser && temporaryEmail ? temporaryEmail : email

    const baseValidate = [otpCode]
    const validate =
        isVerifyingMobileNumber && process.env.REACT_APP_IS_DEV === 'true'
            ? concat(baseValidate, otpCheck)
            : baseValidate

    const [otpCountdown, setOtpCountdown] = useState(0)

    useEffect(() => {
        const otpTimer = window.setInterval(() => {
            const startDate = moment()
            const endDate = moment(tokenExpiryDate, DATE_HOUR_SLASH_FORMAT)
            const difference = endDate.diff(startDate)
            setOtpCountdown(difference / 1000)
        }, 1000)
        return () => clearInterval(otpTimer)
    }, [tokenExpiryDate]) //
    const remainingMinutes = moment.utc(otpCountdown * 1000).format(MINUTE_SECOND_FORMAT)

    const handleSendVerification = () => {
        let data = {
            email,
            id,
            user,
        }
        if (isValidUser) {
            data = Object.assign(data, {
                new_email: temporaryEmail,
            })
        }
        isVerifyingMobileNumber
            ? sendVerificationMobileNumber({ new_mobile_number: '+62' + mobileNumber })
            : sendVerificationEmail(data)
    }

    const renderRemainingMinutes = () => {
        if (otpCountdown < 0 || isUndefined(tokenExpiryDate)) {
            return renderLink(
                { to: '#' },
                <StyledTitle $dark={dark} level={5} onClick={handleSendVerification}>
                    Kirim ulang kode OTP
                </StyledTitle>
            )
        }
        return remainingMinutes
    }

    return (
        <Form onFinish={handleSubmit(onFinish)}>
            <StyledTitle $dark={dark} color={colors.graniteGray} level={2}>
                {title}
            </StyledTitle>
            <br />
            <StyledTitle $dark={dark} color={colors.graniteGray} level={5}>
                6 kode OTP (One Time Password) telah dikirimkan ke{' '}
                {isVerifyingMobileNumber ? `+62${mobileNumber}` : emailBeingVerified}, masukkan kode dalam waktu{' '}
                {isVerifyingMobileNumber ? 10 : 30} menit.
            </StyledTitle>
            <br />
            <Field
                component={ReduxFormInputOtp}
                formItemProps={{ isHideLabel: true, required: true }}
                name='otp'
                validate={validate}
            />
            <StyledAntdSpace direction='vertical' size='large'>
                <AntdSpace direction='vertical'>
                    <AntdSpace>
                        <StyledTitle color={colors.graniteGray} level={5}>
                            Belum menerima kode OTP?
                        </StyledTitle>
                        <StyledTitle $dark={dark} level={5}>
                            {!isLoading && renderRemainingMinutes()}
                        </StyledTitle>
                    </AntdSpace>
                    <AntdSpace>
                        <StyledTitle color={colors.graniteGray} level={5}>
                            Kesalahan pada input data?
                        </StyledTitle>
                        <BackButton icon={null} onClick={onClick} />
                    </AntdSpace>
                </AntdSpace>
                <AntdRow>
                    <AntdCol offset={6} span={12}>
                        <Button
                            dark={dark}
                            htmlType='submit'
                            loading={isActionLoading}
                            name='otp-verify-btn'
                            value='Verifikasi'
                        />
                    </AntdCol>
                </AntdRow>
            </StyledAntdSpace>
        </Form>
    )
}

VerifyOtpForm.propTypes = {
    email: PropTypes.string,
    handleSubmit: PropTypes.func,
    id: PropTypes.number,
    isActionLoadingPemodal: PropTypes.bool,
    isActionLoadingSendVerification: PropTypes.bool,
    isActionLoadingVerify: PropTypes.bool,
    isLoading: PropTypes.bool,
    isValidUser: PropTypes.bool,
    mobileNumber: PropTypes.string,
    onClick: PropTypes.func,
    onFinish: PropTypes.func,
    page: PropTypes.number,
    pathname: PropTypes.string,
    sendVerificationEmail: PropTypes.func,
    sendVerificationMobileNumber: PropTypes.func,
    temporaryEmail: PropTypes.string,
    title: PropTypes.string,
    tokenExpiryDate: PropTypes.string,
    user: PropTypes.string,
}

export default VerifyOtpForm
