import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Fragment, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { BackButton } from '../../antdcomponents'
import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import Loader from '../../components/loader/Loader'
import { StyledImage, StyledSection } from '../../components/StyledComponents'
import StyledTitle from '../../components/styled_title/StyledTitle'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { renderLinkButton } from '../../utility/renderLinkComp'

const DigisignActivationStatus = (props) => {
    const { clearDigisign, digisignCallback, email, isDigisignActivated, isLoading, refetchUser } = props
    const { dark, dispatch, search } = useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })

    const image = <StyledImage src={imagePath.pump} />

    useEffect(() => {
        const callbackRespond = async (query) => {
            await digisignCallback({ options: query })
            await refetchUser({ email })
        }

        if (query.msg) {
            callbackRespond(query)
        }
        return () => {
            dispatch(clearDigisign())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearDigisign, dispatch, email, refetchUser, query.toString()])

    const renderDigisignActivationFailed = (
        <AntdSpace direction='vertical' size='large'>
            <StyledTitle $dark={dark} color={colors.graniteGray} level={5}>
                Pendaftaran tanda tangan digitalmu belum berhasil. Silahkan klik tombol di bawah ini untuk Aktivasi Akun
                Digisign kembali.
                <br />
                Terimakasih !
            </StyledTitle>
            <AntdRow gutter={[20, 20]}>
                <AntdCol md={12} sm={24} xs={24}>
                    {renderLinkButton('/profile', {
                        dark,
                        name: 'digisign-activation-btn',
                        value: 'Aktivasi Digisign',
                    })}
                </AntdCol>
                <AntdCol md={12} sm={24} xs={24}>
                    {renderLinkButton('/products', { dark, name: 'ask-moneypool-btn', value: 'Tanya Moneypool' })}
                </AntdCol>
            </AntdRow>
        </AntdSpace>
    )

    const renderDigisignActivationSuccess = (
        <AntdSpace direction='vertical' size='large'>
            <StyledTitle $dark={dark} color={colors.graniteGray} level={5}>
                Selamat moneypool-er!
                <br />
                Data e-KYC telah berhasil dan tanda tangan digitalmu sudah aktif.
            </StyledTitle>
            <StyledTitle $dark={dark} color={colors.graniteGray} level={5}>
                Sebelum melakukan transaksi selanjutnya, yuk input data akun bankmu yang akan menerima pembagian
                dividen.
                <br />
                Terimakasih!
            </StyledTitle>
            <AntdRow>
                <AntdCol md={12} sm={24} xs={24}>
                    {renderLinkButton('/profile/bank', {
                        dark,
                        name: 'bankinfo-registration-btn',
                        value: 'Registrasi Akun Bank',
                    })}
                </AntdCol>
            </AntdRow>
        </AntdSpace>
    )

    return (
        <StyledSection>
            <AntdRow align='middle'>
                <AntdCol md={12} sm={0} xs={0}>
                    <AntdRow justify='center'>{renderLazyLoadImage(image)}</AntdRow>
                </AntdCol>
                <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                    <AntdSpace direction='vertical'>
                        <StyledTitle $dark={dark} color={colors.graniteGray} level={2}>
                            Aktivasi Digisign {isDigisignActivated ? 'Berhasil' : 'Gagal'}
                        </StyledTitle>
                        {isLoading && <Loader />}
                        {!isLoading && (
                            <Fragment>
                                {!isDigisignActivated && renderDigisignActivationFailed}
                                {isDigisignActivated && renderDigisignActivationSuccess}
                            </Fragment>
                        )}
                        <BackButton route='/profile' />
                    </AntdSpace>
                </AntdCol>
            </AntdRow>
        </StyledSection>
    )
}

DigisignActivationStatus.propTypes = {
    clearDigisign: PropTypes.func,
    digisignCallback: PropTypes.func,
    email: PropTypes.string,
    isDigisignActivated: PropTypes.bool,
    isLoading: PropTypes.bool,
    refetchUser: PropTypes.func,
}

export default DigisignActivationStatus
