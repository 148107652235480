const getTemporaryData = (authentication) => {
    const email = authentication.email
    const mobileNumber = authentication.mobileNumber
    const temporaryEmail = authentication.temporaryEmail
    const temporaryMobileNumber = authentication.temporaryMobileNumber
    let currentEmail = email
    let currentMobileNumber = mobileNumber
    if (temporaryEmail) {
        currentEmail = temporaryEmail
    }
    if (temporaryMobileNumber) {
        currentMobileNumber = temporaryMobileNumber
    }
    return {
        email: currentEmail,
        mobileNumber: currentMobileNumber,
    }
}

export default getTemporaryData
