import * as antdIcon from '@ant-design/icons'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import StyledAntdButton from './StyledAntdButton'

const Button = (props) => {
    const {
        boldOnHover,
        className,
        color,
        dark,
        disabled,
        name,
        htmlType,
        icon,
        loading,
        noStyle,
        onClick,
        shape,
        size,
        type,
        value,
    } = props

    const iconAlign = get(props, 'iconAlign', 'left')
    const AntdIcon = antdIcon[icon]

    let renderValue = null
    if (icon) {
        if (iconAlign === 'left') {
            renderValue = <AntdIcon />
        }
        if (value) {
            renderValue = (
                <span>
                    {renderValue} {value}
                </span>
            )
        }
        if (iconAlign === 'right') {
            renderValue = (
                <span>
                    {renderValue} <AntdIcon />
                </span>
            )
        }
    } else renderValue = value

    return (
        <StyledAntdButton
            $boldOnHover={boldOnHover}
            $color={color}
            $dark={dark}
            $noStyle={noStyle}
            className={className}
            disabled={disabled}
            htmlType={htmlType}
            loading={loading}
            name={name}
            onClick={onClick}
            shape={shape}
            size={size}
            type={type}
        >
            {renderValue}
        </StyledAntdButton>
    )
}

Button.propTypes = {
    boldOnHover: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    htmlType: PropTypes.string,
    icon: PropTypes.node,
    loading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    noStyle: PropTypes.bool,
    onClick: PropTypes.func,
    shape: PropTypes.string,
    size: PropTypes.oneOf(['small', 'middle', 'large']),
    type: PropTypes.oneOf(['default', 'dashed', 'ghost', 'primary', 'link', 'text']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

Button.defaultProps = {
    shape: 'round',
    type: 'primary',
}

export default Button
