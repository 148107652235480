import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import isFunction from 'lodash/isFunction'
import map from 'lodash/map'
import size from 'lodash/size'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import colors from '../../basic/colors'
import { StyledAntdSpace } from '../../components/StyledComponents'
import StyledParagraph from '../../components/styled_paragraph/StyledParagraph'
import { StyledAntdCheckbox, StyledAntdCheckboxGroup } from './StyledAntdCheckbox'

const CheckboxGroup = (props) => {
    const {
        canCheckAll,
        checkboxPosition,
        className,
        containerClassName,
        dark,
        defaultValue,
        disabled,
        name,
        onBlur,
        onChange,
        options,
        optionsClassName,
        value,
    } = props
    const [checkAll, setCheckAll] = useState(size(options) === size(value))
    const allOptions = map(options, 'value')

    const handleChange = (checkedList) => {
        onChange(checkedList, name)
        setCheckAll(size(checkedList) === size(options))
    }

    const handleCheckAll = (e) => {
        const checked = e.target.checked
        setCheckAll(checked)
        onChange(checked ? allOptions : [], name)
    }

    const renderLabel = (option) =>
        isFunction(option.label) ? (
            option.label()
        ) : (
            <StyledParagraph $dark={dark} color={colors.graniteGray}>
                {option.label}
            </StyledParagraph>
        )

    const renderOptions = (options) =>
        map(options, (item, key) => {
            if (checkboxPosition === 'left') {
                return (
                    <StyledAntdCheckbox
                        className={`checkbox-group__option ${optionsClassName}`}
                        disabled={item.disabled}
                        key={item.value}
                        value={item.value}
                    >
                        {renderLabel(item)}
                    </StyledAntdCheckbox>
                )
            } else {
                return (
                    <AntdRow align='top' key={key}>
                        <AntdCol span={22}>
                            <StyledParagraph $dark={dark} color={colors.graniteGray}>
                                {renderLabel(item)}
                            </StyledParagraph>
                        </AntdCol>
                        <AntdCol span={2}>
                            <AntdRow>
                                <StyledAntdCheckbox
                                    className={`checkbox-group__option ${optionsClassName}`}
                                    disabled={item.disabled}
                                    key={item.value}
                                    value={item.value}
                                />
                            </AntdRow>
                        </AntdCol>
                    </AntdRow>
                )
            }
        })

    return (
        <div className={`checkbox-group ${className}`}>
            <StyledAntdSpace direction='vertical'>
                {canCheckAll && (
                    <AntdRow align='top'>
                        <AntdCol span={22}>
                            <StyledParagraph $dark={dark} color={colors.graniteGray}>
                                Semua
                            </StyledParagraph>
                        </AntdCol>
                        <AntdCol span={2}>
                            <AntdRow>
                                <StyledAntdCheckbox
                                    checked={checkAll}
                                    className={`checkbox-group__option ${optionsClassName}`}
                                    disabled={disabled}
                                    onBlur={onBlur}
                                    onChange={handleCheckAll}
                                />
                            </AntdRow>
                        </AntdCol>
                    </AntdRow>
                )}
                <StyledAntdCheckboxGroup
                    className={`checkbox-group__container ${containerClassName}`}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    onBlur={onBlur}
                    onChange={handleChange}
                    value={value}
                >
                    <StyledAntdSpace direction='vertical'>{renderOptions(options, optionsClassName)}</StyledAntdSpace>
                </StyledAntdCheckboxGroup>
            </StyledAntdSpace>
        </div>
    )
}

CheckboxGroup.propTypes = {
    canCheckAll: PropTypes.bool,
    checkboxPosition: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    dark: PropTypes.bool,
    defaultValue: PropTypes.arrayOf(PropTypes.any),
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            required: PropTypes.bool,
            value: PropTypes.string.isRequired,
        })
    ),
    optionsClassName: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.any),
}

CheckboxGroup.defaultProps = {
    checkboxPosition: 'left',
}

export default CheckboxGroup
