import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import includes from 'lodash/includes'
import PropTypes from 'prop-types'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { StyledImage, StyledSection } from '../../components/StyledComponents'
import StyledTitle from '../../components/styled_title/StyledTitle'
import HelmetMeta from '../../components/HelmetMeta'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { renderLinkButton } from '../../utility/renderLinkComp'

const Register = (props) => {
    const { currentRegisterStep, isValidUser, user } = props
    const { dark, isLoggedIn, navigate } = useOutletContext()

    const profitImage = <StyledImage src={imagePath.profit} />

    const handleClickUserType = (userType) => {
        if (includes(['pemodal', 'penerbit'], userType)) {
            return `/register/${userType}/step1`
        }
    }

    if (isLoggedIn) {
        if (isValidUser) {
            navigate('/profile')
        } else {
            navigate(`/register/${user}/step${currentRegisterStep}`)
        }
        return null
    }

    return (
        <StyledSection dark={dark}>
            <HelmetMeta mainTitle='Register' needIndex={false} />
            <AntdRow align='middle' gutter={[10, 20]} justify='center'>
                <AntdCol md={12} sm={24} xs={24}>
                    <AntdRow justify='center'>{renderLazyLoadImage(profitImage)}</AntdRow>
                </AntdCol>
                <AntdCol md={12} sm={24} xs={24}>
                    <StyledTitle $dark={dark} color={colors.graniteGray} level={2}>
                        Become A moneypool-er !
                    </StyledTitle>
                    <StyledTitle $dark={dark} color={colors.graniteGray} level={5}>
                        Daftarkan dirimu sekarang sebagai
                    </StyledTitle>
                    <br />
                    <AntdRow gutter={[20, 20]}>
                        <AntdCol span={12}>
                            {renderLinkButton(handleClickUserType('pemodal'), {
                                dark,
                                name: 'pemodal-btn',
                                value: 'Pemodal',
                            })}
                        </AntdCol>
                        <AntdCol span={12}>
                            {renderLinkButton(handleClickUserType('penerbit'), {
                                dark,
                                name: 'penerbit-btn',
                                value: 'Penerbit',
                            })}
                        </AntdCol>
                    </AntdRow>
                </AntdCol>
            </AntdRow>
        </StyledSection>
    )
}

Register.propTypes = {
    currentRegisterStep: PropTypes.number,
    isValidUser: PropTypes.bool,
    user: PropTypes.string,
}

export default Register
