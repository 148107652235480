import { default as AntdRow } from 'antd/lib/row'
import styled from 'styled-components'
import imagePath from '../basic/imagePath'
import modifyVars from '../basic/modifyVars'

export const StyledAntdRow = styled(AntdRow)`
    margin-bottom: 410px;

    @media screen and (min-width: 768px) {
        margin-bottom: 350px;
    }
`

export const StyledNoMatchSection = styled.div`
    background-image: url(${imagePath.noMatchBackground});
    background-position-y: 180px;
    background-repeat: no-repeat;
    background-size: 200%;

    @media screen and (min-width: 768px) {
        background-position-y: bottom;
        background-size: 100% 100%;
    }
`

export const StyledSpan = styled.span`
    color: white;
    font-style: ${modifyVars.fontStyle};
    font-weight: ${modifyVars.fontWeight};
    font-size: 100px;
    font-family: ${modifyVars.hammersmithOneFont};
    letter-spacing: 0.15em;
    line-height: normal;

    @media screen and (min-width: 768px) {
        font-size: 250px;
    }
`
