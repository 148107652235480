import { createAsyncThunk } from '@reduxjs/toolkit'
import { toApi } from '../../transformers/penerbitBankinfo'
import * as api from '../api'

export const createPenerbitBankinfo = createAsyncThunk(
    'CREATE_PENERBIT_BANKINFO',
    async (data) => await api.create('/penerbit-bankinfo/create', toApi(data))
)

export const getPenerbitBankinfo = createAsyncThunk(
    'GET_PENERBIT_BANKINFO',
    async () => await api.fetch('/penerbit-bankinfo')
)
