import React from 'react'
import PropTypes from 'prop-types'
import { StyledAvatarPicker } from './StyledComponents'
import { avatarSelectorInbox } from '../../utility/avatarSelector'
import { connect } from 'react-redux'

const AvatarPicker = (props) => {
    const { $size, adminUser, colorSelector, drawerInitial, inboxInital, profileInitial, totalInboxes } = props

    const getInitial = (name) => {
        const data = name.toUpperCase().charAt(0)
        return data
    }

    const getInitialSplit = (name) => {
        const data = name.toUpperCase().split(' ')[1].charAt(0)
        return data
    }

    return (
        <div>
            <StyledAvatarPicker
                color={inboxInital || adminUser ? avatarSelectorInbox(totalInboxes) : colorSelector}
                size={$size}
            >
                {drawerInitial && getInitialSplit(drawerInitial)}
                {profileInitial && getInitial(profileInitial)}
                {inboxInital && getInitialSplit(inboxInital)}
                {adminUser && adminUser}
            </StyledAvatarPicker>
        </div>
    )
}

AvatarPicker.propTypes = {
    $size: PropTypes.number,
    adminUser: PropTypes.bool,
    colorSelector: PropTypes.string,
    drawerInitial: PropTypes.string,
    inboxInital: PropTypes.string,
    profileInitial: PropTypes.string,
    totalInboxes: PropTypes.number,
    username: PropTypes.string,
}

const mapStateToProps = (state) => ({
    totalInboxes: state.inbox.totalInboxes,
})
export default connect(mapStateToProps)(AvatarPicker)
