import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactHelmet from 'react-helmet'
import isEmpty from 'lodash/isEmpty'
import { useLocation } from 'react-router-dom'

const HelmetMeta = (props) => {
    const { description = '', imageUrl = '', mainTitle = '', needIndex = true, productTitle = '' } = props
    const location = useLocation()
    const { pathname } = location

    const [metaTitle, setMetaTitle] = useState('Moneypool - Layanan Urun Dana')
    const [metaDesc, setMetaDesc] = useState(
        'Miliki Saham Bisnis UMKM. Alokasikan dana ' + 'pada bisnis pilihan Anda, Tuai keuntungan jangka panjang.'
    )
    const metaHref = 'https://www.moneypool.co.id' + pathname

    useEffect(
        () => {
            if (!isEmpty(description)) {
                setMetaDesc(description)
            }

            if (mainTitle === 'Detail Produk') {
                setMetaTitle(`Moneypool - ${productTitle}`)
            } else if (!isEmpty(mainTitle)) {
                setMetaTitle(`${mainTitle} - ` + 'Moneypool - Layanan Urun Dana')
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mainTitle, productTitle, description]
    )

    return (
        <ReactHelmet>
            {!needIndex && <meta content='noindex, nofollow' name='robots' />}
            {!isEmpty(imageUrl) && <meta content={imageUrl} property='og:image' />}
            <title>{metaTitle}</title>
            <meta content={metaTitle} name='title' />
            <meta content={metaDesc} name='description' />
            <meta content={metaTitle} property='og:title' />
            <meta content={metaDesc} property='og:description' />
            <meta content={metaHref} property='og:url' />
            <link href={metaHref} rel='canonical' />
        </ReactHelmet>
    )
}

HelmetMeta.propTypes = {
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    mainTitle: PropTypes.string,
    needIndex: PropTypes.bool,
    product: PropTypes.object,
    productTitle: PropTypes.string,
}

export default HelmetMeta
