import { default as AntdList } from 'antd/lib/list'
import styled from 'styled-components'
import { Carousel } from '../antdcomponents'
import colors from '../basic/colors'
import imagePath from '../basic/imagePath'
import { StyledSection } from '../components/StyledComponents'

export const StyledAntdList = styled(AntdList)`
    &&& {
        div.ant-row {
            justify-content: center;
        }
    }
`

export const StyledBuildingImage = styled.div`
    background-image: url(${imagePath.building});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 230%;
    min-height: 590px;
    min-width: 375px;
`

export const StyledCarousel = styled(Carousel)`
    &&& {
        top: -100px;
        margin-bottom: -185px;

        & > div:nth-child(1) {
            top: 30%;
        }

        & > div:nth-child(3) {
            top: 30%;
            right: -10px;
        }

        & > div > span {
            color: ${colors.persianIndigo};
        }

        @media screen and (min-width: 768px) {
            top: -160px;
            margin-bottom: -135px;
        }
    }
`

export const StyledImage = styled.img`
    height: 33px;
    width: 115px;
    @media screen and (min-width: 768px) {
        height: auto;
        width: auto;
    }
`

export const StyledLocationInfoContainer = styled.div`
    background-color: ${colors.black}0a;
`

export const StyledLocationInfoDetail = styled.div`
    padding: 40px;

    @media screen and (min-width: 768px) {
        padding: 170px 50px 85px 200px;
    }
`

export const StyledWhyInvestContainer = styled.div`
    border: 2px solid ${colors.aquamarine};
    border-radius: 40px;
    padding: 25px;
`

export const StyledWhyInvestSection = styled(StyledSection)`
    &&& {
        background-color: ${colors.persianIndigo};
        padding-bottom: 110px;

        @media screen and (min-width: 768px) {
            padding-bottom: 155px;
        }
    }
`
