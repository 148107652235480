import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { persistCombineReducers, persistStore } from 'redux-persist'
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'

import { api } from './services/api'
import asyncAction from './middleware/asyncAction'

import rootReducer from './reducers'
import storage from './storage'

// TO::DO https://github.com/rt2zz/redux-persist
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel1,
}

const persistedReducer = persistCombineReducers(persistConfig, rootReducer)

export default () => {
    let store = configureStore({
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: { ignoredPaths: ['form'] },
                serializableCheck: false,
            }).concat([asyncAction, api.middleware]),
        reducer: persistedReducer,
    })
    // The store now has redux-thunk added and the Redux DevTools Extension is turned on

    // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
    // see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
    setupListeners(store.dispatch)

    let persistor = persistStore(store)
    return { store, persistor }
}
