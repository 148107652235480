import includes from 'lodash/includes'

export const isAuthorizedUser = (isPemodal, isPenerbit, pathname) => {
    const pemodalFeature = ['/favorite', '/order', '/portfolio', '/transaction-histories']
    const penerbitFeature = ['/profile/document', '/profile/screening-detail', '/funds']
    let isAuthorized = true
    if (!isPemodal && includes(pemodalFeature, pathname)) {
        isAuthorized = false
    }
    if (!isPenerbit && includes(penerbitFeature, pathname)) {
        isAuthorized = false
    }
    return isAuthorized
}
