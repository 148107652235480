import { default as AntdSpace } from 'antd/lib/space'
import clone from 'lodash/clone'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import React from 'react'
import formatPrice from '../../utility/formatPrice'

const rowActions = (index, handleActions) => [
    {
        label: 'Lihat Produk',
        onClick: () => handleActions(index, 'view'),
        type: 'custom',
    },
]

const columns = (tablesTooltip, renderActions) => [
    {
        dataIndex: 'code',
        title: 'Kode',
        width: 100,
    },
    {
        dataIndex: 'soldShare',
        title: 'Lembar Terjual',
        width: 150,
    },
    {
        dataIndex: 'numberShare',
        title: 'Lembar Total',
        width: 150,
    },
    {
        dataIndex: 'target',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Target',
        width: 150,
    },
    {
        dataIndex: 'collected',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Dana Terkumpul',
        width: 150,
    },
    {
        dataIndex: 'pricePerShare',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Harga Per Lembar',
        width: 200,
    },
    {
        dataIndex: 'startDate',
        title: 'Tanggal Mulai',
        width: 150,
    },
    {
        dataIndex: 'endDate',
        title: 'Tanggal Berakhir',
        width: 150,
    },
    {
        dataIndex: 'status',
        render: (text) => (text !== 'active' && text !== 'inactive' ? `${startCase(text)} Offer` : text),
        title: 'Status',
        width: 100,
    },
    {
        dataIndex: 'settlementDate',
        title: (
            <AntdSpace>
                <>Tanggal Settlement</>
                <>{tablesTooltip}</>
            </AntdSpace>
        ),
        width: 200,
    },
    {
        dataIndex: 'actions',
        render: renderActions,
        title: 'Aksi',
        width: 100,
    },
]

const dataSource = (data) =>
    map(data, (item, key) =>
        Object.assign(clone(item), {
            key: String(key),
        })
    )

export { columns, dataSource, rowActions }
