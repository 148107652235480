import { Select as AntdSelect } from 'antd'
import styled from 'styled-components'
import colors from '../../basic/colors'

export const StyledAntdSelect = styled(AntdSelect)`
    &&& {
        color: ${(props) => (props.$dark ? 'white' : 'black')};
        font-size: 14px;
        height: 30px;
        padding: 0px;
        &,
        &::selection,
        div.ant-select-selector {
            height: 30px;
            ${(props) =>
                !props.disabled &&
                `
                background-color: ${colors.black}00 !important;
            `}
            border-radius: 20px;
        }
        div.ant-select-selector,
        div.ant-select-focused {
            border: 2px;
            border-color: ${(props) => (props.$dark ? colors.robbinEggBlue : 'silver')} !important;
            border-style: solid;
            box-shadow: none !important;
            padding-top: 0px !important;
        }
        .ant-select-selection-item {
            ${(props) =>
                !props.disabled &&
                `
                color: ${props.$dark ? 'white' : 'black'};
            `}
            font-size: 14px;
        }
        input.ant-select-selection-search-input {
            font-size: 14px;
        }
        span.ant-select-clear {
            background: transparent;
        }

        span.ant-select-selection-placeholder {
            padding-top: 1px;
        }

        @media screen and (min-width: 320px) {
            div.ant-select-selector {
                height: 30px;
            }
        }

        @media screen and (min-width: 768px) {
            font-size: 20px;
            height: 45px;
            div.ant-select-selector {
                height: 45px;
                padding-top: 4px;
            }
            .ant-select-selection-item,
            input.ant-select-selection-search-input {
                font-size: 20px;
            }
            span.ant-select-arrow,
            span.ant-select-clear {
                top: 50%;
            }
        }
    }
`
