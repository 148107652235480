import { default as AntdAvatar } from 'antd/lib/avatar'
import styled from 'styled-components'
import colors from '../../basic/colors'

export const StyledAvatarPicker = styled(AntdAvatar)`
    &&& {
        background-color: ${(props) => props.color};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: ${colors.antiFlashWhite};
        text-align: center;
        width: ${(props) => props.size};
        height: ${(props) => props.size};
    }
`
