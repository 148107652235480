import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from '../../antdcomponents'
import colors from '../../basic/colors'
import { renderLinkButton } from '../../utility/renderLinkComp'
import StyledTitle from '../styled_title/StyledTitle'

const BankInfoModal = (props) => {
    const { isModalOpen, onCancel } = props

    const footer = (
        <AntdRow>
            <AntdCol md={14} sm={24} xs={24}>
                {renderLinkButton('/profile/bank', {
                    name: 'register-btn',
                    onClick: onCancel,
                    value: 'Registrasi Akun Bank',
                })}
            </AntdCol>
        </AntdRow>
    )

    return (
        <Modal footer={footer} onCancel={onCancel} visible={isModalOpen}>
            <AntdSpace direction='vertical'>
                <StyledTitle color={colors.graniteGray} level={2}>
                    Rekening Bank
                </StyledTitle>
                <StyledTitle color={colors.graniteGray} level={3}>
                    Sebelum melakukan transaksi selanjutnya, yuk input data akun bankmu yang akan menerima pembagian
                    dividen
                </StyledTitle>
            </AntdSpace>
        </Modal>
    )
}

BankInfoModal.propTypes = {
    isModalOpen: PropTypes.bool,
    onCancel: PropTypes.func,
}

export default BankInfoModal
