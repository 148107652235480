import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import imagePath from '../../basic/imagePath'
import { getRegisterData } from '../../common/helper/authentication'
import { successOptions } from '../../common/helper/profile'
import HelmetMeta from '../../components/HelmetMeta'
import { StyledAntdSpace, StyledImage, StyledSection } from '../../components/StyledComponents'
import VerifyOtpFormContainer from '../../components/verify_otp_form/VerifyOtpFormContainer'
import * as modal from '../../utility/modal'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'

const VerifySmsOtp = (props) => {
    const {
        clearPemodal,
        clearTemporaryData,
        clearVerification,
        currentRegisterStep,
        email,
        id,
        isActionLoadingPemodal,
        isActionLoadingSendVerification,
        isActionLoadingVerify,
        isActionSuccessPemodal,
        isActionSuccessSendVerification,
        isActionSuccessVerify,
        isLoading,
        isValidUser,
        mobileNumber,
        refetchUser,
        updatePemodal,
        user,
        verifyMobileNumber,
    } = props
    const { dark, dispatch, isLoggedIn, isPemodal, navigate, pathname } = useOutletContext()
    const isReverificationPage = pathname === '/profile/phone'
    const { currentPage, page, userType } = getRegisterData(dark, pathname, isPemodal)
    const [isUpdatingMobileNumber, setIsUpdatingMobileNumber] = useState(false)
    const [isAllowedRedirection, setIsAllowedRedirection] = useState(false)

    const metaTitle = 'Verifikasi Nomor Telepon Seluler'
    const profitImage = <StyledImage src={imagePath.profit} />

    useEffect(() => {
        if (
            (!isActionLoadingPemodal && isActionSuccessPemodal) ||
            (!isActionLoadingSendVerification && isActionSuccessSendVerification) ||
            (!isActionLoadingVerify && isActionSuccessVerify)
        ) {
            refetchUser({ additionalAttr: ['mobileNumber'], email })
        }
    }, [
        email,
        isActionLoadingPemodal,
        isActionLoadingSendVerification,
        isActionLoadingVerify,
        isActionSuccessPemodal,
        isActionSuccessSendVerification,
        isActionSuccessVerify,
        refetchUser,
    ])

    useEffect(() => {
        if (
            isValidUser &&
            ((!isActionLoadingPemodal && isActionSuccessPemodal) ||
                (!isActionLoadingVerify && isActionSuccessVerify && !isUpdatingMobileNumber))
        ) {
            setIsUpdatingMobileNumber(true)
            const data = {
                mobileNumber,
                id,
            }
            updatePemodal(data)
        }
        return () => {
            dispatch(clearPemodal())
            dispatch(clearVerification())
        }
    }, [
        clearPemodal,
        clearVerification,
        dispatch,
        id,
        isActionLoadingPemodal,
        isActionLoadingVerify,
        isActionSuccessPemodal,
        isActionSuccessVerify,
        isUpdatingMobileNumber,
        isValidUser,
        mobileNumber,
        updatePemodal,
    ])

    useEffect(() => {
        if (!isActionLoadingPemodal && isActionSuccessPemodal && isUpdatingMobileNumber) {
            setIsAllowedRedirection(true)
            refetchUser({ email })
        }
    }, [email, isActionLoadingPemodal, isActionSuccessPemodal, isUpdatingMobileNumber, refetchUser])

    useEffect(() => {
        if (isAllowedRedirection && !isLoading) {
            dispatch(clearTemporaryData())
            modal.success(successOptions)
            navigate('/profile')
        }
    }, [clearTemporaryData, dispatch, isAllowedRedirection, isLoading, navigate])

    const handleClick = () => navigate(`/register/${userType}/step1`)

    const handleFinish = (values) => {
        if (process.env.REACT_APP_IS_DEV === 'true') {
            const data = {
                id,
                isMobileNumberVerified: 1,
            }
            updatePemodal(data)
        } else {
            const { otp } = values
            const data = {
                code: otp,
                to: '+62' + mobileNumber,
            }
            verifyMobileNumber(data)
        }
    }

    if (isLoggedIn) {
        if (!isReverificationPage) {
            if (isValidUser) {
                navigate('/profile')
                return null
            }
            if (user !== userType || currentRegisterStep !== 3) {
                navigate(`/register/${user}/step${currentRegisterStep}`)
                return null
            }
        }
    } else {
        navigate('/login')
        return null
    }

    return (
        <StyledSection dark={dark}>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <StyledAntdSpace direction='vertical' size={20}>
                {!isReverificationPage && currentPage}
                <AntdRow align='middle'>
                    <AntdCol md={12} sm={0} xs={0}>
                        <AntdRow justify='center'>{renderLazyLoadImage(profitImage)}</AntdRow>
                    </AntdCol>
                    <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                        <VerifyOtpFormContainer
                            {...props}
                            onClick={handleClick}
                            onFinish={handleFinish}
                            page={page}
                            pathname={pathname}
                            title={metaTitle}
                            user={userType}
                        />
                    </AntdCol>
                </AntdRow>
            </StyledAntdSpace>
        </StyledSection>
    )
}

VerifySmsOtp.propTypes = {
    clearPemodal: PropTypes.func,
    clearTemporaryData: PropTypes.func,
    clearVerification: PropTypes.func,
    currentRegisterStep: PropTypes.number,
    email: PropTypes.string,
    id: PropTypes.number,
    isActionLoadingPemodal: PropTypes.bool,
    isActionLoadingSendVerification: PropTypes.bool,
    isActionLoadingVerify: PropTypes.bool,
    isActionSuccessPemodal: PropTypes.bool,
    isActionSuccessSendVerification: PropTypes.bool,
    isActionSuccessVerify: PropTypes.bool,
    isLoading: PropTypes.bool,
    isValidUser: PropTypes.bool,
    mobileNumber: PropTypes.string,
    refetchUser: PropTypes.func,
    updatePemodal: PropTypes.func,
    user: PropTypes.string,
    verifyMobileNumber: PropTypes.func,
}

export default VerifySmsOtp
