import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import { Button, Form, ReduxFormInput, ReduxFormInputPassword, ReduxFormSelect } from '../../antdcomponents'
import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import { typeOptions as userTypeOptions } from '../../common/options/user'
import HelmetMeta from '../../components/HelmetMeta'
import { StyledAntdSpace, StyledImage, StyledSection, StyledUnderlinedText } from '../../components/StyledComponents'
import StyledSpan from '../../components/styled_span/StyledSpan'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { required } from '../../utility/formValidation'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { renderLink } from '../../utility/renderLinkComp'

const Login = (props) => {
    const {
        authToken,
        authenticate,
        clearCustomErrorMessage,
        currentRegisterStep,
        customErrorMessage,
        digisignCallback,
        failedLoginAttempts,
        handleSubmit,
        isLoading,
        isValidUser,
        user,
    } = props
    const { dark, dispatch, navigate, search } = useOutletContext()
    const { executeRecaptcha } = useGoogleReCaptcha()

    const [isDisabled, setIsDisabled] = useState(false)

    const profitImage = <StyledImage src={imagePath.profit} />
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const isLoginAllowed = failedLoginAttempts === 0 || (failedLoginAttempts > 0 && failedLoginAttempts % 3 !== 0)
    const isHideForgotPasswordLink = failedLoginAttempts >= 6

    useEffect(() => {
        if (query.msg) {
            digisignCallback({ options: query })
        }
        const clearDatas = () => {
            dispatch(clearCustomErrorMessage())
        }
        window.addEventListener('beforeunload', clearDatas)
        return () => {
            clearDatas()
            window.removeEventListener('beforeunload', clearDatas)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearCustomErrorMessage, dispatch, query.toString()])

    useEffect(() => {
        if (!isLoading && !isLoginAllowed) {
            setIsDisabled(true)
        }
    }, [isLoading, isLoginAllowed])

    const handleChange = () => {
        if (isDisabled) {
            setIsDisabled(false)
        }
    }

    const handleFinish = async (values) => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha('login')
            const data = Object.assign(values, { token })
            authenticate(data)
        }
    }

    if (isValidUser) {
        navigate('profile')
        return null
    }

    if (authToken) {
        navigate(`/register/${user}/step${currentRegisterStep}`)
        return null
    }

    return (
        <StyledSection>
            <HelmetMeta mainTitle='Login' needIndex={false} />
            <AntdRow align='middle'>
                <AntdCol md={12} sm={0} xs={0}>
                    <AntdRow justify='center'>{renderLazyLoadImage(profitImage)}</AntdRow>
                </AntdCol>
                <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                    <StyledAntdSpace direction='vertical'>
                        <div>
                            <StyledTitle $dark={dark} level={2}>
                                Yuk isi kolam uangmu !
                            </StyledTitle>
                            <StyledTitle $dark={dark} level={5}>
                                Belum punya akun?&nbsp;
                                <StyledSpan color={colors.aquamarine}>
                                    {renderLink({ to: '/register' }, 'Daftar')}
                                </StyledSpan>
                            </StyledTitle>
                        </div>
                        <StyledTitle $dark={dark} level={5}>
                            {customErrorMessage}
                            {!isLoginAllowed &&
                                renderLink(
                                    { to: '/contact-us?subject=akun moneypool.co.id' },
                                    <StyledUnderlinedText>silahkan hubungi tim admin moneypool</StyledUnderlinedText>
                                )}
                        </StyledTitle>
                        <Form onFinish={handleSubmit(handleFinish)}>
                            <Field
                                component={ReduxFormSelect}
                                componentProps={{
                                    options: userTypeOptions,
                                }}
                                dark={dark}
                                label='Masuk sebagai'
                                name='user'
                                onChange={handleChange}
                                placeholder='Pilih masuk sebagai pemodal/penerbit'
                                validate={[required]}
                            />
                            <Field
                                component={ReduxFormInput}
                                dark={dark}
                                label='Email/Username'
                                name='identifier'
                                onChange={handleChange}
                                placeholder='Contoh: example@moneypool.com'
                                validate={[required]}
                            />
                            <Field
                                component={ReduxFormInputPassword}
                                dark={dark}
                                label='Kata Sandi'
                                name='password'
                                onChange={handleChange}
                                placeholder='Masukkan kata sandi anda'
                                validate={[required]}
                            />
                            {!isHideForgotPasswordLink && (
                                <AntdRow justify='end'>
                                    <StyledTitle $dark={dark} level={5}>
                                        {renderLink({ to: '/forgot-password' }, 'Lupa Kata Sandi')}
                                    </StyledTitle>
                                </AntdRow>
                            )}
                            <br />
                            <center>
                                <AntdRow>
                                    <AntdCol offset={6} span={12}>
                                        <Button
                                            dark={dark}
                                            disabled={isDisabled}
                                            htmlType='submit'
                                            loading={isLoading}
                                            name='login-btn'
                                            value='Masuk'
                                        />
                                    </AntdCol>
                                </AntdRow>
                            </center>
                        </Form>
                    </StyledAntdSpace>
                </AntdCol>
            </AntdRow>
        </StyledSection>
    )
}

Login.propTypes = {
    authToken: PropTypes.string,
    authenticate: PropTypes.func,
    clearCustomErrorMessage: PropTypes.func,
    currentRegisterStep: PropTypes.number,
    customErrorMessage: PropTypes.string,
    digisignCallback: PropTypes.func,
    failedLoginAttempts: PropTypes.number,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    isValidUser: PropTypes.bool,
    user: PropTypes.string,
}

export default Login
