import { Form as AntdForm } from 'antd'
import styled from 'styled-components'
import colors from '../../basic/colors'

const AntdFormItem = AntdForm.Item
const StyledAntdFormItem = styled(AntdFormItem)`
    &&& {
        .ant-form-item-label {
            display: ${(props) => (props.$isHideLabel || props.label === '' ? 'none' : 'inline-block')};
            padding: 0px;
        }
        .ant-form-item-label > label {
            height: ${(props) => (props.$isEmptyLabel ? '16px' : '24px')};
            @media screen and (min-width: 768px) {
                height: 40px;
            }
        }
        label.ant-form-item-required::before {
            content: none;
        }
        ${(props) =>
            props.$dark &&
            `
                div.ant-form-item-explain-error {
                    color: ${colors.carnationPink};
                }
            `}
        ${(props) =>
            props.$noMargin &&
            `
                margin: 0px;
        `}
        ${(props) =>
            props.$required &&
            `
            .ant-form-item-label::after {
                color: #ff4d4f;
                content: '*';
            }
        `}
    }
`

export default StyledAntdFormItem
