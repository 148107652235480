import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import * as verificationAPI from './verificationAPI'

const initialState = {
    isActionLoadingSendVerification: false,
    isActionLoadingVerify: false,
    isActionSuccessSendVerification: false,
    isActionSuccessVerify: false,
    isError: false,
}

export const { sendVerificationEmail, sendVerificationMobileNumber, verifyEmail, verifyMobileNumber } = verificationAPI

const verificationSlice = createSlice({
    name: 'VERIFICATION',
    initialState,
    reducers: {
        clearVerification: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(sendVerificationEmail.pending, sendVerificationMobileNumber.pending), (state) =>
            Object.assign(state, {
                isActionLoadingSendVerification: true,
                isActionSuccessSendVerification: false,
            })
        )
        builder.addMatcher(isAnyOf(sendVerificationEmail.fulfilled, sendVerificationMobileNumber.fulfilled), (state) =>
            Object.assign(state, {
                isActionLoadingSendVerification: false,
                isActionSuccessSendVerification: true,
            })
        )
        builder.addMatcher(isAnyOf(sendVerificationEmail.rejected, sendVerificationMobileNumber.rejected), (state) =>
            Object.assign(state, {
                isActionLoadingSendVerification: false,
                isError: true,
            })
        )
        builder.addMatcher(isAnyOf(verifyEmail.pending, verifyMobileNumber.pending), (state) =>
            Object.assign(state, {
                isActionLoadingVerify: true,
                isActionSuccessVerify: false,
            })
        )
        builder.addMatcher(isAnyOf(verifyEmail.fulfilled, verifyMobileNumber.fulfilled), (state) =>
            Object.assign(state, {
                isActionLoadingVerify: false,
                isActionSuccessVerify: true,
            })
        )
        builder.addMatcher(isAnyOf(verifyEmail.rejected, verifyMobileNumber.rejected), (state) =>
            Object.assign(state, {
                isActionLoadingVerify: false,
                isError: true,
            })
        )
    },
})

export const { clearVerification } = verificationSlice.actions

const { reducer } = verificationSlice

export default reducer
