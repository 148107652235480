import clone from 'lodash/clone'
import get from 'lodash/get'
import replace from 'lodash/replace'
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as authenticationSlice from '../features/authentication/authenticationSlice'
import * as pemodalSlice from '../features/pemodal/pemodalSlice'
import * as penerbitSlice from '../features/penerbit/penerbitSlice'
import * as verificationSlice from '../features/verification/verificationSlice'
import scrollToViewFormError from '../utility/scrollToViewFormError'
import DetailForm from './DetailForm'

let DetailFormContainer = (props) => <DetailForm {...props} />

const mapStateToProps = (state) => {
    const user = state.authentication.user
    let isUpdateAllowed
    let initialValues
    if (user === 'pemodal') {
        const pemodal = clone(state.pemodal.pemodal)
        const { email, name, mobileNumber, profilePicture, username } = pemodal
        isUpdateAllowed = get(pemodal, 'isUpdateAllowed', null)
        initialValues = {
            email,
            mobileNumber,
            name,
            password: 'Password321',
            profilePicture,
            username,
        }
    } else if (user === 'penerbit') {
        const penerbit = clone(state.penerbit.penerbit)
        isUpdateAllowed = get(penerbit, 'isUpdateAllowed', null)
        const provinceName = get(penerbit, 'city.province', '')
        const districtName = get(penerbit, 'district.name', '')
        const subdistrictName = get(penerbit, 'district.subdistrict', '')
        let websiteUrl = get(penerbit, 'websiteUrl', '')
        websiteUrl = websiteUrl ? replace(websiteUrl, 'https://', '') : ''
        initialValues = Object.assign(penerbit, {
            districtName,
            password: 'Password321',
            provinceName,
            subdistrictName,
            websiteUrl,
        })
    }
    return {
        districts: state.district.districts,
        email: state.authentication.email,
        id: state.authentication.id,
        initialValues,
        isActionLoadingSendVerification: state.verification.isActionLoadingSendVerification,
        isActionLoadingUser: state.pemodal.isActionLoading || state.penerbit.isActionLoading,
        isActionSuccessSendVerification: state.verification.isActionSuccessSendVerification,
        isActionSuccessUser: state.pemodal.isActionSuccess || state.penerbit.isActionSuccess,
        isDuplicate: state.pemodal.isDuplicate,
        isEmailVerified: state.authentication.isEmailVerified,
        isLoadingPemodal: state.pemodal.isLoading,
        isLoadingPenerbit: state.penerbit.isLoading,
        isMobileNumberVerified: state.authentication.isMobileNumberVerified,
        isUpdateAllowed,
        isValidUser: state.authentication.isValidUser,
        mobileNumber: state.authentication.mobileNumber,
        user: state.authentication.user,
        username: state.authentication.username,
    }
}

const mapDispatchToProps = {
    clearVerification: verificationSlice.clearVerification,
    getPemodalByEmail: pemodalSlice.getPemodalByEmail,
    getPemodalByMobileNumber: pemodalSlice.getPemodalByMobileNumber,
    refetchUser: authenticationSlice.refetchUser,
    sendVerificationEmail: verificationSlice.sendVerificationEmail,
    sendVerificationMobileNumber: verificationSlice.sendVerificationMobileNumber,
    setTemporaryEmail: authenticationSlice.setTemporaryEmail,
    setTemporaryMobileNumber: authenticationSlice.setTemporaryMobileNumber,
    updatePemodal: pemodalSlice.updatePemodal,
    updatePenerbit: penerbitSlice.updatePenerbit,
}

DetailFormContainer = reduxForm({
    enableReinitialize: true,
    form: 'detail',
    onSubmitFail: (errors) => scrollToViewFormError(errors),
})(DetailFormContainer)

export default connect(mapStateToProps, mapDispatchToProps)(DetailFormContainer)
