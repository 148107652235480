import React from 'react'
import { connect } from 'react-redux'
import * as purchaseOrderSlice from '../features/purchase_order/purchaseOrderSlice'
import * as inboxSlice from '../features/inbox/inboxSlice'
import TransactionHistory from './TransactionHistory'
const TransactionHistoryContainer = (props) => <TransactionHistory {...props} />

const mapStateToProps = (state) => ({
    id: state.inbox.id,
    isActionLoading: state.purchaseOrder.isActionLoading,
    isActionSuccess: state.purchaseOrder.isActionSuccess,
    isLoading: state.purchaseOrder.isLoading,
    productTypes: state.productType.productTypes,
    purchaseOrders: state.purchaseOrder.purchaseOrders,
    totalPurchaseOrders: state.purchaseOrder.totalPurchaseOrders,
})

const mapDispatchToProps = {
    cancelPurchaseOrder: purchaseOrderSlice.cancelPurchaseOrder,
    clearInbox: inboxSlice.clearInbox,
    clearPurchaseOrder: purchaseOrderSlice.clearPurchaseOrder,
    getPurchaseOrders: purchaseOrderSlice.getPurchaseOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistoryContainer)
