import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as bankListSlice from '../features/bank_list/bankListSlice'
import * as pemodalBankinfoSlice from '../features/pemodal_bankinfo/pemodalBankinfoSlice'
import * as penerbitBankinfoSlice from '../features/penerbit_bankinfo/penerbitBankinfoSlice'
import scrollToViewFormError from '../utility/scrollToViewFormError'
import BankForm from './BankForm'

let BankFormContainer = (props) => <BankForm {...props} />

const mapStateToProps = (state) => {
    const user = state.authentication.user
    let initialValues
    if (user === 'pemodal') {
        initialValues = state.pemodalBankinfo.pemodalBankinfo
    }
    if (user === 'penerbit') {
        initialValues = state.penerbitBankinfo.penerbitBankinfo
    }
    return {
        bankLists: state.bankList.bankLists,
        initialValues,
        isActionLoadingPemodalBankinfo: state.pemodalBankinfo.isActionLoading,
        isActionLoadingPenerbitBankinfo: state.penerbitBankinfo.isActionLoading,
        isActionSuccessPemodalBankinfo: state.pemodalBankinfo.isActionSuccess,
        isActionSuccessPenerbitBankinfo: state.penerbitBankinfo.isActionSuccess,
        isBankinfoExist: state.authentication.isBankinfoExist,
        isKycStatusSuccessVerified: state.authentication.isKycStatusSuccessVerified,
        isLoadingPemodalBankinfo: state.pemodalBankinfo.isLoading,
        isLoadingPenerbitBankinfo: state.penerbitBankinfo.isLoading,
        username: state.authentication.username,
    }
}

const mapDispatchToProps = {
    clearBankList: bankListSlice.clearBankList,
    clearPemodalBankinfo: pemodalBankinfoSlice.clearPemodalBankinfo,
    clearPenerbitBankinfo: penerbitBankinfoSlice.clearPenerbitBankinfo,
    createPemodalBankinfo: pemodalBankinfoSlice.createPemodalBankinfo,
    createPenerbitBankinfo: penerbitBankinfoSlice.createPenerbitBankinfo,
    getBankLists: bankListSlice.getBankLists,
}

BankFormContainer = reduxForm({
    enableReinitialize: true,
    form: 'bankinfo',
    onSubmitFail: (errors) => scrollToViewFormError(errors),
})(BankFormContainer)

export default connect(mapStateToProps, mapDispatchToProps)(BankFormContainer)
