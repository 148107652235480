import { default as AntdUpload } from 'antd/lib/upload'
import styled, { css } from 'styled-components'
import colors from '../../basic/colors'

const defaultUploadCss = css`
    div.ant-upload-select-picture-card {
        background-color: white !important;
        border-style: solid;
        height: 110px;
        margin: 0px;
    }
    div.ant-upload-list-picture-card-container,
    div.ant-upload-select-picture-card {
        width: 100%;
    }
    span.ant-upload-span {
        padding: 5px;
    }
`

const roundUploadCss = css`
    div.ant-upload-list-item,
    div.ant-upload-list-item-info,
    div.ant-upload {
        border-radius: 50%;
    }
    div.ant-upload-list-item {
        padding: 0px;
    }
    div.ant-upload {
        background-color: silver;
    }
    img.ant-upload-list-item-image {
        object-fit: unset;
    }
`

const getCss = (listType, shape) => {
    if (listType === 'picture-card') {
        if (shape === 'round') {
            return roundUploadCss
        } else {
            return defaultUploadCss
        }
    } else {
        return null
    }
}

export const StyledAntdUpload = styled(AntdUpload)`
    &&& {
        ${(props) => getCss(props.listType, props.$shape)}

        .ant-btn-round, .ant-upload-select-picture-card {
            border: ${(props) => (props.$isUploadRequired ? `solid 2px ${colors.rose}` : null)};
        }
    }
`
