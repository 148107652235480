import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import parse from 'html-react-parser'
import isMobile from 'is-mobile'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Fragment, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { BackButton, Button, Tooltip } from '../antdcomponents'
import colors from '../basic/colors'
import imagePath from '../basic/imagePath'
import HelmetMeta from '../components/HelmetMeta'
import Result from '../components/result/Result'
import { StyledAntdSpace, StyledImage, StyledInfoCircleOutlined, StyledSection } from '../components/StyledComponents'
import StyledSpan from '../components/styled_span/StyledSpan'
import renderLazyLoadImage from '../utility/renderLazyLoadImage'
import BankFormContainer from './BankFormContainer'
import DetailFormContainer from './DetailFormContainer'
import { formInfo } from './helper'
import ScreeningFormContainer from './ScreeningFormContainer'
import { StyledFormInfoCard, StyledTabs } from './StyledComponents'
import { renderLinkButton } from '../utility/renderLinkComp'

const EditProfile = (props) => {
    const {
        clearDigisign,
        clearPemodal,
        clearPemodalBankinfo,
        clearPenerbit,
        clearPenerbitBankinfo,
        clearPenerbitDetail,
        clearVerification,
        currentRegisterStep,
        digisignActivationUrl,
        email,
        generateLinkActivation,
        getPemodalBankinfo,
        getPemodalProfile,
        getPenerbit,
        getPenerbitBankinfo,
        getPenerbitDetail,
        isActionLoadingDigisign,
        isActionLoadingPemodal,
        isActionLoadingPemodalBankinfo,
        isActionLoadingPenerbitBankinfo,
        isActionSuccessDigisign,
        isActionSuccessPemodal,
        isActionSuccessPemodalBankinfo,
        isActionSuccessPenerbitBankinfo,
        isBankinfoExist,
        isDetailExist,
        isDigisignActivated,
        isEmailVerified,
        isKycStatusSuccessVerified,
        isNeedActivation,
        message,
        refetchUser,
        user,
    } = props
    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, params, search } = useOutletContext()
    const query = qs.parse(search, { ignoreQueryPrefix: true })
    const name = startCase(get(query, 'name', ''))
    const type = get(params, 'type', '')
    const mode = get(params, 'mode', '')

    const penerbitRouteType = ['detail', 'bank']
    const pemodalRouteType = ['detail', 'screening', 'bank']
    const activeKey = (findIndex(pemodalRouteType, (item) => item === type) + 1).toString()
    const penerbitScreeningPathname = ['add', 'edit', 'view']
    const isPenerbitScreeningPage = includes(penerbitScreeningPathname, mode)

    const isBankPage = type === 'bank'
    const isDetailPage = type === 'detail'
    const isMessageBerhasil = includes(toLower(message), 'berhasil')
    const isSceeningPage = type === 'screening' || (isPenerbit && isPenerbitScreeningPage)

    const [pageNumber, setPageNumber] = useState(0)
    const [isSavingScreeningData, setIsSavingScreeningData] = useState(false)

    useEffect(() => {
        return () => {
            setPageNumber(0)
            dispatch(clearDigisign())
            dispatch(clearPemodal())
            dispatch(clearPemodalBankinfo())
            dispatch(clearPenerbit())
            dispatch(clearPenerbitBankinfo())
            dispatch(clearPenerbitDetail())
            dispatch(clearVerification())
            if (!isDetailPage) {
                refetchUser({ email })
            }
        }
    }, [
        clearDigisign,
        clearPemodal,
        clearPemodalBankinfo,
        clearPenerbit,
        clearPenerbitBankinfo,
        clearPenerbitDetail,
        clearVerification,
        dispatch,
        email,
        isDetailPage,
        refetchUser,
    ])

    const renderSideContent = () => {
        if ((isSceeningPage && !isDetailExist) || (isBankPage && !isBankinfoExist)) {
            const pumpImage = <StyledImage src={imagePath.pump} />
            return <center>{renderLazyLoadImage(pumpImage)}</center>
        } else {
            return (
                <StyledFormInfoCard>
                    <StyledSpan color={colors.graniteGray}>{formInfo(type)}</StyledSpan>
                </StyledFormInfoCard>
            )
        }
    }

    const detailFormContainer = <DetailFormContainer />
    const screeningFormContainer = (
        <ScreeningFormContainer
            isSavingScreeningData={isSavingScreeningData}
            setIsSavingScreeningData={setIsSavingScreeningData}
            setPageNumber={setPageNumber}
        />
    )
    const bankFormContainer = <BankFormContainer setPageNumber={setPageNumber} />

    const getTooltipTitle = (type) => <StyledSpan color={colors.graniteGray}>{formInfo(type)}</StyledSpan>

    const getPanesTitle = (key, title, placement, type) => (
        <AntdSpace>
            <>{title}</>
            {isMobile() && (
                <Tooltip
                    content={
                        <StyledInfoCircleOutlined
                            $color={key === activeKey ? colors.purpleHeart : colors.silver}
                            style={{ fontSize: '9px' }} // TO:DO need to fix all icon in mobile view render with same size
                        />
                    }
                    placement={placement}
                    title={getTooltipTitle(type)}
                    trigger='click'
                />
            )}
        </AntdSpace>
    )

    let panes = [
        {
            content: detailFormContainer,
            key: '1',
            placement: 'bottomRight',
            title: 'Akun Profil',
            type: 'detail',
        },
        {
            content: screeningFormContainer,
            key: '2',
            placement: 'bottom',
            title: 'Screening',
            type: 'screening',
        },
        {
            content: bankFormContainer,
            key: '3',
            placement: 'bottomLeft',
            title: 'Rekening Bank',
            type: 'bank',
        },
    ]
    panes = map(panes, (item) =>
        Object.assign(item, {
            title: getPanesTitle(item.key, item.title, item.placement, item.type),
        })
    )

    useEffect(() => {
        if (!isActionLoadingDigisign) {
            refetchUser({ email }).then(() => {
                if (isEmpty(digisignActivationUrl)) {
                    return null
                }

                window.open(digisignActivationUrl, '_blank')
                navigate('/profile')
            })
        }
    }, [digisignActivationUrl, email, isActionLoadingDigisign, navigate, refetchUser])

    useEffect(() => {
        if (isPemodal) {
            if (isDetailPage || (isSceeningPage && isDetailExist)) {
                getPemodalProfile(type)
            } else if (isBankPage && isBankinfoExist) {
                getPemodalBankinfo()
            }
        }
        if (isPenerbit) {
            if (isDetailPage) {
                getPenerbit()
            }
            if (isSceeningPage) {
                if (!isEmpty(name)) {
                    getPenerbitDetail(name)
                }
            }
            if (isBankPage && isBankinfoExist) {
                getPenerbitBankinfo()
            }
        }
    }, [
        isBankinfoExist,
        isBankPage,
        isDetailExist,
        isDetailPage,
        isSceeningPage,
        getPemodalBankinfo,
        getPemodalProfile,
        getPenerbit,
        getPenerbitBankinfo,
        getPenerbitDetail,
        isPemodal,
        isPenerbit,
        name,
        type,
    ])

    useEffect(() => {
        if (!isActionLoadingPemodal && isActionSuccessPemodal && isDetailExist && isSceeningPage) {
            getPemodalProfile(type)
        }
    }, [isActionLoadingPemodal, isActionSuccessPemodal, isDetailExist, isSceeningPage, getPemodalProfile, type])

    const handleChangeTab = (activeKey) => {
        const route = `/profile/${pemodalRouteType[activeKey - 1]}`
        navigate(route)
    }

    const getTitle = () => {
        let title = 'Oops ! Terjadi Kesalahan'
        if (isActionSuccessPemodalBankinfo || isActionSuccessPenerbitBankinfo || isMessageBerhasil) {
            title = 'Selamat moneypool-er !'
        }
        if (isNeedActivation) {
            title = 'Yeay! e-KYC Anda telah Berhasil'
        }

        return title
    }

    const imageSrc = imagePath.pump

    const getDescription = () => {
        if (isEmailVerified) {
            const isSuccessScreening = isSceeningPage && !isActionLoadingDigisign && isActionSuccessDigisign
            const isSuccessBank =
                isBankPage &&
                ((!isActionLoadingPemodalBankinfo && isActionSuccessPemodalBankinfo) ||
                    (!isActionLoadingPenerbitBankinfo && isActionSuccessPenerbitBankinfo))

            const verifiedDesc = (
                <Fragment>
                    Data kamu telah berhasil kami terima. Tunggu maximal 1x24 jam untuk verifikasi akunmu oleh tim kami.
                    <br />
                    Terimakasih !
                </Fragment>
            )

            if (isSuccessBank && isKycStatusSuccessVerified) {
                return verifiedDesc
            }
            if (isSuccessScreening && isMessageBerhasil && isBankinfoExist) {
                return verifiedDesc
            }

            return parse(message) || ''
        }

        return parse(message) || ''
    }

    const getContent = () => {
        const showMainButton = !isSceeningPage || (isSceeningPage && isDigisignActivated)

        return (
            <Fragment>
                {(isNeedActivation || isMessageBerhasil || isActionSuccessPemodalBankinfo) && (
                    <AntdRow>
                        <AntdCol md={16} sm={24} xs={24}>
                            <StyledAntdSpace direction='vertical'>
                                {showMainButton &&
                                    renderLinkButton(isSceeningPage ? '/profile/bank' : '/products', {
                                        dark,
                                        name: isSceeningPage ? 'register-bankinfo-btn' : 'see-products-btn',
                                        value: isSceeningPage ? 'Registrasi Rekening Bank' : 'Lihat Penawaran',
                                    })}
                                {isNeedActivation && (
                                    <Button
                                        dark={dark}
                                        loading={isActionLoadingDigisign}
                                        name='generate-link-activation'
                                        onClick={generateLinkActivation}
                                        value='Aktivasi Digisign'
                                    />
                                )}
                            </StyledAntdSpace>
                        </AntdCol>
                    </AntdRow>
                )}
                {isSceeningPage && (
                    <div>
                        <br />
                        <BackButton route='/profile' />
                    </div>
                )}
            </Fragment>
        )
    }

    const result = (
        <Result content={getContent()} description={getDescription()} imageSrc={imageSrc} title={getTitle()} />
    )

    const penerbitPage = {
        detail: detailFormContainer,
        screening: screeningFormContainer,
        bank: bankFormContainer,
    }

    const editProfileContent = (
        <AntdRow>
            <AntdCol md={11} sm={0} xs={0}>
                {renderSideContent()}
            </AntdCol>
            <AntdCol md={{ offset: 2, span: 11 }} sm={24} xs={24}>
                <StyledAntdSpace direction='vertical' size='large'>
                    {isPemodal && (
                        <StyledTabs
                            activeKey={activeKey}
                            name='tabAction'
                            onChange={handleChangeTab}
                            panes={panes}
                            tabBarGutter={isMobile() ? 10 : 30}
                        />
                    )}
                    {isPenerbit && penerbitPage[type || (isPenerbitScreeningPage && 'screening')]}
                    <center>
                        <BackButton route={`/profile${isPenerbitScreeningPage ? '/screening-detail' : ''}`} />
                    </center>
                </StyledAntdSpace>
            </AntdCol>
        </AntdRow>
    )

    const pages = [editProfileContent, result]

    if (!isLoggedIn) {
        navigate('/login' + search)
        return null
    }

    if (currentRegisterStep) {
        navigate(`/register/${user}/step${currentRegisterStep}`)
        return null
    }

    if (
        (isPemodal && !includes(pemodalRouteType, type)) ||
        (isPenerbit && !(includes(penerbitRouteType, type) || isPenerbitScreeningPage))
    ) {
        navigate('/profile')
        return null
    }
    return (
        <StyledSection>
            <HelmetMeta mainTitle='Ubah Profil' needIndex={false} />
            {pages[pageNumber]}
        </StyledSection>
    )
}

EditProfile.propTypes = {
    clearDigisign: PropTypes.func,
    clearPemodal: PropTypes.func,
    clearPemodalBankinfo: PropTypes.func,
    clearPenerbit: PropTypes.func,
    clearPenerbitBankinfo: PropTypes.func,
    clearPenerbitDetail: PropTypes.func,
    clearVerification: PropTypes.func,
    currentRegisterStep: PropTypes.number,
    digisignActivationUrl: PropTypes.func,
    email: PropTypes.string,
    generateLinkActivation: PropTypes.func,
    getPemodalBankinfo: PropTypes.func,
    getPemodalProfile: PropTypes.func,
    getPenerbit: PropTypes.func,
    getPenerbitBankinfo: PropTypes.func,
    getPenerbitDetail: PropTypes.func,
    isActionLoadingDigisign: PropTypes.bool,
    isActionLoadingPemodal: PropTypes.bool,
    isActionLoadingPemodalBankinfo: PropTypes.bool,
    isActionLoadingPenerbitBankinfo: PropTypes.bool,
    isActionSuccessDigisign: PropTypes.bool,
    isActionSuccessPemodal: PropTypes.bool,
    isActionSuccessPemodalBankinfo: PropTypes.bool,
    isActionSuccessPenerbitBankinfo: PropTypes.bool,
    isBankinfoExist: PropTypes.bool,
    isDetailExist: PropTypes.bool,
    isDigisignActivated: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    isKycStatusSuccessVerified: PropTypes.bool,
    isNeedActivation: PropTypes.bool,
    message: PropTypes.string,
    refetchUser: PropTypes.func,
    user: PropTypes.string,
}

export default EditProfile
