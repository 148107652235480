import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import replace from 'lodash/replace'
import trimStart from 'lodash/trimStart'
import moment from 'moment'
import { DATE_HOUR_SLASH_FORMAT, DATE_SLASH_FORMAT } from '../constants/formats'
import * as cityTransformer from './city'
import * as districtTransformer from './district'
import * as penerbitDetailDocumentTransformer from './penerbitDetailDocument'

export const toApi = (data) => {
    const mobile_number = data.mobileNumber ? '+62' + trimStart(data.mobileNumber, '0') : undefined

    const birth_date = moment(data.birthDate, DATE_SLASH_FORMAT).isValid()
        ? moment.utc(data.birthDate, DATE_SLASH_FORMAT).toISOString()
        : undefined

    const penerbit_detail_documents = !isEmpty(data.penerbitDetailDocuments)
        ? map(data.penerbitDetailDocuments, (item) =>
              penerbitDetailDocumentTransformer.toApi(
                  Object.assign(item, {
                      penerbitDetailId: data.id || item.penerbitDetailId,
                  })
              )
          )
        : undefined

    return {
        address: data.address,
        annual_salary_id: data.annualSalaryId,
        birth_date,
        city_id: data.cityId,
        created_by: data.createdBy,
        district_id: data.districtId,
        email: data.email,
        gender: data.gender,
        hometown_id: data.hometownId,
        id_card_name: data.idCardName,
        id_card_number: data.idCardNumber,
        id: data.id,
        income_source_id: data.incomeSourceId,
        is_same_as_penerbit_email: data.isSameAsPenerbitEmail,
        is_uploading: data.isUploading,
        mobile_number,
        mother_name: data.motherName,
        penerbit_detail_documents,
        penerbit_id: data.penerbitId,
        position: data.position,
        postal_code: data.postalCode,
    }
}

export const toState = (data) => {
    const birthDate = moment(data.birthDate).isValid() ? moment(data.birthDate).format(DATE_SLASH_FORMAT) : null
    const city = !isEmpty(data.city) ? cityTransformer.toState(data.city) : {}
    const district = !isEmpty(data.district) ? districtTransformer.toState(data.district) : {}
    const mobileNumber = data.mobileNumber ? replace(data.mobileNumber, '+62', '') : ''

    const penerbitDetailDocuments = !isEmpty(data.penerbitDetailDocuments)
        ? map(data.penerbitDetailDocuments, (item) => penerbitDetailDocumentTransformer.toState(item))
        : undefined
    const ktp = filter(penerbitDetailDocuments, (item) => item.identifier === 'ktp')
    const selfie = filter(penerbitDetailDocuments, (item) => item.identifier === 'selfie')

    const tokenExpiryDate = moment(data.tokenExpiryDate).isValid()
        ? moment(data.tokenExpiryDate).format(DATE_HOUR_SLASH_FORMAT)
        : null

    return {
        address: data.address || '',
        annualSalaryId: data.annualSalaryId || null,
        birthDate,
        city,
        cityId: data.cityId || null,
        district,
        districtId: data.districtId || null,
        email: data.email || '',
        entityIdentificationNo: data.entityIdentificationNo || '',
        gender: data.gender || '',
        hometownId: data.hometownId || null,
        id: data.id || null,
        idCardName: data.idCardName || '',
        idCardNumber: data.idCardNumber || '',
        incomeSourceId: data.incomeSourceId || null,
        isEmailVerified: data.isEmailVerified || false,
        isKycVerified: data.isKycVerified || false,
        isSameAsPenerbitEmail: data.isSameAsPenerbitEmail || false,
        mobileNumber,
        motherName: data.motherName || '',
        ktp,
        selfie,
        penerbitId: data.penerbitId || null,
        position: data.position || '',
        postalCode: data.postalCode || '',
        tokenExpiryDate,
    }
}
