import isArray from 'lodash/isArray'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FormItem, Select } from '../../../antdcomponents'

const ReduxFormSelect = (props) => {
    const { componentProps, dark, disabled, formItemProps, input, keyValue, label, meta, placeholder } = props
    const { options } = componentProps || {}

    const { name } = input
    const currentValue = props.value || input.value
    const { touched, error } = meta
    const [isTouched, setIsTouched] = useState(false)
    const isError = (touched || isTouched) && !!error

    const handleChange = (value) => {
        if (!keyValue || !value) return input.onChange(value)
        if (!isArray(value)) return input.onChange(find(options, (item) => item.value[keyValue] === value)['value'])
        return input.onChange(map(value, (item) => find(options, (option) => option.value[keyValue] === item)['value']))
    }

    let transformedValue = map(currentValue, (item) => item[keyValue])
    if (!isArray(currentValue)) transformedValue = currentValue[keyValue]
    if (!keyValue || !currentValue) transformedValue = currentValue
    if (currentValue === '') transformedValue = undefined

    let newOptions = options
    if (keyValue)
        newOptions = map(options, (item) => ({
            label: item.label,
            value: item.value[keyValue],
        }))

    const handleBlur = () => setIsTouched(true)

    return (
        <FormItem
            {...formItemProps}
            dark={dark}
            help={isError && error}
            label={label}
            validateStatus={isError ? 'error' : undefined}
        >
            <Select
                {...componentProps}
                dark={dark}
                disabled={disabled}
                name={name}
                onBlur={handleBlur}
                onChange={handleChange}
                options={newOptions}
                placeholder={placeholder}
                value={transformedValue}
            />
        </FormItem>
    )
}

ReduxFormSelect.propTypes = {
    componentProps: PropTypes.object,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    formItemProps: PropTypes.object,
    input: PropTypes.object.isRequired,
    keyValue: PropTypes.string,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
}

export default ReduxFormSelect
