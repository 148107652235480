import styled, { css } from 'styled-components'

const listCss = css`
    list-style-position: inside;
    padding-left: 0px;
`

export const StyledOrderedList = styled.ol`
    ${listCss}
    margin: 0px;
    list-style: ${(props) => props.$listStyle};
    li {
        margin: 0px !important;
        padding: 0px !important;
    }
`

export const StyledUnorderedList = styled.ul`
    ${listCss}
`
