import get from 'lodash/get'
import * as constantOfPurchaseOrder from '../constants/purchaseOrder'
import { transactionFees } from '../constants/xendit'

export const getDetailPurchaseOrder = (purchaseOrder) => {
    const adminFee = get(purchaseOrder, 'adminFee', constantOfPurchaseOrder.adminFee)
    const paymentMethod = get(purchaseOrder, 'paymentMethod', '')
    const pricePerShare = get(purchaseOrder, 'product.pricePerShare', 0)
    const productId = get(purchaseOrder, 'product.id', null)
    const shareSize = get(purchaseOrder, 'product.shareSize', 1)
    const quantity = get(purchaseOrder, 'quantity', shareSize)

    const unitPrice = get(purchaseOrder, 'unitPrice', pricePerShare)
    const subtotal = quantity * unitPrice
    const operator = get(transactionFees[paymentMethod], 'operator', '')
    let transactionFee = 0
    if (operator === '+') {
        transactionFee = transactionFees[paymentMethod].value
    }
    if (operator === '*') {
        transactionFee = subtotal * transactionFees[paymentMethod].value
    }
    const totalPayment = subtotal + adminFee + transactionFee

    return Object.assign(purchaseOrder, {
        adminFee,
        productId,
        quantity,
        subtotal,
        totalPayment,
        transactionFee,
        unitPrice,
    })
}
