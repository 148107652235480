import React, { Fragment } from 'react'

export const successOptions = {
    content: (
        <Fragment>
            Jaga kerahasiaan datamu.
            <br />
            Penggantian data maksimal 1 kali dalam jangka waktu 14 hari.
        </Fragment>
    ),
    title: 'Pembaharuan data berhasil disimpan',
}
