import {
    CheckCircleFilled,
    ExclamationCircleFilled,
    InfoCircleFilled,
    InfoCircleOutlined,
    PlusOutlined,
    RightCircleOutlined,
} from '@ant-design/icons'
import { default as AntdAvatar } from 'antd/lib/avatar'
import { default as AntdPagination } from 'antd/lib/pagination'
import { default as AntdSpace } from 'antd/lib/space'
import styled, { css } from 'styled-components'
import colors from '../basic/colors'
import modifyVars from '../basic/modifyVars'
import StyledSpan from './styled_span/StyledSpan'
import StyledTitle from './styled_title/StyledTitle'

export const StyledAntdAvatar = styled(AntdAvatar)`
    &&& {
        background-color: ${colors.salmon};
    }
`

export const StyledAntdPagination = styled(AntdPagination)`
    &&& {
        & li {
            background-color: transparent;
            border: none;
        }
        & li.ant-pagination-item {
            margin-right: 0px;
        }
        & li.ant-pagination-item-active {
            background-color: ${colors.blueberry2};
        }
        & li a {
            color: ${(props) => (props.$dark ? 'white' : colors.persianIndigo)};
            font-style: ${modifyVars.spanStyle.fontStyle};
            font-weight: ${modifyVars.spanStyle.fontWeight};
            font-size: ${modifyVars.spanStyle.fontSizeDesktop};
        }
        & li button.ant-pagination-item-link {
            background-color: transparent;
            border: none;
            color: ${(props) => (props.$dark ? 'white' : colors.persianIndigo)};
        }
        @media screen and (min-width: 768px) {
            margin-right: 8px;
        }
    }
`

export const StyledAntdSpace = styled(AntdSpace)`
    &&& {
        width: 100%;
    }
`

export const StyledCarouselImage = styled.img`
    aspect-ratio: 1/1;
    border-radius: 15px;
    height: auto;
    object-fit: cover;
    width: 600px;
`

export const StyledCenterSection = styled.div`
    max-width: 510px;
`

export const StyledChatImage = styled.img`
    height: auto;
    width: 35px;
`

const iconCss = css`
    color: ${(props) => props.$color};
    font-size: ${(props) => props.$size || 20}px;
`

export const StyledCheckCircleFilled = styled(CheckCircleFilled)`
    &&& {
        ${iconCss}
    }
`

export const StyledExclamationCircleFilled = styled(ExclamationCircleFilled)`
    &&& {
        ${iconCss}
    }
`

export const StyledInfoCircleFilled = styled(InfoCircleFilled)`
    &&& {
        ${iconCss}
    }
`

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
    &&& {
        ${iconCss}
    }
`

export const StyledImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`

export const StyledInlineTitle = styled(StyledTitle)`
    display: inline;
`

export const StyledPlusOutlined = styled(PlusOutlined)`
    &&& {
        font-size: 32px;
    }
`

export const StyledRightCircleOutlined = styled(RightCircleOutlined)`
    &&& {
        svg {
            background: radial-gradient(50% 50% at 50% 50%, ${colors.aquamarine}66 0%, ${colors.aquamarine}00 100%);
            font-size: 31px;
        }
        path:first-child {
            color: ${colors.aquamarine};
        }
        path:last-child {
            color: transparent;
        }
    }
`

export const StyledSection = styled.div`
    padding: ${(props) => (props.$size === 'large' ? '50px 200px' : '50px 100px')};

    @media screen and (max-width: 768px) {
        padding: 30px 35px;
    }
`

export const StyledUnderlinedText = styled.span`
    text-decoration: underline;
`

export const StyledUnorderedList = styled.ul`
    ${(props) =>
        props.$type &&
        `
        list-style-type: ${props.$type};
    `}
`

export const StyledUploadButtonSpace = styled(AntdSpace)`
    &&& {
        line-height: 1;
    }
`

export const StyledVerification = styled(StyledSpan)`
    &&& {
        cursor: pointer;
    }
`
