import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'
import uniqBy from 'lodash/uniqBy'
import PropTypes from 'prop-types'
import React, { useEffect, useCallback } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Field } from 'redux-form'
import {
    Button,
    Form,
    ReduxFormInput,
    ReduxFormInputGroup,
    ReduxFormInputPassword,
    ReduxFormSelect,
    ReduxFormTextArea,
    Upload,
} from '../../antdcomponents'
import colors from '../../basic/colors'
import { VALID_IMAGE_EXTENSIONS, VALID_PDF_EXTENSION } from '../../constants/formats'
import {
    maxLength,
    minLength,
    numberRequired,
    passwordMinimalCharacter,
    passwordRequiredCharacter,
    required,
    username,
    validEmail,
} from '../../utility/formValidation'
import mapSelectOptions from '../../utility/mapSelectOptions'
import { renderLink } from '../../utility/renderLinkComp'
import useSelectorFormValues from '../../utility/useSelectorFormValues'
import StyledSpan from '../styled_span/StyledSpan'

const maxLength15 = maxLength(15)
const minLength15 = minLength(15)
const maxLength16 = maxLength(16)
const maxLength28 = maxLength(28)

const PenerbitForm = (props) => {
    const {
        businessSectors,
        change,
        cities,
        clearBusinessSector,
        clearCity,
        clearDistrict,
        clearEntityType,
        clearFundingAmount,
        clearProductType,
        districts,
        entityTypes,
        fundingAmounts,
        getBusinessSectors,
        getCities,
        getDistricts,
        getEntityTypes,
        getFundingAmounts,
        getProductTypes,
        handleSubmit,
        isActionLoadingUser,
        isEmailVerified,
        isLoading,
        isUpdateAllowed,
        npwp,
        onFinish,
        productTypes,
    } = props
    const { dark, dispatch, passwordValidation } = useOutletContext()
    const formValues = useSelectorFormValues('detail')
    const provinceName = get(formValues, 'provinceName', '')
    const cityId = get(formValues, 'cityId', null)
    const districtName = get(formValues, 'districtName', '')
    const subdistrictName = get(formValues, 'subdistrictName', '')
    const isEmptyProv = !provinceName
    const isEmptyProvCity = isEmptyProv || !cityId
    const isEmptyProvCityDist = isEmptyProvCity || !districtName

    const resetSubdistPostal = useCallback(() => {
        change('subdistrictName', '')
        change('postalCode', '')
    }, [change])

    useEffect(() => {
        if (isEmptyProvCityDist) {
            resetSubdistPostal()
        }
        if (isEmptyProvCity) {
            change('districtName', '')
            resetSubdistPostal()
        }
        if (isEmptyProv) {
            change('cityId', '')
            change('districtName', '')
            resetSubdistPostal()
        }
    }, [change, isEmptyProv, isEmptyProvCity, isEmptyProvCityDist, resetSubdistPostal])

    useEffect(() => {
        const fetchBusinessSectors = async () => await getBusinessSectors()
        const fetchCities = async () => await getCities()
        const fetchEntityTypes = async () => await getEntityTypes()
        const fetchFundingAmounts = async () => await getFundingAmounts()
        const fetchProductTypes = async () => await getProductTypes()
        fetchBusinessSectors()
        fetchCities()
        fetchEntityTypes()
        fetchFundingAmounts()
        fetchProductTypes()

        return () => {
            dispatch(clearBusinessSector())
            dispatch(clearCity())
            dispatch(clearDistrict())
            dispatch(clearEntityType())
            dispatch(clearFundingAmount())
            dispatch(clearProductType())
        }
    }, [
        clearBusinessSector,
        clearCity,
        clearDistrict,
        clearEntityType,
        clearFundingAmount,
        clearProductType,
        dispatch,
        getBusinessSectors,
        getCities,
        getEntityTypes,
        getFundingAmounts,
        getProductTypes,
    ])

    useEffect(() => {
        const fetchDistricts = async () => await getDistricts({ options: { cityId } })
        if (cityId) {
            fetchDistricts()
        }
    }, [cityId, getDistricts])

    useEffect(() => {
        const postalCode = get(find(districts, { subdistrict: subdistrictName }), 'postalCode', '')
        if (!isEmpty(postalCode)) {
            change('postalCode', postalCode)
        }
    }, [change, districts, subdistrictName])

    const provinceOptions = uniqBy(
        map(cities, (item) => ({
            label: item.province,
            value: item.province,
        })),
        'label'
    )
    const cityOptions = filter(cities, (item) => item.province === provinceName)
    const districtOptions = uniqBy(
        map(districts, (item) => ({
            label: item.name,
            value: item.name,
        })),
        'label'
    )
    const subdistrictOptions = map(
        filter(districts, (item) => item.name === districtName),
        (item) => ({
            label: item.subdistrict,
            value: item.subdistrict,
        })
    )

    const handleRemove = () => {
        change('npwp', [])
    }

    const handleUpload = (options) => {
        const { fileList = [], onProgress = noop, onSuccess = noop } = options
        const newFileList = map(cloneDeep(fileList), (item) => {
            return Object.assign(item, {
                fieldName: 'npwp',
                identifier: 'npwp',
                onProgress,
                onSuccess,
            })
        })
        change('npwp', newFileList)
    }

    return (
        <Form onFinish={handleSubmit(onFinish)}>
            <Field
                component={ReduxFormInput}
                dark={dark}
                disabled={isEmailVerified}
                formItemProps={{ required: true }}
                label='Email'
                name='email'
                placeholder='Masukkan Email'
                validate={[required, validEmail]}
            />
            <Field
                component={ReduxFormInput}
                dark={dark}
                disabled={isEmailVerified && !isUpdateAllowed}
                formItemProps={{ required: true }}
                label='Username'
                name='username'
                placeholder='Masukkan Username'
                validate={[required, maxLength15, username]}
            />
            <Field
                component={ReduxFormInputGroup}
                dark={dark}
                disabled={isEmailVerified && !isUpdateAllowed}
                formItemProps={{ required: true }}
                label='Nomor Telepon Seluler'
                name='mobileNumber'
                placeholder='Masukkan Nomor Telepon Seluler'
                prefix='+62'
                validate={[required, numberRequired]}
            />
            <Field
                component={ReduxFormInput}
                dark={dark}
                disabled={isEmailVerified && !isUpdateAllowed}
                formItemProps={{ required: true }}
                label='Kode Perusahaan'
                name='entityCode'
                placeholder='Masukkan Kode Perusahaan'
                validate={[required]}
            />
            <AntdRow>
                <AntdCol span={7}>
                    <Field
                        component={ReduxFormSelect}
                        componentProps={{
                            optionFilterProp: 'label',
                            options: mapSelectOptions(entityTypes, 'value'),
                        }}
                        dark={dark}
                        disabled={isEmailVerified}
                        formItemProps={{ required: true }}
                        label='Nama'
                        name='entityTypeId'
                        placeholder='Pilih Tipe Entitas'
                        validate={[required]}
                    />
                </AntdCol>
                <AntdCol offset={1} span={16}>
                    <Field
                        component={ReduxFormInput}
                        dark={dark}
                        disabled={isEmailVerified}
                        name='entityName'
                        placeholder='Masukkan Nama'
                        validate={[maxLength28, required]}
                    />
                </AntdCol>
            </AntdRow>
            <Field
                component={ReduxFormSelect}
                componentProps={{
                    optionFilterProp: 'label',
                    options: mapSelectOptions(businessSectors, 'value'),
                }}
                dark={dark}
                disabled={isEmailVerified}
                formItemProps={{ required: true }}
                label='Sektor Usaha'
                name='businessSectorId'
                placeholder='Pilih Sektor Usaha'
                validate={[required]}
            />
            <AntdRow gutter={15}>
                <AntdCol md={12} sm={24} xs={24}>
                    <Field
                        component={ReduxFormSelect}
                        componentProps={{
                            options: provinceOptions,
                        }}
                        dark={dark}
                        disabled={isEmailVerified && !isUpdateAllowed}
                        formItemProps={{ required: true }}
                        label='Provinsi'
                        name='provinceName'
                        placeholder='Pilih Provinsi'
                        validate={[required]}
                    />
                </AntdCol>
                <AntdCol md={12} sm={24} xs={24}>
                    <Field
                        component={ReduxFormSelect}
                        componentProps={{
                            optionFilterProp: 'label',
                            options: mapSelectOptions(cityOptions, ['type', 'name']),
                        }}
                        dark={dark}
                        disabled={!provinceName || (isEmailVerified && !isUpdateAllowed)}
                        formItemProps={{ required: true }}
                        label='Kota'
                        name='cityId'
                        placeholder='Pilih Kota'
                        validate={[required]}
                    />
                </AntdCol>
                <AntdCol md={12} sm={24} xs={24}>
                    <Field
                        component={ReduxFormSelect}
                        componentProps={{
                            options: districtOptions,
                        }}
                        dark={dark}
                        disabled={!cityId || (isEmailVerified && !isUpdateAllowed)}
                        formItemProps={{ required: true }}
                        label='Kecamatan'
                        name='districtName'
                        placeholder='Pilih Kecamatan'
                        validate={[required]}
                    />
                </AntdCol>
                <AntdCol md={12} sm={24} xs={24}>
                    <Field
                        component={ReduxFormSelect}
                        componentProps={{
                            options: subdistrictOptions,
                        }}
                        dark={dark}
                        disabled={!districtName || (isEmailVerified && !isUpdateAllowed)}
                        formItemProps={{ required: true }}
                        label='Kelurahan'
                        name='subdistrictName'
                        placeholder='Pilih Kelurahan'
                        validate={[required]}
                    />
                </AntdCol>
            </AntdRow>
            <Field
                component={ReduxFormInput}
                dark={dark}
                disabled={isEmailVerified && !isUpdateAllowed}
                formItemProps={{ required: true }}
                label='Kode Pos'
                name='postalCode'
                placeholder='Masukkan Kode Pos'
                validate={[numberRequired]}
            />
            <Field
                component={ReduxFormTextArea}
                componentProps={{ rows: 3 }}
                dark={dark}
                disabled={isEmailVerified && !isUpdateAllowed}
                formItemProps={{ required: true }}
                label='Alamat Lengkap Usaha'
                name='address'
                placeholder='Contoh : Jalan Ahmad Yani Gang 5 Nomor 80'
                validate={[required]}
            />
            <Field
                component={ReduxFormInput}
                dark={dark}
                disabled={isEmailVerified}
                formItemProps={{ required: true }}
                label='Nomor NPWP'
                name='entityIdentificationNo'
                placeholder='Masukkan Nomor NPWP'
                validate={[minLength15, maxLength16, numberRequired]}
            />
            <Upload
                accept={[VALID_IMAGE_EXTENSIONS, VALID_PDF_EXTENSION]}
                disabled={isEmailVerified && !isUpdateAllowed}
                fileList={npwp}
                formItemProps={{ required: true }}
                label='NPWP'
                name='npwp'
                onRemove={handleRemove}
                onUpload={handleUpload}
            />
            <Field
                component={ReduxFormInputGroup}
                dark={dark}
                disabled={isEmailVerified && !isUpdateAllowed}
                label='Situs Resmi'
                name='websiteUrl'
                placeholder='Masukkan Situs Resmi'
                prefix='https://'
            />
            <Field
                component={ReduxFormTextArea}
                componentProps={{ rows: 3 }}
                dark={dark}
                disabled={isEmailVerified && !isUpdateAllowed}
                label='Deskripsi'
                name='description'
                placeholder='Masukkan Deskripsi'
            />
            <Field
                component={ReduxFormSelect}
                componentProps={{
                    optionFilterProp: 'label',
                    options: mapSelectOptions(productTypes, 'title'),
                }}
                dark={dark}
                disabled={isEmailVerified && !isUpdateAllowed}
                formItemProps={{ required: true }}
                label='Tipe Pendanaan'
                name='productTypeId'
                placeholder='Pilih Tipe Pendanaan'
                validate={[required]}
            />
            <Field
                component={ReduxFormSelect}
                componentProps={{
                    optionFilterProp: 'label',
                    options: mapSelectOptions(fundingAmounts, 'value'),
                }}
                dark={dark}
                disabled={isEmailVerified && !isUpdateAllowed}
                formItemProps={{ required: true }}
                label='Nominal Pendanaan'
                name='fundingAmountId'
                placeholder='Pilih Nominal Pendanaan'
                validate={[required]}
            />
            <Field
                component={ReduxFormInputPassword}
                componentProps={{ visibilityToggle: !isEmailVerified }}
                dark={dark}
                disabled={isEmailVerified}
                formItemProps={{
                    noMargin: passwordValidation ? passwordValidation : isEmailVerified,
                    required: true,
                }}
                label='Kata Sandi'
                name='password'
                placeholder='Masukkan Kata Sandi'
                validate={isEmailVerified ? [] : [required, passwordRequiredCharacter, passwordMinimalCharacter]}
            />
            {isEmailVerified && (
                <AntdRow justify='end'>
                    {renderLink(
                        { to: '/forgot-password' },
                        <StyledSpan color={colors.salmon}>{`Klik untuk mengubah password >`}</StyledSpan>
                    )}
                </AntdRow>
            )}
            {!isEmailVerified && (
                <Field
                    component={ReduxFormInputPassword}
                    componentProps={{
                        isConfirmPassword: true,
                    }}
                    dark={dark}
                    formItemProps={{ required: true }}
                    label='Konfirmasi Kata Sandi'
                    name='confirmPassword'
                    placeholder='Masukkan Konfirmasi Kata Sandi'
                    validate={[required]}
                />
            )}
            <br />
            <AntdRow>
                <AntdCol offset={6} span={12}>
                    <Button
                        dark={dark}
                        disabled={(isEmailVerified && !isUpdateAllowed) || isEmpty(npwp)}
                        htmlType='submit'
                        loading={isActionLoadingUser || isLoading}
                        name='register-btn'
                        value={isEmailVerified ? 'Simpan' : 'Daftar'}
                    />
                </AntdCol>
            </AntdRow>
        </Form>
    )
}

PenerbitForm.propTypes = {
    businessSectors: PropTypes.array,
    change: PropTypes.func,
    cities: PropTypes.array,
    clearBusinessSector: PropTypes.func,
    clearCity: PropTypes.func,
    clearDistrict: PropTypes.func,
    clearEntityType: PropTypes.func,
    clearFundingAmount: PropTypes.func,
    clearProductType: PropTypes.func,
    districts: PropTypes.array,
    entityTypes: PropTypes.array,
    fundingAmounts: PropTypes.array,
    getBusinessSectors: PropTypes.func,
    getCities: PropTypes.func,
    getDistricts: PropTypes.func,
    getEntityTypes: PropTypes.func,
    getFundingAmounts: PropTypes.func,
    getProductTypes: PropTypes.func,
    handleSubmit: PropTypes.func,
    isActionLoadingUser: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    isLoading: PropTypes.bool,
    isUpdateAllowed: PropTypes.bool,
    npwp: PropTypes.array,
    onFinish: PropTypes.func,
    productTypes: PropTypes.array,
}

export default PenerbitForm
