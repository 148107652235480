import React from 'react'
import { useOutletContext } from 'react-router-dom'
import HelmetMeta from '../components/HelmetMeta'
import PrivacyPolicyText from '../components/privacy_policy_text/PrivacyPolicyText'
import { StyledSection } from '../components/StyledComponents'

const PrivacyPolicy = () => {
    const { dark } = useOutletContext()

    const metaTitle = 'Kebijakan Privasi'

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} />
            <PrivacyPolicyText dark={dark} title={metaTitle} />
        </StyledSection>
    )
}

export default PrivacyPolicy
