import isLocalStorageAvailable from './isLocalStorageAvailable'
import get from 'lodash/get'

const setPersistentData = (windowObj = {}, key, value) => {
    if (isLocalStorageAvailable(windowObj)) {
        let localStorage = get(windowObj, 'localStorage', {})
        if (typeof window !== 'undefined') {
            localStorage = window.localStorage
        }
        localStorage.setItem(key, value)
        return
    }
    document.cookie = `${key}=${value}`
    return
}

export default setPersistentData
