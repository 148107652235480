import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import PropTypes from 'prop-types'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import HelmetMeta from '../../components/HelmetMeta'
import { StyledImage, StyledSection } from '../../components/StyledComponents'
import StyledTitle from '../../components/styled_title/StyledTitle'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { renderLinkButton } from '../../utility/renderLinkComp'

const Success = (props) => {
    const { currentRegisterStep, user, username } = props
    const { dark, isLoggedIn, isPemodal, isPenerbit, navigate } = useOutletContext()

    const image = <StyledImage src={isPemodal ? imagePath.profit : imagePath.purchaseOrderSuccess} />

    if (isLoggedIn) {
        if (currentRegisterStep) {
            navigate(`/register/${user}/step${currentRegisterStep}`)
            return null
        }
    } else {
        navigate('/register')
        return null
    }

    const metaTitle = 'Daftar Berhasil'

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <AntdRow align='middle'>
                <AntdCol md={12} sm={0} xs={0}>
                    <AntdRow justify='center'>{renderLazyLoadImage(image)}</AntdRow>
                </AntdCol>
                <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                    <StyledTitle $dark={dark} color={colors.graniteGray} level={2}>
                        Hi, {username} !
                    </StyledTitle>
                    <StyledTitle $dark={dark} color={colors.graniteGray} level={5}>
                        {isPemodal && 'Kamu telah menjadi bagian dari moneypool-er'}
                        {isPenerbit && 'Kamu telah terdaftar sebagai penerbit moneypool'}
                        &#x1F389; Lengkapi data di akun profilmu untuk aktivasi transaksi.
                    </StyledTitle>
                    <br />
                    <AntdRow gutter={[20, 20]}>
                        <AntdCol md={12} sm={isPemodal ? 12 : 24} xs={isPemodal ? 12 : 24}>
                            {renderLinkButton('/profile', { dark, name: 'fill-data-btn', value: 'Lengkapi Data' })}
                        </AntdCol>
                        {isPemodal && (
                            <AntdCol span={12}>
                                {renderLinkButton('/products', { dark, name: 'offer-btn', value: 'Lihat Penawaran' })}
                            </AntdCol>
                        )}
                    </AntdRow>
                </AntdCol>
            </AntdRow>
        </StyledSection>
    )
}

Success.propTypes = {
    currentRegisterStep: PropTypes.number,
    user: PropTypes.string,
    username: PropTypes.string,
}

export default Success
