import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Button, Modal } from '../../antdcomponents'
import colors from '../../basic/colors'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { renderLinkButton } from '../../utility/renderLinkComp'

const DataVerification = (props) => {
    const { isModalOpen, isKycSuccess, onCancel } = props

    const footer = (
        <AntdRow gutter={[0, 15]} justify='center'>
            <AntdCol md={11} sm={24} xs={24}>
                {renderLinkButton('/profile/screening', { name: 'verify-now-btn', value: 'Ya, Bersedia' })}
            </AntdCol>
            <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                <Button
                    name='verify-later-btn'
                    onClick={onCancel}
                    value={`${isKycSuccess ? 'Aktivasi' : 'Verifikasi'} Nanti`}
                />
            </AntdCol>
        </AntdRow>
    )

    let modalContent = (
        <Fragment>
            <center>
                <StyledTitle color={colors.graniteGray} level={2}>
                    Verifikasi Data Diperlukan
                </StyledTitle>
            </center>
            <br />
            <StyledTitle color={colors.doveGray} level={3}>
                Anda diharuskan untuk melakukan proses e-KYC sebelum menggunakan layanan moneypool.id. Proses ini
                ditujukan untuk memastikan bahwa identitas Anda adalah benar dan sah. Pihak moneypool.co.id berhak
                menolak permohonan atau mengubah status keabsahan sebagai pemodal apabila data yang ditemukan tidak
                valid dan/atau terdapat kriteria e-KYC lainnya yang tidak terpenuhi.
                <br />
                <br />
                Layanan e-KYC sepenuhnya otomatis, transparan, teliti, objektif, taat prosedur melalui moneypool.co.id.
                Silahkan lanjut proses verifikasi dengan langkah sebagai berikut:
                <br />
                {`Buka halaman "Profil" > Pilih menu "Screening" > Isi data diri lengkap dengan informasi dan dokumen yang valid > Klik "Verifikasi"`}
                <br />
                <br />
                Anda bersedia untuk memulai verifikasi data diri ?
            </StyledTitle>
        </Fragment>
    )

    if (isKycSuccess) {
        modalContent = (
            <Fragment>
                <center>
                    <StyledTitle color={colors.graniteGray} level={2}>
                        Aktivasi Data Diperlukan
                    </StyledTitle>
                </center>
                <br />
                <StyledTitle color={colors.doveGray} level={3}>
                    Satu langkah terakhir untuk KYC sukses. Silahkan lakukan aktivasi Digisign terlebih dahulu untuk
                    memulai transaksi pada platform moneypool
                    <br />
                    <br />
                    Anda Bersedia untuk aktivasi akun Digisign sekarang?
                </StyledTitle>
            </Fragment>
        )
    }

    return (
        <Modal footer={footer} onCancel={onCancel} visible={isModalOpen}>
            {modalContent}
        </Modal>
    )
}

DataVerification.propTypes = {
    isActivated: PropTypes.bool,
    isKycSuccess: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    onCancel: PropTypes.func,
}

export default DataVerification
