import { createAsyncThunk } from '@reduxjs/toolkit'
import * as api from '../api'

export const getInfo = createAsyncThunk('GET_INFO', async (data, { rejectWithValue }) => {
    try {
        const res = await api.fetch('/info')
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const getSocialMedias = createAsyncThunk('GET_SOCIAL_MEDIAS', async (data, { rejectWithValue }) => {
    try {
        const res = await api.fetch('/social-medias')
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})
