import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/businessSector'
import * as businessSectorAPI from './businessSectorAPI'

const initialState = {
    businessSectors: [],
    isError: false,
    isLoading: false,
    totalBusinessSectors: 0,
}

export const { getBusinessSectors } = businessSectorAPI

const businessSectorSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getBusinessSectors.pending, (state) =>
            Object.assign(state, {
                businessSectors: [],
                isError: false,
                isLoading: true,
            })
        )
        builder.addCase(getBusinessSectors.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                businessSectors: map(data.businessSectors, (item) => toState(item)),
                isLoading: false,
                totalBusinessSectors: data.count,
            })
        })
        builder.addCase(getBusinessSectors.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'BUSINESS_SECTOR',
    reducers: {
        clearBusinessSector: () => initialState,
    },
})

export const { clearBusinessSector } = businessSectorSlice.actions

const { reducer } = businessSectorSlice
export default reducer
