import { createAsyncThunk } from '@reduxjs/toolkit'
import { map, snakeCase } from 'lodash'
import * as request from '../../client/request'

export const authenticate = createAsyncThunk(
    'AUTHENTICATE',
    async ({ identifier, password, token, user }, { rejectWithValue }) => {
        try {
            const res = await request.post('/authenticate', { identifier, password, token, user })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const forgotPassword = createAsyncThunk('FORGOT_PASSWORD', async ({ email, userType }, { rejectWithValue }) => {
    try {
        const res = await request.post('/forgot-password', { email, userType })
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const reauthenticate = createAsyncThunk('REAUTHENTICATE', async (data, { rejectWithValue }) => {
    try {
        const res = await request.post('/reauthenticate')
        return res
    } catch (err) {
        return rejectWithValue(err.response)
    }
})

export const refetchUser = createAsyncThunk(
    'REFETCH_USER',
    async ({ email, id, user, additionalAttr = [] }, { rejectWithValue }) => {
        try {
            const res = await request.post('/refetch-user', {
                email,
                id,
                user,
                additional_attr: map(additionalAttr, (attr) => snakeCase(attr)),
            })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)

export const resetPassword = createAsyncThunk(
    'RESET_PASSWORD',
    async ({ password, secret, user }, { rejectWithValue }) => {
        try {
            const res = await request.post('/reset-password', { password, secret, user })
            return res
        } catch (err) {
            return rejectWithValue(err.response)
        }
    }
)
