import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover as AntdPopover } from 'antd'

class Popover extends Component {
    render() {
        const { children, content, placement, title, trigger } = this.props

        return (
            <AntdPopover
                arrowPointAtCenter={false}
                content={content}
                placement={placement}
                title={title}
                trigger={trigger}
            >
                {children}
            </AntdPopover>
        )
    }
}

Popover.propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    placement: PropTypes.oneOf([
        'topLeft',
        'top',
        'topRight',
        'leftTop',
        'left',
        'leftBottom',
        'rightTop',
        'right',
        'rightBottom',
        'bottomLeft',
        'bottom',
        'bottomRight',
    ]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    trigger: PropTypes.oneOf(['click', 'focus', 'hover']),
}

Popover.defaultProps = {
    placement: 'topLeft',
    trigger: 'hover',
}

export default Popover
