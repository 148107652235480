import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from '../../antdcomponents'
import colors from '../../basic/colors'
import StyledTitle from '../../components/styled_title/StyledTitle'
import { renderLinkButton } from '../../utility/renderLinkComp'

const CartModal = (props) => {
    const { isModalOpen, onCancel } = props

    const footer = (
        <AntdRow>
            <AntdCol md={14} sm={24} xs={24}>
                {renderLinkButton('/products', { name: 'see-offer-btn', onClick: onCancel, value: 'Lihat Penawaran' })}
            </AntdCol>
        </AntdRow>
    )

    return (
        <Modal footer={footer} onCancel={onCancel} visible={isModalOpen}>
            <AntdSpace direction='vertical'>
                <StyledTitle color={colors.graniteGray} level={2}>
                    Whoops!!
                </StyledTitle>
                <StyledTitle color={colors.graniteGray} level={3}>
                    Belum ada penawaran terpilih pada keranjangmu
                </StyledTitle>
            </AntdSpace>
        </Modal>
    )
}

CartModal.propTypes = {
    clearCart: PropTypes.func,
    isModalOpen: PropTypes.bool,
    navigate: PropTypes.func,
    onCancel: PropTypes.func,
}

export default CartModal
