import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import moment from 'moment'
import { onGoingStatus } from '../common/options/product'
import { DATE_SLASH_FORMAT } from '../constants/formats'
import getPenerbitName from '../utility/getPenerbitName'
import * as penerbitTransformer from './penerbit'
import * as productCategoryTransformer from './productCategory'
import * as productDocumentTransformer from './productDocument'
import * as productImageTransformer from './productImage'
import * as productRibbonTransformer from './productRibbon'
import * as productTypeTransformer from './productType'
import * as purchaseOrderTransformer from './purchaseOrder'

export const toApi = (data) => ({
    description: data.description,
    end_date: data.endDate,
    id: data.id,
    ribbon_id: data.ribbonId,
    title: data.title,
})

export const toState = (data) => {
    const penerbit = !isEmpty(data.penerbit) ? penerbitTransformer.toState(data.penerbit) : {}
    const productCategory = !isEmpty(data.productCategory)
        ? productCategoryTransformer.toState(data.productCategory)
        : {}
    const productDocuments = !isEmpty(data.productDocuments)
        ? map(data.productDocuments, (item) => productDocumentTransformer.toState(item))
        : []
    const productImages = !isEmpty(data.productImages)
        ? map(data.productImages, (item) => productImageTransformer.toState(item))
        : []
    const productRibbon = !isEmpty(data.productRibbon) ? productRibbonTransformer.toState(data.productRibbon) : {}
    const productType = !isEmpty(data.productType) ? productTypeTransformer.toState(data.productType) : {}
    const purchaseOrders = !isEmpty(data.purchaseOrders)
        ? map(data.purchaseOrders, (item) => purchaseOrderTransformer.toState(item))
        : []
    const endDate = moment(data.endDate).isValid() ? moment(data.endDate).format(DATE_SLASH_FORMAT) : null
    const settlementDate = moment(data.settlementDate).isValid()
        ? moment(data.settlementDate).format(DATE_SLASH_FORMAT)
        : null
    const startDate = moment(data.startDate).isValid() ? moment(data.startDate).format(DATE_SLASH_FORMAT) : null

    const isProductStarted = moment(startDate, DATE_SLASH_FORMAT).isBefore(moment())
    let remainingDates = !isProductStarted
        ? moment(startDate, DATE_SLASH_FORMAT).startOf('day').diff(moment().startOf('day'), 'days')
        : moment(endDate, DATE_SLASH_FORMAT).startOf('day').diff(moment().startOf('day'), 'days')
    remainingDates > 0 ? remainingDates : (remainingDates = 0)
    const remainingDate = moment(data.startDate).isValid() && moment(data.endDate).isValid() ? remainingDates : null

    const category = get(productCategory, 'title', '')
    const type = get(productType, 'title', '')

    const code = data.code ? toUpper(data.code) : ''
    const numberShare = get(data, 'numberShare', 0)
    const outstandingNumberShare = get(data, 'outstandingNumberShare', 0)
    const pricePerShare = get(data, 'pricePerShare', 0)
    const status = get(data, 'status', '')

    const penerbitName = getPenerbitName(penerbit)
    const name = `${code} - ${penerbitName}`

    const isObligation = toLower(type) === 'obligasi'

    const soldShare = numberShare - outstandingNumberShare
    const target = numberShare * pricePerShare
    const collected = soldShare * pricePerShare

    const currentDate = moment()
    const isExpired = moment(endDate, DATE_SLASH_FORMAT).isBefore(currentDate)
    const isOnGoing =
        moment(startDate, DATE_SLASH_FORMAT).isBefore(currentDate) &&
        moment(endDate, DATE_SLASH_FORMAT).isAfter(currentDate)

    let collectedFundPercentage = parseInt((collected / target) * 100, 10)
    collectedFundPercentage = isNaN(collectedFundPercentage) ? 0 : collectedFundPercentage

    const purchaseOrderSubtotal = reduce(purchaseOrders, (sum, item) => sum + item.subtotal, 0)
    const purchaseOrderDividendTotal = reduce(
        purchaseOrders,
        (sum, item) => {
            const purchaseOrderTransactions = get(item, 'purchaseOrderTransactions', [])
            const dividendTransaction = find(
                purchaseOrderTransactions,
                (item) => item.description === 'pembagian dividen'
            )
            const amount = get(dividendTransaction, 'amount', 0)
            return sum + amount
        },
        0
    )

    const isFinished = isExpired && !includes(onGoingStatus, status)

    return {
        category,
        code,
        collected,
        collectedFundPercentage,
        couponRate: data.couponRate || 0,
        description: data.description || '',
        dividendPeriod: data.dividendPeriod || '',
        endDate,
        id: data.id || null,
        interestRate: data.interestRate || 0,
        isExpired,
        isFinished,
        isOnGoing,
        isObligation,
        isProductStarted,
        isShown: data.isShown || false,
        name,
        numberShare: data.numberShare || 0,
        outstandingNumberShare: data.outstandingNumberShare || 0,
        penerbit,
        penerbitId: data.penerbitId || null,
        penerbitName,
        pricePerShare: data.pricePerShare || 0,
        productCategoryId: data.productCategoryId || null,
        productDocuments,
        productImages,
        productRibbon,
        productRibbonId: data.productRibbonId || null,
        productTypeId: data.productTypeId || null,
        prospectusUrl: data.prospectusUrl || '',
        purchaseOrderDividendTotal,
        purchaseOrders,
        purchaseOrderSubtotal,
        remainingDate,
        settlementDate,
        shareSize: data.shareSize || 0,
        slug: data.slug || '',
        soldShare,
        startDate,
        status,
        target,
        title: data.title || '',
        turnoverLastYear: data.turnoverLastYear || 0,
        type,
    }
}
