import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'

export const dividendPeriods = ['3 bulan', '6 bulan', '12 bulan']

export const ongoingStatusLabel = ['Aktif', 'Tidak Aktif']
export const finishedStatusLabel = ['Full Offer', 'Done Offer']
export const canceledStatusLabel = ['Failed Offer']

export const onGoingStatus = ['active', 'inactive']
export const finishedStatus = ['full', 'done']
export const canceledStatus = ['failed']

export const filterDividendPeriodOptions = map(dividendPeriods, (item) => ({
    label: item,
    value: kebabCase(`tiap ${item}`),
}))

const orders = ['Nama Bisnis, A-Z', 'Nama Bisnis, Z-A', 'Sisa Hari, A-Z', 'Sisa Hari, Z-A', 'Terbaru']
export const filterOrderOptions = map(orders, (item) => ({
    label: item,
    value: kebabCase(item),
}))
