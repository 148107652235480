import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/productCategory'
import * as productCategoryAPI from './productCategoryAPI'

const initialState = {
    isError: false,
    isLoading: false,
    productCategories: [],
    totalProductCategories: 0,
}

export const { getProductCategories } = productCategoryAPI

const productCategory = createSlice({
    name: 'PRODUCT_CATEGORY',
    initialState,
    reducers: {
        clearProductCategory: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getProductCategories.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                productCategories: [],
            })
        )
        builder.addCase(getProductCategories.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                productCategories: map(data.productCategories, (item) => toState(item)),
                totalProductCategories: data.count,
            })
        })
        builder.addCase(getProductCategories.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
})

export const { clearProductCategory } = productCategory.actions

const { reducer } = productCategory
export default reducer
