import { RightOutlined } from '@ant-design/icons'
import { default as AntdAvatar } from 'antd/lib/avatar'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import isMobile from 'is-mobile'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import AllMenu from 'react-burger-menu'
import { connect } from 'react-redux'
import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import AvatarPicker from '../avatar_picker/AvatarPicker'
import StyledTitle from '../styled_title/StyledTitle'
import drawerList from './drawerList'
import { StyledAnchor, StyledToogleDrawerIcon } from './StyledComponents'
import { renderLink } from '../../utility/renderLinkComp'

const BurgerMenu = AllMenu.scaleRotate

const Drawer = (props) => {
    const { colorSelector, isOpen, isPemodal, profilePictureUrl, right, toogleOpenDrawer } = props

    const styles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '22px',
            height: '22px',
            right: '21px',
            top: '21px',
            zIndex: '-1',
        },
        bmBurgerBars: {
            background: 'white',
            borderRadius: '5px',
        },
        bmCrossButton: {
            display: 'none',
        },
        bmMenuWrap: {
            width: '350px',
        },
        bmMenu: {
            background: 'white',
            overflowY: 'scroll',
            padding: isMobile() ? '50px 30px 0px' : '50px 50px 0px',
        },
        bmMorphShape: {
            fill: colors.tuna,
        },
        bmItemList: {
            color: 'white',
            height: '90%',
        },
        bmOverlay: {
            background: 'black',
            opacity: '0.3',
        },
    }

    const renderMenus = () => {
        const menus = drawerList(props)
        const toogleDrawerIcon = <StyledToogleDrawerIcon onClick={toogleOpenDrawer} src={imagePath.drawerBurgerMenu} />
        const mapped = map(menus, (item) => {
            if (!item.isShown) {
                return null
            } else if (item.isProfile) {
                if (item.value === '') {
                    return <br key={item} />
                }
                return (
                    <Fragment key={item}>
                        <AntdRow align='middle'>
                            <AntdCol span={isPemodal ? 4 : 0}>
                                {profilePictureUrl ? (
                                    <AntdAvatar size={44} src={profilePictureUrl} />
                                ) : (
                                    <AvatarPicker $size={44} colorSelector={colorSelector} drawerInitial={item.value} />
                                )}
                            </AntdCol>
                            <AntdCol offset={isPemodal ? 2 : 6} span={18}>
                                <AntdRow justify='end'>
                                    <StyledTitle $textAlign='end' level={4}>
                                        {renderLink({ onClick: toogleOpenDrawer, to: '/profile' }, item.value)}
                                    </StyledTitle>
                                </AntdRow>
                            </AntdCol>
                        </AntdRow>
                        <br />
                    </Fragment>
                )
            } else if (item.isToogleDrawer) {
                return (
                    <Fragment key={item}>
                        <AntdRow justify='end'>{renderLazyLoadImage(toogleDrawerIcon)}</AntdRow>
                        <br />
                        <br />
                    </Fragment>
                )
            }

            return (
                <StyledAnchor key={item} onClick={item.onClick}>
                    {renderLink(
                        { onClick: toogleOpenDrawer, to: item.link },
                        <AntdRow align='middle'>
                            <AntdCol span={23}>
                                <StyledTitle level={5}>{item.value}</StyledTitle>
                            </AntdCol>
                            <AntdCol span={1}>
                                <AntdRow>
                                    <RightOutlined />
                                </AntdRow>
                            </AntdCol>
                        </AntdRow>
                    )}
                </StyledAnchor>
            )
        })
        return mapped
    }

    return (
        <BurgerMenu isOpen={isOpen} onClose={toogleOpenDrawer} right={right} styles={styles}>
            {renderMenus()}
        </BurgerMenu>
    )
}

const mapStateToProps = (state) => ({
    colorSelector: state.authentication.colorSelector,
})

Drawer.propTypes = {
    colorSelector: PropTypes.string,
    history: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    isPemodal: PropTypes.bool,
    onSignupLogin: PropTypes.func,
    profilePictureUrl: PropTypes.string,
    right: PropTypes.bool,
    toogleOpenDrawer: PropTypes.func,
}

export default connect(mapStateToProps)(Drawer)
