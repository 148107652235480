import styled from 'styled-components'
import { default as AntdTypography } from 'antd/lib/typography'
import colors from '../../basic/colors'
import modifyVars from '../../basic/modifyVars'

const getColor = (color, dark) => {
    if (dark) {
        return 'white'
    } else {
        if (color) {
            return color
        } else {
            return colors.persianIndigo
        }
    }
}

const AntdTitle = AntdTypography.Title
const StyledPriceTitle = styled(AntdTitle)`
    &&& {
        color: ${(props) => getColor(props.color, props.$dark)};
        font-style: ${modifyVars.priceTitleStyle.fontStyle};
        font-weight: ${modifyVars.priceTitleStyle.fontWeight};
        font-size: ${modifyVars.priceTitleStyle.fontSizeMobile};
        font-family: ${modifyVars.priceTitleStyle.fontFamily};
        margin: 0px;

        @media screen and (min-width: 768px) {
            font-size: ${modifyVars.priceTitleStyle.fontSizeDesktop};
        }
    }
`

export default StyledPriceTitle
