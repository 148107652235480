import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import split from 'lodash/split'
import * as notification from '../utility/notification'

const throwError = (action, next, response) => {
    const error = get(response, 'body', {})
    const isNotShowNotificationError = get(error, 'isNotShowNotificationError', false)
    const statusText = get(response, 'statusText', '')
    if (
        (error.status < 200 || error.status >= 300) &&
        error.status !== 401 &&
        error.errorMessage &&
        !isNotShowNotificationError
    ) {
        notification.error(error.errorMessage || `${error.status || 500} Unknown Cause`)
    }
    if (error.status === 401) {
        return next({
            type: 'AUTHENTICATION/tokenExpired',
        })
    }

    return next({
        customErrorMessage: get(error, 'customErrorMessage', ''),
        errorMessage: get(error, 'errorMessage', statusText),
        failedLoginAttempts: get(error, 'failedLoginAttempts', 0),
        statusCode: error.status,
        type: action.type,
    })
}

const throwSuccess = (response) => {
    const res = response.body
    if (res && res.status >= 200 && res.status < 300) {
        return Promise.resolve(res)
    }
    throw res
}

const asyncAction = () => (next) => (action) => {
    if (includes(action.type, 'executeQuery')) {
        return next(action)
    }

    const type = last(split(action.type, '/'))
    const error = get(action, 'error', {})

    const { payload } = action
    const response = payload
    // TODO: handle ssr executeQuery
    if (!isEmpty(error)) {
        return throwError(action, next, response)
    }
    if (includes(['fulfilled'], type)) {
        try {
            if (response) {
                return throwSuccess(response).then((res) =>
                    next({
                        data: res.data,
                        statusCode: res.status,
                        type: action.type,
                    })
                )
            }
        } catch (e) {
            return throwError(action, e, next)
        }
    }

    return next(action)
}

export default asyncAction
