import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { toState } from '../../transformers/penerbitBankinfo'
import * as penerbitBankinfoAPI from './penerbitBankinfoAPI'

const initialState = {
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    penerbitBankinfo: {},
}

export const { createPenerbitBankinfo, getPenerbitBankinfo } = penerbitBankinfoAPI

const penerbitBankinfo = createSlice({
    extraReducers: (builder) => {
        builder.addCase(createPenerbitBankinfo.pending, (state) =>
            Object.assign(state, {
                isActionLoading: true,
                isActionSuccess: false,
                isError: false,
            })
        )
        builder.addCase(createPenerbitBankinfo.fulfilled, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
        builder.addCase(createPenerbitBankinfo.rejected, (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isError: true,
            })
        )
        builder.addCase(getPenerbitBankinfo.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                penerbitBankinfo: {},
            })
        )
        builder.addCase(getPenerbitBankinfo.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                penerbitBankinfo: toState(data.penerbitBankinfo),
            })
        })
        builder.addCase(getPenerbitBankinfo.rejected, (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'PENERBIT_BANKINFO',
    reducers: {
        clearPenerbitBankinfo: () => initialState,
    },
})

export const { clearPenerbitBankinfo } = penerbitBankinfo.actions

const { reducer } = penerbitBankinfo
export default reducer
