import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React from 'react'
import StyledTitle from '../../components/styled_title/StyledTitle'
import StyledAntdFormItem from './StyledAntdFormItem'

const FormItem = (props) => {
    const {
        dark,
        extra,
        hasFeedback,
        help,
        isHideLabel,
        label,
        labelCol,
        labelColor,
        labelLevel,
        loading,
        noMargin,
        onBlur,
        required,
        rules,
        wrapperCol,
    } = props
    let validateStatus = get(props, 'validateStatus')
    const validateStatusVal = loading ? 'validating' : validateStatus

    const labelTitle = (
        <StyledTitle $dark={dark} color={labelColor} level={labelLevel || 5}>
            {label}
        </StyledTitle>
    )

    return (
        <StyledAntdFormItem
            $dark={dark}
            $isEmptyLabel={isEmpty(label)}
            $isHideLabel={isHideLabel}
            $noMargin={noMargin}
            $required={required}
            extra={extra}
            hasFeedback={hasFeedback}
            help={help || undefined}
            label={labelTitle}
            labelCol={labelCol}
            onBlur={onBlur}
            required={required}
            rules={rules}
            validateStatus={validateStatusVal}
            wrapperCol={wrapperCol}
        >
            {props.children}
        </StyledAntdFormItem>
    )
}

FormItem.propTypes = {
    children: PropTypes.node,
    dark: PropTypes.bool,
    extra: PropTypes.string,
    formItemProps: PropTypes.object,
    hasFeedback: PropTypes.bool,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isHideLabel: PropTypes.bool,
    label: PropTypes.node,
    labelCol: PropTypes.object,
    labelColor: PropTypes.string,
    labelLevel: PropTypes.number,
    loading: PropTypes.bool,
    noLabel: PropTypes.bool,
    noMargin: PropTypes.bool,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    rules: PropTypes.array,
    validateStatus: PropTypes.string,
    wrapperCol: PropTypes.object,
}

export default FormItem
