import React from 'react'
import { connect } from 'react-redux'
import SwitchAccount from './SwitchAccount'

const SwitchAccountContainer = (props) => <SwitchAccount {...props} />

const mapStateToProps = (state) => ({
    user: state.authentication.user,
})

export default connect(mapStateToProps)(SwitchAccountContainer)
