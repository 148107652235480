import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import * as authenticationSlice from '../../features/authentication/authenticationSlice'
import scrollToViewFormError from '../../utility/scrollToViewFormError'
import ForgotPasswordForm from './ForgotPasswordForm'

let ForgotPasswordFormContainer = (props) => <ForgotPasswordForm {...props} />

const mapStateToProps = (state) => {
    const email = state.authentication.email
    const userType = state.authentication.user
    let initialValues

    if (!isEmpty(email) && !isEmpty(userType)) {
        initialValues = {
            email,
            userType,
        }
    }

    return {
        customErrorMessage: state.authentication.customErrorMessage,
        initialValues,
        isActionLoading: state.authentication.isActionLoading,
        isActionSuccess: state.authentication.isActionSuccess,
    }
}

const mapDispatchToProps = {
    clearCustomErrorMessage: authenticationSlice.clearCustomErrorMessage,
    forgotPassword: authenticationSlice.forgotPassword,
    resetAction: authenticationSlice.resetAction,
}

ForgotPasswordFormContainer = reduxForm({
    form: 'forgotPassword',
    onSubmitFail: (errors) => scrollToViewFormError(errors),
})(ForgotPasswordFormContainer)

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordFormContainer)
