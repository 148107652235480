import { default as AntdCard } from 'antd/lib/card'
import ReactWebcam from 'react-webcam'
import styled from 'styled-components'
import { Button, Tabs } from '../antdcomponents'
import colors from '../basic/colors'
import imagePath from '../basic/imagePath'

export const StyledButton = styled(Button)`
    &&& {
        border: ${(props) => (props.$isError ? `solid 2px ${colors.rose}` : null)};
        border-radius: 20px;
        font-size: unset;
        height: 110px;
        padding: 0px;
        width: 100%;
    }
`

export const StyledFormInfoCard = styled(AntdCard)`
    &&& {
        border-radius: 15px;
        box-shadow: 0px 4px 4px ${colors.black}40;
        max-height: 560px;
        overflow-y: auto;
        text-align: justify;
    }
`

export const StyledTabs = styled(Tabs)`
    &&& {
        & .ant-tabs-ink-bar {
            background: ${colors.black}00 !important;
            background-image: url(${imagePath.customUnderline}) !important;
            height: 10px !important;
            width: 110px !important;
            background-repeat: no-repeat;
            background-size: auto;
            background-position: right bottom;
            margin-left: -40px;
        }

        div.ant-tabs-nav-list {
            width: 100%;
        }

        div.ant-tabs-nav::before {
            border-bottom: none;
        }

        div.ant-tabs-nav {
            border-bottom: none;
        }

        div.ant-tabs-tab {
            width: 33%;
            margin: 0px;
        }

        div.ant-tabs-tab-btn {
            font-size: 12px;
        }

        div[aria-selected='true'] {
            color: ${colors.graniteGray};
        }

        div[aria-selected='false'] {
            color: silver;
        }

        @media screen and (min-width: 768px) {
            & .ant-tabs-ink-bar {
                margin-left: -30px;
                width: 140px !important;
            }
            div.ant-tabs-tab-btn {
                font-size: 20px;
            }
        }
    }
`

export const StyledOrderedList = styled.ol`
    list-style-type: lower-alpha !important;
    padding-left: 12px;
`

export const StyledReactWebcam = styled(ReactWebcam)`
    &&& {
        height: 100%;
        width: 100%;
    }
`
