import moment from 'moment'
import { DATE_HOUR_SLASH_FORMAT } from '../constants/formats'

export const toApi = (data) => ({
    created_by: data.createdBy,
    created_by_from_table: data.createdByFromTable,
    inbox_id: data.inboxId,
    message: data.message,
    user: data.user,
})

export const toState = (data) => {
    const createdAt = moment(data.createdAt).isValid() ? moment(data.createdAt).format(DATE_HOUR_SLASH_FORMAT) : null
    return {
        createdAt,
        createdBy: data.createdBy || '',
        createdByFromTable: data.createdByFromTable || '',
        isDeleted: data.isDeleted || false,
        isRead: data.isRead || false,
        message: data.message || '',
    }
}
