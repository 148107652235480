import React from 'react'
import { connect } from 'react-redux'
import * as authenticationSlice from '../../features/authentication/authenticationSlice'
import * as digisignSlice from '../../features/digisign/digisignSlice'
import * as purchaseOrderSlice from '../../features/purchase_order/purchaseOrderSlice'
import PemodalProfile from './PemodalProfile'

const PemodalProfileContainer = (props) => <PemodalProfile {...props} />

const mapStateToProps = (state) => ({
    colorSelector: state.authentication.colorSelector,
    digisignActivationUrl: state.digisign.digisignActivationUrl,
    email: state.authentication.email,
    initialValueTotal: state.purchaseOrder.initialValueTotal,
    isActionLoadingDigisign: state.digisign.isActionLoading,
    isActive: state.authentication.isActive,
    isBankinfoExist: state.authentication.isBankinfoExist,
    isDetailExist: state.authentication.isDetailExist,
    isDigisignActivated: state.authentication.isDigisignActivated,
    isKycStatusSuccess: state.authentication.isKycStatusSuccess,
    isKycStatusVerified: state.authentication.isKycStatusVerified,
    isTermsAndConditionsApproved: state.authentication.isTermsAndConditionsApproved,
    isRiskStatementApproved: state.authentication.isRiskStatementApproved,
    isValidUser: state.authentication.isValidUser,
    pemodalStatement: state.purchaseOrder.pemodalStatement,
    profilePictureUrl: state.authentication.profilePictureUrl,
    totalInboxes: state.inbox.totalInboxes,
    username: state.authentication.username,
    ytdValueTotal: state.purchaseOrder.ytdValueTotal,
})

const mapDispatchToProps = {
    clearDigisign: digisignSlice.clearDigisign,
    generateLinkActivation: digisignSlice.generateLinkActivation,
    getPemodalTransactionSummary: purchaseOrderSlice.getPemodalTransactionSummary,
    refetchUser: authenticationSlice.refetchUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(PemodalProfileContainer)
