import clone from 'lodash/clone'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import formatPrice from '../../utility/formatPrice'

const columns = [
    {
        dataIndex: 'createdAt',
        title: 'Dibuat Pada',
        width: 150,
    },
    {
        dataIndex: 'createdBy',
        title: 'Dibuat Oleh',
        width: 150,
    },
    {
        dataIndex: 'description',
        render: (text) => startCase(text),
        title: 'Deskripsi',
        width: 150,
    },
    {
        dataIndex: 'amount',
        render: (number) => `Rp ${formatPrice(number)}`,
        title: 'Nominal',
        width: 150,
    },
]

const dataSource = (data) =>
    map(data, (item, key) =>
        Object.assign(clone(item), {
            key: String(key),
        })
    )

export { columns, dataSource }
