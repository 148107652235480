import { default as AntdAvatar } from 'antd/lib/avatar'
import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import { default as AntdSpace } from 'antd/lib/space'
import isMobile from 'is-mobile'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'
import size from 'lodash/size'
import startCase from 'lodash/startCase'
import toNumber from 'lodash/toNumber'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useOutletContext } from 'react-router-dom'
import { Field, reset } from 'redux-form'
import { BackButton, Button, Form, InputSearch, ReduxFormInput, Tooltip } from '../antdcomponents'
import colors from '../basic/colors'
import { recieverTypeOptions } from '../common/options/inbox'
import AvatarPicker from '../components/avatar_picker/AvatarPicker'
import HelmetMeta from '../components/HelmetMeta'
import Loader from '../components/loader/Loader'
import { StyledAntdAvatar, StyledAntdSpace, StyledSection } from '../components/StyledComponents'
import StyledParagraph from '../components/styled_paragraph/StyledParagraph'
import StyledSpan from '../components/styled_span/StyledSpan'
import StyledTitle from '../components/styled_title/StyledTitle'
import { DATE_HOUR_SLASH_FORMAT, DATE_SLASH_FORMAT } from '../constants/formats'
import getPenerbitName from '../utility/getPenerbitName'
import { renderLink } from '../utility/renderLinkComp'
import MessageCard from './comps/MessageCard'
import ProductOrderCard from './comps/ProductOrderCard'
import {
    StyledAntdList,
    StyledAntdListItem,
    StyledAntdRow,
    StyledChat,
    StyledEllipsisParagraph,
    StyledInbox,
    StyledInboxContainer,
    StyledInboxesAntdRow,
    StyledInputSearchAntdRow,
    StyledMessageContainer,
    StyledMessageForm,
    StyledMessages,
    StyledReceiverContainer,
    StyledSelect,
    StyledSelectTypeAntdRow,
    StyledSendOutlined,
} from './StyledComponents'
import { getDecryptedId, getEncryptedId } from '../utility/getEncryptedDecryptedId'

const Inbox = (props) => {
    const {
        clearInbox,
        clearInboxDetail,
        createInbox,
        createInboxDetail,
        getInbox,
        getInboxes,
        getPenerbits,
        getProducts,
        handleSubmit,
        id,
        inbox,
        inboxes,
        isActionLoadingInbox,
        isActionLoadingInboxDetail,
        isActionSuccessInboxDetail,
        penerbits,
        products,
        readInboxDetails,
        totalInboxes,
        updateInbox,
        user,
        username,
    } = props

    const { dark, dispatch, isLoggedIn, isPemodal, isPenerbit, navigate, params, width } = useOutletContext()
    const identifier = get(params, 'id', '')
    const isNewChat = includes(identifier, '=')
    const metaTitle = 'Kotak Masuk'
    let inboxDates = []

    const limit = 10
    const [page, setPage] = useState(1)

    const fromTable = get(inbox, 'fromTable', '')
    const inboxDetails = get(inbox, 'inboxDetails', [])
    const pemodal = get(inbox, 'pemodal', {})
    const pemodalUsername = get(pemodal, 'username', '')
    const penerbit = get(inbox, 'penerbit', {})
    const penerbitName = getPenerbitName(penerbit)
    const inboxStatus = get(inbox, 'status', '')
    const toTable = get(inbox, 'toTable', '')
    const isInboxCreatedByCurrentUser = fromTable === user
    const chatWith = isInboxCreatedByCurrentUser ? toTable : fromTable
    const isChatWithPemodal = chatWith === 'pemodal'
    const isChatWithPenerbit = chatWith === 'penerbit'
    const dataLength = size(inboxes)

    const [newChatProductToggle, setNewChatProductToggle] = useState(true)
    const [newChatPenerbitId, setNewChatPenerbitId] = useState('')
    const [newChatProductId, setNewChatProductId] = useState('')
    const [inboxId, setInboxId] = useState()
    const [currentInboxDetails, setCurrentInboxDetails] = useState([])
    const [status, setStatus] = useState()
    const [inboxSearch, setInboxSearch] = useState('')

    const messagesRef = useRef()
    const messages = messagesRef.current

    const tooltipInfo = (
        <Tooltip title='Semua pesan masuk ke dalam sistem untuk dianalisa sebelum diteruskan ke penerima' />
    )

    const suffix = (
        <Button
            dark={dark}
            htmlType='submit'
            loading={isActionLoadingInboxDetail}
            name='send-message-btn'
            type='text'
            value={<StyledSendOutlined rotate={-20} />}
        />
    )

    const fetchInbox = useCallback((inboxId) => getInbox(inboxId), [getInbox])

    const fetchInboxes = useCallback(() => {
        const options = { inboxSearch, status }
        const offset = (page - 1) * limit
        getInboxes({ limit, offset, options })
    }, [getInboxes, inboxSearch, limit, page, status])

    const fetchProducts = useCallback(async () => {
        await getProducts()
    }, [getProducts])

    const fetchPenerbits = useCallback(async () => {
        await getPenerbits()
    }, [getPenerbits])

    useEffect(() => {
        if (isPemodal && isNewChat && newChatProductToggle) {
            fetchProducts()
            fetchPenerbits()
            const penerbitIdentifier = identifier.split('&')[0].split('=')[1]
            const decryptedPenerbitId = getDecryptedId(penerbitIdentifier)
            const productIdentifier = identifier.split('&')[1].split('=')[1]
            const decryptedProductId = getDecryptedId(productIdentifier)
            setNewChatPenerbitId(decryptedPenerbitId)
            setNewChatProductId(decryptedProductId)
            setNewChatProductToggle(false)
        } else if (!isNewChat) {
            const decryptedInboxId = getDecryptedId(identifier)
            setInboxId(decryptedInboxId)
            if (messages) {
                const height = messages.scrollHeight
                messages.scrollTo(0, height)
            }
        }
    }, [
        identifier,
        messages,
        products,
        isPemodal,
        isNewChat,
        newChatProductId,
        newChatProductToggle,
        fetchProducts,
        fetchPenerbits,
    ])

    useEffect(() => {
        const clearDatas = () => {
            dispatch(reset('inbox'))
            dispatch(clearInbox())
            dispatch(clearInboxDetail())
        }
        window.addEventListener('beforeunload', clearDatas)
        return () => {
            clearDatas()
            window.removeEventListener('beforeunload', clearDatas)
        }
    }, [clearInbox, clearInboxDetail, dispatch])

    useEffect(() => {
        fetchInboxes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(() => {
        if (inboxId) {
            fetchInbox(inboxId)
        }
    }, [inboxId, fetchInbox])

    useEffect(() => {
        if (!isActionLoadingInboxDetail && isActionSuccessInboxDetail && inboxId) {
            fetchInbox(inboxId)
            dispatch(reset('inbox'))
        }
    }, [dispatch, fetchInbox, inboxId, isActionLoadingInboxDetail, isActionSuccessInboxDetail])

    useEffect(() => {
        if (!isEmpty(inboxDetails)) {
            setCurrentInboxDetails(inboxDetails)
        }
    }, [inboxDetails])

    useEffect(() => {
        if (id) {
            const encryptedId = getEncryptedId(id)
            navigate(`/inbox/${encryptedId}`)
        }
    }, [id, navigate])

    const newChatPenerbit = find(penerbits, (item) => item.id === toNumber(newChatPenerbitId))
    const newChatProduct = find(products, (item) => item.id === toNumber(newChatProductId))
    const isSpesificInboxesExist = find(
        inboxes,
        (item) =>
            item.productId === toNumber(newChatProductId) &&
            item.penerbitId === toNumber(newChatPenerbitId) &&
            item.pemodal.username === username
    )

    const newChatPenerbitName = getPenerbitName(newChatPenerbit)
    const newChatPenerbitEntityCode = get(newChatPenerbit, 'entityCode', '')
    const newChatSlugProduct = get(newChatProduct, 'slug', null)

    const isProductCardAvailable = inbox.productId || inbox.purchaseOrderId || newChatProduct
    const isProductOrderCardOpen = !isActionLoadingInbox && isProductCardAvailable

    const handleChange = (value) => {
        setStatus(value)
        if (identifier) {
            navigate('/inbox')
        }
    }

    const handleOpenMessage = (id, isReadMessage) => () => {
        if (isReadMessage) {
            readInboxDetails({ inboxId: id, user })
        }
        const encryptedId = getEncryptedId(id)
        navigate(`/inbox/${encryptedId}`)
    }

    const handleNext = () => {
        setPage((prevState) => prevState + 1)
    }

    const handleFinish = (values) => {
        const { message } = values
        const inboxId = get(inbox, 'id', null)
        const productId = get(newChatProduct, 'id', null)

        if (isNewChat) {
            let newChatData = {
                createdBy: username,
                entityCode: newChatPenerbitEntityCode,
                fromTable: user,
                inboxDetail: {
                    message,
                },
                productId: productId,
                toTable: 'penerbit',
            }

            createInbox(newChatData)
        } else {
            const data = {
                createdBy: username,
                createdByFromTable: user,
                inboxId,
                message,
            }
            createInboxDetail(data)
            if (inboxStatus === 'new' && !isInboxCreatedByCurrentUser) {
                const data = {
                    id: inboxId,
                    status: 'in progress',
                }
                updateInbox(data)
            }
        }
    }

    const groupedInboxDate = map(currentInboxDetails, (item, key) => {
        const messageDateFormat = moment(item.createdAt, DATE_HOUR_SLASH_FORMAT).format(DATE_SLASH_FORMAT)
        if (!includes(inboxDates, messageDateFormat)) {
            inboxDates.push(messageDateFormat)
        } else {
            inboxDates.push('')
        }
        return <MessageCard inboxDates={inboxDates[key]} inboxDetail={item} user={user} />
    })

    const handleRenderItem = (item, key) => {
        const fromTable = get(item, 'fromTable', '')
        const id = get(item, 'id', null)
        const inboxDetails = get(item, 'inboxDetails', [])
        const pemodal = get(item, 'pemodal', {})
        const penerbit = get(item, 'penerbit', {})
        const profilePictureUrl = get(pemodal, 'profilePictureUrl', '')
        const toTable = get(item, 'toTable', '')
        const username = get(pemodal, 'username', '')

        const lastInboxDetail = last(inboxDetails)
        const lastInboxDetailDate = get(lastInboxDetail, 'createdAt', null)
        const chatWith = fromTable === user ? toTable : fromTable
        const isChatWithAdminUser = chatWith === 'admin_user'
        const isChatWithPemodal = chatWith === 'pemodal'
        const isChatWithPenerbit = chatWith === 'penerbit'

        const unreadMessageCount = size(
            filter(inboxDetails, (item) => item.createdByFromTable !== user && !item.isRead && !item.isDeleted)
        )
        const isShowUnreadMessage = unreadMessageCount > 0
        const unreadMessageIcon = isShowUnreadMessage && (
            <StyledAntdAvatar size='small'>{unreadMessageCount}</StyledAntdAvatar>
        )

        return (
            <StyledAntdListItem
                $isActive={inboxId === item.id}
                key={key}
                onClick={handleOpenMessage(id, isShowUnreadMessage)}
            >
                <StyledInboxesAntdRow align='middle'>
                    <AntdCol span={20}>
                        <AntdSpace size={isMobile() ? 'small' : 'large'}>
                            {isChatWithPemodal ? (
                                <AntdAvatar size={isMobile() ? 45 : 60} src={profilePictureUrl} />
                            ) : (
                                <AvatarPicker
                                    $size={isMobile() ? 45 : 60}
                                    adminUser={isChatWithAdminUser && 'S'}
                                    inboxInital={isChatWithPenerbit && getPenerbitName(penerbit)}
                                />
                            )}
                            <AntdSpace direction='vertical' size={0}>
                                <AntdSpace>
                                    <StyledEllipsisParagraph
                                        $isShowUnreadMessage={isShowUnreadMessage}
                                        $width={width}
                                        color={colors.persianIndigo}
                                    >
                                        {isChatWithPemodal && username}
                                        {isChatWithPenerbit && getPenerbitName(penerbit)}
                                        {isChatWithAdminUser && 'support.moneypool'}
                                    </StyledEllipsisParagraph>
                                    {unreadMessageIcon}
                                </AntdSpace>
                                <StyledSpan color={colors.silver}>{startCase(chatWith)}</StyledSpan>
                            </AntdSpace>
                        </AntdSpace>
                    </AntdCol>
                    <AntdCol span={4}>
                        <StyledSpan color={colors.silver}>
                            {moment(lastInboxDetailDate, DATE_HOUR_SLASH_FORMAT).format(DATE_SLASH_FORMAT)}
                        </StyledSpan>
                    </AntdCol>
                </StyledInboxesAntdRow>
            </StyledAntdListItem>
        )
    }

    const handleSearch = (value) => setInboxSearch(value)

    const renderInbox = () => (
        <StyledInboxContainer>
            <StyledAntdSpace direction='vertical' size='middle'>
                <StyledInputSearchAntdRow>
                    <AntdCol span={24}>
                        <InputSearch
                            dark={dark}
                            defaultValue={inboxSearch}
                            name='product-name'
                            onSearch={handleSearch}
                            placeholder='Cari Pesan'
                        />
                    </AntdCol>
                </StyledInputSearchAntdRow>
                <StyledSelectTypeAntdRow>
                    <AntdCol span={24}>
                        <StyledSelect
                            onChange={handleChange}
                            optionFilterProp='label'
                            options={recieverTypeOptions}
                            placeholder='Pilih Tipe Pesan'
                            value={status}
                        />
                    </AntdCol>
                </StyledSelectTypeAntdRow>
                <StyledChat id='scrollableDiv'>
                    {(isNewChat || !identifier) && isActionLoadingInbox ? (
                        <Loader />
                    ) : (
                        <InfiniteScroll
                            dataLength={dataLength}
                            hasMore={dataLength < totalInboxes}
                            loader={<Loader noPadding={true} />}
                            next={handleNext}
                            scrollableTarget='scrollableDiv'
                        >
                            <StyledAntdList dataSource={inboxes} renderItem={handleRenderItem} split={false} />
                        </InfiniteScroll>
                    )}
                </StyledChat>
            </StyledAntdSpace>
        </StyledInboxContainer>
    )

    const handleClickDetailCard = (total, { slug, noDoc }) => {
        if (total) {
            return '/product/' + slug
        } else {
            return '/transaction-histories?noDoc=' + noDoc
        }
    }

    const renderMessage = () => {
        if (!identifier) {
            return null
        }

        let categoryTitle = get(inbox, 'product.category', null)
        let imageUrl = get(inbox, 'product.productImages.0.url', null)
        let typeTitle = get(inbox, 'product.type', null)
        let slugProduct = get(inbox, 'product.slug', null)
        let entityType = get(inbox, 'penerbit.entityType.value', null)
        let entityName = get(inbox, 'penerbit.entityName', null)
        let entityFullName = entityType + ' ' + entityName
        let noDoc = get(inbox, 'purchaseOrder.noDoc', '')
        let total = get(inbox, 'product.target', 0)

        if (!categoryTitle) {
            categoryTitle = get(inbox, 'purchaseOrder.product.category', null)
        }

        if (!imageUrl) {
            imageUrl = get(inbox, 'purchaseOrder.product.productImages.0.url', null)
        }

        if (!typeTitle) {
            typeTitle = get(inbox, 'purchaseOrder.product.type', null)
        }

        if (isNewChat) {
            categoryTitle = get(newChatProduct, 'category', null)
            imageUrl = get(newChatProduct, 'productImages.0.url', null)
            typeTitle = get(newChatProduct, 'type', null)
            slugProduct = get(newChatProduct, 'slug', null)
            entityType = get(newChatProduct, 'penerbit.entityType.value', null)
            entityName = get(newChatProduct, 'penerbit.entityName', null)
            entityFullName = entityType + ' ' + entityName
            total = get(newChatProduct, 'target', 0)
        }

        let requiredMessageInfo = { categoryTitle, typeTitle, slugProduct, entityType, entityName, total }
        if (!isNewChat && noDoc !== '') {
            requiredMessageInfo = Object.assign(requiredMessageInfo, { noDoc })
            delete requiredMessageInfo.slugProduct
        }

        const isMessageInfoNull = Object.values(requiredMessageInfo).some((value) => value === null)

        if (isMessageInfoNull || isActionLoadingInbox) {
            return <Loader />
        }

        return (
            <Fragment>
                <StyledReceiverContainer>
                    <StyledTitle color='black' level={4}>
                        {!isNewChat && isChatWithPemodal && pemodalUsername}
                        {!isNewChat && isChatWithPenerbit && penerbitName}
                        {isNewChat && newChatPenerbitName}
                    </StyledTitle>
                    <StyledParagraph color={colors.silver}>{!isNewChat && startCase(chatWith)}</StyledParagraph>
                    <StyledParagraph color={colors.silver}>
                        {isNewChat && newChatPenerbit && 'Penerbit'}
                    </StyledParagraph>
                </StyledReceiverContainer>
                <StyledMessageContainer>
                    <StyledMessageForm>
                        <StyledMessages ref={messagesRef}>
                            <StyledAntdSpace direction='vertical' size='large'>
                                {isProductOrderCardOpen &&
                                    renderLink(
                                        {
                                            to: handleClickDetailCard(total, {
                                                slug: slugProduct,
                                                noDoc,
                                            }),
                                        },
                                        <ProductOrderCard
                                            category={categoryTitle}
                                            entityName={entityFullName}
                                            imageUrl={imageUrl}
                                            noDoc={noDoc}
                                            total={total}
                                            type={typeTitle}
                                        />
                                    )}
                                {!isNewChat && groupedInboxDate}
                            </StyledAntdSpace>
                        </StyledMessages>
                        <Form onFinish={handleSubmit(handleFinish)}>
                            <Field
                                component={ReduxFormInput}
                                componentProps={{
                                    suffix,
                                }}
                                dark={dark}
                                formItemProps={{
                                    noMargin: true,
                                    isHideLabel: true,
                                }}
                                name='message'
                                placeholder='Ketik Pesan'
                            />
                        </Form>
                    </StyledMessageForm>
                </StyledMessageContainer>
            </Fragment>
        )
    }

    if (!isLoggedIn) {
        navigate('/login')
        return null
    }

    if ((isNewChat && isSpesificInboxesExist) || (isNewChat && isPenerbit)) {
        navigate('/inbox')
        return null
    }

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <StyledAntdSpace direction='vertical' size='large'>
                <AntdRow>
                    <AntdCol>
                        {isNewChat ? (
                            <BackButton route={!isMobile() && `/product/${newChatSlugProduct}`} />
                        ) : (
                            <BackButton route={!isMobile() || !identifier ? '/profile' : '/inbox'} />
                        )}
                    </AntdCol>
                </AntdRow>
                <center>
                    <AntdSpace>
                        <StyledTitle $dark={dark} level={2}>
                            {metaTitle}
                        </StyledTitle>
                        {tooltipInfo}
                    </AntdSpace>
                </center>
                <StyledInbox>
                    <StyledAntdRow>
                        <AntdCol lg={10} sm={!identifier ? 24 : 0} xs={!identifier ? 24 : 0}>
                            {renderInbox()}
                        </AntdCol>
                        <AntdCol lg={14} sm={identifier ? 24 : 0} xs={identifier ? 24 : 0}>
                            {renderMessage()}
                        </AntdCol>
                    </StyledAntdRow>
                </StyledInbox>
            </StyledAntdSpace>
        </StyledSection>
    )
}

Inbox.propTypes = {
    clearInbox: PropTypes.func,
    clearInboxDetail: PropTypes.func,
    createInbox: PropTypes.func,
    createInboxDetail: PropTypes.func,
    getInbox: PropTypes.func,
    getInboxes: PropTypes.func,
    getPenerbits: PropTypes.func,
    getProducts: PropTypes.func,
    handleSubmit: PropTypes.func,
    id: PropTypes.number,
    inbox: PropTypes.object,
    inboxes: PropTypes.array,
    isActionLoadingInbox: PropTypes.bool,
    isActionLoadingInboxDetail: PropTypes.bool,
    isActionSuccessInboxDetail: PropTypes.bool,
    penerbits: PropTypes.array,
    products: PropTypes.array,
    readInboxDetails: PropTypes.func,
    totalInboxes: PropTypes.number,
    updateInbox: PropTypes.func,
    user: PropTypes.string,
    username: PropTypes.string,
}

export default Inbox
