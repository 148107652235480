import PropTypes from 'prop-types'
import React from 'react'
import { default as AntdSpace } from 'antd/lib/space'
import { Modal } from '../../antdcomponents'
import { useOutletContext } from 'react-router-dom'
import VerifyOtpFormContainer from '../../components/verify_otp_form/VerifyOtpFormContainer'

const EmailOtpVerification = (props) => {
    const { initialValues, isModalOpen, onCancel } = props
    const { navigate } = useOutletContext()

    const handleFinish = (values) => {
        const { otp } = values
        navigate(`/verify_email?user=penerbit-detail&value=${otp}`)
    }

    return (
        <Modal onCancel={onCancel} visible={isModalOpen}>
            <AntdSpace direction='vertical'>
                <VerifyOtpFormContainer
                    {...initialValues}
                    {...props}
                    onClick={onCancel}
                    onFinish={handleFinish}
                    user='penerbit-detail'
                />
            </AntdSpace>
        </Modal>
    )
}

EmailOtpVerification.propTypes = {
    initialValues: PropTypes.object,
    isModalOpen: PropTypes.bool,
    onCancel: PropTypes.func,
}

export default EmailOtpVerification
