import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'
import { StyledAntdCollapse, StyledAntdCollapsePanel, StyledAntdRow } from './StyledComponents'

const Collapse = (props) => {
    const { items } = props

    const renderItem = (item, key) => {
        const { content, title } = item
        const header = <StyledAntdRow justify='left'>{title}</StyledAntdRow>
        return (
            <StyledAntdCollapsePanel header={header} key={key}>
                <span className='ql-editor'>{content}</span>
            </StyledAntdCollapsePanel>
        )
    }

    return (
        <StyledAntdCollapse destroyInactivePanel>{map(items, (item, key) => renderItem(item, key))}</StyledAntdCollapse>
    )
}

Collapse.propTypes = {
    items: PropTypes.array,
}

export default Collapse
