import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'

const ScrollToTop = (props) => {
    const { location, windowObj } = props
    const newWindow = typeof window !== 'undefined' ? window : windowObj
    const handleScrollToTop = useCallback(() => newWindow.scrollTo(0, 0), [newWindow])

    useEffect(() => {
        handleScrollToTop()
    }, [handleScrollToTop, location])

    return null
}

ScrollToTop.propTypes = {
    location: PropTypes.object,
    windowObj: PropTypes.object,
}

export default ScrollToTop
