import { Button as AntdButton } from 'antd'
import includes from 'lodash/includes'
import styled, { css } from 'styled-components'
import colors from '../../basic/colors'
import gradientColors from '../../basic/gradientColors'
import modifyVars from '../../basic/modifyVars'

const getButtonBackground = (dark, disabled) => {
    if (disabled) {
        return 'none'
    } else {
        if (dark) {
            return gradientColors.buttonDark
        } else {
            return gradientColors.buttonLight
        }
    }
}

const buttonPrimaryCss = css`
    border: 0px;
    white-space: normal;

    &,
    &:hover,
    &:focus {
        background-image: ${(props) => getButtonBackground(props.$dark, props.disabled)};
    }

    > * {
        margin: auto;
    }
    & span {
        font-family: ${modifyVars.hammersmithOneFont};
        ${(props) =>
            !props.disabled &&
            `
            color: ${props.$dark ? colors.persianIndigo : 'white'};
        `}
    }
    padding: 5px 20px;
    min-height: 45px;
    height: auto;
    width: 100%;
    @media screen and (min-width: 768px) {
        min-height: 55px;
        padding: 10px 20px;
    }
`

const buttonLinkTextCss = css`
    ${(props) =>
        !props.disabled &&
        `
        color: ${props.$color || 'black'};
    `}
    height: auto;
    font-style: ${modifyVars.spanStyle.fontStyle};
    font-weight: ${modifyVars.spanStyle.fontWeight};
    font-size: ${modifyVars.spanStyle.fontSizeMobile} !important;
    font-family: ${modifyVars.spanStyle.fontFamily};
    padding: 0px;
    margin: 0px;

    @media screen and (min-width: 768px) {
        font-size: ${modifyVars.spanStyle.fontSizeDesktop} !important;
    }
`

const buttonNoStyleCss = css`
    border: none;
    height: auto;
    margin: 0px;
    padding: 0px;
`

const StyledAntdButton = styled(AntdButton)`
    &&& {
        ${(props) => props.$noStyle && buttonNoStyleCss}
        ${(props) => includes(['link', 'text'], props.type) && buttonLinkTextCss}
        ${(props) => props.type === 'primary' && buttonPrimaryCss}
        &:hover span {
            font-weight: ${(props) => (props.$boldOnHover ? 'bold' : 'regular')} !important;
        }
    }
`

export default StyledAntdButton
