import React from 'react'
import { connect } from 'react-redux'
import * as inboxSlice from '../features/inbox/inboxSlice'
import Profile from './Profile'

const ProfileContainer = (props) => <Profile {...props} />

const mapStateToProps = (state) => ({
    currentRegisterStep: state.authentication.currentRegisterStep,
    isValidUser: state.authentication.isValidUser,
    user: state.authentication.user,
})

const mapDispatchToProps = {
    clearInbox: inboxSlice.clearInbox,
    getInboxesUnread: inboxSlice.getInboxesUnread,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
