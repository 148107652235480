import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import get from 'lodash/get'
import map from 'lodash/map'
import { toState } from '../../transformers/penerbitDetail'
import * as penerbitDetailAPI from './penerbitDetailAPI'

const initialState = {
    isActionLoading: false,
    isActionSuccess: false,
    isError: false,
    isLoading: false,
    penerbitDetail: {},
    penerbitDetails: [],
    totalPenerbitDetails: 0,
}

export const {
    createPenerbitDetail,
    deletePenerbitDetail,
    getPenerbitDetail,
    getPenerbitDetails,
    updatePenerbitDetail,
} = penerbitDetailAPI

const penerbitDetailSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(createPenerbitDetail.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
                penerbitDetail: toState(data.penerbitDetail),
            })
        })
        builder.addCase(getPenerbitDetail.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                penerbitDetail: {},
            })
        )
        builder.addCase(getPenerbitDetail.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                penerbitDetail: toState(data.penerbitDetail),
            })
        })
        builder.addCase(getPenerbitDetails.pending, (state) =>
            Object.assign(state, {
                isError: false,
                isLoading: true,
                penerbitDetails: [],
            })
        )
        builder.addCase(getPenerbitDetails.fulfilled, (state, action) => {
            const data = get(action, 'data', {})
            return Object.assign(state, {
                isLoading: false,
                penerbitDetails: map(data.penerbitDetails, (item) => toState(item)),
                totalPenerbitDetails: data.count,
            })
        })
        builder.addMatcher(
            isAnyOf(createPenerbitDetail.pending, deletePenerbitDetail.pending, updatePenerbitDetail.pending),
            (state) =>
                Object.assign(state, {
                    isActionLoading: true,
                    isActionSuccess: false,
                    isError: false,
                })
        )
        builder.addMatcher(isAnyOf(deletePenerbitDetail.fulfilled, updatePenerbitDetail.fulfilled), (state) =>
            Object.assign(state, {
                isActionLoading: false,
                isActionSuccess: true,
            })
        )
        builder.addMatcher(
            isAnyOf(createPenerbitDetail.rejected, deletePenerbitDetail.rejected, updatePenerbitDetail.rejected),
            (state) =>
                Object.assign(state, {
                    isActionLoading: false,
                    isError: true,
                })
        )
        builder.addMatcher(isAnyOf(getPenerbitDetail.rejected, getPenerbitDetails.rejected), (state) =>
            Object.assign(state, {
                isError: true,
                isLoading: false,
            })
        )
    },
    initialState,
    name: 'PENERBIT_DETAIL',
    reducers: {
        clearPenerbitDetail: () => initialState,
    },
})

export const { clearPenerbitDetail } = penerbitDetailSlice.actions

const { reducer } = penerbitDetailSlice
export default reducer
