import isArray from 'lodash/isArray'
import join from 'lodash/join'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

const mapSelectOptions = (items = [], keys, isSorted = true) => {
    const mappedItems = map(items, (item) => {
        const label = !isArray(keys)
            ? `${item[keys]}`
            : join(
                  map(keys, (key) => item[key]),
                  ' - '
              )

        return {
            label,
            value: item.id,
        }
    })

    return isSorted ? orderBy(mappedItems, 'label') : mappedItems
}

export default mapSelectOptions
