import { default as AntdCol } from 'antd/lib/col'
import { default as AntdRow } from 'antd/lib/row'
import capitalize from 'lodash/capitalize'
import find from 'lodash/find'
import get from 'lodash/get'
import trim from 'lodash/trim'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import colors from '../../basic/colors'
import imagePath from '../../basic/imagePath'
import { getRegisterData, isValidUserType } from '../../common/helper/authentication'
import HelmetMeta from '../../components/HelmetMeta'
import PemodalForm from '../../components/pemodal_form/PemodalForm'
import PenerbitFormContainer from '../../components/penerbit_form/PenerbitFormContainer'
import { StyledAntdSpace, StyledImage, StyledSection } from '../../components/StyledComponents'
import StyledTitle from '../../components/styled_title/StyledTitle'
import getBase64 from '../../utility/getBase64'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import { renderLink } from '../../utility/renderLinkComp'
import useSelectorFormValues from '../../utility/useSelectorFormValues'

const ProfileForm = (props) => {
    const {
        authToken,
        authenticate,
        clearPemodal,
        clearPenerbit,
        clearVerification,
        createPemodal,
        createPenerbit,
        currentRegisterStep,
        districts,
        email,
        id,
        isActionLoadingSendVerification,
        isActionLoadingUser,
        isActionSuccessSendVerification,
        isActionSuccessUser,
        isEmailVerified,
        isValidUser,
        mobileNumber,
        refetchUser,
        sendVerificationEmail,
        sendVerificationMobileNumber,
        updatePemodal,
        updatePenerbit,
    } = props
    const { dark, dispatch, isPemodal, isPenerbit, navigate, pathname } = useOutletContext()
    const { currentPage, userType } = getRegisterData(dark, pathname, isPemodal)

    const formValues = useSelectorFormValues('detail')
    const newEmail = get(formValues, 'email', '')
    const newMobileNumber = get(formValues, 'mobileNumber', '')
    const password = get(formValues, 'password', '')
    const npwp = get(formValues, 'npwp', [])
    const [isAllowedRedirection, setIsAllowedRedirection] = useState(false)
    const [changingField, setChangingField] = useState('')
    const profitImage = <StyledImage src={imagePath.profit} />

    useEffect(() => {
        const baseData = { user: userType }
        if (!isActionLoadingUser && isActionSuccessUser) {
            if (authToken) {
                if (changingField === 'email') {
                    sendVerificationEmail({ email: trim(newEmail), id, user: userType })
                } else if (changingField === 'phone') {
                    sendVerificationMobileNumber({ new_mobile_number: '+62' + newMobileNumber })
                } else {
                    setIsAllowedRedirection(true)
                }
            } else {
                setIsAllowedRedirection(true)
                const data = Object.assign(baseData, {
                    identifier: trim(newEmail),
                    password,
                })
                authenticate(data)
            }
        }
        return () => {
            dispatch(clearPemodal())
            dispatch(clearPenerbit())
        }
    }, [
        authenticate,
        authToken,
        changingField,
        clearPemodal,
        clearPenerbit,
        dispatch,
        id,
        isActionLoadingUser,
        isActionSuccessUser,
        newEmail,
        newMobileNumber,
        password,
        sendVerificationEmail,
        sendVerificationMobileNumber,
        userType,
    ])

    useEffect(() => {
        if (!isActionLoadingSendVerification && isActionSuccessSendVerification) {
            setIsAllowedRedirection(true)
            dispatch(clearVerification())
            let attr = ['email', 'mobileNumber', 'username']
            if (userType === 'penerbit') {
                attr = [
                    ...attr,
                    'address',
                    'businessSectorId',
                    'cityId',
                    'description',
                    'entityCode',
                    'entityIdentificationNo',
                    'entityName',
                    'entityTypeId',
                    'fundingAmountId',
                    'postalCode',
                    'websiteUrl',
                ]
            }
            const data = {
                email: trim(newEmail),
                additionalAttr: attr,
            }
            refetchUser(data)
        }
    }, [
        clearVerification,
        dispatch,
        isActionLoadingSendVerification,
        isActionSuccessSendVerification,
        newEmail,
        refetchUser,
        userType,
    ])

    const handleFinish = async (values) => {
        const { email: newEmail, mobileNumber: newMobileNumber, password, username } = values
        const baseData = {
            createdBy: username,
            email: trim(newEmail),
            isActive: 1,
            mobileNumber: newMobileNumber,
            password,
            username,
        }
        if (authToken && email !== newEmail) {
            setChangingField('email')
        }
        if (userType === 'pemodal') {
            let data = baseData
            if (authToken) {
                if (mobileNumber !== newMobileNumber && isEmailVerified) {
                    setChangingField('phone')
                }
                data = Object.assign(data, { id })
                updatePemodal(data)
            } else {
                createPemodal(data)
            }
        }
        if (userType === 'penerbit') {
            return Promise.all(Array.from(npwp).map(getBase64)).then((npwp) => {
                const {
                    address,
                    businessSectorId,
                    cityId,
                    description,
                    entityCode,
                    entityIdentificationNo,
                    entityName,
                    entityTypeId,
                    fundingAmountId,
                    postalCode,
                    productTypeId,
                    subdistrictName,
                    websiteUrl,
                } = values
                let data = Object.assign(baseData, {
                    address,
                    businessSectorId,
                    cityId,
                    description,
                    districtId: get(find(districts, { subdistrict: subdistrictName }), 'id', null),
                    entityCode,
                    entityIdentificationNo,
                    entityName,
                    entityTypeId,
                    fundingAmountId,
                    isUploading: true,
                    penerbitDocuments: npwp,
                    postalCode,
                    productTypeId,
                    websiteUrl,
                })
                if (authToken) {
                    data = Object.assign(data, { id })
                    updatePenerbit(data)
                } else {
                    createPenerbit(data)
                }
            })
        }
    }

    const form = {
        pemodal: <PemodalForm {...props} isUpdateAllowed={true} onFinish={handleFinish} />,
        penerbit: <PenerbitFormContainer {...props} npwp={npwp} onFinish={handleFinish} />,
    }

    // TODO: Clean up use authToken because isLoggedIn is delayed
    if (authToken) {
        if (isValidUser) {
            navigate('/profile')
            return null
        } else {
            if (
                isAllowedRedirection ||
                (isPemodal && currentRegisterStep >= 4) ||
                (isPenerbit && currentRegisterStep >= 3)
            ) {
                navigate(`/register/${userType}/step${currentRegisterStep}`)
                return null
            }
        }
    }

    if (!isValidUserType(userType)) {
        navigate('/register')
        return null
    }

    const metaTitle = `Registrasi ${capitalize(userType)}`

    return (
        <StyledSection dark={dark}>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            <StyledAntdSpace direction='vertical' size={20}>
                {currentPage}
                <AntdRow>
                    <AntdCol md={12} sm={0} xs={0}>
                        <AntdRow justify='center'>{renderLazyLoadImage(profitImage)}</AntdRow>
                    </AntdCol>
                    <AntdCol md={{ offset: 1, span: 11 }} sm={24} xs={24}>
                        <StyledAntdSpace direction='vertical' size='middle'>
                            <StyledTitle $dark={dark} color={colors.graniteGray} level={2}>
                                {metaTitle}
                            </StyledTitle>
                            {form[userType]}
                            <center>
                                <AntdRow justify='center'>
                                    <StyledTitle color={colors.graniteGray} level={5}>
                                        Sudah punya akun?
                                    </StyledTitle>
                                    &nbsp;&nbsp;
                                    <StyledTitle $dark={dark} level={5}>
                                        {renderLink({ to: '/login' }, 'Masuk')}
                                    </StyledTitle>
                                </AntdRow>
                            </center>
                        </StyledAntdSpace>
                    </AntdCol>
                </AntdRow>
            </StyledAntdSpace>
        </StyledSection>
    )
}

ProfileForm.propTypes = {
    authToken: PropTypes.string,
    authenticate: PropTypes.func,
    clearPemodal: PropTypes.func,
    clearPenerbit: PropTypes.func,
    clearVerification: PropTypes.func,
    createPemodal: PropTypes.func,
    createPenerbit: PropTypes.func,
    currentRegisterStep: PropTypes.number,
    districts: PropTypes.array,
    email: PropTypes.string,
    id: PropTypes.number,
    isActionLoadingSendVerification: PropTypes.bool,
    isActionLoadingUser: PropTypes.bool,
    isActionSuccessSendVerification: PropTypes.bool,
    isActionSuccessUser: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    isValidUser: PropTypes.bool,
    mobileNumber: PropTypes.string,
    refetchUser: PropTypes.func,
    sendVerificationEmail: PropTypes.func,
    sendVerificationMobileNumber: PropTypes.func,
    updatePemodal: PropTypes.func,
    updatePenerbit: PropTypes.func,
}

export default ProfileForm
