import PropTypes from 'prop-types'
import styled from 'styled-components'
import { default as AntdCol } from 'antd/lib/col'
import { Card, Empty, Ribbon } from '../../antdcomponents'
import colors from '../../basic/colors'
import modifyVars from '../../basic/modifyVars'
import StyledSpan from '../styled_span/StyledSpan'
import StyledTitle from '../styled_title/StyledTitle'

export const StyledAntdCol = styled(AntdCol)`
    &&& {
        @media screen and (min-width: 768px) {
            height: 47px;
        }
    }
`

export const StyledBottomText = styled.div`
    & * {
        align-self: flex-end;
    }
`

export const StyledCard = styled(Card)`
    &&& {
        border-radius: 15px;
        overflow: hidden;
        height: ${(props) => (props.mini ? '490' : '520')}px;

        width: 300px;
        z-index: 10;
        box-shadow: 0px 3px 2px rgb(0 0 0 / 25%);

        .ant-card-body {
            padding: 0px;
        }

        @media screen and (min-width: 768px) {
            box-shadow: 0px 4px 10px ${colors.black}40;
        }
    }
`

StyledCard.propTypes = {
    mini: PropTypes.bool,
}

export const StyledEmpty = styled(Empty)`
    &&& {
        height: 250px;
        .ant-empty-image,
        .ant-empty-description {
            margin: auto;
            width: 100%;
        }
        .ant-empty-description {
            height: 0%;
        }
        .ant-empty-image {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
        }
        img {
            height: auto;
            margin: 0 auto;
            width: 75%;
            opacity: 60%;
        }
    }
`

export const StyledFavoriteImage = styled.img`
    height: 30px;
    margin-top: 5px;
    width: 30px;
`

export const StyledImage = styled.img`
    height: 250px;
    object-fit: cover;
    width: 300px;
`

export const StyledProductDetail = styled.div`
    padding: 0px 20px;
`

export const StyledProductSummary = styled.div`
    padding: 10px 20px 0px 20px;
`

StyledProductSummary.propTypes = {
    mini: PropTypes.bool,
}

export const StyledRibbon = styled(Ribbon)`
    &&& {
        bottom: ${(props) => (props.$mini ? '-30px' : '15px')};
        right: -70px;
        width: 200px;
        height: 40px;
        top: auto;
        left: auto;
        transform: rotate(-40deg);
        overflow: hidden;
        padding-top: 5px;
        position: absolute;
        text-align: center;

        span {
            height: 250px;
            width: 50px;
        }
    }
`

export const StyledTitleText = styled(StyledSpan)`
    &&& {
        font-size: 9px;
        line-height: 135%;
    }
`

export const StyledValueText = styled(StyledTitle)`
    &&& {
        font-family: ${modifyVars.hammersmithOneFont};
        font-size: ${(props) => (props.size === 'md' ? '18px' : '12px')};
        line-height: 135%;
    }
`

StyledValueText.propTypes = {
    $dark: PropTypes.bool,
    size: PropTypes.string,
}
