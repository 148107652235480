import styled from 'styled-components'
import { Input as AntdInput } from 'antd'

export const StyledLeftAntdInput = styled(AntdInput)`
    &&& {
        border-radius: 20px 0px 0px 20px !important;
        border-width: 2px 0px 2px 2px;
        height: 30px;
        text-align: center;
        width: 23% !important;

        span.ant-input-suffix {
            display: none;
        }

        @media screen and (min-width: 768px) {
            font-size: 20px;
            height: 45px;
            width: 30% !important;
        }

        @media screen and (min-width: 1200px) {
            width: 20% !important;
        }
    }
`

export const StyledRightAntdInput = styled(AntdInput)`
    &&& {
        & {
            border-radius: 0px 20px 20px 0px !important;
            border-width: 2px 2px 2px 0px;
            height: 30px;
            width: 77% !important;
            font-size: 14px;
            &:focus {
                box-shadow: none !important;
                border-right-width: 2px !important;
            }

            @media screen and (min-width: 768px) {
                font-size: 20px;
                height: 45px;
                width: 70% !important;
            }

            @media screen and (min-width: 1200px) {
                width: 80% !important;
            }
        }
    }
`
