import PropTypes from 'prop-types'
import React from 'react'
import StyledAntdEmpty from '../../antdcomponents/empty/StyledAntdEmpty'
import imagePath from '../../basic/imagePath'
import formatPrice from '../../utility/formatPrice'
import renderLazyLoadImage from '../../utility/renderLazyLoadImage'
import {
    StyledAntdCard,
    StyledCompanySection,
    StyledDetailSection,
    StyledImageSection,
    StyledNumberSection,
    StyledProductOrderCard,
} from './StyledComponents'

const ProductOrderCard = (props) => {
    const { category, entityName, imageUrl, noDoc, total, type, onClick } = props
    const productImage = <img src={imageUrl} />

    return (
        <StyledProductOrderCard onClick={onClick}>
            <StyledAntdCard>
                <StyledImageSection>
                    {imageUrl ? renderLazyLoadImage(productImage) : <StyledAntdEmpty image={imagePath.noDataGray} />}
                </StyledImageSection>
                <StyledDetailSection>
                    <StyledCompanySection>
                        <div>
                            {category} | {type}
                        </div>
                        <div>{entityName}</div>
                    </StyledCompanySection>
                    <StyledNumberSection>
                        <div>{total ? 'Total Pendanaan' : 'Order ID'}</div>
                        <div>{total ? `Rp ${formatPrice(total)}` : noDoc}</div>
                    </StyledNumberSection>
                </StyledDetailSection>
            </StyledAntdCard>
        </StyledProductOrderCard>
    )
}

ProductOrderCard.propTypes = {
    category: PropTypes.string,
    entityName: PropTypes.string,
    imageUrl: PropTypes.string,
    noDoc: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
    total: PropTypes.number,
    type: PropTypes.string,
}

export default ProductOrderCard
