import toLower from 'lodash/toLower'
import { paymentMethods as purchaseOrderPaymentMethods } from '../common/options/purchaseOrder'

export const transactionFees = {
    [toLower(purchaseOrderPaymentMethods[0])]: { operator: '+', value: 4500 },
    [toLower(purchaseOrderPaymentMethods[1])]: { operator: '*', value: 0.015 },
    [toLower(purchaseOrderPaymentMethods[2])]: { operator: '*', value: 0.03 },
    [toLower(purchaseOrderPaymentMethods[3])]: { operator: '*', value: 0.04 },
    [toLower(purchaseOrderPaymentMethods[4])]: { operator: '*', value: 0.007 },
    [toLower(purchaseOrderPaymentMethods[5])]: { operator: '*', value: 0.019 },
    [toLower(purchaseOrderPaymentMethods[6])]: { operator: '+', value: 5000 },
}
