import React from 'react'
import { connect } from 'react-redux'
import * as productSlice from '../features/product/productSlice'
import Portfolio from './Portfolio'

const PortfolioContainer = (props) => <Portfolio {...props} />

const mapStateToProps = (state) => ({
    initialValueTotal: state.purchaseOrder.initialValueTotal,
    isLoading: state.purchaseOrder.isLoading,
    totalProducts: state.product.totalProducts,
    products: state.product.products,
    ytdValueTotal: state.purchaseOrder.ytdValueTotal,
})

const mapDispatchToProps = {
    clearProduct: productSlice.clearProduct,
    getPortfolio: productSlice.getPortfolio,
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioContainer)
