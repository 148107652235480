import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useOutletContext } from 'react-router-dom'
import colors from '../basic/colors'
import HelmetMeta from '../components/HelmetMeta'
import RiskStatementText from '../components/risk_statement_text/RiskStatementText'
import { StyledSection } from '../components/StyledComponents'
import StyledTitle from '../components/styled_title/StyledTitle'
import { DATE_HOUR_SLASH_FORMAT_WITHOUTCOLON } from '../constants/formats'

const RiskStatement = (props) => {
    const { riskStatementApprovalDate } = props
    const { dark } = useOutletContext()

    const metaTitle = 'Pernyataan Risiko'
    const ApprovalDate = moment(riskStatementApprovalDate).format(DATE_HOUR_SLASH_FORMAT_WITHOUTCOLON)

    return (
        <StyledSection>
            <HelmetMeta mainTitle={metaTitle} needIndex={false} />
            {!isEmpty(riskStatementApprovalDate) && (
                <Fragment>
                    <center>
                        <StyledTitle color={colors.salmon} level={5}>
                            {`telah dipahami dan disetujui pengguna pada ${ApprovalDate}`}
                        </StyledTitle>
                    </center>
                    <br />
                </Fragment>
            )}
            <RiskStatementText dark={dark} title={metaTitle} />
        </StyledSection>
    )
}

RiskStatement.propTypes = {
    riskStatementApprovalDate: PropTypes.string,
}

export default RiskStatement
